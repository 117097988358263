import { FC } from 'react'
import {Field, ErrorMessage, FieldArray} from 'formik'
import { StepProps, defaultWizardArea } from '../helpers/ConfiguracoesIniciaisHelper'
import { useAlert, useHttpClient } from '../../../../modules/services/Bundle'
import { RequestMethod } from '../../../../modules/services/core/_enums'
import { AbrirModalVideo } from '../../../toolbar/AbrirModalVideo'
import $ from 'jquery';

const Step5: FC<StepProps> = ({formik}) => {

  const stepRef = 'cargos'
  const stepNameP = 'Cargos'
  const stepNameS = 'Cargo'
  const stepDescription = 'Funções da empresa'
  const stepModel = defaultWizardArea
  const stepAlertName = 'esse cargo'
  const stepDeleteEndpoint = '/Gerenciamento/Cargo/Deletar'

  const httpClient = useHttpClient()
  const alert = useAlert()

  return (
    <>
      <AbrirModalVideo
        videoPath='https://pro4tech.blob.core.windows.net/visualstuff/Wizard%20Cargos.mp4?si=maintaining&spr=https&sv=2022-11-02&sr=b&sig=YNmyFYD9ut69RD02fFG1QJ3BReWtYuKVqfNHxL%2BrU7A%3D'
        videoTitle='Cargos da empresa'
        descricao='Por fim, você aprenderá como cadastrar cargos na plataforma, fornecendo informações essenciais, como o nome do cargo, seu nível hierárquico e a área a que pertence. Nesta versão experimental, você terá a possibilidade de cadastrar até dez cargos.'
      />
      <div className='w-100'>

          <FieldArray
              name={stepRef}
              render={({ insert, remove, push}) => (
                <>
                  <div className='row align-items-center justify-content-between m-0'>
                    <div className="col-lg-12 text-end px-0 pb-10 pb-lg-12">
                      <button 
                        type="button" 
                        className="btn btn-light-success"
                        onClick={ async () => {

                          const response = await httpClient.request({
                            method: RequestMethod.GET,
                            endpoint: '/DescricaoDeCargo/ConfiguracoesIniciais/VerificarQtdMaximaCargos',
                          })

                          if (response.success && formik.values.cargos.length < 10) {
                            push(stepModel)
                            let scrollHeight = $('.height-step-5').height()
                            $('.scroll-height-step-5').animate({
                              scrollTop: scrollHeight
                            }, (700) );
                          }

                        }}
                      >
                        <i className="fas fa-plus"></i> Adicionar
                      </button>
                    </div>
                  </div>

                  <div className='scroll scroll-height-step-5 h-375px px-3'>

                    <div className='height-step-5' id={`accordion-${stepRef}`}>

                    {
                      formik.values[stepRef].map((data: any, index: number)=>{
                        return(
                          <div className="card card-custom border border-3 border-secondary border-1 shadow-sm mb-3" key={`card-${stepRef}-${index}`}>
                            <div className="card-header border-bottom border-secondary border-1 rounded-bottom" id={`card-${stepRef}-header-${index}`}>
                              <div className="card-title">
                                <h3>{(formik.values[stepRef][index].nome) ? formik.values[stepRef][index].nome : `${stepNameS} ${index + 1}`}</h3>                                
                              </div>
                              <div className="card-toolbar w-md-auto">
                                {(formik.values[stepRef].length !== 1) ? 
                                  <button type="button" className="btn btn-icon btn-sm btn-light-danger me-2" onClick={() => {
                                    
                                    alert.createDialog({
                                      html: `Realmente deseja excluir ${stepAlertName}?`,
                                      confirmAction: async () => {

                                        if(formik.values[stepRef][index].id && formik.values[stepRef][index].id !== 0){
                                          const result = await httpClient.request({
                                            method: RequestMethod.DELETE,
                                            endpoint: stepDeleteEndpoint,
                                            data: formik.values[stepRef][index].id
                                          })
    
                                          if(result.success) remove(index)
                                        }
                                        else remove(index)
                                        
                                      }
                                    })

                                  }}>
                                    <i className="fas fa-trash"></i>
                                  </button> : ''
                                } 
                                <button type="button" className="btn btn-icon btn-sm btn-accordion btn-light-primary" data-bs-toggle="collapse" data-bs-target={`#card-${stepRef}-collapse-${index}`} aria-expanded="true" aria-controls={`card-${stepRef}-collapse-${index}`} >
                                  <i className='fas fa-arrow-down'></i>
                                  <i className='fas fa-arrow-up'></i>
                                </button>                   
                              </div>
                            </div>
                            <div id={`card-${stepRef}-collapse-${index}`} className="collapse show" aria-labelledby={`card-${stepRef}-header-${index}`} data-bs-parent={`#accordion-${stepRef}`}>
                              <div className="card-body">
                                <div className="row">

                                  <div className="col-lg-12 fv-row mb-5">
                                    <label className='form-label required'>Nome</label>
                                    <Field
                                      as='input'
                                      id={`cargos-${index}-nome`}
                                      name={`${stepRef}.${index}.nome`}
                                      placeholder='Nome'
                                      className='form-control form-control-lg form-control-solid'
                                      maxLength={60}
                                      value={formik.values[stepRef][index].nome}
                                    />
                                    <div className='text-danger mt-2'>
                                      <ErrorMessage name={`${stepRef}.${index}.nome`} />
                                    </div> 
                                  </div>

                                  <div className="col-lg-6 fv-row mb-5">
                                    <label className='form-label required'>Nível hierárquico</label>
                                    <Field 
                                      as='select'
                                      id={`cargos-${index}-nivel`}
                                      name={`${stepRef}.${index}.nivel`}
                                      className='form-select form-select-lg form-select-solid'
                                      value={formik.values[stepRef][index].nivel}
                                    >  
                                      <option value=''>Selecione...</option>
                                      {
                                        formik.values.niveis.map((optionData: any, optionIndex: number)=>{
                                          return (
                                            <option key={`select-${stepRef}-nivel-${optionIndex}`} id={`nivel-cargo-${optionIndex}`} value={optionData.id}>{optionData.nome}</option>
                                          )
                                        })
                                      }
                                    </Field>
                                    <div className='text-danger mt-2'>
                                      <ErrorMessage name={`${stepRef}.${index}.nivel`} />
                                    </div>                   
                                  </div>   

                                  <div className="col-lg-6 fv-row">
                                    <label className='form-label required'>Área</label>
                                    <Field 
                                      as='select'
                                      id={`cargos-${index}-area`}
                                      name={`${stepRef}.${index}.area`}
                                      className='form-select form-select-lg form-select-solid'
                                      value={formik.values[stepRef][index].area}
                                    >  
                                      <option value=''>Selecione...</option>
                                      {
                                        formik.values.areas.map((optionData: any, optionIndex: number)=>{
                                          return (
                                            <option key={`select-${stepRef}-area-${optionIndex}`} id={`area-cargo-${optionIndex}`} value={optionData.id}>{optionData.nome}</option>
                                          )
                                        })
                                      }
                                    </Field>
                                    <div className='text-danger mt-2'>
                                      <ErrorMessage name={`${stepRef}.${index}.area`} />
                                    </div>                   
                                  </div>             

                                </div>
                              </div>   
                            </div>                         
                          </div>
                        )
                      })
                    }

                    </div>
                    
                  </div>
                </>              

              )}
          />

      </div>
    </>
  )
}

export {Step5}
