import * as React from 'react'
import Box from '@mui/material/Box'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TablePagination from '@mui/material/TablePagination'
import TableRow from '@mui/material/TableRow'
import TableSortLabel from '@mui/material/TableSortLabel'
import Paper from '@mui/material/Paper'
import {visuallyHidden} from '@mui/utils'
import {useAlert, useHelper, useHttpClient} from '../../../modules/services/Bundle'
import {RequestMethod, EnumDescricaoStatusAprovacao} from '../../../modules/services/core/_enums'
import {Link, useLocation, useNavigate} from 'react-router-dom'
import { IconButton } from '@mui/material'
import { KTSVG } from '../../../../_metronic/helpers'
import { ModalCarregarColaboradores } from './partials/ModalCarregarColaboradores'
import { useAuth } from '../../../modules/auth'
import { ISelectModel } from '../../../modules/services/core/_models'

interface Data {
  Id: number
  Nome: string,
  CPF: string,
  NomeEmpresa: string,
  NomeCargo: string,
  Ativo: boolean
}

interface Column {
  id: keyof Data
  label: string
  minWidth?: number
  align?: 'center'
  format?: (value: number) => string
}

interface Filtros {
  IdCargo?: number
}

const columns: readonly Column[] = [
  {
    id: 'Nome',
    label: 'COLABORADOR',
  },
  {
    id: 'CPF',
    label: 'CPF',
  },
  {
    id: 'NomeEmpresa',
    label: 'EMPRESA',
  },
  {
    id: 'NomeCargo',
    label: 'CARGO',
  }
]

function createData(
    Id: number,
    Nome: string,
    CPF: string,
    NomeEmpresa: string,
    NomeCargo: string,
    Ativo: boolean
): Data {
  return {Id, Nome, CPF, NomeEmpresa, NomeCargo, Ativo}
}

type Order = 'asc' | 'desc'

interface EnhancedTableProps {
  onRequestSort: (event: React.MouseEvent<unknown>, property: keyof Data) => void
  order: Order
  orderBy: string
}

function EnhancedTableHead(props: EnhancedTableProps) {
  const {order, orderBy, onRequestSort} = props
  const createSortHandler = (property: keyof Data) => (event: React.MouseEvent<unknown>) => {
    onRequestSort(event, property)
  }

  return (
    <TableHead>
      <TableRow className='table-header'>
        {columns.map((columns) => (
          <TableCell
            className='table-cell'
            key={columns.id}
            align={columns.align ? 'right' : 'left'}
          >
            <TableSortLabel
              className='fw-bolder'
              active={orderBy === columns.id}
              direction={orderBy === columns.id ? order : 'asc'}
              onClick={createSortHandler(columns.id)}
            >
              {columns.label}
              {orderBy === columns.id ? (
                <Box component='span' sx={visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
        <TableCell className='table-cell fw-bolder' align='center'>
          AÇÕES
        </TableCell>
      </TableRow>
    </TableHead>
  )
}

interface Prop {
  colabCliente: boolean
  title?: string;
  status?: EnumDescricaoStatusAprovacao;
}

const Colaboradores = ({colabCliente, title, status}: Prop) => {
  const httpClient = useHttpClient()
  const navigate = useNavigate();
  const [order, setOrder] = React.useState<Order>('asc')
  const [orderBy, setOrderBy] = React.useState<keyof Data>('Nome')
  const [page, setPage] = React.useState(0)
  const [rowsPerPage, setRowsPerPage] = React.useState(10)
  const [total, setTotal] = React.useState(0)
  const [rows, setRows] = React.useState<Data[]>([])
  const [cargos, setCargos] = React.useState<any[]>([])
  const [filtros, setFiltros] = React.useState<Filtros>({
    IdCargo: undefined
  })
  const alert = useAlert()
  const helper = useHelper()
  const [abrirModalCarregarColaboradores, setAbrirModalCarregarColaboradores] = React.useState(false)
  const {auth} = useAuth()
  const [empresasCliente, setEmpresasCliente] = React.useState<ISelectModel[]>([])
  const [idEmpresaClienteSelecionada, setIdEmpresaClienteSelecionada] = React.useState<number>(0)

  const resquestTable = (
    pageRq: number,
    orderByRq: string,
    orderRq: string,
    rowsPerPageRq: number,
    Filtros: Filtros
  ) => {

    httpClient
      .request({
        method: RequestMethod.POST,
        endpoint: '/Gerenciamento/Usuario/ListarColaboradores',
        queryObject: {
          isConsultoria: colabCliente,
          idEmpresa: idEmpresaClienteSelecionada
        },
        data: {
          Order: orderRq,
          OrderBy: orderByRq,
          Page: pageRq,
          RowsPerPage: rowsPerPageRq,
          Filters: Filtros,
        }
      })
      .then((result) => {
        
        const newRows = result.payload.data.map((data: Data) => {
          return createData(
            data.Id,
            data.Nome,
            data.CPF,
            data.NomeEmpresa,
            data.NomeCargo,
            data.Ativo
          )
        })
        setRows([...newRows])
        setTotal(result.payload.total)
      })
  }

  React.useEffect(() => {
    carregarDados()
  },[colabCliente])

  React.useEffect(() => {
    setRows([])
    if (colabCliente && idEmpresaClienteSelecionada > 0) obterDados()
  },[idEmpresaClienteSelecionada])

  const carregarDados = async () => {
    if (colabCliente) await obterEmpresasCliente()
    else await obterDados()
  }

  const obterEmpresasCliente = async () => {
    const response = await httpClient.request({
      method: RequestMethod.GET,
      endpoint: '/DescricaoDeCargo/Consultoria/ObterClientesParaSelect',
    })

    if(response.success && response.payload) {
      setEmpresasCliente(response.payload)
      if(response.payload.length > 0) setIdEmpresaClienteSelecionada(response.payload[0].Id)
    }
  }

  React.useEffect(() => {
    obterDados()
  }, [title, status])

  const obterDados = () => {
    obterDadosTabela()
    httpClient.request({
      method: RequestMethod.GET,
      endpoint: '/Gerenciamento/Cargo/ObterParaSelect',
      queryObject: {
        isConsultoria: colabCliente,
        idEmpresa: idEmpresaClienteSelecionada
      },
    }).then((response) => {
      if (response.success && response.payload && response.payload.length > 0) setCargos(response.payload)
    })
  }
  
  const obterDadosTabela = () => {
    resquestTable(page, orderBy, order, rowsPerPage, filtros)
  }

  const handleRequestSort = (event: React.MouseEvent<unknown>, property: keyof Data) => {
    const isAsc = orderBy === property && order === 'asc'
    setOrder(isAsc ? 'desc' : 'asc')
    setOrderBy(property)
    resquestTable(page, property, isAsc ? 'desc' : 'asc', rowsPerPage, filtros)
  }

  const handleChangePage = (event: unknown, newPage: number) => {
    resquestTable(newPage, orderBy, order, rowsPerPage, filtros)
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    resquestTable(page, orderBy, order, parseInt(event.target.value, 10), filtros)
    setPage(0)
  }

  const obterStatusUpload = async () => {
    return await httpClient.request({
      method: RequestMethod.GET,
      endpoint: '/Gerenciamento/Usuario/ObterStatusUpload',
    })
  }

  const emptyRows = rows.length

  return (
    <>
      <div className='card mb-5 mb-xl-10'>
        <div className='card-header'>
          <div className='card-title m-0'>
            <h3 className='fw-bolder m-0'>Colaboradores</h3>
          </div>
          <div className='card-toolbar'>
            {((auth?.roles.find(x => x === 'Master') !== undefined) || (auth?.roles.find(x => x === 'Administrador') !== undefined) || (auth?.permissoes.find(x => x === (colabCliente ? '05DCF9A80E6678106FDC57AFCD2B5585' : 'DF137248F6EE6CC1C946F07B78408B82')) !== undefined)) && (
              <button 
                className='btn btn-light-primary me-5'
                onClick={() => {

                  if (colabCliente && idEmpresaClienteSelecionada === 0) {
                    alert.createMessage({
                      html: 'É necessário selecionar a empresa cliente!'
                    })
                    return
                  }

                  alert.createDialog({
                    html: `Realmente deseja enviar os logins para todos os colaboradores${colabCliente ? ' da empresa cliente selecionada' : ''}?`,
                    confirmAction: async () => {                              
                        httpClient.request({
                            method: RequestMethod.POST,
                            endpoint: '/Gerenciamento/Usuario/EnviarTodosLoginsPorEmail',
                            queryObject: {
                              isConsultoria: colabCliente,
                              idEmpresa: idEmpresaClienteSelecionada
                            }
                        });
                    }
                  })
                }}
              >
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" className="bi bi-send-fill me-2" viewBox="0 0 20 20">
                  <path d="M15.964.686a.5.5 0 0 0-.65-.65L.767 5.855H.766l-.452.18a.5.5 0 0 0-.082.887l.41.26.001.002 4.995 3.178 3.178 4.995.002.002.26.41a.5.5 0 0 0 .886-.083l6-15Zm-1.833 1.89L6.637 10.07l-.215-.338a.5.5 0 0 0-.154-.154l-.338-.215 7.494-7.494 1.178-.471-.47 1.178Z"/>
                </svg>
                Enviar Logins
              </button>
            )}
            {((auth?.roles.find(x => x === 'Master') !== undefined) || (auth?.roles.find(x => x === 'Administrador') !== undefined) || (auth?.permissoes.find(x => x === (colabCliente ? 'D32C21EB46878FE7DC54AD8A9ED962EF' : '95766929517498E4D36D1F83C7634516')) !== undefined)) && (
              <button
                type='button'
                className='btn btn-light-primary me-5'
                onClick={() => { 
                  setAbrirModalCarregarColaboradores(true); 
                }}
              >
                <KTSVG path="/media/icons/duotune/files/fil013.svg" className="svg-icon-muted svg-icon-1" />
                Carregar
              </button>
            )}
            {((auth?.roles.find(x => x === 'Master') !== undefined) || (auth?.roles.find(x => x === 'Administrador') !== undefined) || (auth?.permissoes.find(x => x === (colabCliente ? 'B3CDEADEE41680C02F0D2BD66DCE199D' : '022D7B75FB91B941809AAB83FD729462')) !== undefined)) && (
              <button 
                className='btn btn-light-primary'
                title='Adicionar'
                onClick={(e) => {
                  obterStatusUpload().then((response) => {
                    if(response.success){
                      navigate( colabCliente ? '/descricao/consultoria/colaborador/gerenciar':'/configuracao/colaboradores/gerenciar', {
                        state: {
                          visualizar: false
                        }
                      })
                    }
                  })
                }}
              >
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" className="bi bi-person-plus-fill me-2" viewBox="0 0 20 20">
                  <path d="M1 14s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1H1zm5-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6z"/>
                  <path fillRule="evenodd" d="M13.5 5a.5.5 0 0 1 .5.5V7h1.5a.5.5 0 0 1 0 1H14v1.5a.5.5 0 0 1-1 0V8h-1.5a.5.5 0 0 1 0-1H13V5.5a.5.5 0 0 1 .5-.5z"/>
                </svg>
                Adicionar
              </button>
            )}
          </div>
        </div>
        <div className='card-body p-9'>
          <div className='row mb-2'>
            {(colabCliente) && (
              <div className='col-lg-4 mb-5'>
                <label className='form-label'>Cliente</label>
                <select
                  className='form-select form-select-lg form-select-solid'
                  value={idEmpresaClienteSelecionada}
                  onChange={(e: React.ChangeEvent<HTMLSelectElement>) => { setIdEmpresaClienteSelecionada(parseInt(e.target.value)) }}
                >
                  <option value={0}>Selecione...</option>
                  {empresasCliente && empresasCliente.length > 0 && empresasCliente.map((data: ISelectModel, index: number) => {
                      return (
                        <option key={`cliente-option-${index}`} value={data.Id}>
                          {data.Nome}
                        </option>
                      )
                  })}
                </select>
              </div>
            )} 
            <div className={`${colabCliente ? 'col-lg-8' : 'col-lg-12'} mb-5`}>
              <div className={`d-flex justify-content-end ${colabCliente ? 'mt-8' : ''}`}>
                {/* begin::Filter Button */}
                <button
                  type='button'
                  className='btn btn-light-primary'
                  data-kt-menu-trigger='click'
                  data-kt-menu-placement='bottom-end'
                >
                  <i className='fs-2 fas fa-arrow-down-wide-short'></i>
                  Filtros
                </button>
                {/* end::Filter Button */}
                {/* begin::SubMenu */}
                <div className='menu menu-sub menu-sub-dropdown w-600px w-md-650px' data-kt-menu='true'>
                  {/* begin::Header */}
                  <div className='px-7 py-5'>
                    <div className='fs-5 text-dark fw-bolder'>Opções</div>
                  </div>
                  {/* end::Header */}

                  {/* begin::Separator */}
                  <div className='separator border-gray-200'></div>
                  {/* end::Separator */}

                  {/* begin::Content */}
                  <div className='px-7 py-5' data-kt-user-table-filter='form'>
                    {/* begin::Input group */}
                    <div className="row">
                      <div className='mb-10 col-12'>
                        <label className='form-label fs-6 fw-bold'>Cargo:</label>
                        <select
                          className='form-select form-select-solid fw-bolder'
                          data-kt-select2='true'
                          data-placeholder='Select option'
                          data-allow-clear='true'
                          data-kt-user-table-filter='role'
                          data-hide-search='true'
                          onChange={e => {
                            let newFiltros = filtros
                            newFiltros.IdCargo = e.target.value ? parseInt(e.target.value) : undefined
                            setFiltros(newFiltros)
                          }}
                        >
                          <option value=''>Selecione...</option>
                          {
                            cargos && cargos.length > 0 && cargos.map((data: any, index: any) => {
                              return(
                                <option key={`IdCargo-option-${index}`} value={data.Id}>{data.Nome}</option>
                              )
                            })
                          }
                        </select>
                      </div>
                      {/* end::Input group */}
                    </div>

                    {/* begin::Actions */}
                    <div className='d-flex justify-content-end'>
                      <button
                        type='button'
                        className='btn btn-light btn-active-light-primary fw-bold me-5 px-6'
                        data-kt-menu-dismiss='true'
                        data-kt-user-table-filter='reset'
                      >
                        Cancelar
                      </button>
                      <button
                        type='button'
                        className='btn btn-primary fw-bold px-6'
                        data-kt-menu-dismiss='true'
                        data-kt-user-table-filter='filter'
                        onClick={() => obterDadosTabela()}
                      >
                        Buscar
                      </button>
                    </div>
                    {/* end::Actions */}
                  </div>
                  {/* end::Content */}
                </div>
                {/* end::SubMenu */}
              </div>
            </div>
          </div>
          <form noValidate className='form'>
            <div>
              <Box sx={{width: '100%'}}>
                <Paper className='table-border ' sx={{width: '100%', mb: 2}}>
                  <TableContainer>
                    <Table className='table-header' sx={{minWidth: 750}} aria-labelledby='tableTitle'>
                      <EnhancedTableHead
                        order={order}
                        orderBy={orderBy}
                        onRequestSort={handleRequestSort}
                      />
                      <TableBody>
                        {rows.map((row, index) => {
                          return (
                            <TableRow
                              className='table-row'
                              hover
                              tabIndex={-1}
                              key={index}
                              sx={{cursor: 'pointer'}}
                            >
                              <TableCell className='table-cell' align='left'>
                                {row.Nome}
                              </TableCell>
                              <TableCell className='table-cell' align='left'>
                                {row.CPF}
                              </TableCell>
                              <TableCell className='table-cell' align='left'>
                                {row.NomeEmpresa}
                              </TableCell>
                              <TableCell className='table-cell' align='left'>
                                {row.NomeCargo}
                              </TableCell>
                              <TableCell className='table-cell' align='center'>
                                  <IconButton 
                                      className={`text-gray-800`}
                                      title='Visualizar' 
                                      onClick={() => { 
                                          navigate(colabCliente ? '/descricao/consultoria/colaborador/gerenciar':'/configuracao/colaboradores/gerenciar', { state: { 
                                              idColaborador: row.Id,
                                              visualizar: true,
                                              title: "Visualizar"
                                          }})
                                      }}>
                                      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-eye-fill" viewBox="0 0 16 16">
                                          <path d="M10.5 8a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0z"/>
                                          <path d="M0 8s3-5.5 8-5.5S16 8 16 8s-3 5.5-8 5.5S0 8 0 8zm8 3.5a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7z"/>
                                      </svg>
                                  </IconButton>
                                  {((auth?.roles.find(x => x === 'Master') !== undefined) || (auth?.roles.find(x => x === 'Administrador') !== undefined) || (auth?.permissoes.find(x => x === (colabCliente ? '326A6F7D34B56ECDD80C9A379B18F19E' : '5E8D0DB1A4C9AD65F95E51453F4BC7D2')) !== undefined)) && (
                                    <IconButton 
                                      className={`text-gray-800`}
                                      title='Editar'
                                      onClick={() => { 
                                        obterStatusUpload().then((response) => {
                                          if(response.success){
                                            navigate(colabCliente ? '/descricao/consultoria/colaborador/gerenciar':'/configuracao/colaboradores/gerenciar', { state: { 
                                              idColaborador: row.Id,
                                              visualizar: false,
                                              title: "Editar"
                                            }})
                                          }
                                        })
                                      }}>
                                      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-pencil-square" viewBox="0 0 16 16">
                                          <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z"/>
                                          <path fillRule="evenodd" d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z"/>
                                      </svg>
                                  </IconButton>
                                  )}
                                  {((auth?.roles.find(x => x === 'Master') !== undefined) || (auth?.roles.find(x => x === 'Administrador') !== undefined) || (auth?.permissoes.find(x => x === (colabCliente ? '05DCF9A80E6678106FDC57AFCD2B5585' : 'DF137248F6EE6CC1C946F07B78408B82')) !== undefined)) && (
                                    <>
                                      {row.Ativo && (
                                        <IconButton 
                                          className={`text-gray-800`}
                                          title="Enviar login"
                                          onClick={() => {
                                              alert.createDialog({
                                                  html: `Realmente deseja enviar o login para este colaborador?`,
                                                  confirmAction: async () => {                              
                                                      httpClient.request({
                                                          method: RequestMethod.POST,
                                                          endpoint: '/Gerenciamento/Usuario/EnviarLoginPorEmail',
                                                          queryObject: {
                                                            idColaborador: row.Id
                                                          }
                                                      }).then((response) => {
                                                          if (response.success) obterDadosTabela()                                   
                                                      })
                                                  }
                                              })
                                          }}
                                          >
                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-send-fill" viewBox="0 0 16 16">
                                              <path d="M15.964.686a.5.5 0 0 0-.65-.65L.767 5.855H.766l-.452.18a.5.5 0 0 0-.082.887l.41.26.001.002 4.995 3.178 3.178 4.995.002.002.26.41a.5.5 0 0 0 .886-.083l6-15Zm-1.833 1.89L6.637 10.07l-.215-.338a.5.5 0 0 0-.154-.154l-.338-.215 7.494-7.494 1.178-.471-.47 1.178Z"/>
                                            </svg>
                                        </IconButton>
                                        )}
                                    </>
                                  )}
                                  {((auth?.roles.find(x => x === 'Master') !== undefined) || (auth?.roles.find(x => x === 'Administrador') !== undefined) || (auth?.permissoes.find(x => x === (row.Ativo ? (colabCliente ? 'C59FEC6FD23C235C481ACC4B434C541D' : '01138E4B9B3182A6769A3334BB7ED253') : (colabCliente ? '326D5C450B56EB253A27E3EE680660A2' : '7D725AA4A7ECC1C7961A81596C3A6A0F'))) !== undefined)) && (
                                    <IconButton 
                                        className={`text-gray-800`}
                                        title={`${row.Ativo ? "Desabilitar" : "Habilitar"}`}
                                        onClick={() => {
                                            alert.createDialog({
                                                html: `Realmente deseja ${row.Ativo ? "Desabilitar" : "Habilitar"} este colaborador?`,
                                                confirmAction: async () => {                              
                                                    httpClient.request({
                                                        method: RequestMethod.PUT,
                                                        endpoint: '/Gerenciamento/Usuario/Desabilitar',
                                                        queryObject: {
                                                            idUsuario: row.Id
                                                        }
                                                    }).then((response) => {
                                                        if (response.success) obterDadosTabela()                                   
                                                    })
                                                }
                                            })
                                        }}
                                        >
                                        {
                                          row.Ativo ? 
                                          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-person-fill-lock" viewBox="0 0 16 16">
                                              <path d="M11 5a3 3 0 1 1-6 0 3 3 0 0 1 6 0Zm-9 8c0 1 1 1 1 1h5v-1a1.9 1.9 0 0 1 .01-.2 4.49 4.49 0 0 1 1.534-3.693C9.077 9.038 8.564 9 8 9c-5 0-6 3-6 4Zm7 0a1 1 0 0 1 1-1v-1a2 2 0 1 1 4 0v1a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1h-4a1 1 0 0 1-1-1v-2Zm3-3a1 1 0 0 0-1 1v1h2v-1a1 1 0 0 0-1-1Z"/>
                                          </svg>
                                          :
                                          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-person-fill-check" viewBox="0 0 16 16">
                                              <path d="M12.5 16a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7Zm1.679-4.493-1.335 2.226a.75.75 0 0 1-1.174.144l-.774-.773a.5.5 0 0 1 .708-.708l.547.548 1.17-1.951a.5.5 0 1 1 .858.514ZM11 5a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z"/>
                                              <path d="M2 13c0 1 1 1 1 1h5.256A4.493 4.493 0 0 1 8 12.5a4.49 4.49 0 0 1 1.544-3.393C9.077 9.038 8.564 9 8 9c-5 0-6 3-6 4Z"/>
                                          </svg>
                                        }
                                    </IconButton>
                                  )}
                              </TableCell>
                            </TableRow>
                          )
                        })}
                        {emptyRows === 0 && (
                          <TableRow>
                            <TableCell className='table-cell text-center' colSpan={6}>
                              Nenhum registro encontrado
                            </TableCell>
                          </TableRow>
                        )}
                      </TableBody>
                    </Table>
                  </TableContainer>
                  <TablePagination
                    className='table-footer'
                    labelRowsPerPage='linhas por página'
                    rowsPerPageOptions={[10, 25]}
                    component='div'
                    count={total}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                  />
                </Paper>
              </Box>
            </div>
          </form>
        </div>
        <div className='card-footer d-flex justify-content-end py-4'>
          <Link className='btn btn-secondary' to={`/descricao/dashboard`}>
            Voltar
          </Link>
        </div>
      </div>

      {
        abrirModalCarregarColaboradores && (
          <ModalCarregarColaboradores
            colabCliente={colabCliente}
            abrirModal={abrirModalCarregarColaboradores}
            setAbrirModal={setAbrirModalCarregarColaboradores}
          />
        )
      }                      

    </>
  )
}

export {Colaboradores}
