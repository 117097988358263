/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { ReactNode, useEffect, useState } from 'react'
import { Field, FormikProps } from 'formik'
import { ServicoModel, useAuth } from '../../../../modules/auth'
import { IExemploDimensaoPerguntaModel } from '../helpers/PesquisaHelper'

type Props = {
  textos: IExemploDimensaoPerguntaModel[] | undefined,
}

const PesquisaPerguntas: React.FC<Props> = ({
  textos,
}) => {
  const {auth} = useAuth()
  const servico = auth?.servico

  const [currentUrl, setCurrentUrl] = useState('');

  useEffect(()=>{
    const url = window.location.pathname;
    setCurrentUrl(url);

    const handleUrlChange = () => {
      const updatedUrl = window.location.href;
      setCurrentUrl(updatedUrl);
    };

    window.addEventListener('popstate', handleUrlChange);

    return () => {
      window.removeEventListener('popstate', handleUrlChange);
    };
  }, [])

  return (
    <>
      {textos && textos.map((texto, textoIndex) => (
        <div 
          key={textoIndex}
          className={`w-100 ${textoIndex < (textos.length - 1) ? 'mb-5' : ''}`}
        >
          <textarea                  
            className={`form-control form-control-lg form-control-solid w-100`}                                     
            value={texto.Texto ?? ''}
            disabled={true}
          />
          {texto.QuestaoPergunta != null && texto.QuestaoPergunta.length > 0 && 
            (
              <>
                <div key={textoIndex + "questao"} className='ms-5'>
                  {texto.QuestaoPergunta.map((questao, questaoIndex) => (
                    <textarea  
                      key={"questao-"+textoIndex+"-"+questaoIndex}                
                      className={`form-control form-control-lg form-control-solid w-100 mt-3`}                                     
                      value={`${questaoIndex == 0 ? "A" : "B"} - ${questao.ValorQuestao ?? ''}`}
                      disabled={true}
                    />
                  ))}
                </div>
              </>
            )
          }
        </div>
      ))}
    </>
  )
}

export {PesquisaPerguntas}
