import * as Yup from 'yup'

export interface IAcaoModel {
  Id: number,
  Nome: string
}

export interface IFuncionalidadeModel {
  Id: number,
  IdInterface: number,
  IdAcao: number,
  IdAcaoNavigation?: IAcaoModel
}

export interface IPermissaoModel {
  Id: number,
  IdFuncionalidade: number,
  IdPerfil: number,
  IdFuncionalidadeNavigation?: IFuncionalidadeModel
}

export interface IInterfaceModel {
  Id: number,
  IdModulo: number,
  Nome: string
  Funcionalidade: IFuncionalidadeModel[]
}

export interface IModuloModel {
  Id: number,
  Nome: string,
  Interface: IInterfaceModel[]
}

export interface IPerfilModel {
  Id?: number,
  Nome: string,
  Permissao: IPermissaoModel[]
}

const defaultValidationSchemas = Yup.object().shape({
  Nome: Yup.string().required('O nome é obrigatório')
})

const defaultPerfil: IPerfilModel = {
  Id: undefined,
  Nome: '',
  Permissao: []
}

const defaultModulos: IModuloModel = {
  Id: 0,
  Nome: '',
  Interface: []
}

export {defaultValidationSchemas, defaultPerfil, defaultModulos}