import { Column as ColumnType, Task as TaskType } from '../data/initialData'
import { Card, Typography } from '@mui/material'
import TaskList from './Task'
import { Droppable } from '@hello-pangea/dnd'

interface ColumnProps {
  column: ColumnType
  tasks: TaskType[]
}

const Column = (props: ColumnProps) => {
  const { column, tasks } = props

  return (
    <>
        {column && tasks && (
            <Droppable droppableId={column.id}>
            {(
                provided,
                snapshot // the child of a Droppable element must be a function that returns an element
            ) => (
                <>
                <div className='col-lg-4'>
                <Typography variant="h4" className='mb-2' sx={{ textAlign: 'center' }}>
                    {column.title}
                </Typography>
                <Card
                    className='card card-custom borda-aprimorar-color shadow mb-5 bg-body p-3'
                    style={{}}
                    ref={provided.innerRef}
                    square
                    variant="outlined"
                    {...provided.droppableProps} // any droppable elements must have these properties
                >
                    <div className='scroll-cards-aprimorar'>
                        {tasks && tasks.map((task, index) => (
                            <TaskList key={index} columnName={column.id} task={task} taskIndex={index} />
                        ))}
                        {provided.placeholder}
                    </div>
                </Card>
                </div>
                {column.id === 'sugeridas' && (
                    <div className='col-lg-1 text-center d-flex align-items-center justify-content-center'>
                        <i className="bi bi-arrow-left-right fs-1"></i>
                    </div>

                )}
                </>
            )}
            </Droppable>
        )}
        
    </>
    
  )
}

export default Column
