import { ErrorMessage, Field, FormikProps, FormikProvider, useFormik } from 'formik'
import { KTSVG } from '../../../../../_metronic/helpers'
import { useAlert, useHttpClient } from '../../../../modules/services/Bundle'
import { RequestMethod } from '../../../../modules/services/core/_enums'
import { IWizardModel } from '../helpers/ConfiguracoesIniciaisHelper'
import * as Yup from 'yup'
import { useEffect, useState } from 'react'

type Props = {
    abrirModal: boolean,
    setAbrirModal: React.Dispatch<React.SetStateAction<boolean>>
    formik: FormikProps<IWizardModel>
}

interface IDimensaoExcluirPerguntasModel {
    IdDimensao: number
    IdPerguntaSelecionada: number
}

interface IExcluirPerguntasModel {
    DimensaoExcluirPergunta: IDimensaoExcluirPerguntasModel[]
}

const defaultValidationSchemas = Yup.object().shape({
    DimensaoExcluirPergunta: Yup.array().required('É obrigatório selecionar uma pergunta para cada dimensão!'),
})

const defaultResp: IExcluirPerguntasModel = {
    DimensaoExcluirPergunta: [],
}

const ModalExcluirPerguntas = ({abrirModal, setAbrirModal, formik}: Props) => {
    const alert = useAlert()
    const httpClient = useHttpClient()

    const formikExcluirPerguntas = useFormik({
        initialValues: defaultResp,
        validationSchema: defaultValidationSchemas,
        onSubmit: (values, actions) => {
            onClickExcluirPerguntas()
        },
    })

    useEffect(() => {
        let initValues: IExcluirPerguntasModel = {
            DimensaoExcluirPergunta: [],
        }
        formikExcluirPerguntas.setValues(initValues)
    }, [abrirModal])

    const onClickExcluirPerguntas = () =>{

        if(formikExcluirPerguntas.values.DimensaoExcluirPergunta.length !== formik.values.temaBuscarDimensao.Dimensao.length) {
            alert.createMessage({
                html: `Você deve selecionar uma pergunta para excluir de cada dimensão!`,
                icon: 'error',
            })
            return false
        }

        let idsPerguntasSelecionadas = formikExcluirPerguntas.values.DimensaoExcluirPergunta.map((d) => {
            return d.IdPerguntaSelecionada;
        })

        alert.createDialog({
            html: `Realmente deseja excluir as perguntas selecionadas?`,
            confirmAction: async () => {
            
                const response = await httpClient.request({
                    method: RequestMethod.POST,
                    endpoint: '/Pesquisa/ConfiguracoesIniciais/ExcluirPerguntas',
                    data: idsPerguntasSelecionadas
                })
                
                if(response.success) {

                    let pesquisa = formik.values

                    let dimensoes = pesquisa.temaBuscarDimensao.Dimensao.map((d) => {

                        d.Perguntas = [...d.Perguntas.filter(p => !idsPerguntasSelecionadas.includes(p.Id))]

                        return d
                    })

                    pesquisa.temaBuscarDimensao.Dimensao = [...dimensoes]
                    
                    formik.setValues(pesquisa)
                    
                    setAbrirModal(false)
                }
    
            }
        })
    
    }

    return (
        <div className={`modal ${abrirModal? "show" : ""} fade`} tabIndex={-1} id="kt_modal_1" style={{display: `${abrirModal? "block" : "none"}`}}>
            <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-xl">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title">Excluir Perguntas</h5>
                        <div
                            onClick={() => setAbrirModal(false)}
                            className="btn btn-icon btn-sm btn-active-light-primary ms-2"
                            data-bs-dismiss="modal"
                            aria-label="Fechar"
                        >
                            <KTSVG
                                path="/media/icons/duotune/arrows/arr061.svg"
                                className="svg-icon svg-icon-2x"
                            />
                        </div>
                    </div>
                    <div className="modal-body">
                        <FormikProvider value={formikExcluirPerguntas}>
                            <div className='w-100'>
                                <div className="row">
                                    <div className='col-lg-12 fv-row'>
                                        {formik.values.temaBuscarDimensao.Dimensao.length > 0 && formik.values.temaBuscarDimensao.Dimensao.map((dimensao, dimensaoIndex) => {
                                            return (
                                                <div key={`accordion-dimensao-${dimensao.Id}`} id={`accordion-dimensao-${dimensao.Id}`} className={`w-100 ${dimensaoIndex < (formik.values.temaBuscarDimensao.Dimensao.length - 1) ? 'mb-8' : ''}`}>
                                                    <div className={`card card-custom card-dimensao border border-secondary border-1 shadow-sm dimensao-pesquisa-nao-selecionado`}>
                                                        <div className="card-header bg-pesquisa border-bottom border-secondary border-1 rounded-bottom" id={`card-dimensao-header-${dimensao.Id}`}>
                                                            <div className="card-title">
                                                                <h3 className='text-white'>{(dimensao.Nome)}</h3>                                
                                                            </div>
                                                            <div className="card-toolbar w-md-auto">                                
                                                                <button type="button" className="btn btn-icon btn-sm btn-accordion btn-light" data-bs-toggle="collapse" data-bs-target={`#card-dimensao-collapse-${dimensao.Id}`} aria-expanded="true" aria-controls={`card-dimensao-collapse-${dimensao.Id}`} >
                                                                    <i className='fas fa-arrow-down'></i>
                                                                    <i className='fas fa-arrow-up'></i>
                                                                </button>                   
                                                            </div>
                                                        </div>
                                                        <div id={`card-dimensao-collapse-${dimensao.Id}`} className="collapse show" aria-labelledby={`card-dimensao-header-${dimensao.Id}`} data-bs-parent={`#accordion-dimensao-${dimensao.Id}`}>
                                                            <div className={`card-body w-100`}>

                                                                <textarea                  
                                                                    className={`form-control form-control-lg form-control-solid bg-light-pesquisa mb-5 textarea-texto-pesquisa`}                                     
                                                                    value={dimensao?.PesquisaValorAtual?.Texto ?? ""}
                                                                    disabled={true}
                                                                />

                                                                {dimensao.Perguntas && dimensao.Perguntas.map((pergunta, perguntaIndex) => {
                                                                    return (
                                                                        <div 
                                                                        key={`card-pergunta-${perguntaIndex}`}
                                                                        className={`w-100 p-5 border border-secondary rounded ${perguntaIndex < (dimensao.Perguntas.length - 1) ? 'mb-5' : ''}`}
                                                                        >
                                                                            <div className='row align-items-center'>
                                                                                <div className='col-lg-1 d-flex justify-content-center'>
                                                                                    <input 
                                                                                        type="radio" 
                                                                                        className='form-check-input' 
                                                                                        name={`excluir-pergunta-${dimensao.Id}`} 
                                                                                        onChange={() => {
                                                                                            let newValues = {...formikExcluirPerguntas.values}

                                                                                            let indexDimensao = newValues.DimensaoExcluirPergunta.findIndex((d) => d.IdDimensao === dimensao.Id)

                                                                                            if (indexDimensao > -1) {
                                                                                                newValues.DimensaoExcluirPergunta[indexDimensao].IdPerguntaSelecionada = pergunta.Id
                                                                                            }
                                                                                            else{
                                                                                                let dimensaoExcluirPergunta: IDimensaoExcluirPerguntasModel = {
                                                                                                    IdDimensao: dimensao.Id,
                                                                                                    IdPerguntaSelecionada: pergunta.Id
                                                                                                }

                                                                                                newValues.DimensaoExcluirPergunta.push(dimensaoExcluirPergunta)
                                                                                            }

                                                                                            formikExcluirPerguntas.setValues(newValues) 
                                                                                        }}
                                                                                        checked={formikExcluirPerguntas.values.DimensaoExcluirPergunta.find((d) => d.IdDimensao === dimensao.Id && d.IdPerguntaSelecionada === pergunta.Id) !== undefined}
                                                                                    />
                                                                                </div>
                                                                                <div className='col-lg-11'>
                                                                                    <textarea                  
                                                                                        className={`form-control form-control-lg form-control-solid bg-light-success textarea-pergunta-pesquisa`}                                     
                                                                                        value={pergunta?.PesquisaValorAtual?.Texto ?? ""}
                                                                                        disabled={true}
                                                                                    />   
                                                                                </div>
                                                                            </div>                                                                              
                                                                        </div>
                                                                    )
                                                                })}
                                                                
                                                            </div>   
                                                        </div>                         
                                                    </div>
                                                </div>
                                            )
                                        })}  
                                    </div>            
                                </div>
                            </div>
                        </FormikProvider>
                    </div>
                    <div className="modal-footer">
                        <button
                        onClick={() => setAbrirModal(false)}
                        type="button"
                        className="btn btn-light me-5"
                        data-bs-dismiss="modal"
                        >
                            Cancelar
                        </button>
                        
                        <button type='button' onClick={() => formikExcluirPerguntas.handleSubmit()} className='btn btn-pesquisa'>
                            Excluir
                        </button>

                    </div>
                </div>
            </div>
        </div>
    )
}

export {ModalExcluirPerguntas}
