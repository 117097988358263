/* eslint-disable jsx-a11y/anchor-is-valid */
import { useState } from 'react'
import {Link, Navigate, useNavigate} from 'react-router-dom'
import { KTSVG, toAbsoluteUrl } from '../../../../../_metronic/helpers'

type Props = {
  descricao: string
  videoPath: string
  modalPrimeiroAcesso: boolean
  videoTitle: string
  className?: string
}


const ModalTutorial = ({descricao, videoPath, videoTitle, modalPrimeiroAcesso, className = ''}: Props) => {
    
    const [showModal, setShowModal] = useState(modalPrimeiroAcesso)
    
  return (
    <div className={`modal ${showModal? "show" : ""} fade`} tabIndex={-1} id="kt_modal_1" style={{display: `${showModal? "block" : "none"}`}}>
        <div className="modal-dialog modal-dialog-centered modal-xl">
            <div className="modal-content">
            <div className="modal-header">
                <h5 className="modal-title">{videoTitle}</h5>
                <div
                onClick={() => setShowModal(false)}
                className="btn btn-icon btn-sm btn-active-light-primary ms-2"
                data-bs-dismiss="modal"
                aria-label="Fechar"
                >
                <KTSVG
                    path="/media/icons/duotune/arrows/arr061.svg"
                    className="svg-icon svg-icon-2x"
                />
                </div>
            </div>
            <div className="modal-body">
                <div className='row d-flex justify-content-center mt-5 mb-5'>
                    <div className='col-lg-11 d-flex justify-content-center'>
                    <video 
                        controls 
                        style={{backgroundColor: "black"}} 
                        className='w-100 py-5 rounded mw-700px'>
                        <source 
                        src={videoPath} 
                        type="video/mp4"
                        />
                    </video>
                    </div>
                </div>
                <div className='row d-flex justify-content-center mt-5 mb-15'>
                    <div className='col-lg-11 d-flex justify-content-center'>
                    <span className='me-2'>
                        {descricao}
                    </span>
                    </div>
                </div>
            </div>
            <div className="modal-footer">
                <button
                onClick={() => setShowModal(false)}
                type="button"
                className="btn btn-light"
                data-bs-dismiss="modal"
                >
                Ok
                </button>
            </div>
            </div>
        </div>
    </div>
  )
}

export {ModalTutorial}