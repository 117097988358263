import * as React from 'react'
import Box from '@mui/material/Box'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TablePagination from '@mui/material/TablePagination'
import TableRow from '@mui/material/TableRow'
import TableSortLabel from '@mui/material/TableSortLabel'
import Paper from '@mui/material/Paper'
import {visuallyHidden} from '@mui/utils'
import {useAlert, useHelper, useHttpClient} from '../../../../modules/services/Bundle'
import {EnumTipoEnviarAprovacao, RequestMethod} from '../../../../modules/services/core/_enums'
import {Link, useNavigate} from 'react-router-dom'
import {KTSVG} from '../../../../../_metronic/helpers'
import {IconButton} from '@mui/material'
import {ModalCarregarDescricoes} from '.././partials/ModalCarregarDescricoes'
import {FileModel} from '../../../../modules/services/core/_models'

interface Data {
  Id: number
  Nome: string
  Email: string
  NomeCargo: string
}

interface Column {
  id: keyof Data
  label: string
  minWidth?: number
  align?: 'center'
  format?: (value: number) => string
}

interface TableAprovadoresProps {
  IdDescricao: number
  IdsDescricao?: readonly number[]
  AbrirModal: boolean
  TipoRequest?: number
  idEmpresa?: number | null
}

const columns: readonly Column[] = [
  {
    id: 'Nome',
    label: 'COLABORADOR',
  },
  {
    id: 'Email',
    label: 'E-mail',
  },
  {
    id: 'NomeCargo',
    label: 'CARGO',
    align: 'center',
  },
]

function createData(
  Id: number,
  Nome: string,
  Email: string,
  NomeCargo: string,
): Data {
  return {Id, Nome, Email, NomeCargo}
}

type Order = 'asc' | 'desc'

interface EnhancedTableProps {
  onRequestSort: (event: React.MouseEvent<unknown>, property: keyof Data) => void
  order: Order
  orderBy: string
}

function EnhancedTableHead(props: EnhancedTableProps) {
  const {order, orderBy, onRequestSort} = props
  const createSortHandler = (property: keyof Data) => (event: React.MouseEvent<unknown>) => {
    onRequestSort(event, property)
  }

  return (
    <TableHead>
      <TableRow className='table-header'>
        {columns.map((columns) => (
          <TableCell
            className='table-cell'
            key={columns.id}
            align={columns.align ? columns.align : 'left'}
          >
            <TableSortLabel
              className='fw-bolder'
              active={orderBy === columns.id}
              direction={orderBy === columns.id ? order : 'asc'}
              onClick={createSortHandler(columns.id)}
            >
              {columns.label}
              {orderBy === columns.id ? (
                <Box component='span' sx={visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  )
}

const TableAprovadores = ({
  IdDescricao,
  IdsDescricao,
  AbrirModal,
  TipoRequest,
  idEmpresa = null
}: TableAprovadoresProps) => {
  const httpClient = useHttpClient()
  const navigate = useNavigate()
  const alert = useAlert()
  const helper = useHelper()
  const [order, setOrder] = React.useState<Order>('asc')
  const [orderBy, setOrderBy] = React.useState<keyof Data>('Nome')
  const [page, setPage] = React.useState(0)
  const [rowsPerPage, setRowsPerPage] = React.useState(5)
  const [total, setTotal] = React.useState(0)
  const [rows, setRows] = React.useState<Data[]>([])

  const onClickBuscar = (search: string) => {
    resquestTable(page, orderBy, order, rowsPerPage, search, true);
  }

  const resquestTable = (
    pageRq: number,
    orderByRq: string,
    orderRq: string,
    rowsPerPageRq: number,
    search: any = null,
    block: boolean = false,
  ) => {
    httpClient
      .request({
        method: RequestMethod.POST,
        endpoint: '/Gerenciamento/Usuario/ListarAprovadores',
        blockFree: block,
        data: {
          Order: orderRq,
          OrderBy: orderByRq,
          Page: pageRq,
          RowsPerPage: rowsPerPageRq,
          Search: search
        },
        queryObject: {
          idDescricao: IdDescricao,
          idEmpresa: idEmpresa ?? 0,
          apenasSelecionados: true
        },
      })
      .then((result) => {
        const newRows = result.payload.data.map((data: Data) => {
          return createData(data.Id, data.Nome, data.Email, data.NomeCargo)
        })
        setRows([...newRows])
        setTotal(result.payload.total)
      })
  }

  React.useEffect(() => {
    if (AbrirModal) {
      obterDadosTabela()
    }
  }, [AbrirModal])

  const obterDadosTabela = () => {
    resquestTable(page, orderBy, order, rowsPerPage)
  }

  const handleRequestSort = (event: React.MouseEvent<unknown>, property: keyof Data) => {
    const isAsc = orderBy === property && order === 'asc'
    setOrder(isAsc ? 'desc' : 'asc')
    setOrderBy(property)
    resquestTable(page, property, isAsc ? 'desc' : 'asc', rowsPerPage)
  }

  const handleChangePage = (event: unknown, newPage: number) => {
    resquestTable(newPage, orderBy, order, rowsPerPage)
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    resquestTable(page, orderBy, order, parseInt(event.target.value, 10))
    setPage(0)
  }

  const emptyRows = rows.length

  return (
    <div>
      {/* begin::Content */}
      <div className=' py-5 row' >

        {/* begin::Input group */}
        <div className='mb-10 col-lg-6'>
          <input 
            type='text'
            onChange={e => onClickBuscar(e.target.value)} 
            placeholder='Pesquisar (Nome, E-mail ou CPF)' 
            className={`form-control form-control-lg form-control-solid`}
          ></input>
        </div>
        {/* end::Input group */}
      </div>
      {/* end::Content */}

      <Box sx={{width: '100%'}}>
        <Paper className='table-border ' sx={{width: '100%', mb: 2}}>
          <TableContainer>
            <Table className='table-header' sx={{minWidth: 750}} aria-labelledby='tableTitle'>
              <EnhancedTableHead
                order={order}
                orderBy={orderBy}
                onRequestSort={handleRequestSort}
              />
              <TableBody>
                {rows.map((row, index) => {
                  return (
                    <TableRow
                      className='table-row'
                      hover
                      tabIndex={-1}
                      key={index}
                      sx={{cursor: 'pointer'}}
                    >
                      <TableCell className='table-cell' align='left'>
                        {row.Nome}
                      </TableCell>
                      <TableCell className='table-cell' align='left'>
                        {row.Email}
                      </TableCell>
                      <TableCell className='table-cell' align='center'>
                        {row.NomeCargo}
                      </TableCell>
                    </TableRow>
                  )
                })}
                {emptyRows === 0 && (
                  <TableRow>
                    <TableCell className='table-cell text-center' colSpan={6}>
                      Nenhum registro encontrado
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            className='table-footer'
            labelRowsPerPage='linhas por página'
            rowsPerPageOptions={[10, 25]}
            component='div'
            count={total}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Paper>
      </Box>
    </div>
  )
}

export {TableAprovadores}
