/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import { IApresentarDimensaoModel, IApresentarDimensaoPerguntaModel, IWizardModel } from '../helpers/ResponderPesquisaHelper'
import { ErrorMessage, Field, FormikProps } from 'formik'
import { EnumPesquisaParticipanteStatus, EnumPesquisaStatus, EnumPesquisaTipoDisposicaoPergunta, RequestMethod } from '../../../../modules/services/core/_enums'
import { useHttpClient } from '../../../../modules/services/Bundle'

type Props = {
  formik: FormikProps<IWizardModel>,
  perguntas: IApresentarDimensaoPerguntaModel[] | undefined,
  dimensaoIndex?: number
}

const PesquisaPerguntas: React.FC<Props> = ({formik, perguntas, dimensaoIndex}) => {

  const httpClient = useHttpClient()

  const escala: any[] = [];
  if (formik.values.EscalaPesquisa) {
    for (let i = formik.values.EscalaPesquisa.Inicio; i <= formik.values.EscalaPesquisa.Fim; i++) {
      escala.push(
        <option key={i} value={i} label={i.toString()} />
      );
    }
  }

  return (
    <>
      {perguntas && perguntas.map((pergunta, perguntaIndex) => {
        const perguntaAtual = dimensaoIndex ? formik.values.Dimensoes[dimensaoIndex].Perguntas[perguntaIndex] : formik.values.Perguntas[perguntaIndex]; 
        const onClickInputRangeResposta = async (e: React.MouseEvent<HTMLInputElement>, idPergunta: number) => {
          
          let resposta: number | undefined = parseInt(e.currentTarget.value)

          const response = await httpClient.request({
            method: RequestMethod.GET,
            endpoint: '/Pesquisa/ResponderPesquisa/ResponderPergunta',
            queryObject: {
              chave: formik.values.Participante.Chave,
              resposta: resposta,
              idDimensaoPergunta: idPergunta
            },
            blockFree: true
          })
          
          let newValues = {...formik.values}

          if (response.success && response.payload) { 

            const dimensao: IApresentarDimensaoModel = response.payload

            const index = newValues.Dimensoes.findIndex(d => d.Id == dimensao.Id)

            newValues.Dimensoes[index].QtdPerguntas = dimensao.QtdPerguntas
            newValues.Dimensoes[index].QtdPerguntasRespondidas = dimensao.QtdPerguntasRespondidas
            newValues.Dimensoes[index].Porcentagem = dimensao.Porcentagem
            newValues.Dimensoes[index].TodasPerguntasRespondidas = dimensao.TodasPerguntasRespondidas

          }
          else resposta = undefined
          
          if (dimensaoIndex !== undefined) newValues.Dimensoes[dimensaoIndex].Perguntas[perguntaIndex].Resposta = resposta
          else newValues.Perguntas[perguntaIndex].Resposta = resposta

          formik.setValues(newValues)

        }

        const onClickInputRangeRespostaAB = async (e: React.MouseEvent<HTMLInputElement>, idPergunta: number) => {

          let resposta: number | undefined = parseInt(e.currentTarget.value)

          let newValues = {...formik.values}

          let eRespA = e.currentTarget.classList.contains('RespA')

          let respAntes = dimensaoIndex != undefined ? newValues.Dimensoes[dimensaoIndex].Perguntas[perguntaIndex].Resposta : newValues.Perguntas[perguntaIndex].Resposta;
          let respBAntes = dimensaoIndex != undefined ? newValues.Dimensoes[dimensaoIndex].Perguntas[perguntaIndex].RespostaB : newValues.Perguntas[perguntaIndex].RespostaB;

          if (dimensaoIndex !== undefined) {
            newValues.Dimensoes[dimensaoIndex].Perguntas[perguntaIndex].Resposta = eRespA ? resposta : Math.abs(resposta - 7);
            newValues.Dimensoes[dimensaoIndex].Perguntas[perguntaIndex].RespostaB = eRespA ? Math.abs(resposta - 7) : resposta
          }
          else {
            newValues.Perguntas[perguntaIndex].Resposta = eRespA ? resposta : Math.abs(resposta - 7);
            newValues.Perguntas[perguntaIndex].RespostaB = eRespA ? Math.abs(resposta - 7) : resposta
          }
          formik.setValues(newValues)

          const response = await httpClient.request({
            method: RequestMethod.GET,
            endpoint: '/Pesquisa/ResponderPesquisa/ResponderPergunta',
            queryObject: {
              chave: formik.values.Participante.Chave,
              resposta: eRespA ? resposta : Math.abs(resposta - 7),
              respostaB: eRespA ? Math.abs(resposta - 7) : resposta,
              idDimensaoPergunta: idPergunta
            },
            blockFree: true
          })
          
          if (response.success && response.payload) { 

            const dimensao: IApresentarDimensaoModel = response.payload

            const index = newValues.Dimensoes.findIndex(d => d.Id == dimensao.Id)

            newValues.Dimensoes[index].QtdPerguntas = dimensao.QtdPerguntas
            newValues.Dimensoes[index].QtdPerguntasRespondidas = dimensao.QtdPerguntasRespondidas
            newValues.Dimensoes[index].Porcentagem = dimensao.Porcentagem
            newValues.Dimensoes[index].TodasPerguntasRespondidas = dimensao.TodasPerguntasRespondidas

          }
          else {
            if (dimensaoIndex !== undefined) {
              newValues.Dimensoes[dimensaoIndex].Perguntas[perguntaIndex].Resposta = respAntes;
              newValues.Dimensoes[dimensaoIndex].Perguntas[perguntaIndex].RespostaB = respBAntes;
            }
            else {
              newValues.Perguntas[perguntaIndex].Resposta = respAntes
              newValues.Perguntas[perguntaIndex].RespostaB = respBAntes
            }
            formik.setValues(newValues)
          }

        }

        return (
            <div 
              key={perguntaIndex}
              className={`w-100 p-5 border border-secondary rounded ${perguntaIndex < (perguntas.length - 1) ? 'mb-5' : ''}`}
            >
              <textarea                  
                className={`form-control form-control-lg form-control-solid bg-light-success textarea-pergunta-pesquisa mb-5`}                                     
                value={pergunta.Texto}
                disabled={true}
              />
              {pergunta.QuestaoPergunta == null || pergunta.QuestaoPergunta.length == 0 ? (
                <>
                  <datalist className='pesquisa-datalist w-100' id={`input-range-pergunta-${pergunta.Id}`}>
                    {escala}
                  </datalist>
                  {dimensaoIndex !== undefined ? (
                    <>
                      <Field 
                        type="range"
                        min={formik.values.EscalaPesquisa ? formik.values.EscalaPesquisa.Inicio : 0}
                        max={formik.values.EscalaPesquisa ? formik.values.EscalaPesquisa.Fim : 10}
                        step="1"
                        name={`Dimensoes.${dimensaoIndex}.Perguntas.${perguntaIndex}.Resposta`}
                        className={`pesquisa-input-range ${formik.values.Dimensoes[dimensaoIndex].Perguntas[perguntaIndex].Resposta ? 'pesquisa-input-range-com-resposta' : 'pesquisa-input-range-sem-resposta'} form-range w-100`}
                        list={`input-range-pergunta-${pergunta.Id}`}
                        value={formik.values.Dimensoes[dimensaoIndex].Perguntas[perguntaIndex].Resposta ?? (formik.values.EscalaPesquisa ? formik.values.EscalaPesquisa.Inicio : 0)}
                        disabled={formik.values.Pesquisa.Status !== EnumPesquisaStatus.EmAndamento || formik.values.Participante.Status !== EnumPesquisaParticipanteStatus['Em andamento']}
                        onClick={(e: React.MouseEvent<HTMLInputElement>) => onClickInputRangeResposta(e, pergunta.Id)}
                      />
                      <div data-order={dimensaoIndex + perguntaIndex} className='formik-error-message text-danger mt-2'>
                        <ErrorMessage name={`Dimensoes.${dimensaoIndex}.Perguntas.${perguntaIndex}.Resposta`} render={msg => <div>É necessário escolher uma escala de {formik.values.EscalaPesquisa ? formik.values.EscalaPesquisa.Inicio : 0} a {formik.values.EscalaPesquisa ? formik.values.EscalaPesquisa.Fim : 10}! Caso sua resposta seja a atual, apenas clique sobre o círculo para confirmar sua escolha.</div>}/>
                      </div>
                    </>
                  ) : (
                    <>
                      <Field 
                        type="range"
                        min={formik.values.EscalaPesquisa ? formik.values.EscalaPesquisa.Inicio : 0}
                        max={formik.values.EscalaPesquisa ? formik.values.EscalaPesquisa.Fim : 10}
                        step="1"
                        name={`Perguntas.${perguntaIndex}.Resposta`}
                        className={`pesquisa-input-range ${formik.values.Perguntas[perguntaIndex].Resposta ? 'pesquisa-input-range-com-resposta' : 'pesquisa-input-range-sem-resposta'} form-range w-100`}
                        list={`input-range-pergunta-${pergunta.Id}`}
                        value={formik.values.Perguntas[perguntaIndex].Resposta ?? (formik.values.EscalaPesquisa ? formik.values.EscalaPesquisa.Inicio : 0)}
                        disabled={formik.values.Pesquisa.Status !== EnumPesquisaStatus.EmAndamento || formik.values.Participante.Status !== EnumPesquisaParticipanteStatus['Em andamento']}
                        onClick={(e: React.MouseEvent<HTMLInputElement>) => onClickInputRangeResposta(e, pergunta.Id)}
                      />
                      <div data-order={perguntaIndex} className='formik-error-message text-danger mt-2'>
                        <ErrorMessage name={`Perguntas.${perguntaIndex}.Resposta`} render={msg => <div>É necessário escolher uma escala de {formik.values.EscalaPesquisa ? formik.values.EscalaPesquisa.Inicio : 0} a {formik.values.EscalaPesquisa ? formik.values.EscalaPesquisa.Fim : 10}! Caso sua resposta seja a atual, apenas clique sobre o círculo para confirmar sua escolha.</div>}/>
                      </div>
                    </>
                  )}
                </>
              )
              :
              (
              <>
                {pergunta.QuestaoPergunta.map((questao, qIndex) => {
                    return(
                      <div key={`div-range-pergunta-${pergunta.Id}-questao-${questao.Id}`} className='ms-5'>
                        <textarea                  
                          className={`form-control form-control-lg form-control-solid bg-light-secondary textarea-pergunta-pesquisa mb-5`}                                     
                          value={`${qIndex == 0 ? "A" : "B"}) ${questao.ValorQuestao ?? ''}`}
                          disabled={true}
                        />
                        <datalist className='pesquisa-datalist w-100' id={`input-range-pergunta-${pergunta.Id}-questao-${questao.Id}`}>
                          <option value="0" label="0"></option>
                          <option value="1" label="1"></option>
                          <option value="2" label="2"></option>
                          <option value="3" label="3"></option>
                          <option value="4" label="4"></option>
                          <option value="5" label="5"></option>
                          <option value="6" label="6"></option>
                          <option value="7" label="7"></option>
                        </datalist>
                        {dimensaoIndex !== undefined ? (
                          <>
                            <Field 
                              type="range"
                              min="0"
                              max="7"
                              step="1"
                              name={`Dimensoes.${dimensaoIndex}.Perguntas.${perguntaIndex}.Resposta${qIndex > 0 ? "B" : ""}`}
                              className={`pesquisa-input-range ${qIndex == 0 ? (perguntaAtual.Resposta ? 'pesquisa-input-range-com-resposta RespA' : 'pesquisa-input-range-sem-resposta RespA') : (perguntaAtual.RespostaB ? 'pesquisa-input-range-com-resposta RespB' : 'pesquisa-input-range-sem-resposta RespB')} form-range w-100`}
                              list={`input-range-pergunta-${pergunta.Id}`}
                              defaultValue={(qIndex == 0 ? (perguntaAtual.Resposta ?? (`${Math.abs((perguntaAtual.RespostaB ?? 7) - 7)}`)) : (perguntaAtual.RespostaB ?? (`${Math.abs((perguntaAtual.Resposta ?? 7) - 7)}`))) ?? 0}
                              disabled={formik.values.Pesquisa.Status !== EnumPesquisaStatus.EmAndamento || formik.values.Participante.Status !== EnumPesquisaParticipanteStatus['Em andamento']}
                              onClick={(e: React.MouseEvent<HTMLInputElement>) => onClickInputRangeRespostaAB(e, pergunta.Id)}
                            />
                            <div data-order={dimensaoIndex + perguntaIndex} className='formik-error-message text-danger mt-2'>
                              <ErrorMessage name={`Dimensoes.${dimensaoIndex}.Perguntas.${perguntaIndex}.Resposta${qIndex > 0 ? "B" : ""}`} render={msg => <div>É necessário escolher um valor na escala de 0 a 7!</div>}/>
                            </div>
                          </>
                        ) : (
                          <>
                            <Field 
                              type="range"
                              min="0"
                              max="7"
                              step="1"
                              name={`Perguntas.${perguntaIndex}.Resposta${qIndex > 0 ? "B" : ""}`}
                              className={`pesquisa-input-range ${qIndex == 0 ? (perguntaAtual.Resposta ? 'pesquisa-input-range-com-resposta RespA' : 'pesquisa-input-range-sem-resposta RespA') : (perguntaAtual.RespostaB ? 'pesquisa-input-range-com-resposta RespB' : 'pesquisa-input-range-sem-resposta RespB')} form-range w-100`}
                              list={`input-range-pergunta-${pergunta.Id}`}
                              defaultValue={(qIndex == 0 ? (perguntaAtual.Resposta ?? (`${Math.abs((perguntaAtual.RespostaB ?? 7) - 7)}`)) : (perguntaAtual.RespostaB ?? (`${Math.abs((perguntaAtual.Resposta ?? 7) - 7)}`))) ?? 0}
                              disabled={formik.values.Pesquisa.Status !== EnumPesquisaStatus.EmAndamento || formik.values.Participante.Status !== EnumPesquisaParticipanteStatus['Em andamento']}
                              onClick={(e: React.MouseEvent<HTMLInputElement>) => onClickInputRangeRespostaAB(e, pergunta.Id)}
                            />
                            <div data-order={perguntaIndex} className='formik-error-message text-danger mt-2'>
                              <ErrorMessage name={`Perguntas.${perguntaIndex}.Resposta${qIndex > 0 ? "B" : ""}`} render={msg => <div>É necessário escolher um valor na escala de 0 a 7!</div>}/>
                            </div>
                          </>
                        )}
                      </div>
                    )
                })}
              </>
              )}    
            </div>
          )
        })
      }
    </>
  )
}

export {PesquisaPerguntas}
