import * as Yup from 'yup'


export interface IAreaModel {
  id?: number,
  nome: string,
  descricao: string
}

const defaultValidationSchemas = Yup.object().shape({
  nome: Yup.string().required('O nome é obrigatório')
})

const defaultArea: IAreaModel = {
  id: undefined,
  nome: '',
  descricao: ''
}

export {defaultValidationSchemas, defaultArea}