import { NumberFormatNotation } from '@formatjs/ecma402-abstract'
import * as Yup from 'yup'
import { EnumServicoTipoPlano } from '../../../../modules/services/core/_enums'

export interface IModuloDescricaoConfig {
  idServico: number
  ativo: boolean
  usaAprovacao: boolean
  Consultoria: boolean
  QtdMaximaCargos: number
}

const defaultModuloDescricaoConfig: IModuloDescricaoConfig = {
  idServico: 0,
	ativo: false,
  usaAprovacao: false,
  Consultoria: false,
  QtdMaximaCargos: 10
}

export interface IModuloPesquisaConfig {
  idServico: number
  ativo: boolean
  QtdMaximaTemas: number
  QtdMaximaPesquisas: number
  QtdMaximaParticipantes: number
}


const defaultModuloPesquisaConfig: IModuloPesquisaConfig = {
  idServico: 0,
	ativo: false,
  QtdMaximaTemas: 3,
  QtdMaximaPesquisas: 3,
  QtdMaximaParticipantes: 10
}

export interface IEmpresaParamsModel {  
  idEmpresa: number
  tipoPlano: number
  QtdMaximaUsuarios: number
  IsClienteConsultoriaDescricao: boolean
  PeriodoAcessoDataInicial: string
  PeriodoAcessoDataFinal: string
  AutoGerenciarPerfis: boolean
  limparDadosVersaoTry: boolean
  idUsuarioMaster?: number
  moduloDescricaoConfig: IModuloDescricaoConfig
  moduloPesquisaConfig: IModuloPesquisaConfig
  EmpresaDominio: []
}

const initValues: IEmpresaParamsModel = {
  idEmpresa: 0,
  tipoPlano: EnumServicoTipoPlano.Try,
  QtdMaximaUsuarios: 5,
  IsClienteConsultoriaDescricao: false,
  PeriodoAcessoDataInicial: '',
  PeriodoAcessoDataFinal: '',
  AutoGerenciarPerfis: false,
  limparDadosVersaoTry: false,
  idUsuarioMaster: undefined,
  moduloDescricaoConfig: defaultModuloDescricaoConfig,
  moduloPesquisaConfig: defaultModuloPesquisaConfig,
  EmpresaDominio: []
}

const defaultValidationSchemas = Yup.object().shape({
    QtdMaximaUsuarios: Yup.string().required('A quantidade máxima de usuários é obrigatória'),  
    PeriodoAcessoDataInicial: Yup.date()
    .required("O período inicial é obrigatório")
    .typeError("O período inicial é obrigatório")
    .nullable(),
    PeriodoAcessoDataFinal: Yup.date()
    .when(
      "PeriodoAcessoDataInicial",
      (PeriodoAcessoDataInicial, schema) => PeriodoAcessoDataInicial 
      && schema != null && schema.min(PeriodoAcessoDataInicial, 'Data final deve ser maior que a data inicial')
    )
    .required("O período final é obrigatório")
    .typeError("O período final é obrigatório")
    .nullable(),
    moduloDescricaoConfig: Yup.object().shape({
      QtdMaximaCargos: Yup.string().required('A quantidade máxima de cargos é obrigatória'),
    }),
    moduloPesquisaConfig: Yup.object().shape({
      QtdMaximaTemas: Yup.string().required('A quantidade máxima de temas é obrigatória'),
      QtdMaximaPesquisas: Yup.string().required('A quantidade máxima de pesquisas é obrigatória'),
      QtdMaximaParticipantes: Yup.string().required('A quantidade máxima de participantes é obrigatória'),
    }),
    idUsuarioMaster: Yup.string().nullable().required('O usuário master é obrigatório'),
    EmpresaDominio: Yup.array().min(1, 'Deve ter pelo menos um domínio')
  })

export {defaultValidationSchemas, initValues, defaultModuloDescricaoConfig, defaultModuloPesquisaConfig}
