import {useEffect, useState} from 'react'
import {useHttpClient} from '../../../modules/services/Bundle'
import {RequestMethod} from '../../../modules/services/core/_enums'
import {Field, FormikProvider, useFormik} from 'formik'
import {
  IDescreverDescricaoModel,
  descreverDescricao,
  descreverDescricaoValidation,
} from './helpers/DescreverHelper'
import {useLocation} from 'react-router-dom'
import {VisualizarCampos} from './partials/VisualizarCampos'
import $ from 'jquery'

const VisualizarDescricao = () => {
  const httpClient = useHttpClient()
  const [cargoNome, setCargoNome] = useState('')
  const [modeloNome, setModeloNome] = useState('')
  const formik = useFormik({
    initialValues: descreverDescricao,
    validationSchema: descreverDescricaoValidation,
    onSubmit: (values, actions) => {},
  })

  const autoSizeTextArea = () => {
    $('.textarea-resposta-descricao').each(function (i, element) {
      let target = element as HTMLTextAreaElement
      target.style.height = '90px'

      if (target.scrollHeight && target.scrollHeight > 90)
        target.style.height = target.scrollHeight + 'px'
    })
  }

  useEffect(() => {
    autoSizeTextArea()
  }, [formik])

  interface dados {
    idDescricao: number
  }
  const location = useLocation()
  const state = location.state as dados
  const informacao: dados = state

  useEffect(() => {
    if (informacao) {
      httpClient
        .request({
          method: RequestMethod.GET,
          endpoint: '/DescricaoDeCargo/Descrito/VisualizarDescricao',
          queryObject: {
            idDescricao: informacao.idDescricao,
          },
        })
        .then((response) => {
          if (response.success && response.payload) {
            const descricao: IDescreverDescricaoModel = response.payload
            formik.setFieldValue('Id', descricao.Id)
            formik.setFieldValue('StatusAprovacao', descricao.StatusAprovacao)
            formik.setFieldValue('NomeModelo', descricao.NomeModelo)
            formik.setFieldValue('NomeCargo', descricao.NomeCargo)
            formik.setFieldValue('IdModelo', descricao.IdModelo)
            setCargoNome(descricao.NomeCargo)
            setModeloNome(descricao.NomeModelo)
            formik.setFieldValue('NomeNivelHierarquico', descricao.NomeNivelHierarquico)
            formik.setFieldValue('NomeArea', descricao.NomeArea)
            formik.setFieldValue('Campos', descricao.Campos)
          }
        })
    }
  }, [])

  return (
    <>
      <div className='card mb-5 mb-xl-10'>
        <div className='card-header'>
          <div className='card-title m-0'>
            <h3 className='fw-bolder m-0'>Visualizar Descrição</h3>
          </div>
        </div>

        <div className='card-body p-9'>
          <FormikProvider value={formik}>
            <form noValidate onSubmit={formik.handleSubmit}>
              <div className={`row mb-10`}>
                <div className='col-lg-6 fv-row'>
                  <label className='form-label'>Cargo</label>
                  <Field
                    disabled
                    as='select'
                    name='IdCargo'
                    className='form-select form-select-lg form-select-solid'
                    value={formik.values.IdCargo}
                    select={formik.values.IdCargo}
                  >
                    <option value=''>{cargoNome}</option>
                  </Field>
                </div>

                <div className='col-lg-6 fv-row'>
                  <label className='form-label'>Modelo do formulário</label>
                  <Field
                    disabled
                    as='select'
                    name='IdModelo'
                    className='form-select form-select-lg form-select-solid'
                    value={formik.values.IdModelo}
                    select={formik.values.IdModelo}
                  >
                    <option value=''>{modeloNome}</option>
                  </Field>
                </div>
              </div>

              <div className='row'>
                <div className='col-lg-12'>
                  <VisualizarCampos formik={formik} />
                </div>
              </div>
            </form>
          </FormikProvider>
        </div>
      </div>
    </>
  )
}

export {VisualizarDescricao}
