export enum RequestMethod {
  GET = 'GET',
  POST = 'POST',
  PUT = 'PUT',
  DELETE = 'DELETE',
}

export enum EnumDescricaoStatusAprovacao {
  ARevisar = 1,
  AAvaliar = 2,
  AAprovar = 3,
  Reprovada = 4,
  Aprovada = 5,
}

export enum EnumStepsConfigInicial {
  'Empresa' = 0,
  'Modelo' = 1,
  'Níveis hierárquicos' = 2,
  'Áreas' = 3,
  'Cargos' = 4,
  'Finalizar' = 5,
}

export enum EnumStepsConfigInicialPesquisa {
  'Empresa' = 0,
  'Tema' = 1,
  'Dimensões' = 2,
  'Perguntas' = 3,
  'Revisão' = 4,
}

export enum EnumStepsPesquisa {
  'Identificação' = 0,
  'Disposição' = 1,
  'Participantes' = 2,
  'Definir Períodos' = 3,
  'Revisar e Enviar' = 4,
}

export enum EnumStepsResponderPesquisa {
  'Iniciar a pesquisa' = 0,
  'Responder a pesquia' = 1,
}

export enum EnumPesquisaTipoResposta {
  Topico = 1,
  Dimensao = 2,
  Pergunta = 3,
}

export enum EnumServicoTipoPlano {
  Try = 1,
  Buy = 2,
}

export enum EnumUploadTipo {
  "Cargo" = 1,
  "Colaborador" = 2,
  "Descrição De Cargo" = 3,
}

export enum EnumProcessoDescricaoTipo {
  'Gerar Descrições' = 1,
  'Gerar Respostas Para Campos Em Branco' = 2,
  'Gerar Respostas Para Campos Adicionados' = 3,
}

export enum EnumDownloadTipo {
  "Relatório Geral" = 1,
}

export enum EnumTaskStatus {
  "Processando" = 1,
  "Incompleto" = 2,
  "Completo" = 3,
  "Erro" = 4,
}

export enum EnumPromptTipo {
  GerarDescricoes = 1,
  AdicionarResposta = 2,
  AdicionarArgumento = 3,
  RemoverArgumento = 4,
  AprimorarRespostaAdicionando = 5,
  AprimorarRespostaRemovendo = 6,
  ObterAcoesExistentes = 7,
  ObterAcoesSugeridas = 8,
  GerarTopicos = 9,
  GerarDimensoes = 10,
  GerarDimensoesPerguntas = 11,
  AdicionarTopico = 12,
  AdicionarDimensao = 13,
  AdicionarDimensaoPergunta = 14,
}

export enum EnumModulo {
  Administracao = 1,
  Ajuda = 2,
  Configuracao = 3,
  DescricaoDeCargo = 4,
  Pesquisa = 5,
}

export enum EnumPesquisaTipoDisposicaoPergunta {
  AgruparPorDimensao = 1,
  OrdenarAleatoriamente = 2,
}

export enum EnumPesquisaStatus {
  NaoLiberada = 1,
  NaoIniciada = 2,
  EmAndamento = 3,
  Suspensa = 4,
  Finalizada = 5,
}

export enum EnumPesquisaParticipanteStatus {
  "Não iniciada" = 1,
  "Em andamento" = 2,
  "Finalizada" = 3,
}

export enum EnumPesquisaParticipanteStatusDeAlcance {
  "Não visualizada" = 1,
  "Visualizada e não respondida" = 2,
  "Em andamento" = 3,
  "Finalizada" = 4,
}

export enum EnumPesquisaGraficoDispersaoTipo {
  "Detratores" = 1,
  "Neutros" = 2,
  "Promotores" = 3,
}

export enum EnumPorteEmpresa
{   
  "Microempreendedor Individual (MEI)" = 1,
  "Microempresa (ME)" = 2,
  "Empresa de Pequeno Porte (EPP)" = 3,
  "Empresa de Médio Porte" = 4,
  "Grande Empresa" = 5,
}

export enum EnumStepsTema {
  'Tema' = 0,
  'Dimensões' = 1,
  'Perguntas' = 2,
  'Revisão' = 3,
}

export enum EnumTipoEnviarAprovacao {
  Unica = 1,
  Multipla = 2,
}

export enum EnumTipoEnviarAvaliacao {
  Unica = 1,
  Multipla = 2,
}

export enum EnumModalAvalidorAprovador {
  Avaliador = 1,
  Aprovador = 2,
}