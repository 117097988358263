import Chart from 'react-apexcharts'
import { IDadosGraficosParticipanteModel, IDadosGraficosPesquisaModel, IPesquisaParticipanteModel } from '../helpers/AnaliseResultadoHelper'
import { ISelectModel } from '../../../../modules/services/core/_models'
import { useEffect, useState } from 'react'
import { KTSVG } from '../../../../../_metronic/helpers'
import { RequestMethod } from '../../../../modules/services/core/_enums'
import { useHttpClient } from '../../../../modules/services/Bundle'
import { ModalGraficoMediaGeralPorParticipante } from './ModalGraficoMediaGeralPorParticipante'

type Props = {
  isMobile: boolean
  dadosGraficosPesquisa: IDadosGraficosPesquisaModel | undefined
  idParticipanteProprio?: string
}

type PropsGraficoMediaGeralDoParticipante = {
  dadosGraficosParticipante: IDadosGraficosParticipanteModel | undefined
}

const GraficoMediaGeralDoParticipante  = ({dadosGraficosParticipante}:PropsGraficoMediaGeralDoParticipante) => {

  let options = {              
    labels: dadosGraficosParticipante?.GraficoMediaGeral.Labels ?? [],
    series: dadosGraficosParticipante?.GraficoMediaGeral.Series ?? [],
    colors: dadosGraficosParticipante?.GraficoMediaGeral.Colors ?? [],
    plotOptions: {
      radialBar: {
        startAngle: -180,
        endAngle: 180,
        track: {
          background: '#f4f4f4',
          startAngle: -180,
          endAngle: 180,
        },
        dataLabels: {
          name: {
            show: false,
          },
          value: {
            fontSize: '30px',
            color: '#3e875f',
            show: true,
            formatter: function (val: number) {
              const escalaMaxima = dadosGraficosParticipante?.Escala.Fim ?? 10;
              const valorAjustado = ((val * escalaMaxima) / 100).toFixed(2);
              return valorAjustado;
            }
          },
        }
      }
    },
  }

  return (
    <div className='col-lg-12 mt-5'>
      <Chart options={options} series={options.series} type="radialBar" height={'400px'} />   
    </div>  
  )
}

const GraficoMediaGeralPorParticipante  = ({isMobile, dadosGraficosPesquisa, idParticipanteProprio}:Props) => {
  const httpClient = useHttpClient()
  let [showModal, setShowModal] = useState<boolean>(false)
  let [idDimensao, setIdDimensao] = useState<number>(0)
  let [dadosGraficosParticipante, setDadosGraficosParticipante] = useState<IDadosGraficosParticipanteModel | undefined>(undefined)

  const onChangeSelectParticipante = async (e: React.ChangeEvent<HTMLSelectElement>) => {
    
    let idParticipante = parseInt(e.target.value)

    if (idParticipante > 0){
      await obterDadosParticipante(idParticipante)
    }
    else setDadosGraficosParticipante(undefined)
  }

  const onClickGraficoMediaPorDimensaoParticipante = async (idDimensao: number) => {    
    setIdDimensao(idDimensao)
    setShowModal(true)
  }

  const obterDadosParticipante = async (idParticipante: any) =>{
    const response = await httpClient.request({
      method: RequestMethod.GET,
      endpoint: '/Pesquisa/Resultado/ObterDadosGraficosParticipante',
      queryObject: {
        idParticipante: idParticipante
      }
    })

    if (response.success && response.payload) {
      setDadosGraficosParticipante(response.payload)
    }
  }

  useEffect(() => {
    if (idParticipanteProprio && idParticipanteProprio !== "") obterDadosParticipante(idParticipanteProprio)
  }, [idParticipanteProprio])

  return (
    <>
      <ModalGraficoMediaGeralPorParticipante 
        showModal={showModal}
        setShowModal={setShowModal}
        isMobile={isMobile}
        idDimensao={idDimensao}
        dadosGraficosParticipante={dadosGraficosParticipante}
      />

      <div className={`row gy-5 mb-10`}>
        <div className='col-lg-4'>        
          <div className='card h-100'>
            <div
              className='card-header'
            >
              <div className='card-title m-0'>
                <h3 className='fw-bolder m-0'>Média geral por participante</h3>
              </div>
            </div>
            <div className='card-body'>
              <div className='row'>
                {!idParticipanteProprio && (
                  <div className='col-lg-12'>
                    <label className={`form-label text-gray-800`}>Selecione o participante</label>
                    <select
                      className='form-select form-select-lg form-select-solid'
                      onChange={(e: React.ChangeEvent<HTMLSelectElement>) => onChangeSelectParticipante(e)}
                    >
                      <option value='0'>{'Selecione...'}</option>

                      {dadosGraficosPesquisa && dadosGraficosPesquisa.Participantes && dadosGraficosPesquisa.Participantes.length > 0
                        && dadosGraficosPesquisa.Participantes.map((data: IPesquisaParticipanteModel, index: number) => {
                          return (
                            <option key={`select-participante-${index}`} value={data.Id}>
                              {`${data.Nome} (${data.Email})`}
                            </option>
                          )
                        })
                      }
                      
                    </select>
                  </div>
                )}

                {dadosGraficosParticipante && (
                  <GraficoMediaGeralDoParticipante
                    dadosGraficosParticipante={dadosGraficosParticipante}
                  />
                )}                    
              </div>
            </div>        
          </div>   
        </div>
        <div className='col-lg-8'>        
          <div className='card h-100'>
            <div
              className='card-header'
            >
              <div className='card-title m-0'>
                <h3 className='fw-bolder m-0'>Média do participante por dimensão</h3>
              </div>
            </div>
            <div className='card-body d-flex align-items-center'>
              {dadosGraficosParticipante ? (

                <div className='row justify-content-center mt-10'>
                  {dadosGraficosParticipante && dadosGraficosParticipante.GraficoMediaPorDimensao && dadosGraficosParticipante.GraficoMediaPorDimensao.length > 0 && dadosGraficosParticipante.GraficoMediaPorDimensao.map((data, index) => {
                    
                    let valuePercent = data.Series[0]
                    const escalaMaxima = dadosGraficosParticipante?.Escala.Fim ?? 10;
                    let value = ((valuePercent * escalaMaxima) / 100).toFixed(2);
                    let valueColor = '#3e875f'
                    let progressBarBackgroundColor = '#f4f4f4'
                    let progressBarColor = data.Colors[0]

                    return (
                      <div key={`grafico-media-por-dimensao-participante-${index}`} className='col-lg-6'>
                        <div 
                          className='grafico-media-por-dimensao-participante border border-1 border-success rounded p-5 h-75px mb-10'
                          onClick={() => onClickGraficoMediaPorDimensaoParticipante(data.IdDimensao)}
                        >
                          <div className='d-flex justify-content-between text-success fw-bold mb-2'>
                            <span>{data.Labels[0]}</span>
                            <span style={{color: valueColor}}>{value}</span>
                          </div>

                          <div className='h-8px rounded w-100' style={{backgroundColor: progressBarBackgroundColor}}>
                            <div
                              className='h-8px rounded'
                              role='progressbar'
                              style={{
                                width: valuePercent + '%',
                                backgroundColor: progressBarColor
                              }}
                              aria-valuenow={50}
                              aria-valuemin={0}
                              aria-valuemax={100}
                            ></div>
                          </div>
                        </div>
                      </div>  
                    )
                  })}        
                </div>

              ) : (
                <div className='notice d-flex bg-light-warning rounded border-warning border border-dashed p-6 w-100'>
                  <KTSVG
                    path='/media/icons/duotune/general/gen044.svg'
                    className='svg-icon-2tx svg-icon-warning me-4'
                  />
                  <div className='d-flex flex-stack flex-grow-1'>
                    <div className='fw-bold'>
                      <h4 className='text-gray-800 fw-bolder'>Nenhum participante selecionado!</h4>
                      <div className='fs-6 text-gray-600'>
                        É necessário selecionar um participante para visualizar suas médias por dimensão.
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>        
          </div>   
        </div>
      </div>    
    </>   
  )
}
  
export {GraficoMediaGeralPorParticipante}