import * as Yup from 'yup'
import { FileModel } from '../../../../modules/services/core/_models'

export interface ICarregarCargosModel {
  Arquivo: FileModel | undefined
  IdModelo?: number
}

const defaultValidationSchemas = Yup.object().shape({
  Arquivo: Yup.object().required('O arquivo do modelo é obrigatório'),
})

const modeloValidationSchema = Yup.object().shape({
  Arquivo: Yup.object().required('O arquivo do modelo é obrigatório'),
  IdModelo: Yup.string().required('O modelo de formulário é obrigatório')
})

const defaultCarregarCargos: ICarregarCargosModel = {
  Arquivo: undefined,
  IdModelo: undefined
}

export {defaultValidationSchemas, defaultCarregarCargos, modeloValidationSchema}