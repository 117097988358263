/* eslint-disable react-hooks/exhaustive-deps */
import * as React from 'react'
import Box from '@mui/material/Box'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TablePagination from '@mui/material/TablePagination'
import TableRow from '@mui/material/TableRow'
import TableSortLabel from '@mui/material/TableSortLabel'
import Paper from '@mui/material/Paper'
import {visuallyHidden} from '@mui/utils'
import {useHttpClient} from '../../../../modules/services/Bundle'
import {RequestMethod} from '../../../../modules/services/core/_enums'

interface Column {
  id: 'NomeCargo' | 'NomeUsuario' | 'Data' | 'Atividade'
  label: string
  minWidth?: number
  align?: 'center'
  format?: (value: number) => string
}

const columns: readonly Column[] = [
  {
    id: 'NomeCargo',
    label: 'CARGO',
  },
  {
    id: 'NomeUsuario',
    label: 'USUÁRIO',
  },
  {
    id: 'Data',
    label: 'DATA',
  },
  {
    id: 'Atividade',
    label: 'ATIVIDADE',
  },
]

interface Data {
  Id: number
  NomeUsuario: string
  NomeCargo: string
  IdUsuario: number
  IdDescricao: number
  Data: string
  Tipo: number
  Atividade: string
  Usuario: any
  Descricao: any
}

function createData(
  Id: number,
  NomeUsuario: string,
  NomeCargo: string,
  IdUsuario: number,
  IdDescricao: number,
  Data: string,
  Tipo: number,
  Atividade: string,
  Usuario: any,
  Descricao: any
): Data {
  return {
    Id,
    NomeUsuario,
    NomeCargo,
    IdUsuario,
    IdDescricao,
    Data,
    Tipo,
    Atividade,
    Usuario,
    Descricao,
  }
}

type Order = 'asc' | 'desc'

interface EnhancedTableProps {
  numSelected: number
  onRequestSort: (event: React.MouseEvent<unknown>, property: keyof Data) => void
  order: Order
  orderBy: string
}

const TableHistorico: React.FC = () => {
  const httpClient = useHttpClient()
  const [order, setOrder] = React.useState<Order>('desc')
  const [orderBy, setOrderBy] = React.useState<keyof Data>('Data')
  const [selected, setSelected] = React.useState<readonly string[]>([])
  const [page, setPage] = React.useState(0)
  const [rowsPerPage, setRowsPerPage] = React.useState(10)
  const [total, setTotal] = React.useState(0)
  const [rows, setRows] = React.useState<Data[]>([])

  function EnhancedTableHead(props: EnhancedTableProps) {
    const {order, orderBy, onRequestSort} = props
    const createSortHandler = (property: keyof Data) => (event: React.MouseEvent<unknown>) => {
      onRequestSort(event, property)
    }

    return (
      <TableHead>
        <TableRow className='table-header'>
          {columns.map((columns) => (
            <TableCell
              className='table-cell'
              key={columns.id}
              align={columns.align ? 'right' : 'left'}
            >
              <TableSortLabel
                className='fw-bolder'
                active={orderBy === columns.id}
                direction={orderBy === columns.id ? order : 'asc'}
                onClick={createSortHandler(columns.id)}
              >
                {columns.label}
                {orderBy === columns.id ? (
                  <Box component='span' sx={visuallyHidden}>
                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                  </Box>
                ) : null}
              </TableSortLabel>
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
    )
  }

  const resquestTable = (
    pageRq: number,
    orderByRq: string,
    orderRq: string,
    rowsPerPageRq: number,
    Filtros: any = null
  ) => {
    httpClient
      .request({
        method: RequestMethod.POST,
        endpoint: '/DescricaoDeCargo/Dashboard/ObterHistoricoDescricao',
        data: {
          Order: orderRq,
          OrderBy: orderByRq,
          Page: pageRq,
          RowsPerPage: rowsPerPage,
          Filters: Filtros,
        },
      })
      .then((result) => {
        const newRows = result.payload.data.map((data: Data) => {
          return createData(
            data.Id,
            data.NomeUsuario,
            data.NomeCargo,
            data.IdUsuario,
            data.IdDescricao,
            data.Data,
            data.Tipo,
            data.Atividade,
            data.Usuario,
            data.Descricao
          )
        })
        setRows([...newRows])
        setTotal(result.payload.total)
      })
  }

  React.useEffect(() => {
    resquestTable(page, orderBy, order, rowsPerPage)
  }, [])

  const handleRequestSort = (event: React.MouseEvent<unknown>, property: keyof Data) => {
    const isAsc = orderBy === property && order === 'asc'
    setOrder(isAsc ? 'desc' : 'asc')
    setOrderBy(property)
    resquestTable(page, property, isAsc ? 'desc' : 'asc', rowsPerPage)
  }

  const handleChangePage = (event: unknown, newPage: number) => {
    resquestTable(newPage, orderBy, order, rowsPerPage)
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    resquestTable(page, orderBy, order, parseInt(event.target.value, 10))
    setPage(0)
  }

  const emptyRows = rows.length

  return (
    <div className='card mb-5 mb-xl-10 mt-10'>
      <div
        className='card-header border-0 cursor-pointer'
        role='button'
        data-bs-toggle='collapse'
        data-bs-target='#kt_account_table_relatorio'
        aria-expanded='true'
        aria-controls='kt_account_table_relatorio'
      >
        <div className='card-title m-0'>
          <h3 className='fw-bolder m-0'>Histórico das Descrições</h3>
        </div>
      </div>

      <div id='kt_account_table_relatorio' className='collapse show'>
        <form noValidate className='form'>
          <div className='card-body border-top p-9'>
            <Paper className='table-border ' sx={{width: '100%', mb: 2}}>
              <TableContainer>
                <Table sx={{minWidth: 750}} aria-labelledby='tableTitle'>
                  <EnhancedTableHead
                    numSelected={selected.length}
                    order={order}
                    orderBy={orderBy}
                    onRequestSort={handleRequestSort}
                  />
                  <TableBody>
                    {rows.map((row, index) => {
                      return (
                        <TableRow
                          className='table-row'
                          hover
                          tabIndex={-1}
                          key={index}
                          sx={{cursor: 'pointer'}}
                        >
                          <TableCell className='table-cell' align='left'>
                            {row.NomeCargo}
                          </TableCell>
                          <TableCell className='table-cell' align='left'>
                            {row.NomeUsuario}
                          </TableCell>
                          <TableCell className='table-cell' align='left'>
                            {row.Data}
                          </TableCell>
                          <TableCell className='table-cell' align='left'>
                            {row.Atividade}
                          </TableCell>
                        </TableRow>
                      )
                    })}
                    {emptyRows === 0 && (
                      <TableRow>
                        <TableCell className='table-cell bg-body text-center' colSpan={6}>
                          Nenhum registro encontrado
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                className='table-footer'
                labelRowsPerPage='linhas por página'
                rowsPerPageOptions={[10, 25]}
                component='div'
                count={total}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </Paper>
          </div>
        </form>
      </div>
    </div>
  )
}

export {TableHistorico}
