import {ErrorMessage, Field, FormikProvider, useFormik} from 'formik'
import {useEffect, useRef, useState} from 'react'
import {Link, useLocation, useNavigate} from 'react-router-dom'
import {useAlert, useHttpClient} from '../../../modules/services/Bundle'
import {EnumServicoTipoPlano, RequestMethod} from '../../../modules/services/core/_enums'
import {
  defaultModuloDescricaoConfig,
  defaultModuloPesquisaConfig,
  defaultValidationSchemas,
  initValues,
} from './helpers/EmpresaParamsHelper'
import {AdapterDayjs} from '@mui/x-date-pickers/AdapterDayjs'
import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider'
import {DatePicker} from '@mui/x-date-pickers/DatePicker'
import {ptBR} from '@mui/x-date-pickers/locales'
import br from 'dayjs/locale/pt-br'
import dayjs from 'dayjs'
import {KTSVG} from '../../../../_metronic/helpers'
import {ModalAddUsuario} from './partials/ModalAddUsuario'
import { MultipleSelect } from '../../descricao/components/partials/ModalAddCliente'
interface dados {
  Id: number
}

const Parametrizar = () => {
  const location = useLocation()
  const state = location.state as dados
  const informacao: dados = state
  const navigate = useNavigate()
  const httpClient = useHttpClient()
  const [buyAtivo, setBuyAtivo] = useState(false)
  const [tipoPlanoBuy, setTipoPlanoBuy] = useState(false)
  const datePickerRef = useRef()
  const alert = useAlert()
  let [usuarios, setUsuarios] = useState<any[]>([])
  let [abrirModal, setAbrirModal] = useState(false)
  const [selectedOptions, setSelectedOptions] = useState<string[]>([])

  const formik = useFormik({
    initialValues: {...initValues},
    validationSchema: defaultValidationSchemas,
    onSubmit: async (values, actions) => {
      alert.createDialog({
        html: `Realmente deseja alterar os parâmetros desta empresa?
        ${
          values.limparDadosVersaoTry
            ? `
        <div class='mt-5'>
          <div class="alert alert-danger d-flex align-items-center p-5">
            
            <div class="d-flex flex-column">
              <h5 class="mb-1">Atenção</h5>
              <span>Todos os dados desta empresa serão deletados permanentemente!</span>
            </div>
          </div>
        </div>`
            : ''
        }`,
        confirmAction: async () => {
          const response = await httpClient.request({
            method: RequestMethod.PUT,
            endpoint: '/Administracao/ParametrizarEmpresa/EditarParamsEmpresa',
            data: values,
            ifSuccessRedirectTo: '/administracao/empresas',
          })
        },
      })
    },
  })

  const onChangeCheckConsultoria = (e: any) => {
    const valor = e.target.value

    if (valor.toString() === 'false') {
      formik.setFieldValue('moduloDescricaoConfig.Consultoria', true)
    } else {
      formik.setFieldValue('moduloDescricaoConfig.Consultoria', false)
    }
  }

  const onChangeCheckTipoPlano = (e: any) => {
    const valor = e.target.value

    if (valor.toString() === 'false') {
      formik.setFieldValue('tipoPlano', EnumServicoTipoPlano.Buy)
      setTipoPlanoBuy(true)
    } else {
      formik.setFieldValue('tipoPlano', EnumServicoTipoPlano.Try)
      setTipoPlanoBuy(false)
    }
  }

  const onChangeAtivarDescricaoDeCargo = (e: any) => {
    const valor = e.target.value

    if (valor.toString() === 'true') {
      formik.setFieldValue(
        'moduloDescricaoConfig.QtdMaximaCargos',
        defaultModuloDescricaoConfig.QtdMaximaCargos
      )
      formik.setFieldValue(
        'moduloDescricaoConfig.usaAprovacao',
        defaultModuloDescricaoConfig.usaAprovacao
      )
      formik.setFieldValue('moduloDescricaoConfig.ativo', defaultModuloDescricaoConfig.ativo)
    } else formik.setFieldValue('moduloDescricaoConfig.ativo', true)
  }

  const onChangeAtivarPesquisa = (e: any) => {
    const valor = e.target.value

    if (valor.toString() === 'true') {
      formik.setFieldValue(
        'moduloPesquisaConfig.QtdMaximaTemas',
        defaultModuloPesquisaConfig.QtdMaximaTemas
      )
      formik.setFieldValue(
        'moduloPesquisaConfig.QtdMaximaPesquisas',
        defaultModuloPesquisaConfig.QtdMaximaPesquisas
      )
      formik.setFieldValue(
        'moduloPesquisaConfig.QtdMaximaParticipantes',
        defaultModuloPesquisaConfig.QtdMaximaParticipantes
      )
      formik.setFieldValue('moduloPesquisaConfig.ativo', defaultModuloPesquisaConfig.ativo)
    } else formik.setFieldValue('moduloPesquisaConfig.ativo', true)
  }

  const onChangeUsaAprovacaoDescricaoDeCargo = (e: any) => {
    const valor = e.target.value

    if (valor.toString() === 'true')
      formik.setFieldValue('moduloDescricaoConfig.usaAprovacao', false)
    else formik.setFieldValue('moduloDescricaoConfig.usaAprovacao', true)
  }

  const onChangeAutoGerenciarPerfis = (e: any) => {
    const valor = e.target.value

    if (valor.toString() === 'true') formik.setFieldValue('AutoGerenciarPerfis', false)
    else formik.setFieldValue('AutoGerenciarPerfis', true)
  }

  const onChangeLimparDadosVersaoTry = (e: any) => {
    const valor = e.target.value

    if (valor.toString() === 'true') {
      formik.setFieldValue('limparDadosVersaoTry', false)
      buscarUsuariosSelect()
    } else {
      formik.setFieldValue('limparDadosVersaoTry', true)
    }
  }

  const onChangeDatePickerStart = (e: any) => {
    if (e && dayjs(e.$d).toString() !== 'Invalid Date') {
      formik.setFieldValue('PeriodoAcessoDataInicial', dayjs(e.$d).format('YYYY-MM-DD'))
    } else if (e === null) {
      formik.setFieldValue('PeriodoAcessoDataInicial', '')
    }
  }

  const onChangeDatePickerEnd = (e: any) => {
    if (e && dayjs(e.$d).toString() !== 'Invalid Date')
      formik.setFieldValue('PeriodoAcessoDataFinal', dayjs(e.$d).format('YYYY-MM-DD'))
    else if (e === null) formik.setFieldValue('PeriodoAcessoDataFinal', '')
  }

  const requestDados = async () => {
    const response = await httpClient.request({
      method: RequestMethod.GET,
      endpoint: '/Administracao/ParametrizarEmpresa/ObterParamsEmpresa',
      queryObject: {
        IdEmpresa: informacao.Id,
      },
    })
    if (response.success && response.payload) {
      if (response.payload.tipoPlano === EnumServicoTipoPlano.Buy) {
        setTipoPlanoBuy(true)
        setBuyAtivo(true)
      }

      if (response.payload.tipoPlano === EnumServicoTipoPlano.Try){
        setTipoPlanoBuy(false)
        setBuyAtivo(false)
      }
      formik.setFieldValue('idEmpresa', response.payload.idEmpresa)
      formik.setFieldValue('idUsuarioMaster', response.payload.idUsuarioMaster)
      formik.setFieldValue('tipoPlano', response.payload.tipoPlano)
      formik.setFieldValue('AutoGerenciarPerfis', response.payload.AutoGerenciarPerfis)
      formik.setFieldValue('QtdMaximaUsuarios', response.payload.QtdMaximaUsuarios)
      formik.setFieldValue('EmpresaDominio', response.payload.EmpresaDominio)
      formik.setFieldValue('IsClienteConsultoriaDescricao', response.payload.clienteConsultoriaDescricao !== null && response.payload.clienteConsultoriaDescricao !== undefined)

      if (response.payload.PeriodoAcessoDataInicial !== '')
        formik.setFieldValue(
          'PeriodoAcessoDataInicial',
          dayjs(response.payload.PeriodoAcessoDataInicial)
        )
      if (response.payload.PeriodoAcessoDataFinal !== '')
        formik.setFieldValue(
          'PeriodoAcessoDataFinal',
          dayjs(response.payload.PeriodoAcessoDataFinal)
        )

      if (response.payload.moduloDescricaoConfig) formik.setFieldValue('moduloDescricaoConfig', response.payload.moduloDescricaoConfig)
      
      if (response.payload.moduloPesquisaConfig) formik.setFieldValue('moduloPesquisaConfig', response.payload.moduloPesquisaConfig)

      setSelectedOptions(response.payload.EmpresaDominio)
    }
  }

  const buscarUsuariosSelect = async () => {
    const response = await httpClient.request({
      method: RequestMethod.GET,
      endpoint: '/Administracao/ParametrizarEmpresa/ObterUsuariosEmpresa',
      queryObject: {
        IdEmpresa: informacao.Id,
      },
    })
    if (response.success && response.payload && response.payload.length > 0) {
      setUsuarios(response.payload)
    }
  }

  useEffect(() => {
    if (informacao && informacao.Id) {
      requestDados()
      buscarUsuariosSelect()
    }
  }, [])

  useEffect(() => {
    formik.setFieldValue('EmpresaDominio', selectedOptions);
  },[selectedOptions])

  return (
    <>
      <ModalAddUsuario
        abrirModal={abrirModal}
        setAbrirModal={setAbrirModal}
        atualizarDados={() => buscarUsuariosSelect()}
      />

      <div className='card mb-xl-10'>
        <div className='card-header'>
          <div className='card-title m-0'>
            <h3 className='fw-bolder m-0'>Parametrizar Empresa</h3>
          </div>
        </div>

        <div className='card-body'>
          <FormikProvider value={formik}>
            <div className='w-100'>
              <form noValidate>
                <div className='row'>
                  <div className='col-lg-4 fv-row mb-5'>
                    <label className='form-label required'>Status da Versão da Empresa</label>
                    <div className='form-check form-switch form-check-custom form-check-solid mx-0 mt-4'>
                      <label className='form-label m-0 fs-3'>Ativar a versão buy?</label>
                      <Field
                        type='checkbox'
                        name={`tipoPlano`}
                        placeholder='Módulo de descrição de cargos'
                        className='form-check-input ms-2 h-25px w-40px'
                        onChange={(e: any) => onChangeCheckTipoPlano(e)}
                        checked={tipoPlanoBuy}
                        value={tipoPlanoBuy}
                        disabled={buyAtivo}
                      ></Field>
                    </div>
                  </div>
                  <div className='col-lg-8 fv-row mb-5'>                    
                      <div className='row'>
                        <div className='col-lg-5'>
                          <label className='form-label fs-6 fw-semibold required'>Período de Acesso - Início:</label>
                          <LocalizationProvider adapterLocale={br.name} dateAdapter={AdapterDayjs}>
                            <DatePicker
                              format='DD/MM/YYYY'
                              inputRef={datePickerRef.current}
                              {...formik.getFieldProps('PeriodoAcessoDataInicial')}
                              localeText={
                                ptBR.components.MuiLocalizationProvider.defaultProps.localeText
                              }
                              onChange={(e: any) => onChangeDatePickerStart(e)}
                              className='date-picker-color w-100'
                              value={
                                formik.values.PeriodoAcessoDataInicial !== ''
                                  ? formik.values.PeriodoAcessoDataInicial
                                  : null
                              }
                              slotProps={{
                                textField: {size: 'small', margin: 'dense'},
                              }}
                            />
                          </LocalizationProvider>
                          <div className='text-danger mt-2'>
                            <ErrorMessage name='PeriodoAcessoDataInicial' />
                          </div>
                        </div>
                        <div className='col-lg-2 d-flex justify-content-center align-items-center mt-4'>
                          <KTSVG
                            path='/media/icons/duotune/arrows/arr010.svg'
                            className='svg-icon-muted svg-icon-2hx text-gray-800'
                          />
                        </div>
                        <div className='col-lg-5'>
                          <label className='form-label fs-6 fw-semibold required'>Período de Acesso - Fim:</label>
                          <LocalizationProvider adapterLocale={br.name} dateAdapter={AdapterDayjs}>
                            <DatePicker
                              format='DD/MM/YYYY'
                              inputRef={datePickerRef.current}
                              {...formik.getFieldProps('PeriodoAcessoDataFinal')}
                              localeText={
                                ptBR.components.MuiLocalizationProvider.defaultProps.localeText
                              }
                              onChange={(e: any) => onChangeDatePickerEnd(e)}
                              className='date-picker-color w-100'
                              value={
                                formik.values.PeriodoAcessoDataFinal !== ''
                                  ? formik.values.PeriodoAcessoDataFinal
                                  : null
                              }
                              slotProps={{
                                textField: {size: 'small', margin: 'dense'},
                              }}
                            />
                          </LocalizationProvider>
                          <div className='text-danger mt-2'>
                            <ErrorMessage name='PeriodoAcessoDataFinal' />
                          </div>
                        </div>
                      </div>
                  </div>
                </div>                
                {!buyAtivo && formik.values.tipoPlano === EnumServicoTipoPlano.Buy && !formik.values.IsClienteConsultoriaDescricao && (                    
                  <div className='row'> 
                      <div className='col-lg-12 fv-row mb-5'>
                        <div className=''>
                          <div className='form-check form-switch form-check-custom form-check-solid'>
                            <label className='form-label m-0 fs-3'>Limpar os dados da versão try?</label>
                            <Field
                              type='checkbox'
                              name={`limparDadosVersaoTry`}
                              placeholder='Módulo de descrição de cargos'
                              className='form-check-input ms-2 h-25px w-40px'
                              onChange={(e: any) => onChangeLimparDadosVersaoTry(e)}
                              checked={formik.values.limparDadosVersaoTry}
                              value={formik.values.limparDadosVersaoTry}
                            ></Field>
                          </div>
                        </div>
                        {formik.values.limparDadosVersaoTry && (
                          <div className='mt-5'>
                            <div className='alert alert-danger d-flex align-items-center p-5'>
                              <span className='svg-icon svg-icon-2hx svg-icon-danger me-3'>
                                <KTSVG
                                  path='/media/icons/duotune/general/gen044.svg'
                                  className='svg-icon-danger svg-icon-2hx'
                                />
                              </span>

                              <div className='d-flex flex-column'>
                                <h5 className='mb-1'>Atenção</h5>
                                <span>Todos os dados desta empresa serão deletados permanentemente!</span>
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                  </div>
                )}   
                <div className='row'>
                  <div className='col-lg-6 fv-row mb-5'>
                    <label className='form-label required'>
                      Quantidade máxima de usuários permitidos
                    </label>
                    <Field
                      type='number'
                      name={`QtdMaximaUsuarios`}
                      placeholder='Quantidade máxima de usuários permitidos'
                      className='form-control form-control-lg form-control-solid'
                      value={formik.values.QtdMaximaUsuarios}
                    ></Field>
                    <div className='text-danger mt-2'>
                      <ErrorMessage name='QtdMaximaUsuarios' />
                    </div>
                  </div>
                </div>
                <div className='row'>            
                  <div className='col-lg-12 fv-row'>
                    <label className='form-label required'>Módulos</label>
                  </div>
                  <div className='col-lg-12 fv-row mb-5'>
                    <div className='w-100fv-row border border-2 rounded p-2'>
                      <div className='col-lg-12 fv-row m-2'>
                        <div className='form-check form-switch form-check-custom form-check-solid m-0'>
                          <label className='form-label m-0 fs-3'>
                            Ativar módulo Descrição de Cargos?
                          </label>
                          <Field
                            type='checkbox'
                            name={`moduloDescricaoConfig.ativo`}
                            placeholder='Módulo de descrição de cargos'
                            className='form-check-input ms-2 h-25px w-40px'
                            onChange={(e: any) => onChangeAtivarDescricaoDeCargo(e)}
                            checked={formik.values.moduloDescricaoConfig.ativo}
                            value={formik.values.moduloDescricaoConfig.ativo}
                          ></Field>
                        </div>
                      </div>
                      {formik.values.moduloDescricaoConfig.ativo && (
                        <div className='accordion mt-5' id='kt_accordion_1'>
                          <div className='accordion-item'>
                            <h2 className='accordion-header' id='kt_accordion_1_header_1'>
                              <button
                                className='accordion-button fs-4 fw-bold collapsed'
                                type='button'
                                data-bs-toggle='collapse'
                                data-bs-target='#kt_accordion_1_body_1'
                                aria-expanded='false'
                                aria-controls='kt_accordion_1_body_1'
                              >
                                Parâmetros de descrição de cargos
                              </button>
                            </h2>
                            <div
                              id='kt_accordion_1_body_1'
                              className='accordion-collapse collapse'
                              aria-labelledby='kt_accordion_1_header_1'
                              data-bs-parent='#kt_accordion_1'
                            >
                              <div className='accordion-body row'>
                                <div className='col-lg-4 fv-row mb-5'>
                                  <label className='form-label required'>
                                    Quantidade máxima de cargos permitidos
                                  </label>
                                  <Field
                                    type='number'
                                    name={`moduloDescricaoConfig.QtdMaximaCargos`}
                                    placeholder='Quantidade máxima de cargos permitidos'
                                    className='form-control form-control-lg form-control-solid'
                                    value={formik.values.moduloDescricaoConfig.QtdMaximaCargos}
                                  ></Field>
                                  <div className='text-danger mt-2'>
                                    <ErrorMessage name='moduloDescricaoConfig.QtdMaximaCargos' />
                                  </div>
                                </div>
                                <div className='col-lg-4 fv-row mb-5'>
                                  <label className='form-label'></label>
                                  <div className='form-check form-switch form-check-custom form-check-solid d-flex justify-content-between mx-0 mt-2 border border-1 rounded ps-5 py-3'>
                                    <label className='form-label m-0'>
                                      Utiliza workflow de aprovação?
                                    </label>
                                    <Field
                                      type='checkbox'
                                      name={`moduloDescricaoConfig.usaAprovacao`}
                                      placeholder='Módulo de descrição de cargos'
                                      className='form-check-input me-2 h-25px w-40px'
                                      onChange={(e: any) => onChangeUsaAprovacaoDescricaoDeCargo(e)}
                                      checked={formik.values.moduloDescricaoConfig.usaAprovacao}
                                      value={formik.values.moduloDescricaoConfig.usaAprovacao}
                                    ></Field>
                                  </div>
                                </div>
                                <div className='col-lg-4 fv-row mb-5'>
                                  <label className='form-label'></label>
                                  <div className='form-check form-switch form-check-custom form-check-solid d-flex justify-content-between mx-0 mt-2 border border-1 rounded ps-5 py-3'>
                                    <label className='form-label m-0'>
                                      Empresa é consultoria?
                                    </label>
                                    <Field
                                      type='checkbox'
                                      name={`moduloDescricaoConfig.Consultoria`}
                                      placeholder='Módulo de descrição de cargos'
                                      className='form-check-input me-2 h-25px w-40px'
                                      onChange={(e: any) => onChangeCheckConsultoria(e)}
                                      checked={formik.values.moduloDescricaoConfig.Consultoria}
                                      value={formik.values.moduloDescricaoConfig.Consultoria}
                                    ></Field>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className='col-lg-12 fv-row mb-5'>
                    <div className='w-100fv-row border border-2 rounded p-2'>
                      <div className='col-lg-12 fv-row m-2'>
                        <div className='form-check form-switch form-check-custom form-check-solid m-0'>
                          <label className='form-label m-0 fs-3'>
                            Ativar módulo Pesquisa?
                          </label>
                          <Field
                            type='checkbox'
                            name={`moduloPesquisaConfig.ativo`}
                            placeholder='Módulo de pesquisa'
                            className='form-check-input ms-2 h-25px w-40px'
                            onChange={(e: any) => onChangeAtivarPesquisa(e)}
                            checked={formik.values.moduloPesquisaConfig.ativo}
                            value={formik.values.moduloPesquisaConfig.ativo}
                          ></Field>
                        </div>
                      </div>
                      {formik.values.moduloPesquisaConfig.ativo && (
                        <div className='accordion mt-5' id='kt_accordion_2'>
                          <div className='accordion-item'>
                            <h2 className='accordion-header' id='kt_accordion_2_header_1'>
                              <button
                                className='accordion-button fs-4 fw-bold collapsed'
                                type='button'
                                data-bs-toggle='collapse'
                                data-bs-target='#kt_accordion_2_body_1'
                                aria-expanded='false'
                                aria-controls='kt_accordion_2_body_1'
                              >
                                Parâmetros de pesquisa
                              </button>
                            </h2>
                            <div
                              id='kt_accordion_2_body_1'
                              className='accordion-collapse collapse'
                              aria-labelledby='kt_accordion_2_header_1'
                              data-bs-parent='#kt_accordion_2'
                            >
                              <div className='accordion-body row'>
                                <div className='col-lg-4 fv-row mb-5'>
                                  <label className='form-label required'>
                                    Quantidade máxima de temas
                                  </label>
                                  <Field
                                    type='number'
                                    name={`moduloPesquisaConfig.QtdMaximaTemas`}
                                    placeholder='Quantidade máxima de temas permitidos'
                                    className='form-control form-control-lg form-control-solid'
                                    value={formik.values.moduloPesquisaConfig.QtdMaximaTemas}
                                  ></Field>
                                  <div className='text-danger mt-2'>
                                    <ErrorMessage name='moduloPesquisaConfig.QtdMaximaTemas' />
                                  </div>
                                </div>
                                <div className='col-lg-4 fv-row mb-5'>
                                  <label className='form-label required'>
                                    Quantidade máxima de pesquisas
                                  </label>
                                  <Field
                                    type='number'
                                    name={`moduloPesquisaConfig.QtdMaximaPesquisas`}
                                    placeholder='Quantidade máxima de pesquisas permitidos'
                                    className='form-control form-control-lg form-control-solid'
                                    value={formik.values.moduloPesquisaConfig.QtdMaximaPesquisas}
                                  ></Field>
                                  <div className='text-danger mt-2'>
                                    <ErrorMessage name='moduloPesquisaConfig.QtdMaximaPesquisas' />
                                  </div>
                                </div>
                                <div className='col-lg-4 fv-row mb-5'>
                                  <label className='form-label required'>
                                    Quantidade máxima de participantes
                                  </label>
                                  <Field
                                    type='number'
                                    name={`moduloPesquisaConfig.QtdMaximaParticipantes`}
                                    placeholder='Quantidade máxima de participantes permitidos'
                                    className='form-control form-control-lg form-control-solid'
                                    value={formik.values.moduloPesquisaConfig.QtdMaximaParticipantes}
                                  ></Field>
                                  <div className='text-danger mt-2'>
                                    <ErrorMessage name='moduloPesquisaConfig.QtdMaximaParticipantes' />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>     
                </div>                
                <div className='row'>        
                  <div className='col-lg-6 fv-row mb-5'>
                    <label className='form-label required'>Usuário master</label>
                    <Field
                      as='select'
                      name='idUsuarioMaster'
                      className='form-select form-select-lg form-select-solid'
                      value={formik.values.idUsuarioMaster}
                    >
                      <option value=''>Selecione...</option>
                      {usuarios && usuarios.length > 0
                        ? usuarios.map((data: any, index: any) => {
                            return (
                              <option key={`idUsuarioMaster-${index}`} value={data.Id}>
                                {data.NomeCompleto} {'-'} {data.Email}{' '}
                              </option>
                            )
                          })
                        : ''}
                    </Field>
                    <div className='text-danger mt-2'>
                      <ErrorMessage name='idUsuarioMaster' />
                    </div>
                  </div>
                  <div className='col-lg-6 fv-row mt-1 d-flex align-items-center'>
                    <button
                      type='button'
                      className='btn btn-light-primary'
                      onClick={() => setAbrirModal(true)}
                    >
                      <KTSVG
                        path='/media/icons/duotune/arrows/arr009.svg'
                        className='svg-icon-muted svg-icon-1'
                      />
                      Novo Usuário
                    </button>
                  </div>
                </div>
                <div className='row'>   
                <div className='col-lg-6 fv-row mb-5'>
                    <label className='form-label required'>
                      Domínios reservados
                    </label>
                    <Field 
                        name='EmpresaDominio'
                        className='form-control form-control-lg form-control-solid'
                        component={() =>
                            <MultipleSelect selectedOptions={selectedOptions} setSelectedOptions={setSelectedOptions}/>
                        }
                    />
                    <div className='text-danger mt-2'>
                        <ErrorMessage name='EmpresaDominio' />
                    </div>
                  </div>     
                </div>
                <div className='row'>        
                  <div className='col-lg-12 fv-row'>
                    <label className='form-label'></label>
                    <div className='form-check form-switch form-check-custom form-check-solid'>
                      <label className='form-label m-0 fs-3'>Auto gerenciar perfis?</label>
                      <Field
                        type='checkbox'
                        name={`AutoGerenciarPerfis`}
                        placeholder='Módulo de descrição de cargos'
                        className='form-check-input ms-2 h-25px w-40px'
                        onChange={(e: any) => onChangeAutoGerenciarPerfis(e)}
                        checked={formik.values.AutoGerenciarPerfis}
                        value={formik.values.AutoGerenciarPerfis}
                      ></Field>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </FormikProvider>
        </div>
        <div className='card-footer py-4 d-flex justify-content-end'>
          <button
            type='button'
            className='btn btn-lg btn-secondary me-5'
            onClick={() => navigate(-1)}
          >
            Voltar
          </button>
          <button
            type='button'
            onClick={() => formik.handleSubmit()}
            className='btn btn-lg btn-primary'
          >
            Salvar
          </button>
        </div>
      </div>
    </>
  )
}

export {Parametrizar}
