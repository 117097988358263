/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useEffect} from 'react'
import { CardInicio } from './components/partials/CardInicio'
import { useAuth } from '../../modules/auth'
import { ModalTutorial } from './components/partials/ModalTutorial'
import { EnumServicoTipoPlano, RequestMethod } from '../../modules/services/core/_enums'
import { useHttpClient } from '../../modules/services/Bundle'
import { AbrirModalVideo } from '../toolbar/AbrirModalVideo'

const InicioPage: FC = () => {
  const {auth, saveAuth} = useAuth()
  const user = auth?.user
  const servico = auth?.servico
  const moduloDescricaoConfig = servico?.moduloDescricaoConfig
  const modalPrimeiroAcesso = !user?.primeiroAcesso ?? false;
  const httpClient = useHttpClient()

  useEffect(() => {

    if (!user?.primeiroAcesso) {
        httpClient.request({
          method: RequestMethod.POST,
          endpoint: '/Account/ValidarPrimeiroAcesso',
        }).then((response) => {
          if(response.success && auth){
            let newAuth = {...auth}
            newAuth.user.primeiroAcesso = true
            saveAuth(newAuth)
          }
        })
    }
  }, [])

  return (
    <>
      <ModalTutorial
        videoPath='https://pro4tech.blob.core.windows.net/visualstuff/Pagina%20inicial.mp4?si=maintaining&spr=https&sv=2022-11-02&sr=b&sig=uFEWdmkq07JGvRjaFTjrPuUGZOQ2lM9tz2Kbv3NL%2FQk%3D'
        modalPrimeiroAcesso={modalPrimeiroAcesso}
        videoTitle='Página Inicial'
        descricao='Estamos felizes por você estar aqui! Assista a este vídeo para obter uma visão geral do módulo Descrição de cargo. Em seguida, confira os outros vídeos para obter conhecimento sobre a navegação no sistema.'
      ></ModalTutorial>

      <AbrirModalVideo
        videoPath='https://pro4tech.blob.core.windows.net/visualstuff/Pagina%20inicial.mp4?si=maintaining&spr=https&sv=2022-11-02&sr=b&sig=uFEWdmkq07JGvRjaFTjrPuUGZOQ2lM9tz2Kbv3NL%2FQk%3D'
        videoTitle='Página Inicial'
        descricao='Estamos felizes por você estar aqui! Assista a este vídeo para obter uma visão geral do módulo Descrição de cargo. Em seguida, confira os outros vídeos para obter conhecimento sobre a navegação no sistema.'
      ></AbrirModalVideo>

      <h2>Início</h2>      
    </>
  )
}

export {InicioPage}
