import {useEffect, useState} from 'react'
import {useAlert, useHttpClient} from '../../../modules/services/Bundle'
import {EnumModalAvalidorAprovador, EnumServicoTipoPlano, EnumTipoEnviarAprovacao, EnumTipoEnviarAvaliacao, RequestMethod} from '../../../modules/services/core/_enums'
import {ErrorMessage, Field, FormikProvider, useFormik} from 'formik'
import $ from 'jquery'
import {KTSVG} from '../../../../_metronic/helpers'
import {DescricaoCampos} from './partials/DescricaoCampos'
import {
  IDescreverDescricaoModel,
  descreverDescricao,
  descreverDescricaoValidation,
  IDescreverRespostaModel,
} from './helpers/DescreverHelper'
import {DescricaoPainel} from './partials/DescricaoPainel'
import {useLocation, useNavigate} from 'react-router-dom'
import {ModalAddResp} from './partials/ModalAddResp'
import {ModalArgumentarResp} from './partials/ModalArgumentarResp'
import {ModalAprimorarResp} from './partials/ModalAprimorarResp'
import {useAuth} from '../../../modules/auth'
import {ModalAprovadores} from './partials/ModalAprovadores'
import { ModalAvaliadores } from './partials/ModalAvaliadores'
import { ModalAvaliadoresAprovadores } from './partials/ModalAvaliadoresAprovadores'

const Descrever = () => {
  let [modelos, setModelos] = useState<any[]>([])
  let [cargos, setCargos] = useState<any[]>([])
  let [campoSelecionado, setCampoSelecionado] = useState<number | undefined>()
  let [respostaSelecionada, setRespostaSelecionada] = useState<number | undefined>()
  let [respostaSendoEditada, setRespostaSendoEditada] = useState(false)
  let [mostrarPainel, setMostrarPainel] = useState(false)
  const navigate = useNavigate()

  const httpClient = useHttpClient()
  const alert = useAlert()
  const {auth} = useAuth()
  const servico = auth?.servico

  const [respostaBackup, setRespostaBackup] = useState<IDescreverRespostaModel | undefined>()
  const [abrirModalAddResp, setAbrirModalAddResp] = useState<boolean>(false)
  const [abrirModalArgumentarResp, setAbrirModalArgumentarResp] = useState<boolean>(false)
  const [abrirModalAprimorarResp, setAbrirModalAprimorarResp] = useState<boolean>(false)
  const [abrirModalAprovadores, setAbrirModalAprovadores] = useState<boolean>(false)
  const [abrirModalAvaliadoresAprovadores, setAbrirModalAvaliadoresAprovadores] = useState<boolean>(false)
  const [tipoModalAprovadorAvaliador, setTipoModalAprovadorAvaliador] = useState(0)

  const [tipoRequestEnviarAvaliacao, setTipoRequestEnviarAvaliacao] = useState<number>(0);
  const [abrirModalAvaliadores, setAbrirModalAvaliadores] = useState<boolean>(false)

  const formik = useFormik({
    initialValues: descreverDescricao,
    validationSchema: descreverDescricaoValidation,
    onSubmit: async (values, actions) => {
      alert.createDialog({
        html: `Realmente deseja salvar e finalizar essa descrição?`,
        confirmAction: async () => {
          await httpClient.request({
            method: RequestMethod.GET,
            endpoint: '/DescricaoDeCargo/Descrever/FinalizarDescricao',
            queryObject: {
              idDescricao: values.Id,
            },
            ifSuccessRedirectTo: '/descricao/dashboard',
          })
        },
      })
    },
  })

  const autoSizeTextArea = () => {
    $('.textarea-resposta-descricao').each(function (i, element) {
      let target = element as HTMLTextAreaElement
      target.style.height = '90px'

      if (target.scrollHeight && target.scrollHeight > 90)
        target.style.height = target.scrollHeight + 'px'
    })
  }

  useEffect(() => {
    autoSizeTextArea()
  }, [formik])

  const ReprovarAvaliar = (idDescricao: number) => {
    alert.createDialog({
      html: `Realmente deseja reprovar esta descrição?`,
      confirmAction: async () => {
        const result = await httpClient.request({
          method: RequestMethod.GET,
          endpoint: '/DescricaoDeCargo/Descrito/ReprovarAvaliacaoDescricao',
          queryObject: {idDescricao: idDescricao},
          ifSuccessRedirectTo: '/descricao/dashboard',
        })

      },
    })
  }

  const focusResposta = () => {
    var element = $('.resposta-descricao-selecionada')

    if (element && element[0]) {
      let respostaInput = element[0] as HTMLInputElement
      var strLength = respostaInput.value.length * 2
      respostaInput.focus()
      respostaInput.setSelectionRange(strLength, strLength)
    }
  }

  useEffect(() => {
    if (respostaSendoEditada) focusResposta()
  }, [respostaSendoEditada])

  useEffect(() => {
    setCampoSelecionado(undefined)
    setRespostaSelecionada(undefined)
    setRespostaSendoEditada(false)
    setRespostaBackup(undefined)
    setAbrirModalAddResp(false)
    setAbrirModalArgumentarResp(false)
    setAbrirModalAprimorarResp(false)

    if (formik.values.IdModelo && formik.values.IdCargo) {
      httpClient
        .request({
          method: RequestMethod.GET,
          endpoint: '/DescricaoDeCargo/Descrever/ObterDescricao',
          queryObject: {
            idModelo: formik.values.IdModelo,
            idCargo: formik.values.IdCargo,
          },
        })
        .then((response) => {
          if (response.success && response.payload) {
            const descricao: IDescreverDescricaoModel = response.payload

            formik.setFieldValue('Id', descricao.Id)
            formik.setFieldValue('StatusAprovacao', descricao.StatusAprovacao)
            formik.setFieldValue('NomeModelo', descricao.NomeModelo)
            formik.setFieldValue('NomeCargo', descricao.NomeCargo)
            formik.setFieldValue('NomeNivelHierarquico', descricao.NomeNivelHierarquico)
            formik.setFieldValue('NomeArea', descricao.NomeArea)
            formik.setFieldValue('Campos', descricao.Campos)

            setMostrarPainel(true)
          }
        })
    } else {
      let defaultDescricao = descreverDescricao

      formik.setFieldValue('Id', defaultDescricao.Id)
      formik.setFieldValue('StatusAprovacao', defaultDescricao.StatusAprovacao)
      formik.setFieldValue('NomeModelo', defaultDescricao.NomeModelo)
      formik.setFieldValue('NomeCargo', defaultDescricao.NomeCargo)
      formik.setFieldValue('NomeNivelHierarquico', defaultDescricao.NomeNivelHierarquico)
      formik.setFieldValue('NomeArea', defaultDescricao.NomeArea)
      formik.setFieldValue('Campos', defaultDescricao.Campos)

      setMostrarPainel(false)
    }
  }, [formik.values.IdModelo, formik.values.IdCargo])

  interface dados {
    statusDescricao: number,
    idCargoState: number,
    idModelo: number,
    idDescricao: number,
    idEmpresa: number | null,
    isAvaliador?: boolean,
  }

  const location = useLocation()
  const state = location.state as dados
  const informacao: dados = state
  const [utilizaAvaliador, setUtilizaAvaliador] = useState(false)
  const [permiteEditarDescricao, setPermiteEditarDescricao] = useState(false)

  const loadingData = async() => {
    if(modelos.length === 0 || cargos.length === 0){
      const response = await httpClient.request({ 
        method: RequestMethod.GET,
        endpoint: '/DescricaoDeCargo/Descrever/ObterDescreverDescricaoDados',
        queryObject:{
          statusDescricao: informacao?.statusDescricao ?? 1,
          idEmpresa: informacao?.idEmpresa ?? 0,
        }
      })
  
      if (response.success && response.payload) {
        const dados = response.payload
        if (dados.Modelos && dados.Modelos.length > 0) {
          setModelos(dados.Modelos)
          formik.setFieldValue('IdModelo', dados.Modelos[0].Id)
        }
        if (dados.Cargos && dados.Cargos.length > 0) setCargos(dados.Cargos)

          if (informacao !== null && informacao.idEmpresa !== null && informacao.idEmpresa > 0) {
            httpClient
            .request({
              method: RequestMethod.GET,
              endpoint: '/DescricaoDeCargo/Consultoria/ObterCliente',
              queryObject: {
                idEmpresaCliente: informacao.idEmpresa
              },
            })
            .then((result) => {
              if (result.success && result.payload) {
                setPermiteEditarDescricao(result.payload.PermiteEditarDescricao)
                setUtilizaAvaliador(result.payload.UtilizaAvaliador)
              }
            })
          }
      }
    }

    if (informacao) {
      formik.setFieldValue('IdCargo', informacao.idCargoState)
      if(informacao.idModelo){
        formik.setFieldValue('IdModelo', informacao.idModelo)
      }
    }
  }

  useEffect(() => {
    loadingData()
  }, [])

  return (
    <>
      <ModalAddResp
        abrirModal={abrirModalAddResp}
        setAbrirModal={setAbrirModalAddResp}
        formik={formik}
        campoSelecionado={campoSelecionado}
        setCampoSelecionado={setCampoSelecionado}
        respostaSelecionada={respostaSelecionada}
        setRespostaSelecionada={setRespostaSelecionada}
        respostaSendoEditada={respostaSendoEditada}
        setRespostaSendoEditada={setRespostaSendoEditada}
      />

      <ModalArgumentarResp
        abrirModal={abrirModalArgumentarResp}
        setAbrirModal={setAbrirModalArgumentarResp}
        formik={formik}
        campoSelecionado={campoSelecionado}
        setCampoSelecionado={setCampoSelecionado}
        respostaSelecionada={respostaSelecionada}
        setRespostaSelecionada={setRespostaSelecionada}
        respostaSendoEditada={respostaSendoEditada}
        setRespostaSendoEditada={setRespostaSendoEditada}
      />

      <ModalAprimorarResp
        abrirModal={abrirModalAprimorarResp}
        setAbrirModal={setAbrirModalAprimorarResp}
        formik={formik}
        campoSelecionado={campoSelecionado}
        setCampoSelecionado={setCampoSelecionado}
        respostaSelecionada={respostaSelecionada}
        setRespostaSelecionada={setRespostaSelecionada}
        respostaSendoEditada={respostaSendoEditada}
        setRespostaSendoEditada={setRespostaSendoEditada}
      />

      {abrirModalAprovadores && (
        <ModalAprovadores
          AbrirModal={abrirModalAprovadores}
          SetAbrirModal={setAbrirModalAprovadores}
          IdDescricao={formik.values.Id ?? 0}
          idEmpresa={informacao.idEmpresa}
        />
      )}

      {abrirModalAvaliadores && (
        <ModalAvaliadores
          AbrirModal={abrirModalAvaliadores}
          SetAbrirModal={setAbrirModalAvaliadores}
          IdDescricao={formik.values.Id ?? 0}
          idEmpresa={informacao.idEmpresa}
        />
      )}

      <ModalAvaliadoresAprovadores
        TipoModal={tipoModalAprovadorAvaliador}
        AbrirModal={abrirModalAvaliadoresAprovadores}
        SetAbrirModal={setAbrirModalAvaliadoresAprovadores}
        IdDescricao={formik.values.Id ?? 0}
        TipoRequest={tipoRequestEnviarAvaliacao}
        idEmpresa={informacao.idEmpresa}
      />

      <div className='card mb-5 mb-xl-10'>
        <div className='card-header'>
          <div className='card-title m-0'>
            <h3 className='fw-bolder m-0'>Revisar Descrição</h3>
          </div>
          <div className='card-toolbar'>
            {servico?.clienteConsultoriaDescricao === null && formik.values.IdCargo && formik.values.IdModelo && (
              <>
                {(auth?.servico.tipoPlano === EnumServicoTipoPlano.Try ||
                  auth?.roles.find((x) => x === 'Master') !== undefined ||
                  auth?.roles.find((x) => x === 'Administrador') !== undefined ||
                  auth?.permissoes.find((x) => x === '9325790079D4AF50249EE2262AC90AEE') !==
                    undefined) && (
                  <>
                    {servico?.moduloDescricaoConfig.usaAprovacao ? (
                      <>
                      {(auth?.roles.find((x) => x === 'Master') !== undefined ||
                        auth?.roles.find((x) => x === 'Administrador') !== undefined ||
                        (auth?.permissoes.find((x) => x === '854FB94286FD7EF473F9F5F5D74C7DCC') !== undefined && informacao?.idEmpresa === null) ||
                        (auth?.permissoes.find((x) => x === '40881629371476181E6567A0110D57AA') !== undefined && informacao?.idEmpresa !== null)) && (
                          <>
                            {utilizaAvaliador && (
                              <>
                                <button
                                  type='button'
                                  title='Vincular Avaliadores'
                                  className='btn btn-info me-3'
                                  onClick={() => {
                                    setAbrirModalAvaliadores(true)
                                    }
                                  }
                                >
                                  <i className={`fas fa-link fs-2`}></i>
                                  Vincular Avaliadores
                                </button>
                              </>
                            )}
                            <button
                              type='button'
                              title='Vincular Aprovadores'
                              className='btn btn-secondary me-3'
                              onClick={() => setAbrirModalAprovadores(true)
                              }
                            >
                              <i className={`fas fa-link fs-2`}></i>
                              Vincular Aprovadores
                            </button>
                          </>
                        )}

                        {utilizaAvaliador ?
                          <button
                            type='button'
                            title='Enviar para avaliação'
                            className='btn btn-primary me-3'
                            onClick={() => {
                                setTipoModalAprovadorAvaliador(EnumModalAvalidorAprovador.Avaliador)
                                setAbrirModalAvaliadoresAprovadores(true)
                                setTipoRequestEnviarAvaliacao(EnumTipoEnviarAvaliacao.Unica)
                              }
                            }
                          >
                            <svg
                              xmlns='http://www.w3.org/2000/svg'
                              width='16'
                              height='16'
                              fill='currentColor'
                              className='bi bi-send-plus me-2'
                              viewBox='0 0 16 16'
                            >
                              <path d='M15.964.686a.5.5 0 0 0-.65-.65L.767 5.855a.75.75 0 0 0-.124 1.329l4.995 3.178 1.531 2.406a.5.5 0 0 0 .844-.536L6.637 10.07l7.494-7.494-1.895 4.738a.5.5 0 1 0 .928.372l2.8-7Zm-2.54 1.183L5.93 9.363 1.591 6.602l11.833-4.733Z' />
                              <path d='M16 12.5a3.5 3.5 0 1 1-7 0 3.5 3.5 0 0 1 7 0Zm-3.5-2a.5.5 0 0 0-.5.5v1h-1a.5.5 0 0 0 0 1h1v1a.5.5 0 0 0 1 0v-1h1a.5.5 0 0 0 0-1h-1v-1a.5.5 0 0 0-.5-.5Z' />
                            </svg>
                            Enviar para avaliação
                          </button>
                        : 
                          <button
                            type='button'
                            title='Enviar para aprovação'
                            className='btn btn-primary me-3'
                            onClick={() => {
                              setTipoModalAprovadorAvaliador(EnumModalAvalidorAprovador.Aprovador)
                              setAbrirModalAvaliadoresAprovadores(true)
                              setTipoRequestEnviarAvaliacao(EnumTipoEnviarAprovacao.Unica)
                              }
                            }
                          >
                            <svg
                              xmlns='http://www.w3.org/2000/svg'
                              width='16'
                              height='16'
                              fill='currentColor'
                              className='bi bi-send-plus me-2'
                              viewBox='0 0 16 16'
                            >
                              <path d='M15.964.686a.5.5 0 0 0-.65-.65L.767 5.855a.75.75 0 0 0-.124 1.329l4.995 3.178 1.531 2.406a.5.5 0 0 0 .844-.536L6.637 10.07l7.494-7.494-1.895 4.738a.5.5 0 1 0 .928.372l2.8-7Zm-2.54 1.183L5.93 9.363 1.591 6.602l11.833-4.733Z' />
                              <path d='M16 12.5a3.5 3.5 0 1 1-7 0 3.5 3.5 0 0 1 7 0Zm-3.5-2a.5.5 0 0 0-.5.5v1h-1a.5.5 0 0 0 0 1h1v1a.5.5 0 0 0 1 0v-1h1a.5.5 0 0 0 0-1h-1v-1a.5.5 0 0 0-.5-.5Z' />
                            </svg>
                            Enviar para aprovação
                          </button>
                        }
                      </>
                    ) : (
                      <button
                        id='revisar-descricao'
                        type='button'
                        className='btn btn-descricao'
                        onClick={() => formik.handleSubmit()}
                      >
                        <KTSVG
                          path='/media/icons/duotune/files/fil005.svg'
                          className='svg-icon-2 text-white'
                        />
                        Salvar
                      </button>
                    )}
                  </>
                )}
              </>
            )}
          </div>
        </div>

        <div className='card-body p-9'>
          <FormikProvider value={formik}>
            <form noValidate onSubmit={formik.handleSubmit}>
              <div
                className={`row ${formik.values.IdCargo && formik.values.IdModelo ? 'mb-10' : ''}`}
              >
                <div className='col-lg-6 fv-row'>
                  <label className='form-label'>Cargo</label>
                  <Field
                    disabled
                    as='select'
                    name='IdCargo'
                    className='form-select form-select-lg form-select-solid'
                    value={formik.values.IdCargo}
                  >
                    <option value=''>Selecione...</option>
                    {cargos &&
                      cargos.length > 0 &&
                      cargos.map((data: any, index: number) => {
                        return (
                          <option key={`IdCargo-option-${index}`} value={data.Id}>
                            {data.Nome}
                          </option>
                        )
                      })}
                  </Field>
                  <div className='text-danger mt-2'>
                    <ErrorMessage name='IdCargo' />
                  </div>
                </div>

                <div className='col-lg-6 fv-row'>
                  <label className='form-label'>Modelo do formulário</label>
                  <Field
                    disabled
                    as='select'
                    name='IdModelo'
                    className='form-select form-select-lg form-select-solid'
                    value={formik.values.IdModelo}
                    selected={formik.values.IdModelo}                    
                  >
                    {modelos &&
                      modelos.length > 0 &&
                      modelos.map((data: any, index: number) => {
                        return (
                          <option key={`IdModelo-option-${index}`} value={data.Id}>
                            {data.Nome}
                          </option>
                        )
                      })}
                  </Field>
                  <div className='text-danger mt-2'>
                    <ErrorMessage name='IdModelo' />
                  </div>
                </div>
              </div>

              <div className='row'>
                <div className='col-lg-12'>
                  <DescricaoCampos
                    formik={formik}
                    campoSelecionado={campoSelecionado}
                    setCampoSelecionado={setCampoSelecionado}
                    respostaSelecionada={respostaSelecionada}
                    setRespostaSelecionada={setRespostaSelecionada}
                    respostaSendoEditada={respostaSendoEditada}
                    setRespostaSendoEditada={setRespostaSendoEditada}
                    respostaBackup={respostaBackup}
                    setRespostaBackup={setRespostaBackup}
                  />
                </div>
              </div>
            </form>
          </FormikProvider>
        </div>

        <div className='card-footer text-end' style={{minHeight: '70px'}}>
          {servico?.clienteConsultoriaDescricao != null ? (
            <>
              {informacao.isAvaliador && (
                <>
                  <button
                    type='button'
                    title='Enviar para aprovação'
                    className='btn btn-primary me-3'
                    onClick={() => {
                      setTipoModalAprovadorAvaliador(EnumModalAvalidorAprovador.Aprovador)
                      setAbrirModalAvaliadoresAprovadores(true)
                      setTipoRequestEnviarAvaliacao(EnumTipoEnviarAprovacao.Unica)
                      }
                    }
                  >
                    <svg
                      xmlns='http://www.w3.org/2000/svg'
                      width='16'
                      height='16'
                      fill='currentColor'
                      className='bi bi-send-plus me-2'
                      viewBox='0 0 16 16'
                    >
                      <path d='M15.964.686a.5.5 0 0 0-.65-.65L.767 5.855a.75.75 0 0 0-.124 1.329l4.995 3.178 1.531 2.406a.5.5 0 0 0 .844-.536L6.637 10.07l7.494-7.494-1.895 4.738a.5.5 0 1 0 .928.372l2.8-7Zm-2.54 1.183L5.93 9.363 1.591 6.602l11.833-4.733Z' />
                      <path d='M16 12.5a3.5 3.5 0 1 1-7 0 3.5 3.5 0 0 1 7 0Zm-3.5-2a.5.5 0 0 0-.5.5v1h-1a.5.5 0 0 0 0 1h1v1a.5.5 0 0 0 1 0v-1h1a.5.5 0 0 0 0-1h-1v-1a.5.5 0 0 0-.5-.5Z' />
                    </svg>
                    Enviar para aprovação
                  </button>
                  <button onClick={() => ReprovarAvaliar(informacao.idDescricao)} className="btn btn-danger me-3">
                    <i className="fas fa-times fs-2"></i>
                    Reprovar
                  </button>
                </>
              )}
              <button className='btn btn-secondary' onClick={() => navigate(-1)}>
                Voltar
              </button>
            </>
          ) : 
          (
            <>
            {formik.values.IdCargo && formik.values.IdModelo && (
              <>
                {(auth?.servico.tipoPlano === EnumServicoTipoPlano.Try ||
                  auth?.roles.find((x) => x === 'Master') !== undefined ||
                  auth?.roles.find((x) => x === 'Administrador') !== undefined ||
                  auth?.permissoes.find((x) => x === '9325790079D4AF50249EE2262AC90AEE') !==
                    undefined) && (
                  <>
                    {servico?.moduloDescricaoConfig.usaAprovacao ? (
                      <>
                        {(auth?.roles.find((x) => x === 'Master') !== undefined ||
                        auth?.roles.find((x) => x === 'Administrador') !== undefined ||
                        (auth?.permissoes.find((x) => x === '854FB94286FD7EF473F9F5F5D74C7DCC') !== undefined && informacao?.idEmpresa === null) ||
                        (auth?.permissoes.find((x) => x === '40881629371476181E6567A0110D57AA') !== undefined && informacao?.idEmpresa !== null)) && (
                          <>
                            {utilizaAvaliador && (
                              <>
                                <button
                                  type='button'
                                  title='Vincular Avaliadores'
                                  className='btn btn-info me-3'
                                  onClick={() => {
                                    setAbrirModalAvaliadores(true)
                                    }
                                  }
                                >
                                  <i className={`fas fa-link fs-2`}></i>
                                  Vincular Avaliadores
                                </button>
                              </>
                            )}
                            <button
                              type='button'
                              title='Vincular Aprovadores'
                              className='btn btn-secondary me-3'
                              onClick={() => setAbrirModalAprovadores(true)
                              }
                            >
                              <i className={`fas fa-link fs-2`}></i>
                              Vincular Aprovadores
                            </button>
                          </>
                        )}

                        {utilizaAvaliador ?
                          <button
                            type='button'
                            title='Enviar para avaliação'
                            className='btn btn-primary me-3'
                            onClick={() => {
                                setTipoModalAprovadorAvaliador(EnumModalAvalidorAprovador.Avaliador)
                                setAbrirModalAvaliadoresAprovadores(true)
                                setTipoRequestEnviarAvaliacao(EnumTipoEnviarAvaliacao.Unica)
                              }
                            }
                          >
                            <svg
                              xmlns='http://www.w3.org/2000/svg'
                              width='16'
                              height='16'
                              fill='currentColor'
                              className='bi bi-send-plus me-2'
                              viewBox='0 0 16 16'
                            >
                              <path d='M15.964.686a.5.5 0 0 0-.65-.65L.767 5.855a.75.75 0 0 0-.124 1.329l4.995 3.178 1.531 2.406a.5.5 0 0 0 .844-.536L6.637 10.07l7.494-7.494-1.895 4.738a.5.5 0 1 0 .928.372l2.8-7Zm-2.54 1.183L5.93 9.363 1.591 6.602l11.833-4.733Z' />
                              <path d='M16 12.5a3.5 3.5 0 1 1-7 0 3.5 3.5 0 0 1 7 0Zm-3.5-2a.5.5 0 0 0-.5.5v1h-1a.5.5 0 0 0 0 1h1v1a.5.5 0 0 0 1 0v-1h1a.5.5 0 0 0 0-1h-1v-1a.5.5 0 0 0-.5-.5Z' />
                            </svg>
                            Enviar para avaliação
                          </button>
                        : 
                          <button
                            type='button'
                            title='Enviar para aprovação'
                            className='btn btn-primary me-3'
                            onClick={() => {
                              setTipoModalAprovadorAvaliador(EnumModalAvalidorAprovador.Aprovador)
                              setAbrirModalAvaliadoresAprovadores(true)
                              setTipoRequestEnviarAvaliacao(EnumTipoEnviarAprovacao.Unica)
                              }
                            }
                          >
                            <svg
                              xmlns='http://www.w3.org/2000/svg'
                              width='16'
                              height='16'
                              fill='currentColor'
                              className='bi bi-send-plus me-2'
                              viewBox='0 0 16 16'
                            >
                              <path d='M15.964.686a.5.5 0 0 0-.65-.65L.767 5.855a.75.75 0 0 0-.124 1.329l4.995 3.178 1.531 2.406a.5.5 0 0 0 .844-.536L6.637 10.07l7.494-7.494-1.895 4.738a.5.5 0 1 0 .928.372l2.8-7Zm-2.54 1.183L5.93 9.363 1.591 6.602l11.833-4.733Z' />
                              <path d='M16 12.5a3.5 3.5 0 1 1-7 0 3.5 3.5 0 0 1 7 0Zm-3.5-2a.5.5 0 0 0-.5.5v1h-1a.5.5 0 0 0 0 1h1v1a.5.5 0 0 0 1 0v-1h1a.5.5 0 0 0 0-1h-1v-1a.5.5 0 0 0-.5-.5Z' />
                            </svg>
                            Enviar para aprovação
                          </button>
                        }
                      </>
                    ) : (
                      <button
                        type='button'
                        className='btn btn-descricao'
                        onClick={() => formik.handleSubmit()}
                      >
                        <KTSVG
                          path='/media/icons/duotune/files/fil005.svg'
                          className='svg-icon-2 text-white'
                        />
                        Salvar
                      </button>
                    )}
                  </>
                )}
              </>
            )}
            </>
          )}
        </div>
      </div>

      <DescricaoPainel
        mostrarPainel={mostrarPainel}
        formik={formik}
        campoSelecionado={campoSelecionado}
        setCampoSelecionado={setCampoSelecionado}
        respostaSelecionada={respostaSelecionada}
        setRespostaSelecionada={setRespostaSelecionada}
        respostaSendoEditada={respostaSendoEditada}
        setRespostaSendoEditada={setRespostaSendoEditada}
        respostaBackup={respostaBackup}
        setRespostaBackup={setRespostaBackup}
        abrirModalAddResp={abrirModalAddResp}
        setAbrirModalAddResp={setAbrirModalAddResp}
        abrirModalArgumentarResp={abrirModalArgumentarResp}
        setAbrirModalArgumentarResp={setAbrirModalArgumentarResp}
        abrirModalAprimorarResp={abrirModalAprimorarResp}
        setAbrirModalAprimorarResp={setAbrirModalAprimorarResp}
      />
    </>
  )
}

export {Descrever}
