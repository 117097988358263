import {Route, Routes, Navigate} from 'react-router-dom'
import { ResponderPesquisa } from './components/ResponderPesquisa'
import { ResponderPesquisaLayout } from '../../../_metronic/layout/ResponderPesquisaLayout'

const ResponderPesquisaPage = () => {
  return (
    <Routes>
      <Route element={<ResponderPesquisaLayout />}>        
        <Route path=':chave' element={<ResponderPesquisa />} />   
        <Route path='*' element={<Navigate to='/error/404' />} />
      </Route>
    </Routes>
  )
}

export {ResponderPesquisaPage}
