import React, { useState } from 'react';
import Chart from 'react-apexcharts'
import { IDadosGraficosPesquisaModel } from '../helpers/AnaliseResultadoHelper';
import { ModalGraficoDispersao } from './ModalGraficoDispersao';

type Props = {
  dadosGraficosPesquisa: IDadosGraficosPesquisaModel | undefined
}

const GraficoDispersao  = ({dadosGraficosPesquisa}:Props) => {
  let [showModal, setShowModal] = useState<boolean>(false)
  let [dimensaoIndex, setDimensaoIndex] = useState<number | undefined>(undefined)
  let [dispersaoIndex, setDispersaoIndex] = useState<number | undefined>(undefined)
  let [fillColor, setFillColor] = useState<string | undefined>(undefined)
  
  let stackType: any = '100%'

  let options = {
    colors : dadosGraficosPesquisa?.GraficoDispersao.Colors ?? [],
    series: dadosGraficosPesquisa?.GraficoDispersao.Series ?? [],
    chart: {
      stacked: true,
      stackType: stackType,
      toolbar: {
        show: false
      },
      events: {
        click: function(event: React.ChangeEvent<HTMLInputElement>, chartContext:any, config:any) {
          let valueJ = event.target.attributes.getNamedItem('j')?.value 
          let valueI = event.target.attributes.getNamedItem('index')?.value 
          let fColor = event.target.attributes.getNamedItem('fill')?.value 
          if (valueJ !== undefined && valueI !== undefined && fColor !== undefined) {
            setDimensaoIndex(parseInt(valueJ))
            setDispersaoIndex(parseInt(valueI))
            setFillColor(fColor)
            setShowModal(true)
          }
          else{
            setDimensaoIndex(undefined)
            setDispersaoIndex(undefined)
            setFillColor(undefined)
          }
        }
      }
    },
    plotOptions: {
      bar: {
        horizontal: true,
      },
    },
    stroke: {
      width: 1,
      colors: ['#fff']
    },
    xaxis: {
      categories: dadosGraficosPesquisa?.GraficoDispersao.Categories ?? [],
    },
    fill: {
      opacity: 1    
    },
    legend: {
      offsetX: 40
    }
  }
  
  return (
    <>
      <ModalGraficoDispersao 
        showModal={showModal}
        setShowModal={setShowModal}
        dimensaoIndex={dimensaoIndex}
        dispersaoIndex={dispersaoIndex}
        fillColor={fillColor}
        dadosGraficosPesquisa={dadosGraficosPesquisa}
      />

      <div className={`card mb-10 ${dadosGraficosPesquisa ? '' : 'd-none'}`}>
        <div
          className='card-header'
        >
          <div className='card-title m-0'>
            <h3 className='fw-bolder m-0'>Dispersão</h3>
          </div>
        </div>
        <div className='card-body'>
          <div className='row justify-content-center my-10 mx-8'>
            <Chart options={options} series={options.series} type="bar" height={'400px'} />
          </div>
        </div>        
      </div> 
    </>         
  )
}
  
export {GraficoDispersao}