import { useEffect, useState } from 'react'
import { useHttpClient } from '../../../modules/services/Bundle'
import { RequestMethod } from '../../../modules/services/core/_enums'
import { Field, FormikProvider, useFormik } from 'formik'
import { IDescreverDescricaoModel, descreverDescricao, descreverDescricaoValidation } from './helpers/DescreverHelper'
import { useLocation, useNavigate } from 'react-router-dom'
import { VisualizarCampos } from './partials/VisualizarCampos'
import $ from 'jquery';
import { KTSVG } from '../../../../_metronic/helpers'
import { useAuth } from '../../../modules/auth'


const AprovarDescricao = () => {
  const httpClient = useHttpClient()
  const navigate = useNavigate();
  const {auth} = useAuth()
  const [cargoNome, setCargoNome] = useState("")
  const [modeloNome, setModeloNome] = useState("")
  const [temValorReprovado, setTemValorReprovado] = useState<boolean>(false);

  const formik = useFormik({
    initialValues: descreverDescricao,
    validationSchema: descreverDescricaoValidation,
    onSubmit: (values, actions) => {

    },
  })

  const autoSizeTextArea = () => {

    $('.textarea-resposta-descricao').each(function (i, element) {

      let target = element as HTMLTextAreaElement
      target.style.height = "90px"

      if (target.scrollHeight && target.scrollHeight > 90) target.style.height = (target.scrollHeight) + "px"

    })

  }

  useEffect(() => {
    autoSizeTextArea()

  }, [formik])

  interface dados {
    idDescricao: number
  }
  const location = useLocation();
  const state = location.state as dados
  const informacao: dados = state

  useEffect(() => {
    
    if(informacao){
      httpClient.request({
        method: RequestMethod.GET,
        endpoint: '/DescricaoDeCargo/Descrito/VisualizarDescricao',
        queryObject: {
          idDescricao: informacao.idDescricao,
        }
      }).then((response) => {
        if(response.success && response.payload){
          const descricao: IDescreverDescricaoModel = response.payload
          formik.setFieldValue('Id', descricao.Id)
          formik.setFieldValue('StatusAprovacao', descricao.StatusAprovacao)
          formik.setFieldValue('NomeModelo', descricao.NomeModelo)
          formik.setFieldValue('NomeCargo', descricao.NomeCargo)
          setCargoNome(descricao.NomeCargo)
          setModeloNome(descricao.NomeModelo)
          formik.setFieldValue('NomeNivelHierarquico', descricao.NomeNivelHierarquico)
          formik.setFieldValue('NomeArea', descricao.NomeArea)
          formik.setFieldValue('Campos', descricao.Campos)
          formik.setFieldValue('IsAprovador', descricao.IsAprovador)
        }
      })
    }
  }, [])

  const reprovarDescricao = (id: number) => {
    httpClient
      .request({
        method: RequestMethod.GET,
        endpoint: "/DescricaoDeCargo/Descrito/ReprovarDescricao",
        queryObject: {
          idDescricao: id,
        },
        ifSuccessRedirectTo: "/descricao/reprovadas"
      });
  }

  const aprovarDescricao = (id: number) => {
    httpClient
      .request({
        method: RequestMethod.GET,
        endpoint: "/DescricaoDeCargo/Descrito/AprovarDescricao",
        queryObject: {
          idDescricao: id,
        },
        ifSuccessRedirectTo: "/descricao/descricoes"
      });
  }
  
  return (
    <>
      <div className='card mb-5 mb-xl-10'>

        <div className='card-header'>
          <div className='card-title m-0'>
            <h3 className='fw-bolder m-0'>Aprovar Descrição</h3>
          </div>
        </div>

        <div className='card-body p-9'>

          <FormikProvider value={formik}>
            <form noValidate onSubmit={formik.handleSubmit}>
              
              <div className={`row mb-10`}>

                <div className='col-lg-6 fv-row'>
                  <label className='form-label'>Cargo</label>
                  <Field
                    disabled
                    as='select'
                    name='IdCargo'
                    className='form-select form-select-lg form-select-solid'
                    value={formik.values.IdCargo}
                  >
                    <option value=''>{cargoNome}</option>
                  </Field>
                </div>
                
                <div className='col-lg-6 fv-row'>
                  <label className='form-label'>Modelo do formulário</label>
                  <Field
                    disabled
                    as='select'
                    name='IdModelo'
                    className='form-select form-select-lg form-select-solid'
                    value={formik.values.IdModelo}
                  >
                    <option value=''>{modeloNome}</option>
                  </Field>
                </div>

              </div>

              <div className='row'>

                <div className='col-lg-12'>

                  <VisualizarCampos    
                    aprovar={true}                 
                    formik={formik}
                    setTemValorReprovado={setTemValorReprovado}
                  />
                    
                </div>

              </div>

            </form>
          </FormikProvider>
          
        </div>
        <div className="card-footer d-flex justify-content-end">
            {((auth?.roles.find(x => x === 'Master') !== undefined) || (auth?.roles.find(x => x === 'Administrador') !== undefined) || (auth?.permissoes.find(x => x === 'B7AA89B71EB1790942909C50DDFDF311') !== undefined) || (auth?.permissoes.find(x => x === 'B847D84BCACD0DF5405CCE536A99AD58') !== undefined)) && (                                    
              <button  disabled={temValorReprovado || !formik.values.IsAprovador} onClick={() => aprovarDescricao(informacao.idDescricao)} id="btnAprovarDescricaoiblioteca" className="btn btn-success me-3">
                <i className="fas fa-check fs-2"></i>
                Aprovar
              </button>
            )}
            {((auth?.roles.find(x => x === 'Master') !== undefined) || (auth?.roles.find(x => x === 'Administrador') !== undefined) || (auth?.permissoes.find(x => x === '577E869CC2D4BCDD54A6440F565F48B9') !== undefined) || (auth?.permissoes.find(x => x === 'B847D84BCACD0DF5405CCE536A99AD58') !== undefined)) && (                                    
              <button disabled={!temValorReprovado || !formik.values.IsAprovador} onClick={() => reprovarDescricao(informacao.idDescricao)} id="btnReprovarDescricaoBiblioteca" className="btn btn-danger me-3">
                <i className="fas fa-times fs-2"></i>
                Reprovar
              </button>
            )}
            <button className='btn btn-secondary' onClick={() => navigate(-1)}>
              Voltar
            </button>
        </div>
      </div>
    </>
  )
}

export {AprovarDescricao}
