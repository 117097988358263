import * as Yup from 'yup'
import { useValidation } from '../../../../modules/services/Bundle'

export interface IEmpresaEnderecoModel {
  Id?: number
	IdEmpresa?: number,
	Cep: string
	Estado: string
	Cidade: string,
	Bairro: string
	Endereco: string
	Numero: string
	Complemento: string
	EnderecoPrincipal: boolean
	EnderecoCobranca: boolean
}

const defaultEmpresaEndereco: IEmpresaEnderecoModel = {
  Id: undefined,
	IdEmpresa: undefined,
	Cep: '',
	Estado: '',
	Cidade: '',
	Bairro: '',
	Endereco: '',
	Numero: '',
	Complemento: '',
	EnderecoPrincipal: true,
	EnderecoCobranca: false,
}

export interface IEmpresaModel {  
  Id?: number
  RazaoSocial: string
  NomeFantasia: string
  Cnpj: string
  IdSegmento?: number
  NomeSegmento: string
  Telefone: string
  Celular: string
  Site: string
  EmpresaLogo: any
  EmpresaEndereco: IEmpresaEnderecoModel[]
  QtdFuncionarios: number
  PorteEmpresa: number
}

const initValues: IEmpresaModel = {
  Id: undefined,
  RazaoSocial: '',
  NomeFantasia: '',
  Cnpj: '',
  IdSegmento: undefined,
  NomeSegmento: '',
  Telefone: '',
  Celular: '',
  Site: '',
  EmpresaLogo: undefined,
  EmpresaEndereco: [ defaultEmpresaEndereco ],
  QtdFuncionarios: 0,
  PorteEmpresa: 0
}

const phoneRegExp = /^(?:(?:\+|00)?(55)\s?)?(?:(?:\(?[1-9][0-9]\)?)?\s?)?(?:((?:9\d|[2-9])\d{3})-?(\d{4}))$/

const defaultValidationSchemas = Yup.object().shape({
      Cnpj: Yup.string().test('cnpj', 'CNPJ inválido', (val = '') => {
        if (!val) return true
        const validation = useValidation()
        return validation.CNPJ(val)
      }).required('O CNPJ é obrigatório'),
      RazaoSocial: Yup.string().required('A razão social é obrigatória'),
      NomeFantasia: Yup.string().required('O nome fantasia é obrigatório'),
      Telefone: Yup.string().matches(phoneRegExp, 'Telefone inválido')
      .required('O telefone é obrigatório'),
      Celular: Yup.string().matches(phoneRegExp, 'Celular inválido'),
      EmpresaEndereco: Yup.array().of(
        Yup.object().shape({
          Cep: Yup.string().required('O CEP é obrigatório'),
          Estado: Yup.string().required('O estado é obrigatório'),
          Cidade: Yup.string().required('A cidade é obrigatória'),
          Bairro: Yup.string().required('O bairro é obrigatório'),
          Endereco: Yup.string().required('O endereço é obrigatório'),
          Numero: Yup.string().required('O número é obrigatório'),
        })
      ),
      IdSegmento: Yup.string().required('O segmento de mercado é obrigatório'),
      NomeSegmento: Yup.string().when('IdSegmento', {
        is: '2',
        then: Yup.string().nullable()
        .required('O nome do segmento é obrigatório')
        .matches(/^[a-zA-Z\sáÁàÀâÂãÃéÉèÈêÊíÍìÌîÎóÓòÒôÔõÕúÚùÙûÛüÜñÑçÇ]*$/, 'O nome do segmento não pode conter números, caracteres especiais ou espaços consecutivos')
        .matches(/^(?!(.*\s){3})/, 'O nome do segmento contém muitos espaços consecutivos'),
        otherwise: Yup.string().nullable()
        .matches(/^[a-zA-Z\sáÁàÀâÂãÃéÉèÈêÊíÍìÌîÎóÓòÒôÔõÕúÚùÙûÛüÜñÑçÇ]*$/, 'O nome do segmento não pode conter números, caracteres especiais ou espaços consecutivos')
        .matches(/^(?!(.*\s){3})/, 'O nome do segmento contém muitos espaços consecutivos'),
      }),
      QtdFuncionarios: Yup.number().min(0, "A quantidade não pode ser negativa."),    
  })

export {defaultValidationSchemas, defaultEmpresaEndereco, initValues}
