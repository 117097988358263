/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useCallback, useEffect, useRef, useState} from 'react'
import {Field, ErrorMessage, FormikProps} from 'formik'
import { initValues, IWizardModel, StepProps } from '../helpers/PesquisaHelper'
import { useHttpClient } from '../../../../modules/services/Bundle'
import { EnumPesquisaStatus, RequestMethod } from '../../../../modules/services/core/_enums'
import {AdapterDayjs} from '@mui/x-date-pickers/AdapterDayjs'
import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider'
import {DatePicker} from '@mui/x-date-pickers/DatePicker'
import {ptBR} from '@mui/x-date-pickers/locales'
import br from 'dayjs/locale/pt-br'
import dayjs from 'dayjs'
import 'react-quill/dist/quill.snow.css';
import ReactQuill from 'react-quill'

interface Step1Props {
  formik: FormikProps<IWizardModel>,
  temas: any[],
  setTemas: React.Dispatch<React.SetStateAction<any[]>>,
  escalas: any[],
  setEscalas: React.Dispatch<React.SetStateAction<any[]>>,
}

const Step1: FC<Step1Props> = ({formik, temas, setTemas, escalas, setEscalas}) => {
  const httpClient = useHttpClient()
  let [pesquisas, setPesquisas] = useState<any[]>([])
  const datePickerRef = useRef()

  const onChangeDatePickerStart = (e: any) => {
    if (e && dayjs(e.$d).toString() !== 'Invalid Date') {
      formik.setFieldValue('DataInicial', dayjs(e.$d).format('YYYY-MM-DD'))
    } else if (e === null) {
      formik.setFieldValue('DataInicial', undefined)
    }
  }

  const onChangeDatePickerEnd = (e: any) => {
    if (e && dayjs(e.$d).toString() !== 'Invalid Date'){
      formik.setFieldValue('DataFinal', dayjs(e.$d).format('YYYY-MM-DD'))
    }
    else if (e === null) {
      formik.setFieldValue('DataFinal', undefined)
    }
  }

  const onChangeSelectPesquisa = async (e:any) => {
    const valor = e.target.value
    
    if (valor) {
      const response = await httpClient.request({
        method: RequestMethod.GET,
        endpoint: '/Pesquisa/Pesquisa/Obter',
        queryObject: {
          idPesquisa: valor
        }
      })

      if (response.success && response.payload) {

        let newValues = {...initValues}

        newValues = response.payload

        newValues.Id = 0
        newValues.Nome = '' 

        newValues.DataInicial = undefined
        newValues.DataFinal = undefined

        for (let i = 0; i < newValues.Participantes.length; i++) {
          newValues.Participantes[i].Id = 0
          newValues.Participantes[i].IdPesquisa = 0
        }
        
        formik.setValues(newValues)
      }
    }else {
      let newValues = {...initValues}
      newValues.IdTema = formik.values.IdTema
      formik.setValues(newValues)
    }
  }

  return (
    <>
      <div className='w-100'>
        <h3 className='stepper-title'>Identificação da Pesquisa</h3>
          <div className="row">
            <div className='col-lg-12 fv-row mb-5'>
              <label className='form-label required'>Nome da Pesquisa</label>
              <Field 
                name={`Nome`}
                maxLength={60}
                placeholder='Nome'
                className='form-control form-control-lg form-control-solid'
                value={formik.values.Nome}
                disabled={formik.values.Status === EnumPesquisaStatus.Finalizada || formik.values.Status === EnumPesquisaStatus.EmAndamento || formik.values.Status === EnumPesquisaStatus.Suspensa}
              />
              <div className='text-danger mt-2'>
                <ErrorMessage name={`Nome`}/>
              </div>
            </div>

            <div className='col-lg-6 fv-row mb-5'>
              <label className='form-label required'>Selecione o Tema</label>
              <Field
                as='select'
                name='IdTema'
                className='form-select form-select-lg form-select-solid'
                value={formik.values.IdTema}
                onChange={(e : React.ChangeEvent<HTMLSelectElement>) => { 
                  formik.setFieldValue('IdTema', parseInt(e.target.value))
                  if (formik.values.IdEscala > 0) formik.setFieldValue('IdEscala', 0)
                }}
                disabled={formik.values.Status === EnumPesquisaStatus.Finalizada || formik.values.Status === EnumPesquisaStatus.EmAndamento || formik.values.Status === EnumPesquisaStatus.Suspensa}
              >
                <option value={0}>Selecione...</option>
                {temas && temas.length > 0
                && temas.map((data: any, index: any) => {
                    return (
                      <option key={`tema-${index}`} value={data.Id}>
                        {data.Nome}
                      </option>
                    )
                  })
                }
              </Field>
              <div className='text-danger mt-2'>
                <ErrorMessage name='IdTema' />
              </div>
            </div>

            <div className='col-lg-6 fv-row mb-5'>
              <label className={`form-label ${formik.values.Id > 0 || pesquisas.length === 0 ? 'text-gray-400' : 'text-gray-800'} `}>Clonar uma pesquisa existente</label>
              <select
                className='form-select form-select-lg form-select-solid'
                onChange={(e:any) => onChangeSelectPesquisa(e)}
                disabled={formik.values.Id > 0 || pesquisas.length === 0}
              >
                <option value=''>{formik.values.Id > 0 || pesquisas.length === 0 ? '' : 'Selecione...'}</option>

                {formik.values.Id === 0 && pesquisas && pesquisas.length > 0
                && pesquisas.map((data: any, index: any) => {
                    return (
                      <option key={`pesquisa-${index}`} value={data.Id}>
                        {data.Nome}
                      </option>
                    )
                  })
                }
                
              </select>
            </div>

            <div className="title_box col-lg-12 fv-row mb-5" id="subject">
              <div id="title" className='required'>Período de realização da pesquisa</div>
              <div id="content">
                <div className='row justify-content-center'>
                  <div className='col-lg-6 mb-5'>
                    <LocalizationProvider adapterLocale={br.name} dateAdapter={AdapterDayjs}>
                      <DatePicker
                        label={'Início'}
                        format='DD/MM/YYYY'
                        inputRef={datePickerRef.current}
                        disabled={formik.values.Status === EnumPesquisaStatus.Finalizada || formik.values.Status === EnumPesquisaStatus.EmAndamento}
                        {...formik.getFieldProps(`DataInicial`)}
                        localeText={
                          ptBR.components.MuiLocalizationProvider.defaultProps.localeText
                        }
                        onChange={(e: any) => onChangeDatePickerStart(e)}
                        className='date-picker-color w-100 date-picker-pesquisa'
                        value={
                          formik.values.DataInicial !== undefined
                          ? formik.values.DataInicial
                          : null
                        }
                        slotProps={{
                          textField: {size: 'small', margin: 'dense'},
                        }}
                      />
                    </LocalizationProvider>
                    <div className='text-danger mt-2'>
                      <ErrorMessage name={`DataInicial`} />
                    </div>
                  </div>

                  <div className='col-lg-6 mb-5'>
                    <LocalizationProvider adapterLocale={br.name} dateAdapter={AdapterDayjs}>
                      <DatePicker
                        label={'Término'}
                        format='DD/MM/YYYY'
                        inputRef={datePickerRef.current}
                        disabled={formik.values.Status === EnumPesquisaStatus.Finalizada || formik.values.Status === EnumPesquisaStatus.EmAndamento}
                        {...formik.getFieldProps(`DataFinal`)}
                        localeText={
                          ptBR.components.MuiLocalizationProvider.defaultProps.localeText
                        }
                        onChange={(e: any) => onChangeDatePickerEnd(e)}
                        className='date-picker-color w-100 date-picker-pesquisa'
                        value={
                          formik.values.DataFinal !== undefined
                          ? formik.values.DataFinal
                          : null
                        }
                        slotProps={{
                          textField: {size: 'small', margin: 'dense'},
                        }}
                      />
                    </LocalizationProvider>
                    <div className='text-danger mt-2'>
                      <ErrorMessage name={`DataFinal`} />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="title_box col-lg-12 fv-row mb-5" id="subject">
              <div id="title" className='required'>Mês de Referência</div>
              <div id="content">
                <div className='row justify-content-center'>
                  <div className='col-lg-6 fv-row mb-5'>
                    <input 
                      placeholder='Mês de Referência'
                      className='form-control form-control-lg form-control-solid text-center'
                      value={formik.values.DataInicial !== undefined ? dayjs(formik.values.DataInicial).locale('pt-br').format('MMMM, YYYY').toUpperCase() : ''}
                      disabled={true}
                    />
                  </div>        
                </div>
              </div>
            </div>

            <div className='col-lg-12 fv-row mb-5'>
              <label className='form-label required'>Selecione a Escala</label>
              <Field
                as='select'
                name='IdEscala'
                className='form-select form-select-lg form-select-solid'
                disabled={formik.values.Status === EnumPesquisaStatus.Finalizada || formik.values.Status === EnumPesquisaStatus.EmAndamento || formik.values.Status === EnumPesquisaStatus.Suspensa}
              >
                <option value={0}>Selecione...</option>

                {escalas && escalas.length > 0 && escalas.filter(e => (temas.find(t => t.Id == formik.values.IdTema) !== undefined && temas.find(t => t.Id == formik.values.IdTema).UtilizaQuestao ? (e.Id === 3) : true)).map((data: any, index: any) => {
                    return (
                      <option key={`escala-${index}`} value={data.Id}>
                        {data.Nome}
                      </option>
                    )
                  })
                }
                
              </Field>
              <div className='text-danger mt-2'>
                <ErrorMessage name='IdEscala' />
              </div>
            </div>

            <div className='col-lg-12 fv-row mb-5'>
              <label className='form-label required'>Termos e condições</label>
              <ReactQuill  
                theme="snow"
                placeholder='Termos e condições'                
                value={formik.values.Termos}
                onChange={(value: string) => { formik.setFieldValue('Termos', value) }}
                readOnly={formik.values.Status === EnumPesquisaStatus.Finalizada || formik.values.Status === EnumPesquisaStatus.EmAndamento || formik.values.Status === EnumPesquisaStatus.Suspensa}
              />
              <div className='text-danger mt-2'>
                <ErrorMessage name='Termos' />
              </div>
            </div>
          </div>
      </div>
    </>
  )
}

export {Step1}
