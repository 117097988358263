import {useEffect, useRef, useState} from 'react'
import {KTSVG} from '../../../../_metronic/helpers'
import {Step1} from './stepsTema/Step1'
import {Step2} from './stepsTema/Step2'
import {Step3} from './stepsTema/Step3'
import {Step4} from './stepsTema/Step4'
import {StepperComponent} from '../../../../_metronic/assets/ts/components'
import {FormikValues, useFormik, FormikProvider} from 'formik'
import {
  IWizardModel,
  defaultValidationSchemas,
  defaultTema,
  IPesquisaRespostaModel,
} from './helpers/CrudTemaHelper'
import {useAlert, useHttpClient} from '../../../modules/services/Bundle'
import {EnumStepsTema, RequestMethod} from '../../../modules/services/core/_enums'
import {useAuth} from '../../../modules/auth'
import {useNavigate} from 'react-router'
import { PesquisaPainel } from './partials/PesquisaPainelCrud'
import $ from 'jquery'
import { ModalAddResp } from './partials/ModalAddRespCrud'
import { ModalArgumentarResp } from './partials/ModalArgumentarRespCrud'
import { ModalAprimorarResp } from './partials/ModalAprimorarRespCrud'
import { Link, useLocation } from 'react-router-dom'
import { ModalExcluirPerguntas } from './partials/ModalExcluirPerguntasCrud'

interface dados {
  idTema: number
  tipoRedirect: string
}

const initValues: IWizardModel = {
  empresaId: 0,
  tema: [],
  temaBuscar: defaultTema,
  topicosBuscar: 0,
  temaBuscarDimensao: defaultTema,
  dimensoesBuscar: 0,
  numeroPerguntas: 0,
}

const CrudTema = () => {
  const stepperRef = useRef<HTMLDivElement | null>(null)
  const stepper = useRef<StepperComponent | null>(null)
  const [validationSchemas] = useState(defaultValidationSchemas)
  const [currentSchema, setCurrentSchema] = useState(validationSchemas[0])
  const {auth, saveAuth} = useAuth()
  const httpClient = useHttpClient()
  const [nameStep, setNameStep] = useState('Tema')
  const [tipoRedirect, setTipoRedirect] = useState('')
  const [numeroStep, setNumeroStep] = useState(0)
  const [segmentos, setSegmentos] = useState<any[]>([])
  const location = useLocation();
  const navigate = useNavigate()
  const state = location.state as dados
  const informacao: dados = state
  let [tituloSelecionado, setTituloSelecionado] = useState<number | undefined>()
  let [textoSelecionado, setTextoSelecionado] = useState<number | undefined>()
  let [textoSendoEditado, setTextoSendoEditado] = useState(false)
  let [mostrarPainel, setMostrarPainel] = useState(true)
  const [textoBackup, setTextoBackup] = useState<IPesquisaRespostaModel | undefined>()
  const [abrirModalAddTexto, setAbrirModalAddTexto] = useState<boolean>(false)
  const [abrirModalArgumentarTexto, setAbrirModalArgumentarTexto] = useState<boolean>(false)
  const [abrirModalAprimorarTexto, setAbrirModalAprimorarTexto] = useState<boolean>(false)
  const [abrirModalExcluirPerguntas, setAbrirModalExcluirPerguntas] = useState<boolean>(false)
  const [indexSelecionado, setIndexSelecionado] = useState<number | undefined>(undefined)
  const questoesRef = useRef(null)
  const alert = useAlert();

  const loadStepper = () => {
    stepper.current = StepperComponent.createInsance(stepperRef.current as HTMLDivElement)
  }

  const resetFieldsToPainel = () => {
    setTituloSelecionado(undefined)
    setTextoSelecionado(undefined)
    setTextoSendoEditado(false)
    setTextoBackup(undefined)
    setAbrirModalAddTexto(false)
    setAbrirModalArgumentarTexto(false)
    setAbrirModalAprimorarTexto(false)
    setAbrirModalExcluirPerguntas(false)
  }

  const prevStep = () => {
    if (!stepper.current) {
      return
    }
    if (stepper.current.currentStepIndex === 1) {
      navigate('/pesquisa-ia/temas')
      return
    }
    stepper.current.goPrev()
    
    setMostrarPainel(informacao.tipoRedirect === 'Visualizar' || (stepper.current.currentStepIndex - 1) >= 3 ? false : true)
    
    setNumeroStep(stepper.current.currentStepIndex - 1)
    resetFieldsToPainel()
    setNameStep(EnumStepsTema[stepper.current.currentStepIndex - 1])
  }

  const submitStep = async (values: IWizardModel, actions: FormikValues) => {
    
    if (!stepper.current) {
      return
    }

    if(stepper.current.currentStepIndex == 3){
      const inputs = document.querySelectorAll('input.questao');

      for (let i = 0; i < inputs.length; i++) {
        const inputElement = inputs[i] as HTMLInputElement
        if (inputElement.value.trim() === '') {
          return alert.createMessage({
            html: "Preencha todos os campos de questões para continuar!",
            icon: 'warning'
           })
        }
      }
    }
    
    setMostrarPainel(informacao.tipoRedirect === 'Visualizar' || (stepper.current.currentStepIndex) >= 3 ? false : true)
    setNameStep(EnumStepsTema[stepper.current.currentStepIndex])
    setNumeroStep(stepper.current.currentStepIndex)
    resetFieldsToPainel()
    if (stepper.current.currentStepIndex !== stepper.current.totatStepsNumber) {
      let sucesso = true
      //Salvar Step
      if (sucesso) stepper.current.goNext()
      return
    }
    if (stepper.current.currentStepIndex === stepper.current.totatStepsNumber && auth) {
      navigate('/pesquisa-ia/temas')
    }
  }

  const formik = useFormik({
    initialValues: {...initValues},
    validationSchema: currentSchema,
    onSubmit: submitStep,
  })

  const autoSizeTextArea = () => {
    $('.textarea-texto-pesquisa').each(function (i, element) {
      let target = element as HTMLTextAreaElement
      target.style.height = '90px'

      if (target.scrollHeight && target.scrollHeight > 90)
        target.style.height = target.scrollHeight + 'px'
    })
  }

  useEffect(() => {
    autoSizeTextArea()
  }, [formik])

  const fetchTema = async (IdTema:number) => {
    const response = await httpClient.request({
      method: RequestMethod.GET,
      endpoint: '/Pesquisa/Tema/ObterDadosTema',
      queryObject: {
        idTema: IdTema
      }
    })

    if (response.success && response.payload) {
      let tema = response.payload

      let newValues = {...initValues}
      newValues.tema = []
      newValues.tema.push(tema)

      formik.setValues(newValues)
    }
  }

  useEffect(() => {
    if (informacao && informacao.idTema && informacao.tipoRedirect !== '') {
      fetchTema(informacao.idTema)
      setTipoRedirect(informacao.tipoRedirect)
      if (informacao.tipoRedirect === 'Visualizar') {
        setMostrarPainel(false)
      }
    }
  }, [])

  useEffect(() => {
    if (!stepperRef.current) {
      return
    }
    loadStepper()
  }, [stepperRef])

  return (
    <>
      <ModalAddResp
        abrirModal={abrirModalAddTexto}
        dimensaoSelecionada={indexSelecionado} 
        tipoResposta={numeroStep}
        setAbrirModal={setAbrirModalAddTexto}
        formik={formik}
        tituloSelecionado={tituloSelecionado}
        setTituloSelecionado={setTituloSelecionado}
        textoSelecionado={textoSelecionado}
        setTextoSelecionado={setTextoSelecionado}
        textoSendoEditado={textoSendoEditado}
        setTextoSendoEditado={setTextoSendoEditado}
      />

      <ModalArgumentarResp
        abrirModal={abrirModalArgumentarTexto}
        dimensaoSelecionada={indexSelecionado} 
        tipoResposta={numeroStep}
        setAbrirModal={setAbrirModalArgumentarTexto}
        formik={formik}
        tituloSelecionado={tituloSelecionado}
        setTituloSelecionado={setTituloSelecionado}
        respostaSelecionada={textoSelecionado}
        setRespostaSelecionada={setTextoSelecionado}
        respostaSendoEditada={textoSendoEditado}
        setRespostaSendoEditada={setTextoSendoEditado}
      />

      <ModalAprimorarResp
        abrirModal={abrirModalAprimorarTexto}
        dimensaoSelecionada={indexSelecionado} 
        tipoResposta={numeroStep}
        setAbrirModal={setAbrirModalAprimorarTexto}
        formik={formik}
        tituloSelecionado={tituloSelecionado}
        setTituloSelecionado={setTituloSelecionado}
        respostaSelecionada={textoSelecionado}
        setRespostaSelecionada={setTextoSelecionado}
        respostaSendoEditada={textoSendoEditado}
        setRespostaSendoEditada={setTextoSendoEditado}
      />

      <ModalExcluirPerguntas
        abrirModal={abrirModalExcluirPerguntas}
        setAbrirModal={setAbrirModalExcluirPerguntas}
        formik={formik}         
      />
    
      <PesquisaPainel 
        formik={formik}
        textoBackup={textoBackup}
        dimensaoSelecionada={indexSelecionado} 
        setTextostaBackup={setTextoBackup}
        mostrarPainel={mostrarPainel}
        numeroStep={numeroStep}
        setNumeroStep={setNumeroStep}
        tituloSelecionado={tituloSelecionado}
        setTituloSelecionado={setTituloSelecionado}
        textoSelecionado={textoSelecionado}
        setTextoSelecionado={setTextoSelecionado}
        textoSendoEditado={textoSendoEditado}
        setTextoSendoEditado={setTextoSendoEditado}
        abrirModalAddTexto={abrirModalAddTexto}
        setAbrirModalAddTexto={setAbrirModalAddTexto}
        abrirModalArgumentarTexto={abrirModalArgumentarTexto}
        setAbrirModalArgumentarTexto={setAbrirModalArgumentarTexto}
        abrirModalAprimorarTexto={abrirModalAprimorarTexto}
        setAbrirModalAprimorarTexto={setAbrirModalAprimorarTexto}
        abrirModalExcluirPerguntas={abrirModalExcluirPerguntas}
        setAbrirModalExcluirPerguntas={setAbrirModalExcluirPerguntas}
      />

      <ol className='breadcrumb breadcrumb-line text-muted fs-6 mt-0 fw-semibold mb-10'>
      <li className='breadcrumb-item h2 hover-link-name-dash text-gray-600'><Link className="text-gray-600" to='/pesquisa-ia/temas'>Pesquisa</Link></li>
        <li className='breadcrumb-item h2 hover-link-name-dash text-gray-600'><Link className="text-gray-600" to='/pesquisa-ia/temas'>Temas</Link></li>
        <li className='breadcrumb-item h2 hover-link-name-dash text-gray-600'>Criar</li>
        <li className='breadcrumb-item h2 text-dark'>{nameStep}</li>
      </ol>

      <div
        ref={stepperRef}
        className='stepper stepper-pills'
        id='kt_create_account_stepper'
      >
        {/* begin::Aside*/}
        <div className='card d-flex justify-content-center mb-5 w-100 me-5'>
          {/* begin::Wrapper*/}
          <div className='card-body'>
            {/* begin::Nav*/}
            <div className='stepper-nav  mat-horizontal-content-container flex-center flex-wrap'>

              {/* begin::Step 1*/}
              <div className='stepper-item mx-8 my-4 current' data-kt-stepper-element='nav'>
                {/* begin::Wrapper*/}
                <div className='stepper-wrapper d-flex align-items-center flex-column'>
                  {/* begin::Icon*/}
                  <div className='stepper-icon step-counter mx-0 w-40px h-40px'>
                    <i className='stepper-check fas fa-check'></i>
                    <span className='stepper-number color-white-step-number'>1</span>
                  </div>
                  {/* end::Icon*/}

                  {/* begin::Label*/}
                  <div className='stepper-label '>
                    <h3 className='stepper-title'>Tema</h3>
                  </div>
                  {/* end::Label*/}
                </div>
                {/* end::Wrapper*/}

                {/* begin::Line*/}
                <div className='stepper-line h-40px'></div>
                {/* end::Line*/}
              </div>
              {/* end::Step 1*/}

              {/* begin::Step 2*/}
              <div className='stepper-item mx-8 my-4 ' data-kt-stepper-element='nav'>
                {/* begin::Wrapper*/}
                <div className='stepper-wrapper d-flex align-items-center flex-column'>
                  {/* begin::Icon*/}
                  <div className='stepper-icon step-counter mx-0 w-40px h-40px'>
                    <i className='stepper-check fas fa-check'></i>
                    <span className='stepper-number color-white-step-number'>2</span>
                  </div>
                  {/* end::Icon*/}

                  {/* begin::Label*/}
                  <div className='stepper-label'>
                    <h3 className='stepper-title'>Dimensões</h3>
                  </div>
                  {/* end::Label*/}
                </div>
                {/* end::Wrapper*/}

                {/* begin::Line*/}
                <div className='stepper-line h-40px'></div>
                {/* end::Line*/}
              </div>
              {/* end::Step 2*/}

              {/* begin::Step 3*/}
              <div className='stepper-item mx-8 my-4' data-kt-stepper-element='nav'>
                {/* begin::Wrapper*/}
                <div className='stepper-wrapper d-flex align-items-center flex-column'>
                  {/* begin::Icon*/}
                  <div className='stepper-icon step-counter mx-0 w-40px h-40px'>
                    <i className='stepper-check fas fa-check'></i>
                    <span className='stepper-number color-white-step-number'>3</span>
                  </div>
                  {/* end::Icon*/}

                  {/* begin::Label*/}
                  <div className='stepper-label'>
                    <h3 className='stepper-title'>Perguntas</h3>
                  </div>
                  {/* end::Label*/}
                </div>
                {/* end::Wrapper*/}

                {/* begin::Line*/}
                <div className='stepper-line h-40px'></div>
                {/* end::Line*/}
              </div>
              {/* end::Step 3*/}

              {/* begin::Step 4*/}
              <div className='stepper-item mx-8 my-4' data-kt-stepper-element='nav'>
                {/* begin::Wrapper*/}
                <div className='stepper-wrapper d-flex align-items-center flex-column'>
                  {/* begin::Icon*/}
                  <div className='stepper-icon step-counter mx-0 w-40px h-40px'>
                    <i className='stepper-check fas fa-check'></i>
                    <span className='stepper-number color-white-step-number'>4</span>
                  </div>
                  {/* end::Icon*/}

                  {/* begin::Label*/}
                  <div className='stepper-label'>
                    <h3 className='stepper-title'>Revisão</h3>
                  </div>
                  {/* end::Label*/}
                </div>
                {/* end::Wrapper*/}
              </div>
              {/* end::Step 4*/}
            </div>
            {/* end::Nav*/}
          </div>
          {/* end::Wrapper*/}
        </div>
        {/* begin::Aside*/}

        <div className='d-flex flex-row-fluid flex-center bg-body rounded'>
          <FormikProvider value={formik}>
            <form
              className={`py-20 w-100 px-9`}
              noValidate
              onSubmit={formik.handleSubmit}
            >
              <div className='current' data-kt-stepper-element='content'>
                <Step1 
                  formik={formik}
                  tipoRedirect={tipoRedirect}
                  indexSelecionado={indexSelecionado}
                  setIndexSelecionado={setIndexSelecionado}
                  numeroStep={numeroStep} 
                  tituloSelecionado={tituloSelecionado}
                  setTituloSelecionado={setTituloSelecionado}
                  textoSelecionado={textoSelecionado}
                  setTextoSelecionado={setTextoSelecionado}
                  textoSendoEditado={textoSendoEditado}
                  setTextoSendoEditado={setTextoSendoEditado}
                  textoBackup={textoBackup}
                  setTextoBackup={setTextoBackup}
                />
              </div>

              <div data-kt-stepper-element='content' ref={questoesRef}>
                <Step2 
                  formik={formik}
                  tipoRedirect={tipoRedirect}
                  indexSelecionado={indexSelecionado}
                  setIndexSelecionado={setIndexSelecionado}
                  numeroStep={numeroStep} 
                  tituloSelecionado={tituloSelecionado}
                  setTituloSelecionado={setTituloSelecionado}
                  textoSelecionado={textoSelecionado}
                  setTextoSelecionado={setTextoSelecionado}
                  textoSendoEditado={textoSendoEditado}
                  setTextoSendoEditado={setTextoSendoEditado}
                  textoBackup={textoBackup}
                  setTextoBackup={setTextoBackup}
                />
              </div>

              <div data-kt-stepper-element='content'>
                <Step3 
                  formik={formik}
                  tipoRedirect={tipoRedirect}
                  indexSelecionado={indexSelecionado}
                  setIndexSelecionado={setIndexSelecionado}
                  numeroStep={numeroStep} 
                  tituloSelecionado={tituloSelecionado}
                  setTituloSelecionado={setTituloSelecionado}
                  textoSelecionado={textoSelecionado}
                  setTextoSelecionado={setTextoSelecionado}
                  textoSendoEditado={textoSendoEditado}
                  setTextoSendoEditado={setTextoSendoEditado}
                  textoBackup={textoBackup}
                  setTextoBackup={setTextoBackup}
                />
              </div>

              <div data-kt-stepper-element='content'>
                <Step4 formik={formik} />
              </div>

              <div className='d-flex flex-stack pt-10'>
                <div className='mr-2'>
                  <button
                    onClick={prevStep}
                    type='button'
                    className='btn btn-lg btn-pesquisa me-3'
                    // data-kt-stepper-action='previous'
                  >
                    <KTSVG
                      path='/media/icons/duotune/arrows/arr063.svg'
                      className='svg-icon-4 me-1 text-white'
                    />
                    Voltar
                  </button>
                </div>

                <div>
                  <button
                    type='button'
                    onClick={() => formik.handleSubmit()}
                    className='btn btn-lg btn-pesquisa me-3'
                  >
                    <span className='indicator-label'>
                      {(stepper.current?.currentStepIndex ?? 0) ===
                      (stepper.current?.totatStepsNumber ?? 4)
                        ? 'Finalizar'
                        : 'Continuar'}
                      <KTSVG
                        path='/media/icons/duotune/arrows/arr064.svg'
                        className='svg-icon-3 ms-2 text-white me-0'
                      />
                    </span>
                  </button>
                </div>
              </div>
            </form>
          </FormikProvider>
        </div>
      </div>
    </>
  )
}

export {CrudTema}
