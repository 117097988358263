import { FormikProps } from 'formik'
import * as Yup from 'yup'

export interface ITopicoValorModel {
  Id: number
  IdDimensao: number
  Texto: string
}

export interface ITopicoModel {
  Id:number             
  IdTema:number             
  Ordem:number             
  IdTopicoValorAtual?:number            
  TopicoValorAtual:ITopicoValorModel   
  TopicoValores:ITopicoValorModel[] 
}

export interface IPesquisaValorModel {
  Id: number
  IdEntidade: number
  Texto: string
}

export interface IPesquisaRespostaPerguntaModel {
  Id:number             
  IdEntidade:number             
  TipoResposta:number             
  Ordem:number       
  Nome:string           
  IdPesquisaValorAtual?:number            
  PesquisaValorAtual:IPesquisaValorModel   
  QuestaoA?: string
  QuestaoB?: string
  PesquisaValores:IPesquisaValorModel[]
  Perguntas:IPesquisaRespostaPerguntaModel[]
  DimensaoDescricoes: IPesquisaDimensaoDescricaoModel[] 
}

export interface IPesquisaDimensaoDescricaoModel {
  Id:number             
  IdEntidade:number             
  TipoResposta:number             
  Ordem:number       
  Nome:string           
  IdPesquisaValorAtual?:number            
  PesquisaValorAtual:IPesquisaValorModel
  PesquisaValores:IPesquisaValorModel[]
  Perguntas:IPesquisaRespostaPerguntaModel[]
  DimensaoDescricoes: IPesquisaDimensaoDescricaoModel[] 
}

export interface IPesquisaRespostaModel {
  Id:number             
  IdEntidade:number 
  TipoResposta:number              
  Ordem:number
  Nome:string             
  IdPesquisaValorAtual?:number            
  PesquisaValorAtual:IPesquisaValorModel   
  PesquisaValores:IPesquisaValorModel[] 
  Perguntas:IPesquisaRespostaPerguntaModel[]
  DimensaoDescricoes: IPesquisaDimensaoDescricaoModel[] 
}

export interface IDimensaoValorModel {
  Id: number
  IdDimensao: number
  Texto: string
}

export interface IDimensaoPerguntaValorModel {
  Id: number
  IdDimensaoPergunta: number
  Texto: string
}

export interface IDimensaoPerguntaModel {
  Id:number                      
  IdDimensao:number                      
  Ordem:number                      
  IdDimensaoPerguntaValorAtual?:number                    
  DimensaoPerguntaValorAtual:IDimensaoPerguntaValorModel  
  DimensaoPerguntaValores:IDimensaoPerguntaValorModel[]
}

export interface IDimensaoModel {  
  Id: number              
  IdTema: number              
  Ordem: number              
  Nome: string
  DimensaoDescricao: IDimensaoDescricaoModel[]
  DimensaoPergunta: IDimensaoPerguntaModel[]
}

export interface IDimensaoDescricaoModel {  
  Id: number              
  IdDimensao: number              
  Ordem: number      
  IdDimensaoValorAtual?: number             
  DimensaoValorAtual: IDimensaoValorModel  
  DimensaoValores: IDimensaoValorModel[]
}

export interface ITemaModel {  
  Id: number        
  IdEmpresa: number        
  Nome: string     
  UtilizaQuestao: boolean
  ExistePesquisa: boolean
  Dimensao: IPesquisaRespostaModel[]
  Topico: IPesquisaRespostaModel[]   
}

export interface IWizardModel {
  empresaId: number
  tema: ITemaModel[]
  temaBuscar: ITemaModel
  topicosBuscar: number
  temaBuscarDimensao: ITemaModel,
  dimensoesBuscar: number
  numeroPerguntas: number
  dimensaoSelecionada?: number
}

const defaultTema: ITemaModel = {
  Id: 0,        
  IdEmpresa: 0,        
  Nome: '',
  UtilizaQuestao: false,
  ExistePesquisa: false,
  Dimensao: [],
  Topico: []
}

export interface StepProps {
  formik: FormikProps<IWizardModel>,
}

const defaultValidationSchemas = [
  Yup.object({   
    tema: Yup.array().min(1, 'É obrigatório ter no mínimo 1 tema').of(
      Yup.object().shape({
        Nome: Yup.string().required('O tema é obrigatório'),
        Topico: Yup.array().min(1, 'É obrigatório ter no mínimo 1 tópico para o tema'),
      })
    ),
  }),
  Yup.object({
    numeroDimensoes: Yup.number().min(1, "O número de dimensões deve ser maior que zero.").required('O número de dimensões é obrigatório'),    
    numeroPerguntas: Yup.number().min(1, "O número de perguntas deve ser maior que zero.").required('O número de perguntas é obrigatório'),    
    tema: Yup.object().shape({
      Dimensao: Yup.array().min(1, 'É obrigatório ter no mínimo 1 dimensão para o tema'),
    })
  }),
]

export {defaultValidationSchemas, defaultTema}
