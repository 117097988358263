import { FC } from 'react'
import {Field, ErrorMessage, FieldArray} from 'formik'
import { StepProps, defaultWizardArea } from '../helpers/ConfiguracoesIniciaisHelper'
import { useAlert, useHttpClient } from '../../../../modules/services/Bundle'
import { RequestMethod } from '../../../../modules/services/core/_enums'
import { AbrirModalVideo } from '../../../toolbar/AbrirModalVideo'
import $ from 'jquery';

const Step4: FC<StepProps> = ({formik}) => {

  const stepRef = 'areas'
  const stepNameP = 'Áreas'
  const stepNameS = 'Área'
  const stepDescription = 'Departamentos da empresa'
  const stepModel = defaultWizardArea
  const stepAlertName = 'essa área'
  const stepDeleteEndpoint = '/Gerenciamento/Area/Deletar'

  const httpClient = useHttpClient()
  const alert = useAlert()

  return (
    <>
    <AbrirModalVideo
        videoPath='https://pro4tech.blob.core.windows.net/visualstuff/Wizard%20Areas.mp4?si=maintaining&spr=https&sv=2022-11-02&sr=b&sig=%2BXGQ3oSrIz47q7MQlaScdq3EXzTWPBkvrb%2BTvw%2B9dEs%3D'
        videoTitle='Áreas da empresa'
        descricao='Nessa etapa realize as configurações das áreas de sua empresa.'
      />
      <div className='w-100'>

          <FieldArray
              name={stepRef}
              render={({ insert, remove, push}) => (

                <>
                  <div className='row align-items-center justify-content-between m-0'>
                    <div className="col-lg-12 text-end px-0 pb-10 pb-lg-12">
                      <button type="button" className="btn btn-light-success" onClick={() => {
                        push(stepModel)
                        let scrollHeight = $('.height-step-4').height()
                        $('.scroll-height-step-4').animate({
                          scrollTop: scrollHeight
                        }, (700) );
                        }}>
                        <i className="fas fa-plus"></i> Adicionar
                      </button>
                    </div>
                  </div>

                  <div className='scroll scroll-height-step-4 h-375px px-3'>

                    <div className='height-step-4' id={`accordion-${stepRef}`}>

                    {
                      formik.values[stepRef].map((data: any, index: number)=>{
                        return(
                          <div className="card card-custom border border-3 border-secondary border-1 shadow-sm mb-3" key={`card-${stepRef}-${index}`}>
                            <div className="card-header border-bottom border-secondary border-1 rounded-bottom" id={`card-${stepRef}-header-${index}`}>
                              <div className="card-title">
                                <h3>{(formik.values[stepRef][index].nome) ? formik.values[stepRef][index].nome : `${stepNameS} ${index + 1}`}</h3>                                
                              </div>
                              <div className="card-toolbar w-md-auto">
                                {(formik.values[stepRef].length !== 1) ? 
                                  <button type="button" className="btn btn-icon btn-sm btn-light-danger me-2" onClick={() => {
                                    
                                    alert.createDialog({
                                      html: `Realmente deseja excluir ${stepAlertName}?`,
                                      confirmAction: async () => {

                                        if(formik.values[stepRef][index].id && formik.values[stepRef][index].id !== 0){
                                          const result = await httpClient.request({
                                            method: RequestMethod.DELETE,
                                            endpoint: stepDeleteEndpoint,
                                            data: formik.values[stepRef][index].id
                                          })
    
                                          if(result.success) remove(index)
                                        }
                                        else remove(index)
                                        
                                      }
                                    })

                                  }}>
                                    <i className="fas fa-trash"></i>
                                  </button> : ''
                                } 
                                <button type="button" className="btn btn-icon btn-sm btn-accordion btn-light-primary" data-bs-toggle="collapse" data-bs-target={`#card-${stepRef}-collapse-${index}`} aria-expanded="true" aria-controls={`card-${stepRef}-collapse-${index}`} >
                                  <i className='fas fa-arrow-down'></i>
                                  <i className='fas fa-arrow-up'></i>
                                </button>                   
                              </div>
                            </div>
                            <div id={`card-${stepRef}-collapse-${index}`} className="collapse show" aria-labelledby={`card-${stepRef}-header-${index}`} data-bs-parent={`#accordion-${stepRef}`}>
                              <div className="card-body">
                                <div className="row">

                                  <div className="col-lg-12 fv-row mb-5">
                                    <label className='form-label required'>Nome</label>
                                    <Field
                                      as='input'
                                      name={`${stepRef}.${index}.nome`}
                                      placeholder='Nome'
                                      className='form-control form-control-lg form-control-solid'
                                      maxLength={40}
                                      value={formik.values[stepRef][index].nome}
                                    />
                                    <div className='text-danger mt-2'>
                                      <ErrorMessage name={`${stepRef}.${index}.nome`} />
                                    </div> 
                                  </div>

                                  <div className="col-lg-12 fv-row">
                                    <label className='form-label'>Descrição</label>
                                    <Field 
                                      as='textarea'
                                      rows={3}
                                      name={`${stepRef}.${index}.descricao`}
                                      placeholder='Descrição'
                                      className='form-control form-control-lg form-control-solid'
                                      maxLength={250}
                                      value={formik.values[stepRef][index].descricao}
                                    />  
                                    <div className='text-danger mt-2'>
                                      <ErrorMessage name={`${stepRef}.${index}.descricao`} />
                                    </div>                   
                                  </div>                

                                </div>
                              </div>   
                            </div>                         
                          </div>
                        )
                      })
                    }

                    </div>
                    
                  </div>
                </>              

              )}
          />

      </div>
    </>
  )
}

export {Step4}
