import {lazy, FC, Suspense} from 'react'
import {Route, Routes, Navigate} from 'react-router-dom'
import {MasterLayout} from '../../_metronic/layout/MasterLayout'
import TopBarProgress from 'react-topbar-progress-indicator'
import {InicioPage} from '../pages/inicio/InicioPage'
import {MenuTestPage} from '../pages/MenuTestPage'
import {getCSSVariableValue} from '../../_metronic/assets/ts/_utils'
import {WithChildren} from '../../_metronic/helpers'
import BuilderPageWrapper from '../pages/layout-builder/BuilderPageWrapper'
import DescricaoPage from '../pages/descricao/DescricaoPage'
import AjudaPage from '../pages/ajuda/AjudaPage'
import ConceitosPage from '../pages/conceitos/ConceitosPage'
import ConfigPage from '../pages/configuracao/ConfigPage'
import AdmPage from '../pages/administracao/AdmPage'
import {AdmInicioPage} from '../pages/administracao/AdmInicioPage'
import {useAuth} from '../modules/auth'
import ContaPage from '../pages/conta/ContaPage'
import PesquisaPage from '../pages/pesquisa/PesquisaPage'

const PrivateRoutes = () => {
  const WizardsPage = lazy(() => import('../modules/wizards/WizardsPage'))
  const AccountPage = lazy(() => import('../modules/accounts/AccountPage'))
  const WidgetsPage = lazy(() => import('../modules/widgets/WidgetsPage'))
  const ChatPage = lazy(() => import('../modules/apps/chat/ChatPage'))
  const UsersPage = lazy(() => import('../modules/apps/user-management/UsersPage'))
  const {auth, redirect} = useAuth()
  const servico = auth?.servico
  const {moduloDescricaoConfig, moduloPesquisaConfig} = servico!

  return (
    <Routes>
      <Route element={<MasterLayout />}>
        {/* Redirect to inicio after success login/registration */}
        <Route path='/*' element={<Navigate to={`/${redirect}`} />} />
        {/* Pages */}
        <Route path='inicio' element={<InicioPage />} />
        <Route path='builder' element={<BuilderPageWrapper />} />
        <Route path='menu-test' element={<MenuTestPage />} />
        {/* Lazy Modules */}
        {auth?.roles.find((x) => x === 'Administrador') !== undefined && (
          <>
            <Route
              path='administracao/*'
              element={
                <SuspensedView>
                  <AdmPage />
                </SuspensedView>
              }
            />
          </>
        )}
        <>
          <Route
            path='configuracao/*'
            element={
              <SuspensedView>
                <ConfigPage />
              </SuspensedView>
            }
          />
          {servico?.moduloConfig.DescricaoDeCargo && moduloDescricaoConfig?.ativo && (
            <Route
              path='descricao/*'
              element={
                <SuspensedView>
                  <DescricaoPage />
                </SuspensedView>
              }
            />
          )}
          {servico?.moduloConfig.Pesquisa && moduloPesquisaConfig?.ativo && (
            <Route
              path='pesquisa-ia/*'
              element={
                <SuspensedView>
                  <PesquisaPage />
                </SuspensedView>
              }
            />
          )}
          <Route
            path='ajuda/*'
            element={
              <SuspensedView>
                <AjudaPage />
              </SuspensedView>
            }
          />
          <Route
            path='conceitos/*'
            element={
              <SuspensedView>
                <ConceitosPage />
              </SuspensedView>
            }
          />
        </>

        <Route
          path='conta/*'
          element={
            <SuspensedView>
              <ContaPage />
            </SuspensedView>
          }
        />
        <Route
          path='crafted/pages/wizards/*'
          element={
            <SuspensedView>
              <WizardsPage />
            </SuspensedView>
          }
        />
        <Route
          path='crafted/widgets/*'
          element={
            <SuspensedView>
              <WidgetsPage />
            </SuspensedView>
          }
        />
        <Route
          path='crafted/account/*'
          element={
            <SuspensedView>
              <AccountPage />
            </SuspensedView>
          }
        />
        <Route
          path='apps/chat/*'
          element={
            <SuspensedView>
              <ChatPage />
            </SuspensedView>
          }
        />
        <Route
          path='apps/user-management/*'
          element={
            <SuspensedView>
              <UsersPage />
            </SuspensedView>
          }
        />
        {/* Page Not Found */}
        <Route path='*' element={<Navigate to='/error/404' />} />
      </Route>
    </Routes>
  )
}

const SuspensedView: FC<WithChildren> = ({children}) => {
  const baseColor = getCSSVariableValue('--bs-primary')
  TopBarProgress.config({
    barColors: {
      '0': baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  })
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>
}

export {PrivateRoutes}
