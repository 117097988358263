import { Box, Card, Typography } from '@mui/material'
import { Task as TaskType } from '../data/initialData'
import { Draggable } from '@hello-pangea/dnd'

interface TaskListProps {
  task: TaskType
  taskIndex: number
  columnName: string
}

const TaskList = (props: TaskListProps) => {
  const { task, taskIndex, columnName } = props

  return (
    <>
        {task && (
            <Draggable key={task.id} draggableId={task.id} index={taskIndex}>
            {(
                provided,
                snapshot // the child of a Draggable element must be a function that returns an element
            ) => (
                <Card
                className='p-3 mb-2 bg-body rounded shadow-sm borda-tasks-aprimorar-color m-2 '
                ref={provided.innerRef}
                square
                variant="outlined"
                sx={{
                  opacity: snapshot.isDragging ? '0.5 !important' : {},
                }}

                {...provided.draggableProps} //any draggable elements must have these properties
                {...provided.dragHandleProps} //comment out if you want to uncomment the drag handle code below
                >
                <Typography>
                  <span className={`
                    ${columnName === "sugeridas" && task.situacaoInicial === "existentes" ? "text-danger" : ""} 
                    ${(columnName === "existentes" && task.situacaoInicial === "existentes") || (columnName === "sugeridas" && task.situacaoInicial === "sugeridas") ? "table-cell" : ""} 
                    ${columnName === "existentes" && task.situacaoInicial === "sugeridas" ? "text-success" : ""}`}
                  >
                    {task.content}
                  </span>
                </Typography>
    
                </Card>
            )}
            </Draggable>
        )}
        
    </>
    
  )
}

export default TaskList
