/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState, useRef } from 'react'
import { useBlockUI, useHttpClient } from '../../../modules/services/Bundle'
import { RequestMethod } from '../../../modules/services/core/_enums'
import { ErrorMessage, Field, FormikProvider, useFormik } from 'formik'
import InputMask from 'react-input-mask'
import { IUsuarioFotoModel, defaultValidationSchemas, initialValues } from './helpers/ColaboradorHelper'
import { useLocation, useNavigate } from 'react-router-dom'
import {NumericFormat} from 'react-number-format';
import Select from 'react-select';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { ptBR } from '@mui/x-date-pickers/locales';
import br from 'dayjs/locale/pt-br';
import dayjs from 'dayjs';

interface dados {
  idColaborador: number
  visualizar: boolean
  title: string
}

interface Props {
  colabCliente: boolean
}

const Colaborador = ({colabCliente}: Props) => {
  const httpClient = useHttpClient()
  const navigate = useNavigate();
  const {setBlocking} = useBlockUI()
  const [srcLogo, setSrcLogo] = useState("/media/avatars/blank.png")
  const [perfis, setPerfis] = useState<any[]>([])
  const [cargos, setCargos] = useState<any[]>([])
  const [usuarios, setUsuarios] = useState<any[]>([])
  const [empresasCliente, setEmpresasCliente] = useState<any[]>([])
  const location = useLocation();
  const datePickerRef = useRef()
  const state = location.state as dados
  const informacao: dados = state
  
  const formik = useFormik({
      initialValues: {...initialValues},
      validationSchema: defaultValidationSchemas,
      onSubmit: async (values, actions) => {  
        const Colaborador = {
          Id: informacao.idColaborador ?? 0,
          Nome: values.Nome,
          Sobrenome: values.Sobrenome,
          Email: values.Email,
          CPF: values.CPF.replace(/\D/g, ''),
          Ativo: true,
          IdCargo: values.IdCargo,
          UsuarioPerfil: values.UsuarioPerfil && values.UsuarioPerfil.map((data) => ({
            IdUsuario: informacao.idColaborador ?? 0,
            IdPerfil: Number(data.IdPerfil) 
          })),
          Salario: values.Salario ? parseFloat(values.Salario.toString().replace(/[^\d,]/g, '').replace(',', '.')).toFixed(2) : null,
          DataAdmissao: values.DataAdmissao ? values.DataAdmissao : null,
          UsuarioFoto: values.UsuarioFoto ?? null,
          IdSuperior: values.IdSuperior ?? null,
          ColabCliente: colabCliente,
          IdEmpresa: values.IdEmpresa??0
        }

        httpClient.request({
          method: informacao.idColaborador ? RequestMethod.PUT : RequestMethod.POST,
          endpoint: `/Gerenciamento/Usuario/${informacao.idColaborador ? "Editar" : "Adicionar"}`,
          data: Colaborador,
          ifSuccessRedirectTo: colabCliente ? '/descricao/consultoria/colaborador' : '/configuracao/colaboradores'
        })
      },
  })

  
  const handleLogoChange = (e: any) => {
    e.preventDefault()

    const file = e.target.files[0];
    var fReader = new FileReader();
    
    if (file) {
      fReader.readAsDataURL(file);
    }

    fReader.onloadend = function (event: any) {
      const base64Image = event.target.result;

      const UsuarioFoto: IUsuarioFotoModel = {
        Nome: file.name, // Nome da imagem (nome do arquivo)
        Extensao: file.name.split('.').pop(), // Extensão da imagem (parte após o último ponto)
        Tamanho: file.size, // Tamanho da imagem em bytes
        Tipo: file.type, // Tipo MIME da imagem
        Base64: base64Image, // URL de dados da imagem em base64
      };

      formik.setFieldValue("UsuarioFoto", UsuarioFoto);
      setSrcLogo(base64Image);
    };
  }

  const removePhoto = () => {
    const UsuarioFoto: IUsuarioFotoModel = {
      Nome: "", // Nome da imagem (nome do arquivo)
      Extensao: "", // Extensão da imagem (parte após o último ponto)
      Tamanho: 0, // Tamanho da imagem em bytes
      Tipo: "", // Tipo MIME da imagem
      Base64: "", // URL de dados da imagem em base64
    };

    formik.setFieldValue("UsuarioFoto", UsuarioFoto);
    setSrcLogo("/media/avatars/blank.png");
  }

  const obterSelects = async () =>{

    await httpClient.request({
      method: RequestMethod.GET,
      endpoint: '/Gerenciamento/Cargo/ObterParaSelect',
      blockFree: true
    }).then((response) => {
      if(response.success && response.payload) setCargos(response.payload)
    })

    await httpClient.request({
      method: RequestMethod.GET,
      endpoint: '/Gerenciamento/Perfil/ObterParaSelect',
      blockFree: true
    }).then((response) => {
      if(response.success && response.payload) setPerfis(response.payload)
    })

    await httpClient.request({
      method: RequestMethod.GET,
      endpoint: '/Gerenciamento/Usuario/ObterColaboradoresParaSelect',
      queryObject:{
        idUsuario: informacao ? informacao.idColaborador ?? 0 : 0
      },
      blockFree: true
    }).then((response) => {
      if(response.success && response.payload) {
        let newUsuarios = response.payload
        if(informacao.idColaborador && informacao.idColaborador > 0) newUsuarios = newUsuarios.filter((x: { Id: number, Nome: string }) => x.Id !== informacao.idColaborador)
        setUsuarios(newUsuarios)
      }
    })
    
  }

  const obterSelectsCliente = async (idEmpresa: number) =>{

    setCargos([])
    setPerfis([])
    setUsuarios([])  

    if (idEmpresa > 0) {

      const response = await httpClient.request({
        method: RequestMethod.GET,
        endpoint: '/DescricaoDeCargo/Consultoria/ObterDadosColaborador',
        queryObject: {
          IdEmpresa: idEmpresa,
        }
      })
      
      if(response.success && response.payload) {
        setCargos(response.payload.Cargos)
        setPerfis(response.payload.Perfis)
        let newUsuarios = response.payload.Usuarios
        if(informacao.idColaborador && informacao.idColaborador > 0) newUsuarios = newUsuarios.filter((x: { Id: number, Nome: string }) => x.Id !== informacao.idColaborador)
        setUsuarios(newUsuarios)
      }

    }
    
  }

  const obterColaborador = async () => {
    
    const response = await httpClient.request({
      method: RequestMethod.GET,
      endpoint: '/Gerenciamento/Usuario/ObterPorId',
      queryObject: {
        idUsuario: informacao.idColaborador,
      },
      blockFree: true
    })
    
    if(response.success && response.payload){          
      const colaborador = response.payload;
      
      formik.setFieldValue('Nome', colaborador.Nome)
      formik.setFieldValue('Sobrenome', colaborador.Sobrenome)
      formik.setFieldValue('Email', colaborador.Email)
      formik.setFieldValue('CPF', colaborador.CPF)
      formik.setFieldValue('Salario', colaborador.Salario)
      formik.setFieldValue('IdSuperior', colaborador.IdSuperior)
      formik.setFieldValue('IdCargo', colaborador.IdCargo)
      formik.setFieldValue('IdEmpresa', colaborador.IdEmpresa)
      
      if(colaborador.IdEmpresa && colaborador.IdEmpresa > 0 && colabCliente) obterSelectsCliente(colaborador.IdEmpresa)

      if(colaborador.UsuarioPerfil){
        formik.setFieldValue("PerfilPermissao", colaborador.UsuarioPerfil.map((data: any) => ({
          value: data.Id,
          label: data.IdPerfilNavigation.Nome
        })))
        formik.setFieldValue('UsuarioPerfil', colaborador.UsuarioPerfil)
      }
      formik.setFieldValue('UsuarioFoto', colaborador.UsuarioFoto)
      
      if (colaborador.DataAdmissao) formik.setFieldValue('DataAdmissao', dayjs(colaborador.DataAdmissao))
      
      if(colaborador.UsuarioFoto) setSrcLogo(colaborador.UsuarioFoto.Base64);
    }

  }

  const obterDados = async() => {
    setBlocking(true)

    if(colabCliente) {

      await httpClient.request({
        method: RequestMethod.GET,
        endpoint: '/DescricaoDeCargo/Consultoria/ObterClientesParaSelect',
      }).then((response) => {
        let newValues = {...initialValues}
        newValues.ColabCliente = colabCliente        

        if(response.success && response.payload) setEmpresasCliente(response.payload)

        formik.setValues(newValues)        
      })
    }
    else await obterSelects()

    if(informacao && informacao.idColaborador){
      await obterColaborador()
    }

    setBlocking(false)
  }

  useEffect(() => {
    obterDados()
  },[informacao])

  const handleDadosChange = (e: any) => {
    formik.setFieldValue("UsuarioPerfil", e.map((data: any) => ({
      IdPerfil: data.value,
      IdPerfilNavigation: { Nome: data.label }
    })))
    formik.setFieldValue("PerfilPermissao", e.map((data: any) => ({
      value: data.value,
      label: data.label
    })))
  }

  const onChangeDate = (e:any) => {
    
    if (e && dayjs(e.$d).toString() !== 'Invalid Date') {
      formik.setFieldValue('DataAdmissao', dayjs(e.$d).format('YYYY-MM-DD'))
    }
    else if (e === null) {
      formik.setFieldValue('DataAdmissao', '')
    }
  };

  return (
    <>
    <div className='card mb-5 mb-xl-10'>

      <div
        className='card-header'
      >
        <div className='card-title m-0'>
          <h3 className='fw-bolder m-0'>{informacao != null && informacao.title ? informacao.title : "Adicionar"} Colaborador</h3>
        </div>
      </div>

      <div
        className='card-body'
      >
        <FormikProvider value={formik}>
          <div className='w-100'>
            <form 
              noValidate
            >
              <div className="row">
                {colabCliente && (
                  <div className='col-lg-12 fv-row mb-5'>
                    <label className='form-label required'>Cliente</label>
                    <Field
                      as='select'
                      name='IdEmpresa'
                      className='form-select form-select-lg form-select-solid'
                      value={formik.values.IdEmpresa}
                      onChange={(event: { target: { value: number } }) => {
                        formik.handleChange(event)
                        obterSelectsCliente(event.target.value)
                      }}
                      disabled={informacao.idColaborador > 0}
                    >
                      <option value={''}>Selecione...</option>
                      {
                        empresasCliente && empresasCliente.length > 0 && empresasCliente.map((data: any, index: any) => {
                            return <option key={`IdEmpresa-option-${index}`} value={data.Id}>{data.Nome}</option>
                        })
                      }
                    </Field>
                    <div className='text-danger mt-2'>
                      <ErrorMessage name='IdEmpresa' />
                    </div>
                  </div>
                )}

                <div className="row d-flex align-items-center mb-5">
                  <div className='col-sm-2 d-flex justify-content-center flex-wrap'>
                      <div
                        className='image-input image-input-outline'
                        data-kt-image-input='true'
                        style={{ backgroundImage: `url('${srcLogo}')` }}
                    >
                        <div
                            className='image-input-wrapper w-125px h-125px'
                            style={{ backgroundImage: `url('${srcLogo}')` }}
                        ></div>
                          <label
                              className='btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow'
                              data-kt-image-input-action='change'
                              data-bs-toggle='tooltip'
                              title='Adicionar imagem'
                          >
                              <i className='bi bi-pencil-fill fs-7'></i>

                              <input disabled={informacao != null && informacao.visualizar} type='file' onChange={handleLogoChange} name='avatar' accept='.png, .jpg, .jpeg' />
                              <input type='hidden' name='avatar_remove' />
                          </label>
                      </div>
                      <br />
                      <span className={`mt-2 ${formik.values.UsuarioFoto ? "ms-9" : "ms-4"}`}>Foto do Colaborador</span>
                      {formik.values.UsuarioFoto && (
                        <button
                            type='button'
                            className='btn btn-bg-white btn-active-color-primary pt-2 ps-2'
                            data-bs-toggle='tooltip'
                            title='Remover imagem'
                            onClick={removePhoto}
                            disabled={informacao != null && informacao.visualizar} 
                        >
                          <i onClick={removePhoto} className="bi bi-trash3-fill fs-7"></i>
                        </button>
                      )}
                  </div>

                  <div className="col-lg-5 fv-row">
                      <label className='form-label required'>Nome</label>
                      <Field
                          name={`Nome`}
                          placeholder='Nome'
                          disabled={informacao != null && informacao.visualizar}
                          className='form-control form-control-lg form-control-solid'
                      />
                      <div className='text-danger mt-2'>
                        <ErrorMessage name='Nome' />
                      </div> 
                  </div>
                  <div className="col-lg-5 fv-row">
                      <label className='form-label required'>Sobrenome</label>
                      <Field
                          name={`Sobrenome`}
                          placeholder='Sobrenome'
                          disabled={informacao != null && informacao.visualizar}
                          className='form-control form-control-lg form-control-solid'
                      />
                      <div className='text-danger mt-2'>
                        <ErrorMessage name='Sobrenome' />
                      </div> 
                  </div>
                </div>

                <div className="row">
                  <div className="col-lg-4 fv-row mb-5">
                    <label className='form-label required'>Email</label>
                        <Field
                            name={`Email`}
                            placeholder='Email'
                            disabled={informacao != null && informacao.visualizar}
                            className='form-control form-control-lg form-control-solid'
                        />
                        <div className='text-danger mt-2'>
                          <ErrorMessage name='Email' />
                        </div> 
                  </div>  

                  <div className="col-lg-4 fv-row mb-5">
                    <label className='form-label required'>CPF</label>
                      <InputMask
                        mask={'999.999.999-99'}
                        placeholder='___.___.___-__'
                        disabled={informacao != null && informacao.visualizar}
                        type='text'
                        {...formik.getFieldProps('CPF')}
                        name={`CPF`}
                        className='form-control form-control-lg form-control-solid'
                        value={formik.values.CPF}
                        
                      ></InputMask>
                      <div className='text-danger mt-2'>
                        <ErrorMessage name='CPF' />
                      </div> 
                  </div>
                  <div className='col-lg-4 fv-row mb-5'>
                    <label className='form-label required'>Perfil de permissão</label>
                    <Select 
                      placeholder='Selecione...' 
                      className='form-control-custom-select'
                      noOptionsMessage={() => 'Nenhuma opção encontrada'}
                      {...formik.getFieldProps('PerfilPermissao')}
                      styles={{
                        control: (baseStyles, state) => ({
                          ...baseStyles,
                          border: 'none',
                          backgroundColor: '$input-solid-bg',
                          borderRadius: '7px',
                          height: '3.4rem',
                          color: '#5e6278',
                          fontSize: '1.15rem'
                        }),
                        menu: (baseStyles, state) => ({
                          ...baseStyles,
                          backgroundColor: '$input-solid-bg',
                        }),
                        option: (provided, state) => ({
                          ...provided,
                          color: state.isFocused ? 'black' : '$input-solid-color', // Change the color of the options in the dropdown menu
                        }),
                      }}
                      options={perfis.map((data) => ({
                        value: data.Id,
                        label: data.Nome
                      }))} 
                      isDisabled={informacao != null && informacao.visualizar}
                      value={formik.values.UsuarioPerfil ? formik.values.UsuarioPerfil.map((data) => ({
                        value: data.IdPerfil,
                        label: data.IdPerfilNavigation.Nome
                      })) : []} 
                      isSearchable
                      isMulti
                      onChange={(e) => handleDadosChange(e)}
                    />
                    <div className='text-danger mt-2'>
                      <ErrorMessage name='PerfilPermissao' />
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-lg-6 fv-row mb-5">
                    <label className='form-label'>Salário</label>
                      <NumericFormat
                        thousandSeparator="."
                        decimalSeparator=","
                        prefix="R$ "
                        allowNegative={false}
                        decimalScale={2}
                        fixedDecimalScale={true}
                        disabled={informacao != null && informacao.visualizar}
                        type='text'
                        {...formik.getFieldProps('Salario')}
                        name={`Salario`}
                        className='form-control form-control-lg form-control-solid'
                        value={formik.values.Salario}
                      />
                      <div className='text-danger mt-2'>
                        <ErrorMessage name='Salario' />
                      </div> 
                  </div>
                  <div className='col-lg-6 fv-row mb-5'>
                    <label className='form-label'>Cargo</label>
                    <Field
                      as='select'
                      name='IdCargo'
                      disabled={informacao != null && informacao.visualizar}
                      className='form-select form-select-lg form-select-solid'
                      onChange={(event: { target: { value: number } }) => {
                        formik.handleChange(event);
                      }}
                    >
                      <option value=''>Selecione...</option>
                      {
                        cargos && cargos.length > 0 && cargos.map((data: any, index: any) => {
                          return <option key={`idCargo-option-${index}`} value={data.Id}>{data.Nome}</option>
                        })
                      }
                    </Field>
                    <div className='text-danger mt-2'>
                      <ErrorMessage name='IdCargo' />
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className='col-lg-6 fv-row mb-5'>
                    <label className='form-label'>Superior</label>
                    <Field
                      as='select'
                      name='IdSuperior'
                      disabled={informacao != null && informacao.visualizar}
                      className='form-select form-select-lg form-select-solid'
                      onChange={(event: { target: { value: number } }) => {
                        formik.handleChange(event);
                      }}
                    >
                      <option value=''>Selecione...</option>
                      {
                        usuarios && usuarios.length > 0 && usuarios.map((data: any, index: any) => {
                          return <option key={`idSuperior-option-${index}`} value={data.Id}>{data.Nome}</option>
                        })
                      }
                    </Field>
                    <div className='text-danger mt-2'>
                      <ErrorMessage name='IdSuperior' />
                    </div>
                  </div>
                  <div className="col-lg-6 fv-row mb-5">
                    <label className='form-label'>Data de Admissão</label>
                        <LocalizationProvider adapterLocale={br.name} dateAdapter={AdapterDayjs}>
                          <DatePicker 
                            format="DD/MM/YYYY"
                            inputRef={datePickerRef.current}
                            disabled={informacao != null && informacao.visualizar}
                            {...formik.getFieldProps('DataAdmissao')}
                            localeText={ptBR.components.MuiLocalizationProvider.defaultProps.localeText}
                            onChange={(e:any) => onChangeDate(e)}
                            className='w-100 mt-2'
                            value={formik.values.DataAdmissao !== '' ? formik.values.DataAdmissao : null}
                            slotProps={{
                              textField: { size: "small" },
                            }}
                          />
                        </LocalizationProvider>
                    <div className='text-danger mt-2'>
                      <ErrorMessage name='DataAdmissao' />
                    </div> 
                  </div> 
                </div>
              </div>
              
            </form>
          </div>
        </FormikProvider>
      </div>
      <div className="card-footer d-flex justify-content-end py-4">        
        <button className='btn btn-secondary' onClick={() => navigate(-1)}>
              Voltar
        </button>
        <button
          type='button'
          onClick={() => formik.handleSubmit()}
          className={`btn btn-lg btn-primary ms-5 ${informacao != null && informacao.visualizar ? "d-none" : ""}`}
        >
          Salvar
        </button>
      </div>
    </div>
    </>
  )
}

export {Colaborador}

