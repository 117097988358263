import {Route, Routes, Outlet, Navigate} from 'react-router-dom'
import { useAuth } from '../../modules/auth'
import { ArrayBreadcrumb, BreadcrumbLayout } from '../../modules/services/core/Breadcrumb'
import { EnumServicoTipoPlano } from '../../modules/services/core/_enums'
import { Empresa } from './components/Empresa'
import { NiveisHierarquicos } from './components/NiveisHierarquicos'
import { Areas } from './components/Areas'
import { Cargos } from './components/Cargos'
import { Perfis } from './components/Perfis'
import { CrudPerfil } from './components/partials/CrudPerfil'
import { Colaborador } from './components/Colaborador'
import { Colaboradores } from './components/Colaboradores'

const wizardsBreadCrumbs: Array<ArrayBreadcrumb> = [
  {
    titulo: 'Configuração',
    path: '',
  }
]

const wizardsBreadCrumbsGeral: Array<ArrayBreadcrumb> = [
  {
    titulo: 'Configuração',
    path: '',
  },
  {
    titulo: 'Geral',
    path: '',
  }
]

const wizardsBreadCrumbsPerfis: Array<ArrayBreadcrumb> = [
  {
    titulo: 'Configuração',
    path: '',
  },
  {
    titulo: 'Geral',
    path: '',
  },
  {
    titulo: 'Perfis',
    path: '/configuracao/perfis',
  }
]

const DescricaoPage = () => {
  const {auth} = useAuth()
  const servico = auth?.servico

  return (
    <Routes>
      <Route element={<Outlet />}>   
          {servico?.tipoPlano === EnumServicoTipoPlano.Buy ? (
            <>
              {((auth?.roles.find(x => x === 'Master') !== undefined) || (auth?.roles.find(x => x === 'Administrador') !== undefined) || (auth?.permissoes.find(x => x === 'D35C8D7C5D36798171037211EDD26ECC') !== undefined)) && (
                <Route
                  path='empresa'
                  element={
                    <>
                      <BreadcrumbLayout props={wizardsBreadCrumbsGeral}>Empresa</BreadcrumbLayout>
                      <Empresa/>
                    </>
                  }
                /> 
              )}
              {((auth?.roles.find(x => x === 'Master') !== undefined) || (auth?.roles.find(x => x === 'Administrador') !== undefined) || (auth?.permissoes.find(x => x === 'F09BC7725A3BCB24201548FABE70AA63') !== undefined)) && (
                <>
                  <Route
                    path='colaboradores'
                    element={
                      <>
                        <BreadcrumbLayout props={wizardsBreadCrumbsGeral}>Colaboradores</BreadcrumbLayout>
                        <Colaboradores colabCliente={false}/>
                      </>
                    }
                  /> 
                  <Route
                    path='colaboradores/gerenciar'
                    element={
                      <>
                        <BreadcrumbLayout props={wizardsBreadCrumbsGeral}>Colaborador</BreadcrumbLayout>
                        <Colaborador colabCliente={false}/>
                      </>
                    }
                  /> 
                </>
              )}
              {((auth?.roles.find(x => x === 'Master') !== undefined) || (auth?.roles.find(x => x === 'Administrador') !== undefined) || (auth?.permissoes.find(x => x === 'D3839E80EC2C42BBEF34E55F754BB33C') !== undefined)) && (
                <Route
                  path='niveis-hierarquicos'
                  element={
                    <>
                      <BreadcrumbLayout props={wizardsBreadCrumbsGeral}>Níveis Hierárquicos</BreadcrumbLayout>
                      <NiveisHierarquicos isConsultoria={false}/>
                    </>
                  }
                /> 
              )}
              {((auth?.roles.find(x => x === 'Master') !== undefined) || (auth?.roles.find(x => x === 'Administrador') !== undefined) || (auth?.permissoes.find(x => x === '1294D4AC190149DFB635279F699672C8') !== undefined)) && (
                <Route
                  path='areas'
                  element={
                    <>
                      <BreadcrumbLayout props={wizardsBreadCrumbsGeral}>Áreas</BreadcrumbLayout>
                      <Areas isConsultoria={false} />
                    </>
                  }
                /> 
              )}
              {((auth?.roles.find(x => x === 'Master') !== undefined) || (auth?.roles.find(x => x === 'Administrador') !== undefined) || (auth?.permissoes.find(x => x === '5742599FD4095A48247B0DF529E961F1') !== undefined)) && (                
                <Route
                  path='cargos'
                  element={
                    <>
                      <BreadcrumbLayout props={wizardsBreadCrumbsGeral}>Cargos</BreadcrumbLayout>
                      <Cargos isConsultoria={false} />
                    </>
                  }
                /> 
              )}
              {((auth?.roles.find((x) => x === 'Master') !== undefined && auth?.servico.AutoGerenciarPerfis ) || (auth?.roles.find((x) => x === 'Administrador') !== undefined )) && (
                <>
                  <Route
                    path='perfis'
                    element={
                      <>
                        <BreadcrumbLayout props={wizardsBreadCrumbsGeral}>Perfis</BreadcrumbLayout>
                        <Perfis/>
                      </>
                    }
                  /> 
                  <Route
                    path='perfis/configurar'
                    element={
                      <>
                        <BreadcrumbLayout props={wizardsBreadCrumbsPerfis}>Configurar</BreadcrumbLayout>
                        <CrudPerfil/>
                      </>
                    }
                  />
                </>
              )}
            </>
          ) : (
            <>
              <Route path='*' element={<Navigate to='/inicio' />} />
            </>
          )} 
        
        <Route index element={<Navigate to='/configuracao/empresa' />} />        
      </Route>
    </Routes>
  )
}

export default DescricaoPage
