import { FormikProps } from 'formik'
import * as Yup from 'yup'

  export interface IPesquisaUsuarioModel {
    Id: number
    IdPesquisa: number
    Email: string
    ConfirmeEmail: string
    Nome: string
  }
  
  export interface IPesquisaAreaModel {
    Id: number
    IdPesquisa: number
    IdArea: number
  }
  
  export interface IExemploDimensaoPerguntaModel {
    Id:number             
    IdDimensao:number               
    Ordem:number       
    Texto:string
    QuestaoPergunta: IQuestaoPerguntaModel[]
  }

  export interface IQuestaoPerguntaModel {
    Id: number,
    IdDimensaoPergunta: number,
    ValorQuestao: string
  }

  export interface IExemploDimensaoPerguntaParticipanteModel {
    Id:number             
    IdDimensao:number               
    Ordem:number       
    Texto:string
    Respondida:boolean
  }
  
  export interface IExemploDimensaoModel {
    Id:number             
    IdTema:number 
    Ordem:number  
    Nome:string             
    Texto:string
    Perguntas:IExemploDimensaoPerguntaModel[]
  }

  export interface IExemploDimensaoParticipanteModel {
    Id:number             
    IdTema:number 
    Ordem:number  
    Nome:string             
    Texto:string
    Porcentagem:number
    Perguntas:IExemploDimensaoPerguntaParticipanteModel[]
  }
  
  export interface IWizardModel {
    Id:number              
    IdTema:number 
    IdEscala: number             
    NomeTema:string           
    Nome:string           
    Descricao:string           
    Termos:string           
    Status:number           
    StatusString:string           
    StatusColor:string           
    DataInicial:Date|undefined             
    DataInicialString:string           
    DataInicialStringYMD:string           
    DataFinal:Date|undefined           
    DataFinalString:string     
    DataFinalStringYMD:string  
    TipoDisposicaoPergunta:number
    TipoDisposicaoPerguntaString:string           
    QtdMinRespParaMostrarResultados:number           
    QtdDimensoes:number           
    QtdPerguntas:number           
    Participantes:IPesquisaUsuarioModel[]
  }

  const initValuesParticipante: IPesquisaUsuarioModel = {
    Id: 0,
    IdPesquisa: 0,
    Email: '',
    Nome: '',
    ConfirmeEmail: '',
  }

  const initValues: IWizardModel = {
    Id:0,              
    IdTema:0,  
    IdEscala: 0,  
    NomeTema:'',           
    Nome:'',           
    Descricao:'',           
    Termos:'<p><br></p>',   
    Status:1,            
    StatusString:'',           
    StatusColor:'',           
    DataInicial:undefined,           
    DataInicialString:'',           
    DataInicialStringYMD:'',           
    DataFinal:undefined,            
    DataFinalString:'',         
    DataFinalStringYMD:'',      
    TipoDisposicaoPergunta:1,
    TipoDisposicaoPerguntaString:'',
    QtdMinRespParaMostrarResultados:0,           
    QtdDimensoes:0,           
    QtdPerguntas:0,         
    Participantes:[initValuesParticipante]
  }

  export interface StepProps {
    formik: FormikProps<IWizardModel>,
  }

  const defaultValidationSchemas = [
    Yup.object({
      Nome: Yup.string().required('O nome é obrigatório'),
      DataInicial: Yup.date()
      .required("A data inicial é obrigatória")
      .typeError("A data inicial é obrigatória")
      .nullable(),
      DataFinal: Yup.date()
      .when(
        "DataInicial",
        (DataInicial, schema) => DataInicial 
        && schema != null && schema.min(DataInicial, 'Data final deve ser maior que a data inicial')
      )
      .required("A data final é obrigatória")
      .typeError("A data final é obrigatória")
      .nullable(), 
      Termos: Yup.string().notOneOf(['<p><br></p>'], 'O termo é obrigatório'),      
      IdTema: Yup.number().notOneOf([0], 'O tema é obrigatório'),
      IdEscala: Yup.number().notOneOf([0], 'A escala é obrigatória'),
    }),
    Yup.object({
    }),
    Yup.object({
      Participantes: Yup.array().of(
        Yup.object().shape({
          Nome: Yup.string().required('O nome é obrigatório'),
          Email: Yup.string().email('E-mail inválido').required('O e-mail é obrigatório'),
          ConfirmeEmail: Yup.string().email('E-mail inválido').required('O e-mail é obrigatório').when('Email', {
            is: (val: string) => (val && val.length > 0 ? true : false),
            then: Yup.string().oneOf(
              [Yup.ref('Email')],
              'O e-mail e a confirmação do e-mail não conferem'
            ),
          }),
        })
      ),
      QtdMinRespParaMostrarResultados: Yup.number().min(1, "O Nº mínimo de respondentes deve ser maior que zero.").required('O Nº mínimo de respondentes é obrigatório').when(
        "Participantes",
        (Participantes, schema) => Participantes 
        && schema != null && schema.max(Participantes.length, 'O Nº mínimo de respondentes deve ser menor ou igual quantidade de participantes infomados acima')
      )
    }),
  ]

  export {defaultValidationSchemas, initValues, initValuesParticipante}