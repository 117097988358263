import { ErrorMessage, Field, FormikProvider, useFormik } from 'formik'
import { Key, ReactChild, ReactFragment, ReactPortal, useEffect, useState } from 'react'
import { KTSVG, toAbsoluteUrl } from '../../../../../_metronic/helpers'
import { useHttpClient, validateDomain } from '../../../../modules/services/Bundle'
import { EnumPorteEmpresa, RequestMethod } from '../../../../modules/services/core/_enums'
import { defaultValidationSchemas, initValues } from '../helpers/AddClienteHelper'

type Props = {
    setAtualizarTable: Function
    abrirModal: boolean
    setAbrirModal: Function
    atualizarDados?: Function
    currentCliente?: Data | undefined
}

interface Data {
    Id?: number
    IdEmpresaCliente: number;
    DominioEmpresaCliente: string;
    RazaoSocialEmpresaCliente: string;
    NomeResponsavel: string;
    IsResponsavel: boolean;
    DataCadastroString: string;
    Ativo: boolean;
    EmailResponsavel: string
    IdSegmento: number
    NomeSegmento: string
    PorteEmpresa: number
    QtdFuncionarios: number
    QtdMaximaCargos: number
    CaracteristicasDoNegocio: string
    PermiteEditarDescricao: boolean,
    UtilizaAvaliador: boolean,
    EmailRepresentante: string,
    EmpresaDominio: string[]
  }

  interface MultipleSelectProps {
    selectedOptions: string[]
    setSelectedOptions: Function
  }

  export const MultipleSelect = ({selectedOptions, setSelectedOptions}: MultipleSelectProps) => {
    const [isVisible, setIsVisible] = useState(false);

    const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
      const inputValue = event.currentTarget.value.trim();
  
      if (event.key === 'Enter' && inputValue) {
        if(validateDomain(inputValue)) setSelectedOptions([...selectedOptions, inputValue]);
        else dominioInvalido()
        event.currentTarget.value = '';
      }
    };
  
    const handleOptionRemove = (index: number) => {
      const newOptions = [...selectedOptions];
      newOptions.splice(index, 1);
      setSelectedOptions(newOptions);
    };

    useEffect(() => {
      if (isVisible) {
        const timer = setTimeout(() => {
          setIsVisible(false);
        }, 3000);
  
        return () => clearTimeout(timer);
      }
    }, [isVisible]);
  
    const dominioInvalido = () => {
      setIsVisible(true);
    };
  
    return (
      <div>
        <div className='input-group'>
            <span className="input-group-text" id="basic-addon1" style={{border: "none"}}>@</span>
            <input
                type="text"
                placeholder="Digite um domínio e pressione Enter"
                onKeyDown={handleKeyDown}
                className="form-control form-control-lg form-control-solid"
                aria-describedby="basic-addon1"
                
                />
        </div>
  
        <div className="mt-3">
          {isVisible && <span className='text-danger mr-2'>Domínio inválido!</span>}
          {selectedOptions.map((option, index) => (
            <div key={index} className="d-inline-block mr-2 mb-2">
              <span className="badge badge-secondary">{option}</span>
              <span 
                className="close ms-1" 
                style={{cursor: 'pointer'}}
                onClick={() => handleOptionRemove(index)} 
              >
                ×
              </span>
            </div>
          ))}
        </div>
      </div>
    );
  };

const ModalAddCliente = ({abrirModal, setAbrirModal, setAtualizarTable, atualizarDados, currentCliente}: Props) => {

    const httpClient = useHttpClient()
    const [segmentos, setSegmentos] = useState<any[]>([])
    const [selectedOptions, setSelectedOptions] = useState<string[]>([]);

    const formik = useFormik({
        initialValues: {...initValues},
        validationSchema: defaultValidationSchemas,
        onSubmit: async (values, actions) => {
          
            const result = await httpClient.request({
                method: RequestMethod.POST,
                endpoint: '/DescricaoDeCargo/Consultoria/AdicionarCliente',
                data: values,
            })
            if (result.success) {
                setAtualizarTable(true)
                setAbrirModal(false)
            }
        },
    })

    const [showNomeSegmento, setShowNomeSegmento] = useState<boolean>(false);

    const cancelarAdd = () => {
    
        formik.setValues({...initValues})
        setAbrirModal(false)

    }

    const requestDados = async () => {
    
        await httpClient.request({
          method: RequestMethod.GET,
          endpoint: '/Gerenciamento/Segmento/ObterParaSelect',
        }).then((response) => {
          if(response.success && response.payload) setSegmentos(response.payload)
        })

    }
    
    useEffect(() => {
        requestDados()
    },[])

    useEffect(() => {
        if(currentCliente){
            formik.setValues({
                RazaoSocial: currentCliente.RazaoSocialEmpresaCliente,
                EmailRepresentante: currentCliente.EmailRepresentante,
                IdSegmento: currentCliente.IdSegmento,
                NomeSegmento: currentCliente.NomeSegmento,
                PorteEmpresa: currentCliente.PorteEmpresa,
                QtdFuncionarios: currentCliente.QtdFuncionarios,
                QtdMaximaCargos: currentCliente.QtdMaximaCargos,
                IdEmpresaCliente: currentCliente.IdEmpresaCliente,
                CaracteristicasDoNegocio: currentCliente.CaracteristicasDoNegocio,
                UtilizaAvaliador: currentCliente.UtilizaAvaliador,
                PermiteEditarDescricao: currentCliente.PermiteEditarDescricao,
                EmpresaDominio: currentCliente.EmpresaDominio
            })
            setSelectedOptions(currentCliente.EmpresaDominio)
        }
    }, [currentCliente])

    useEffect(() => {
        if(!currentCliente) formik.setFieldValue('CaracteristicasDoNegocio', '')
    },[formik.values.PorteEmpresa])

    const onChangeCheckUtilizaAvaliador = (e: any) => {
        const valor = e.target.value
    
        if (valor.toString() === 'false') {
          formik.setFieldValue('UtilizaAvaliador', true)
        } else {
          formik.setFieldValue('UtilizaAvaliador', false)
        }
    }

    const onChangeCheckPermiteEditarDescricao = (e: any) => {
        const valor = e.target.value

        if (valor.toString() === 'false') {
            formik.setFieldValue('PermiteEditarDescricao', true)
        } else {
            formik.setFieldValue('PermiteEditarDescricao', false)
        }
    }

    useEffect(() => {
        formik.setFieldValue('EmpresaDominio', selectedOptions);
    },[selectedOptions])
    
    return (
    <div className={`modal ${abrirModal? "show" : ""} fade`} tabIndex={-1} id="kt_modal_1" style={{display: `${abrirModal? "block" : "none"}`}}>
        <div className="modal-dialog modal-dialog-centered modal-xl">
            <div className="modal-content">
            <div className="modal-header">
                <h5 className="modal-title">{currentCliente ? "Editar cliente" : "Adicionar novo cliente"}</h5>
                <div
                onClick={() => setAbrirModal(false)}
                className="btn btn-icon btn-sm btn-active-light-primary ms-2"
                data-bs-dismiss="modal"
                aria-label="Fechar"
                >
                <KTSVG
                    path="/media/icons/duotune/arrows/arr061.svg"
                    className="svg-icon svg-icon-2x"
                />
                </div>
            </div>
            <div className="modal-body">
                <FormikProvider value={formik}>
                    <div className='w-100'>
                        <form 
                            noValidate
                        >
                            <div className='row'>
                                <div className='col-lg-12 fv-row mb-5'>
                                <label className='form-label required'>Razão Social</label>
                                <Field 
                                    name='RazaoSocial'
                                    placeholder='Nome'
                                    className='form-control form-control-lg form-control-solid'
                                    value={formik.values.RazaoSocial}
                                />
                                <div className='text-danger mt-2'>
                                    <ErrorMessage name='RazaoSocial' />
                                </div>
                                </div>
                                <div className='col-lg-12 fv-row mb-5'>
                                    <label className='form-label required'>E-mail do representante da empresa cliente</label>
                                    <Field 
                                        name='EmailRepresentante'
                                        placeholder='E-mail do representante'
                                        className='form-control form-control-lg form-control-solid'
                                        value={formik.values.EmailRepresentante}
                                    />
                                    <div className='text-danger mt-2'>
                                        <ErrorMessage name='EmailRepresentante' />
                                    </div>
                                </div>
                                <div className='col-lg-12 fv-row mb-5'>
                                    <label className='form-label required'>Domínios reservados</label>
                                    <Field 
                                        name='EmpresaDominio'
                                        className='form-control form-control-lg form-control-solid'
                                        component={() =>
                                            <MultipleSelect selectedOptions={selectedOptions} setSelectedOptions={setSelectedOptions}/>
                                        }
                                    />
                                    <div className='text-danger mt-2'>
                                        <ErrorMessage name='EmpresaDominio' />
                                    </div>
                                </div>
                                <div className='col-lg-12 fv-row mb-5'>
                                <label className='form-label required'>Segmento de mercado</label>
                                <Field
                                    as='select'
                                    name='IdSegmento'
                                    className='form-select form-select-lg form-select-solid'
                                    value={formik.values.IdSegmento}
                                    onChange={(event: { target: { value: number } }) => {
                                    formik.handleChange(event);
                                    // Aqui você pode acessar o valor selecionado através de event.target.value
                                    setShowNomeSegmento(event.target.value == 2);
                                    }}
                                >
                                    <option value=''>Selecione...</option>
                                    {
                                    segmentos && segmentos.length > 0 ? segmentos.map((data: any, index: any) => {
                                        return <option key={`IdSegmento-option-${index}`} value={data.Id}>{data.Nome}</option>
                                    }) : ''
                                    }
                                </Field>
                                <div className='text-danger mt-2'>
                                    <ErrorMessage name='IdSegmento' />
                                </div>
                                </div>
                                {(showNomeSegmento || formik.values.IdSegmento == 2) && (
                                    <div className="col-lg-12 fv-row mb-5">
                                    <label className='form-label required'>Nome do Segmento:</label>
                                    <Field
                                        name={`NomeSegmento`}
                                        placeholder='Segmento de mercado'
                                        className='form-control form-control-lg form-control-solid'
                                        value={formik.values.NomeSegmento}
                                    />
                                    <div className='text-danger mt-2'>
                                        <ErrorMessage name='NomeSegmento' />
                                    </div>
                                    </div>
                                )}                                
                                <div className='col-lg-6 fv-row mb-5'>
                                <label className='form-label'>Porte da empresa</label>
                                <Field
                                    as='select'
                                    name='PorteEmpresa'
                                    className='form-select form-select-lg form-select-solid'
                                    value={formik.values.PorteEmpresa}
                                >
                                    <option value='0'>Selecione...</option>
                                    {Object.keys(EnumPorteEmpresa).map(key => (
                                    
                                    parseFloat(key) > 0 && (
                                        <option key={key} value={key}>
                                        {EnumPorteEmpresa[parseFloat(key)]}
                                        </option>
                                    )
                                    
                                    ))}
                                </Field>
                                </div>
                                {(formik.values.PorteEmpresa.toString() === EnumPorteEmpresa['Empresa de Médio Porte'].toString() || formik.values.PorteEmpresa.toString() === EnumPorteEmpresa['Grande Empresa'].toString()) && (
                                    <div className="col-lg-6 fv-row mb-5">
                                        <label className='form-label'>Características do negócio:</label>
                                        <Field
                                            name={`CaracteristicasDoNegocio`}
                                            placeholder='Características do negócio'
                                            className='form-control form-control-lg form-control-solid'
                                            value={formik.values.CaracteristicasDoNegocio}
                                            maxLength={150}
                                        />
                                    </div>
                                )}                                
                                <div className="col-lg-6 fv-row mb-5">
                                <label className='form-label'>Quantidade de funcionários</label>
                                <Field
                                    type={`number`}
                                    name={`QtdFuncionarios`}
                                    placeholder='Quantidade de funcionários'
                                    className='form-control form-control-lg form-control-solid'
                                    value={formik.values.QtdFuncionarios}
                                />
                                </div>                                
                                <div className='col-lg-4 fv-row mb-5'>
                                  <label className='form-label required'>
                                    Quantidade máxima de cargos permitidos
                                  </label>
                                  <Field
                                    type='number'
                                    name={`QtdMaximaCargos`}
                                    placeholder='Quantidade máxima de cargos permitidos'
                                    className='form-control form-control-lg form-control-solid'
                                    value={formik.values.QtdMaximaCargos}
                                  ></Field>
                                  <div className='text-danger mt-2'>
                                    <ErrorMessage name='QtdMaximaCargos' />
                                  </div>
                                </div>
                                <div className='col-lg-4 fv-row mb-5'>
                                  <label className='form-label'></label>
                                  <div className='form-check form-switch form-check-custom form-check-solid d-flex justify-content-between mx-0 mt-2 border border-1 rounded ps-5 py-3'>
                                    <label className='form-label m-0'>
                                      Utiliza avaliador?
                                    </label>
                                    <Field
                                      type='checkbox'
                                      name={`UtilizaAvaliador`}
                                      className='form-check-input me-2 h-25px w-40px'
                                      onChange={(e: any) => onChangeCheckUtilizaAvaliador(e)}
                                      checked={formik.values.UtilizaAvaliador}
                                      value={formik.values.UtilizaAvaliador}
                                    ></Field>
                                  </div>
                                </div>
                                <div className='col-lg-4 fv-row mb-5'>
                                  <label className='form-label'></label>
                                  <div className='form-check form-switch form-check-custom form-check-solid d-flex justify-content-between mx-0 mt-2 border border-1 rounded ps-5 py-3'>
                                    <label className='form-label m-0'>
                                      Permite editar descrição?
                                    </label>
                                    <Field
                                      type='checkbox'
                                      name={`PermiteEditarDescricao`}
                                      className='form-check-input me-2 h-25px w-40px'
                                      onChange={(e: any) => onChangeCheckPermiteEditarDescricao(e)}
                                      checked={formik.values.PermiteEditarDescricao}
                                      value={formik.values.PermiteEditarDescricao}
                                    ></Field>
                                  </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </FormikProvider>
            </div>
            <div className="modal-footer d-flex justify-content-between">
                <button
                onClick={() => cancelarAdd()}
                type="button"
                className="btn btn-light"
                data-bs-dismiss="modal"
                >
                    Cancelar
                </button>
                <button
                    type='button'
                    onClick={() => formik.handleSubmit()}
                    className='btn btn-lg btn-primary'
                >
                    Salvar
                </button>
            </div>
            </div>
        </div>
    </div>
  )
}

export {ModalAddCliente}