/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import { Field, FormikProps } from 'formik'
import { IDescreverCampoModel, IDescreverDescricaoModel, IDescreverRespostaModel } from '../helpers/DescreverHelper'
import { DescricaoRespostas } from './DescricaoRespostas'

type Props = {
  formik: FormikProps<IDescreverDescricaoModel>,
  campoSelecionado: number | undefined,
  setCampoSelecionado: React.Dispatch<React.SetStateAction<number | undefined>>,
  respostaSelecionada: number | undefined,
  setRespostaSelecionada: React.Dispatch<React.SetStateAction<number | undefined>>,
  respostaSendoEditada: boolean,
  setRespostaSendoEditada: React.Dispatch<React.SetStateAction<boolean>>,
  respostaBackup: IDescreverRespostaModel | undefined,
  setRespostaBackup: React.Dispatch<React.SetStateAction<IDescreverRespostaModel | undefined>>,
}

const DescricaoCampos: React.FC<Props> = ({
  formik,
  campoSelecionado,
  setCampoSelecionado,
  respostaSelecionada,
  setRespostaSelecionada,
  respostaSendoEditada,
  setRespostaSendoEditada,
  respostaBackup,
  setRespostaBackup
}) => {

  const onClickCampo = (campo: IDescreverCampoModel) => {
    if(campo.TipoCampo.RespostaAutomatica) return
    if (campoSelecionado != campo.Id) {
      fazerBackupDaResposta()
      setCampoSelecionado(campo.Id)
      setRespostaSelecionada(undefined)
    }
  }

  const fazerBackupDaResposta = () => {
    if (respostaBackup && respostaBackup.Id && respostaBackup.ValorAtual) {
      let descricao = formik.values
      let campoIndex = descricao.Campos.findIndex((c => c.Id === respostaBackup.IdCampo))
      let respostaIndex = descricao.Campos[campoIndex].Respostas.findIndex((c => c.Id === respostaBackup.Id))

      descricao.Campos[campoIndex].Respostas[respostaIndex].ValorAtual = respostaBackup.ValorAtual

      formik.setValues(descricao)      
    }
    setRespostaBackup(undefined)
    setRespostaSendoEditada(false)
  }

  return (
    <>
      {
        formik.values.Campos && formik.values.Campos.length > 0 ? formik.values.Campos.map((campo, campoIndex) => {

          const tipoCampo = campo.TipoCampo

          const campoDescricaoClass = `campo-descricao ${tipoCampo.InteracaoComAI ? 'campo-descricao-interativo' : ''}`

          const campoRespostaAutomatica = tipoCampo.RespostaAutomatica
          let campoRespostaAutomaticaValor = ''

          switch(tipoCampo.Id){
            case 1: {
              campoRespostaAutomaticaValor = formik.values.NomeCargo
              break
            }
            case 2: {
              campoRespostaAutomaticaValor = formik.values.NomeNivelHierarquico
              break
            }
            case 3: {
              campoRespostaAutomaticaValor = formik.values.NomeArea
              break
            }
          }

          return <div key={`accordion-campo-${campo.Id}`} id={`accordion-campo-${campo.Id}`} className='mb-8'>
                  <div className={`card card-custom border border-secondary border-1 shadow-sm ${campoDescricaoClass} ${campoSelecionado === campo.Id ? 'campo-descricao-selecionado' : 'campo-descricao-nao-selecionado'}`}>
                    <div className="card-header bg-descricao border-bottom border-secondary border-1 rounded-bottom" id={`card-campo-header-${campo.Id}`} onClick={() => onClickCampo(campo)}>
                      <div className="card-title">
                        <h3 className='text-white'>{(campo.Nome ? campo.Nome : tipoCampo.Nome)}</h3>                                
                      </div>
                      <div className="card-toolbar w-md-auto">                                
                        <button type="button" className="btn btn-icon btn-sm btn-accordion btn-light" data-bs-toggle="collapse" data-bs-target={`#card-campo-collapse-${campo.Id}`} aria-expanded="true" aria-controls={`card-campo-collapse-${campo.Id}`} >
                          <i className='fas fa-arrow-down'></i>
                          <i className='fas fa-arrow-up'></i>
                        </button>                   
                      </div>
                    </div>
                    <div id={`card-campo-collapse-${campo.Id}`} className="collapse show" aria-labelledby={`card-campo-header-${campo.Id}`} data-bs-parent={`#accordion-campo-${campo.Id}`}>
                      <div className="card-body">

                        {(campoRespostaAutomatica) && (
                          <input className={`form-control form-control-lg form-control-solid resposta-descricao`} disabled={true} value={campoRespostaAutomaticaValor} /> 
                        )}

                        {(!campoRespostaAutomatica) && (
                          (campo.Respostas && campo.Respostas.length > 0) && (
                            <DescricaoRespostas
                              formik={formik}
                              respostas={campo.Respostas}
                              tipoCampo={tipoCampo}
                              campoIndex={campoIndex}
                              fazerBackupDaResposta={fazerBackupDaResposta}
                              campoSelecionado={campoSelecionado}
                              setCampoSelecionado={setCampoSelecionado}
                              respostaSelecionada={respostaSelecionada}
                              setRespostaSelecionada={setRespostaSelecionada}
                              respostaSendoEditada={respostaSendoEditada}
                              setRespostaSendoEditada={setRespostaSendoEditada}
                              respostaBackup={respostaBackup}
                              setRespostaBackup={setRespostaBackup}
                            />
                          )
                        )}

                      </div>   
                    </div>                         
                  </div>
                </div>
        }) : ''
      }
    </>    
  )
}

export {DescricaoCampos}
