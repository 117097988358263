import { useEffect, useState } from 'react'
import { useHttpClient } from '../../../modules/services/Bundle'
import { EnumPesquisaParticipanteStatusDeAlcance, RequestMethod } from '../../../modules/services/core/_enums'
import { GraficoAlcancePesquisa } from './partials/GraficoAlcancePesquisa'
import { ModalGraficoAlcancePesquisa } from './partials/ModalGraficoAlcancePesquisa'
import { ISelectModel } from '../../../modules/services/core/_models'
import { IDadosGraficosPesquisaModel } from './helpers/AnaliseResultadoHelper'
import { GraficoMediaPorDimensao } from './partials/GraficoMediaPorDimensao'
import { GraficoMediaGeralPorParticipante } from './partials/GraficoMediaGeralPorParticipante'
import { GraficoDispersao } from './partials/GraficoDispersao'

const AnaliseResultado = () => {
  let [isMobile, setIsMobile] = useState(window.innerWidth < 992)
  let [pesquisas, setPesquisas] = useState<ISelectModel[]>([])
  let [idPesquisa, setIdPesquisa] = useState<number>(0)
  let [dadosGraficosPesquisa, setDadosGraficosPesquisa] = useState<IDadosGraficosPesquisaModel | undefined>(undefined)
  let [statusDeAlcance, setStatusDeAlcance] = useState<number>(EnumPesquisaParticipanteStatusDeAlcance['Não visualizada'])
  let [statusDeAlcanceColor, setStatusDeAlcanceColor] = useState<string>('#FFFFFF')
  let [showModal, setShowModal] = useState<boolean>(false)
  const httpClient = useHttpClient()

  const obterParaSelectPesquisa = async () => {
    const response = await httpClient.request({
      method: RequestMethod.GET,
      endpoint: '/Pesquisa/Resultado/ObterParaSelect',
    })
    if (response.success && response.payload && response.payload.length > 0) {
      setPesquisas(response.payload)
    }
  }

  useEffect(() => {
    obterParaSelectPesquisa()
  },[])

  useEffect(() => {

    const onResizeWindow = () => {
      setIsMobile(window.innerWidth < 992)
    };

    window.addEventListener("resize", onResizeWindow);

    return () => window.removeEventListener("resize", onResizeWindow) 

  }, [])

  const onChangeSelectPesquisa = async (e: React.ChangeEvent<HTMLSelectElement>) => {
    setIdPesquisa(parseInt(e.target.value))  
  }

  const obterDadosGraficos = async () => {
    const response = await httpClient.request({
      method: RequestMethod.GET,
      endpoint: '/Pesquisa/Resultado/ObterDadosGraficos',
      queryObject: {
        idPesquisa: idPesquisa
      }
    })

    if (response.success && response.payload) {
      setDadosGraficosPesquisa(response.payload)
    }
  }

  useEffect(() => {

    setDadosGraficosPesquisa(undefined)
    setStatusDeAlcance(EnumPesquisaParticipanteStatusDeAlcance['Não visualizada'])
    setStatusDeAlcanceColor('#FFFFFF')

    if(idPesquisa > 0) obterDadosGraficos()
    
  },[idPesquisa])

  return (
    <>
      {showModal && statusDeAlcance && idPesquisa && (
        <ModalGraficoAlcancePesquisa 
          idPesquisa={idPesquisa}
          setShowModal={setShowModal}
          statusDeAlcance={statusDeAlcance}
          statusDeAlcanceColor={statusDeAlcanceColor}
        />
      )}
      
      <div className='card mb-10'>
        <div
          className='card-header'
        >
          <div className='card-title m-0'>
            <h3 className='fw-bolder m-0'>Análise de Dados</h3>
          </div>
        </div>

        <div className='card-body'>
            <div className='col-lg-6 fv-row'>
              <label className={`form-label text-gray-800`}>Selecione a pesquisa</label>
              <select
                className='form-select form-select-lg form-select-solid'
                onChange={(e: React.ChangeEvent<HTMLSelectElement>) => onChangeSelectPesquisa(e)}
              >
                <option value='0'>{'Selecione...'}</option>

                {pesquisas && pesquisas.length > 0
                  && pesquisas.map((data: ISelectModel, index: number) => {
                    return (
                      <option key={`select-pesquisa-${index}`} value={data.Id}>
                        {data.Nome}
                      </option>
                    )
                  })
                }
                
              </select>
            </div>
        </div>
      </div>

      {dadosGraficosPesquisa && (
        <>
          <GraficoAlcancePesquisa 
          dadosGraficosPesquisa={dadosGraficosPesquisa}
          setStatusDeAlcance={setStatusDeAlcance}
          setStatusDeAlcanceColor={setStatusDeAlcanceColor}
          setShowModal={setShowModal}
          />

          <GraficoMediaPorDimensao
            dadosGraficosPesquisa={dadosGraficosPesquisa}
          />

          <GraficoDispersao
            dadosGraficosPesquisa={dadosGraficosPesquisa}
          />

          <GraficoMediaGeralPorParticipante
            isMobile={isMobile}
            dadosGraficosPesquisa={dadosGraficosPesquisa}
          />
        </>
      )}

    </>
  )
}

export {AnaliseResultado}
