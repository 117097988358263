import {useEffect, useState} from 'react'
import {CardDescricao} from './partials/CardDescricao'
import {useHttpClient} from '../../../modules/services/Bundle'
import {EnumDescricaoStatusAprovacao, RequestMethod} from '../../../modules/services/core/_enums'
import { DescricaoType } from './Dashboard'
import { ISelectModel } from '../../../modules/services/core/_models'
import { CircularProgressbarWithChildren, buildStyles } from 'react-circular-progressbar'

interface IDashboardDescricaoDados {
  TotalCargos: number
  CargosARevisar: number
  CargosAAvaliar: number
  CargosAAprovar: number
  CargosARevisarPorcentagem: number
  CargosAAvaliarPorcentagem: number
  CargosAAprovarPorcentagem: number
  DescricoesReprovadas: number
  DescricoesAprovadas: number
  DescricoesReprovadasPorcentagem: number
  DescricoesAprovadasPorcentagem: number
}

const defaultDados: IDashboardDescricaoDados = {
  TotalCargos: 0,
  CargosARevisar: 0,
  CargosAAvaliar: 0,
  CargosAAprovar: 0,
  CargosARevisarPorcentagem: 0,
  CargosAAvaliarPorcentagem: 0,
  CargosAAprovarPorcentagem: 0,
  DescricoesReprovadas: 0,
  DescricoesAprovadas: 0,
  DescricoesReprovadasPorcentagem: 0,
  DescricoesAprovadasPorcentagem: 0,
}

const DashboardConsultor = () => {
  let [dados, setDados] = useState<IDashboardDescricaoDados>(defaultDados)
  let [atualizarTodasAsTabelas, setAtualizarTodasAsTabelas] = useState(false)
  let [clientes, setClientes] = useState<ISelectModel[]>([])
  let [idEmpresa, setIdEmpresa] = useState(0);
  const httpClient = useHttpClient()
  const [utilizaAvaliador, setUtilizaAvaliador] = useState(false)
  const [permiteEditarDescricao, setPermiteEditarDescricao] = useState(false)

  const obterParaSelectClientes = async () => {
    const response = await httpClient.request({
      method: RequestMethod.GET,
      endpoint: '/DescricaoDeCargo/Consultoria/ObterClientesParaSelect',
    })
    if (response.success && response.payload && response.payload.length > 0) {
      setClientes(response.payload)
    }
  }

  const obterDescricaoDados = () => {
    httpClient.request({
      method: RequestMethod.GET,
      endpoint: `/DescricaoDeCargo/Dashboard/ObterDashboardDescricaoDados`,
      queryObject: {
        isConsultoria: true,
        idEmpresa: idEmpresa,
      },
    }).then((response) => {
      if (response.success && response.payload) setDados(response.payload)
        httpClient
        .request({
          method: RequestMethod.GET,
          endpoint: '/DescricaoDeCargo/Consultoria/ObterCliente',
          queryObject: {
            idEmpresaCliente: idEmpresa
          },
        })
        .then((result) => {
          if (result.success && result.payload) {
            setPermiteEditarDescricao(result.payload.PermiteEditarDescricao)
            setUtilizaAvaliador(result.payload.UtilizaAvaliador)
          }
        })
    })
  }

  const onChangeSelectCliente = async (e: React.ChangeEvent<HTMLSelectElement>) => {
    setIdEmpresa(parseInt(e.target.value))  
  }

  useEffect(() => {
    obterParaSelectClientes()
  }, [])

  useEffect(() => {
    setDados(defaultDados)
    if (idEmpresa > 0) obterDescricaoDados()
  }, [idEmpresa])

  return (
    <>
      {/* begin::Row */}
      <div className='row g-5 g-xl-8'>
        <div className='col-xl-12'>
          <div className='card'>
            <div
              className='card-header'
            >
              <div className='card-title m-0'>
                <h3 className='fw-bolder m-0'>Dashboard</h3>
              </div>
            </div>

            <div className='card-body d-flex justify-content-between'>
                <div className='col-lg-6 fv-row'>
                  <label className={`form-label text-gray-800`}>Selecione um cliente</label>
                  <select
                    className='form-select form-select-lg form-select-solid'
                    onChange={(e: React.ChangeEvent<HTMLSelectElement>) => onChangeSelectCliente(e)}
                    defaultValue={idEmpresa}
                  >
                    <option value='0'>{'Selecione...'}</option>

                    {clientes && clientes.length > 0
                      && clientes.map((data: ISelectModel, index: number) => {
                        return (
                          <option key={`select-cliente-${index}`} value={data.Id}>
                            {data.Nome}
                          </option>
                        )
                      })
                    }
                    
                  </select>
                </div>
                {idEmpresa !== 0 && (
                  <div className='d-flex justify-content-center col-3' style={{flexWrap: "wrap"}}>
                    <label className='fs-2 fw-semibold'>Desenvolvimento do Projeto:</label>
                    <div className='mt-2' style={{ width: 150, height: 150 }}>
                      <CircularProgressbarWithChildren 
                        value={dados.DescricoesAprovadasPorcentagem} 
                        maxValue={100} 
                        styles={buildStyles({
                          strokeLinecap: 'round',
                          pathTransitionDuration: 1,
                          pathColor: `rgba(46, 204, 113, ${dados.DescricoesAprovadasPorcentagem / 100})`,
                          trailColor: '#d6d6d6',
                          backgroundColor: '#3e98c7',
                        })}>
                            <h1 className='ms-2'>{dados.DescricoesAprovadasPorcentagem}%</h1>
                        </CircularProgressbarWithChildren>
                    </div>

                  </div>
                )}
            </div>
          </div>
        </div>

        {idEmpresa !== 0 && (
          <>
            <div className='col-xl-12'>
              <CardDescricao
                svgIcon='/media/icons/duotune/files/fil005.svg'
                color='#ABABAB'
                iconColor='white'
                title='A Revisar'
                titleColor='white'
                total={dados.CargosARevisar}
                chartValue={dados.CargosARevisarPorcentagem}
                descricaoType={DescricaoType.Revisar}
                statusDescricao={EnumDescricaoStatusAprovacao.ARevisar}
                btnTo='/descricao/revisar'
                setDadosDash={obterDescricaoDados}
                atualizarTodasAsTabelas={atualizarTodasAsTabelas}
                setAtualizarTodasAsTabelas={setAtualizarTodasAsTabelas}
                consultor={idEmpresa}
                filterArea={true}
              />
            </div>
            {utilizaAvaliador && (
              <div className='col-xl-12'>
                <CardDescricao
                  svgIcon='/media/icons/duotune/files/fil011.svg'
                  color='#3498DB'
                  iconColor='white'
                  title='A Avaliar'
                  titleColor='white'
                  total={dados.CargosAAvaliar}
                  chartValue={dados.CargosAAvaliarPorcentagem}
                  btnTo='/descricao/descricoes' 
                  descricaoType={DescricaoType.Avaliar}
                  statusDescricao={EnumDescricaoStatusAprovacao.AAvaliar}
                  setDadosDash={obterDescricaoDados}
                  atualizarTodasAsTabelas={atualizarTodasAsTabelas}
                  setAtualizarTodasAsTabelas={setAtualizarTodasAsTabelas}
                  consultor={idEmpresa}
                  filter={true}
                  filterArea={true}
                />
              </div>

            )}
            <div className='col-xl-12'>
              <CardDescricao
                svgIcon='/media/icons/duotune/general/gen005.svg'
                color='#1ABC9C'
                iconColor='white'
                title='A Aprovar'
                titleColor='white'
                total={dados.CargosAAprovar}
                chartValue={dados.CargosAAprovarPorcentagem}
                btnTo='/descricao/descricoes'
                descricaoType={DescricaoType.Aprovar}
                statusDescricao={EnumDescricaoStatusAprovacao.AAprovar}
                setDadosDash={obterDescricaoDados}
                atualizarTodasAsTabelas={atualizarTodasAsTabelas}
                setAtualizarTodasAsTabelas={setAtualizarTodasAsTabelas}
                consultor={idEmpresa}
                filter={true}
                filterArea={true}
              />
            </div>
            <div className='col-xl-12'>
              <CardDescricao
                svgIcon='/media/icons/duotune/files/fil007.svg'
                color='#E74C3C'
                iconColor='white'
                title='Reprovadas'
                titleColor='white'
                total={dados.DescricoesReprovadas}
                chartValue={dados.DescricoesReprovadasPorcentagem}
                descricaoType={DescricaoType.DescricoesReprovadas}
                statusDescricao={EnumDescricaoStatusAprovacao.Reprovada}
                btnTo=''
                setDadosDash={setDados}
                atualizarTodasAsTabelas={atualizarTodasAsTabelas}
                setAtualizarTodasAsTabelas={setAtualizarTodasAsTabelas}
                consultor={idEmpresa}
                filterArea={true}
              />
            </div>
            <div className='col-xl-12'>
              <CardDescricao
                svgIcon='/media/icons/duotune/files/fil008.svg'
                color='#2ECC71'
                iconColor='white'
                title='Aprovadas'
                titleColor='white'
                total={dados.DescricoesAprovadas}
                chartValue={dados.DescricoesAprovadasPorcentagem}
                descricaoType={DescricaoType.DescricoesAprovadas}
                statusDescricao={EnumDescricaoStatusAprovacao.Aprovada}
                btnTo=''
                setDadosDash={setDados}
                atualizarTodasAsTabelas={atualizarTodasAsTabelas}
                setAtualizarTodasAsTabelas={setAtualizarTodasAsTabelas}
                consultor={idEmpresa}
                filterArea={true}
                gerarRelatorio={true}
              />
            </div>
          </>

        )}
      </div>
      {/* end::Row */}
    </>
  )
}

export {DashboardConsultor, DescricaoType}
