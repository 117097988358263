import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import { visuallyHidden } from '@mui/utils';
import TableSortLabel from '@mui/material/TableSortLabel';
import React from 'react';
import { useAlert, useHttpClient } from '../../../modules/services/Bundle';
import { RequestMethod } from '../../../modules/services/core/_enums';
import Paper from '@mui/material/Paper';
import { Link } from 'react-router-dom';
import { KTSVG } from '../../../../_metronic/helpers';
import { IconButton } from '@mui/material';
import { ModalArea } from './partials/ModalArea';
import { useAuth } from '../../../modules/auth';
import { ISelectModel } from '../../../modules/services/core/_models';

interface Column {
    id: 'Id' |'Nome' | 'Descricao';
    label: string;
    minWidth?: number;
    align?: 'center';
    format?: (value: number) => string;
}

const columns: readonly Column[] = [
    { 
      id: 'Nome', 
      label: 'ÁREA'
    },
    {
        id: 'Descricao',
        label: 'DESCRIÇÃO'
    }
];

interface Data {
    Id: number;
    IdEmpresa: number;
    Nome: string;
    Descricao: string;
    NomeEmpresa: string;
}

function createData(
    Id: number,
    IdEmpresa: number,
    Nome: string,
    Descricao: string,
    NomeEmpresa: string
): Data {
  return {Id, IdEmpresa, Nome, Descricao, NomeEmpresa};
} 

type Order = 'asc' | 'desc';

interface EnhancedTableProps {
    numSelected: number;
    onRequestSort: (event: React.MouseEvent<unknown>, property: keyof Data) => void;
    order: Order;
    orderBy: string;
}

function EnhancedTableHead(props: EnhancedTableProps) {
  const { order, orderBy, onRequestSort } =
    props;
  const createSortHandler =
    (property: keyof Data) => (event: React.MouseEvent<unknown>) => {
      onRequestSort(event, property);
    };

  return (
    <TableHead>
      <TableRow className='table-header'>
        {columns.map((columns) => (
          <TableCell
            className='table-cell'
            key={columns.id}
            align={columns.align ? 'right' : 'left'}
          >
            <TableSortLabel
              className='fw-bolder'
              active={orderBy === columns.id}
              direction={orderBy === columns.id ? order : 'asc'}
              onClick={createSortHandler(columns.id)}
            >
              {columns.label}
              {orderBy === columns.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
          <TableCell
            className='table-cell fw-bolder'
            align='center'
          >
            AÇÕES
          </TableCell>
      </TableRow>
    </TableHead>
  );
}

interface AreasProps {
    isConsultoria: boolean
}

const Areas = ({ isConsultoria }: AreasProps) => {
    const httpClient = useHttpClient()
    const [order, setOrder] = React.useState<Order>('asc');
    const [orderBy, setOrderBy] = React.useState<keyof Data>('Nome');
    const [selected, setSelected] = React.useState<readonly string[]>([]);
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [total, setTotal] = React.useState(0)
    const [rows, setRows] = React.useState<Data[]>([] as Data[]);
    const [abrirModalArea, setAbrirModalArea] = React.useState(false)
    const [visualizar, setVisualizar] = React.useState<boolean>(false)
    const [currentArea, setCurrentArea] = React.useState<Data | undefined>(undefined);
    const alert = useAlert()
    const {auth} = useAuth()
    const [empresasCliente, setEmpresasCliente] = React.useState<ISelectModel[]>([])
    const [idEmpresaClienteSelecionada, setIdEmpresaClienteSelecionada] = React.useState<number>(0)

    const resquestTable = async (pageRq: number, orderByRq: string, orderRq: string, rowsPerPageRq: number, Filtros: any = null) => {
        await httpClient.request({
            method: RequestMethod.POST,
            endpoint: '/Gerenciamento/Area/Listar',
            queryObject: {
              isConsultoria: isConsultoria,
              idEmpresa: idEmpresaClienteSelecionada
            },
            data: {
              Order: orderRq,
              OrderBy: orderByRq,
              Page: pageRq,
              RowsPerPage: rowsPerPageRq,
              Filters: Filtros
            }
        }).then((result) => {
            const newRows = result.payload.data.map((data: Data) => {
                return createData(data.Id, data.IdEmpresa, data.Nome, data.Descricao, data.NomeEmpresa)
            })
            setRows([...newRows])
            setTotal(result.payload.total)
        })
    }

    React.useEffect(() => {
      carregarDados()
    },[isConsultoria])
  
    React.useEffect(() => {
      setRows([])
      if (isConsultoria && idEmpresaClienteSelecionada > 0) obterDadosTabela()
    },[idEmpresaClienteSelecionada])
  
    const carregarDados = async () => {
      if (isConsultoria) await obterEmpresasCliente()
      else await obterDadosTabela()
    }
  
    const obterEmpresasCliente = async () => {
      const response = await httpClient.request({
        method: RequestMethod.GET,
        endpoint: '/DescricaoDeCargo/Consultoria/ObterClientesParaSelect',
      })
  
      if(response.success && response.payload) {
        setEmpresasCliente(response.payload)
        if(response.payload.length > 0) setIdEmpresaClienteSelecionada(response.payload[0].Id)
      }
    }
  
    const obterDadosTabela = async () => {
      await resquestTable(page, orderBy, order, rowsPerPage)
    }

    const handleRequestSort = (
        event: React.MouseEvent<unknown>,
        property: keyof Data,
    ) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
        resquestTable(page, property, isAsc ? 'desc' : 'asc', rowsPerPage);
    };

    const handleChangePage = (event: unknown, newPage: number) => {
        resquestTable(newPage, orderBy, order, rowsPerPage);
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        resquestTable(page, orderBy, order, parseInt(event.target.value, 10));
        setPage(0);
    };

    const emptyRows = rows.length;

    return (
        <>
        <div className='card mb-5 mb-xl-10'>
    
          <div className='card-header'>
            <div className='card-title m-0'>
              <h3 className='fw-bolder m-0'>Áreas</h3>
            </div>
            {((auth?.roles.find(x => x === 'Master') !== undefined) || (auth?.roles.find(x => x === 'Administrador') !== undefined) || (auth?.permissoes.find(x => x === (isConsultoria ? '204305E6078159C878A3AB2E724507FE' : '0583CA12263F41DA12F6118983945532')) !== undefined)) && (
              <div className='card-toolbar'>
                  <button
                  type='button'
                  className='btn btn-light-primary'
                  onClick={() => { 
                      setCurrentArea(undefined); 
                      setVisualizar(false); 
                      setAbrirModalArea(true); 
                  }}
                  >
                      <KTSVG path="/media/icons/duotune/arrows/arr009.svg" className="svg-icon-muted svg-icon-1" />
                      Adicionar
                  </button>
              </div>
            )}
          </div>
          <div className='card-body p-9'>     
            {(isConsultoria) && (
              <>
                <div className='row'>
                  <div className='col-lg-4 mb-5'>
                    <label className='form-label'>Cliente</label>
                    <select
                      className='form-select form-select-lg form-select-solid'
                      value={idEmpresaClienteSelecionada}
                      onChange={(e: React.ChangeEvent<HTMLSelectElement>) => { setIdEmpresaClienteSelecionada(parseInt(e.target.value)) }}
                    >
                      <option value={0}>Selecione...</option>
                      {empresasCliente && empresasCliente.length > 0 && empresasCliente.map((data: ISelectModel, index: number) => {
                          return (
                            <option key={`cliente-option-${index}`} value={data.Id}>
                              {data.Nome}
                            </option>
                          )
                      })}
                    </select>
                  </div>
                </div>
              </>
            )}                     
            <div className='row'>
              <div className='col-lg-12'>
                <Box sx={{ width: '100%' }}>
                  <Paper className='table-border ' sx={{ width: '100%', mb: 2 }}>
                    <TableContainer>
                      <Table
                        className='table-header'
                        sx={{ minWidth: 750 }}
                        aria-labelledby="tableTitle"
                      >
                        <EnhancedTableHead
                          numSelected={selected.length}
                          order={order}
                          orderBy={orderBy}
                          onRequestSort={handleRequestSort}
                        />
                        <TableBody>
                          {rows.map((row, index) => {
                            return (
                              <TableRow
                                className='table-row'
                                hover
                                tabIndex={-1}
                                key={index}
                                sx={{ cursor: 'pointer' }}
                              >
                                <TableCell className='table-cell' align="left">{row.Nome}</TableCell>
                                <TableCell className='table-cell' align="left">{row.Descricao}</TableCell>
                                <TableCell className='table-cell' align="center">
                                  <IconButton 
                                    className={`text-gray-800`}
                                    title='Visualizar' 
                                    onClick={() => { 
                                        setCurrentArea(row); 
                                        setVisualizar(true); 
                                        setAbrirModalArea(true); 
                                    }}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-eye-fill" viewBox="0 0 16 16">
                                        <path d="M10.5 8a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0z"/>
                                        <path d="M0 8s3-5.5 8-5.5S16 8 16 8s-3 5.5-8 5.5S0 8 0 8zm8 3.5a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7z"/>
                                    </svg>
                                  </IconButton>
                                  {((auth?.roles.find(x => x === 'Master') !== undefined) || (auth?.roles.find(x => x === 'Administrador') !== undefined) || (auth?.permissoes.find(x => x === (isConsultoria ? 'A8CA5F02E69A35C1112DA20EBB786960' : 'A73C511E8CDA63F791DB77780041C93C')) !== undefined)) && (
                                    <IconButton 
                                      className={`text-gray-800`}
                                      title='Editar'
                                      onClick={() => { 
                                          setCurrentArea(row); 
                                          setVisualizar(false); 
                                          setAbrirModalArea(true); 
                                      }}>
                                      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-pencil-square" viewBox="0 0 16 16">
                                          <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z"/>
                                          <path fillRule="evenodd" d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z"/>
                                      </svg>
                                    </IconButton>
                                  )}
                                  {((auth?.roles.find(x => x === 'Master') !== undefined) || (auth?.roles.find(x => x === 'Administrador') !== undefined) || (auth?.permissoes.find(x => x === (isConsultoria ? 'E6142BA03AC85988BABEDD10F0F6F8F6' : 'FA1E4A99E46C1B3F40869D0E8735B233')) !== undefined)) && (
                                    <IconButton 
                                      className={`text-gray-800`}
                                      title='Excluir'
                                      onClick={() => {
                                          alert.createDialog({
                                              html: `Realmente deseja excluir esta área?`,
                                              confirmAction: async () => {                                      
                                                  const response = await httpClient.request({
                                                    method: RequestMethod.DELETE,
                                                    endpoint: '/Gerenciamento/Area/Deletar',
                                                    queryObject: {
                                                      isConsultoria: isConsultoria,
                                                      idEmpresa: idEmpresaClienteSelecionada
                                                    },
                                                    data: row.Id
                                                  })

                                                  if (response.success) obterDadosTabela()
                                              }
                                          })
                                      }}
                                      >
                                      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-trash-fill" viewBox="0 0 16 16">
                                          <path d="M2.5 1a1 1 0 0 0-1 1v1a1 1 0 0 0 1 1H3v9a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V4h.5a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H10a1 1 0 0 0-1-1H7a1 1 0 0 0-1 1H2.5zm3 4a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 .5-.5zM8 5a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7A.5.5 0 0 1 8 5zm3 .5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 1 0z"/>
                                      </svg>
                                    </IconButton>
                                  )}
                                </TableCell>
                              </TableRow>
                            );
                          })}
                          {emptyRows === 0 && (
                            <TableRow
                            >
                              <TableCell className='table-cell text-center' colSpan={6}>
                                Nenhum registro encontrado
                              </TableCell>
                            </TableRow>
                          )}
                        </TableBody>
                      </Table>
                    </TableContainer>
                    <TablePagination
                      className='table-footer'
                      labelRowsPerPage="linhas por página"
                      rowsPerPageOptions={[10, 25]}
                      component="div"
                      count={total}
                      rowsPerPage={rowsPerPage}
                      page={page}
                      onPageChange={handleChangePage}
                      onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                  </Paper>
                </Box>
              </div>
            </div>
          </div>
          <div className='card-footer d-flex justify-content-end py-4'>
            <Link className='btn btn-secondary' to={`/inicio`} >Voltar</Link>
          </div>
        </div>
        {
          abrirModalArea && (
              <ModalArea
                isConsultoria={isConsultoria}
                empresasCliente={empresasCliente}
                descricaoTipo={!currentArea ? "Adicionar nova área" : visualizar ? "Visualizar área" : "Editar área"}
                abrirModal={abrirModalArea}
                setAbrirModal={setAbrirModalArea}
                obterDadosTabela={obterDadosTabela}
                visualizar={visualizar}
                currentArea={currentArea}
            />
          )
        }
        </>
      )
}

export {Areas}
