import { ErrorMessage, Field, FormikProvider, useFormik } from 'formik'
import { useEffect, useState } from 'react'
import { KTSVG } from '../../../../../_metronic/helpers'
import { useHttpClient } from '../../../../modules/services/Bundle'
import { RequestMethod } from '../../../../modules/services/core/_enums'
import { defaultValidationSchemas,  defaultCargo, moduloDescricaoValidationSchema} from '../helpers/ModalAddCargoHelper'
import { useAuth } from '../../../../modules/auth'

type Props = {
    descricaoTipo: string
    abrirModal: boolean
    setAbrirModal: Function
    atualizarDados: Function
    idEmpresa?: number | null
}

const ModalAddCargo = ({descricaoTipo, abrirModal, setAbrirModal, atualizarDados, idEmpresa = null}: Props) => {
    
    const httpClient = useHttpClient()
    const {auth} = useAuth()
    const servico = auth?.servico

    const formik = useFormik({
        initialValues: defaultCargo,
        validationSchema: servico?.moduloDescricaoConfig.ativo ? moduloDescricaoValidationSchema : defaultValidationSchemas,
        onSubmit: (values, actions) => {
    
        },
    })

    const [modelos, setModelos] = useState<any[]>([]);
    let [nivel, setNivel] = useState<any[]>([])
    let [area, setArea] = useState<any[]>([])
    

    useEffect(() => {
        httpClient.request({
          method: RequestMethod.GET,
          endpoint: '/DescricaoDeCargo/Descrever/ObterSelectsParaAddCargos',
          queryObject: {
            idEmpresa: idEmpresa ?? 0
          },
        }).then((response) => {
          if(response.success && response.payload){
    
            const dados = response.payload
            if(dados.Areas && dados.Areas.length > 0) setArea(dados.Areas)
            if(dados.NiveisHierarquicos && dados.NiveisHierarquicos.length > 0) setNivel(dados.NiveisHierarquicos)
            if(dados.Modelos && dados.Modelos.length > 0) setModelos(dados.Modelos)
          }
        })
    }, [])

    const salvarNovoCargo = async () => {
        if (formik.values.area && formik.values.nome && formik.values.nivel && formik.values.modelo) {
           
            try {
                setAbrirModal(false)
                const response = await httpClient.request({
                    method: RequestMethod.POST, 
                    endpoint: '/Gerenciamento/Cargo/Adicionar',
                    queryObject: {
                        isConsultoria: idEmpresa !== null,
                        idEmpresa: idEmpresa ?? 0
                    },
                    data:{
                        Nome: formik.values.nome,
                        IdArea: formik.values.area,
                        IdNivelHierarquico: formik.values.nivel,
                        IdModelo: servico?.moduloDescricaoConfig.ativo ? formik.values.modelo : 0,
                    },
                    ifSuccessRedirectTo: '/descricao/processos',
                })
                if (response && response.success && response.payload) {
                    atualizarDados()

                    let defaultFormik = defaultCargo
                    formik.setFieldValue('nome', defaultFormik.nome)
                    formik.setFieldValue('area', '')
                    formik.setFieldValue('nivel', '')
                    formik.setFieldValue('modelo', '')
                }
            } catch (e) {
                setAbrirModal(true)
            }            

        }
    }

    return (
    <div className={`modal ${abrirModal? "show" : ""} fade`} tabIndex={-1} id="kt_modal_1" style={{display: `${abrirModal? "block" : "none"}`}}>
        <div className="modal-dialog modal-dialog-centered modal-xl">
            <div className="modal-content">
            <div className="modal-header">
                <h5 className="modal-title">{descricaoTipo}</h5>
                <div
                onClick={() => setAbrirModal(false)}
                className="btn btn-icon btn-sm btn-active-light-primary ms-2"
                data-bs-dismiss="modal"
                aria-label="Fechar"
                >
                <KTSVG
                    path="/media/icons/duotune/arrows/arr061.svg"
                    className="svg-icon svg-icon-2x"
                />
                </div>
            </div>
            <div className="modal-body">
                
                <FormikProvider value={formik}>
                    <div className='w-100'>
                        <div className="row">

                        <div className="col-lg-12 fv-row mb-5">
                            <label className='form-label required'>Nome</label>
                            <Field
                                name={`nome`}
                                placeholder='Nome'
                                className='form-control form-control-lg form-control-solid'
                                maxLength={60}
                                value={formik.values.nome}
                            />
                            <div className='text-danger mt-2'>
                                <ErrorMessage name='nome' />
                            </div> 
                        </div>

                        <div className="col-lg-6 fv-row mb-5">
                            <label className='form-label required'>Nível hierárquico</label>
                                <Field
                                    as="select" 
                                    name={`nivel`}
                                    className='form-select form-select-lg form-select-solid'
                                    value={formik.values.nivel}
                                >  
                                    <option value=''>Selecione...</option>
                                    {
                                        nivel && nivel.length > 0 && nivel.map((data: any, index: any) => {
                                            return <option key={`IdNivel-option-${index}`} value={data.Id}>{data.Nome}</option>
                                        })
                                    }
                                </Field>
                                <div className='text-danger mt-2'>
                                    <ErrorMessage name='nivel' />
                                </div>                   
                        </div>   

                        <div className="col-lg-6 fv-row">
                            <label className='form-label required'>Área</label>
                                <Field 
                                    as="select" 
                                    name={`area`}
                                    className='form-select form-select-lg form-select-solid'
                                    value={formik.values.area}
                                >  
                                    <option value=''>Selecione...</option>
                                    {
                                        area && area.length > 0 && area.map((data: any, index: any) => {
                                            return <option key={`IdArea-option-${index}`} value={data.Id}>{data.Nome}</option>
                                        })
                                    }
                                </Field>
                                <div className='text-danger mt-2'>
                                    <ErrorMessage name='area' />
                                </div>                   
                        </div>             

                        </div>

                        {servico?.moduloDescricaoConfig.ativo && (
                            <div className='row'>
                                <div className='col-lg-6'>
                                    <label className='form-label required'>Modelo de formulário</label>
                                    <Field as='select' name={'modelo'} className='form-select form-select-lg form-select-solid' value={formik.values.modelo}>
                                        <option value=''>Selecione...</option>
                                        {modelos.map((data: any, index: any) => {
                                            return <option key={`modelo-${index}`} value={data.Id}>{data.Nome}</option>
                                        })}
                                    </Field>
                                    <div className='text-danger mt-2'>
                                        <ErrorMessage name='modelo' />
                                    </div>      
                                </div>
                            </div>
                        )}
                    </div>
                </FormikProvider>
            </div>
            <div className="modal-footer">
                <button
                onClick={() => setAbrirModal(false)}
                type="button"
                className="btn btn-light me-5"
                data-bs-dismiss="modal"
                >
                    Cancelar
                </button>
                
                <button type='button' onClick={() => salvarNovoCargo()} className='btn  btn-primary btn-descricao'>
                    Salvar
                </button>

            </div>
            </div>
        </div>
    </div>
  )
}

export {ModalAddCargo}