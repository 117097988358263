import { ErrorMessage, Field, FormikProvider, useFormik } from 'formik'
import { useEffect } from 'react'
import { KTSVG } from '../../../../../_metronic/helpers'
import { useAlert, useHttpClient } from '../../../../modules/services/Bundle'
import { RequestMethod } from '../../../../modules/services/core/_enums'
import { defaultArea, defaultValidationSchemas } from '../helpers/ModalAreaHelper'
import { ISelectModel } from '../../../../modules/services/core/_models'
import React from 'react'

type Props = {
    isConsultoria: boolean
    empresasCliente: ISelectModel[]
    descricaoTipo: string
    abrirModal: boolean
    setAbrirModal: Function
    obterDadosTabela: Function
    visualizar: boolean
    currentArea: Data | undefined    
}

interface Data {
    Id: number;
    IdEmpresa: number;
    Nome: string;
    Descricao: string;
    NomeEmpresa: string;
}

const ModalArea = ({isConsultoria, empresasCliente, descricaoTipo, setAbrirModal, obterDadosTabela, visualizar, currentArea}: Props) => {
    
    const httpClient = useHttpClient()
    const alert = useAlert()
    const [idEmpresaClienteSelecionada, setIdEmpresaClienteSelecionada] = React.useState<number>(0)
    
    const formik = useFormik({
        initialValues: defaultArea,
        validationSchema: defaultValidationSchemas,
        onSubmit: async (values, actions) => {

            alert.createDialog({
                html: `Realmente deseja ${currentArea ? 'editar' : 'adicionar'} esta área?`,
                confirmAction: async () => {                                      
                    
                    const response = await httpClient.request({
                        method: currentArea ? RequestMethod.PUT : RequestMethod.POST, 
                        endpoint: `/Gerenciamento/Area/${currentArea ? "Editar" : "Adicionar"}`,
                        queryObject: {
                            isConsultoria: isConsultoria,
                            idEmpresa: idEmpresaClienteSelecionada
                        },
                        data:{
                            Id: currentArea ? currentArea.Id : 0,
                            Nome: values.nome,
                            Descricao: values.descricao
                        }
                    })
                    
                    if (response.success) {
                        setAbrirModal(false)
                        obterDadosTabela()
                    }

                }
            })            
    
        },
    })    

    useEffect(() => {
        if(currentArea){            
            formik.setValues({
                nome: currentArea.Nome,
                descricao: currentArea.Descricao
            })  
            setIdEmpresaClienteSelecionada(currentArea.IdEmpresa)          
        }
    }, [currentArea])

    return (
    <div className={`modal show fade`} tabIndex={-1} id="kt_modal_1" style={{display: "block"}}>
        <div className="modal-dialog modal-dialog-centered modal-xl">
            <div className="modal-content">
            <div className="modal-header">
                <h5 className="modal-title">{descricaoTipo}</h5>
                <div
                onClick={() => setAbrirModal(false)}
                className="btn btn-icon btn-sm btn-active-light-primary ms-2"
                data-bs-dismiss="modal"
                aria-label="Fechar"
                >
                <KTSVG
                    path="/media/icons/duotune/arrows/arr061.svg"
                    className="svg-icon svg-icon-2x"
                />
                </div>
            </div>
            <div className="modal-body">                
                <FormikProvider value={formik}>
                    <div className='w-100'>                        
                        <div className='row'>
                            {(isConsultoria) && (
                                <>
                                    <div className='col-lg-12 fv-row mb-5'>
                                        <label className='form-label required'>Cliente</label>
                                        <select
                                            className='form-select form-select-lg form-select-solid'
                                            value={idEmpresaClienteSelecionada}
                                            onChange={(e: React.ChangeEvent<HTMLSelectElement>) => { setIdEmpresaClienteSelecionada(parseInt(e.target.value)) }}
                                            disabled={currentArea !== undefined && currentArea.Id > 0}
                                        >
                                        <option value={0}>Selecione...</option>
                                        {empresasCliente && empresasCliente.length > 0 && empresasCliente.map((data: ISelectModel, index: number) => {
                                            return (
                                                <option key={`cliente-option-${index}`} value={data.Id}>
                                                {data.Nome}
                                                </option>
                                            )
                                        })}
                                        </select>
                                    </div>
                                </>
                            )}   
                            <div className="col-lg-12 fv-row mb-5">
                                <label className='form-label required'>Nome</label>
                                <Field
                                    name={`nome`}
                                    placeholder='Nome'
                                    className='form-control form-control-lg form-control-solid'
                                    maxLength={60}
                                    value={formik.values.nome}
                                    disabled={visualizar}
                                />
                                <div className='text-danger mt-2'>
                                    <ErrorMessage name='nome' />
                                </div> 
                            </div>     
                            <div className="col-lg-12 fv-row mb-5">
                                <label className='form-label'>Descrição</label>
                                <Field
                                    name={`descricao`}
                                    as={() => 
                                    <textarea 
                                    readOnly={visualizar} 
                                    defaultValue={formik.values.descricao} 
                                    rows={3} 
                                    className='form-control form-control-lg form-control-solid' 
                                    placeholder='Descrição'
                                    onBlur={(e) => {
                                        formik.setFieldValue('descricao', e.currentTarget.value)
                                    }}
                                    />}
                                />
                                <div className='text-danger mt-2'>
                                    <ErrorMessage name='descricao' />
                                </div> 
                            </div>     
                        </div>
                    </div>
                </FormikProvider>
            </div>
            <div className="modal-footer">
                <button
                    onClick={() => setAbrirModal(false)}
                    type="button"
                    className="btn btn-light me-5"
                    data-bs-dismiss="modal"
                >
                    Cancelar
                </button>
                
                <button type='button' onClick={() => formik.handleSubmit()} className={`btn btn-primary ${visualizar ? 'd-none' : ''}`}>
                    Salvar
                </button>

            </div>
            </div>
        </div>
    </div>
  )
}

export {ModalArea}