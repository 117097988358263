import {FC, useEffect, useState} from 'react'
import {KTSVG, toAbsoluteUrl} from '../../../../../_metronic/helpers'
import {Field, ErrorMessage, FormikProps} from 'formik'
import { IExemploDimensaoModel, IExemploDimensaoPerguntaModel, IWizardModel } from '../helpers/PesquisaHelper'
import { Tooltip, useAlert, useHttpClient } from '../../../../modules/services/Bundle'
import { EnumPesquisaStatus, EnumPesquisaTipoDisposicaoPergunta, RequestMethod } from '../../../../modules/services/core/_enums'
import $ from 'jquery'
import { AbrirModalVideo } from '../../../toolbar/AbrirModalVideo'
import { IconButton } from '@mui/material'
import { PesquisaAccordions } from '../partials/PesquisaAccordions'
import { PesquisaPerguntas } from '../partials/PesquisaPerguntas'
import { IPesquisaRespostaModel } from '../helpers/ConfiguracoesIniciaisHelper'

interface Step2Props {
  formik: FormikProps<IWizardModel>,
  dimensoes: IExemploDimensaoModel[],
  setDimensoes: React.Dispatch<React.SetStateAction<IExemploDimensaoModel[]>>,
  perguntas: IExemploDimensaoPerguntaModel[],
  setPerguntas: React.Dispatch<React.SetStateAction<IExemploDimensaoPerguntaModel[]>>,
  divAgrupar: boolean,
  setDivAgrupar: React.Dispatch<React.SetStateAction<boolean>>,
  divAleatorio: boolean
  setDivAleatorio: React.Dispatch<React.SetStateAction<boolean>>,
}

const Step2: FC<Step2Props> = ({
  formik,
  dimensoes,
  setDimensoes,
  perguntas,
  setPerguntas,
  divAgrupar,
  setDivAgrupar,
  divAleatorio,
  setDivAleatorio,
}) => {
  const httpClient = useHttpClient()
  const alert = useAlert()

  const onClickDivAgrupar = async () => {
    if (formik.values.Status !== EnumPesquisaStatus.Finalizada && formik.values.Status !== EnumPesquisaStatus.EmAndamento && formik.values.Status !== EnumPesquisaStatus.Suspensa) {
      setDivAgrupar(true)
      setDivAleatorio(false)
      setPerguntas([])
      formik.setFieldValue('TipoDisposicaoPergunta', EnumPesquisaTipoDisposicaoPergunta.AgruparPorDimensao)
      const response = await httpClient.request({
        method: RequestMethod.GET,
        endpoint: '/Pesquisa/Pesquisa/ObterExemploPerguntasAgrupadasPorDimensao',
        queryObject: {
          idTema: formik.values.IdTema
        },
      })
      if (response.success && response.payload && response.payload.length > 0) {
        setDimensoes(response.payload)
      }
    }
  }

  const onClickDivAleatorio = async () => {
    if (formik.values.Status !== EnumPesquisaStatus.Finalizada && formik.values.Status !== EnumPesquisaStatus.EmAndamento && formik.values.Status !== EnumPesquisaStatus.Suspensa) {
      setDivAleatorio(true)
      setDivAgrupar(false)
      setDimensoes([])
      formik.setFieldValue('TipoDisposicaoPergunta', EnumPesquisaTipoDisposicaoPergunta.OrdenarAleatoriamente)
      const response = await httpClient.request({
        method: RequestMethod.GET,
        endpoint: '/Pesquisa/Pesquisa/ObterExemploPerguntasOrdenadasAleatoriamente',
        queryObject: {
          idTema: formik.values.IdTema
        },
      })
  
      if (response.success && response.payload && response.payload.length > 0) {
        setPerguntas(response.payload)
      }
    }
  }

  return (
    <>
      <div className='w-100 d-flex justify-content-center flex-wrap'>
        <div className='row col-12'>
          <div 
            className='col-lg-12 row mb-5'
          >
            <h3 className='stepper-title text-center'>Disposição das Perguntas para a pesquisa: <span className='text-white'>{formik.values.Nome}</span></h3>   
          </div>
        </div>
        <div className='row w-xl-700px'>
          <div 
            id='agrupar'
            className='col-lg-6 mb-5  d-flex justify-content-center'
            onClick={() => onClickDivAgrupar()}
          >
            <div className={`w-100 row border border-2 rounded p-5 ${divAgrupar ? 'card-pesquisa-selecionado' : 'card-pesquisa-nao-selecionado'} `}>
              <div className='col-lg-1 '>
                <KTSVG path="/media/icons/duotune/text/txt009.svg" className="svg-icon-muted text-gray-900 svg-icon-2hx" />
              </div>
              <div className='col-lg-11 '>
                <h3 className='stepper-title text-center'>Agrupar por dimensão</h3>   
              </div>
            </div>
          </div>

          <div 
            id='aleatorio'
            className='col-lg-6 mb-5 '
            onClick={() => onClickDivAleatorio()}
          >
            <div className={`w-100 row border border-2 rounded p-5 ${divAleatorio ? 'card-pesquisa-selecionado' : 'card-pesquisa-nao-selecionado'}`}>
              <div className='col-lg-1 '>
                <KTSVG path="/media/icons/duotune/text/txt001.svg" className="svg-icon-muted text-gray-900 svg-icon-2hx" />
              </div>
              <div className='col-lg-11 '>
                <h3 className='stepper-title text-center'>Ordenar Aleatóriamente</h3>   
              </div>
            </div>
          </div>
        </div>

        <div className='row w-100'>
          <div 
            className='col-lg-12 mb-5 scroll scroll-height-step-4 h-475px '
          >
            {divAgrupar && dimensoes.length > 0 && dimensoes.map((dimensao, dimensaoIndex) => {
              return <div key={`accordion-titulo-${dimensao.Id}`} id={`accordion-titulo-${dimensao.Id}`} className='mb-8 w-100'>
                <div className={`card card-custom border border-secondary border-1 shadow-sm titulo-pesquisa-nao-selecionado`}>
                  <div className="card-header bg-pesquisa border-bottom border-secondary border-1 rounded-bottom" id={`card-titulo-header-${dimensao.Id}`}>
                    <div className="card-title">
                      <h3 className='text-white'>{(dimensao.Nome)}</h3>                                
                    </div>
                    <div className="card-toolbar w-md-auto">                                
                      <button type="button" className="btn btn-icon btn-sm btn-accordion btn-light" data-bs-toggle="collapse" data-bs-target={`#card-titulo-collapse-${dimensao.Id}`} aria-expanded="false" aria-controls={`card-titulo-collapse-${dimensao.Id}`} >
                        <i className='fas fa-arrow-down'></i>
                        <i className='fas fa-arrow-up'></i>
                      </button>                   
                    </div>
                  </div>
                  <div id={`card-titulo-collapse-${dimensao.Id}`} className="collapse" aria-labelledby={`card-titulo-header-${dimensao.Id}`} data-bs-parent={`#accordion-titulo-${dimensao.Id}`}>
                    <div className="card-body w-100">
    
                      {(dimensao.Perguntas && dimensao.Perguntas.length > 0) && (
                        <PesquisaPerguntas
                          textos={dimensao.Perguntas}
                        />
                      )}
                    </div>   
                  </div>                         
                </div>
              </div>
            })}
            {divAleatorio && perguntas.length > 0 && (
              <PesquisaPerguntas
                textos={perguntas}
              />
            )}
          </div>
        </div>
      </div>
    </>
  )
}

export {Step2}
