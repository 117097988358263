import {TableHistorico} from './partials/TableHistorico'

const HistoricoDescricao = () => {
  return (
    <>
      <TableHistorico />
    </>
  )
}

export {HistoricoDescricao}
