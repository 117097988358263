import {useEffect, useRef, useState} from 'react'
import {KTSVG} from '../../../../_metronic/helpers'
import {Step1} from './steps/Step1'
import {Step2} from './steps/Step2'
import {Step3} from './steps/Step3'
import {Step4} from './steps/Step4'
import {Step5} from './steps/Step5'
import {StepperComponent} from '../../../../_metronic/assets/ts/components'
import {FormikValues, useFormik, FormikProvider} from 'formik'
import {
  IWizardModel,
  defaultValidationSchemas,
  defaultTema,
  IPesquisaRespostaModel,
  ITemaModel,
} from './helpers/ConfiguracoesIniciaisHelper'
import {useHttpClient} from '../../../modules/services/Bundle'
import {EnumStepsConfigInicialPesquisa, RequestMethod} from '../../../modules/services/core/_enums'
import {useAuth} from '../../../modules/auth'
import {useNavigate} from 'react-router'
import { PesquisaPainel } from './partials/PesquisaPainel'
import $ from 'jquery'
import { ModalAddResp } from './partials/ModalAddResp'
import { ModalArgumentarResp } from './partials/ModalArgumentarResp'
import { ModalAprimorarResp } from './partials/ModalAprimorarResp'
import { ModalExcluirPerguntas } from './partials/ModalExcluirPerguntas'

const initValues: IWizardModel = {
  empresaId: undefined,
  empresaRazaoSocial: '',
  empresaSegmento: undefined,
  empresaNomeSegmento: '',
  QtdFuncionarios: 0,
  PorteEmpresa: 0,
  empresaLogo: undefined,
  tema: [],
  temaBuscar: defaultTema,
  topicosBuscar: 0,
  temaBuscarDimensao: defaultTema,
  dimensoesBuscar: 0,
  numeroPerguntas: 0,
}

const ConfiguracoesIniciais = () => {
  const stepperRef = useRef<HTMLDivElement | null>(null)
  const stepper = useRef<StepperComponent | null>(null)
  const [validationSchemas] = useState(defaultValidationSchemas)
  const [currentSchema, setCurrentSchema] = useState(validationSchemas[0])
  const {auth, saveAuth} = useAuth()
  const httpClient = useHttpClient()
  const [nameStep, setNameStep] = useState('Empresa')
  const [numeroStep, setNumeroStep] = useState(0)
  const [segmentos, setSegmentos] = useState<any[]>([])
  const navigate = useNavigate()
  let [tituloSelecionado, setTituloSelecionado] = useState<number | undefined>()
  let [textoSelecionado, setTextoSelecionado] = useState<number | undefined>()
  let [textoSendoEditado, setTextoSendoEditado] = useState(false)
  let [mostrarPainel, setMostrarPainel] = useState(false)
  const [textoBackup, setTextoBackup] = useState<IPesquisaRespostaModel | undefined>()
  const [abrirModalAddTexto, setAbrirModalAddTexto] = useState<boolean>(false)
  const [abrirModalArgumentarTexto, setAbrirModalArgumentarTexto] = useState<boolean>(false)
  const [abrirModalAprimorarTexto, setAbrirModalAprimorarTexto] = useState<boolean>(false)
  const [abrirModalExcluirPerguntas, setAbrirModalExcluirPerguntas] = useState<boolean>(false)
  const [indexSelecionado, setIndexSelecionado] = useState<number | undefined>(undefined)

  const loadStepper = () => {
    stepper.current = StepperComponent.createInsance(stepperRef.current as HTMLDivElement)
  }

  const resetFieldsToPainel = () => {
    setTituloSelecionado(undefined)
    setTextoSelecionado(undefined)
    setTextoSendoEditado(false)
    setTextoBackup(undefined)
    setAbrirModalAddTexto(false)
    setAbrirModalArgumentarTexto(false)
    setAbrirModalAprimorarTexto(false)
    setAbrirModalExcluirPerguntas(false)
  }

  const prevStep = () => {
    if (!stepper.current) {
      return
    }
    if (stepper.current.currentStepIndex === 1) {
      navigate('/inicio')
      return
    }
    stepper.current.goPrev()
    
    setMostrarPainel((stepper.current.currentStepIndex - 1) === 0 || (stepper.current.currentStepIndex - 1) >= 4 ? false : true)
    
    setNumeroStep(stepper.current.currentStepIndex - 1)
    resetFieldsToPainel()
    setNameStep(EnumStepsConfigInicialPesquisa[stepper.current.currentStepIndex - 1])
  }

  const submitStep = async (values: IWizardModel, actions: FormikValues) => {
    if (!stepper.current) {
      return
    }
    
    setMostrarPainel((stepper.current.currentStepIndex) === 0 || (stepper.current.currentStepIndex) >= 4 ? false : true)
    setNameStep(EnumStepsConfigInicialPesquisa[stepper.current.currentStepIndex])
    setNumeroStep(stepper.current.currentStepIndex)
    resetFieldsToPainel()
    if (stepper.current.currentStepIndex !== stepper.current.totatStepsNumber) {
      let sucesso = true
      //Salvar Step
      if(stepper.current.currentStepIndex == 1){
        const model = {
          Id: values.empresaId ?? 0,
          RazaoSocial: values.empresaRazaoSocial,
          IdSegmento: values.empresaSegmento,
          EmpresaLogo: values.empresaLogo,
          NomeSegmento: values.empresaNomeSegmento ?? "",
          PorteEmpresa: values.PorteEmpresa ?? 0,
          QtdFuncionarios: values.QtdFuncionarios ?? 0
        }

        if(model.NomeSegmento){
          const response = await httpClient.request({
            method: RequestMethod.GET,
            endpoint: '/Gerenciamento/Segmento/ExisteSegmento',
            queryObject:{
              nomeSegmento: values.empresaNomeSegmento
            }
          })

          if(response.success){
            const resp = await httpClient.request({
              method: RequestMethod.POST,
              endpoint: '/Pesquisa/ConfiguracoesIniciais/AdicionarEditarEmpresa',
              data: model,
            })
  
            if (resp.success && resp.payload) {
              const empresa = resp.payload
              actions.setFieldValue('empresaId', empresa.Id)
              sucesso = true
            }
          }
        } else {
          const response = await httpClient.request({
            method: RequestMethod.POST,
            endpoint: '/Pesquisa/ConfiguracoesIniciais/AdicionarEditarEmpresa',
            data: model,
          })

          if (response.success && response.payload) {
            const empresa = response.payload
            actions.setFieldValue('empresaId', empresa.Id)
            sucesso = true
          }
        }
      } else {
        sucesso = true
      }

      if (sucesso) stepper.current.goNext()
      return
    }
    if (stepper.current.currentStepIndex === stepper.current.totatStepsNumber && auth) {
      try {
        const response = await httpClient.request({
          method: RequestMethod.GET,
          endpoint: '/Pesquisa/ConfiguracoesIniciais/FinalizarConfiguracoesIniciais',
          ifSuccessRedirectTo: '/pesquisa-ia/dashboard',
        })
      } catch (e) {
      }
    }
  }

  const formik = useFormik({
    initialValues: {...initValues},
    validationSchema: currentSchema,
    onSubmit: submitStep,
  })

  const autoSizeTextArea = () => {
    $('.textarea-texto-pesquisa').each(function (i, element) {
      let target = element as HTMLTextAreaElement
      target.style.height = '90px'

      if (target.scrollHeight && target.scrollHeight > 90)
        target.style.height = target.scrollHeight + 'px'
    })
  }

  useEffect(() => {
    autoSizeTextArea()
  }, [formik])

  const fetchConfiguracoesIniciais = async () => {
      
    await httpClient
    .request({
      method: RequestMethod.GET,
      endpoint: '/Gerenciamento/Segmento/ObterParaSelect',
    })
    .then((response) => {
      if (response.success && response.payload) setSegmentos(response.payload)
    })

    const response = await httpClient.request({
      method: RequestMethod.GET,
      endpoint: '/Pesquisa/ConfiguracoesIniciais/ObterConfiguracoesIniciais',
    })

    if (response.success && response.payload) {
      let ci = response.payload

      let empresa = ci.Empresa

      let newValues = {...initValues}

      newValues.empresaId = empresa.Id
      newValues.empresaRazaoSocial = empresa.RazaoSocial
      newValues.empresaSegmento = empresa.IdSegmento
      newValues.empresaNomeSegmento = empresa.NomeSegmento
      newValues.QtdFuncionarios = empresa.QtdFuncionarios
      newValues.PorteEmpresa = empresa.PorteEmpresa

      let empresaLogo = empresa.EmpresaLogo

      if (empresaLogo) {
        newValues.empresaLogo = {
          Nome: empresaLogo.Nome,
          Extensao: empresaLogo.Extensao,
          Tamanho: empresaLogo.Tamanho,
          Tipo: empresaLogo.Tipo,
          Base64: empresaLogo.Base64,
        }
      }

      let tema:ITemaModel[] = ci.Tema
      if (tema.length) {
        newValues.tema = tema
      }

      formik.setValues(newValues)
    }
  }

  useEffect(() => {
    fetchConfiguracoesIniciais()
  }, [])

  useEffect(() => {
    if (!stepperRef.current) {
      return
    }
    loadStepper()
  }, [stepperRef])

  return (
    <>
      <ModalAddResp
        abrirModal={abrirModalAddTexto}
        dimensaoSelecionada={indexSelecionado} 
        tipoResposta={numeroStep}
        setAbrirModal={setAbrirModalAddTexto}
        formik={formik}
        tituloSelecionado={tituloSelecionado}
        setTituloSelecionado={setTituloSelecionado}
        textoSelecionado={textoSelecionado}
        setTextoSelecionado={setTextoSelecionado}
        textoSendoEditado={textoSendoEditado}
        setTextoSendoEditado={setTextoSendoEditado}
      />

      <ModalArgumentarResp
        abrirModal={abrirModalArgumentarTexto}
        dimensaoSelecionada={indexSelecionado} 
        tipoResposta={numeroStep}
        setAbrirModal={setAbrirModalArgumentarTexto}
        formik={formik}
        tituloSelecionado={tituloSelecionado}
        setTituloSelecionado={setTituloSelecionado}
        respostaSelecionada={textoSelecionado}
        setRespostaSelecionada={setTextoSelecionado}
        respostaSendoEditada={textoSendoEditado}
        setRespostaSendoEditada={setTextoSendoEditado}
      />

      <ModalAprimorarResp
        abrirModal={abrirModalAprimorarTexto}
        dimensaoSelecionada={indexSelecionado} 
        tipoResposta={numeroStep}
        setAbrirModal={setAbrirModalAprimorarTexto}
        formik={formik}
        tituloSelecionado={tituloSelecionado}
        setTituloSelecionado={setTituloSelecionado}
        respostaSelecionada={textoSelecionado}
        setRespostaSelecionada={setTextoSelecionado}
        respostaSendoEditada={textoSendoEditado}
        setRespostaSendoEditada={setTextoSendoEditado}
      />

      <ModalExcluirPerguntas
        abrirModal={abrirModalExcluirPerguntas}
        setAbrirModal={setAbrirModalExcluirPerguntas}
        formik={formik}
      />
    
      <PesquisaPainel 
        formik={formik}
        textoBackup={textoBackup}
        dimensaoSelecionada={indexSelecionado} 
        setTextostaBackup={setTextoBackup}
        mostrarPainel={mostrarPainel}
        numeroStep={numeroStep}
        setNumeroStep={setNumeroStep}
        tituloSelecionado={tituloSelecionado}
        setTituloSelecionado={setTituloSelecionado}
        textoSelecionado={textoSelecionado}
        setTextoSelecionado={setTextoSelecionado}
        textoSendoEditado={textoSendoEditado}
        setTextoSendoEditado={setTextoSendoEditado}
        abrirModalAddTexto={abrirModalAddTexto}
        setAbrirModalAddTexto={setAbrirModalAddTexto}
        abrirModalArgumentarTexto={abrirModalArgumentarTexto}
        setAbrirModalArgumentarTexto={setAbrirModalArgumentarTexto}
        abrirModalAprimorarTexto={abrirModalAprimorarTexto}
        setAbrirModalAprimorarTexto={setAbrirModalAprimorarTexto}
        abrirModalExcluirPerguntas={abrirModalExcluirPerguntas}
        setAbrirModalExcluirPerguntas={setAbrirModalExcluirPerguntas}
      />

      <ol className='breadcrumb breadcrumb-line text-muted fs-6 mt-0 fw-semibold mb-10'>
        <li className='breadcrumb-item h2 text-gray-600'>Pesquisa</li>
        <li className='breadcrumb-item h2 text-gray-600'>Configurações Iniciais</li>
        <li className='breadcrumb-item h2 text-dark'>{nameStep}</li>
      </ol>

      <div
        ref={stepperRef}
        className='stepper stepper-pills'
        id='kt_create_account_stepper'
      >
        {/* begin::Aside*/}
        <div className='card d-flex justify-content-center mb-5 w-100 me-5'>
          {/* begin::Wrapper*/}
          <div className='card-body'>
            {/* begin::Nav*/}
            <div className='stepper-nav  mat-horizontal-content-container flex-center flex-wrap'>
              {/* begin::Step 1*/}
              <div className='stepper-item mx-8 my-4 current' data-kt-stepper-element='nav'>
                {/* begin::Wrapper*/}
                <div className='stepper-wrapper d-flex align-items-center flex-column'>
                  {/* begin::Icon*/}
                  <div className='stepper-icon step-counter mx-0 w-40px h-40px'>
                    <i className='stepper-check fas fa-check'></i>
                    <span className='stepper-number color-white-step-number'>1</span>
                  </div>
                  {/* end::Icon*/}

                  {/* begin::Label*/}
                  <div className='stepper-label '>
                    <h3 className='stepper-title'>Empresa</h3>
                  </div>
                  {/* end::Label*/}
                </div>
                {/* end::Wrapper*/}

                {/* begin::Line*/}
                <div className='stepper-line h-40px'></div>
                {/* end::Line*/}
              </div>
              {/* end::Step 1*/}

              {/* begin::Step 2*/}
              <div className='stepper-item mx-8 my-4 ' data-kt-stepper-element='nav'>
                {/* begin::Wrapper*/}
                <div className='stepper-wrapper d-flex align-items-center flex-column'>
                  {/* begin::Icon*/}
                  <div className='stepper-icon step-counter mx-0 w-40px h-40px'>
                    <i className='stepper-check fas fa-check'></i>
                    <span className='stepper-number color-white-step-number'>2</span>
                  </div>
                  {/* end::Icon*/}

                  {/* begin::Label*/}
                  <div className='stepper-label'>
                    <h3 className='stepper-title'>Tema</h3>
                  </div>
                  {/* end::Label*/}
                </div>
                {/* end::Wrapper*/}

                {/* begin::Line*/}
                <div className='stepper-line h-40px'></div>
                {/* end::Line*/}
              </div>
              {/* end::Step 2*/}

              {/* begin::Step 3*/}
              <div className='stepper-item mx-8 my-4 ' data-kt-stepper-element='nav'>
                {/* begin::Wrapper*/}
                <div className='stepper-wrapper d-flex align-items-center flex-column'>
                  {/* begin::Icon*/}
                  <div className='stepper-icon step-counter mx-0 w-40px h-40px'>
                    <i className='stepper-check fas fa-check'></i>
                    <span className='stepper-number color-white-step-number'>3</span>
                  </div>
                  {/* end::Icon*/}

                  {/* begin::Label*/}
                  <div className='stepper-label'>
                    <h3 className='stepper-title'>Dimensões</h3>
                  </div>
                  {/* end::Label*/}
                </div>
                {/* end::Wrapper*/}

                {/* begin::Line*/}
                <div className='stepper-line h-40px'></div>
                {/* end::Line*/}
              </div>
              {/* end::Step 3*/}

              {/* begin::Step 4*/}
              <div className='stepper-item mx-8 my-4' data-kt-stepper-element='nav'>
                {/* begin::Wrapper*/}
                <div className='stepper-wrapper d-flex align-items-center flex-column'>
                  {/* begin::Icon*/}
                  <div className='stepper-icon step-counter mx-0 w-40px h-40px'>
                    <i className='stepper-check fas fa-check'></i>
                    <span className='stepper-number color-white-step-number'>4</span>
                  </div>
                  {/* end::Icon*/}

                  {/* begin::Label*/}
                  <div className='stepper-label'>
                    <h3 className='stepper-title'>Perguntas</h3>
                  </div>
                  {/* end::Label*/}
                </div>
                {/* end::Wrapper*/}

                {/* begin::Line*/}
                <div className='stepper-line h-40px'></div>
                {/* end::Line*/}
              </div>
              {/* end::Step 4*/}

              {/* begin::Step 5*/}
              <div className='stepper-item mx-8 my-4' data-kt-stepper-element='nav'>
                {/* begin::Wrapper*/}
                <div className='stepper-wrapper d-flex align-items-center flex-column'>
                  {/* begin::Icon*/}
                  <div className='stepper-icon step-counter mx-0 w-40px h-40px'>
                    <i className='stepper-check fas fa-check'></i>
                    <span className='stepper-number color-white-step-number'>5</span>
                  </div>
                  {/* end::Icon*/}

                  {/* begin::Label*/}
                  <div className='stepper-label'>
                    <h3 className='stepper-title'>Revisão</h3>
                  </div>
                  {/* end::Label*/}
                </div>
                {/* end::Wrapper*/}
              </div>
              {/* end::Step 5*/}
            </div>
            {/* end::Nav*/}
          </div>
          {/* end::Wrapper*/}
        </div>
        {/* begin::Aside*/}

        <div className='d-flex flex-row-fluid flex-center bg-body rounded'>
          <FormikProvider value={formik}>
            <form
              className={`py-20 w-100 ${
                (stepper.current?.currentStepIndex ?? 0) === 1 ? 'w-xl-700px' : ''
              } px-9`}
              noValidate
              onSubmit={formik.handleSubmit}
            >
              <div className='current' data-kt-stepper-element='content'>
                <Step1 setSegmentos={setSegmentos} segmentos={segmentos} formik={formik} />
              </div>

              <div data-kt-stepper-element='content'>
                <Step2 
                  formik={formik}
                  indexSelecionado={indexSelecionado}
                  setIndexSelecionado={setIndexSelecionado}
                  numeroStep={numeroStep} 
                  tituloSelecionado={tituloSelecionado}
                  setTituloSelecionado={setTituloSelecionado}
                  textoSelecionado={textoSelecionado}
                  setTextoSelecionado={setTextoSelecionado}
                  textoSendoEditado={textoSendoEditado}
                  setTextoSendoEditado={setTextoSendoEditado}
                  textoBackup={textoBackup}
                  setTextoBackup={setTextoBackup}
                />
              </div>

              <div data-kt-stepper-element='content'>
                <Step3 
                  formik={formik}
                  indexSelecionado={indexSelecionado}
                  setIndexSelecionado={setIndexSelecionado}
                  numeroStep={numeroStep} 
                  tituloSelecionado={tituloSelecionado}
                  setTituloSelecionado={setTituloSelecionado}
                  textoSelecionado={textoSelecionado}
                  setTextoSelecionado={setTextoSelecionado}
                  textoSendoEditado={textoSendoEditado}
                  setTextoSendoEditado={setTextoSendoEditado}
                  textoBackup={textoBackup}
                  setTextoBackup={setTextoBackup}
                />
              </div>

              <div data-kt-stepper-element='content'>
                <Step4 
                  formik={formik}
                  indexSelecionado={indexSelecionado}
                  setIndexSelecionado={setIndexSelecionado}
                  numeroStep={numeroStep} 
                  tituloSelecionado={tituloSelecionado}
                  setTituloSelecionado={setTituloSelecionado}
                  textoSelecionado={textoSelecionado}
                  setTextoSelecionado={setTextoSelecionado}
                  textoSendoEditado={textoSendoEditado}
                  setTextoSendoEditado={setTextoSendoEditado}
                  textoBackup={textoBackup}
                  setTextoBackup={setTextoBackup}
                />
              </div>

              <div data-kt-stepper-element='content'>
                <Step5 formik={formik} />
              </div>

              <div className='d-flex flex-stack pt-10'>
                <div className='mr-2'>
                  <button
                    onClick={prevStep}
                    type='button'
                    className='btn btn-lg btn-pesquisa me-3'
                    // data-kt-stepper-action='previous'
                  >
                    <KTSVG
                      path='/media/icons/duotune/arrows/arr063.svg'
                      className='svg-icon-4 me-1 text-white'
                    />
                    Voltar
                  </button>
                </div>

                <div>
                  <button
                    type='button'
                    onClick={() => formik.handleSubmit()}
                    className='btn btn-lg btn-pesquisa me-3'
                  >
                    <span className='indicator-label'>
                      {(stepper.current?.currentStepIndex ?? 0) ===
                      (stepper.current?.totatStepsNumber ?? 5)
                        ? 'Finalizar'
                        : 'Continuar'}
                      <KTSVG
                        path='/media/icons/duotune/arrows/arr064.svg'
                        className='svg-icon-3 ms-2 text-white me-0'
                      />
                    </span>
                  </button>
                </div>
              </div>
            </form>
          </FormikProvider>
        </div>
      </div>
    </>
  )
}

export {ConfiguracoesIniciais}
