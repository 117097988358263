import { Outlet, Route, Routes } from "react-router-dom";
import { ConfiguracoesIniciais } from "./components/ConfiguracoesIniciais";
import InicioPesquisa from "./components/Inicio";
import { DashboardPesquisa } from "./components/Dashboard";
import { useAuth } from "../../modules/auth";
import { EnumServicoTipoPlano } from "../../modules/services/core/_enums";
import { Pesquisa } from "./components/Pesquisa";
import { PesquisaEmAndamento } from "./components/PesquisaEmAndamento";
import { AnaliseResultado } from "./components/AnaliseResultado";
import { ResultadosGlobais } from "./components/ResultadosGlobais";
import { ResultadosPorDimensao } from "./components/ResultadosPorDimensao";
import { ResultadosPorQuestao } from "./components/ResultadosPorQuestao";
import { ResultadosGlobaisPorPergunta } from "./components/ResultadosGlobaisPorPergunta";
import { Temas } from "./components/Temas";
import { CrudTema } from "./components/CrudTema";
import { ResultadosParticipantesPorCategoria } from "./components/ResultadosParticipantesPorCategoria";
import { ExportarResultados } from "./components/ExportarResultados";
import { Escalas } from "./components/Escalas";
import { CrudEscala } from "./components/CrudEscala";
import { ResultadoProprio } from "./components/ResultadoProprio";
import { ResultadoLiderados } from "./components/ResultadoLiderados";

export default function PesquisaPage(){
    const {auth} = useAuth()
    const servico = auth?.servico
    return(
        <Routes>
            <Route element={<Outlet/>}>
                <Route
                    path="inicio"
                    element={
                        <InicioPesquisa/>
                    }
                />
                {(servico?.tipoPlano === EnumServicoTipoPlano.Try || (auth?.roles.find(x => x === 'Master') !== undefined) || (auth?.roles.find(x => x === 'Administrador') !== undefined) || (auth?.permissoes.find(x => x === '7576890D4CA4472D3DD78EAD3FE317F0') !== undefined)) && (         
                    <Route
                        path="dashboard"
                        element={
                            <DashboardPesquisa/>
                        }
                    />
                )}
                {(servico?.tipoPlano === EnumServicoTipoPlano.Try || (auth?.roles.find(x => x === 'Master') !== undefined) || (auth?.roles.find(x => x === 'Administrador') !== undefined) || (auth?.permissoes.find(x => x === '52CA6BCD3B0C506D925D7A05F5CE9376') !== undefined)) && (         
                    <Route
                        path="configuracoes-iniciais"
                        element={
                            <ConfiguracoesIniciais/>
                        }
                    />
                )}
                {(servico?.tipoPlano === EnumServicoTipoPlano.Try || (auth?.roles.find(x => x === 'Master') !== undefined) || (auth?.roles.find(x => x === 'Administrador') !== undefined) || (auth?.permissoes.find(x => x === 'B9F9D6DD0BAB131A1D4D215F03D46378') !== undefined)) && (         
                    <Route
                        path="dashboard/pesquisa"
                        element={
                            <Pesquisa/>
                        }
                    />
                )}
                <Route
                    path="pesquisa-em-andamento"
                    element={
                        <PesquisaEmAndamento/>
                    }
                />
                {(servico?.tipoPlano === EnumServicoTipoPlano.Buy && ((auth?.roles.find(x => x === 'Master') !== undefined) || (auth?.roles.find(x => x === 'Administrador') !== undefined) || (auth?.permissoes.find(x => x === '72FF3D60CA5D0F6E20EAAC12A9B91BB4')) !== undefined)) && (         
                    <>
                        <Route
                            path="temas"
                            element={
                                <Temas/>
                            }
                        />
                        <Route
                            path="temas/crud-tema"
                            element={
                                <CrudTema/>
                            }
                        />
                    </>
                )}
                {(servico?.tipoPlano === EnumServicoTipoPlano.Buy && ((auth?.roles.find(x => x === 'Master') !== undefined) || (auth?.roles.find(x => x === 'Administrador') !== undefined) || (auth?.permissoes.find(x => x === '72FF3D60CA5D0F6E20EAAC12A9B91BB4')) !== undefined)) && (         
                    <>
                        <Route
                            path="escalas"
                            element={
                                <Escalas/>
                            }
                        />
                        <Route
                            path="escalas/crud-escala"
                            element={
                                <CrudEscala/>
                            }
                        />
                    </>
                )}
                {(servico?.tipoPlano === EnumServicoTipoPlano.Try || (auth?.roles.find(x => x === 'Master') !== undefined) || (auth?.roles.find(x => x === 'Administrador') !== undefined) || (auth?.permissoes.find(x => x === 'FE0760BF2063B496A862291596BC552C') !== undefined)) && (         
                    <Route
                        path="analise-resultado/por-pesquisa"
                        element={
                            <AnaliseResultado/>
                        }
                    />
                )}
                {(servico?.tipoPlano === EnumServicoTipoPlano.Try || (auth?.roles.find(x => x === 'Master') !== undefined) || (auth?.roles.find(x => x === 'Administrador') !== undefined) || (auth?.permissoes.find(x => x === '45BA682962BDF1BD095E21672BA621DE') !== undefined)) && (         
                    <Route
                        path="analise-resultado/proprio"
                        element={
                            <ResultadoProprio/>
                        }
                    />
                )}
                {(servico?.tipoPlano === EnumServicoTipoPlano.Try || (auth?.roles.find(x => x === 'Master') !== undefined) || (auth?.roles.find(x => x === 'Administrador') !== undefined) || (auth?.permissoes.find(x => x === 'C75659958306F0D526608C9C79D72B9C') !== undefined)) && (         
                    <Route
                        path="analise-resultado/liderados"
                        element={
                            <ResultadoLiderados/>
                        }
                    />
                )}
                {(servico?.tipoPlano === EnumServicoTipoPlano.Buy && (auth?.roles.find(x => x === 'Master') !== undefined) || (auth?.roles.find(x => x === 'Administrador') !== undefined) || (auth?.permissoes.find(x => x === 'A8B1E40269C56AD04420505CE124AF25') !== undefined)) && (         
                    <Route
                        path="resultados-globais"
                        element={
                            <ResultadosGlobais/>
                        }
                    />
                )} 
                {(servico?.tipoPlano === EnumServicoTipoPlano.Buy && (auth?.roles.find(x => x === 'Master') !== undefined) || (auth?.roles.find(x => x === 'Administrador') !== undefined) || (auth?.permissoes.find(x => x === '816E0E82917C685F73BB366C2ED76B8C') !== undefined)) && (         
                    <Route
                        path="resultados-por-dimensao"
                        element={
                            <ResultadosPorDimensao/>
                        }
                    />
                )} 
                {(servico?.tipoPlano === EnumServicoTipoPlano.Buy && (auth?.roles.find(x => x === 'Master') !== undefined) || (auth?.roles.find(x => x === 'Administrador') !== undefined) || (auth?.permissoes.find(x => x === '10077E63ED95C4370BEC30B9D5FA49B9') !== undefined)) && (         
                    <Route
                        path="resultados-por-questao"
                        element={
                            <ResultadosPorQuestao/>
                        }
                    />
                )} 
                {(servico?.tipoPlano === EnumServicoTipoPlano.Buy && (auth?.roles.find(x => x === 'Master') !== undefined) || (auth?.roles.find(x => x === 'Administrador') !== undefined) || (auth?.permissoes.find(x => x === '028CFBF5E2617367DB36F7E4DC1E2EDF') !== undefined)) && (         
                    <Route
                        path="participantes-por-categoria"
                        element={
                            <ResultadosParticipantesPorCategoria/>
                        }
                    />
                )} 
                {(servico?.tipoPlano === EnumServicoTipoPlano.Buy && (auth?.roles.find(x => x === 'Master') !== undefined) || (auth?.roles.find(x => x === 'Administrador') !== undefined) || (auth?.permissoes.find(x => x === 'D509A704DE38592C4A88C4318F6D3C70') !== undefined)) && (         
                    <Route
                        path="globais-por-pergunta"
                        element={
                            <ResultadosGlobaisPorPergunta/>
                        }
                    />
                )} 
                {/* {(servico?.tipoPlano === EnumServicoTipoPlano.Try || (auth?.roles.find(x => x === 'Master') !== undefined) || (auth?.roles.find(x => x === 'Administrador') !== undefined) || (auth?.permissoes.find(x => x === '727FBC53FEBD31D2F8333A06FB8BF605') !== undefined)) && (         
                    <Route
                        path="exportar-resultados"
                        element={
                            <ExportarResultados/>
                        }
                    />
                )} */}
                <Route
                    path="exportar-resultados"
                    element={
                        <ExportarResultados/>
                    }
                />
            </Route>
        </Routes>
    )
}