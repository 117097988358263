/* eslint-disable react-hooks/exhaustive-deps */
import {useLocation, useNavigate} from 'react-router-dom'
import {useAlert, useHttpClient} from '../../../modules/services/Bundle'
import {FormikProvider, useFormik} from 'formik'
import {EnumModulo, EnumPromptTipo, RequestMethod} from '../../../modules/services/core/_enums'
import {useEffect} from 'react'
import {initValues} from './helpers/PromptHelper'

type Data = {
  IdPrompt: number
}

const EditarPrompt = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const httpClient = useHttpClient()
  const alert = useAlert()
  const initialValues = {...initValues}

  const idPrompt = location.state as Data

  initialValues.Id = idPrompt.IdPrompt
  const formik = useFormik({
    initialValues: {...initialValues},
    onSubmit: async (values, actions) => {
      alert.createDialog({
        html: `Realmente deseja editar esse prompt?`,
        confirmAction: async () => {
          await httpClient.request({
            method: RequestMethod.POST,
            endpoint: '/Administracao/Prompt/Editar',
            data: values,
          })
        },
      })
    },
  })

  const requestDados = async () => {
    const response = await httpClient.request({
      method: RequestMethod.GET,
      endpoint: '/Administracao/Prompt/Obter',
      queryObject: {
        id: idPrompt.IdPrompt,
      },
    })
    
    if (response.success && response.payload) formik.setValues(response.payload)
  }

  useEffect(() => {
    if (location.state && idPrompt.IdPrompt) {
      requestDados()
    }
  }, [])

  return (
    <div className='card mb-xl-10'>
      <div className='card-header'>
        <div className='card-title m-0'>
          <h3 className='fw-bolder m-0'>Editar prompt</h3>
        </div>
      </div>

      <div className='card-body'>
        <FormikProvider value={formik}>
          <div className='w-100'>
            <form noValidate>
              <div className='row d-flex'>                
                <div className='col'>
                  <label className='form-label'>Tipo:</label>
                  <input
                    type='text'
                    disabled
                    readOnly
                    className='form-control'
                    value={formik.values.NomeTipo}
                  />
                </div>
                <div className='col'>
                  <label className='form-label'>Módulo:</label>
                  <input
                    type='text'
                    disabled
                    readOnly
                    className='form-control'
                    value={formik.values.NomeModulo}
                  />
                </div>
                <div className='col'>
                  <label className='form-label'>Tipo de Campo:</label>
                  <input
                    type='text'
                    disabled
                    readOnly
                    className='form-control'
                    value={formik.values.NomeTipoCampo}
                    placeholder='Tipo de Campo'
                  />
                </div>
              </div>
              <div className='row mt-5'>
                <div className='col'>
                  <label className='form-label'>Mensagem:</label>
                  <textarea
                    className='form-control'
                    onChange={(e) => formik.setFieldValue('Mensagem', e.target.value)}
                    value={formik.values.Mensagem}
                    rows={10}
                  ></textarea>
                  <label className='mt-2'>Legenda:</label>
                  <ul>
                    {(formik.values.Tipo !== EnumPromptTipo.ObterAcoesExistentes &&
                      formik.values.IdModulo === EnumModulo.DescricaoDeCargo) && (
                      <li>{'{{CARGO}}'} - Nome do cargo</li>
                    )}
                    {(formik.values.Tipo === EnumPromptTipo.AdicionarResposta ||
                      formik.values.Tipo === EnumPromptTipo.AdicionarTopico ||
                      formik.values.Tipo === EnumPromptTipo.AdicionarDimensao ||
                      formik.values.Tipo === EnumPromptTipo.AdicionarDimensaoPergunta) && (
                      <li>{'{{IDEIA}}'} - Ideia central</li>
                    )}
                    {(formik.values.Tipo === EnumPromptTipo.AdicionarResposta ||
                      formik.values.Tipo === EnumPromptTipo.GerarDescricoes) && (
                      <>
                        <li>{'{{NIVEL}}'} - Nome do nível hierárquico</li>
                        <li>{'{{AREA}}'} - Nome da área</li>
                        <li>{'{{SEGMENTO}}'} - Nome do segmento de mercado</li>
                        <li>{'{{PORTE}}'} - Porte da empresa</li>
                        <li>{'{{QTDFUNCIONARIOS}}'} - Quantidade de funcionários da empresa</li>
                      </>
                    )}
                    {(formik.values.Tipo === EnumPromptTipo.AdicionarArgumento ||
                      formik.values.Tipo === EnumPromptTipo.RemoverArgumento ||
                      formik.values.Tipo === EnumPromptTipo.AprimorarRespostaAdicionando ||
                      formik.values.Tipo === EnumPromptTipo.AprimorarRespostaRemovendo ||
                      formik.values.Tipo === EnumPromptTipo.ObterAcoesExistentes ||
                      formik.values.Tipo === EnumPromptTipo.ObterAcoesSugeridas) && (
                        <>
                          <li>{'{{TEXTO}}'} - Texto da resposta</li>
                          {(formik.values.IdModulo === EnumModulo.Pesquisa) && (
                            <li>{'{{DESCRICAO}}'} - Descrição do tipo de resposta: tópico, dimensão ou pergunta</li>
                          )}
                        </>
                    )}
                    {(formik.values.Tipo === EnumPromptTipo.AdicionarArgumento ||
                      formik.values.Tipo === EnumPromptTipo.RemoverArgumento) && (
                      <li>{'{{ARGUMENTO}}'} - Argumento</li>
                    )}
                    {(formik.values.Tipo === EnumPromptTipo.AprimorarRespostaAdicionando ||
                      formik.values.Tipo === EnumPromptTipo.AprimorarRespostaRemovendo) && (
                      <li>{'{{ACOES}}'} - Ações, cada uma separada por ','</li>
                    )}
                    {(formik.values.IdModulo === EnumModulo.Pesquisa) && (
                      <li>{'{{TEMA}}'} - Nome do tema</li>
                    )}
                    {(formik.values.Tipo === EnumPromptTipo.GerarTopicos) && (
                      <li>{'{{QTDTOPICOS}}'} - Quantidade de tópicos</li>
                    )}
                    {(formik.values.Tipo === EnumPromptTipo.GerarDimensoes) && (
                      <li>{'{{QTDDIMENSOES}}'} - Quantidade de dimensões</li>
                    )}
                    {(formik.values.Tipo === EnumPromptTipo.GerarDimensoesPerguntas) && (
                      <>
                        <li>{'{{QTDPERGUNTAS}}'} - Quantidade de perguntas</li>
                        <li>{'{{DIMENSAO}}'} - Nome da dimensão</li>
                      </>
                    )}
                  </ul>
                </div>                
              </div>
            </form>
          </div>
        </FormikProvider>
      </div>

      <div className='card-footer py-4 d-flex justify-content-end'>
        <button
          type='button'
          className='btn btn-lg btn-secondary me-5'
          onClick={() => navigate(-1)}
        >
          Voltar
        </button>
        <button
          type='button'
          onClick={() => formik.handleSubmit()}
          className='btn btn-lg btn-primary'
        >
          Salvar
        </button>
      </div>
    </div>
  )
}

export {EditarPrompt}
