import * as Yup from 'yup'

export interface IEscalaDescricaoModel{
    Inicio?: number
    Fim?: number
    Descricao?: string
    Cor: string
}

interface IEscalaModel {
    Id?: number
    Nome?: string
    InicioEscala?: number
    FimEscala?: number
    EscalaDescricao?: IEscalaDescricaoModel[]
}

const initialValuesDescricao: IEscalaDescricaoModel = {
    Inicio: 0,
    Fim: 0,
    Descricao: '',
    Cor: ''
}

const initialValues: IEscalaModel = {
  Id: 0,        
  Nome: '',
  InicioEscala: 0,
  FimEscala: 0,
  EscalaDescricao: [initialValuesDescricao]
}

const defaultValidationSchemas = [
  Yup.object({
    Nome: Yup.string().required('O nome da escala é obrigatório'),
    EscalaDescricao: Yup.array().of(
      Yup.object().shape({
        Inicio: Yup.number().required('O início do intervalo é obrigatório!'),
        Fim: Yup.number()
          .required('O fim do intervalo é obrigatório!')
          .test(
            'validacaoFim',
            'O fim do intervalo deve ser igual ou maior que o início!',
            function (value) {
              const inicio = this.parent.Inicio; // Obtém o valor de Inicio do objeto pai
              return value ? value >= inicio : false;
            }
          ),
        Descricao: Yup.string().required('A descrição é obrigatória!'),
        Cor: Yup.string().required('A cor é obrigatória!'),
      })
    ).min(1, 'É obrigatório ter no mínimo uma descrição'),
  }),
];

  


export { defaultValidationSchemas, initialValues, initialValuesDescricao }