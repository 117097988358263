/* eslint-disable react-hooks/exhaustive-deps */
import { ErrorMessage, Field, FormikProvider, useFormik } from 'formik'
import { useEffect, useState } from 'react'
import { KTSVG } from '../../../../../_metronic/helpers'
import { useAlert, useHttpClient } from '../../../../modules/services/Bundle'
import { RequestMethod } from '../../../../modules/services/core/_enums'
import { defaultCargo, defaultValidationSchemas, moduloDescricaoValidationSchema } from '../helpers/ModalCargoHelper'
import { useAuth } from '../../../../modules/auth'
import { ISelectModel } from '../../../../modules/services/core/_models'

type Props = {
    isConsultoria: boolean
    empresasCliente: ISelectModel[]
    descricaoTipo: string
    abrirModal: boolean
    setAbrirModal: Function
    obterDadosTabela: Function
    visualizar: boolean
    currentCargo: Data | undefined
}

interface Data {
    Id: number;
    IdEmpresa: number;
    IdNivelHierarquico: number;
    IdArea: number;
    Nome: string;
    NomeArea: string;
    NomeNivelHierarquico: string;
    Descrito: boolean;
    IdModelo: number | undefined;
  }

interface ModeloFormulario {
    Id: number;
    Nome: string;
}

const ModalCargo = ({isConsultoria, empresasCliente, descricaoTipo, setAbrirModal, obterDadosTabela, visualizar, currentCargo}: Props) => {
    
    const httpClient = useHttpClient()
    const alert = useAlert()
    const {auth} = useAuth()
    const servico = auth?.servico
    const [modelos, setModelos] = useState([] as ModeloFormulario[])
    const [areas, setAreas] = useState<any[]>([])
    const [niveisHierarquicos, setNiveisHierarquicos] = useState<any[]>([])
    const moduloDescricaoConfig = servico?.moduloDescricaoConfig
    const [idEmpresaClienteSelecionada, setIdEmpresaClienteSelecionada] = useState<number>(0)
    
    const formik = useFormik({
        initialValues: defaultCargo,
        validationSchema: servico?.moduloDescricaoConfig.ativo ? moduloDescricaoValidationSchema :  defaultValidationSchemas,
        onSubmit: async (values, actions) => {

            alert.createDialog({
                html: `Realmente deseja ${currentCargo ? 'editar' : 'adicionar'} este cargo?`,
                confirmAction: async () => {      
                    
                    if(moduloDescricaoConfig?.ativo) {
                        setAbrirModal(false)
                    }
                    
                    const response = await httpClient.request({
                        method: currentCargo ? RequestMethod.PUT : RequestMethod.POST, 
                        endpoint: `/Gerenciamento/Cargo/${currentCargo ? "Editar" : "Adicionar"}`,
                        queryObject: {
                            isConsultoria: isConsultoria,
                            idEmpresa: idEmpresaClienteSelecionada
                        },
                        data:{
                            Id: currentCargo ? currentCargo.Id : 0,
                            Nome: values.nome,
                            IdNivelHierarquico: values.nivel,
                            IdArea: values.area,
                            IdModelo: servico?.moduloDescricaoConfig.ativo ? values.modelo : 0
                        },
                        ifSuccessRedirectTo: '/descricao/processos',
                    })

                    if (response.success) {
                        setAbrirModal(false)
                        obterDadosTabela()
                    }
                    else{                        
                        if(moduloDescricaoConfig?.ativo) {
                            setAbrirModal(true)
                        }
                    }

                }
            })
    
        },
    })    

    const loadModelos = async () => {        
        const response = await httpClient.request({
            method: RequestMethod.GET,
            endpoint: '/Gerenciamento/Modelo/Obter',
            queryObject: {
                isConsultoria: isConsultoria,
                idEmpresa: idEmpresaClienteSelecionada
            },
        })

        if(response.success && response.payload){
            setModelos(response.payload)
        }
    }

    const obterDados = async () => {
        if(!currentCargo) formik.resetForm()
        setAreas([])
        setNiveisHierarquicos([])
        setModelos([])
        const response = await httpClient.request({
          method: RequestMethod.GET,
          endpoint: '/Gerenciamento/Cargo/ObterDadosSelect',
          queryObject: {
            isConsultoria: isConsultoria,
            idEmpresa: idEmpresaClienteSelecionada
          },
        })
    
        if (response.success && response.payload) {
          const dados = response.payload
          if (dados.Areas && dados.Areas.length > 0) setAreas(dados.Areas)
          if (dados.NiveisHierarquicos && dados.NiveisHierarquicos.length > 0) setNiveisHierarquicos(dados.NiveisHierarquicos)
        }

        if(auth?.servico?.moduloDescricaoConfig.ativo) await loadModelos()
    }

    useEffect(() => {        
        if(currentCargo){
            formik.setValues({
                nome: currentCargo.Nome,
                nivel: currentCargo.IdNivelHierarquico,
                area: currentCargo.IdArea,
                modelo: currentCargo.IdModelo
            })
            if(isConsultoria) setIdEmpresaClienteSelecionada(currentCargo.IdEmpresa)   
        }
    }, [currentCargo])

    useEffect(() => {
        obterDados()
    },[idEmpresaClienteSelecionada])

    return (
    <div className={`modal show fade`} tabIndex={-1} id="kt_modal_1" style={{display: "block"}}>
        <div className="modal-dialog modal-dialog-centered modal-xl">
            <div className="modal-content">
                <div className="modal-header">
                    <h5 className="modal-title">{descricaoTipo}</h5>
                    <div
                    onClick={() => setAbrirModal(false)}
                    className="btn btn-icon btn-sm btn-active-light-primary ms-2"
                    data-bs-dismiss="modal"
                    aria-label="Fechar"
                    >
                    <KTSVG
                        path="/media/icons/duotune/arrows/arr061.svg"
                        className="svg-icon svg-icon-2x"
                    />
                    </div>
                </div>
                <div className="modal-body">
                    
                    <FormikProvider value={formik}>
                        <div className='w-100'>
                            <div className="row">

                                {(isConsultoria) && (
                                    <>
                                        <div className='col-lg-12 fv-row mb-5'>
                                            <label className='form-label required'>Cliente</label>
                                            <select
                                                className='form-select form-select-lg form-select-solid'
                                                value={idEmpresaClienteSelecionada}
                                                onChange={(e: React.ChangeEvent<HTMLSelectElement>) => { setIdEmpresaClienteSelecionada(parseInt(e.target.value)) }}
                                                disabled={currentCargo !== undefined && currentCargo.Id > 0}
                                            >
                                            <option value={0}>Selecione...</option>
                                            {empresasCliente && empresasCliente.length > 0 && empresasCliente.map((data: ISelectModel, index: number) => {
                                                return (
                                                    <option key={`cliente-option-${index}`} value={data.Id}>
                                                    {data.Nome}
                                                    </option>
                                                )
                                            })}
                                            </select>
                                        </div>
                                    </>
                                )}   

                                <div className="col-lg-12 fv-row mb-5">
                                    <label className='form-label required'>Nome</label>
                                    <Field
                                        name={`nome`}
                                        placeholder='Nome'
                                        className='form-control form-control-lg form-control-solid'
                                        maxLength={60}
                                        value={formik.values.nome}
                                        disabled={visualizar}
                                    />
                                    <div className='text-danger mt-2'>
                                        <ErrorMessage name='nome' />
                                    </div> 
                                </div>

                                <div className="col-lg-6 fv-row mb-5">
                                    <label className='form-label required'>Nível hierárquico</label>
                                        <Field
                                            as="select" 
                                            name={`nivel`}
                                            className='form-select form-select-lg form-select-solid'
                                            value={formik.values.nivel}
                                            disabled={visualizar}
                                        >  
                                            <option value=''>Selecione...</option>
                                            {
                                                niveisHierarquicos && niveisHierarquicos.length > 0 && niveisHierarquicos.map((data: any, index: any) => {
                                                    return <option key={`IdNivel-option-${index}`} value={data.Id}>{data.Nome}</option>
                                                })
                                            }
                                        </Field>
                                        <div className='text-danger mt-2'>
                                            <ErrorMessage name='nivel' />
                                        </div>                   
                                </div>   

                                <div className="col-lg-6 fv-row">
                                    <label className='form-label required'>Área</label>
                                        <Field 
                                            as="select" 
                                            name={`area`}
                                            className='form-select form-select-lg form-select-solid'
                                            value={formik.values.area}
                                            disabled={visualizar}
                                        >  
                                            <option value=''>Selecione...</option>
                                            {
                                                areas && areas.length > 0 && areas.map((data: any, index: any) => {
                                                    return <option key={`IdArea-option-${index}`} value={data.Id}>{data.Nome}</option>
                                                })
                                            }
                                        </Field>
                                        <div className='text-danger mt-2'>
                                            <ErrorMessage name='area' />
                                        </div>                   
                                </div>             

                            </div>

                            {(((currentCargo && visualizar) && servico?.moduloDescricaoConfig.ativo) || (!currentCargo && servico?.moduloDescricaoConfig.ativo)) && (
                                <div className='row'>
                                    <div className='col-lg-6'>
                                        <label className='form-label required'>Modelo de formulário</label>
                                        <Field as='select' name={'modelo'} className='form-select form-select-lg form-select-solid' value={formik.values.modelo} disabled={visualizar}>
                                            <option value=''>Selecione...</option>
                                            {modelos.map((data: ModeloFormulario, index: any) => {
                                                return <option key={`modelo-${index}`} value={data.Id}>{data.Nome}</option>
                                            })}
                                        </Field>
                                        <div className='text-danger mt-2'>
                                            <ErrorMessage name='modelo' />
                                        </div>      
                                    </div>
                                </div>
                            )}
                        </div>
                    </FormikProvider>
                </div>
                <div className="modal-footer">
                    <button
                        onClick={() => setAbrirModal(false)}
                        type="button"
                        className="btn btn-light me-5"
                        data-bs-dismiss="modal"
                    >
                        Cancelar
                    </button>
                    
                    <button id='modal-salvar' type='button' onClick={() => formik.handleSubmit()} className={`btn btn-primary ${visualizar ? 'd-none' : ''}`}>
                        Salvar
                    </button>

                </div>
            </div>
        </div>
    </div>
  )
}

export {ModalCargo}