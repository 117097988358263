import { FC } from 'react'
import {Field, ErrorMessage, FieldArray} from 'formik'
import { StepProps } from '../helpers/ConfiguracoesIniciaisHelper'
import { useAlert, useHttpClient } from '../../../../modules/services/Bundle'
import { RequestMethod } from '../../../../modules/services/core/_enums'
import { AbrirModalVideo } from '../../../toolbar/AbrirModalVideo'
import $ from 'jquery';

const Step5: FC<StepProps> = ({formik}) => {  

  return (
    <>
      <div className='w-100 scroll scroll-height-step-4 h-475px '>
        {formik.values.tema.length > 0 && formik.values.tema.map((temaRevisar, temaIndex) => (
          <div key={`tema-index-revisasr-${temaIndex}`}>
          {temaRevisar && temaRevisar.Topico && temaRevisar.Dimensao && temaRevisar.Dimensao.length > 0 && (
            <div key={`accordion-titulo-${temaRevisar.Id}`} id={`accordion-titulo-${temaRevisar.Id}`} className='mb-8'>
              <div className={`card card-custom border border-secondary border-1 shadow-sm titulo-pesquisa titulo-pesquisa-interativo titulo-pesquisa-nao-selecionado`}>
                <div className="card-header bg-pesquisa border-bottom border-secondary border-1 rounded-bottom" id={`card-titulo-header-${temaRevisar.Id}`}>
                  <div className="card-title">
                    <h3 className='text-white'>Tópicos que definem o tema: {(temaRevisar.Nome)}</h3>                                
                  </div>
                  <div className="card-toolbar w-md-auto">                                
                    <button type="button" className="btn btn-icon btn-sm btn-accordion btn-light" data-bs-toggle="collapse" data-bs-target={`#card-titulo-collapse-${temaRevisar.Id}`} aria-expanded={formik.values.tema.length > 1 ? "false" : "true"} aria-controls={`card-titulo-collapse-${temaRevisar.Id}`} >
                      <i className='fas fa-arrow-down'></i>
                      <i className='fas fa-arrow-up'></i>
                    </button>                   
                  </div>
                </div>
                <div id={`card-titulo-collapse-${temaRevisar.Id}`} className={`${formik.values.tema.length === 1 ? "show" : ""} collapse`} aria-labelledby={`card-titulo-header-${temaRevisar.Id}`} data-bs-parent={`#accordion-titulo-${temaRevisar.Id}`}>
                  <div className="card-body">
                    {temaRevisar.Topico.map((topico, topicoIndex) => (
                      <div key={topicoIndex} className='mb-5'>
                        <Field
                          as='textarea'
                          value={topico.PesquisaValorAtual ? topico.PesquisaValorAtual.Texto : ""}                  
                          className={`form-control form-control-lg form-control-solid textarea-texto-pesquisa texto-pesquisa-nao-selecionada overflow-auto`}
                          disabled={true}                                         
                          />
                      </div>
                    ))}
                    {temaRevisar.Dimensao.map((dimensao, dimensaoIndex) => (
                      <div key={`accordion-dimensao-${dimensao.Id}`} id={`accordion-dimensao-${dimensao.Id}`} className='mb-8'>
                        <div className={`card card-custom border border-secondary border-1 shadow-sm titulo-pesquisa titulo-pesquisa-interativo titulo-pesquisa-nao-selecionado`}>
                          <div className="card-header bg-pesquisa border-bottom border-secondary border-1 rounded-bottom" id={`card-titulo-header-${dimensao.Id}`}>
                            <div className="card-title">
                              <h3 className='text-white'>Dimensão: {(dimensao.Nome)}</h3>                                
                            </div>
                            <div className="card-toolbar w-md-auto">                                
                              <button type="button" className="btn btn-icon btn-sm btn-accordion btn-light" data-bs-toggle="collapse" data-bs-target={`#card-dimensao-collapse-${dimensao.Id}`} aria-expanded="true" aria-controls={`card-dimensao-collapse-${dimensao.Id}`} >
                                <i className='fas fa-arrow-down'></i>
                                <i className='fas fa-arrow-up'></i>
                              </button>                   
                            </div>
                          </div>
                          <div id={`card-dimensao-collapse-${dimensao.Id}`} className="collapse show" aria-labelledby={`card-dimensao-header-${dimensao.Id}`} data-bs-parent={`#accordion-dimensao-${dimensao.Id}`}>
                            <div className="card-body">
                              {dimensao.DimensaoDescricoes && dimensao.DimensaoDescricoes.map((dimensaoDescricoes, dimensaoDescricoesIndex) => (
                                <div key={`textarea-DimensaoDescricoes-${dimensaoDescricoes.Id}`} className='mb-5'>
                                  <Field
                                    as='textarea'
                                    value={dimensaoDescricoes.PesquisaValorAtual ? dimensaoDescricoes.PesquisaValorAtual.Texto : ""}                                    
                                    className={`form-control form-control-lg form-control-solid textarea-texto-pesquisa texto-pesquisa-nao-selecionada`}
                                    disabled={true}                                         
                                  />
                                </div>
                              ))}
                              <div key={`accordion-pergunta-${dimensao.Id}`} id={`accordion-pergunta-${dimensao.Id}`} className='mb-8'>
                                <div className={`card card-custom border border-secondary border-1 shadow-sm titulo-pesquisa titulo-pesquisa-interativo titulo-pesquisa-nao-selecionado`}>
                                  <div className="card-header bg-pesquisa border-bottom border-secondary border-1 rounded-bottom" id={`card-pergunta-header-${dimensao.Id}`}>
                                    <div className="card-title">
                                      <h3 className='text-white'>Perguntas da dimensão</h3>                                
                                    </div>
                                    <div className="card-toolbar w-md-auto">                                
                                      <button type="button" className="btn btn-icon btn-sm btn-accordion btn-light" data-bs-toggle="collapse" data-bs-target={`#card-pergunta-collapse-${dimensao.Id}`} aria-expanded="true" aria-controls={`card-pergunta-collapse-${dimensao.Id}`} >
                                        <i className='fas fa-arrow-down'></i>
                                        <i className='fas fa-arrow-up'></i>
                                      </button>                   
                                    </div>
                                  </div>
                                  <div id={`card-pergunta-collapse-${dimensao.Id}`} className="collapse show" aria-labelledby={`card-pergunta-header-${dimensao.Id}`} data-bs-parent={`#accordion-pergunta-${dimensao.Id}`}>
                                    <div className="card-body">
  
                                      {dimensao.Perguntas.map((pergunta, perguntaIndex) => (
                                        <div key={`card-pergunta-${perguntaIndex}`}>
                                            <div className='mb-5'>
                                              <Field
                                                as='textarea'
                                                value={pergunta.PesquisaValorAtual ? pergunta.PesquisaValorAtual.Texto : ""}                   
                                                className={`form-control form-control-lg form-control-solid textarea-texto-pesquisa texto-pesquisa-nao-selecionada`}
                                                disabled={true}                                         
                                              />
                                            </div>
                                            {pergunta.QuestaoA && pergunta.QuestaoA != "" && pergunta.QuestaoB && pergunta.QuestaoB != "" && (
                                              <div key={`accordion-questao-${pergunta.Id}`} id={`accordion-questao-${pergunta.Id}`} className='mb-8'>
                                                <div className={`card card-custom border border-secondary border-1 shadow-sm titulo-pesquisa titulo-pesquisa-interativo titulo-pesquisa-nao-selecionado`}>
                                                  <div className="card-header bg-pesquisa border-bottom border-secondary border-1 rounded-bottom" id={`card-questao-header-${pergunta.Id}`}>
                                                    <div className="card-title">
                                                      <h3 className='text-white'>Questões da pergunta</h3>                                
                                                    </div>
                                                    <div className="card-toolbar w-md-auto">                                
                                                      <button type="button" className="btn btn-icon btn-sm btn-accordion btn-light" data-bs-toggle="collapse" data-bs-target={`#card-questao-collapse-${pergunta.Id}`} aria-expanded="true" aria-controls={`card-questao-collapse-${pergunta.Id}`} >
                                                        <i className='fas fa-arrow-down'></i>
                                                        <i className='fas fa-arrow-up'></i>
                                                      </button>                   
                                                    </div>
                                                  </div>
                                                  <div id={`card-questao-collapse-${pergunta.Id}`} className="collapse show" aria-labelledby={`card-questao-header-${pergunta.Id}`} data-bs-parent={`#accordion-questao-${pergunta.Id}`}>
                                                    <div className="card-body">
                  
                                                      {pergunta.QuestaoA && pergunta.QuestaoA != "" &&
                                                      (
                                                        <>
                                                          <div key={perguntaIndex+"questaoA"} className='mb-5'>
                                                            <Field
                                                              as='textarea'
                                                              value={pergunta.QuestaoA ? pergunta.QuestaoA : ""}                   
                                                              className={`form-control form-control-lg form-control-solid textarea-texto-pesquisa texto-pesquisa-nao-selecionada`}
                                                              disabled={true}                                         
                                                            />
                                                          </div>
                                                        </>
                                                      )}

                                                      {pergunta.QuestaoB && pergunta.QuestaoB != "" &&
                                                      (
                                                        <>
                                                          <div key={perguntaIndex+"questaoB"} className='mb-5'>
                                                            <Field
                                                              as='textarea'
                                                              value={pergunta.QuestaoA ? pergunta.QuestaoB : ""}                   
                                                              className={`form-control form-control-lg form-control-solid textarea-texto-pesquisa texto-pesquisa-nao-selecionada`}
                                                              disabled={true}                                         
                                                            />
                                                          </div>
                                                        </>
                                                      )}
                                                    </div>   
                                                  </div>                         
                                                </div>
                                              </div>
                                            )}
                                        </div>
                                      ))}
                                    </div>   
                                  </div>                         
                                </div>
                              </div>
                            </div>   
                          </div>                         
                        </div>
                      </div>
                    ))}
                  </div>   
                </div>                         
              </div>
            </div>
          )}
          </div>
        ))}

      </div>
    </>
  )
}

export {Step5}
