import { ErrorMessage, Field, FormikProps, FormikProvider, useFormik } from 'formik'
import { KTSVG } from '../../../../../_metronic/helpers'
import { useAlert, useHttpClient } from '../../../../modules/services/Bundle'
import { EnumStepsTema, RequestMethod } from '../../../../modules/services/core/_enums'
import { IPesquisaRespostaModel, IPesquisaRespostaPerguntaModel, IWizardModel } from '../helpers/CrudTemaHelper'
import * as Yup from 'yup'

type Props = {
    abrirModal: boolean,
    setAbrirModal: React.Dispatch<React.SetStateAction<boolean>>
    tipoResposta: number
    dimensaoSelecionada: number | undefined,
    formik: FormikProps<IWizardModel>,
    tituloSelecionado: number | undefined,
    setTituloSelecionado: React.Dispatch<React.SetStateAction<number | undefined>>,
    respostaSelecionada: number | undefined,
    setRespostaSelecionada: React.Dispatch<React.SetStateAction<number | undefined>>,
    respostaSendoEditada: boolean,
    setRespostaSendoEditada: React.Dispatch<React.SetStateAction<boolean>>
}

interface IRespModel {
    texto: string
}

const defaultValidationSchemas = Yup.object().shape({
    texto: Yup.string().required('O argumento é obrigatório'),
})

const defaultResp: IRespModel = {
    texto: '',
}

const ModalArgumentarResp= ({abrirModal, setAbrirModal, formik, tipoResposta, dimensaoSelecionada, tituloSelecionado, respostaSelecionada}: Props) => {
    const alert = useAlert()
    const httpClient = useHttpClient()
    let titulo: IPesquisaRespostaModel | IPesquisaRespostaPerguntaModel | undefined = undefined
    if (tipoResposta === EnumStepsTema.Dimensões) titulo = formik.values.temaBuscarDimensao.Dimensao.find(c => c.Id === tituloSelecionado)

    let resposta: IPesquisaRespostaModel | IPesquisaRespostaPerguntaModel | undefined = undefined
    let tituloIndex = tipoResposta === EnumStepsTema.Tema ? formik.values.tema.findIndex((c => c.Id === tituloSelecionado)) : formik.values.temaBuscarDimensao.Dimensao.findIndex((c => c.Id === tituloSelecionado))
    if (tipoResposta === EnumStepsTema.Tema && tituloSelecionado !== undefined && formik.values.tema.length > 0 && tituloIndex >= 0) resposta = formik.values.tema[tituloIndex].Topico.find(c => c.Id === respostaSelecionada) ?? undefined
    if (tipoResposta === EnumStepsTema.Dimensões && tituloSelecionado !== undefined && formik.values.temaBuscarDimensao.Dimensao.length > 0 && tituloIndex >= 0) resposta = formik.values.temaBuscarDimensao.Dimensao[tituloIndex].DimensaoDescricoes.find(c => c.Id === respostaSelecionada) ?? undefined
    if (tipoResposta === EnumStepsTema.Perguntas && dimensaoSelecionada) resposta = formik.values.temaBuscarDimensao.Dimensao[dimensaoSelecionada].Perguntas.find(c => c.Id === respostaSelecionada) ?? undefined

    const formikArgumentarResp = useFormik({
        initialValues: defaultResp,
        validationSchema: defaultValidationSchemas,
        onSubmit: (values, actions) => {
    
        },
    })

    const onClickAddRemoveArgumentoResp = (remover:boolean) =>{

        alert.createDialog({
            html: remover ? `Realmente deseja remover este argumento da frase selecionada?`: `Realmente deseja adicionar um novo argumento na frase selecionada?`,
            confirmAction: async () => {
            
                const response = await httpClient.request({
                    method: RequestMethod.POST,
                    endpoint: '/Pesquisa/Tema/AdicionarRemoverArgumentoResposta',
                    data: {
                        TipoResposta: tipoResposta,
                        IdEntidade: resposta?.Id,
                        Texto: resposta?.PesquisaValorAtual.Texto,
                        Tema: formik.values.temaBuscarDimensao.Nome,
                        Dimensao: tipoResposta === EnumStepsTema.Perguntas ? formik.values.temaBuscarDimensao.Dimensao[dimensaoSelecionada??0].PesquisaValorAtual.Texto : '',
                        Argumento: formikArgumentarResp.values.texto,
                        Remover: remover,
                    }
                })
                if(response.success && response.payload) {
                    let pesquisa = formik.values
                    if (tipoResposta === EnumStepsTema.Tema && tituloSelecionado !== undefined && formik.values.tema.length > 0 && tituloIndex >= 0){
                        let tituloIndex = pesquisa.tema.findIndex((c => c.Id === tituloSelecionado))
                        let respIndex = pesquisa.tema[tituloIndex].Topico.findIndex((c => c.Id === respostaSelecionada))
                        
                        pesquisa.tema[tituloIndex].Topico[respIndex].PesquisaValorAtual = response.payload

                        pesquisa.tema[tituloIndex].Topico[respIndex].PesquisaValores.push(response.payload)

                        pesquisa.tema[tituloIndex].Topico[respIndex].IdPesquisaValorAtual = response.payload.Id

                        formik.setValues(pesquisa)
                        formikArgumentarResp.resetForm()
                        setAbrirModal(false)
                    }
                    if (tipoResposta === EnumStepsTema.Dimensões){
                        let tituloIndex = pesquisa.temaBuscarDimensao.Dimensao.findIndex((c => c.Id === tituloSelecionado))
                        
                        pesquisa.temaBuscarDimensao.Dimensao[tituloIndex].PesquisaValorAtual = response.payload

                        pesquisa.temaBuscarDimensao.Dimensao[tituloIndex].PesquisaValores.push(response.payload)

                        pesquisa.temaBuscarDimensao.Dimensao[tituloIndex].IdPesquisaValorAtual = response.payload.Id

                        formik.setValues(pesquisa)
                        formikArgumentarResp.resetForm()
                        setAbrirModal(false)
                    }
                    if (tipoResposta === EnumStepsTema.Perguntas && dimensaoSelecionada){
                        
                        let respIndex = pesquisa.temaBuscarDimensao.Dimensao[dimensaoSelecionada].Perguntas.findIndex((c => c.Id === respostaSelecionada))
                        
                        pesquisa.temaBuscarDimensao.Dimensao[dimensaoSelecionada].Perguntas[respIndex].PesquisaValorAtual = response.payload

                        pesquisa.temaBuscarDimensao.Dimensao[dimensaoSelecionada].Perguntas[respIndex].PesquisaValores.push(response.payload)

                        pesquisa.temaBuscarDimensao.Dimensao[dimensaoSelecionada].Perguntas[respIndex].IdPesquisaValorAtual = response.payload.Id

                        formik.setValues(pesquisa)
                        formikArgumentarResp.resetForm()
                        setAbrirModal(false)
                    }
                }
    
            }
        })
    
    }

    return (
    <div className={`modal ${abrirModal? "show" : ""} fade`} tabIndex={-1} id="kt_modal_1" style={{display: `${abrirModal? "block" : "none"}`}}>
        <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
            <div className="modal-header">
                <h5 className="modal-title">Argumento - adicionar ou remover</h5>
                <div
                onClick={() => setAbrirModal(false)}
                className="btn btn-icon btn-sm btn-active-light-primary ms-2"
                data-bs-dismiss="modal"
                aria-label="Fechar"
                >
                <KTSVG
                    path="/media/icons/duotune/arrows/arr061.svg"
                    className="svg-icon svg-icon-2x"
                />
                </div>
            </div>
            <div className="modal-body">
                <FormikProvider value={formikArgumentarResp}>
                    <div className='w-100'>
                        <div className="row">
                            <div className="col-lg-12 fv-row mb-5">
                                <div className='notice d-flex bg-light-primary rounded border-primary border border-dashed p-6'>
                                    <div className='d-flex flex-stack flex-grow-1'>
                                    <div className='fw-bold'>
                                        <div className='fs-6 text-gray-600 mh-400px overflow-auto'>
                                        {resposta && resposta.PesquisaValorAtual && (
                                            resposta.PesquisaValorAtual.Texto
                                            
                                        )}
                                        </div>
                                    </div>
                                    </div>
                                </div>
                            </div>     
                            
                            <div className="col-lg-12 fv-row mb-5">
                                <label className='form-label required'>Insira um argumento para adicionar ou remover da resposta:</label>
                                <Field
                                    as="textarea"
                                    name={`texto`}
                                    className='form-control form-control-lg form-control-solid'
                                    value={formikArgumentarResp.values.texto}
                                />
                                <div className='text-danger mt-2'>
                                    <ErrorMessage name='texto' />
                                </div> 
                            </div>         
                        </div>
                    </div>
                </FormikProvider>
            </div>
            <div className="modal-footer">
                <button
                onClick={() => setAbrirModal(false)}
                type="button"
                className="btn btn-light me-5"
                data-bs-dismiss="modal"
                >
                    Cancelar
                </button>
                
                <button type='button' onClick={() => onClickAddRemoveArgumentoResp(false)} className='btn  btn-primary me-5'>
                    Adicionar
                </button>

                <button type='button' onClick={() => onClickAddRemoveArgumentoResp(true)} className='btn  btn-danger'>
                    Remover
                </button>
                

            </div>
            </div>
        </div>
    </div>
  )
}

export {ModalArgumentarResp}
