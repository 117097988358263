import * as React from 'react';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Paper from '@mui/material/Paper';
import { visuallyHidden } from '@mui/utils';
import { Link, Navigate, useNavigate } from 'react-router-dom';
import { Tooltip, useAlert, useHttpClient } from '../../../../modules/services/Bundle';
import { EnumPesquisaParticipanteStatus, EnumServicoTipoPlano, RequestMethod } from '../../../../modules/services/core/_enums';
import Popover from '@mui/material/Popover';
import Button from '@mui/material/Button';
import { IconButton } from '@mui/material';
import { ModalDetalhesPesquisaParticipante } from './ModalDetalhesPesquisaParticipante';

interface Column {
  id: 'Id' | 'IdPesquisa' | 'Nome' | 'NomeParticipante' | 'Status' | 'Chave';
  label: string;
  minWidth?: number;
  align?: 'center';
  format?: (value: number) => string;
}

interface Filtros {
  Nome: string,
}

const columns: readonly Column[] = [
  { 
    id: 'Nome', 
    label: 'PESQUISA'
  },
  { 
    id: 'Status', 
    label: 'STATUS'
  },
];

interface Data {
  Id: number;
  IdPesquisa: number;
  Nome: string;
  NomeParticipante: string;
  Status: number;
  Chave: string;
}

function createData(
  Id: number,
  IdPesquisa: number,
  Nome: string,
  NomeParticipante: string,
  Status: number,
  Chave: string,
): Data {
  return { Id, IdPesquisa, Nome, NomeParticipante, Status, Chave};
}

type Order = 'asc' | 'desc';

interface EnhancedTableProps {
  numSelected: number;
  onRequestSort: (event: React.MouseEvent<unknown>, property: keyof Data) => void;
  onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
  order: Order;
  orderBy: string;
}

const TablePesquisaAndamento = () => {  

  const httpClient = useHttpClient()
  const [order, setOrder] = React.useState<Order>('asc')
  const [orderBy, setOrderBy] = React.useState<keyof Data>('Nome')
  const [selected, setSelected] = React.useState<readonly string[]>([])
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10)
  const [total, setTotal] = React.useState(0)
  const [rows, setRows] = React.useState<Data[]>([])   
  const alert = useAlert()
  const navigate = useNavigate()

  const [showModalDetalhes, setShowModalDetalhes] = React.useState(false)
  const [idParticipante, setIdParticipante] = React.useState(0)
  const [statusParticipante, setStatusParticipante] = React.useState(0)
  const [nomeParticipante, setNomeParticipante] = React.useState('')

  const [nome, setNome] = React.useState<any>(null)

  function EnhancedTableHead(props: EnhancedTableProps) {
    const { onSelectAllClick, order, orderBy, onRequestSort } =
      props;
    const createSortHandler =
      (property: keyof Data) => (event: React.MouseEvent<unknown>) => {
        onRequestSort(event, property);
      };
  
    return (
      <TableHead >
        <TableRow className='table-header-pesquisa'>
          {columns.map((columns) => (
            <TableCell
            className='table-cell'
            key={columns.id}
            align={columns.align ? 'right' : 'left'}
          >
            <TableSortLabel
              className={`fw-bolder ${columns.id === 'Status' ? 'ms-4' : ''}`}
              active={orderBy === columns.id}
              direction={orderBy === columns.id ? order : 'asc'}
              onClick={createSortHandler(columns.id)}
            >
              {columns.label}
              {orderBy === columns.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
          ))}
            <TableCell
              className='fw-bolder table-cell'
              align='center'
            >
              AÇÕES
            </TableCell> 
        </TableRow>
      </TableHead>
    );
  }

  const resquestTable = (pageRq: number, orderByRq: string, orderRq: string, rowsPerPageRq: number, Filtros: any = null) => {
    
      httpClient.request({
        method: RequestMethod.POST,
        endpoint: '/Pesquisa/Pesquisa/ListarPesquisasDoUsuarioEmAdamento',
        data: {
          Order: orderRq,
          OrderBy: orderByRq,
          Page: pageRq,
          RowsPerPage: rowsPerPage,
          Filters: Filtros
        }
      }).then((result) => {
          
          const newRows = result.payload.data.map((data: Data) => {
            return createData(data.Id, data.IdPesquisa, data.Nome, data.NomeParticipante, data.Status, data.Chave)
          })
          setRows([...newRows])
          setTotal(result.payload.total)
      })
    
  }

  React.useEffect(() => {
      resquestTable(page, orderBy, order, rowsPerPage);
  },[])

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: keyof Data,
  ) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
    resquestTable(page, property, isAsc ? 'desc' : 'asc', rowsPerPage);
  };

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelected = rows.map((n) => n.Nome);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    resquestTable(newPage, orderBy, order, rowsPerPage);
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    resquestTable(page, orderBy, order, parseInt(event.target.value, 10));
    setPage(0);
  };

  const emptyRows = rows.length;

  const atualizarDados = () => {
    resquestTable(page, orderBy, order, rowsPerPage)
  }

  const onClickBuscar = () => {

    const filtros:Filtros = {
      Nome: nome,
    }
    
    resquestTable(page, orderBy, order, rowsPerPage,filtros);
    handleClose()
  }

  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
      setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
      setAnchorEl(null);
      setNome(null)
  };

  const onClickAbrirModalVisualizarDetalhes = (id:number, nome:string, status:number) => {
    setIdParticipante(id)
    setNomeParticipante(nome)
    setStatusParticipante(status)
    setShowModalDetalhes(true)
  }

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  return (
    <>
      <ModalDetalhesPesquisaParticipante 
        nomeParticipante={nomeParticipante}
        statusParticipante={statusParticipante}
        idParticipante={idParticipante}
        setIdParticipante={setIdParticipante}
        showModal={showModalDetalhes}
        setShowModal={setShowModalDetalhes}
        tipoChamada={"pesquisa-em-andamento"}
      />
      
      <form className='form'>

          <div className='d-flex justify-content-end mb-2'>

            <div>
              <Button type='button' className='btn btn-light-success' aria-describedby={id} onClick={handleClick}>
                <i className="fs-2 fas fa-arrow-down-wide-short"></i>
                Filtros
              </Button>
              <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                className="popover-filtro-color-bg"
              >
                
                  <div className='bg-body'>
                    {/* begin::Header */}
                    <div className='px-7 py-5'>
                      <div className='fs-5 text-dark fw-bolder'>Opções</div>
                    </div>
                    {/* end::Header */}

                    {/* begin::Separator */}
                    <div className='separator border-gray-200'></div>
                    {/* end::Separator */}

                    {/* begin::Content */}
                    <div className='px-7 py-5' >

                      {/* begin::Input group */}
                      <div className='mb-10'>
                        <label className='form-label fs-6 fw-bold'>Nome da Pesquisa:</label>
                        <input 
                          type='text'
                          onChange={e => setNome(e.target.value)} 
                          placeholder='Nome da Pesquisa' 
                          className={`form-control form-control-lg form-control-solid`}
                        ></input>
                      </div>
                      {/* end::Input group */}

                      {/* begin::Actions */}
                      <div className='d-flex justify-content-end'>
                        <button
                          type='button'
                          className='btn btn-light btn-active-light-primary fw-bold me-5 px-6'
                          onClick={handleClose}
                        >
                          Cancelar
                        </button>
                        <button
                          type='button'
                          className='btn btn-success fw-bold px-6'
                          onClick={() => onClickBuscar()}
                        >
                          Buscar
                        </button>
                      </div>
                      {/* end::Actions */}
                    </div>
                    {/* end::Content */}
                  </div>
              </Popover>
            </div>
          </div>

          <Box sx={{ width: '100%' }}>
            <Paper className='table-border-pesquisa tabela-pesquisa' sx={{ width: '100%', mb: 2 }}>
                     
              <TableContainer>
                <Table
                  sx={{ minWidth: 750 }}
                  aria-labelledby="tableTitle"
                >
                  <EnhancedTableHead
                    numSelected={selected.length}
                    order={order}
                    orderBy={orderBy}
                    onSelectAllClick={handleSelectAllClick}
                    onRequestSort={handleRequestSort}
                  />
                  <TableBody>
                    {rows.map((row, index) => {
                      return (
                        <TableRow
                          className='table-row-pesquisa'
                          hover
                          tabIndex={-1}
                          key={index}
                          sx={{ cursor: 'pointer' }}
                        >
                          <TableCell className='table-cell' align="left">{row.Nome}</TableCell>
                          <TableCell className='table-cell' align="left">
                            <span
                              className={`badge 
                              ${row.Status === 1 ? 'badge-light-danger' : ''}
                              ${row.Status === 2 ? 'badge-light-primary' : ''}
                              ${row.Status === 3 ? 'badge-light-success' : ''}
                              fw-bolder fs-8 px-2 py-1 ms-2`}
                            >
                              {EnumPesquisaParticipanteStatus[row.Status]}
                            </span>
                          </TableCell>
                          <TableCell className='table-cell' align="center">
                            {row.Status !== EnumPesquisaParticipanteStatus['Não iniciada'] && (
                              <IconButton 
                                className={`text-gray-800 icon-button-dark-hover ms-1`}
                                title='Visualizar' 
                                onClick={() => onClickAbrirModalVisualizarDetalhes(row.Id, row.NomeParticipante, row.Status)}
                              >
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-eye-fill" viewBox="0 0 16 16">
                                    <path d="M10.5 8a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0z"/>
                                    <path d="M0 8s3-5.5 8-5.5S16 8 16 8s-3 5.5-8 5.5S0 8 0 8zm8 3.5a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7z"/>
                                </svg>
                              </IconButton>
                            )}
                            {row.Status === EnumPesquisaParticipanteStatus.Finalizada && (
                              <IconButton className='ms-4'
                              >
                                
                              </IconButton>
                            )}
                            {row.Status !== EnumPesquisaParticipanteStatus.Finalizada && (
                              <IconButton className='icon-button-dark-hover' 
                                title='Iniciar Pesquisa' color="primary" aria-label="upload picture" component="label"
                                onClick={() => { 
                                  navigate(`/responder-pesquisa/${row.Chave}`, { state: { 
                                    isColaborador: true,
                                  } })
                                }}
                              >
                                <i className="fs-6 text-success ps-1 fas fa-play"></i>
                              </IconButton>
                            )}
                            
                            {row.Status === EnumPesquisaParticipanteStatus['Não iniciada'] && (
                              <IconButton className='ms-4'
                              >
                                
                              </IconButton>
                            )}
                          </TableCell>
                        </TableRow>
                      );
                    })}
                    {emptyRows == 0 && (
                      <TableRow
                      >
                        <TableCell className='table-cell bg-body text-center' colSpan={6}>
                          Nenhum registro encontrado
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                className='table-footer-pesquisa'
                labelRowsPerPage="linhas por página"
                rowsPerPageOptions={[10, 25]}
                component="div"
                count={total}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </Paper>
          </Box>
      </form>
    </>    
  )
}

export {TablePesquisaAndamento}