import { Link } from 'react-router-dom'
import { TablePesquisaAndamento } from './partials/TablePesquisaAndamento'

const PesquisaEmAndamento = () => {

  return (
    <>

    <div className='card mb-xl-10'>
      <div
        className='card-header'
      >
        <div className='card-title m-0'>
          <h3 className='fw-bolder m-0'>Pesquisa em andamento</h3>
        </div>
      </div>

      <div className='card-body'>
        <TablePesquisaAndamento />
      </div>
      <div className="card-footer py-4 d-flex justify-content-end">
        <Link className='btn btn-secondary' to='pesquisa-ia/inicio'>
          Voltar
        </Link>
      </div>
    </div>
    </>
  )
}

export {PesquisaEmAndamento}
