import * as Yup from 'yup'
import { FileModel } from '../../../../modules/services/core/_models'

export interface ICarregarDescricoesModel {
  IdModelo?: number
  GerarRespostasParaCamposEmBranco: boolean
  Arquivo: FileModel | undefined
}

const defaultValidationSchemas = Yup.object().shape({
  IdModelo: Yup.string().required('O modelo é obrigatório'),
  Arquivo: Yup.object().required('O arquivo do modelo é obrigatório')
})

const defaultCarregarDescricoes: ICarregarDescricoesModel = {
  IdModelo: undefined,
  GerarRespostasParaCamposEmBranco: true,
  Arquivo: undefined
}

export {defaultValidationSchemas, defaultCarregarDescricoes}