import { useEffect, useState } from 'react'
import { useAlert, useHelper, useHttpClient } from '../../../modules/services/Bundle'
import { EnumPesquisaParticipanteStatusDeAlcance, RequestMethod } from '../../../modules/services/core/_enums'
import { GraficoAlcancePesquisa } from './partials/GraficoAlcancePesquisa'
import { ModalGraficoAlcancePesquisa } from './partials/ModalGraficoAlcancePesquisa'
import { FileModel, ISelectModel } from '../../../modules/services/core/_models'
import { IDadosGraficosPesquisaModel } from './helpers/AnaliseResultadoHelper'
import { GraficoMediaPorDimensao } from './partials/GraficoMediaPorDimensao'
import { GraficoMediaGeralPorParticipante } from './partials/GraficoMediaGeralPorParticipante'
import { GraficoDispersao } from './partials/GraficoDispersao'
import { IconButton } from '@mui/material'
import { toAbsoluteUrl } from '../../../../_metronic/helpers'

const ExportarResultados = () => {
  let [isMobile, setIsMobile] = useState(window.innerWidth < 992)
  let [pesquisas, setPesquisas] = useState<ISelectModel[]>([])
  let [idPesquisa, setIdPesquisa] = useState<number>(0)
  const helper = useHelper()
  const alert = useAlert()

  const httpClient = useHttpClient()

  const obterParaSelectPesquisa = async () => {
    const response = await httpClient.request({
      method: RequestMethod.GET,
      endpoint: '/Pesquisa/Resultado/ObterParaSelectExportar',
    })
    if (response.success && response.payload && response.payload.length > 0) {
      setPesquisas(response.payload)
    }
  }

  useEffect(() => {
    obterParaSelectPesquisa()
  },[])

  useEffect(() => {

    const onResizeWindow = () => {
      setIsMobile(window.innerWidth < 992)
    };

    window.addEventListener("resize", onResizeWindow);

    return () => window.removeEventListener("resize", onResizeWindow) 

  }, [])

  const onChangeSelectPesquisa = async (e: React.ChangeEvent<HTMLSelectElement>) => {
    setIdPesquisa(parseInt(e.target.value))  
  }

  const obterDadosGraficos = async () => {
    const response = await httpClient.request({
      method: RequestMethod.GET,
      endpoint: '/Pesquisa/Resultado/ObterDadosGraficos',
      queryObject: {
        idPesquisa: idPesquisa
      }
    })
  }

  return (
      <div className='card mb-10 p-8'>
        <div className='card-body border rounded'>
            <div className='fv-row'>
              <h1 className={`text-pesquisa fw-bold`}>Exportação</h1>
              <span className={`form-label text-gray-800 fs-3`}>Selecione uma pesquisa para exportar os resultados</span>
              <div className='d-flex justify-content-end mt-4'>
                <div className='bg-pesquisa d-flex justify-content-end p-5 rounded col-6'>
                  <select
                    className='form-select form-select-lg form-select-solid'
                    onChange={(e: React.ChangeEvent<HTMLSelectElement>) => onChangeSelectPesquisa(e)}
                  >
                    <option value='0'>{'Selecione...'}</option>

                    {pesquisas && pesquisas.length > 0
                      && pesquisas.map((data: ISelectModel, index: number) => {
                        return (
                          <option key={`select-pesquisa-${index}`} value={data.Id}>
                            {data.Nome}
                          </option>
                        )
                      })
                    }
                    
                  </select>
                  <IconButton
                    className='ms-3'
                    children={<i className="fa-solid fa-download text-dark fs-1"></i>}
                    title='Baixar resultados'
                    onClick={() => {
                        alert.createDialog({
                          html: 'Deseja realizar o download?',
                          confirmAction: async () => {
                            const response = await httpClient.request({
                              method: RequestMethod.GET,
                              endpoint: `/Pesquisa/Resultado/ExportarResultado?id=` + idPesquisa,
                            })
                    
                            if (response.success && response.payload) {
                              const file: FileModel = response.payload
                              await helper.downloadFile(file)
                            }
                          },
                        })
                    }}
                  />
                </div>
              </div>
              <div className='col-lg-8 mt-5 d-flex justify-content-center'>
                <img
                  className='mx-auto mh-auto'
                  src={toAbsoluteUrl('/media/prolead/pesquisa/exportar-resultados.svg')}
                  alt=''
                />
              </div>
            </div>
        </div>
      </div>
  )
}

export {ExportarResultados}
