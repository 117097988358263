import * as React from 'react';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Toolbar from '@mui/material/Toolbar';
import Paper from '@mui/material/Paper';
import { visuallyHidden } from '@mui/utils';
import Stack from '@mui/material/Stack'
import { Link, Navigate, useNavigate } from 'react-router-dom';
import { Tooltip, useAlert, useHttpClient } from '../../../../modules/services/Bundle';
import { EnumServicoTipoPlano, RequestMethod } from '../../../../modules/services/core/_enums';
import { PopoverTabela } from './Popover';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { ptBR } from '@mui/x-date-pickers/locales';
import br from 'dayjs/locale/pt-br';
import dayjs from 'dayjs';
import { KTSVG } from '../../../../../_metronic/helpers';
import { IconButton } from '@mui/material';

interface Column {
  id: 'Id' | 'Dominio' | 'RazaoSocial' | 'DataCadastro' | 'TipoPlano' | 'ConsultoriaDescricao' | 'ClienteConsultoriaDescricao' | 'RazaoSocialEmpresaConsultoriaDescricao';
  label: string;
  minWidth?: number;
  align?: 'center';
  format?: (value: number) => string;
}

interface Filtros {
  RazaoSocial: string,
  TipoPlano: number,
  DataCadastroInicio: string,
  DataCadastroFim: string,
}

const columns: readonly Column[] = [
  { 
    id: 'Dominio', 
    label: 'DOMÍNIO'
  },
  { 
    id: 'RazaoSocial', 
    label: 'RAZÃO SOCIAL'
  },
  {
    id: 'DataCadastro',
    label: ' DATA DE CRIAÇÃO'
  },
  {
    id: 'TipoPlano',
    label: 'STATUS DA VERSÃO'
  },
  {
    id: 'ConsultoriaDescricao',
    label: 'EMPRESA CONSULTORIA'
  },
  {
    id: 'RazaoSocialEmpresaConsultoriaDescricao',
    label: 'PERTENCENTE À CONSULTORIA'
  },
];

interface Data {
  Id: number;
  Dominio: string;
  RazaoSocial: string;
  DataCadastro: string;
  TipoPlano: number;
  ConsultoriaDescricao: number;
  ClienteConsultoriaDescricao: number;
  RazaoSocialEmpresaConsultoriaDescricao: number;
  EmpresaDominio: []
}

function createData(
  Id: number,
  Dominio: string,
  RazaoSocial: string,
  DataCadastro: string,
  TipoPlano: number,
  ConsultoriaDescricao: number,
  ClienteConsultoriaDescricao: number,
  RazaoSocialEmpresaConsultoriaDescricao: number,
  EmpresaDominio: []
): Data {
  return { Id, Dominio, RazaoSocial, DataCadastro, TipoPlano, ConsultoriaDescricao, ClienteConsultoriaDescricao, RazaoSocialEmpresaConsultoriaDescricao, EmpresaDominio};
}

type Order = 'asc' | 'desc';


interface EnhancedTableProps {
  numSelected: number;
  onRequestSort: (event: React.MouseEvent<unknown>, property: keyof Data) => void;
  onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
  order: Order;
  orderBy: string;
}


const TableEmpresas: React.FC = () => {  
  const httpClient = useHttpClient()
  const [order, setOrder] = React.useState<Order>('asc')
  const [orderBy, setOrderBy] = React.useState<keyof Data>('RazaoSocial')
  const [selected, setSelected] = React.useState<readonly string[]>([])
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10)
  const [total, setTotal] = React.useState(0)
  const [rows, setRows] = React.useState<Data[]>([])   
  const alert = useAlert()
  const navigate = useNavigate()

  const [razaoSocial, setRazaoSocial] = React.useState<any>(null)
  const [dataCadastroStart, setDataCadastroStart] = React.useState<any>(null)
  const [dataCadastroEnd, setDataCadastroEnd] = React.useState<any>(null)
  const [tipoPlano, setTipoPlano] = React.useState<any>(null)

  
  function EnhancedTableHead(props: EnhancedTableProps) {
    const { onSelectAllClick, order, orderBy, onRequestSort } =
      props;
    const createSortHandler =
      (property: keyof Data) => (event: React.MouseEvent<unknown>) => {
        onRequestSort(event, property);
      };
  
    return (
      <TableHead >
        <TableRow className='table-header'>
          {columns.map((columns) => (
            <TableCell
            className='table-cell'
            key={columns.id}
            align={columns.align ? 'right' : 'left'}
          >
            <TableSortLabel
              className='fw-bolder'
              active={orderBy === columns.id}
              direction={orderBy === columns.id ? order : 'asc'}
              onClick={createSortHandler(columns.id)}
            >
              {columns.label}
              {orderBy === columns.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
          ))}
            <TableCell
              className='fw-bolder table-cell'
              align='center'
            >
              AÇÕES
            </TableCell> 
        </TableRow>
      </TableHead>
    );
  }

  const resquestTable = (pageRq: number, orderByRq: string, orderRq: string, rowsPerPageRq: number, Filtros: any = null) => {
    
      httpClient.request({
        method: RequestMethod.POST,
        endpoint: '/Gerenciamento/Empresa/ListarParaAdm',
        data: {
          Order: orderRq,
          OrderBy: orderByRq,
          Page: pageRq,
          RowsPerPage: rowsPerPage,
          Filters: Filtros
        }
      }).then((result) => {
          
          const newRows = result.payload.data.map((data: Data) => {
            return createData(data.Id, data.Dominio, data.RazaoSocial, data.DataCadastro, data.TipoPlano, data.ConsultoriaDescricao, data.ClienteConsultoriaDescricao, data.RazaoSocialEmpresaConsultoriaDescricao, data.EmpresaDominio)
          })
          setRows([...newRows])
          setTotal(result.payload.total)
      })
    
  }

  React.useEffect(() => {
      resquestTable(page, orderBy, order, rowsPerPage);
  },[])

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: keyof Data,
  ) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
    resquestTable(page, property, isAsc ? 'desc' : 'asc', rowsPerPage);
  };

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelected = rows.map((n) => n.RazaoSocial);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    resquestTable(newPage, orderBy, order, rowsPerPage);
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    resquestTable(page, orderBy, order, parseInt(event.target.value, 10));
    setPage(0);
  };

  const emptyRows = rows.length;

  const atualizarDados = () => {
    resquestTable(page, orderBy, order, rowsPerPage)
  }

  const onClickBuscar = () => {

    const filtros:Filtros = {
      RazaoSocial: razaoSocial,
      DataCadastroInicio: dataCadastroStart,
      DataCadastroFim: dataCadastroEnd,
      TipoPlano: tipoPlano
    }
    
    resquestTable(page, orderBy, order, rowsPerPage,filtros);
    handleClose()
  }

  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
      setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
      setAnchorEl(null);
      setDataCadastroStart(null)
      setDataCadastroEnd(null)
      setRazaoSocial(null)
      setTipoPlano(null)
  };

  const onChangeDatePickerStart = (e:any) => {
    setDataCadastroStart(dayjs(e.$d).format('YYYY-MM-DD'))
  };

  const onChangeDatePickerEnd = (e:any) => {
    setDataCadastroEnd(dayjs(e.$d).format('YYYY-MM-DD'))
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  return (
    <>
      <form className='form'>

          <div className='d-flex justify-content-end mb-2'>

            <div>
              <Button type='button' className='btn btn-light-primary' aria-describedby={id} onClick={handleClick}>
                <i className="fs-2 fas fa-arrow-down-wide-short"></i>
                Filtros
              </Button>
              <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                className="popover-filtro-color-bg"
              >
                
                  <div className='bg-body'>
                    {/* begin::Header */}
                    <div className='px-7 py-5'>
                      <div className='fs-5 text-dark fw-bolder'>Opções</div>
                    </div>
                    {/* end::Header */}

                    {/* begin::Separator */}
                    <div className='separator border-gray-200'></div>
                    {/* end::Separator */}

                    {/* begin::Content */}
                    <div className='px-7 py-5' >

                      {/* begin::Input group */}
                      <div className='mb-10'>
                        <label className='form-label fs-6 fw-bold'>Nome da Empresa:</label>
                        <input 
                          type='text'
                          onChange={e => setRazaoSocial(e.target.value)} 
                          placeholder='Nome da Empresa' 
                          className={`form-control form-control-lg form-control-solid`}
                        ></input>
                      </div>
                      {/* end::Input group */}

                      {/* begin::Input group */}
                      <div className='mb-10'>
                        <label className='form-label fs-6 fw-bold'>Data de Criação:</label>
                        <div className='d-flex justify-content-between'>
                          <div className='col-lg-5'>
                            <label className='form-label fs-6 fw-semibold'>Início:</label>
                            <LocalizationProvider adapterLocale={br.name} dateAdapter={AdapterDayjs}>
                              <DatePicker 
                                format="DD/MM/YYYY"
                                localeText={ptBR.components.MuiLocalizationProvider.defaultProps.localeText}
                                onChange={(e) => onChangeDatePickerStart(e)} 
                                className='date-picker-color w-100'
                              />
                            </LocalizationProvider>
                          </div>
                          <div className='col-lg-1 d-flex justify-content-center align-items-center mt-5'>
                            <KTSVG path="/media/icons/duotune/arrows/arr010.svg" className="svg-icon-muted svg-icon-2hx text-gray-800" />
                          </div>
                          <div className='col-lg-5'>
                            <label className='form-label fs-6 fw-semibold'>Fim:</label>
                            <LocalizationProvider adapterLocale={br.name} dateAdapter={AdapterDayjs}>
                              <DatePicker 
                                format="DD/MM/YYYY"
                                localeText={ptBR.components.MuiLocalizationProvider.defaultProps.localeText}
                                onChange={(e) => onChangeDatePickerEnd(e)} 
                                className='date-picker-color w-100'
                              />
                            </LocalizationProvider>
                          </div>
                        </div>
                      </div>
                      {/* end::Input group */}

                      {/* begin::Input group */}
                      <div className='mb-10'>
                        <label className='form-label fs-6 fw-bold'>Tipo Plano:</label>
                        <select
                          className='form-select form-select-solid fw-bolder'
                          data-kt-select2='true'
                          data-placeholder='Select option'
                          data-allow-clear='true'
                          data-kt-user-table-filter='role'
                          data-hide-search='true'
                          onChange={e => setTipoPlano(e.target.value)}
                        >
                          <option value=''>Selecione...</option>
                          {Object.keys(EnumServicoTipoPlano).map(key => (
                            
                            parseFloat(key) > 0 && (
                              <option key={key} value={key}>
                                {EnumServicoTipoPlano[parseFloat(key)]}
                              </option>
                            )
                            
                          ))}
                        </select>
                      </div>
                      {/* end::Input group */}

                      {/* begin::Actions */}
                      <div className='d-flex justify-content-end'>
                        <button
                          type='button'
                          className='btn btn-light btn-active-light-primary fw-bold me-5 px-6'
                          onClick={handleClose}
                        >
                          Cancelar
                        </button>
                        <button
                          type='button'
                          className='btn btn-primary fw-bold px-6'
                          onClick={() => onClickBuscar()}
                        >
                          Buscar
                        </button>
                      </div>
                      {/* end::Actions */}
                    </div>
                    {/* end::Content */}
                  </div>
              </Popover>
            </div>
          </div>

          <Box sx={{ width: '100%' }}>
            <Paper className='table-border ' sx={{ width: '100%', mb: 2 }}>
                     
              <TableContainer>
                <Table
                  sx={{ minWidth: 750 }}
                  aria-labelledby="tableTitle"
                >
                  <EnhancedTableHead
                    numSelected={selected.length}
                    order={order}
                    orderBy={orderBy}
                    onSelectAllClick={handleSelectAllClick}
                    onRequestSort={handleRequestSort}
                  />
                  <TableBody>
                    {rows.map((row, index) => {
                      return (
                        <TableRow
                          className='table-row'
                          hover
                          tabIndex={-1}
                          key={index}
                          sx={{ cursor: 'pointer' }}
                        >
                          <TableCell className='table-cell' align="left">{row.EmpresaDominio?.join(', ') ?? ""}</TableCell>
                          <TableCell className='table-cell' align="left">{row.RazaoSocial}</TableCell>
                          <TableCell className='table-cell' align="left">{row.DataCadastro}</TableCell>
                          <TableCell className='table-cell' align="left">
                            <span
                              className={`badge ${
                                row.TipoPlano === 1 ? 'badge-light-warning' : 'badge-light-success'
                              } fw-bolder fs-8 px-2 py-1 ms-2`}
                            >
                              {EnumServicoTipoPlano[row.TipoPlano]}
                            </span>
                          </TableCell>
                          <TableCell className='table-cell' align="left">{row.ConsultoriaDescricao ? "Sim" : "Não"}</TableCell>
                          <TableCell className='table-cell' align="left">{row.ClienteConsultoriaDescricao ? row.RazaoSocialEmpresaConsultoriaDescricao : "Não"}</TableCell>
                          <TableCell className='table-cell' align="center">
                            <IconButton 
                              className={`text-gray-800 icon-button-dark-hover`}
                              title='Parametrizar' 
                              onClick={() => { 
                                navigate("/administracao/empresas/parametrizar",
                                  {state:{
                                      Id: row.Id,
                                  }}
                                )
                            }}>
                              <i className={`fs-6 text-gray-800 m-0 p-0 fas fa-sliders`}></i>
                            </IconButton>
                            <IconButton 
                              className={`text-gray-800 icon-button-dark-hover`}
                              title='Editar'
                              onClick={() => { 
                                navigate('/administracao/empresas/editar', { state: { 
                                  idEmpresa: row.Id,
                                } })
                              }}>
                              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-pencil-square" viewBox="0 0 16 16">
                                  <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z"/>
                                  <path fillRule="evenodd" d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z"/>
                              </svg>
                            </IconButton>
                            <IconButton 
                              className='icon-button-dark-hover' 
                              title='Excluir'
                              onClick={() => {
                                alert.createDialog({
                                  html: `Realmente deseja excluir esta empresa?`,
                                  confirmAction: async () => {
                                    httpClient.request({
                                      method: RequestMethod.POST,
                                      endpoint: '/Administracao/ParametrizarEmpresa/DesativaEmpresa',
                                      queryObject: {
                                        IdEmpresa: row.Id
                                      }
                                    }).then((response) => {
                                      if(response.success){
                                        resquestTable(page, orderBy, order, rowsPerPage);
                                      }
                                    })
                                  }
                                })
                                }} color="primary" aria-label="upload picture" component="label"
                              >
                                <i className="fs-6 text-gray-800 m-0 p-0 fas fa-trash-alt"></i>
                            </IconButton>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                    {emptyRows == 0 && (
                      <TableRow
                      >
                        <TableCell className='table-cell bg-body text-center' colSpan={6}>
                          Nenhum registro encontrado
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                className='table-footer'
                labelRowsPerPage="linhas por página"
                rowsPerPageOptions={[10, 25]}
                component="div"
                count={total}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </Paper>
          </Box>
      </form>
    </>    
  )
}

export {TableEmpresas}