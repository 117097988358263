import { FormikProps } from 'formik'
import * as Yup from 'yup'

export interface IWizardTipoCampoModel {
  Id?: number,
  Nome: string,
  Descricao: string
  MultiplasRespostas: boolean
  TipoInputHTML: number
  RespostaAutomatica: boolean
  InteracaoComAI: boolean
}

export interface IWizardCampoModel {
  id?: number,
  idTipoCampo?: number,
  nome: string
}

export interface IWizardNivelModel {
  id?: number,
  nome: string,
  relevancia?: number
}

export interface IWizardAreaModel {
  id?: number,
  nome: string
  descricao: string
}

export interface IWizardCargoModel {
  id?: number,
  nome: string
  nivel?: number
  area?: number
}

export interface IWizardModel {  
  empresaId?: number
  empresaRazaoSocial: string
  empresaSegmento?: number
  empresaNomeSegmento: string
  QtdFuncionarios: number
  PorteEmpresa: number
  empresaLogo: any
  modeloId?: number
  modeloNome: string
  modeloCampos: IWizardCampoModel[]
  niveis: IWizardNivelModel[]
  areas: IWizardAreaModel[]
  cargos: IWizardCargoModel[]
}

export interface StepProps {
  formik: FormikProps<IWizardModel>,
}

const defaultValidationSchemas = [
  Yup.object({
    empresaRazaoSocial: Yup.string().required('O nome é obrigatório'),    
    empresaSegmento: Yup.string().required('O segmento de mercado é obrigatório'),
    empresaNomeSegmento: Yup.string()
    .when('empresaSegmento', {
      is: '2',
      then: Yup.string().nullable()
      .required('O nome do segmento é obrigatório')
      .matches(/^[a-zA-Z\sáÁàÀâÂãÃéÉèÈêÊíÍìÌîÎóÓòÒôÔõÕúÚùÙûÛüÜñÑçÇ]*$/, 'O nome do segmento não pode conter números, caracteres especiais ou espaços consecutivos')
      .matches(/^(?!(.*\s){3})/, 'O nome do segmento contém muitos espaços consecutivos'),
      otherwise: Yup.string().nullable(),
    }),
    QtdFuncionarios: Yup.number().min(0, "A quantidade não pode ser negativa."),
  }),  
  Yup.object({
    modeloNome: Yup.string().required('O nome é obrigatório'),
    modeloCampos: Yup.array().min(1, 'É obrigatório ter no mínimo 1 campo no modelo'),
  }),
  Yup.object({
    niveis: Yup.array().of(
      Yup.object().shape({
         nome: Yup.string().required('O nome é obrigatório'),
         relevancia: Yup.number()
         .min(1, "A relevância deve ser maior que zero.")
         .typeError("Somente números são permitidos.")
         .required("A Relevância é obrigatória")
      })
    ),
  }),
  Yup.object({
    areas: Yup.array().of(
      Yup.object().shape({
         nome: Yup.string().required('O nome é obrigatório'),
      })
    ),
  }),
  Yup.object({
    cargos: Yup.array().of(
      Yup.object().shape({
         nome: Yup.string().required('O nome é obrigatório'),
         nivel: Yup.string().required('O nível hierárquico é obrigatório'),
         area: Yup.string().required('A área é obrigatória'),
      })
    ),
  }),
  Yup.object({
    
  }),
]

const defaultWizardNivel: IWizardNivelModel = {
  id: undefined,
  nome: '',
  relevancia: undefined
}

const defaultWizardArea: IWizardAreaModel = {
  id: undefined,
  nome: '',
  descricao: ''
}

const defaultWizardCargo: IWizardCargoModel = {
  id: undefined,
  nome: '',
  nivel: undefined,
  area: undefined
}

export {defaultValidationSchemas, defaultWizardNivel, defaultWizardArea}
