import {useEffect, useRef, useState} from 'react'
import {KTSVG} from '../../../../_metronic/helpers'
import {Step1} from './stepsResponderPesquisa/Step1'
import {Step2} from './stepsResponderPesquisa/Step2'
import {Step3} from './stepsResponderPesquisa/Step3'
import {Step4} from './stepsResponderPesquisa/Step4'
import {StepperComponent} from '../../../../_metronic/assets/ts/components'
import {FormikValues, useFormik, FormikProvider} from 'formik'
import {
  IWizardModel,
  defaultValidationSchemasDimensoes,
  defaultValidationSchemasPerguntas,
  initValues,
} from './helpers/ResponderPesquisaHelper'
import {ScrollToError, useAlert, useHttpClient} from '../../../modules/services/Bundle'
import {EnumPesquisaParticipanteStatus, EnumPesquisaStatus, EnumPesquisaTipoDisposicaoPergunta, EnumStepsResponderPesquisa, RequestMethod} from '../../../modules/services/core/_enums'
import {useNavigate} from 'react-router'
import { Link, useLocation, useParams } from 'react-router-dom'
import $ from 'jquery'
import { format } from 'date-fns'
import { ptBR } from 'date-fns/locale'

interface dados {
  isColaborador: boolean
}

const ResponderPesquisa = () => {
  const stepperRef = useRef<HTMLDivElement | null>(null)
  const stepper = useRef<StepperComponent | null>(null)
  const [skipInitStep, setSkipInitStep] = useState<boolean>(true)
  const [isMobile, setIsMobile] = useState(window.innerWidth < 992) 
  const [requestObter, setRequestObter] = useState<boolean>(false)
  const [validationSchemas, setValidationSchemas] = useState(defaultValidationSchemasDimensoes)
  const [currentSchema, setCurrentSchema] = useState(validationSchemas[0])
  const httpClient = useHttpClient()
  const routeParams = useParams()
  const [chave, setChave] = useState(routeParams["chave"])
  const navigate = useNavigate()
  const location = useLocation()
  const state = location.state as dados
  const informacao: dados = state

  const loadStepper = () => {
    stepper.current = StepperComponent.createInsance(stepperRef.current as HTMLDivElement)
  }

  const submitStep = async (values: IWizardModel, actions: FormikValues) => {

    if (!stepper.current) return    
    
    if (stepper.current.currentStepIndex < stepper.current.totatStepsNumber) {

      let sucesso = false

      switch (stepper.current.currentStepIndex) {
        case 1: {
          const response = await httpClient.request({
            method: RequestMethod.GET,
            endpoint: '/Pesquisa/ResponderPesquisa/IniciarPesquisaParticipante',
            queryObject: {
              chave: chave
            }
          })

          if (response.success) { 
            let newValues = {...formik.values}
    
            newValues.Participante.Status = EnumPesquisaParticipanteStatus['Em andamento']
            
            formik.setValues(newValues)

            sucesso = true   
          }

          break
        }
        case 2: {
          sucesso = true   
          break
        }
        case 3: {

          const response = await httpClient.request({
            method: RequestMethod.GET,
            endpoint: '/Pesquisa/ResponderPesquisa/FinalizarPesquisaParticipante',
            queryObject: {
              chave: chave
            }
          })

          if (response.success) {
            let newValues = {...formik.values}
    
            newValues.Participante.Status = EnumPesquisaParticipanteStatus.Finalizada
            
            formik.setValues(newValues)

            sucesso = true   
          }

          break
        }   
      }

      if (sucesso) {
        stepper.current.goNext()
        setCurrentSchema(validationSchemas[stepper.current.currentStepIndex - 1])
      }
      
    }

  }

  const formik = useFormik({
    initialValues: {...initValues},
    validationSchema: currentSchema,
    onSubmit: submitStep,
  })

  let dataFinal: Date = new Date();
  let dataFinalFormatada: string = "";

  if(formik.values.Pesquisa.DataFinalString !== ""){
    const [day, month, year] = formik.values.Pesquisa.DataFinalString.split("/");

    dataFinal = new Date(`${month}/${day}/${year}`);

    dataFinalFormatada = format(dataFinal, 'MMMM/yyyy', { locale: ptBR })
  }


  useEffect(() => {
    const fetchResponderPesquisa = async () => {
      if (!chave) {
        navigate('/error/404')
        return
      }

      const response = await httpClient.request({
        method: RequestMethod.GET,
        endpoint: '/Pesquisa/ResponderPesquisa/Obter',
        queryObject: {
          chave: chave
        }
      })

      if (response.success && response.payload) {

        let newValues = {...initValues}

        newValues = response.payload

        if (newValues.Participante.Status === EnumPesquisaParticipanteStatus['Não iniciada']) setSkipInitStep(false)

        setValidationSchemas(newValues.Pesquisa.TipoDisposicaoPergunta === EnumPesquisaTipoDisposicaoPergunta.AgruparPorDimensao ? defaultValidationSchemasDimensoes : defaultValidationSchemasPerguntas)
        setCurrentSchema(validationSchemas[0])
        
        formik.setValues(newValues)

      }

      setRequestObter(true)

    }
    
    fetchResponderPesquisa()
    
  }, [])

  useEffect(() => {
    if (!stepperRef.current) {
      return
    }

    loadStepper()

  }, [stepperRef])

  useEffect(() => {

    if (skipInitStep && stepper.current && formik.values.Pesquisa.Id > 0 && formik.values.Participante.Status !== EnumPesquisaParticipanteStatus['Não iniciada']) {    
      if (formik.values.Participante.Status === EnumPesquisaParticipanteStatus['Em andamento']) {
        stepper.current.goNext()        
      }

      if (formik.values.Participante.Status === EnumPesquisaParticipanteStatus.Finalizada){
        stepper.current.goNext() 
        stepper.current.goNext() 
        stepper.current.goNext() 
      }

      setCurrentSchema(validationSchemas[stepper.current.currentStepIndex - 1])

      setSkipInitStep(false)
    }

  }, [skipInitStep && stepper.current && stepper.current.currentStepIndex === 1 && formik.values.Pesquisa.Id > 0])

  const autoSizeTextArea = () => {
    $('.textarea-texto-pesquisa').each(function (i, element) {
      let target = element as HTMLTextAreaElement
      target.style.height = '110px'

      if (target.scrollHeight && target.scrollHeight > 110)
        target.style.height = target.scrollHeight + 'px'
    })

    $('.textarea-pergunta-pesquisa').each(function (i, element) {
      let target = element as HTMLTextAreaElement
      target.style.height = '60px'

      if (target.scrollHeight && target.scrollHeight > 60)
        target.style.height = target.scrollHeight + 'px'
    })
  }

  useEffect(() => {
    autoSizeTextArea()
  }, [formik])

  useEffect(() => {

    const onResizeWindow = () => {
      autoSizeTextArea()
      setIsMobile(window.innerWidth < 992)
    };

    window.addEventListener("resize", onResizeWindow);

    return () => window.removeEventListener("resize", onResizeWindow) 

  }, [])

  return (
    <div className={`${requestObter ? '' : 'd-none '}${isMobile ? '' : 'container'}`}>
      <div
        ref={stepperRef}
        className='stepper stepper-pills'
        id='kt_create_account_stepper'
      >

        {/* begin::Aside*/}
        <div className='card d-flex justify-content-center mb-5 w-100 me-5 d-none'>
          {/* begin::Wrapper*/}
          <div className='card-body'>
            {/* begin::Nav*/}
            <div className='stepper-nav  mat-horizontal-content-container flex-center flex-wrap'>
              {/* begin::Step 1*/}
              <div className='stepper-item mx-8 my-4 current' data-kt-stepper-element='nav'>
                {/* begin::Wrapper*/}
                <div className='stepper-wrapper d-flex align-items-center flex-column'>
                  {/* begin::Icon*/}
                  <div className='stepper-icon step-counter mx-0 w-40px h-40px'>
                    <i className='stepper-check fas fa-check'></i>
                    <span className='stepper-number color-white-step-number'>1</span>
                  </div>
                  {/* end::Icon*/}

                  {/* begin::Label*/}
                  <div className='stepper-label '>
                    <h3 className='stepper-title'>Iniciar</h3>
                  </div>
                  {/* end::Label*/}
                </div>
                {/* end::Wrapper*/}

                {/* begin::Line*/}
                <div className='stepper-line h-40px'></div>
                {/* end::Line*/}
              </div>
              {/* end::Step 1*/}

              {/* begin::Step 2*/}
              <div className='stepper-item mx-8 my-4 ' data-kt-stepper-element='nav'>
                {/* begin::Wrapper*/}
                <div className='stepper-wrapper d-flex align-items-center flex-column'>
                  {/* begin::Icon*/}
                  <div className='stepper-icon step-counter mx-0 w-40px h-40px'>
                    <i className='stepper-check fas fa-check'></i>
                    <span className='stepper-number color-white-step-number'>2</span>
                  </div>
                  {/* end::Icon*/}

                  {/* begin::Label*/}
                  <div className='stepper-label'>
                    <h3 className='stepper-title'>Tema</h3>
                  </div>
                  {/* end::Label*/}
                </div>
                {/* end::Wrapper*/}

                {/* begin::Line*/}
                <div className='stepper-line h-40px'></div>
                {/* end::Line*/}
              </div>
              {/* end::Step 2*/}


              {/* begin::Step 3*/}
              <div className='stepper-item mx-8 my-4 ' data-kt-stepper-element='nav'>
                {/* begin::Wrapper*/}
                <div className='stepper-wrapper d-flex align-items-center flex-column'>
                  {/* begin::Icon*/}
                  <div className='stepper-icon step-counter mx-0 w-40px h-40px'>
                    <i className='stepper-check fas fa-check'></i>
                    <span className='stepper-number color-white-step-number'>3</span>
                  </div>
                  {/* end::Icon*/}

                  {/* begin::Label*/}
                  <div className='stepper-label'>
                    <h3 className='stepper-title'>Responder</h3>
                  </div>
                  {/* end::Label*/}
                </div>
                {/* end::Wrapper*/}

                {/* begin::Line*/}
                <div className='stepper-line h-40px'></div>
                {/* end::Line*/}
              </div>
              {/* end::Step 3*/}

              {/* begin::Step 4*/}
              <div className='stepper-item mx-8 my-4 ' data-kt-stepper-element='nav'>
                {/* begin::Wrapper*/}
                <div className='stepper-wrapper d-flex align-items-center flex-column'>
                  {/* begin::Icon*/}
                  <div className='stepper-icon step-counter mx-0 w-40px h-40px'>
                    <i className='stepper-check fas fa-check'></i>
                    <span className='stepper-number color-white-step-number'>4</span>
                  </div>
                  {/* end::Icon*/}

                  {/* begin::Label*/}
                  <div className='stepper-label'>
                    <h3 className='stepper-title'>Finalizar</h3>
                  </div>
                  {/* end::Label*/}
                </div>
                {/* end::Wrapper*/}

                {/* begin::Line*/}
                <div className='stepper-line h-40px'></div>
                {/* end::Line*/}
              </div>
              {/* end::Step 4*/}
              
            </div>
            {/* end::Nav*/}
          </div>
          {/* end::Wrapper*/}
        </div>
        {/* begin::Aside*/}

        <div className='d-flex flex-row-fluid flex-center bg-body rounded'>

        <div className={`w-100 ${isMobile ? 'py-10' : 'p-10'}`}>
            {formik.values.Pesquisa.Id > 0 && (
              <>
                {!isMobile ? (
                    <><h1 className='text-center'>{formik.values.Pesquisa.Nome}</h1><h1 className='mb-10 text-center'>{`Período: ${formik.values.Pesquisa.DataInicialString} - ${formik.values.Pesquisa.DataFinalString}. Ref.: ${dataFinalFormatada.toUpperCase()}`}</h1></>
                ) : (
                  <>
                    <h1 className='text-center'>{formik.values.Pesquisa.Nome}</h1>
                    <h1 className='mb-10 text-center'>{`Período: ${formik.values.Pesquisa.DataInicialString} - ${formik.values.Pesquisa.DataFinalString}. Ref.: ${dataFinalFormatada.toUpperCase()}`}</h1>
                  </>
                )}
              </>              
            )}

            <FormikProvider value={formik}>
              <form
                className='w-100'
                noValidate
                onSubmit={formik.handleSubmit}
              >
                <ScrollToError />

                {formik.values.Pesquisa.Id === 0 && (
                  <>
                    <div className='notice d-flex bg-light-danger rounded border-danger border border-dashed p-6'>
                      <KTSVG
                        path='/media/icons/duotune/general/gen044.svg'
                        className='svg-icon-2tx svg-icon-danger me-4'
                      />
                      <div className='d-flex flex-stack flex-grow-1'>
                        <div className='fw-bold'>
                          <h4 className='text-gray-800 fw-bolder'>Pesquisa não encontrada!</h4>
                          <div className='fs-6 text-gray-600'>
                            O código de acesso do participante não existe ou esta inválido.
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                )}            

                {formik.values.Pesquisa.Id > 0 && (
                  <>
                    {(formik.values.Pesquisa.Status === EnumPesquisaStatus.NaoLiberada || formik.values.Pesquisa.Status === EnumPesquisaStatus.NaoIniciada) && (
                      <>
                        <div className='notice d-flex bg-light-warning rounded border-warning border border-dashed p-6'>
                          <KTSVG
                            path='/media/icons/duotune/general/gen044.svg'
                            className='svg-icon-2tx svg-icon-warning me-4'
                          />
                          <div className='d-flex flex-stack flex-grow-1'>
                            <div className='fw-bold'>
                              <h4 className='text-gray-800 fw-bolder'>Pesquisa não iniciada!</h4>
                              <div className='fs-6 text-gray-600'>
                                Essa pesquisa ainda não foi iniciada! Tente novamente mais tarde ou contate seu representante para mais informações.
                              </div>
                            </div>
                          </div>
                        </div>
                      </>
                    )}

                    {(formik.values.Pesquisa.Status === EnumPesquisaStatus.EmAndamento || formik.values.Pesquisa.Status === EnumPesquisaStatus.Finalizada && formik.values.Participante.Status === EnumPesquisaParticipanteStatus.Finalizada) && (
                      <>
                        <div className='current' data-kt-stepper-element='content'>
                          <Step1 
                            formik={formik}
                            isMobile={isMobile}
                          />
                        </div>

                        <div data-kt-stepper-element='content'>
                          <Step2 
                            formik={formik}
                            isMobile={isMobile}
                          />
                        </div>

                        <div data-kt-stepper-element='content'>
                          <Step3 
                            formik={formik}
                            isMobile={isMobile}
                          />
                        </div>

                        <div data-kt-stepper-element='content'>
                          <Step4
                            formik={formik}
                            isMobile={isMobile}
                          />
                        </div>

                        <div className={`d-flex flex-stack pt-10 ${((stepper.current && stepper.current.currentStepIndex < stepper.current.totatStepsNumber) || (informacao?.isColaborador)) ? '' : 'd-none'}`}>
                          
                          <div>
                            {informacao?.isColaborador &&  (
                              <>
                                <Link
                                  to={'/pesquisa-ia/pesquisa-em-andamento'}
                                  className='btn btn-lg btn-pesquisa'
                                >
                                  <KTSVG
                                    path='/media/icons/duotune/arrows/arr063.svg'
                                    className='svg-icon-4 me-1 text-white'
                                  />
                                  Voltar
                                </Link>
                              </>
                            )}
                          </div>
                          
                          <div>
                            {stepper.current && stepper.current.currentStepIndex < stepper.current.totatStepsNumber && (
                              <>
                                <button
                                  disabled={stepper.current && stepper.current.currentStepIndex === 1 && !formik.values.Termos}
                                  type='button'
                                  onClick={() =>  {
  
                                    if (formik.values.Pesquisa.TipoDisposicaoPergunta === EnumPesquisaTipoDisposicaoPergunta.AgruparPorDimensao){
                                      $('.card-dimensao .collapse').addClass('show')
                                      $('.card-dimensao .btn-accordion').attr('aria-expanded', 'true')
                                    }
                                    
                                    formik.handleSubmit()
                                  }}
                                  className='btn btn-lg btn-pesquisa'
                                >
                                  <span className='indicator-label'>
                                    {stepper.current && stepper.current.currentStepIndex === 1 && 'Aceitar'}
                                    {stepper.current && stepper.current.currentStepIndex === 2 && 'Continuar'}
                                    {stepper.current && stepper.current.currentStepIndex === 3 && 'Finalizar'}
                                    <KTSVG
                                      path='/media/icons/duotune/arrows/arr064.svg'
                                      className='svg-icon-3 ms-2 text-white me-0'
                                    />
                                  </span>
                                </button>
                              </>
                            )}                      
                          </div>   

                        </div>
                      </>
                    )}

                    {formik.values.Pesquisa.Status === EnumPesquisaStatus.Suspensa  && (
                      <>
                        <div className='notice d-flex bg-light-warning rounded border-warning border border-dashed p-6'>
                          <KTSVG
                            path='/media/icons/duotune/general/gen044.svg'
                            className='svg-icon-2tx svg-icon-warning me-4'
                          />
                          <div className='d-flex flex-stack flex-grow-1'>
                            <div className='fw-bold'>
                              <h4 className='text-gray-800 fw-bolder'>Pesquisa suspensa!</h4>
                              <div className='fs-6 text-gray-600'>
                                Essa pesquisa foi suspensa por tempo indeterminado! Tente novamente mais tarde ou contate seu representante para mais informações.
                              </div>
                            </div>
                          </div>
                        </div>
                      </>
                    )}

                    {formik.values.Pesquisa.Status === EnumPesquisaStatus.Finalizada && formik.values.Participante.Status !== EnumPesquisaParticipanteStatus.Finalizada && (
                      <>
                        <div className='notice d-flex bg-light-warning rounded border-warning border border-dashed p-6'>
                          <KTSVG
                            path='/media/icons/duotune/general/gen044.svg'
                            className='svg-icon-2tx svg-icon-warning me-4'
                          />
                          <div className='d-flex flex-stack flex-grow-1'>
                            <div className='fw-bold'>
                              <h4 className='text-gray-800 fw-bolder'>Pesquisa finalizada!</h4>
                              <div className='fs-6 text-gray-600'>
                                Essa pesquisa já foi finalizada! Caso ainda não tenha respondido contate seu representante para mais informações.
                              </div>
                            </div>
                          </div>
                        </div>
                      </>
                    )}                    
                  </>
                )}
                
              </form>
            </FormikProvider>
          </div>
          
        </div>
      </div>
    </div>
  )
}

export {ResponderPesquisa}
