/* eslint-disable jsx-a11y/anchor-is-valid */
import {useAuth} from '../../../modules/auth'
import {EnumServicoTipoPlano} from '../../../modules/services/core/_enums'
import {CardInicio} from './partials/CardInicio'

export default function InicioPesquisa() {
  const {auth, saveAuth} = useAuth()
  const servico = auth?.servico

  return (
    <>
      <h2>Início</h2>
      <div className='row g-5 g-xl-10 mb-5 mb-xl-10'>
        <div className='col-lg-12 text-center'>
          <h1>Veja como a IA Generativa pode te tornar ainda produtivo!</h1>
        </div>
      </div>

      {/* begin::Row */}
      <div className='row g-5 g-xl-10 mb-5 mb-xl-10'>
        {(auth?.servico.tipoPlano === EnumServicoTipoPlano.Try ||
          auth?.roles.find((x) => x === 'Master') !== undefined ||
          auth?.roles.find((x) => x === 'Administrador') !== undefined) && (
          <>
            {/* begin::Col */}
            <div className='col-xl-6'>
              <CardInicio
                descricao='Ajude os gestores da sua empresa a conhecer os <b class="text-pesquisa">conceitos</b> de Pesquisas de forma intuitiva'
                btnName='Ver conceitos'
                btnTo=''
                btnDisabled={true}
                imgPath='/media/prolead/inicio/conceitos.svg'
              />
            </div>
            {/* end::Col */}
          </>
        )}

        {(auth?.servico.tipoPlano === EnumServicoTipoPlano.Try ||
          auth?.roles.find((x) => x === 'Master') !== undefined ||
          auth?.roles.find((x) => x === 'Administrador') !== undefined ||
          auth?.permissoes.find((x) => x === '427BA6663CB56A628D01831C050EE359') !== undefined) && (
          <>
            {/* begin::Col */}
            <div className='col-xl-6'>
              <CardInicio
                descricao='Domine nossa plataforma rapidamente com <b class="text-pesquisa">tutoriais interativos</b> baseados em IA'
                btnName='Assistir tutorial'
                btnTo=''
                btnDisabled={true}
                imgPath='/media/prolead/inicio/navegacao.svg'
              />
            </div>
            {/* end::Col */}
          </>
        )}
        {(servico?.tipoPlano === EnumServicoTipoPlano.Try ||
          auth?.roles.find((x) => x === 'Master') !== undefined ||
          auth?.roles.find((x) => x === 'Administrador') !== undefined ||
          auth?.permissoes.find((x) => x === '52CA6BCD3B0C506D925D7A05F5CE9376') !== undefined) && (
          <>
            {/* begin::Col */}
            <div className='col-xl-6'>
              <CardInicio
                descricao='Personalize sua pesquisa com <b class="text-pesquisa">configurações iniciais</b> simplificadas utilizando nossa IA Generativa'
                btnName='Efetuar configurações'
                btnTo='/pesquisa-ia/configuracoes-iniciais'
                imgPath='/media/prolead/inicio/pesquisa3.png'
              />
            </div>
            {/* end::Col */}
          </>
        )}

        {(auth?.servico.tipoPlano === EnumServicoTipoPlano.Try ||
          auth?.roles.find((x) => x === 'Master') !== undefined ||
          auth?.roles.find((x) => x === 'Administrador') !== undefined ||
          auth?.permissoes.find((x) => x === '7576890D4CA4472D3DD78EAD3FE317F0') !== undefined) && (
          <>
            {/* begin::Col */}
            <div className='col-xl-6'>
              <CardInicio
                descricao='Após as configurações iniciais, <b class="text-pesquisa">responda a pesquisa</b> criada à partir de nossa IA Generativa'
                btnName='Configurar Pesquisa'
                btnTo='/pesquisa-ia/dashboard'
                btnDisabled={false}
                imgPath='/media/prolead/inicio/pesquisa4.png'
              />
            </div>
            {/* end::Col */}
          </>
        )}
      </div>
      {/* end::Row */}
    </>
  )
}
