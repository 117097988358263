import {useEffect, useState} from 'react'
import {useHttpClient} from '../../../modules/services/Bundle'
import {RequestMethod} from '../../../modules/services/core/_enums'
import {useNavigate} from 'react-router'
import { useParams } from 'react-router-dom'

const ConfirmarClienteConsultoriaDescricao = () => {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 992) 
  const [requestObter, setRequestObter] = useState<boolean>(false)
  const httpClient = useHttpClient()
  const routeParams = useParams()
  const [chave, setChave] = useState(routeParams["chave"])
  const [msg, setMsg] = useState('')
  const navigate = useNavigate()

  useEffect(() => {
    const fetchResponderPesquisa = async () => {
      if (!chave) {
        navigate('/error/404')
        return
      }

      const response = await httpClient.request({
        method: RequestMethod.POST,
        endpoint: '/DescricaoDeCargo/ConfirmarClienteConsultoria/ConfirmarClienteConsultoriaDescricao',
        queryObject: {
          chave: chave
        }
      })

      if (response.success) {
        setMsg('Sucesso ao confirmar o cliente da consultoria!')
      }
      else{
        setMsg('Desculpe, houve um erro ao confirmar o cliente da consultoria! Por favor, tente novamente ou contate nosso suporte.')
      }
      

      setRequestObter(true)
    }
    
    fetchResponderPesquisa()
    
  }, [])

  useEffect(() => {

    const onResizeWindow = () => {
      setIsMobile(window.innerWidth < 992)
    };

    window.addEventListener("resize", onResizeWindow);

    return () => window.removeEventListener("resize", onResizeWindow) 

  }, [])

  return (
    <div className={`${requestObter ? '' : 'd-none '}${isMobile ? '' : 'container'} `}>
      <div className='d-flex align-items-center scroll h-300px'></div>
      <span className='text-center'><h1>{msg}</h1></span>
    </div>
  )
}

export {ConfirmarClienteConsultoriaDescricao}
