import { useEffect, useState } from 'react'
import { Tooltip, useAlert, useHttpClient } from '../../../modules/services/Bundle'
import { RequestMethod } from '../../../modules/services/core/_enums'
import { ErrorMessage, Field, FormikProvider, FormikValues, useFormik } from 'formik'
import * as Yup from 'yup'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import $ from 'jquery';
import { IWizardCampoModel, IWizardTipoCampoModel } from './helpers/ConfiguracoesIniciaisHelper'
import { toAbsoluteUrl } from '../../../../_metronic/helpers'
import { ISelectModel } from '../../../modules/services/core/_models'

type IModeloModel = {
    modeloId: undefined,
    modeloNome: "",
    modeloCampos: IWizardCampoModel[]
}

const initValues: IModeloModel = {
    modeloId: undefined,
    modeloNome: '',
    modeloCampos: []
}

interface dados {
    idModelo: number,
    visualizar: boolean
}

interface VisualizarEditarModeloProps {
    isConsultoria: boolean
}

const VisualizarEditarModelo = ({ isConsultoria }: VisualizarEditarModeloProps) => {
  const httpClient = useHttpClient()
  const alert = useAlert()
  const [tiposCampo, setTiposCampo] = useState<IWizardTipoCampoModel[]>([])
  const [visualizar, setVisualizar] = useState<boolean>(false);
  const location = useLocation();
  let state = location.state as dados

  if(!state) {
    state = { visualizar: false, idModelo: 0 }
  }

  const informacao: dados = state
  const [empresasCliente, setEmpresasCliente] = useState<ISelectModel[]>([])
  const [idEmpresaClienteSelecionada, setIdEmpresaClienteSelecionada] = useState<number>(0)
    
  const formik = useFormik({
    initialValues: {...initValues},
    validationSchema: Yup.object({
        modeloNome: Yup.string().required('O nome é obrigatório'),
        modeloCampos: Yup.array().min(1, 'É obrigatório ter no mínimo 1 campo no modelo'),
    }),
    onSubmit: async (values, actions) => {

        const model = {
            Id: values.modeloId ?? 0,
            Nome: values.modeloNome,
            Campo: values.modeloCampos.map((x, index) => {
                return {
                    Id: x.id ?? 0,
                    IdModelo: values.modeloId ?? 0,
                    IdTipoCampo: x.idTipoCampo,
                    Nome: x.nome,
                    Ordem: index,
                }
            }),
        }

        const response = await httpClient.request({
            method: RequestMethod.POST,
            endpoint: '/Gerenciamento/Modelo/SalvarModelo',
            queryObject: {
                isConsultoria: isConsultoria,
                idEmpresa: idEmpresaClienteSelecionada
            },
            data: model,
            ifSuccessRedirectTo: `/descricao/${isConsultoria ? 'consultoria/' : ''}modelo-formulario-descricao`
        })

        if (response.success && response.payload) {
            const modelo = response.payload
            actions.setFieldValue('modeloId', modelo.Id)
            actions.setFieldValue('modeloNome', modelo.Nome)
            actions.setFieldValue('modeloCampos',
                modelo.Campo.sort((x: any) => x.Ordem).map((x: any) => {
                    return {
                        id: x.Id,
                        idTipoCampo: x.IdTipoCampo,
                        nome: x.Nome,
                    }
                })
            )
        }

    },
  })

  const obterEmpresasCliente = async () => {
    const response = await httpClient.request({
      method: RequestMethod.GET,
      endpoint: '/DescricaoDeCargo/Consultoria/ObterClientesParaSelect',
    })

    if(response.success && response.payload) {
      setEmpresasCliente(response.payload)
      if(response.payload.length > 0) setIdEmpresaClienteSelecionada(response.payload[0].Id)
    }
  }

  useEffect(() => {

    const carregarDados = async () => {

        if (isConsultoria) await obterEmpresasCliente()

        setVisualizar(informacao.visualizar)

        await httpClient.request({
            method: RequestMethod.GET,
            endpoint: '/Gerenciamento/TipoCampo/ObterTodos',
        }).then((response) => {

            if(response.success && response.payload) { 
                setTiposCampo(response.payload)
                if(formik.values.modeloCampos)
                {
                    let tipoCampoCargo = response.payload.find((x: { Id: number }) => x.Id === 1)
                    if(tipoCampoCargo) addCampo(tipoCampoCargo)
                }
            }

        })        

        if(informacao.idModelo){
            await httpClient.request({
                method: RequestMethod.GET,
                endpoint: '/Gerenciamento/Modelo/ObterModelo',
                queryObject: {
                    id: informacao.idModelo,
                }
            }).then((response) => {

                if(response.success && response.payload){

                    let modelo = response.payload;    
                    formik.setFieldValue('modeloId', modelo.Id)
                    formik.setFieldValue('modeloNome', modelo.Nome)            
                    let campos = modelo.Campo
            
                    if (campos && campos.length > 0) {
                        formik.setFieldValue(
                            'modeloCampos',
                            campos
                            .sort((x: any) => x.Ordem)
                            .map((x: any) => {
                                return {
                                id: x.Id,
                                idTipoCampo: x.IdTipoCampo,
                                nome: x.Nome,
                                }
                            })
                        ) 
                    }                                
                }
                
            })            
        } 

    }

    carregarDados()

  }, [])
 
  const [indexSelecionado, setIndexSelecionado] = useState<number | undefined>(undefined)

  const addCampo = (tipoCampo: IWizardTipoCampoModel) => {
    let newCamposModelo = formik.values.modeloCampos
    let campoIndex = newCamposModelo.findIndex(c => c.idTipoCampo === tipoCampo.Id)
    if (campoIndex !== -1) return
    let campoModelo: IWizardCampoModel = {
      id: undefined,
      nome: '',
      idTipoCampo: tipoCampo.Id
    }
    newCamposModelo = [ ...newCamposModelo, campoModelo ]
    formik.setFieldValue('modeloCampos', newCamposModelo)
  }

  const addCampoLivre = (tipoCampo: IWizardTipoCampoModel) => {
    let newCamposModelo = formik.values.modeloCampos
    let campoModelo: IWizardCampoModel = {
      id: undefined,
      nome: '',
      idTipoCampo: tipoCampo.Id
    }
    newCamposModelo = [ ...newCamposModelo, campoModelo ]
    formik.setFieldValue('modeloCampos', newCamposModelo)
  }

  const rmvCampo = (e: any) => {
    e.currentTarget.blur()
    $('.tooltip.show').remove()

    if(!indexSelecionado) return

    alert.createDialog({
      html: `Realmente deseja excluir esse campo?`,
      confirmAction: async () => {

        if(formik.values.modeloCampos[indexSelecionado].id){
          const result = await httpClient.request({
            method: RequestMethod.DELETE,
            endpoint: '/Gerenciamento/Modelo/DeletarCampo',
            data: formik.values.modeloCampos[indexSelecionado].id
          })

          if(result.success) removerCampoSelecionado()
        }
        else removerCampoSelecionado()
        
      }
    })

    
  }

  const removerCampoSelecionado = () => {    
    let newCamposModelo = [ ...formik.values.modeloCampos.filter((c, i) => i !== indexSelecionado) ]
    formik.setFieldValue('modeloCampos', newCamposModelo)
    setIndexSelecionado(undefined)    
  }

  const moverCampoParaBaixo = (e: any) => {
    e.currentTarget.blur()
    $('.tooltip.show').remove()

    if(indexSelecionado === undefined) return

    const from: number = indexSelecionado
    const to: number = (indexSelecionado + 1)
    let newCamposModelo = formik.values.modeloCampos
    const f = newCamposModelo.splice(from, 1)[0]
    newCamposModelo.splice(to, 0, f)
    formik.setFieldValue('modeloCampos', [ ...newCamposModelo ])
    setIndexSelecionado(to)
  }

  const moverCampoParaCima = (e: any) => {
    e.currentTarget.blur()
    $('.tooltip.show').remove()

    if(indexSelecionado === undefined) return

    const from: number = indexSelecionado
    const to: number = (indexSelecionado - 1)
    let newCamposModelo = formik.values.modeloCampos
    const f = newCamposModelo.splice(from, 1)[0]
    newCamposModelo.splice(to, 0, f)
    formik.setFieldValue('modeloCampos', [ ...newCamposModelo ])
    setIndexSelecionado(to)
  }
  
  return (
    <>
        <div className='card mb-5 mb-xl-10'>

            <div className='card-header'>
                <div className='card-title m-0'>
                    <h3 className='fw-bolder m-0'>{visualizar ? "Visualizar modelo" : informacao.idModelo ? "Editar modelo" : "Adicionar modelo"}</h3>
                </div>
            </div>

            <div className='card-body p-9'>                
                <FormikProvider value={formik}>
                    <form
                        noValidate
                        className='form'
                    >  
                        <div className="row">
                            {(isConsultoria) && (
                                <>
                                    <div className='col-lg-6 fv-row mb-5'>
                                        <label className='form-label required'>Cliente</label>
                                        <select
                                        className='form-select form-select-lg form-select-solid'
                                        value={idEmpresaClienteSelecionada}
                                        onChange={(e: React.ChangeEvent<HTMLSelectElement>) => { setIdEmpresaClienteSelecionada(parseInt(e.target.value)) }}
                                        disabled={formik.values.modeloId !== undefined || visualizar}
                                        >
                                        <option value={0}>Selecione...</option>
                                        {empresasCliente && empresasCliente.length > 0 && empresasCliente.map((data: ISelectModel, index: number) => {
                                            return (
                                                <option key={`cliente-option-${index}`} value={data.Id}>
                                                {data.Nome}
                                                </option>
                                            )
                                        })}
                                        </select>
                                    </div>
                                </>
                            )}  
                            <div className='col-lg-6 fv-row mb-5'>
                                <label className='form-label required'>Nome</label>
                                <Field 
                                    name='modeloNome'
                                    placeholder='Nome'
                                    className='form-control form-control-lg form-control-solid'
                                    disabled={visualizar}
                                    value={formik.values.modeloNome}
                                />
                                <div className='text-danger mt-2'>
                                    <ErrorMessage name='modeloNome' />
                                </div>
                            </div>                                
                        </div>
                        <div className='row'>
                            <div className="col-lg-4 fv-row mb-5">
                                <div className="card card-custom border border-secondary border-1 h-100">
                                <div className="card-header border-bottom border-secondary border-1 rounded-bottom min-h-50px">
                                    <div className="card-title">
                                    <h4>Tipos de campos</h4>                                
                                    </div>
                                </div>
                                <div className="card-body p-4 h-100">
                                    <div className="row">

                                    {
                                        tiposCampo && tiposCampo.length > 0 && tiposCampo.map((data, index) => {
                                        return(  
                                            <div key={`tipo-campo-${data.Id}`} className="form-check form-switch form-check-custom form-check-solid ms-5 mb-5">
                                                {
                                                data.Id === 17 ? 
                                                    <div className='w-40px text-center'>
                                                    <Tooltip title={`Adicionar campo livre`}>
                                                        <button disabled={visualizar} type='button' className='btn btn-icon btn-sm btn-success' onClick={() => addCampoLivre(data)}><i className='fas fa-plus'></i></button>
                                                    </Tooltip>
                                                    </div>                                  
                                                : <input 
                                                    id={`tipo-campo-id-${data.Id}`}
                                                    className="form-check-input h-25px w-40px" 
                                                    type="checkbox" 
                                                    onChange={() => addCampo(data)} 
                                                    checked={(formik.values.modeloCampos.find(x => x.idTipoCampo === data.Id) !== undefined)}
                                                    disabled={(formik.values.modeloCampos.find(x => x.idTipoCampo === data.Id) !== undefined || visualizar)}
                                                    />
                                                }
                                                <Tooltip title={`<em>${data.Descricao}</br><b>${data.MultiplasRespostas ? `Múltiplas respostas` : `Resposta única`}</b></br><b>${data.RespostaAutomatica ? `Resposta automática` : `Resposta manual`}</b></br><b>${data.InteracaoComAI ? `Interação com AI` : `Sem interação com AI`}</b></em></br>${data.TipoInputHTML === 1 ? `<u>input text</u></br><img class="w-175px" src="${toAbsoluteUrl('/media/prolead/inputs/text.png')}">` : `<u>textarea</u></br><img class="w-175px" src="${toAbsoluteUrl('/media/prolead/inputs/textarea.png')}">`} ${data.Id === 1 ? '<span class="text-danger">Obrigatório<span>' : ''}`}>
                                                <label className="form-check-label fs-3">{data.Nome}</label>
                                                </Tooltip>                                
                                            </div>
                                        );
                                        })                   
                                    }

                                    </div>
                                </div>                        
                                </div>
                                <div className='mt-2'></div>
                            </div>

                            <div className="col-lg-8 fv-row mb-5">
                                <div className="card card-custom border border-secondary border-1 h-100">
                                <div className="card-header border-bottom border-secondary border-1 rounded-bottom min-h-50px">
                                    <div className="card-title">
                                    <h4 className='required'>Campos do modelo</h4>                                
                                    </div>
                                    <div className="card-toolbar">                
                                    <Tooltip title={`Remover`}>
                                        <button type='button' className='btn btn-icon btn-sm btn-danger me-2' disabled={indexSelecionado === undefined || (indexSelecionado !== undefined && formik.values.modeloCampos[indexSelecionado].idTipoCampo === 1) || visualizar} onClick={(e) => rmvCampo(e)}><i className='fas fa-trash'></i></button>
                                    </Tooltip>
                                    <Tooltip title={`Mover para cima`}>
                                        <button type='button' className='btn btn-icon btn-sm btn-secondary me-2' disabled={indexSelecionado === undefined || indexSelecionado === 0 || visualizar} onClick={(e) => moverCampoParaCima(e)}><i className='fas fa-arrow-up'></i></button>
                                    </Tooltip>
                                    <Tooltip title={`Mover para baixo`}>
                                        <button type='button' className='btn btn-icon btn-sm btn-secondary' disabled={indexSelecionado === undefined || indexSelecionado === (formik.values.modeloCampos.length - 1) || visualizar} onClick={(e) => moverCampoParaBaixo(e)}><i className='fas fa-arrow-down'></i></button>
                                    </Tooltip>
                                    </div>
                                </div>
                                <div className="card-body p-4 h-100">

                                    <div className='row align-items-center'>
                                    <div className='col-lg-3 mb-2 text-center text-nowrap'>
                                        <Tooltip title={`Selecione o campo que deseja mover ou remover`}>
                                        <label className='fs-4 text-gray-400'>Seleção</label>
                                        </Tooltip>                  
                                    </div>
                                    <div className='col-lg-6 mb-2 text-center text-nowrap'>
                                        <Tooltip title={`Como ficará o nome do campo no seu modelo</br></br><span class="text-danger">Caso deixe vazio será utilizado o nome padrão</span>`}>
                                        <label className='fs-4 text-gray-400'>Nome do campo</label>
                                        </Tooltip>                    
                                    </div>
                                    <div className='col-lg-3 mb-2 text-center text-nowrap'>
                                        <Tooltip title={`Informa se o campo aceita ou não múltiplas respostas</br></br><span class="text-danger">Não é editável</span>`}>
                                        <label className='fs-4 text-gray-400'>Múlti-resp?</label>
                                        </Tooltip>
                                    </div>                
                                    </div>
                                    
                                    {
                                    formik.values.modeloCampos && formik.values.modeloCampos.length > 0 && formik.values.modeloCampos.map((data, index) => {
                                        const tipoCampo = tiposCampo.find(x => x.Id === data.idTipoCampo)

                                        if(!tipoCampo) return false

                                        return  <div key={`campo-modelo-${index}`} className="row align-items-center mb-5">
                                                <div className='col-lg-3 mb-5 mb-md-0 d-flex justify-content-center'>
                                                    <div className="form-check form-check-custom form-check-solid">
                                                    <input disabled={visualizar} className="form-check-input" type="radio" name="rb-campo-modelo" onChange={() => setIndexSelecionado(index)} checked={indexSelecionado === index} />
                                                    </div>                                    
                                                </div> 
                                                <Tooltip title={tipoCampo.Nome}>
                                                    <div className='col-lg-6 mb-5 mb-md-0'>                              
                                                    <Field
                                                        as='input'
                                                        name={`modeloCampos.${index}.nome`}
                                                        placeholder={tipoCampo.Nome}
                                                        className="form-control form-control-lg form-control-solid"
                                                        maxLength={30}
                                                        value={formik.values.modeloCampos[index].nome}
                                                        disabled={visualizar}
                                                    />               
                                                    </div>  
                                                </Tooltip>   
                                                <div className='col-lg-3 mb-5 mb-md-0 d-flex justify-content-center'>
                                                    <div className="form-check form-check-solid">                                
                                                    <input className="form-check-input" type="checkbox" disabled={true} checked={tipoCampo.MultiplasRespostas} />                               
                                                    </div>      
                                                </div>                                                                                                                 
                                                </div>
                                    })                   
                                    }

                                </div>                        
                                </div>   
                                <div className='text-danger mt-2'>
                                <ErrorMessage name='modeloCampos' />
                                </div>
                            </div>        
                        </div>
                    </form>
                </FormikProvider>
            </div>
            <div className='card-footer d-flex justify-content-end py-4'>
                <Link className='btn btn-secondary me-5' to={`/descricao/${isConsultoria ? 'consultoria/' : ''}modelo-formulario-descricao`} >Voltar</Link>
                {
                    !visualizar && informacao && (
                        <button
                            type='button'
                            className='btn btn-primary'
                            onClick={() => formik.handleSubmit()}
                        >
                            Salvar
                        </button>
                    )
                }
            </div>
        </div>

    </>
  )
}

export {VisualizarEditarModelo}