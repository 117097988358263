import * as Yup from 'yup'

export interface ICargoModel {
  id?: number
  nome: string
  nivel?: number
  area?: number
  modelo?: number
}

const defaultValidationSchemas = Yup.object().shape({
  nome: Yup.string().required('O nome é obrigatório'),
  nivel: Yup.string().required('O nível hierárquico é obrigatório'),
  area: Yup.string().required('A área é obrigatória'),
})

const moduloDescricaoValidationSchema = Yup.object().shape({
  nome: Yup.string().required('O nome é obrigatório'),
  nivel: Yup.string().required('O nível hierárquico é obrigatório'),
  area: Yup.string().required('A área é obrigatória'),
  modelo: Yup.string().required('O modelo de formulário é obrigatório')
})

const defaultCargo: ICargoModel = {
  id: undefined,
  nome: '',
  nivel: undefined,
  area: undefined,
  modelo: undefined,
}

export {defaultValidationSchemas, defaultCargo, moduloDescricaoValidationSchema}
