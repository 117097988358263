import * as Yup from 'yup'
import { useValidation } from '../../../../modules/services/Bundle'


export interface IUsuarioModel {
  Nome: string,
  Sobrenome: string,
  Email: string,
  Telefone: string,
  Password: string,
  ConfirmPassword: string,
}

const initialValues:IUsuarioModel = {
  Nome: '',
  Sobrenome: '',
  Email: '',
  Telefone: '',
  Password: '',
  ConfirmPassword: '',
}

const defaultValidationSchemas = Yup.object().shape({
  Nome: Yup.string()
    .min(3, 'O nome deve ter no mínimo 3 caracteres')
    .max(50, 'O nome deve ter no máximo 50 caracteres')
    .required('O nome é obrigatório'),
  Sobrenome: Yup.string()
    .min(3, 'O sobrenome deve ter no mínimo 3 caracteres')
    .max(50, 'O sobrenome deve ter no máximo 50 caracteres')
    .required('O sobrenome é obrigatório'),
  Email: Yup.string()
    .email('E-mail inválido')
    .test('company-email', 'O e-email deve ser corporativo', (val = '') => {
      if (!val) return true
      const validation = useValidation()
      return validation.CompanyEmail(val)
    })
    .min(3, 'O e-mail deve ter no mínimo 3 caracteres')
    .max(80, 'O e-mail deve ter no máximo 80 caracteres')
    .required('O e-mail é obrigatório'),
  Telefone: Yup.string()
    .test('Telefone', 'Telefone inválido', (val = '') => {
      if (!val) return true
      return val.replace(/\D/g, '').length === 11
    })
    .required('O telefone é obrigatório'),
  Password: Yup.string()
    .min(8, 'A senha deve ter no mínimo 8 caracteres')
    .max(20, 'A senha deve ter no máximo 20 caracteres')
    // eslint-disable-next-line
    .matches(
      /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[$*&@#!?%\-\=\\\/\[\]\{\}\(\)])[0-9a-zA-Z$*&@#!?%\-\=\\\/\[\]\{\}\(\)]{8,20}$/,
      'A senha deve ter pelo menos 1 letra maiúscula, 1 letra minúscula, 1 número e 1 símbolo'
    )
    .required('A senha é obrigatória'),
  ConfirmPassword: Yup.string()
    .required('A confirmação de senha é obrigatória')
    .when('Password', {
      is: (val: string) => (val && val.length > 0 ? true : false),
      then: Yup.string().oneOf(
        [Yup.ref('Password')],
        'A senha e a confirmação de senha não conferem'
      ),
    }),
})

export {defaultValidationSchemas, initialValues}
