import {Route, Routes, Outlet, Navigate} from 'react-router-dom'
import {PageLink, PageTitle} from '../../../_metronic/layout/core'
import { Conceitos } from './components/Conceitos'
import { Detalhes } from './components/Detalhes'
import { ArrayBreadcrumb, BreadcrumbLayout } from '../../modules/services/core/Breadcrumb'
import { useAuth } from '../../modules/auth'
import { EnumServicoTipoPlano } from '../../modules/services/core/_enums'

const wizardsBreadCrumbs: Array<ArrayBreadcrumb> = []

const wizardsBreadCrumbsConceitos: Array<ArrayBreadcrumb> = [
  {
    titulo: 'Conceitos',
    path: '/conceitos/ajuda',
  }
]

const ConceitosPage = () => {

  const {auth} = useAuth()
  const servico = auth?.servico

  return (
    <Routes>
      <Route element={<Outlet />}>
        {(servico?.tipoPlano === EnumServicoTipoPlano.Try || ((auth?.roles.find(x => x === 'Master') !== undefined) || (auth?.roles.find(x => x === 'Administrador') !== undefined) || (auth?.permissoes.find(x => x === '9C374241FF1E5715026B0FDE04A5E870') !== undefined))) && (
          <>
            <Route
              path='ajuda'
              element={
                <>
                  <BreadcrumbLayout props={wizardsBreadCrumbs}>Conceitos</BreadcrumbLayout>
                  <Conceitos />
                </>
              }
            />
            <Route
              path='detalhes/:id'
              element={
                <>
                  <BreadcrumbLayout props={wizardsBreadCrumbsConceitos}>Detalhes</BreadcrumbLayout>
                  <Detalhes />
                </>
              }
            />
          </>
        )}    
        <Route index element={<Navigate to='/ajuda' />} />
      </Route>
    </Routes>
  )
}

export default ConceitosPage
