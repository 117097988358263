/* eslint-disable react-hooks/exhaustive-deps */
import * as React from 'react'
import Box from '@mui/material/Box'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TablePagination from '@mui/material/TablePagination'
import TableRow from '@mui/material/TableRow'
import TableSortLabel from '@mui/material/TableSortLabel'
import Paper from '@mui/material/Paper'
import { visuallyHidden } from '@mui/utils'
import { Tooltip, useAlert, useHelper, useHttpClient } from '../../../../modules/services/Bundle'
import { EnumModalAvalidorAprovador, EnumServicoTipoPlano, EnumTipoEnviarAprovacao, EnumTipoEnviarAvaliacao, RequestMethod } from '../../../../modules/services/core/_enums'
import IconButton from '@mui/material/IconButton'
import { Link } from 'react-router-dom'
import { ModalAddCargo } from './ModalAddCargo'
import { DescricaoType } from '../Dashboard'
import { useAuth } from '../../../../modules/auth'
import { FileModel } from '../../../../modules/services/core/_models'
import { ModalAprovadores } from './ModalAprovadores'
import { Checkbox, Toolbar, Typography } from '@mui/material'
import { ModalAvaliadores } from './ModalAvaliadores'
import { ModalAvaliadoresAprovadores } from './ModalAvaliadoresAprovadores'

type Props = {
  title: string
  buscar: boolean
  setDadosDash: Function
  color: string
  titleColor: string
  descricaoType: string
  statusDescricao: number
  atualizarTodasAsTabelas: boolean
  setAtualizarTodasAsTabelas: Function
  consultor?: number | null
  filters?: Filters
  tipoRequestEnviarAprovacao: number
  setTipoRequestEnviarAprovacao: Function
  tipoRequestEnviarAvaliacao: number
  setTipoRequestEnviarAvaliacao: Function
  utilizaAvaliador: boolean
  setUtilizaAvaliador: Function
  abrirModalAddCargo: boolean
  setAbrirModalAddCargo: Function
  abrirModalAvaliadores: number | null
  setAbrirModalAvaliadores: Function
  abrirModalAprovadores: number | null
  setAbrirModalAprovadores: Function
  abrirModalAvaliadoresAprovadores: boolean
  setAbrirModalAvaliadoresAprovadores: Function
  selected: readonly number[]
  setSelected: Function
}

interface Column {
  id: 'Id' | 'Nome' | 'NomeNivelHierarquico' | 'NomeArea' | 'IdDescricao' | 'Colaboradores' | 'IdModelo' | 'DataAprovacao' | 'Avaliadores'
  label: string
  minWidth?: number
  align?: 'center'
  format?: (value: number) => string
}

const columns: Column[] = [
  {
    id: 'Nome',
    label: 'NOME',
  },
  {
    id: 'NomeNivelHierarquico',
    label: 'NÍVEL HIERÁRQUICO',
  },
  {
    id: 'NomeArea',
    label: 'ÁREA',
  },
]

interface Data {
  Id: number,
  Nome: string
  NomeNivelHierarquico: string
  NomeArea: string
  IsAprovador: boolean
  IsAvaliador: boolean
  IdDescricao?: number
  IdModelo?: number
  Colaboradores?: string[]
  Avaliadores?: string[]
  DataAprovacao?: string
}

export default interface Filters {
  IdEntidade?: number
  Texto?: string
  IdArea?: number
}

function createData(
  Id: number,
  Nome: string,
  NomeNivelHierarquico: string,
  NomeArea: string,
  IsAprovador: boolean,
  IsAvaliador: boolean,
  IdDescricao?: number,
  IdModelo?: number,
  Colaboradores?: string[],
  Avaliadores?: string[],
  DataAprovacao?: string,
): Data {
  return { Id, Nome, NomeNivelHierarquico, NomeArea, IsAprovador, IsAvaliador, IdDescricao, IdModelo, Colaboradores, Avaliadores, DataAprovacao }
}

type Order = 'asc' | 'desc'

interface EnhancedTableProps {
  numSelected: number;
  onRequestSort: (event: React.MouseEvent<unknown>, property: keyof Data) => void;
  onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
  order: Order;
  orderBy: string;
  rowCount: number;
}

const TableDescricao: React.FC<Props> = ({
  title,
  buscar,
  titleColor,
  color,
  setDadosDash,
  descricaoType,
  statusDescricao,
  atualizarTodasAsTabelas,
  setAtualizarTodasAsTabelas,
  filters,
  consultor = null,
  tipoRequestEnviarAprovacao,
  setTipoRequestEnviarAprovacao,
  tipoRequestEnviarAvaliacao,
  setTipoRequestEnviarAvaliacao,
  utilizaAvaliador,
  setUtilizaAvaliador,
  abrirModalAddCargo,
  setAbrirModalAddCargo,
  abrirModalAprovadores,
  setAbrirModalAvaliadores,
  abrirModalAvaliadores,
  setAbrirModalAprovadores,
  abrirModalAvaliadoresAprovadores,
  setAbrirModalAvaliadoresAprovadores,
  selected,
  setSelected
}) => {
  const helper = useHelper()
  const { auth } = useAuth()
  const servico = auth?.servico
  const httpClient = useHttpClient()
  const [order, setOrder] = React.useState<Order>('asc')
  const [orderBy, setOrderBy] = React.useState<keyof Data>('Nome')
  const [page, setPage] = React.useState(0)
  const [rowsPerPage, setRowsPerPage] = React.useState(10)
  const [total, setTotal] = React.useState(0)
  const [idDescricaoModalSelect, setIdDescricaoModalSelect] = React.useState(0)
  const [tipoModalAprovadorAvaliador, setTipoModalAprovadorAvaliador] = React.useState(0)
  const [colorOpacity] = React.useState(color + '80')
  const [rows, setRows] = React.useState<Data[]>([])
  const alert = useAlert()
  const [permiteEditarDescricao, setPermiteEditarDescricao] = React.useState(false)

  if (descricaoType === DescricaoType.Aprovar) {
    if (!columns.some((c) => c.id === 'Colaboradores')) {
      columns.splice(columns.length, 0, { id: 'Colaboradores', label: 'APROVADORES' })
    }
    if (columns.some((c) => c.id === 'Avaliadores')) {
      var ix = columns.findIndex((c) => c.id === 'Avaliadores')
      columns.splice(ix, 1)
    }
  } else if (descricaoType === DescricaoType.Avaliar) {
    if (!columns.some((c) => c.id === 'Avaliadores')) {
      columns.splice(columns.length, 0, { id: 'Avaliadores', label: 'AVALIADORES' })
    }
    if (columns.some((c) => c.id === 'Colaboradores')) {
      var index = columns.findIndex((c) => c.id === 'Colaboradores')
      columns.splice(index, 1)
    }
    if (columns.some((c) => c.id === 'DataAprovacao')) {
      var i = columns.findIndex((c) => c.id === 'DataAprovacao')
      columns.splice(i, 1)
    }
  } else {
    if (columns.some((c) => c.id === 'Colaboradores')) {
      var index = columns.findIndex((c) => c.id === 'Colaboradores')
      columns.splice(index, 1)
    }
    if (columns.some((c) => c.id === 'Avaliadores')) {
      var ix = columns.findIndex((c) => c.id === 'Avaliadores')
      columns.splice(ix, 1)
    }
    if (columns.some((c) => c.id === 'DataAprovacao')) {
      var i = columns.findIndex((c) => c.id === 'DataAprovacao')
      columns.splice(i, 1)
    }
  }

  if (descricaoType === DescricaoType.DescricoesAprovadas && (consultor || servico?.clienteConsultoriaDescricao) && !columns.some((c) => c.id === 'DataAprovacao')) {
    columns.push({ id: 'DataAprovacao', label: 'DATA DE APROVAÇÃO' })
  }

  function EnhancedTableHead(props: EnhancedTableProps) {
    const { onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } =
      props;
    const createSortHandler = (property: keyof Data) => (event: React.MouseEvent<unknown>) => {
      onRequestSort(event, property)
    }

    return (
      <TableHead>
        <TableRow style={{ backgroundColor: colorOpacity }}>
          {((descricaoType === DescricaoType.Revisar && auth?.servico.clienteConsultoriaDescricao == null) || (descricaoType === DescricaoType.Avaliar && auth?.permissoes.find((x) => x === 'F83AC0F344DD8AFE835ABD3DF11092A8') !== undefined)) && (
            <>
              <TableCell padding="checkbox">
                <Checkbox
                  color="primary"
                  indeterminate={numSelected > 0 && numSelected < rowCount}
                  checked={rowCount > 0 && numSelected === rowCount}
                  onChange={onSelectAllClick}
                  inputProps={{
                    'aria-label': 'select all desserts',
                  }}
                />
              </TableCell>
            </>
          )}
          {columns.map((columns) => (
            <TableCell
              style={{ color: titleColor }}
              key={columns.id}
              align={columns.align ? 'right' : 'left'}
            >
              <TableSortLabel
                className='fw-bolder table-cell'
                active={orderBy === columns.id}
                direction={orderBy === columns.id ? order : 'asc'}
                onClick={createSortHandler(columns.id)}
              >
                {columns.label}
                {orderBy === columns.id ? (
                  <Box component='span' sx={visuallyHidden}>
                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                  </Box>
                ) : null}
              </TableSortLabel>
            </TableCell>
          ))}
          <TableCell
            style={{ color: titleColor }}
            className='fw-bolder table-cell'
            align='right'
          >
            AÇÕES
          </TableCell>
        </TableRow>
      </TableHead>
    )
  }

  const resquestTable = (
    pageRq: number,
    orderByRq: string,
    orderRq: string,
    rowsPerPageRq: number,
    filters?: Filters
  ) => {
    let url = consultor == null ? '/DescricaoDeCargo/Dashboard/' : '/DescricaoDeCargo/Consultoria/';
    if (descricaoType === DescricaoType.Aprovar) {
      url += 'ListarAguardandoAprovar'
    } else if (descricaoType === DescricaoType.Avaliar) {
      url += 'ListarAguardandoAvaliar'
    } else if (descricaoType === DescricaoType.Revisar) {
      url += 'ListarAguardandoRevisar'
    } else if (descricaoType === DescricaoType.DescricoesAprovadas) {
      url += 'ListarAprovados'
    } else if (descricaoType === DescricaoType.DescricoesReprovadas) {
      url += 'ListarReprovados'
    }

    httpClient
      .request({
        method: RequestMethod.POST,
        endpoint: url,
        queryObject: {
          idEmpresa: consultor
        },
        data: {
          Order: orderRq,
          OrderBy: orderByRq,
          Page: pageRq,
          RowsPerPage: rowsPerPageRq,
          Filters: filters
        },
      })
      .then((result) => {
        let newRows = result.payload.data.map((data: Data) => {
          if (descricaoType === DescricaoType.Aprovar) {
            return createData(
              data.Id,
              data.Nome,
              data.NomeNivelHierarquico,
              data.NomeArea,
              data.IsAprovador,
              data.IsAvaliador,
              data.IdDescricao,
              data.IdModelo,
              data.Colaboradores,
              []
            )
          } else if (descricaoType === DescricaoType.Avaliar) {
            return createData(
              data.Id,
              data.Nome,
              data.NomeNivelHierarquico,
              data.NomeArea,
              data.IsAprovador,
              data.IsAvaliador,
              data.IdDescricao,
              data.IdModelo,
              [],
              data.Avaliadores
            )
          } else if (descricaoType === DescricaoType.DescricoesAprovadas && (consultor || servico?.clienteConsultoriaDescricao)) {
            return createData(
              data.Id,
              data.Nome,
              data.NomeNivelHierarquico,
              data.NomeArea,
              data.IsAprovador,
              data.IsAvaliador,
              data.IdDescricao,
              data.IdModelo,
              [],
              [],
              data.DataAprovacao
            )
          } else {
            return createData(
              data.Id,
              data.Nome,
              data.NomeNivelHierarquico,
              data.NomeArea,
              data.IsAprovador,
              data.IsAvaliador,
              data.IdDescricao,
              data.IdModelo
            )
          }
        })
        setRows([...newRows])
        setTotal(result.payload.total)
        if (consultor !== null && consultor > 0) {
          httpClient
            .request({
              method: RequestMethod.GET,
              endpoint: '/DescricaoDeCargo/Consultoria/ObterCliente',
              queryObject: {
                idEmpresaCliente: consultor
              },
            })
            .then((result) => {
              if (result.success && result.payload) {
                setPermiteEditarDescricao(result.payload.PermiteEditarDescricao)
                setUtilizaAvaliador(result.payload.UtilizaAvaliador)
              }
            })
        }
      })
  }

  React.useEffect(() => {
    if (buscar === true) {
      resquestTable(page, orderBy, order, rowsPerPage, filters)
    }
  }, [buscar])

  const handleRequestSort = (event: React.MouseEvent<unknown>, property: keyof Data) => {
    const isAsc = orderBy === property && order === 'asc'
    setOrder(isAsc ? 'desc' : 'asc')
    setOrderBy(property)
    resquestTable(page, property, isAsc ? 'desc' : 'asc', rowsPerPage, filters)
  }

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelected = rows.map((n) => n.IdDescricao ?? 0)
      setSelected(newSelected)
      return
    }
    setSelected([])
  }

  const handleClick = (event: React.MouseEvent<unknown>, id: number) => {
    if (descricaoType === DescricaoType.Revisar || descricaoType === DescricaoType.Avaliar) {
      const selectedIndex = selected.indexOf(id);
      let newSelected: readonly number[] = [];

      if (selectedIndex === -1) {
        newSelected = newSelected.concat(selected, id);
      } else if (selectedIndex === 0) {
        newSelected = newSelected.concat(selected.slice(1));
      } else if (selectedIndex === selected.length - 1) {
        newSelected = newSelected.concat(selected.slice(0, -1));
      } else if (selectedIndex > 0) {
        newSelected = newSelected.concat(
          selected.slice(0, selectedIndex),
          selected.slice(selectedIndex + 1),
        );
      }
      setSelected(newSelected);
    }
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    resquestTable(newPage, orderBy, order, rowsPerPage, filters)
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    resquestTable(page, orderBy, order, parseInt(event.target.value, 10), filters)
    setPage(0)
  }

  const emptyRows = rows.length

  const DeletarCargoADescrever = (idCargo: number) => {
    alert.createDialog({
      html: `Realmente deseja excluir esta descrição?`,
      confirmAction: async () => {
        const result = await httpClient.request({
          method: RequestMethod.DELETE,
          endpoint: '/Gerenciamento/Cargo/Deletar',
          data: idCargo,
        })
        if (result.success) {
          atualizarDados()
        }
      },
    })
  }

  const ReprovarAvaliar = (idDescricao: number) => {
    alert.createDialog({
      html: `Realmente deseja reprovar esta descrição?`,
      confirmAction: async () => {
        const result = await httpClient.request({
          method: RequestMethod.GET,
          endpoint: '/DescricaoDeCargo/Descrito/ReprovarAvaliacaoDescricao',
          queryObject: { idDescricao: idDescricao },
        })
        if (result.success) {
          atualizarDados()
        }
      },
    })
  }

  const RetornarConsultoria = (idDescricao: number) => {
    alert.createDialog({
      html: `Realmente deseja retornar esta descrição?`,
      confirmAction: async () => {
        const result = await httpClient.request({
          method: RequestMethod.PUT,
          endpoint: '/DescricaoDeCargo/Descrever/FinalizarDescricaoVoltarParaConsultoria',
          queryObject: { idDescricao: idDescricao, idEmpresa: consultor },
        })
        if (result.success) {
          atualizarDados()
        }
      },
    })
  }

  const RetornarConsultoriaAprovar = (idDescricao: number) => {
    alert.createDialog({
      html: `Realmente deseja retornar esta descrição?`,
      confirmAction: async () => {
        const result = await httpClient.request({
          method: RequestMethod.PUT,
          endpoint: '/DescricaoDeCargo/Descrever/FinalizarDescricaoAprovarVoltarParaConsultoria',
          queryObject: { idDescricao: idDescricao, idEmpresa: consultor },
        })
        if (result.success) {
          atualizarDados()
        }
      },
    })
  }

  const DownloadDescricao = (idCargo: number) => {
    alert.createDialog({
      html: 'Deseja realizar o download?',
      confirmAction: async () => {
        const response = await httpClient.request({
          method: RequestMethod.GET,
          endpoint: `/DescricaoDeCargo/Dashboard/DownloadDescricao?id=` + idCargo,
        })

        if (response.success && response.payload) {
          const file: FileModel = response.payload
          await helper.downloadFile(file)
        }
      },
    })
  }

  const atualizarDados = () => {
    setDadosDash()
    setAtualizarTodasAsTabelas(true)
    setSelected([])
  }

  React.useEffect(() => {
    resquestTable(page, orderBy, order, rowsPerPage, filters)
    setAtualizarTodasAsTabelas(false)
  }, [atualizarTodasAsTabelas === true])

  React.useEffect(() => {
    if (consultor != null) resquestTable(page, orderBy, order, rowsPerPage, filters)
  }, [consultor])

  React.useEffect(() => {
    resquestTable(page, orderBy, order, rowsPerPage, filters)
  }, [filters])

  const setModalAprovadoresState = (state: boolean) => {
    setAbrirModalAprovadores(null)
  }

  const setModalAvaliadoresState = (state: boolean) => {
    setAbrirModalAvaliadores(null)
  }

  const isSelected = (id: number) => selected.indexOf(id) !== -1;

  const formatArray = (array: string[]) => {
    if (array === undefined) {
      return 'Esta descrição não tem aprovador'
    } else if (array.length <= 3) {
      return array.length !== 1 ? array.join(', ') : array[0]
    } else {
      const firstThree = array.slice(0, 3).join(', ')
      return `${firstThree}, ...`
    }
  }

  return (
    <>
      <ModalAddCargo
        descricaoTipo='Adicionar novo cargo'
        abrirModal={abrirModalAddCargo}
        setAbrirModal={setAbrirModalAddCargo}
        atualizarDados={atualizarDados}
        idEmpresa={consultor}
      />

      {abrirModalAprovadores && (
        <ModalAprovadores
          AbrirModal={abrirModalAprovadores != null}
          SetAbrirModal={setModalAprovadoresState}
          IdDescricao={abrirModalAprovadores}
          IdsDescricao={selected}
          TipoRequest={tipoRequestEnviarAprovacao}
          atualizarDados={atualizarDados}
          idEmpresa={consultor}
        />
      )}

      {abrirModalAvaliadores && (
        <ModalAvaliadores
          AbrirModal={abrirModalAvaliadores != null}
          SetAbrirModal={setModalAvaliadoresState}
          IdDescricao={abrirModalAvaliadores}
          IdsDescricao={selected}
          TipoRequest={tipoRequestEnviarAvaliacao}
          atualizarDados={atualizarDados}
          idEmpresa={consultor}
        />
      )}

      <ModalAvaliadoresAprovadores
        TipoModal={tipoModalAprovadorAvaliador}
        AbrirModal={abrirModalAvaliadoresAprovadores}
        SetAbrirModal={setAbrirModalAvaliadoresAprovadores}
        IdDescricao={idDescricaoModalSelect}
        IdsDescricao={selected}
        TipoRequest={tipoRequestEnviarAvaliacao}
        atualizarDados={atualizarDados}
        idEmpresa={consultor}
      />

      <form noValidate className='form'>
        <div className='card-body'>
          <Box sx={{ width: '100%' }}>
            <Paper
              className='tabela-descricao'
              style={{
                borderWidth: '2px',
                borderStyle: 'solid',
                borderRadius: '0px 0px 5px 5px',
                borderColor: colorOpacity,
              }}
              sx={{ width: '100%', mb: 2 }}
            >
              <TableContainer>
                <Table sx={{ minWidth: 750 }} aria-labelledby='tableTitle'>
                  <EnhancedTableHead
                    numSelected={selected.length}
                    order={order}
                    orderBy={orderBy}
                    onSelectAllClick={handleSelectAllClick}
                    onRequestSort={handleRequestSort}
                    rowCount={rows.length}
                  />
                  <TableBody>
                    {rows.map((row, index) => {
                      const isItemSelected = isSelected(row.IdDescricao ?? 0);
                      const labelId = `enhanced-table-checkbox-${index}`;

                      return (
                        <TableRow
                          onClick={(event) => handleClick(event, row.IdDescricao ?? 0)}
                          className='table-row'
                          hover
                          role="checkbox"
                          aria-checked={isItemSelected}
                          tabIndex={-1}
                          key={row.Id}
                          selected={isItemSelected}
                          sx={{ cursor: 'pointer' }}
                        >
                          {((descricaoType === DescricaoType.Revisar && auth?.servico.clienteConsultoriaDescricao == null) || (descricaoType === DescricaoType.Avaliar && auth?.permissoes.find((x) => x === 'F83AC0F344DD8AFE835ABD3DF11092A8') !== undefined)) && (
                            <>
                              <TableCell padding="checkbox">
                                <Checkbox
                                  color="primary"
                                  checked={isItemSelected}
                                  inputProps={{
                                    'aria-labelledby': labelId,
                                  }}
                                />
                              </TableCell>
                            </>
                          )}
                          <TableCell className='table-cell' align='left'>
                            {row.Nome}
                          </TableCell>
                          <TableCell className='table-cell' align='left'>
                            {row.NomeNivelHierarquico}
                          </TableCell>
                          <TableCell className='table-cell' align='left'>
                            {row.NomeArea}
                          </TableCell>
                          {descricaoType === DescricaoType.Aprovar && (
                            <TableCell className='table-cell' align='left'>
                              <Tooltip
                                title={
                                  row.Colaboradores !== undefined
                                    ? row.Colaboradores.join(', ')
                                    : 'Esta descrição não tem aprovadores'
                                }
                              >
                                <span>{formatArray(row.Colaboradores!)}</span>
                              </Tooltip>
                            </TableCell>
                          )}
                          {descricaoType === DescricaoType.Avaliar && (
                            <TableCell className='table-cell' align='left'>
                              <Tooltip
                                title={
                                  row.Avaliadores !== undefined
                                    ? row.Avaliadores.join(', ')
                                    : 'Esta descrição não tem avaliadores'
                                }
                              >
                                <span>{formatArray(row.Avaliadores!)}</span>
                              </Tooltip>
                            </TableCell>
                          )}
                          {descricaoType === DescricaoType.DescricoesAprovadas && (consultor || servico?.clienteConsultoriaDescricao) && (
                            <TableCell className='table-cell' align='left'>
                              {row.DataAprovacao}
                            </TableCell>
                          )}
                          <TableCell
                            className='table-cell'
                            align='right'
                          >
                            <div className=''>
                              {descricaoType === DescricaoType.Revisar && (
                                <>
                                  <Link
                                    to={`/descricao/visualizar`}
                                    state={{
                                      idDescricao: row.IdDescricao,
                                    }}
                                  >
                                    <span
                                      title='Visualizar'
                                      className='btn btn-icon mx-1 btn-active-color-warning w-25px h-25px'
                                    >
                                      <i className='fas fa-eye fs-2'></i>
                                    </span>
                                  </Link>
                                  {(auth?.servico.tipoPlano === EnumServicoTipoPlano.Try ||
                                    auth?.roles.find((x) => x === 'Master') !== undefined ||
                                    auth?.roles.find((x) => x === 'Administrador') !== undefined ||
                                    auth?.permissoes.find(
                                      (x) => x === '5B42881F9A3C88423E1B16E251773B40'
                                    ) !== undefined) && servico?.clienteConsultoriaDescricao === null && (
                                      <Link
                                        to='/descricao/revisar'
                                        state={{
                                          statusDescricao: statusDescricao,
                                          idCargoState: row.Id,
                                          idModelo: row.IdModelo,
                                          idEmpresa: consultor
                                        }}
                                      >
                                        <span
                                          title='Revisar'
                                          className='btn btn-icon mx-1 btn-active-color-primary w-25px h-25px'
                                        >
                                          <i className={`far fa-file-alt fs-2`}></i>
                                        </span>
                                      </Link>
                                    )}
                                  {auth?.servico.tipoPlano === EnumServicoTipoPlano.Buy && auth.servico.moduloDescricaoConfig.usaAprovacao && (
                                    <>
                                      {(auth?.roles.find((x) => x === 'Master') !== undefined ||
                                        auth?.roles.find((x) => x === 'Administrador') !== undefined ||
                                        (auth?.permissoes.find((x) => x === '854FB94286FD7EF473F9F5F5D74C7DCC') !== undefined && consultor === null) ||
                                        (auth?.permissoes.find((x) => x === '40881629371476181E6567A0110D57AA') !== undefined && consultor !== null)) &&
                                        servico?.clienteConsultoriaDescricao === null && (
                                          <>
                                            {utilizaAvaliador && (
                                              <>
                                                <button
                                                  type='button'
                                                  title='Vincular Avaliadores'
                                                  className='btn btn-icon mx-1 btn-active-color-primary w-25px h-25px'
                                                  onClick={() => {
                                                    setAbrirModalAvaliadores(row.IdDescricao ?? 0)
                                                    setTipoRequestEnviarAvaliacao(EnumTipoEnviarAvaliacao.Unica)
                                                  }
                                                  }
                                                >
                                                  <i className={`fas fa-link fs-2`}></i>
                                                </button>
                                              </>
                                            )}
                                            <button
                                              type='button'
                                              title='Vincular Aprovadores'
                                              className='btn btn-icon mx-1 btn-active-color-primary w-25px h-25px'
                                              onClick={() => {
                                                setAbrirModalAprovadores(row.IdDescricao ?? 0)
                                                setTipoRequestEnviarAprovacao(EnumTipoEnviarAprovacao.Unica)
                                              }
                                              }
                                            >
                                              <i className={`fas fa-link fs-2`}></i>
                                            </button>
                                          </>
                                        )}
                                    </>
                                  )}
                                  {(auth?.roles.find((x) => x === 'Master') !== undefined ||
                                    auth?.roles.find((x) => x === 'Administrador') !== undefined ||
                                    auth?.permissoes.find(
                                      (x) => x === 'B22400C324639DE16D7F0B2B532F8057'
                                    ) !== undefined) && servico?.clienteConsultoriaDescricao === null && (
                                      <button
                                        type='button'
                                        title='Deletar'
                                        className='btn btn-icon mx-1 btn-active-color-danger w-25px h-25px'
                                        onClick={() => DeletarCargoADescrever(row.Id)}
                                      >
                                        <i className={`fas fa-trash-alt fs-2`}></i>
                                      </button>
                                    )}
                                  {auth?.servico.tipoPlano === EnumServicoTipoPlano.Buy && auth.servico.moduloDescricaoConfig.usaAprovacao && (
                                    <>
                                      {(auth?.roles.find((x) => x === 'Master') !== undefined ||
                                        auth?.roles.find((x) => x === 'Administrador') !== undefined ||
                                        auth?.permissoes.find(
                                          (x) => x === '0954F595AA10438B90C18A92D201687D'
                                        ) !== undefined) && servico?.clienteConsultoriaDescricao === null && (
                                          <>
                                            {utilizaAvaliador ? (
                                              <>
                                                <button
                                                  type='button'
                                                  title='Enviar para avaliação'
                                                  className='btn btn-icon mx-1 btn-active-color-primary w-25px h-25px'
                                                  onClick={() => {
                                                    setTipoModalAprovadorAvaliador(EnumModalAvalidorAprovador.Avaliador)
                                                    setAbrirModalAvaliadoresAprovadores(true)
                                                    setIdDescricaoModalSelect(row.IdDescricao ?? 0)
                                                    setTipoRequestEnviarAvaliacao(EnumTipoEnviarAvaliacao.Unica)
                                                  }
                                                  }
                                                >
                                                  <i className={`fas fa-paper-plane fs-2`}></i>
                                                </button>
                                              </>
                                            )
                                              :
                                              (
                                                <>
                                                  <button
                                                    type='button'
                                                    title='Enviar para aprovação'
                                                    className='btn btn-icon mx-1 btn-active-color-primary w-25px h-25px'
                                                    onClick={() => {
                                                      setTipoModalAprovadorAvaliador(EnumModalAvalidorAprovador.Aprovador)
                                                      setAbrirModalAvaliadoresAprovadores(true)
                                                      setIdDescricaoModalSelect(row.IdDescricao ?? 0)
                                                      setTipoRequestEnviarAvaliacao(EnumTipoEnviarAprovacao.Unica)
                                                    }
                                                    }
                                                  >
                                                    <i className={`fas fa-paper-plane fs-2`}></i>
                                                  </button>
                                                </>
                                              )}
                                          </>
                                        )}
                                    </>
                                  )}
                                </>
                              )}
                              {descricaoType === DescricaoType.Avaliar && (
                                <>
                                  <Link
                                    to={`/descricao/visualizar`}
                                    state={{
                                      idDescricao: row.IdDescricao,
                                    }}
                                  >
                                    <span
                                      title='Visualizar'
                                      className='btn btn-icon mx-1 btn-active-color-warning w-25px h-25px'
                                    >
                                      <i className='fas fa-eye fs-2'></i>
                                    </span>
                                  </Link>
                                  {row.IsAvaliador && (auth?.permissoes.find((x) => x === 'F83AC0F344DD8AFE835ABD3DF11092A8' !== undefined)) && (
                                    <>
                                      <button
                                        type='button'
                                        title='Enviar para aprovação'
                                        className='btn btn-icon mx-1 btn-active-color-primary w-25px h-25px'
                                        onClick={() => {
                                          setTipoModalAprovadorAvaliador(EnumModalAvalidorAprovador.Aprovador)
                                          setAbrirModalAvaliadoresAprovadores(true)
                                          setIdDescricaoModalSelect(row.IdDescricao ?? 0)
                                          setTipoRequestEnviarAvaliacao(EnumTipoEnviarAprovacao.Unica)
                                        }
                                        }
                                      >
                                        <i className={`fas fa-paper-plane fs-2`}></i>
                                      </button>
                                    </>
                                  )}
                                  {row.IsAvaliador && (auth?.permissoes.find((x) => x === '3835307EC13BE9946A1BDD7AD6EEC258' !== undefined)) && (
                                    <>
                                      <button
                                        type='button'
                                        title='Reprovar descrição'
                                        className='btn btn-icon mx-1 btn-active-color-primary w-25px h-25px'
                                        onClick={() => {
                                          ReprovarAvaliar(row.IdDescricao ?? 0)
                                        }
                                        }
                                      >
                                        <i className={`fas fa-close fs-2`}></i>
                                      </button>
                                    </>
                                  )}
                                  {row.IsAvaliador && servico?.clienteConsultoriaDescricao && servico?.clienteConsultoriaDescricao.PermiteEditarDescricao && (auth?.permissoes.find((x) => x === 'CCAF640BDFC88B35F188A34ADB171D96') !== undefined) && (
                                    <Link
                                      to='/descricao/revisar'
                                      state={{
                                        statusDescricao: statusDescricao,
                                        idCargoState: row.Id,
                                        idModelo: row.IdModelo,
                                        idDescricao: row.IdDescricao,
                                        isAvaliador: row.IsAvaliador
                                      }}
                                    >
                                      <span
                                        title='Revisar'
                                        className='btn btn-icon mx-1 btn-active-color-primary w-25px h-25px'
                                      >
                                        <i className={`far fa-file-alt fs-2`}></i>
                                      </span>
                                    </Link>
                                  )}
                                </>
                              )}
                              {descricaoType === DescricaoType.Aprovar && (
                                <>
                                  <Link
                                    to={`/descricao/visualizar`}
                                    state={{
                                      idDescricao: row.IdDescricao,
                                    }}
                                  >
                                    <span
                                      title='Visualizar'
                                      className='btn btn-icon mx-1 btn-active-color-warning w-25px h-25px'
                                    >
                                      <i className='fas fa-eye fs-2'></i>
                                    </span>
                                  </Link>
                                  {row.IsAprovador && (auth?.permissoes.find((x) => x === '60E946886866A1943B1CCC0A214FD466' || x === 'B847D84BCACD0DF5405CCE536A99AD58') !== undefined) && (
                                    <>
                                      <Link
                                        to='/descricao/aprovar'
                                        state={{
                                          idDescricao: row.IdDescricao
                                        }}
                                      >
                                        <span
                                          title='Aprovar'
                                          className='btn btn-icon mx-1 btn-active-color-success w-25px h-25px'
                                        >
                                          <i className={`fas fa-user-check fs-2`}></i>
                                        </span>
                                      </Link>
                                    </>
                                  )}
                                  {row.IsAprovador && servico?.clienteConsultoriaDescricao && servico?.clienteConsultoriaDescricao.PermiteEditarDescricao && (auth?.permissoes.find((x) => x === 'CCAF640BDFC88B35F188A34ADB171D96') !== undefined) && (
                                    <Link
                                      to='/descricao/revisar'
                                      state={{
                                        statusDescricao: statusDescricao,
                                        idCargoState: row.Id,
                                        idModelo: row.IdModelo
                                      }}
                                    >
                                      <span
                                        title='Revisar'
                                        className='btn btn-icon mx-1 btn-active-color-primary w-25px h-25px'
                                      >
                                        <i className={`far fa-file-alt fs-2`}></i>
                                      </span>
                                    </Link>
                                  )}
                                </>
                              )}
                              {descricaoType === DescricaoType.DescricoesAprovadas && (
                                <>
                                  <Link
                                    to={`/descricao/visualizar`}
                                    state={{
                                      idDescricao: row.IdDescricao,
                                    }}
                                  >
                                    <span
                                      title='Visualizar'
                                      className='btn btn-icon mx-1 btn-active-color-warning w-25px h-25px'
                                    >
                                      <i className='fas fa-eye fs-2'></i>
                                    </span>
                                  </Link>
                                  {servico?.tipoPlano === EnumServicoTipoPlano.Buy && (
                                    <>
                                      {(auth?.roles.find((x) => x === 'Master') !== undefined ||
                                        auth?.roles.find((x) => x === 'Administrador') !==
                                        undefined ||
                                        servico?.moduloDescricaoConfig.Consultoria ||
                                        auth?.permissoes.find(
                                          (x) => x === '2AF974A722A38B94553688EC135D44DC'
                                        ) !== undefined) && (
                                          <button
                                            type='button'
                                            title='Download'
                                            className='btn btn-icon mx-1 btn-active-color-primary w-25px h-25px'
                                            onClick={() => DownloadDescricao(row.IdDescricao ?? 0)}
                                          >
                                            <i className={`fas fa-download fs-2`}></i>
                                          </button>
                                        )}
                                    </>
                                  )}
                                </>
                              )}
                              {descricaoType === DescricaoType.DescricoesReprovadas && (
                                <>
                                  <Link
                                    to={`/descricao/visualizar`}
                                    state={{
                                      idDescricao: row.IdDescricao,
                                    }}
                                  >
                                    <span
                                      title='Visualizar'
                                      className='btn btn-icon mx-1 btn-active-color-warning w-25px h-25px'
                                    >
                                      <i className='fas fa-eye fs-2'></i>
                                    </span>
                                  </Link>
                                  {(auth?.servico.tipoPlano === EnumServicoTipoPlano.Try ||
                                    auth?.roles.find((x) => x === 'Master') !== undefined ||
                                    auth?.roles.find((x) => x === 'Administrador') !== undefined ||
                                    servico?.moduloDescricaoConfig.Consultoria ||
                                    auth?.permissoes.find(
                                      (x) => x === '0FA6E3A251E9B1A1BD9870095B0D8619'
                                    ) !== undefined) && servico?.clienteConsultoriaDescricao == null && (
                                      <Link
                                        to='/descricao/revisar'
                                        state={{
                                          statusDescricao: statusDescricao,
                                          idCargoState: row.Id,
                                          idModelo: row.IdModelo,
                                          idEmpresa: consultor
                                        }}
                                      >
                                        <span
                                          title='Corrigir'
                                          className='btn btn-icon mx-1 btn-active-color-primary w-25px h-25px'
                                        >
                                          <i className={`fas fa-edit fs-2`}></i>
                                        </span>
                                      </Link>
                                    )}
                                </>
                              )}

                              {(descricaoType === DescricaoType.Avaliar && consultor) && (
                                <>
                                  <button
                                    type='button'
                                    title='Retornar Consultoria'
                                    className='btn btn-icon mx-1 btn-active-color-primary w-25px h-25px'
                                    onClick={() => {
                                      RetornarConsultoria(row.IdDescricao ?? 0)
                                    }
                                    }
                                  >
                                    <i className={`fa fa-undo fs-2`}></i>
                                  </button>
                                </>
                              )}
                              {(descricaoType === DescricaoType.Aprovar && consultor) && (
                                <>
                                  <button
                                    type='button'
                                    title='Retornar Consultoria'
                                    className='btn btn-icon mx-1 btn-active-color-primary w-25px h-25px'
                                    onClick={() => {
                                      RetornarConsultoriaAprovar(row.IdDescricao ?? 0)
                                    }
                                    }
                                  >
                                    <i className={`fa fa-undo fs-2`}></i>
                                  </button>
                                </>
                              )}

                            </div>
                          </TableCell>
                        </TableRow>
                      )
                    })}
                    {emptyRows === 0 && (
                      <TableRow>
                        <TableCell className='table-cell bg-body text-center' colSpan={7}>
                          Nenhum registro encontrado
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                className='table-cell'
                style={{ backgroundColor: colorOpacity, color: titleColor }}
                labelRowsPerPage='linhas por página'
                rowsPerPageOptions={[10, 25]}
                component='div'
                count={total}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </Paper>
          </Box>
        </div>
      </form>
    </>
  )
}

export { TableDescricao }
