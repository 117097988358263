/* eslint-disable jsx-a11y/anchor-is-valid */
import {Link, Navigate, useNavigate} from 'react-router-dom'
import { toAbsoluteUrl } from '../../../../../_metronic/helpers'

type Props = {
  descricao: string
  btnName: string
  btnTo: string
  btnDisabled?: boolean
  imgPath: string
  className?: string
}

const CardInicio = ({descricao, btnName, btnTo, btnDisabled = false, imgPath, className = ''}: Props) => {
  const navigate = useNavigate()

  return (
    <div className={`card card-flush h-md-100 ${className}`}>
    <div
      className='card-body d-flex flex-column justify-content-between mt-9 bgi-no-repeat bgi-size-cover bgi-position-x-center pb-0 min-h-300px'
      style={{
        backgroundPosition: '100% 50%',
        backgroundImage: `url('${toAbsoluteUrl('/media/stock/900x600/42.png')}')`,
      }}
    >
      <div className='row align-items-center'>
        <div className='col-lg-8'>
          <div className='mb-10'>
            <div className='fs-1 fw-bold text-gray-700 text-center mb-13'>
              <span className='me-2' dangerouslySetInnerHTML={{__html: descricao}}></span>
            </div>

            <div className='text-center fs-2'>
              <button type='button' onClick={() => navigate(btnTo)} disabled={btnDisabled} className='btn btn-primary'>{btnName}</button>
            </div>
          </div>
        </div>
        <div className='col-lg-4 d-flex justify-content-center'>
          <img
            className='mx-auto mh-250px'
            src={toAbsoluteUrl(imgPath)}
            alt=''
          />
        </div>
      </div>
    </div>
  </div>
  )
}

export {CardInicio}
