import { FormikProps } from "formik";
import { IWizardModel } from "../helpers/ConfiguracoesIniciaisHelper";

export const createImage = (url:string) => {
    const image = new Image();
    new Promise((resolve, reject) => {
      
      image.addEventListener("load", () => resolve(image));
      image.addEventListener("error", (error) => reject(error));
      image.setAttribute("crossOrigin", "anonymous"); 
      image.src = url;
    });
    return image
}

export function getRadianAngle(degreeValue:any) {
  return (degreeValue * Math.PI) / 180;
}

export function rotateSize(width:any, height:any, rotation:any) {
  const rotRad = getRadianAngle(rotation);

  return {
    width:
      Math.abs(Math.cos(rotRad) * width) + Math.abs(Math.sin(rotRad) * height),
    height:
      Math.abs(Math.sin(rotRad) * width) + Math.abs(Math.cos(rotRad) * height),
  };
}

export default async function getCroppedImg(
  imageSrc:string,
  pixelCrop:any,
  formik: FormikProps<IWizardModel>,
  convertFileToBase64: Function,
  rotation:number = 0,
  flip = { horizontal: false, vertical: false }
) {
  const image = await createImage(imageSrc);
  const canvas = document.createElement("canvas");
  const ctx = canvas.getContext("2d");

  if (!ctx) {
    return null;
  }

  const rotRad = getRadianAngle(rotation);

  const { width: bBoxWidth, height: bBoxHeight } = rotateSize(
    image.width,
    image.height,
    rotation
  );

  // set canvas size to match the bounding box
  canvas.width = bBoxWidth;
  canvas.height = bBoxHeight;

  ctx.translate(bBoxWidth / 2, bBoxHeight / 2);
  ctx.rotate(rotRad);
  ctx.scale(flip.horizontal ? -1 : 1, flip.vertical ? -1 : 1);
  ctx.translate(-image.width / 2, -image.height / 2);

  ctx.drawImage(image, 0, 0);

  const data = ctx.getImageData(
    pixelCrop.x,
    pixelCrop.y,
    pixelCrop.width,
    pixelCrop.height
  );


  canvas.width = pixelCrop.width;
  canvas.height = pixelCrop.height;

  ctx.putImageData(data, 0, 0);

    canvas.toBlob(async (blob) => {
        if (blob) {
            const base64 = await convertFileToBase64(blob)
            formik.setFieldValue('empresaLogo', {
            Nome: formik.values.empresaLogo.Nome,
            Extensao: formik.values.empresaLogo.Extensao,
            Tamanho: blob.size,
            Tipo: formik.values.empresaLogo.Tipo,
            Base64: base64
            })
        }
    }, formik.values.empresaLogo.Tipo);

}