import { FC, useState } from 'react'
import {Field, ErrorMessage, FieldArray, FormikProps} from 'formik'
import { IPesquisaRespostaModel, ITemaModel, IWizardModel, StepProps, defaultTema } from '../helpers/CrudTemaHelper'
import { useAlert, useHttpClient } from '../../../../modules/services/Bundle'
import { RequestMethod } from '../../../../modules/services/core/_enums'
import { AbrirModalVideo } from '../../../toolbar/AbrirModalVideo'
import $ from 'jquery';
import { IconButton } from '@mui/material'
import { KTSVG } from '../../../../../_metronic/helpers'
import { PesquisaAccordions } from '../partials/PesquisaAccordionsCrud'

interface Step3Props {
  formik: FormikProps<IWizardModel>,
  tipoRedirect: string
  numeroStep: number
  indexSelecionado: number | undefined,
  setIndexSelecionado: React.Dispatch<React.SetStateAction<number | undefined>>,
  tituloSelecionado: number | undefined,
  setTituloSelecionado: React.Dispatch<React.SetStateAction<number | undefined>>,
  textoSelecionado: number | undefined,
  setTextoSelecionado: React.Dispatch<React.SetStateAction<number | undefined>>,
  textoSendoEditado: boolean,
  setTextoSendoEditado: React.Dispatch<React.SetStateAction<boolean>>,
  textoBackup: IPesquisaRespostaModel | undefined,
  setTextoBackup: React.Dispatch<React.SetStateAction<IPesquisaRespostaModel | undefined>>,
}

const Step3: FC<Step3Props> = ({
  formik,
  indexSelecionado,
  tipoRedirect,
  setIndexSelecionado,
  tituloSelecionado,
  setTituloSelecionado,
  textoSelecionado,
  setTextoSelecionado,
  textoSendoEditado,
  setTextoSendoEditado,
  textoBackup,
  setTextoBackup,
  numeroStep
}) => {

  const httpClient = useHttpClient()
  const alert = useAlert()

  const resetFieldsToPainel = () => {
    setTituloSelecionado(undefined)
    setTextoSelecionado(undefined)
    setTextoSendoEditado(false)
    setTextoBackup(undefined)
  }

  const onChangeIndexDimensao = (e:any) => {
    const valor = e.target.value
    formik.setFieldValue('dimensaoSelecionada', valor)
    setIndexSelecionado(valor)
    resetFieldsToPainel()
  }

  const onChangeIndexTema = (e:any) => {
    const valor = e.target.value
    const tema = valor == "" ? defaultTema : formik.values.tema.find(t => t.Id == valor)
    formik.setFieldValue('dimensaoSelecionada', '')
    setIndexSelecionado(undefined)
    formik.setFieldValue('temaBuscarDimensao', tema);
  }

  const onClickBuscarPerguntas = async () => {
    if (formik.values.numeroPerguntas > 0 && formik.values.dimensaoSelecionada !== 0) {
      const response = await httpClient.request({
        method: RequestMethod.GET,
        endpoint: '/Pesquisa/ConfiguracoesIniciais/BuscarPerguntas',
        queryObject: {
          idTema: formik.values.temaBuscarDimensao.Id,
          qtdPerguntas: formik.values.numeroPerguntas,
        }
      })
  
      if (response.success && response.payload) {
        const newValues = formik.values.temaBuscarDimensao
        const temas = formik.values.tema

        const tema:ITemaModel = response.payload
        const indexTema = temas.findIndex(x => x.Id == tema.Id);
        temas[indexTema].Dimensao = tema.Dimensao;

        newValues.Dimensao = tema.Dimensao

        formik.setFieldValue('temaBuscarDimensao', newValues)
        formik.setFieldValue('tema', temas);
      }
      
    }
  }

  return (
    <div className='w-100 row'>   
      <div className='col-lg-4 mb-5'>
        <label className='form-label'>Escolha o tema:</label>
        <Field
          as='select'
          name='temaBuscarDimensao.Id'
          className='form-select form-select-lg form-select-solid'
          onChange={(e:any) => onChangeIndexTema(e)}
        >
          <option value=''>Selecione...</option>
          {formik.values.tema && formik.values.tema.map(
            (tema, temaIndex) => (
              <option key={temaIndex} value={tema.Id}>{tema.Nome}</option>
            )
          )}
        </Field>
      </div>
      <div className='col-lg-4 mb-5'>
        <label className='form-label'>Escolha a dimensão para o tema: </label>
        <Field
          as='select'
          name='dimensaoSelecionada'
          className='form-select form-select-lg form-select-solid'
          onChange={(e:any) => onChangeIndexDimensao(e)}
        >
          <option value=''>Selecione...</option>
          {formik.values.temaBuscarDimensao.Dimensao && formik.values.temaBuscarDimensao.Dimensao.map(
            (dimensao, dimensaoIndex) => (
              <option key={dimensaoIndex} value={dimensaoIndex}>{dimensao.Nome}</option>
            )
          )}
        </Field>
      </div>
      {tipoRedirect !== 'Visualizar' && (
        <>
          <div className='col-lg-3 mb-5 text-center'>
            <label className='form-label required'>Nº de perguntas fixo (todas as dimensões)</label>
            <Field 
              name='numeroPerguntas'
              type='number'
              placeholder='Números de perguntas'
              className='form-control form-control-lg form-control-solid'
            />
            <div className='text-danger mt-2'>
              <ErrorMessage name='numeroPerguntas' />
            </div>
          </div>
          <div className='col-lg-1 mb-5'>
            <label className='form-label required'>Buscar</label>
            <div>
              <IconButton 
                className='icon-button-dark-hover' 
                disabled={(!(formik.values.temaBuscarDimensao.Id !== 0 && formik.values.dimensaoSelecionada !== undefined && formik.values.numeroPerguntas !== 0) || formik.values.temaBuscarDimensao.ExistePesquisa)} 
                onClick={() => onClickBuscarPerguntas()}
              >
                <KTSVG path="/media/prolead/pesquisa/icons/search_.svg" className="svg-icon-muted text-gray-800 svg-icon-2hx" />
              </IconButton>
            </div>
          </div>
        </>
      )}
      {indexSelecionado !== undefined && (
        <div className='col-lg-12 mb-5 scroll scroll-height-step-4 h-475px '>
          <PesquisaAccordions
            formik={formik}
            tipoRedirect={tipoRedirect}
            indexDimensaoSelecionada={indexSelecionado}
            numeroStep={numeroStep}
            tituloSelecionado={tituloSelecionado}
            setTituloSelecionado={setTituloSelecionado}
            textoSelecionado={textoSelecionado}
            setTextoSelecionado={setTextoSelecionado}
            textoSendoEditado={textoSendoEditado}
            setTextoSendoEditado={setTextoSendoEditado}
            textoBackup={textoBackup}
            setTextoBackup={setTextoBackup} 
          />
        </div>
      )}
    </div>
  )
}

export {Step3}
