import { ErrorMessage, Field, FormikProvider, useFormik } from 'formik'
import { useEffect, useState } from 'react'
import { KTSVG } from '../../../../../_metronic/helpers'
import { useAlert, useHttpClient } from '../../../../modules/services/Bundle'
import { RequestMethod } from '../../../../modules/services/core/_enums'
import { ISelectModel } from '../../../../modules/services/core/_models'
import { defaultNivel, defaultValidationSchemas } from '../helpers/ModalNivelHelper'

type Props = {
    isConsultoria: boolean
    empresasCliente: ISelectModel[]
    descricaoTipo: string
    abrirModal: boolean
    setAbrirModal: Function
    atualizarDados: Function
    visualizar: boolean
    currentNivel: Data | undefined
}

interface Data {
    Id: number;
    IdEmpresa: number;
    Relevancia: number;
    Nome: string;
    NomeEmpresa: string;
}

const ModalNivelHierarquico = ({isConsultoria, empresasCliente, descricaoTipo, abrirModal, setAbrirModal, atualizarDados, visualizar, currentNivel}: Props) => {
    
    const httpClient = useHttpClient()
    const alert = useAlert()
    const [idEmpresaClienteSelecionada, setIdEmpresaClienteSelecionada] = useState<number>(0)
    
    const formik = useFormik({
        initialValues: defaultNivel,
        validationSchema: defaultValidationSchemas,
        onSubmit: async (values, actions) => {
            alert.createDialog({
                html: `Realmente deseja ${currentNivel ? 'editar' : 'adicionar'} este nível hierárquico?`,
                confirmAction: async () => {                                      
                    
                    const response = await httpClient.request({
                        method: currentNivel ? RequestMethod.PUT : RequestMethod.POST, 
                        endpoint: `/Gerenciamento/NivelHierarquico/${currentNivel ? "Editar" : "Adicionar"}`,
                        queryObject: {
                            isConsultoria: isConsultoria,
                            idEmpresa: idEmpresaClienteSelecionada
                        },
                        data:{
                            Id: currentNivel ? currentNivel.Id : 0,
                            Nome: values.nome,
                            Relevancia: values.relevancia
                        }
                    })
        
                    if (response.success) {
                        setAbrirModal(false)
                        atualizarDados()
                    }
                }
            }) 
    
        },
    })    

    useEffect(() => {
        if(currentNivel){
            formik.setValues({
                nome: currentNivel.Nome,
                relevancia: currentNivel.Relevancia
            })
            setIdEmpresaClienteSelecionada(currentNivel.IdEmpresa)
        }
    }, [currentNivel])

    return (
    <div className={`modal show fade`} tabIndex={-1} id="kt_modal_1" style={{display: "block"}}>
        <div className="modal-dialog modal-dialog-centered modal-xl">
            <div className="modal-content">
            <div className="modal-header">
                <h5 className="modal-title">{descricaoTipo}</h5>
                <div
                onClick={() => setAbrirModal(false)}
                className="btn btn-icon btn-sm btn-active-light-primary ms-2"
                data-bs-dismiss="modal"
                aria-label="Fechar"
                >
                <KTSVG
                    path="/media/icons/duotune/arrows/arr061.svg"
                    className="svg-icon svg-icon-2x"
                />
                </div>
            </div>
            <div className="modal-body">
                
                <FormikProvider value={formik}>
                    {(isConsultoria) && (
                        <>
                            <div className='col-lg-12 fv-row mb-5'>
                                <label className='form-label required'>Cliente</label>
                                <select
                                    className='form-select form-select-lg form-select-solid'
                                    value={idEmpresaClienteSelecionada}
                                    onChange={(e: React.ChangeEvent<HTMLSelectElement>) => { setIdEmpresaClienteSelecionada(parseInt(e.target.value)) }}
                                    disabled={currentNivel !== undefined && currentNivel.Id > 0}
                                >
                                <option value={0}>Selecione...</option>
                                {empresasCliente && empresasCliente.length > 0 && empresasCliente.map((data: ISelectModel, index: number) => {
                                    return (
                                        <option key={`cliente-option-${index}`} value={data.Id}>
                                        {data.Nome}
                                        </option>
                                    )
                                })}
                                </select>
                            </div>
                        </>
                    )} 
                    <div className='w-100'>
                        <div className="col-lg-12 fv-row mb-5">
                            <label className='form-label required'>Nome</label>
                            <Field
                                name={`nome`}
                                placeholder='Nome'
                                className='form-control form-control-lg form-control-solid'
                                maxLength={60}
                                valua={formik.values.nome}
                                disabled={visualizar}
                            />
                            <div className='text-danger mt-2'>
                                <ErrorMessage name='nome' />
                            </div> 
                        </div>     
                    </div>
                    <div className='w-100'>
                        <div className="col-lg-12 fv-row mb-5">
                            <label className='form-label required'>Relevância</label>
                            <Field
                                name={`relevancia`}
                                placeholder='Relevância'
                                className='form-control form-control-lg form-control-solid'
                                maxLength={5}
                                disabled={visualizar}
                            />
                            <label htmlFor="relevancia" className='ps-2 text-muted'>A relevância deve ser cadastrada com números inteiros e deve seguir uma ordem crescente de 1 em 1.</label>
                            <div className='text-danger mt-2'>
                                <ErrorMessage name='relevancia' />
                            </div> 
                        </div>     
                    </div>
                </FormikProvider>
            </div>
            <div className="modal-footer">
                <button
                onClick={() => setAbrirModal(false)}
                type="button"
                className="btn btn-light me-5"
                data-bs-dismiss="modal"
                >
                    Cancelar
                </button>
                
                <button type='button' onClick={() => formik.handleSubmit()} className={`btn btn-primary ${visualizar ? 'd-none' : ''}`}>
                    Salvar
                </button>

            </div>
            </div>
        </div>
    </div>
  )
}

export {ModalNivelHierarquico}