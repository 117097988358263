/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, } from 'react'
import {FormikProps} from 'formik'
import { IWizardModel } from '../helpers/ResponderPesquisaHelper'

interface Step2Props {
  formik: FormikProps<IWizardModel>,
  isMobile: boolean
}

const Step2: FC<Step2Props> = ({formik, isMobile}) => {
  
  return (
    <>
      <div className='w-100'>
        <div className={`row ${isMobile ? 'p-5' : ''}`}>
          <h2 className='card-header bg-pesquisa border-bottom border-secondary border-1 rounded-bottom mb-5 mt-2 text-center p-5 text-white'>Está pesquisa refere-se a: {formik.values.Tema.Nome}</h2>
  
          {formik.values.Topicos.map((topico, topicoIndex) => (
            <div key={topicoIndex} className='col-lg-12 fv-row py-5 rounded-3 bg-light-success p-5 mb-5'>
              <h3>{topico.Texto}</h3>
            </div>
          ))}
        </div>
      </div>
    </>
  );
  
  
}

export {Step2}
