import { FormikProps } from 'formik'
import { KTSVG } from '../../../../../_metronic/helpers'
import { useAlert, useHttpClient } from '../../../../modules/services/Bundle'
import { EnumPesquisaTipoResposta, RequestMethod } from '../../../../modules/services/core/_enums'
import { IPesquisaRespostaModel, IPesquisaRespostaPerguntaModel, IPesquisaValorModel, IWizardModel } from '../helpers/ConfiguracoesIniciaisHelper'
import * as React from 'react';
import * as Yup from 'yup';

import {
  Column as ColumnType,
  Data,
  Task,
} from './data/initialData'
import { useEffect, useState, useRef } from 'react'
import Column from './dndAprimorar/Column'
import { Box } from '@mui/material'
// @hello-pangea/dnd library is a fork of react-beautiful-dnd and enables dnd use with React 18.
import { DragDropContext, DropResult } from '@hello-pangea/dnd'
  
type Props = {
    abrirModal: boolean,
    setAbrirModal: React.Dispatch<React.SetStateAction<boolean>>
    formik: FormikProps<IWizardModel>,
    tipoResposta: number
    dimensaoSelecionada: number | undefined,
    tituloSelecionado: number | undefined,
    setTituloSelecionado: React.Dispatch<React.SetStateAction<number | undefined>>,
    respostaSelecionada: number | undefined,
    setRespostaSelecionada: React.Dispatch<React.SetStateAction<number | undefined>>,
    respostaSendoEditada: boolean,
    setRespostaSendoEditada: React.Dispatch<React.SetStateAction<boolean>>
}

const ModalAprimorarResp= ({abrirModal, setAbrirModal, formik, tipoResposta, dimensaoSelecionada, tituloSelecionado, respostaSelecionada}: Props) => {

    const alert = useAlert()
    const httpClient = useHttpClient()

    let [existentesOriginal, setExistentesOriginal] = React.useState<string[]>([])
    let [sugeridasOriginal, setSugeridasOriginal] = React.useState<string[]>([])
    const [newVerbValue, setNewVerbValue] = React.useState('');
    const [newVerbError, setNewVerbError] = React.useState('');

    
    const schema = Yup.object().shape({
        newVerb: Yup.string()
        .matches(/^[^\d]+$/, 'O verbo não contém números')
        .matches(/^\w+$/, 'Adicione um verbo por vez')
        .test('dupVerb', 'Este verbo está na lista dos sugeridos', (e) => {
            var contem = sugeridasOriginal.includes(e!);
            return !contem;
        }),
    });

    const schemaToSave = Yup.object().shape({
        newVerbToSave: Yup.string()
        .required('Titulo obrigatório')
        .matches(/^[^\d]+$/, 'O verbo não contém números')
        .matches(/^\w+$/, 'Adicione um verbo por vez')
        .test('dupVerb', 'Este verbo está na lista dos sugeridos', (e) => {
            var contem = sugeridasOriginal.includes(e!);
            return !contem;
        })
        .test('validVerb', 'Esta palavra não é um verbo conjugado no infinitivo', async (value) => {
            try {
                const result = await httpClient.request({
                    method: RequestMethod.GET,
                    endpoint: '/Pesquisa/ConfiguracoesIniciais/ValidarVerbo?verbo=' + newVerbValue,
                })
                return result.success;
            } catch (error) {
                console.error('Erro na requisição de validação:', error);
                return false;
            }
        }),
    });

    const handleNewVerbChange = (event: any) => {
        
        setNewVerbValue(event.target.value);
        schema.validate({ newVerb: event.target.value }, { abortEarly: false })
        .then(() => {
            // Validação bem-sucedida, continuar com a lógica de envio
            setNewVerbError(''); // Limpar o erro, se houver
            // Continuar com a lógica de envio
        })
        .catch((validationError) => {
            // Validação falhou, atualizar o estado com os erros
            const errors = validationError.inner.reduce(
              (errorsObj: any, currentError: { path: any; message: any }) => ({
                ...errorsObj,
                [currentError.path]: currentError.message,
              }),
              {}
            );
            setNewVerbError(errors.newVerb);
        });;
    };      

    const handleVerbSubmit = () => {
        schemaToSave
          .validate({ newVerbToSave: newVerbValue }, { abortEarly: false })
          .then(async () => {
            // Validação bem-sucedida, continuar com a lógica de envio
            setNewVerbError(''); // Limpar o erro, se houver
            // Continuar com a lógica de envio

            setSugeridasOriginal(currentArray => [...currentArray, newVerbValue.toLowerCase()]);

            // Clonar o objeto initialDataDefault
            const newData: Data = data;
            // Criar um novo objeto de tarefa
            const newTask = {
                id: newVerbValue.toLowerCase(),
                content: newVerbValue.toLowerCase(),
                situacaoInicial: "existentes"
            };

            // Adicionar a nova tarefa ao objeto tasks
            newData.tasks = {
                [newVerbValue.toLowerCase()]: newTask,
                ...newData.tasks
            };
            

            // Adicionar o ID da nova tarefa à propriedade taskIds da coluna 'sugeridas'
            newData.columns['existentes'].taskIds = [newVerbValue.toLowerCase(), ...newData.columns["existentes"].taskIds];

            // Atualizar o estado com os novos dados
            setData(newData);
            // onDragEnd(dropResult);
          })
          .catch((validationError) => {
            // Validação falhou, atualizar o estado com os erros
            const errors = validationError.inner.reduce(
              (errorsObj: any, currentError: { path: any; message: any }) => ({
                ...errorsObj,
                [currentError.path]: currentError.message,
              }),
              {}
            );
            setNewVerbError(errors.newVerbToSave);
          });
      };
      


        let titulo: IPesquisaRespostaModel | IPesquisaRespostaPerguntaModel | undefined = undefined
        if (tipoResposta === EnumPesquisaTipoResposta.Dimensao) titulo = formik.values.temaBuscarDimensao.Dimensao.find(c => c.Id === tituloSelecionado)
    
        let resposta: IPesquisaRespostaModel | IPesquisaRespostaPerguntaModel | undefined = undefined
        let tituloIndex = tipoResposta === EnumPesquisaTipoResposta.Topico ? formik.values.tema.findIndex((c => c.Id === tituloSelecionado)) : formik.values.temaBuscarDimensao.Dimensao.findIndex((c => c.Id === tituloSelecionado))
        if (tipoResposta === EnumPesquisaTipoResposta.Topico && tituloSelecionado !== undefined && formik.values.tema.length > 0 && tituloIndex >= 0) resposta = formik.values.tema[tituloIndex].Topico.find(c => c.Id === respostaSelecionada) ?? undefined
        if (tipoResposta === EnumPesquisaTipoResposta.Dimensao && tituloSelecionado !== undefined && formik.values.temaBuscarDimensao.Dimensao.length > 0 && tituloIndex >= 0) resposta = formik.values.temaBuscarDimensao.Dimensao[tituloIndex].DimensaoDescricoes.find(c => c.Id === respostaSelecionada) ?? undefined
        if (tipoResposta === EnumPesquisaTipoResposta.Pergunta && dimensaoSelecionada) resposta = formik.values.temaBuscarDimensao.Dimensao[dimensaoSelecionada].Perguntas.find(c => c.Id === respostaSelecionada) ?? undefined
        let [abrirModalAprimorarResp, setAbrirModalAprimorarResp] = React.useState<boolean>(false)
        
        const initialDataDefault: Data = {
            tasks: {},
            columns: {
            'sugeridas': {
                id: 'sugeridas',
                title: 'Ações sugeridas',
                taskIds: []
            },
            'existentes': {
                id: 'existentes',
                title: 'Ações existentes',
                taskIds: []
            }
            },
            columnOrder: ['sugeridas','existentes']
    }
    
    const [data, setData] = useState<Data>(initialDataDefault)
    const isInitialLoad = useRef<boolean>(true)

    useEffect(() => {
        if (isInitialLoad.current) {
        isInitialLoad.current = false
        }
    }, [])

    const onDragEnd = (result: DropResult) => {
        
        const { destination, source, draggableId } = result

        if (
        !destination || //if no destination, i.e. it is dropped outside of a droppable location
        (destination.droppableId === source.droppableId && // if the destination and source columns are the same
            destination.index === source.index) // if the location of the task within the column is the same
        ) {
        return
        }

        // the data is shaped in a way that the key values are the column ids
        const startColumn = data.columns[source.droppableId]
        const finishColumn = data.columns[destination.droppableId]

        // logic for moving within the same column
        if (startColumn === finishColumn) {
        const newTaskIds = [...startColumn.taskIds] //copy of task ids from the start column
        newTaskIds.splice(source.index, 1) //remove the task from the source location
        newTaskIds.splice(destination.index, 0, draggableId) //add the task to the array

        const newColumn = {
            ...startColumn,
            taskIds: newTaskIds,
        } // write all the new tasks to the column object

        setData({
            ...data,
            columns: { ...data.columns, [newColumn.id]: newColumn },
        }) // add the new column to the data object
        return
        }

        // logic for moving between columns
        const startTaskIds = [...startColumn.taskIds] // copy of task ids from the start column
        startTaskIds.splice(source.index, 1) // remove the task from the source location

        const newStartColumn = {
        ...startColumn,
        taskIds: startTaskIds,
        } // create the new source column object

        const finishTaskIds = [...finishColumn.taskIds] // copy of the task ids from the destination column
        finishTaskIds.splice(destination.index, 0, draggableId) // add the task to the array

        const newFinishColumn = {
        ...finishColumn,
        taskIds: finishTaskIds,
        } // create the new destination column object

        setData({
        ...data,
        columns: {
            ...data.columns,
            [newStartColumn.id]: newStartColumn,
            [newFinishColumn.id]: newFinishColumn,
        },
        }) // write both the new source column and new destination column to the data object
    }
    
    const buscarDados = async () => {
        setExistentesOriginal([])
        setSugeridasOriginal([])
        setData(initialDataDefault)
        const response = await httpClient.request({
            method: RequestMethod.GET,
            endpoint: '/Pesquisa/ConfiguracoesIniciais/ObterAcoesResposta',
            queryObject: {
                tipoResposta: tipoResposta,
                texto: resposta?.PesquisaValorAtual.Texto,
                tema: formik.values.temaBuscarDimensao.Nome,
                dimensao: tipoResposta === EnumPesquisaTipoResposta.Pergunta ? formik.values.temaBuscarDimensao.Dimensao[dimensaoSelecionada??0].PesquisaValorAtual.Texto : ''
            }
        })
        if(response.success && response.payload) {
            const initialDataValue:Data = initialDataDefault
            
            if(response.payload.Existentes && response.payload.Existentes.length > 0){
                setExistentesOriginal(response.payload.Existentes)
                response.payload.Existentes.map((dados:string) => (
                    initialDataValue.tasks[dados] = {
                        content: dados,
                        id: dados,
                        situacaoInicial: "existentes"
                    }
                ))
                

                initialDataValue.columns["existentes"].taskIds = response.payload.Existentes
                
            } 
            if(response.payload.Sugeridas && response.payload.Sugeridas.length > 0){
                setSugeridasOriginal(response.payload.Sugeridas)
                
                response.payload.Sugeridas.map((dados:string) => (
                    initialDataValue.tasks[dados] = {
                        id: dados,
                        content: dados,
                        situacaoInicial: "sugeridas"
                    }
                ))
                
                initialDataValue.columns["sugeridas"].taskIds = response.payload.Sugeridas
                
            } 
            setData(initialDataValue)
            setAbrirModalAprimorarResp(abrirModal)
            setAbrirModal(false)
        }
    }

    React.useEffect(() => {
        if (abrirModal) {
            buscarDados()
        }
        
    }, [abrirModal])

    const onClickAprimorarResp = () =>{

        const removidos = existentesOriginal.filter(dados => data.columns["sugeridas"].taskIds.includes(dados))
        const adicionados = sugeridasOriginal.filter(dados => data.columns["existentes"].taskIds.includes(dados))
        if (removidos.length === 0 && adicionados.length === 0) return alert.createMessage({ html: `É necessário adicionar ou remover alguma ação para aprimorar a resposta!`})
        
        alert.createDialog({
            html: `Realmente deseja aprimorar a resposta?`,
            confirmAction: async () => {
            
                const response = await httpClient.request({
                    method: RequestMethod.POST,
                    endpoint: '/Pesquisa/ConfiguracoesIniciais/AprimorarResposta',
                    data: {
                        AcoesParaAdicionar: adicionados,
                        AcoesParaRemover: removidos,
                        TipoResposta: tipoResposta,
                        IdEntidade: resposta?.Id,
                        Texto: resposta?.PesquisaValorAtual.Texto,
                        Tema: formik.values.temaBuscarDimensao.Nome,
                        Dimensao: tipoResposta === EnumPesquisaTipoResposta.Pergunta ? formik.values.temaBuscarDimensao.Dimensao[dimensaoSelecionada??0].PesquisaValorAtual.Texto : ''
                    }
                    })
                    if(response.success && response.payload) {
                        let pesquisa = formik.values
                        let respostaModel:IPesquisaValorModel = response.payload
                        if (tipoResposta === EnumPesquisaTipoResposta.Topico){
                            let tituloIndex = pesquisa.tema.findIndex((c => c.Id === tituloSelecionado))

                            let respIndex = pesquisa.tema[tituloIndex].Topico.findIndex((c => c.Id === respostaSelecionada))
                            
                            pesquisa.tema[tituloIndex].Topico[respIndex].PesquisaValorAtual = respostaModel

                            pesquisa.tema[tituloIndex].Topico[respIndex].PesquisaValores.push(respostaModel)

                            pesquisa.tema[tituloIndex].Topico[respIndex].IdPesquisaValorAtual = respostaModel.Id

                            formik.setValues(pesquisa)
                            setAbrirModalAprimorarResp(false)
                            setAbrirModal(false)
                        }
                        if (tipoResposta === EnumPesquisaTipoResposta.Dimensao){
                            let tituloIndex = pesquisa.temaBuscarDimensao.Dimensao.findIndex((c => c.Id === tituloSelecionado))
                            let respIndex = pesquisa.temaBuscarDimensao.Dimensao[tituloIndex].DimensaoDescricoes.findIndex((c => c.Id === respostaSelecionada))

                            pesquisa.temaBuscarDimensao.Dimensao[tituloIndex].DimensaoDescricoes[respIndex].PesquisaValorAtual = response.payload

                            pesquisa.temaBuscarDimensao.Dimensao[tituloIndex].DimensaoDescricoes[respIndex].PesquisaValores.push(response.payload)

                            pesquisa.temaBuscarDimensao.Dimensao[tituloIndex].DimensaoDescricoes[respIndex].IdPesquisaValorAtual = response.payload.Id

                            formik.setValues(pesquisa)
                            setAbrirModalAprimorarResp(false)
                            setAbrirModal(false)
                        }
                        if (tipoResposta === EnumPesquisaTipoResposta.Pergunta && dimensaoSelecionada){
                            
                            let respIndex = pesquisa.temaBuscarDimensao.Dimensao[dimensaoSelecionada].Perguntas.findIndex((c => c.Id === respostaSelecionada))
                            
                            pesquisa.temaBuscarDimensao.Dimensao[dimensaoSelecionada].Perguntas[respIndex].PesquisaValorAtual = response.payload

                            pesquisa.temaBuscarDimensao.Dimensao[dimensaoSelecionada].Perguntas[respIndex].PesquisaValores.push(response.payload)

                            pesquisa.temaBuscarDimensao.Dimensao[dimensaoSelecionada].Perguntas[respIndex].IdPesquisaValorAtual = response.payload.Id

                            formik.setValues(pesquisa)
                            setAbrirModalAprimorarResp(false)
                            setAbrirModal(false)
                        }
                    }
    
            }
        })
    
    }



    return (
    <div data-testid="modalAprimorarPesquisa" className={`modal ${abrirModalAprimorarResp? "show" : ""} fade`} tabIndex={-1} id="kt_modal_1" style={{display: `${abrirModalAprimorarResp? "block" : "none"}`}}>
        <div className="modal-dialog modal-dialog-centered modal-xl">
            <div className="modal-content">
            <div className="modal-header">
                <h5 className="modal-title">Aprimorar Resposta</h5>
                <div
                onClick={() => setAbrirModalAprimorarResp(false)}
                className="btn btn-icon btn-sm btn-active-light-primary ms-2"
                data-bs-dismiss="modal"
                aria-label="Fechar"
                >
                <KTSVG
                    path="/media/icons/duotune/arrows/arr061.svg"
                    className="svg-icon svg-icon-2x"
                />
                </div>
            </div>
            <div className="modal-body">
                
                    <div className='w-100'>
                        <div className="row">
                            <div className="col-lg-12 fv-row mb-5">
                                <div className='notice d-flex bg-light-success rounded border-success border border-dashed p-6'>
                                    <div className='d-flex flex-stack flex-grow-1'>
                                        <div className='fw-bold'>
                                            <div className='fs-6 text-gray-600 mh-400px overflow-auto'>
                                            {resposta && resposta.PesquisaValorAtual && (
                                                resposta.PesquisaValorAtual.Texto
                                            )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>        
                        </div>
                        <div className="row">
                            <DragDropContext onDragEnd={onDragEnd}>
                                <Box className='row  justify-content-center '>
                                    {data.columnOrder.map((colId) => {
                                    const column: ColumnType = data.columns[colId]
                                    const tasks: Task[] = column.taskIds.map(
                                        (taskId) => data.tasks[taskId]
                                    )
                                    return <Column key={column.id} column={column} tasks={tasks} />
                                    })}
                                </Box>
                            </DragDropContext>
                        </div>
                    </div>
            </div>
            <div className="modal-footer flex-nowrap justify-content-between">
                <div className='flex-wrap flex-column w-50'>
                    <div className='flex-nowrap d-flex align-items-center'>
                        <input
                            className='form-control w-50 me-2'
                            type="text"
                            name="newVerb"
                            id="verbo"
                            value={newVerbValue}
                            placeholder='Insira um verbo'
                            onInput={handleNewVerbChange}
                        />
                        <button 
                        type='button' 
                        className='btn btn-pesquisa'
                        onClick={handleVerbSubmit}
                        >
                            Adicionar Verbo
                        </button>
                    </div>
                    {newVerbError && <div className="text-danger">{newVerbError}</div>}
                </div>
                <div className='w-50 d-flex justify-content-end'>
                    <button
                    onClick={() => setAbrirModalAprimorarResp(false)}
                    type="button"
                    className="btn btn-light me-5"
                    data-bs-dismiss="modal"
                    >
                        Cancelar
                    </button>
                    
                    <button type='button' onClick={() => onClickAprimorarResp()} className='btn btn-pesquisa'>
                        Aprimorar
                    </button>
                </div>

            </div>
            </div>
        </div>
    </div>
  )
}

export {ModalAprimorarResp}
