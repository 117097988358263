import {ErrorMessage, Field, FormikProvider, useFormik} from 'formik'
import {useEffect, useState} from 'react'
import {Link, useLocation, useNavigate} from 'react-router-dom'
import {useAuth} from '../../../../modules/auth'
import {useHttpClient} from '../../../../modules/services/Bundle'
import {RequestMethod} from '../../../../modules/services/core/_enums'
import {
  defaultModulos,
  defaultPerfil,
  defaultValidationSchemas,
  IFuncionalidadeModel,
  IInterfaceModel,
  IModuloModel,
} from '../helpers/CrudPerfilHelper'

interface dados {
  Id?: number
  Acao: string
}

const CrudPerfil = () => {
  const httpClient = useHttpClient()
  const {auth} = useAuth()
  const servico = auth?.servico
  const location = useLocation()
  const state = location.state as dados
  const informacao: dados = state
  const navigate = useNavigate()
  const [tipoAcaoPage, setTipoAcaoPage] = useState('')
  const [modulos, setModulos] = useState<IModuloModel[]>([])

  const formik = useFormik({
    initialValues: defaultPerfil,
    validationSchema: defaultValidationSchemas,
    onSubmit: async (values, actions) => {
      if (tipoAcaoPage === 'Adicionar') {
        const response = await httpClient.request({
          method: RequestMethod.POST,
          endpoint: '/Gerenciamento/Perfil/Salvar',
          data: values,
          ifSuccessRedirectTo: '/configuracao/perfis',
        })
      }

      if (tipoAcaoPage === 'Editar') {
        const response = await httpClient.request({
          method: RequestMethod.PUT,
          endpoint: '/Gerenciamento/Perfil/Editar',
          data: values,
          ifSuccessRedirectTo: '/configuracao/perfis',
        })
      }
    },
  })

  const buscarModulos = async () => {
    const response = await httpClient.request({
      method: RequestMethod.GET,
      endpoint: '/Gerenciamento/Perfil/ObterModulosAtivosPorEmpresa',
    })
    if (response.success && response.payload) {
      setModulos(response.payload)
    }
  }

  const onChangeInputsCheckAll = (e: any) => {
    const checked = e.target.checked
    const idInterface = e.target.getAttribute('data-id')
    const inputsInterfaces = document.querySelectorAll(`.interface-${idInterface}`)

    if (checked) {
      inputsInterfaces.forEach((i: Element) => {
        let input = i as HTMLInputElement
        const id = parseInt(input.id)
        const idFVisualizar = input.getAttribute('data-visualizar')
          ? parseInt(input.getAttribute('data-visualizar')!)
          : undefined
        const idAcao = input.getAttribute('data-acao')
          ? parseInt(input.getAttribute('data-acao')!)
          : undefined
        const newValues = formik.values
        if (
          idFVisualizar &&
          idFVisualizar !== id &&
          formik.values.Permissao.find((x) => x.IdFuncionalidade === idFVisualizar) === undefined
        ) {
          newValues.Permissao.push({
            Id: 0,
            IdPerfil: formik.values.Id ?? 0,
            IdFuncionalidade: idFVisualizar,
            IdFuncionalidadeNavigation: {
              Id: 0,
              IdAcao: idAcao ?? 0,
              IdInterface: idInterface,
            },
          })
          const inputFVisuliazar = document.getElementById(idFVisualizar.toString())
          if (inputFVisuliazar) {
            var novoInput = inputFVisuliazar as HTMLInputElement
            novoInput.checked = true
            novoInput.disabled = true
          }
        }
        if (
          idFVisualizar &&
          idFVisualizar !== id &&
          formik.values.Permissao.find((x) => x.IdFuncionalidade === idFVisualizar) !== undefined
        ) {
          const inputFVisuliazar = document.getElementById(idFVisualizar.toString())
          if (inputFVisuliazar) {
            var novoInput = inputFVisuliazar as HTMLInputElement
            novoInput.checked = true
            novoInput.disabled = true
          }
        }
        if (newValues.Permissao.find((x) => x.IdFuncionalidade === id) === undefined) {
          newValues.Permissao.push({
            Id: 0,
            IdPerfil: formik.values.Id ?? 0,
            IdFuncionalidade: id,
            IdFuncionalidadeNavigation: {
              Id: 0,
              IdAcao: idAcao ?? 0,
              IdInterface: idInterface,
            },
          })
        }
        formik.setValues(newValues)
        return (input.checked = true)
      })
    } else {
      inputsInterfaces.forEach((i: Element) => {
        let input = i as HTMLInputElement
        const id = parseInt(input.id)
        const newValues = formik.values
        const permissoes = newValues.Permissao.filter((x) => x.IdFuncionalidade !== id)
        newValues.Permissao = permissoes
        formik.setValues(newValues)
        input.disabled = false
        return (input.checked = false)
      })
    }
  }

  const onChangeInputsCheck = (
    e: any,
    idFVisualizar: number | undefined,
    idAcao: number | undefined,
    idInterface: number
  ) => {
    const valor = e.target.checked
    const id = parseInt(e.target.id)

    if (valor) {
      const newValues = formik.values
      if (
        idFVisualizar &&
        idFVisualizar !== id &&
        formik.values.Permissao.find((x) => x.IdFuncionalidade === idFVisualizar) === undefined
      ) {
        newValues.Permissao.push({
          Id: 0,
          IdPerfil: formik.values.Id ?? 0,
          IdFuncionalidade: idFVisualizar,
          IdFuncionalidadeNavigation: {
            Id: 0,
            IdAcao: idAcao ?? 0,
            IdInterface: idInterface,
          },
        })
        const inputFVisuliazar = document.getElementById(idFVisualizar.toString())
        if (inputFVisuliazar) {
          var novoInput = inputFVisuliazar as HTMLInputElement
          novoInput.checked = true
        }
      }
      if (
        idFVisualizar &&
        idFVisualizar !== id &&
        formik.values.Permissao.find((x) => x.IdFuncionalidade === idFVisualizar) !== undefined
      ) {
        const inputFVisuliazar = document.getElementById(idFVisualizar.toString())
        if (inputFVisuliazar) {
          var novoInput = inputFVisuliazar as HTMLInputElement
          novoInput.checked = true
        }
      }
      if (newValues.Permissao.find((x) => x.IdFuncionalidade === id) === undefined) {
        newValues.Permissao.push({
          Id: 0,
          IdPerfil: formik.values.Id ?? 0,
          IdFuncionalidade: id,
          IdFuncionalidadeNavigation: {
            Id: 0,
            IdAcao: idAcao ?? 0,
            IdInterface: idInterface,
          },
        })
      }
      formik.setValues(newValues)
    } else {
      const newValues = formik.values
      const permissoes = newValues.Permissao.filter((x) => x.IdFuncionalidade !== id)
      newValues.Permissao = permissoes
      formik.setValues(newValues)
    }
  }

  const buscarPorId = async (id: number) => {
    const response = await httpClient.request({
      method: RequestMethod.GET,
      endpoint: '/Gerenciamento/Perfil/ObterPorId',
      queryObject: {
        IdPerfil: id,
      },
    })
    if (response.success && response.payload) {
      formik.setValues(response.payload)
    }
  }

  useEffect(() => {
    const realizarRequest = async () => {
      if (informacao && informacao.Acao && informacao.Acao !== '') {
        setTipoAcaoPage(informacao.Acao)
      }

      if (informacao && informacao.Id) {
        await buscarPorId(informacao.Id)
      }

      await buscarModulos()
    }

    realizarRequest()
  }, [])

  return (
    <>
      <div className='card mb-xl-10'>
        <div className='card-header'>
          <div className='card-title m-0'>
            <h3 className='fw-bolder m-0'>{tipoAcaoPage} perfil</h3>
          </div>
        </div>

        <div className='card-body'>
          <FormikProvider value={formik}>
            <div className='w-100'>
              <div className='row'>
                <div className='col-lg-12 fv-row mb-5'>
                  <label className='form-label required'>Nome</label>
                  <Field
                    name={`Nome`}
                    placeholder='Nome'
                    className='form-control form-control-lg form-control-solid'
                    maxLength={150}
                    value={formik.values.Nome}
                    disabled={tipoAcaoPage === 'Visualizar' ? true : false}
                  />
                  <div className='text-danger mt-2'>
                    <ErrorMessage name='Nome' />
                  </div>
                </div>

                <div className='col-lg-12 fv-row mb-5'>
                  <div className='w-100 d-flex align-items-center'>
                    <label className='form-label m-0'>Módulos </label>
                    <label style={{width: '100%'}} className='ms-2 d-flex justify-content-end '>
                      {' '}
                      <hr className='m-0' style={{width: '100%'}} />{' '}
                    </label>
                  </div>
                  <ul className='nav nav-tabs nav-line-tabs mb-5 fs-6'>
                    {modulos &&
                      modulos.length > 0 &&
                      modulos.map((data: any, index: any) => {
                        return (
                          <li key={`li-${index}`} className='nav-item'>
                            <a
                              className={`nav-link ${index === 0 ? 'active' : ''}`}
                              data-bs-toggle='tab'
                              href={`#kt_tab_${data.Id}`}
                            >
                              {data.Nome}
                            </a>
                          </li>
                        )
                      })}
                  </ul>
                  <div className='tab-content' id='myTabContent'>
                    <div className='w-100 d-flex align-items-center'>
                      <label className='form-label m-0'>Interfaces </label>
                      <label style={{width: '100%'}} className='ms-2 d-flex justify-content-end '>
                        {' '}
                        <hr className='m-0' style={{width: '100%'}} />{' '}
                      </label>
                    </div>
                    {modulos &&
                      modulos.length > 0 &&
                      modulos.map((data: any, index: any) => {
                        return (
                          <div
                            key={`div-${index}`}
                            className={`tab-pane fade ${index === 0 ? 'active show' : ''}`}
                            id={`kt_tab_${data.Id}`}
                            role='tabpanel'
                          >
                            {data.Interface &&
                              data.Interface.length > 0 &&
                              data.Interface.map((interfaceDados: IInterfaceModel, index: any) => {
                                if (
                                  interfaceDados.Funcionalidade &&
                                  interfaceDados.Funcionalidade.length > 0
                                ) {
                                  return (
                                    <div
                                      key={`accordion-${index}`}
                                      className='accordion mb-2'
                                      id={`kt_accordion_1${interfaceDados.Id}`}
                                    >
                                      <div className='accordion-item'>
                                        <h2
                                          className='accordion-header'
                                          id={`kt_accordion_1_header_${interfaceDados.Id}`}
                                        >
                                          <button
                                            className='accordion-button fs-4 fw-bold collapsed'
                                            type='button'
                                            data-bs-toggle='collapse'
                                            data-bs-target={`#kt_accordion_1_body_${interfaceDados.Id}`}
                                            aria-expanded='false'
                                            aria-controls={`kt_accordion_1_body_${interfaceDados.Id}`}
                                          >
                                            {interfaceDados.Nome}
                                          </button>
                                        </h2>
                                        <div
                                          id={`kt_accordion_1_body_${interfaceDados.Id}`}
                                          className='accordion-collapse collapse'
                                          aria-labelledby={`kt_accordion_1_header_${interfaceDados.Id}`}
                                          data-bs-parent={`#kt_accordion_${interfaceDados.Id}`}
                                        >
                                          <div className='accordion-body  pt-0 row'>
                                            <div className='col-lg-4 fv-row'>
                                              <label className='form-label'></label>
                                              <div className='form-check form-switch form-check-custom form-check-solid d-flex justify-content-between m-0 border border-1 rounded px-5 py-2'>
                                                <label className='form-label m-0'>
                                                  Selecionar todos
                                                </label>
                                                <input
                                                  type='checkbox'
                                                  name={`interface.${interfaceDados.Id}`}
                                                  id={interfaceDados.Id.toString()}
                                                  onChange={onChangeInputsCheckAll}
                                                  className={`form-check-input h-25px w-40px interface-${interfaceDados.Id}`}
                                                  data-id={interfaceDados.Id}
                                                ></input>
                                              </div>
                                            </div>
                                            <div className='col-lg-8'></div>

                                            {interfaceDados.Funcionalidade &&
                                              interfaceDados.Funcionalidade.length > 0 &&
                                              interfaceDados.Funcionalidade.map(
                                                (
                                                  funcionalidade: IFuncionalidadeModel,
                                                  index: any
                                                ) => {
                                                  const idFVisualizar =
                                                    interfaceDados.Funcionalidade.find(
                                                      (x) => x.IdAcao === 3
                                                    )?.Id
                                                  const idAcao = interfaceDados.Funcionalidade.find(
                                                    (x) => x.IdAcao === 3
                                                  )?.IdAcao
                                                  return (
                                                    <div
                                                      key={`funcionalidade-${index}`}
                                                      className='col-lg-4 fv-row'
                                                    >
                                                      <label className='form-label'></label>
                                                      <div className='form-check form-switch form-check-custom form-check-solid d-flex justify-content-between m-0 border border-1 rounded px-5 py-2'>
                                                        <label className='form-label m-0'>
                                                          {funcionalidade.IdAcaoNavigation?.Nome}
                                                        </label>
                                                        <input
                                                          type='checkbox'
                                                          name={`funcionalidade.${funcionalidade.Id}`}
                                                          id={funcionalidade.Id.toString()}
                                                          onChange={(e) =>
                                                            onChangeInputsCheck(
                                                              e,
                                                              idFVisualizar,
                                                              idAcao,
                                                              funcionalidade.IdInterface
                                                            )
                                                          }
                                                          data-visualizar={idFVisualizar}
                                                          data-acao={idAcao}
                                                          data-interface={
                                                            funcionalidade.IdInterface
                                                          }
                                                          defaultChecked={
                                                            (tipoAcaoPage === 'Editar' ||
                                                              tipoAcaoPage === 'Visualizar') &&
                                                            formik.values.Permissao &&
                                                            formik.values.Permissao.find(
                                                              (x) =>
                                                                x.IdFuncionalidade ===
                                                                funcionalidade.Id
                                                            ) !== undefined
                                                              ? true
                                                              : false
                                                          }
                                                          className={`form-check-input h-25px w-40px interface-${interfaceDados.Id}`}
                                                          disabled={
                                                            (idFVisualizar &&
                                                              funcionalidade.Id === idFVisualizar &&
                                                              formik.values.Permissao.filter(
                                                                (x) =>
                                                                  x.IdFuncionalidadeNavigation
                                                                    ?.IdInterface ===
                                                                  funcionalidade.IdInterface
                                                              ).length > 1) ||
                                                            tipoAcaoPage === 'Visualizar'
                                                              ? true
                                                              : false
                                                          }
                                                        ></input>
                                                      </div>
                                                    </div>
                                                  )
                                                }
                                              )}
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  )
                                }
                              })}
                          </div>
                        )
                      })}
                  </div>
                </div>
              </div>
            </div>
          </FormikProvider>
        </div>
        <div className='card-footer py-4 d-flex justify-content-end'>
          <button
            type='button'
            className='btn btn-lg btn-secondary me-5'
            onClick={() => navigate(-1)}
          >
            Voltar
          </button>
          {tipoAcaoPage !== 'Visualizar' && tipoAcaoPage !== '' && (
            <button
              type='button'
              onClick={() => formik.handleSubmit()}
              className='btn btn-lg btn-primary'
            >
              Salvar
            </button>
          )}
        </div>
      </div>
    </>
  )
}

export {CrudPerfil}
