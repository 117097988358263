/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useRef, useState } from 'react'
import { FormikProps } from 'formik'
import { useThemeMode } from '../../../../../_metronic/partials'
import { IDescreverDescricaoModel, IDescreverRespostaModel } from '../helpers/DescreverHelper'
import { Draggable } from 'drag-react'
import { Tooltip, useAlert, useHttpClient } from '../../../../modules/services/Bundle'
import { toAbsoluteUrl } from '../../../../../_metronic/helpers'
import { EnumServicoTipoPlano, RequestMethod } from '../../../../modules/services/core/_enums'
import { useAuth } from '../../../../modules/auth'

type Props = {
  mostrarPainel: boolean
  formik: FormikProps<IDescreverDescricaoModel>,
  campoSelecionado: number | undefined,
  setCampoSelecionado: React.Dispatch<React.SetStateAction<number | undefined>>,
  respostaSelecionada: number | undefined,
  setRespostaSelecionada: React.Dispatch<React.SetStateAction<number | undefined>>,
  respostaSendoEditada: boolean,
  setRespostaSendoEditada: React.Dispatch<React.SetStateAction<boolean>>,
  respostaBackup: IDescreverRespostaModel | undefined,
  setRespostaBackup: React.Dispatch<React.SetStateAction<IDescreverRespostaModel | undefined>>,
  abrirModalAddResp: boolean,
  setAbrirModalAddResp: React.Dispatch<React.SetStateAction<boolean>>,
  abrirModalArgumentarResp: boolean,
  setAbrirModalArgumentarResp: React.Dispatch<React.SetStateAction<boolean>>,
  abrirModalAprimorarResp: boolean,
  setAbrirModalAprimorarResp: React.Dispatch<React.SetStateAction<boolean>>
}

const DescricaoPainel: React.FC<Props> = ({
  mostrarPainel,
  formik,
  campoSelecionado,
  setCampoSelecionado,
  respostaSelecionada,
  setRespostaSelecionada,
  respostaSendoEditada,
  setRespostaSendoEditada,
  respostaBackup,
  setRespostaBackup,
  abrirModalAddResp,
  setAbrirModalAddResp,
  abrirModalArgumentarResp,
  setAbrirModalArgumentarResp,
  abrirModalAprimorarResp,
  setAbrirModalAprimorarResp
}) => {
  const {menuMode} = useThemeMode() 
  const [isMobile, setIsMobile] = useState(window.innerWidth < 992)   
  const btnMode = menuMode === 'light' ? 'btn-descricao' : 'btn-light'
  const campo = formik.values.Campos.find(c => c.Id === campoSelecionado)
  const resposta = campo?.Respostas.find(c => c.Id === respostaSelecionada) ?? undefined
  const httpClient = useHttpClient()
  const alert = useAlert()
  const {auth} = useAuth()
  
  useEffect(() => {

    const checkIsMobile = () => {
      setIsMobile(window.innerWidth < 992)
    };

    window.addEventListener("resize", checkIsMobile);

    return () => window.removeEventListener("resize", checkIsMobile) 

  }, [])

  const onClickEditar = () =>{

    setRespostaBackup(resposta)
    setRespostaSendoEditada(true)    

  }

  const onClickSalvar = () =>{

    let descricao = formik.values
    let campoIndex = descricao.Campos.findIndex((c => c.Id === campoSelecionado))
    let respIndex = descricao.Campos[campoIndex].Respostas.findIndex((r => r.Id === respostaSelecionada))

    if(respostaBackup && formik.values.Campos[campoIndex].Respostas[respIndex].ValorAtual?.Texto === respostaBackup.ValorAtual?.Texto){
      setRespostaBackup(undefined)
      setRespostaSendoEditada(false)
    }
    else{

      alert.createDialog({
        html: `Realmente deseja salvar a resposta editada?`,
        confirmAction: async () => {
          
          const response = await httpClient.request({
            method: RequestMethod.GET,
            endpoint: '/DescricaoDeCargo/Descrever/SalvarResposta',
            queryObject: {
              idResposta: respostaSelecionada,
              texto: resposta?.ValorAtual?.Texto
            }
          })
  
          if(response.success && response.payload) {
  
            descricao.Campos[campoIndex].Respostas[respIndex].ValorAtual = response.payload
  
            descricao.Campos[campoIndex].Respostas[respIndex].Valores.push(response.payload)
  
            descricao.Campos[campoIndex].Respostas[respIndex].IdValorAtual = response.payload.Id

            formik.setValues(descricao)  
            setRespostaBackup(undefined)
            setRespostaSendoEditada(false)
            
          }
  
        }
      })

    }

  }

  const onClickAnterior = async () =>{
    const response = await httpClient.request({
      method: RequestMethod.GET,
      endpoint: '/DescricaoDeCargo/Descrever/AnteriorValorResposta',
      queryObject: {
        idResposta: respostaSelecionada
      }
    })

    if(response.success && response.payload) {
      let descricao = formik.values
      let campoIndex = descricao.Campos.findIndex((c => c.Id === campoSelecionado))
      let respIndex = descricao.Campos[campoIndex].Respostas.findIndex((r => r.Id === respostaSelecionada))
      
      descricao.Campos[campoIndex].Respostas[respIndex].ValorAtual = response.payload

      descricao.Campos[campoIndex].Respostas[respIndex].IdValorAtual = response.payload.Id

      formik.setValues(descricao)
    }

  }

  const onClickProximo = async () =>{
    const response = await httpClient.request({
      method: RequestMethod.GET,
      endpoint: '/DescricaoDeCargo/Descrever/ProximoValorResposta',
      queryObject: {
        idResposta: respostaSelecionada
      }
    })

    if(response.success && response.payload) {
      let descricao = formik.values
      let campoIndex = descricao.Campos.findIndex((c => c.Id === campoSelecionado))
      let respIndex = descricao.Campos[campoIndex].Respostas.findIndex((r => r.Id === respostaSelecionada))
      
      descricao.Campos[campoIndex].Respostas[respIndex].ValorAtual = response.payload

      descricao.Campos[campoIndex].Respostas[respIndex].IdValorAtual = response.payload.Id

      formik.setValues(descricao)
    }

  }

  const onClickExcluir = () =>{

    alert.createDialog({
      html: `Realmente deseja excluir essa resposta?`,
      confirmAction: async () => {
        
        const response = await httpClient.request({
          method: RequestMethod.GET,
          endpoint: '/DescricaoDeCargo/Descrever/ExcluirResposta',
          queryObject: {
            idResposta: respostaSelecionada
          }
        })

        if(response.success) {

          let descricao = formik.values
          let campoIndex = descricao.Campos.findIndex((c => c.Id === campoSelecionado))
          descricao.Campos[campoIndex].Respostas = descricao.Campos[campoIndex].Respostas.filter(r => r.Id !== respostaSelecionada)
          formik.setValues(descricao)

          setRespostaSelecionada(undefined)
          setRespostaBackup(undefined)
          setRespostaSendoEditada(false)
          
        }

      }
    })

  }
  
  return (
    <Draggable className={`painel-descricao ${isMobile ? 'painel-descricao-mobile' : 'painel-descricao-desktop'} ${mostrarPainel ? '' : 'd-none'}`}  style={{ left: 'calc(70% - 277.375px)', top: '200px', zIndex: 11  }}>

      <div className={`painel-descricao-action`}>

        <span className='painel-descricao-botao-ia'>
          <img className='draggable-false-image' draggable={false} src={toAbsoluteUrl("/media/prolead/descricao/botao_IA.png")} width="50px" height="50px" alt="botao-ia" />
        </span>

        {(auth?.servico.tipoPlano === EnumServicoTipoPlano.Try || ((auth?.roles.find(x => x === 'Master') !== undefined) || (auth?.roles.find(x => x === 'Administrador') !== undefined) || (auth?.permissoes.find(x => x === 'E91DE97D46F24FD2CCE4616296526F9E') !== undefined))) && (                                    
          <>
            <Tooltip title={'Anterior'}>
              <div className='btn-painel-descricao border-painel-descricao-left'>
                <button 
                  type="button"
                  onClick={() => onClickAnterior()} 
                  disabled={!resposta || (resposta && resposta.Valores.length > 0 && resposta.IdValorAtual === resposta.Valores[0].Id) || (resposta && respostaSendoEditada)} 
                  className={`btn btn-icon ${isMobile ? 'btn-sm' : 'btn-md'} m-2 ${btnMode}`}
                >
                  <i className="fs-2 fas fa-arrow-left"></i>
                </button>
              </div>                 
            </Tooltip> 

            <Tooltip title={'Aprimorar'}>
              <div className='btn-painel-descricao border-painel-descricao-top'>
                <button 
                  type="button" 
                  onClick={() => setAbrirModalAprimorarResp(true)}
                  disabled={(campo && !campo.TipoCampo.InteracaoComAI) || !resposta || (resposta && resposta.Valores.length > 0 && resposta.IdValorAtual !== resposta.Valores[resposta.Valores.length - 1].Id) || (resposta && respostaSendoEditada)} 
                  className={`btn btn-icon ${isMobile ? 'btn-sm' : 'btn-md'} m-2 ${btnMode}`}
                >
                  <i className="fs-2 fas fa-arrow-up-wide-short"></i>
                </button> 
              </div>               
            </Tooltip>  

            <Tooltip title={'Próxima'}>
              <div className='btn-painel-descricao border-painel-descricao-top'>
                <button 
                  type="button" 
                  onClick={() => onClickProximo()} 
                  disabled={!resposta || (resposta && resposta.Valores.length > 0 && resposta.IdValorAtual === resposta.Valores[resposta.Valores.length - 1].Id) || (resposta && respostaSendoEditada)} 
                  className={`btn btn-icon ${isMobile ? 'btn-sm' : 'btn-md'} m-2 ${btnMode}`}
                >
                  <i className="fs-2 fas fa-arrow-right"></i>
                </button> 
              </div>               
            </Tooltip>  
          </>
        )}

        {(auth?.servico.tipoPlano === EnumServicoTipoPlano.Try || ((auth?.roles.find(x => x === 'Master') !== undefined) || (auth?.roles.find(x => x === 'Administrador') !== undefined) || (auth?.permissoes.find(x => x === 'B8833CFB9D8DB3D3658A89C87B9FE4E5') !== undefined))) && (                                    
          <>
            <Tooltip title={'Adicionar'}>
              <div className='btn-painel-descricao border-painel-descricao-top'>
                <button 
                  type="button" 
                  onClick={() => setAbrirModalAddResp(true)}
                  disabled={!resposta && !campo || (campo && campo.Respostas.length > 0 && !campo.TipoCampo.MultiplasRespostas) || (resposta && respostaSendoEditada)} 
                  className={`btn btn-icon ${isMobile ? 'btn-sm' : 'btn-md'} m-2 ${btnMode}`}
                >
                  <i className="fs-2 fas fa-plus"></i>
                </button>             
              </div>        
            </Tooltip>  
          </>
        )}

        {(auth?.servico.tipoPlano === EnumServicoTipoPlano.Try || ((auth?.roles.find(x => x === 'Master') !== undefined) || (auth?.roles.find(x => x === 'Administrador') !== undefined) || (auth?.permissoes.find(x => x === 'E53833CD3514289FF1B990C865640A73') !== undefined))) && (                                    
          <>
            <Tooltip title={'Excluir'}>
              <div className='btn-painel-descricao border-painel-descricao-top'>
                <button 
                  type="button" 
                  disabled={!resposta || (resposta && respostaSendoEditada)} 
                  className={`btn btn-icon ${isMobile ? 'btn-sm' : 'btn-md'} m-2 ${btnMode}`}
                  onClick={onClickExcluir}
                >
                  <i className="fs-2 fas fa-trash"></i>
                </button>    
              </div>    
            </Tooltip> 
          </>
        )}  

        {(auth?.servico.tipoPlano === EnumServicoTipoPlano.Try || ((auth?.roles.find(x => x === 'Master') !== undefined) || (auth?.roles.find(x => x === 'Administrador') !== undefined) || (auth?.permissoes.find(x => x === '0E656548A74B142315F2A7114BB30575') !== undefined))) && (                                    
          <>
            <Tooltip title={'Argumentar'}>
              <div className='btn-painel-descricao border-painel-descricao-top'>
                <button 
                  type="button"
                  onClick={() => setAbrirModalArgumentarResp(true)}
                  disabled={(campo && !campo.TipoCampo.InteracaoComAI) || !resposta || (resposta && resposta.Valores.length > 0 && resposta.IdValorAtual !== resposta.Valores[resposta.Valores.length - 1].Id) || (resposta && respostaSendoEditada)} 
                  className={`btn btn-icon ${isMobile ? 'btn-sm' : 'btn-md'} m-2 ${btnMode}`}
                >
                  <i className="fs-2 fas fa-comment-dots"></i>
                </button>
              </div>                
            </Tooltip>  
          </>
        )}

        {(auth?.servico.tipoPlano === EnumServicoTipoPlano.Try || ((auth?.roles.find(x => x === 'Master') !== undefined) || (auth?.roles.find(x => x === 'Administrador') !== undefined) || (auth?.permissoes.find(x => x === 'E6435ED5C41520469A0472C4923ACBD0') !== undefined))) && (                                    
          <>
            <Tooltip title={'Editar'}>
              <div className='btn-painel-descricao border-painel-descricao-top'>            
                <button 
                  type="button" 
                  onClick={() => onClickEditar()}
                  disabled={!resposta || (resposta && resposta.Valores.length > 0 && resposta.IdValorAtual !== resposta.Valores[resposta.Valores.length - 1].Id) || (resposta && respostaSendoEditada)} 
                  className={`btn btn-icon ${isMobile ? 'btn-sm' : 'btn-md'} m-2 ${btnMode}`}
                >
                  <i className="fs-2 fas fa-edit"></i>
                </button> 
              </div>     
            </Tooltip>  
          </>
        )}

        {(auth?.servico.tipoPlano === EnumServicoTipoPlano.Try || ((auth?.roles.find(x => x === 'Master') !== undefined) || (auth?.roles.find(x => x === 'Administrador') !== undefined) || (auth?.permissoes.find(x => x === '9325790079D4AF50249EE2262AC90AEE') !== undefined))) && (                                    
          <>
            <Tooltip title={'Salvar'}>
              <div className='btn-painel-descricao border-painel-descricao-right'>
                <button 
                  type="button" 
                  onClick={() => onClickSalvar()}
                  disabled={!resposta || (resposta && !respostaSendoEditada)} 
                  className={`btn btn-icon ${isMobile ? 'btn-sm' : 'btn-md'} m-2 ${btnMode}`}
                >
                  <i className="fs-2 fas fa-save"></i>
                </button>    
              </div>              
            </Tooltip>  
          </>
        )}

      </div>
    </Draggable>    
  )
}

export {DescricaoPainel}
