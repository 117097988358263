/* eslint-disable react-hooks/exhaustive-deps */
import * as React from 'react'
import Box from '@mui/material/Box'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TablePagination from '@mui/material/TablePagination'
import TableRow from '@mui/material/TableRow'
import TableSortLabel from '@mui/material/TableSortLabel'
import Paper from '@mui/material/Paper'
import {visuallyHidden} from '@mui/utils'
import {Tooltip, useAlert, useHelper, useHttpClient} from '../../../../modules/services/Bundle'
import {EnumServicoTipoPlano, RequestMethod} from '../../../../modules/services/core/_enums'
import {Link, useNavigate} from 'react-router-dom'
import {useAuth} from '../../../../modules/auth'
import { KTSVG } from '../../../../../_metronic/helpers'
import { ModalVisualizarParticipante } from './ModalVisualizarParticipante'
import { IconButton } from '@mui/material'
import { ModalDetalhesPesquisaParticipante } from './ModalDetalhesPesquisaParticipante'

type Props = {
  title: string
  buscar: boolean
  setDadosDash: Function
  color: string
  titleColor: string
  statusPesquisa: StatusPesquisa
}

interface Column {
  id: 'Id' | 'Nome' | 'QtdDimensoes' | 'QtdPerguntas' | 'DataInicialString'
  label: string
  minWidth?: number
  align?: 'center'
  format?: (value: number) => string
}

export enum StatusPesquisa {
  NaoLiberada = 1,
  NaoIniciada = 2,
  EmAndamento = 3,
  Suspensa = 4,
  Finalizada = 5
}

const columns: readonly Column[] = [
  {
    id: 'Nome',
    label: 'PESQUISA',
  },
  {
    id: 'QtdDimensoes',
    label: 'QTDE. DIMENSÕES',
  },
  {
    id: 'QtdPerguntas',
    label: 'QTDE. PERGUNTAS',
  },
  {
    id: 'DataInicialString',
    label: 'PERÍODO DA PESQUISA',
  },
]

interface Data {
  Id: number
  Nome: string
  QtdDimensoes: number
  QtdPerguntas: number
  DataInicialString: string
  DataFinalString: string
}

function createData(
  Id: number,
  Nome: string,
  QtdDimensoes: number,
  QtdPerguntas: number,
  DataInicialString: string,
  DataFinalString: string
): Data {
  return {Id, Nome, QtdDimensoes, QtdPerguntas, DataInicialString, DataFinalString}
}

type Order = 'asc' | 'desc'

interface EnhancedTableProps {
  numSelected: number
  onRequestSort: (event: React.MouseEvent<unknown>, property: keyof Data) => void
  onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void
  order: Order
  orderBy: string
}

const TablePesquisa: React.FC<Props> = ({
  title,
  buscar,
  titleColor,
  color,
  setDadosDash,
  statusPesquisa
}) => {
  const {auth} = useAuth()
  const servico = auth?.servico
  const httpClient = useHttpClient()
  const [order, setOrder] = React.useState<Order>('asc')
  const [orderBy, setOrderBy] = React.useState<keyof Data>('Nome')
  const [selected, setSelected] = React.useState<readonly string[]>([])
  const [page, setPage] = React.useState(0)
  const [rowsPerPage, setRowsPerPage] = React.useState(10)
  const [idPesquisa, setIdPesquisa] = React.useState(0)
  const [total, setTotal] = React.useState(0)
  const [colorOpacity] = React.useState(color + '80')
  const [rows, setRows] = React.useState<Data[]>([])
  const alert = useAlert()
  const navigate = useNavigate()
  const [showModal, setShowModal] = React.useState(false)
  const [showModalDetalhes, setShowModalDetalhes] = React.useState(false)
  const [idParticipante, setIdParticipante] = React.useState(0)
  const [statusParticipante, setStatusParticipante] = React.useState(0)
  const [nomeParticipante, setNomeParticipante] = React.useState('')

  function EnhancedTableHead(props: EnhancedTableProps) {
    const {order, orderBy, onRequestSort} = props
    const createSortHandler = (property: keyof Data) => (event: React.MouseEvent<unknown>) => {
      onRequestSort(event, property)
    }

    return (
      <TableHead>
        <TableRow style={{backgroundColor: colorOpacity}}>
          {columns.map((columns) => (
            <TableCell
              style={{color: titleColor}}
              key={columns.id}
              align={columns.align ? 'right' : 'left'}
            >
              <TableSortLabel
                className='fw-bolder table-cell'
                active={orderBy === columns.id}
                direction={orderBy === columns.id ? order : 'asc'}
                onClick={createSortHandler(columns.id)}
              >
                {columns.label}
                {orderBy === columns.id ? (
                  <Box component='span' sx={visuallyHidden}>
                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                  </Box>
                ) : null}
              </TableSortLabel>
            </TableCell>
          ))}
          {statusPesquisa === StatusPesquisa.NaoLiberada ? (
            <>
              <TableCell
                style={{color: titleColor}}
                className='fw-bolder table-cell'
                align='right'
              >
                <div className='d-flex justify-content-center w-50'>AÇÕES</div>
              </TableCell>
              <TableCell
                style={{color: titleColor}}
                className='fw-bolder table-cell'
                align='center'
                padding='none'
              >
                <>
                  {(auth?.servico.tipoPlano === EnumServicoTipoPlano.Try ||
                    auth?.roles.find((x) => x === 'Master') !== undefined ||
                    auth?.roles.find((x) => x === 'Administrador') !== undefined ||
                    (auth?.permissoes.find(x => x === 'B9F9D6DD0BAB131A1D4D215F03D46378') !== undefined)) && (
                    <>
                      <Tooltip title='Adicionar nova pesquisa'>
                        <IconButton
                          style={{marginRight: '-8px'}}
                          className='btn py-0 my-0 px-0 btn-light'
                          onClick={() => { 
                            navigate('/pesquisa-ia/dashboard/pesquisa', { state: { 
                              
                            } })
                          }}
                        >
                          <i className='fs-2 fas fa-plus py-2 px-4 m-0'></i>
                        </IconButton>
                      </Tooltip>
                    </>
                  )}
                </>
              </TableCell>
            </>
          ) : (
            <TableCell
              style={{color: titleColor}}
              className='fw-bolder table-cell '
              align='right'
            >
              <div className='d-flex justify-content-center w-50'>AÇÕES</div>
            </TableCell>
          )}
        </TableRow>
      </TableHead>
    )
  }

  const resquestTable = (
    pageRq: number,
    orderByRq: string,
    orderRq: string,
    rowsPerPageRq: number
  ) => {
    let url = '/Pesquisa/Dashboard/'
    if (statusPesquisa === StatusPesquisa.NaoLiberada) {
      url += 'ListarNaoLiberadas'
    } else if (statusPesquisa === StatusPesquisa.NaoIniciada) {
      url += 'ListarNaoIniciadas'
    } else if (statusPesquisa === StatusPesquisa.EmAndamento) {
      url += 'ListarEmAndamento'
    } else if (statusPesquisa === StatusPesquisa.Suspensa) {
      url += 'ListarSuspensas'
    } else {
      url += 'ListarFinalizadas'
    }

    httpClient
      .request({
        method: RequestMethod.POST,
        endpoint: url,
        data: {
          Order: orderRq,
          OrderBy: orderByRq,
          Page: pageRq,
          RowsPerPage: rowsPerPageRq,
        },
      })
      .then((result) => {
        const newRows = result.payload.data.map((data: any) => {
          return createData(data.Id, data.Nome, data.QtdDimensoes, data.QtdPerguntas, data.DataInicialString, data.DataFinalString)
        })
        setRows([...newRows])
        setTotal(result.payload.total)
      })
  }

  React.useEffect(() => {
    if (buscar === true) {
      resquestTable(page, orderBy, order, rowsPerPage)
    }
  }, [buscar])

  const handleRequestSort = (event: React.MouseEvent<unknown>, property: keyof Data) => {
    const isAsc = orderBy === property && order === 'asc'
    setOrder(isAsc ? 'desc' : 'asc')
    setOrderBy(property)
    resquestTable(page, property, isAsc ? 'desc' : 'asc', rowsPerPage)
  }

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelected = rows.map((n) => n.Nome)
      setSelected(newSelected)
      return
    }
    setSelected([])
  }

  const handleChangePage = (event: unknown, newPage: number) => {
    resquestTable(newPage, orderBy, order, rowsPerPage)
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    resquestTable(page, orderBy, order, parseInt(event.target.value, 10))
    setPage(0)
  }

  const emptyRows = rows.length

  const atualizarDados = () => {
    setDadosDash()
    resquestTable(page, orderBy, order, rowsPerPage)
  }

  const onClickAbrirModalParticipantes = (id:number) => {
    setIdPesquisa(id)
    setShowModal(true)
  }

  React.useEffect(() => {
    atualizarDados()
  }, [statusPesquisa])

  return (
    <>

      <ModalVisualizarParticipante 
        idPesquisa={idPesquisa}
        setShowModal={setShowModal}
        setStatusParticipante={setStatusParticipante}
        setNomeParticipante={setNomeParticipante}
        showModal={showModal}
        setIdParticipante={setIdParticipante}
        setShowModalDetalhes={setShowModalDetalhes}
      />

      <ModalDetalhesPesquisaParticipante 
        nomeParticipante={nomeParticipante}
        statusParticipante={statusParticipante}
        idParticipante={idParticipante}
        setIdParticipante={setIdParticipante}
        showModal={showModalDetalhes}
        setShowModal={setShowModalDetalhes}
      />

      <form noValidate className='form'>
        <div className='card-body'>
          <Box sx={{width: '100%'}}>
            <Paper
              className='tabela-descricao'
              style={{
                borderWidth: '2px',
                borderStyle: 'solid',
                borderRadius: '0px 0px 5px 5px',
                borderColor: colorOpacity,
              }}
              sx={{width: '100%', mb: 2}}
            >
              <TableContainer>
                <Table sx={{minWidth: 750}} aria-labelledby='tableTitle'>
                  <EnhancedTableHead
                    numSelected={selected.length}
                    order={order}
                    orderBy={orderBy}
                    onSelectAllClick={handleSelectAllClick}
                    onRequestSort={handleRequestSort}
                  />
                  <TableBody>
                    {rows.map((row, index) => {
                      return (
                        <TableRow
                          className='table-row'
                          hover
                          tabIndex={-1}
                          key={index}
                          sx={{cursor: 'pointer'}}
                        >
                          <TableCell className='table-cell' align='left'>
                            {row.Nome}
                          </TableCell>
                          <TableCell className='table-cell' align='left'>
                            {row.QtdDimensoes}
                          </TableCell>
                          <TableCell className='table-cell' align='left'>
                            {row.QtdPerguntas}
                          </TableCell>
                          <TableCell className='table-cell' align='left'>
                            {row.DataInicialString + " - " + row.DataFinalString}
                          </TableCell>
                          <TableCell
                            className='table-cell'
                            align='right'
                          >
                            <div className='d-flex justify-content-center w-50 align-items-center'>
                              {statusPesquisa === StatusPesquisa.NaoLiberada && (
                                <>
                                  {(auth?.servico.tipoPlano === EnumServicoTipoPlano.Try ||
                                    (auth?.roles.find((x) => x === 'Master') !== undefined ||
                                    auth?.roles.find((x) => x === 'Administrador') !== undefined || 
                                    (auth?.permissoes.find(x => x === 'E61F324733385863C220A523898375C3') !== undefined))) && (
                                    <>
                                      <IconButton 
                                        className='btn'
                                        onClick={() => {
                                          alert.createDialog({
                                            html: "Realmente deseja liberar esta pesquisa?",
                                            confirmAction: () => {
                                              httpClient.request({
                                                method: RequestMethod.POST,
                                                endpoint: '/Pesquisa/Pesquisa/Liberar',
                                                queryObject: {
                                                  idPesquisa: row.Id
                                                }
                                              }).then((response) => {
                                                if(response.success){
                                                  atualizarDados()
                                                }
                                              })
                                            }
                                          })
                                        }}
                                      >
                                        <span
                                          title='Liberar'
                                          className='btn btn-icon p-0 m-0 btn-active-color-success w-25px h-25px'
                                        >
                                          <KTSVG
                                            path='/media/prolead/pesquisa/icons/check_.svg'
                                            className={`svg-icon-2x ms-n1`}
                                          />
                                        </span>
                                      </IconButton>
                                    </>
                                  )}
                                  {(servico?.tipoPlano === EnumServicoTipoPlano.Try || (auth?.roles.find(x => x === 'Master') !== undefined) || (auth?.roles.find(x => x === 'Administrador') !== undefined) || (auth?.permissoes.find(x => x === '2EF021725B77227D985BEB31B56CCCAA') !== undefined)) && (
                                    <IconButton 
                                      className='btn' 
                                      onClick={() => {
                                        alert.createDialog({
                                          html: "Realmente deseja excluir esta pesquisa?",
                                          confirmAction: () => {
                                            httpClient.request({
                                              method: RequestMethod.DELETE,
                                              endpoint: '/Pesquisa/Pesquisa/Excluir',
                                              queryObject: {
                                                idPesquisa: row.Id
                                              }
                                            }).then((response) => {
                                              if(response.success){
                                                atualizarDados()
                                              }
                                            })
                                          }
                                        })
                                      }}
                                    >
                                      <span
                                        title='Excluir'
                                        className='btn btn-icon p-0 m-0 btn-active-color-danger w-25px h-25px'
                                      >
                                        <KTSVG
                                          path='/media/prolead/pesquisa/icons/trash_.svg'
                                          className={`svg-icon-2x ms-n1`}
                                        />
                                      </span>
                                    </IconButton>
                                  )}
                                </>
                              )}
                              {statusPesquisa === StatusPesquisa.NaoIniciada && (
                                <>
                                  {(auth?.roles.find((x) => x === 'Master') !== undefined ||
                                    auth?.roles.find((x) => x === 'Administrador') !== undefined ||
                                    (auth?.permissoes.find(x => x === '9473BF27471FD10C5335AD9110751EC0') !== undefined)) && (
                                    <>
                                      <IconButton className='btn' onClick={() => onClickAbrirModalParticipantes(row.Id)}>
                                        <span
                                          title='Participantes'
                                          className='btn btn-icon p-0 m-0 btn-active-color-primary w-25px h-25px'
                                        >
                                          <i className="fa-solid fa-users  fs-1 text-primary"></i>
                                        </span>
                                      </IconButton>
                                    </>
                                  )}
                                  {(auth?.servico.tipoPlano === EnumServicoTipoPlano.Try ||
                                    auth?.roles.find((x) => x === 'Master') !== undefined ||
                                    auth?.roles.find((x) => x === 'Administrador') !== undefined ||
                                    (auth?.permissoes.find(x => x === '2043D5D169C1F776F669F23D6B1CD783') !== undefined)) && (
                                    <>
                                      <IconButton className='btn' onClick={() => { 
                                        navigate("/pesquisa-ia/dashboard/pesquisa",
                                          {state:{
                                              Id: row.Id,
                                              TipoRedirect: 'editar',
                                          }}
                                        )
                                      }}>
                                        <span
                                          title='Editar'
                                          className='btn btn-icon p-0 m-0 btn-active-color-primary w-25px h-25px'
                                        >
                                          <KTSVG
                                            path='/media/prolead/pesquisa/icons/edit_.svg'
                                            className={`svg-icon-2x ms-n1`}
                                          />
                                        </span>
                                      </IconButton>                                      
                                    </>
                                  )}
                                  {(auth?.servico.tipoPlano === EnumServicoTipoPlano.Try ||
                                    auth?.roles.find((x) => x === 'Master') !== undefined ||
                                    auth?.roles.find((x) => x === 'Administrador') !== undefined ||
                                    (auth?.permissoes.find(x => x === 'D53451232FA9111492CC1945A9E6A2D6') !== undefined)) && (
                                    <>
                                      <IconButton 
                                        className='btn'
                                        onClick={() => {
                                          alert.createDialog({
                                            html: "Realmente deseja iniciar esta pesquisa?",
                                            confirmAction: () => {
                                              httpClient.request({
                                                method: RequestMethod.POST,
                                                endpoint: '/Pesquisa/Pesquisa/Iniciar',
                                                queryObject: {
                                                  idPesquisa: row.Id
                                                }
                                              }).then((response) => {
                                                if(response.success){
                                                  atualizarDados()
                                                }
                                              })
                                            }
                                          })
                                        }}
                                      > 
                                        <span
                                          title='Iniciar'
                                          className='btn btn-icon p-0 m-0 btn-active-color-primary w-25px h-25px'
                                        >
                                          <KTSVG
                                            path='/media/prolead/pesquisa/icons/play_.svg'
                                            className={`svg-icon-2x ms-n1`}
                                          />
                                        </span>
                                      </IconButton>                                      
                                    </>
                                  )}
                                </>
                              )}
                              {statusPesquisa === StatusPesquisa.EmAndamento && (
                                <>
                                  {(auth?.servico.tipoPlano === EnumServicoTipoPlano.Try ||
                                    auth?.roles.find((x) => x === 'Master') !== undefined ||
                                    auth?.roles.find((x) => x === 'Administrador') !== undefined ||
                                    (auth?.permissoes.find(x => x === '9473BF27471FD10C5335AD9110751EC0') !== undefined)) && (
                                    <>
                                      <IconButton className='btn' onClick={() => onClickAbrirModalParticipantes(row.Id)}>
                                        <span
                                          title='Participantes'
                                          className='btn btn-icon p-0 m-0 btn-active-color-primary w-25px h-25px'
                                        >
                                          <i className="fa-solid fa-users  fs-1 text-primary"></i>
                                        </span>
                                      </IconButton>
                                    </>
                                  )}
                                  {(auth?.servico.tipoPlano === EnumServicoTipoPlano.Try ||
                                    auth?.roles.find((x) => x === 'Master') !== undefined ||
                                    auth?.roles.find((x) => x === 'Administrador') !== undefined ||
                                    (auth?.permissoes.find(x => x === '60BC69ED48448997A9D90CC3584BF3DB') !== undefined)) && (
                                    <>
                                      <IconButton 
                                        className='btn' 
                                        onClick={() => {
                                          alert.createDialog({
                                            html: "Realmente deseja suspender esta pesquisa?",
                                            confirmAction: () => {
                                              httpClient.request({
                                                method: RequestMethod.POST,
                                                endpoint: '/Pesquisa/Pesquisa/Suspender',
                                                queryObject: {
                                                  idPesquisa: row.Id
                                                }
                                              }).then((response) => {
                                                if(response.success){
                                                  atualizarDados()
                                                }
                                              })
                                            }
                                          })
                                        }}
                                      >
                                        <span
                                          title='Suspender'
                                          className='btn btn-icon p-0 m-0 btn-active-color-primary w-25px h-25px'                                          
                                        >
                                          <KTSVG
                                            path='/media/prolead/pesquisa/icons/pause_.svg'
                                            className={`svg-icon-2x ms-n1`}
                                          />
                                        </span>
                                      </IconButton>
                                    </>
                                  )}
                                </>
                              )}
                              {statusPesquisa === StatusPesquisa.Suspensa && (
                                <>
                                {(auth?.roles.find((x) => x === 'Master') !== undefined ||
                                  auth?.roles.find((x) => x === 'Administrador') !== undefined ||
                                  (auth?.permissoes.find(x => x === '9473BF27471FD10C5335AD9110751EC0') !== undefined)) && (
                                  <>
                                    <IconButton className='btn' onClick={() => onClickAbrirModalParticipantes(row.Id)}>
                                      <span
                                        title='Participantes'
                                        className='btn btn-icon p-0 m-0 btn-active-color-primary w-25px h-25px'
                                      >
                                          <i className="fa-solid fa-users fs-1 text-primary"></i>
                                      </span>
                                    </IconButton>
                                  </>
                                )}
                                {(auth?.roles.find((x) => x === 'Master') !== undefined ||
                                  auth?.roles.find((x) => x === 'Administrador') !== undefined ||
                                  (auth?.permissoes.find(x => x === '2043D5D169C1F776F669F23D6B1CD783') !== undefined)) && (
                                  <>
                                    <IconButton className='btn' onClick={() => { 
                                      navigate("/pesquisa-ia/dashboard/pesquisa",
                                        {state:{
                                            Id: row.Id,
                                            TipoRedirect: 'editar',
                                        }}
                                      )
                                    }}>
                                      <span
                                        title='Editar'
                                        className='btn btn-icon p-0 m-0 btn-active-color-primary w-25px h-25px'
                                      >
                                        <KTSVG
                                          path='/media/prolead/pesquisa/icons/edit_.svg'
                                          className={`svg-icon-2x ms-n1`}
                                        />
                                      </span>
                                    </IconButton>
                                  </>
                                )}
                                {(auth?.roles.find((x) => x === 'Master') !== undefined ||
                                  auth?.roles.find((x) => x === 'Administrador') !== undefined ||
                                  (auth?.permissoes.find(x => x === 'D53451232FA9111492CC1945A9E6A2D6') !== undefined)) && (
                                  <>
                                    <IconButton className='btn'>
                                      <span
                                        title='Iniciar'
                                        className='btn btn-icon p-0 m-0 btn-active-color-primary w-25px h-25px'
                                        onClick={() => {
                                          alert.createDialog({
                                            html: "Realmente deseja iniciar esta pesquisa?",
                                            confirmAction: () => {
                                              httpClient.request({
                                                method: RequestMethod.POST,
                                                endpoint: '/Pesquisa/Pesquisa/Iniciar',
                                                queryObject: {
                                                  idPesquisa: row.Id
                                                }
                                              }).then((response) => {
                                                if(response.success){
                                                  atualizarDados()
                                                }
                                              })
                                            }
                                          })
                                        }}
                                      >
                                        <KTSVG
                                          path='/media/prolead/pesquisa/icons/play_.svg'
                                          className={`svg-icon-2x ms-n1`}
                                        />
                                      </span>
                                    </IconButton>
                                  </>
                                )}
                                </>
                              )}
                              {statusPesquisa === StatusPesquisa.Finalizada && (
                                <>
                                  {(auth?.servico.tipoPlano === EnumServicoTipoPlano.Try ||
                                    auth?.roles.find((x) => x === 'Master') !== undefined ||
                                    auth?.roles.find((x) => x === 'Administrador') !==
                                      undefined) && (
                                    <>
                                      <IconButton className='btn' >
                                        <span
                                          title='Resultados'
                                          className='btn btn-icon p-0 m-0 btn-active-color-primary w-25px h-25px'
                                        >
                                          <KTSVG
                                            path='/media/prolead/pesquisa/icons/chart_.svg'
                                            className={`svg-icon-2x ms-n1`}
                                          />
                                        </span>
                                      </IconButton>
                                    </>
                                  )}
                                </>
                              )}
                            </div>
                          </TableCell>
                          {statusPesquisa === StatusPesquisa.NaoLiberada && (
                            <TableCell className='table-cell' align='left'></TableCell>
                          )}
                        </TableRow>
                      )
                    })}
                    {emptyRows === 0 && (
                      <TableRow>
                        <TableCell className='table-cell bg-body text-center' colSpan={6}>
                          Nenhum registro encontrado
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                className='table-cell'
                style={{backgroundColor: colorOpacity, color: titleColor}}
                labelRowsPerPage='linhas por página'
                rowsPerPageOptions={[10, 25]}
                component='div'
                count={total}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </Paper>
          </Box>
        </div>
      </form>
    </>
  )
}

export {TablePesquisa}
