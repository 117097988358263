import { useState } from 'react'
import {KTSVG, toAbsoluteUrl } from '../../../_metronic/helpers'
import { Tooltip } from '../../modules/services/Bundle'

type Props = {
    descricao: string
    videoPath: string
    videoTitle: string
    className?: string
  }
  
  
const AbrirModalVideo = ({descricao, videoPath, videoTitle, className = ''}: Props) => {
      
    const [showModal, setShowModal] = useState(false)
      
    return (
  
      <>
        <div className='engage-toolbar d-flex position-fixed px-2 fw-bolder z-index-2 top-50 end-0 gap-2'>
            <Tooltip title={`Tutorial`}>
                <button
                    type='button'
                    className='engage-help-toggle m-0 btn btn-flex h-35px btn-secondary btn-color-gray-700 btn-active-color-gray-900 shadow-sm  rounded'
                    onClick={() => setShowModal(true)}
                >
                    <i className="fs-2 m-0 p-0 fas fa-play-circle"></i>
                </button>
            </Tooltip>
        </div>
          
        <div className={`modal ${showModal? "show" : ""} fade`} tabIndex={-1} id="kt_modal_1" style={{display: `${showModal? "block" : "none"}`}}>
            <div className="modal-dialog modal-dialog-centered modal-xl">
                <div className="modal-content">
                <div className="modal-header">
                    <h5 className="modal-title">{videoTitle}</h5>
                    <div
                    onClick={() => setShowModal(false)}
                    className="btn btn-icon btn-sm btn-active-light-primary ms-2"
                    data-bs-dismiss="modal"
                    aria-label="Fechar"
                    >
                    <KTSVG
                        path="/media/icons/duotune/arrows/arr061.svg"
                        className="svg-icon svg-icon-2x"
                    />
                    </div>
                </div>
                <div className="modal-body">
                    <div className='row d-flex justify-content-center mt-5 mb-5'>
                        <div className='col-lg-11 d-flex justify-content-center'>
                        <video 
                            controls 
                            style={{backgroundColor: "black"}} 
                            className='w-100 py-5 rounded mw-700px'>
                            <source 
                            src={videoPath} 
                            type="video/mp4"
                            />
                        </video>
                        </div>
                    </div>
                    <div className='row d-flex justify-content-center mt-5 mb-15'>
                        <div className='col-lg-11 d-flex justify-content-center'>
                        <span className='me-2'>
                            {descricao}
                        </span>
                        </div>
                    </div>
                </div>
                <div className="modal-footer">
                    <button
                    onClick={() => setShowModal(false)}
                    type="button"
                    className="btn btn-light"
                    data-bs-dismiss="modal"
                    >
                    Ok
                    </button>
                </div>
                </div>
            </div>
        </div>
      </>
  
    )
}

export {AbrirModalVideo}


