/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, } from 'react'
import {ErrorMessage, Field, FormikProps} from 'formik'
import { IWizardModel } from '../helpers/ResponderPesquisaHelper'

interface Step1Props {
  formik: FormikProps<IWizardModel>,
  isMobile: boolean
}

const Step1: FC<Step1Props> = ({formik, isMobile}) => {
  
  return (
    <>
      <div className='w-100'>
        <div className={`row ${isMobile ? 'p-5' : ''}`}>

          <div className='col-lg-12 mb-5'>
            <h2 className='mt-2 text-center'>Termos e condições da pesquisa</h2>
          </div>

          <div className='col-lg-12 mb-5 fv-row  py-5 rounded-3 bg-light-success'>      
            <div className='p-5 scroll h-300px'>
              <p 
                className='fs-3'
                dangerouslySetInnerHTML={{__html: formik.values.Pesquisa.Termos}}
              >                
              </p>
            </div>   
          </div> 

          <div className='col-lg-12 mb-5 fv-row d-flex justify-content-center'>
              <Field 
                type='checkbox'
                name='Termos'
                className='form-check-input'
              />
              <label className='form-label required ms-1'>Li e concordo com os termos</label>
              <div className='text-danger mt-2'>
                <ErrorMessage name='Termos' />
              </div>
            </div>

        </div>
      </div>
    </>
  )
}

export {Step1}
