import * as Yup from 'yup'
import { useValidation } from '../../../../modules/services/Bundle'


export interface IConsultor {  
  IdUsuario?: number
  IdCliente?: number
}

const initValues: IConsultor = {
  IdUsuario: undefined,
  IdCliente: undefined
}

const defaultValidationSchemas = Yup.object().shape({
     IdUsuario: Yup.string().nullable().required('É necessário selecionar um Consultor.'),
     IdCliente: Yup.string().nullable().required('É necessário selecionar um Cliente.')
  })

export {defaultValidationSchemas, initValues}
