import { FormikProps } from 'formik'
import { useState } from 'react'
import { KTSVG } from '../../../../../_metronic/helpers'
import { useAlert, useHttpClient } from '../../../../modules/services/Bundle'
import { RequestMethod } from '../../../../modules/services/core/_enums'
import { IPesquisaRespostaModel, ITemaModel } from '../helpers/ConfiguracoesIniciaisHelper'

type Props = {
    setAbrirModal: Function
    formik: FormikProps<any>
    crud: boolean
    dimensao?: IPesquisaRespostaModel
}

const ModalDimensao = ({setAbrirModal, formik, crud, dimensao}: Props) => {
    
    const httpClient = useHttpClient()
    const alert = useAlert()

    const [nomeDimensao, setNomeDimensao] = useState("");

    const handleSubmit = async () => {
        if(nomeDimensao === "") return alert.createMessage({
            html: "Você deve digitar o nome da dimensão para a IA gerar a descrição!"
        })

        const queryObj = dimensao ? {
            nomeDimensao: nomeDimensao,
            idTema: formik.values.temaBuscarDimensao.Id,
            idDimensao: dimensao!.Id,
            idDimensaoDescricao: dimensao.DimensaoDescricoes[0].Id
        } : {
            nomeDimensao: nomeDimensao,
            idTema: formik.values.temaBuscarDimensao.Id
        }

        const response = await httpClient.request({
            method: RequestMethod.POST, 
            endpoint: `/Pesquisa/${crud ? 'Tema' : 'ConfiguracoesIniciais'}/${dimensao ? 'EditarDimensaoPersonalizada' : 'AdicionarDimensaoPersonalizada'}`,
            queryObject: queryObj,
        })
        
        if (response.success && response.payload) {
      
            const newValues = formik.values.temaBuscarDimensao
            const temas = formik.values.tema
      
            const tema:ITemaModel = response.payload
            const indexTema = temas.findIndex((x: any) => x.Id === tema.Id);
            temas[indexTema].Dimensao = tema.Dimensao;
      
            newValues.Dimensao = tema.Dimensao
      
            formik.setFieldValue('temaBuscarDimensao', newValues)
            formik.setFieldValue('tema', temas);
            setAbrirModal(false)

            alert.createMessage({
                html: dimensao ? 'Dimensão alterada com sucesso!' : 'Dimensão adicionada com sucesso!',
                icon: 'success'
            })
          }
    }

    return (
    <div className={`modal show fade`} tabIndex={-1} id="kt_modal_1" style={{display: "block"}}>
        <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
            <div className="modal-header">
                <h5 className="modal-title">{dimensao ? 'Alterar' : 'Adicionar'} Dimensão</h5>
                <div
                onClick={() => setAbrirModal(false)}
                className="btn btn-icon btn-sm btn-active-light-primary ms-2"
                data-bs-dismiss="modal"
                aria-label="Fechar"
                >
                <KTSVG
                    path="/media/icons/duotune/arrows/arr061.svg"
                    className="svg-icon svg-icon-2x"
                />
                </div>
            </div>
            <div className="modal-body">                
                <div className='row'>
                    <div className='col-lg-12 fv-row d-flex flex-wrap justify-content-center'>
                        {dimensao ? (
                            <>
                                <label className='form-label fs-3 fw-bold'>Deseja alterar a dimensão: <span className='text-pesquisa'>{dimensao.Nome}</span>&nbsp;</label>
                            </>
                        )
                        : 
                        (
                            <label className='form-label fs-2 fw-bold'>Forneça a ideia de dimensão para seu tema</label>
                        )}
                        <div className='col-12 d-flex align-items-center'>
                            {dimensao && (<span className='fs-2 fw-bold me-2'>Para: </span>)}
                            <input type="text" placeholder='Informe a nova dimensão' className='form-control form-control-lg form-control-solid' onBlur={(e) => setNomeDimensao(e.target.value)} />
                        </div>
                    </div>
                </div>
            </div>
            <div className="modal-footer">
                <button
                    onClick={() => setAbrirModal(false)}
                    type="button"
                    className="btn btn-light me-5"
                    data-bs-dismiss="modal"
                >
                    Cancelar
                </button>
                
                <button type='button' onClick={() => handleSubmit()} className={`btn bg-pesquisa text-white`}>
                    {dimensao ? 'Alterar' : 'Adicionar'}
                </button>

            </div>
            </div>
        </div>
    </div>
  )
}

export {ModalDimensao}