import { ErrorMessage, Field, FormikProps, FormikProvider, useFormik } from 'formik'
import { KTSVG } from '../../../../../_metronic/helpers'
import { useAlert, useHttpClient } from '../../../../modules/services/Bundle'
import { RequestMethod } from '../../../../modules/services/core/_enums'
import { IDescreverDescricaoModel, IDescreverRespostaModel } from '../helpers/DescreverHelper'
import * as Yup from 'yup'

type Props = {
    abrirModal: boolean,
    setAbrirModal: React.Dispatch<React.SetStateAction<boolean>>
    formik: FormikProps<IDescreverDescricaoModel>,
    campoSelecionado: number | undefined,
    setCampoSelecionado: React.Dispatch<React.SetStateAction<number | undefined>>,
    respostaSelecionada: number | undefined,
    setRespostaSelecionada: React.Dispatch<React.SetStateAction<number | undefined>>,
    respostaSendoEditada: boolean,
    setRespostaSendoEditada: React.Dispatch<React.SetStateAction<boolean>>
}

interface IRespModel {
    texto: string
}

const defaultValidationSchemas = Yup.object().shape({
    texto: Yup.string().required('A resposta é obrigatória'),
})

const defaultResp: IRespModel = {
texto: '',
}

const ModalAddResp= ({abrirModal, setAbrirModal, formik, campoSelecionado, respostaSelecionada, respostaSendoEditada}: Props) => {
    const alert = useAlert()
    const httpClient = useHttpClient()
    const campo = formik.values.Campos.find(c => c.Id === campoSelecionado)

    const formikAddNovaResp = useFormik({
        initialValues: defaultResp,
        validationSchema: defaultValidationSchemas,
        onSubmit: (values, actions) => {
            onClickSalvarNovaResp()
        },
    })

    const moverResposta = (respostas: IDescreverRespostaModel[], oldIndex: number, newIndex: number) => {
        var resposta = respostas[oldIndex]
        respostas.splice(oldIndex, 1)
        respostas.splice(newIndex, 0, resposta)
        for(let i = 0; i < respostas.length; i++) respostas[i].Ordem = i
        return respostas
    }

    const onClickSalvarNovaResp = () =>{
        alert.createDialog({
            html: campo && campo.TipoCampo.InteracaoComAI ? `Realmente deseja gerar uma nova resposta a partir da ideia central inserida?` : `Realmente deseja gerar uma nova resposta?`,
            confirmAction: async () => {
            
                const response = await httpClient.request({
                    method: RequestMethod.GET,
                    endpoint: '/DescricaoDeCargo/Descrever/AdicionarResposta',
                    queryObject: {
                        idDescricao: formik.values.Id,
                        idCampo: campoSelecionado,
                        ideia: formikAddNovaResp.values.texto,
                        idRespostaSelecionada: respostaSelecionada !== undefined ? respostaSelecionada : 0
                    }
                })
                
                if(response.success && response.payload) {
                    let descricao = formik.values
                    let campoIndex = descricao.Campos.findIndex((c => c.Id === campoSelecionado))
                    descricao.Campos[campoIndex].Respostas.push(response.payload)

                    if(respostaSelecionada){
                        let oldIndex = descricao.Campos[campoIndex].Respostas.length - 1
                        let respIndex = descricao.Campos[campoIndex].Respostas.findIndex((r => r.Id === respostaSelecionada))
                        let newIndex = respIndex + 1 
                        descricao.Campos[campoIndex].Respostas = moverResposta(descricao.Campos[campoIndex].Respostas, oldIndex, newIndex)
                    }

                    formik.setValues(descricao)
                    formikAddNovaResp.resetForm()
                    setAbrirModal(false)
                }
    
            }
        })
    
    }

    return (
    <div className={`modal ${abrirModal? "show" : ""} fade`} tabIndex={-1} id="kt_modal_1" style={{display: `${abrirModal? "block" : "none"}`}}>
        <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
            <div className="modal-header">
                <h5 className="modal-title">Adicionar Resposta</h5>
                <div
                onClick={() => setAbrirModal(false)}
                className="btn btn-icon btn-sm btn-active-light-primary ms-2"
                data-bs-dismiss="modal"
                aria-label="Fechar"
                >
                <KTSVG
                    path="/media/icons/duotune/arrows/arr061.svg"
                    className="svg-icon svg-icon-2x"
                />
                </div>
            </div>
            <div className="modal-body">
                <FormikProvider value={formikAddNovaResp}>
                    <div className='w-100'>
                        <div className="row">

                        <div className="col-lg-12 fv-row mb-5">
                            <label className='form-label required'>{campo && campo.TipoCampo.InteracaoComAI ? `Insira a ideia central para nova resposta que deseja elaborar:` : `Insira a nova resposta que deseja elaborar`}</label>
                            <Field
                                as="textarea"
                                name={`texto`}
                                className='form-control form-control-lg form-control-solid'
                                value={formikAddNovaResp.values.texto}
                            />
                            <div className='text-danger mt-2'>
                                <ErrorMessage name='texto' />
                            </div> 
                        </div>         

                        </div>
                    </div>
                </FormikProvider>
            </div>
            <div className="modal-footer">
                <button
                onClick={() => setAbrirModal(false)}
                type="button"
                className="btn btn-light me-5"
                data-bs-dismiss="modal"
                >
                    Cancelar
                </button>
                
                <button type='button' onClick={() => formikAddNovaResp.handleSubmit()} className='btn  btn-primary btn-descricao'>
                    Salvar
                </button>

            </div>
            </div>
        </div>
    </div>
  )
}

export {ModalAddResp}
