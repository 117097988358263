import { useEffect, useState } from 'react'
import { useHttpClient } from '../../../modules/services/Bundle'
import { RequestMethod } from '../../../modules/services/core/_enums'
import { ISelectModel } from '../../../modules/services/core/_models'
import { IDadosGraficosResultadosPorDimensaoPesquisaModel } from './helpers/AnaliseResultadoHelper'
import Select from 'react-select'
import { GraficoResultadosPorDimensao } from './partials/GraficoResultadosPorDimensao'

const ResultadosPorDimensao = () => {
  let [escalas, setEscalas] = useState<ISelectModel[]>([])
  let [pesquisas, setPesquisas] = useState<ISelectModel[]>([])
  let [pesquisasSelecionadas, setPesquisasSelecionadas] = useState<ISelectModel[]>([])
  let [idsPesquisas, setIdsPesquisas] = useState<number[]>([])
  let [dadosGraficosPesquisa, setDadosGraficosPesquisa] = useState<IDadosGraficosResultadosPorDimensaoPesquisaModel | undefined>(undefined)
  const httpClient = useHttpClient()

  const obterParaSelectEscala = async () => {
    const response = await httpClient.request({
      method: RequestMethod.GET,
      endpoint: '/Pesquisa/Escala/ObterParaSelect',
    })
    if (response.success && response.payload && response.payload.length > 0) {
      setEscalas(response.payload)
    }
  }

  const obterParaSelectPesquisa = async (idEscala: any) => {
    const response = await httpClient.request({
      method: RequestMethod.GET,
      endpoint: '/Pesquisa/Resultado/ObterParaSelect',
      queryObject:{
        idEscala: idEscala ?? 0
      }
    })
    setPesquisas([])
    setPesquisasSelecionadas([])
    setIdsPesquisas([])
    
    if (idEscala !== "0" && response.success && response.payload && response.payload.length > 0) {
      setPesquisas(response.payload)
    }
  }

  useEffect(() => {
    obterParaSelectEscala()
  },[])

  const onChangeSelectPesquisa = async (e: any) => {
    setPesquisasSelecionadas(e.map((data: any) => ({
      Id: data.value,
      Nome: data.label
    })))  
    setIdsPesquisas(e.map((data: any) => (data.value)))  
  }

  const obterDadosGraficosResultadosPorDimensao = async () => {
    const response = await httpClient.request({
      method: RequestMethod.POST,
      endpoint: '/Pesquisa/Resultado/ObterDadosGraficosResultadosPorDimensao',
      data: idsPesquisas
    })

    if (response.success && response.payload) {
      setDadosGraficosPesquisa(response.payload)
    }
  }

  useEffect(() => {

    setDadosGraficosPesquisa(undefined)

    if(idsPesquisas.length > 0) obterDadosGraficosResultadosPorDimensao()
    
  },[idsPesquisas])

  return (
    <>
      <div className='card mb-10'>
        <div
          className='card-header'
        >
          <div className='card-title m-0'>
            <h3 className='fw-bolder m-0'>Resultados Por Dimensão</h3>
          </div>
        </div>

        <div className='card-body row'>
            <div className='col-lg-6 fv-row'>
              <label className={`form-label text-gray-800`}>Selecione a escala da pesquisa</label>
              <select
                className='form-select form-select-lg form-select-solid'
                onChange={(e: React.ChangeEvent<HTMLSelectElement>) => obterParaSelectPesquisa(e.target.value)}
              >
                <option value='0'>{'Selecione...'}</option>

                {escalas && escalas.length > 0
                  && escalas.map((data: ISelectModel, index: number) => {
                    return (
                      <option key={`select-escala-${index}`} value={data.Id}>
                        {data.Nome}
                      </option>
                    )
                  })
                }
                
              </select>
            </div>
            <div className='col-lg-6 fv-row'>
              <label className={`form-label text-gray-800`}>Selecione as pesquisas</label>
              <Select 
                placeholder='Selecione...' 
                className='form-control-custom-select'
                noOptionsMessage={() => 'Nenhuma opção encontrada'}
                styles={{
                  control: (baseStyles, state) => ({
                    ...baseStyles,
                    border: 'none',
                    backgroundColor: '$input-solid-bg',
                    borderRadius: '7px',
                    height: '3.4rem',
                    color: '#5e6278',
                    fontSize: '1.15rem'
                  }),
                  menu: (baseStyles, state) => ({
                    ...baseStyles,
                    backgroundColor: '$input-solid-bg',
                  }),
                  option: (provided, state) => ({
                    ...provided,
                    color: state.isFocused ? 'black' : '$input-solid-color',
                  }),
                }}
                options={pesquisas.map((data) => ({
                  value: data.Id,
                  label: data.Nome
                }))} 
                value={pesquisasSelecionadas.map((data) => ({
                  value: data.Id,
                  label: data.Nome
                }))} 
                isSearchable
                isMulti
                onChange={(e: any) => onChangeSelectPesquisa(e)}
              />
            </div>
        </div>
      </div>

      <GraficoResultadosPorDimensao
        dadosGraficosPesquisa={dadosGraficosPesquisa}
      />

    </>
  )
}

export {ResultadosPorDimensao}
