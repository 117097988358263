import {Route, Routes, Outlet, Navigate} from 'react-router-dom'
import {ArrayBreadcrumb, BreadcrumbLayout} from '../../modules/services/core/Breadcrumb'
import {Empresa} from '../configuracao/components/Empresa'
import {Empresas} from './components/Empresas'
import {Parametrizar} from './components/Parametrizar'
import {Relatorio} from './components/Relatorio'
import {Prompt} from './components/Prompt'
import {EditarPrompt} from './components/EditarPrompt'
import { CriarEmpresa } from './components/CriarEmpresa'

const wizardsBreadCrumbs: Array<ArrayBreadcrumb> = []

const wizardsBreadCrumbsParametrizar: Array<ArrayBreadcrumb> = [
  {
    titulo: 'Empresas',
    path: '/administracao/empresas',
  },
]

const wizardsBreadCrumbsPrompts: Array<ArrayBreadcrumb> = [
  {
    titulo: 'Prompts',
    path: '/administracao/prompts',
  },
]

const AdmPage = () => (
  <Routes>
    <Route element={<Outlet />}>
      <Route
        path='empresas'
        element={
          <>
            <BreadcrumbLayout props={wizardsBreadCrumbs}>Empresas</BreadcrumbLayout>
            <Empresas />
          </>
        }
      />
      <Route
        path='empresas/parametrizar'
        element={
          <>
            <BreadcrumbLayout props={wizardsBreadCrumbsParametrizar}>Parametrizar</BreadcrumbLayout>
            <Parametrizar />
          </>
        }
      />
      <Route
        path='empresas/criar'
        element={
          <>
            <BreadcrumbLayout props={wizardsBreadCrumbsParametrizar}>Criar</BreadcrumbLayout>
            <CriarEmpresa />
          </>
        }
      />
      <Route
        path='empresas/editar'
        element={
          <>
            <BreadcrumbLayout props={wizardsBreadCrumbsParametrizar}>Editar</BreadcrumbLayout>
            <Empresa />
          </>
        }
      />
      <Route
        path='relatorio'
        element={
          <>
            <BreadcrumbLayout props={wizardsBreadCrumbs}>Relatório</BreadcrumbLayout>
            <Relatorio />
          </>
        }
      />
      <Route
        path='prompts'
        element={
          <>
            <BreadcrumbLayout props={wizardsBreadCrumbs}>Prompts</BreadcrumbLayout>
            <Prompt />
          </>
        }
      />
      <Route
        path='prompts/editar'
        element={
          <>
            <BreadcrumbLayout props={wizardsBreadCrumbsPrompts}>Editar</BreadcrumbLayout>
            <EditarPrompt />
          </>
        }
      />
      <Route index element={<Navigate to='/inicio' />} />
    </Route>
  </Routes>
)

export default AdmPage
