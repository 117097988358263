import Chart from 'react-apexcharts'
import { IDadosGraficosPesquisaModel } from '../helpers/AnaliseResultadoHelper'

type Props = {
  dadosGraficosPesquisa: IDadosGraficosPesquisaModel | undefined
}

const GraficoMediaPorDimensao  = ({dadosGraficosPesquisa}:Props) => {
  
  return (
    <div className={`card mb-10 ${dadosGraficosPesquisa ? '' : 'd-none'}`}>
      <div
        className='card-header'
      >
        <div className='card-title m-0'>
          <h3 className='fw-bolder m-0'>Média por dimensão {`(${dadosGraficosPesquisa?.Escala.Inicio ?? 0} a ${dadosGraficosPesquisa?.Escala.Fim?? 10})`}</h3>
        </div>
      </div>
      <div className='card-body'>
        <div className='row justify-content-center mt-10'>
          {dadosGraficosPesquisa && dadosGraficosPesquisa.GraficoMediaPorDimensao && dadosGraficosPesquisa.GraficoMediaPorDimensao.length > 0 && dadosGraficosPesquisa.GraficoMediaPorDimensao.map((data, index) => {
            
            let options = {              
              labels: data.Labels,
              series: data.Series,
              colors: data.Colors,
              plotOptions: {
                radialBar: {
                  startAngle: -90,
                  endAngle: 90,
                  track: {
                    background: ['#f4f4f4'],
                    startAngle: -90,
                    endAngle: 90,
                  },
                  dataLabels: {
                    name: {
                      show: true,
                      offsetY: -145
                    },
                    value: {
                      fontSize: '30px',
                      color: '#3e875f',
                      show: true,
                      offsetY: -40, 
                      formatter: function (val: number) {
                        const escalaMaxima = dadosGraficosPesquisa.Escala.Fim ?? 10;
                        const valorAjustado = ((val * escalaMaxima) / 100).toFixed(2);
                        return valorAjustado;
                      }
                    },
                  }
                }
              },
            }

            return (
              <div key={`chart-media-por-dimensao-${index}`} className='col-lg-4'>
                <Chart options={options} series={options.series} type="radialBar" height={'400px'} />   
              </div>  
            )
          })}        
        </div>
      </div>        
    </div>      
  )
}
  
export {GraficoMediaPorDimensao}