import {Route, Routes, Navigate} from 'react-router-dom'
import { SemAcessoLayout } from '../../../_metronic/layout/SemAcessoLayout'
import { ConfirmarClienteConsultoriaDescricao } from './components/ConfirmarClienteConsultoriaDescricao'

const ConfirmarClienteConsultoria = () => {
  return (
    <Routes>
      <Route element={<SemAcessoLayout />}>        
        <Route path=':chave' element={<ConfirmarClienteConsultoriaDescricao />} />   
        <Route path='*' element={<Navigate to='/error/404' />} />
      </Route>
    </Routes>
  )
}

export {ConfirmarClienteConsultoria}
