import { useEffect, useState } from 'react'
import { PageLink, PageTitle } from '../../../../_metronic/layout/core'
import { BtnAbrirVideo } from './partials/BtnAbrirVideo'
import { CardConceitos } from './partials/CardConceitos'
import { useNavigate } from 'react-router-dom'

interface Videos {
  btnTo: string,
  descricaoVideo: string,
  videoName: string,
  videoPath: string,
}

const Conceitos = () => {

  let [descricao, setDescricao] = useState("Conheça os conceitos sobre descrição de cargos de forma intuitiva")
  let [descricaoVideo, setDescricaoVideo] = useState("Neste vídeo iremos auxiliar os gestores da sua empresa a compreenderem de forma intuitiva os conceitos fundamentais da descrição de cargos. Aprenda de maneira prática e acessível como essa ferramenta pode contribuir para a gestão eficiente dos cargos dentro da organização. Assista agora e fortaleça o conhecimento dos gestores sobre a descrição de cargos.")
  let [videoName, setVideoName] = useState("Seja bem-vindo à página de Conceitos da Descrição de Cargos")
  let [videoPath, setVideoPath] = useState("https://pro4tech.blob.core.windows.net/visualstuff/Pagina%20de%20conceitos.mp4?si=maintaining&spr=https&sv=2022-11-02&sr=b&sig=TX0IBDlby7NISIzfWEobgdvYqEx1SenO2KCEGZOhH6c%3D")
  const [videos, setVideos] = useState<Videos[]>([])
  const navigate = useNavigate()

  useEffect(() => {
    setVideos([
      {
        btnTo: '/conceitos/detalhes/cargo',
        descricaoVideo:'Descubra a importância da definição clara de cargos na gestão de uma organização. Explore responsabilidades, comunicação e tomada de decisões. Assista agora e entenda o papel fundamental dos cargos na organização.',
        videoName:'O que é um cargo?',
        videoPath:'https://pro4tech.blob.core.windows.net/visualstuff/Cargo.mp4?si=maintaining&spr=https&sv=2022-11-02&sr=b&sig=4NIYtYwAqnz6AGSzwlA%2B3L0NXG%2FS4S4LmbGOcgj78x8%3D',
      },
      {
        btnTo: '/conceitos/detalhes/premissas',
        descricaoVideo:'Descubra as premissas e possibilidades para um processo eficiente de descrição de cargos. Entenda a importância de revisar e atualizar regularmente as descrições de cargos, garantindo alinhamento com as necessidades em constante mudança. Saiba como a clareza, objetividade e abrangência são fundamentais para criar descrições de cargos claras e precisas. Assista agora e aprimore o processo de descrição de cargos na sua organização.',
        videoName:'Premissas para o processo de descrição de cargos',
        videoPath:'https://pro4tech.blob.core.windows.net/visualstuff/Premissas.mp4?si=maintaining&spr=https&sv=2022-11-02&sr=b&sig=NHu7c0MWIiese9O5%2Fhwb6pyzcyfFf%2FGRakynv1q0R6U%3D',
      },
    ])
  }, [])

  return (
    <>
      {/* begin::Row */}
      <div className='row g-5 g-xl-10 mb-5 mb-xl-10'>
        <div className='col-xl-12'>
          <CardConceitos
            descricao={descricao}
            descricaoVideo={descricaoVideo}
            videoName={videoName}
            videoPath={videoPath}
          />
        </div>
      </div>

      <div className='row g-5 g-xl-10 mb-5 mb-xl-10'>
        {
          videos && videos.length > 0 && videos.map((data: any, index: any) => {
            return <div key={index} className='col-xl-4'>
                    <BtnAbrirVideo
                      btnTo={data.btnTo}
                      descricaoVideo={data.descricaoVideo}
                      videoName={data.videoName}
                      videoPath={data.videoPath}
                    />
                  </div>
          })
        }
      </div>
      <div className='d-flex justify-content-end'>
        <button className='btn btn-secondary' onClick={() => navigate(-1)}>
          Voltar
        </button>
      </div>
      
    </>
  )
}

export {Conceitos}
