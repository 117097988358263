import { ErrorMessage, Field, FormikProps, FormikProvider, useFormik } from 'formik'
import { KTSVG } from '../../../../../_metronic/helpers'
import { useAlert, useHttpClient } from '../../../../modules/services/Bundle'
import { EnumPesquisaTipoResposta, RequestMethod } from '../../../../modules/services/core/_enums'
import { IPesquisaRespostaModel, IPesquisaRespostaPerguntaModel, IWizardModel } from '../helpers/ConfiguracoesIniciaisHelper'
import * as Yup from 'yup'
import { useEffect } from 'react'

type Props = {
    abrirModal: boolean,
    tipoResposta: number
    dimensaoSelecionada: number | undefined,
    setAbrirModal: React.Dispatch<React.SetStateAction<boolean>>
    formik: FormikProps<IWizardModel>,
    tituloSelecionado: number | undefined,
    setTituloSelecionado: React.Dispatch<React.SetStateAction<number | undefined>>,
    textoSelecionado: number | undefined,
    setTextoSelecionado: React.Dispatch<React.SetStateAction<number | undefined>>,
    textoSendoEditado: boolean,
    setTextoSendoEditado: React.Dispatch<React.SetStateAction<boolean>>
}

interface IRespModel {
    texto: string
}

const defaultValidationSchemas = Yup.object().shape({
    texto: Yup.string().required('A resposta é obrigatória'),
})

const defaultResp: IRespModel = {
texto: '',
}

const ModalAddResp= ({abrirModal, setAbrirModal, formik, tituloSelecionado, textoSelecionado, textoSendoEditado, tipoResposta, dimensaoSelecionada}: Props) => {
    const alert = useAlert()
    const httpClient = useHttpClient()

    const formikAddNovaResp = useFormik({
        initialValues: defaultResp,
        validationSchema: defaultValidationSchemas,
        onSubmit: (values, actions) => {
            onClickSalvarNovaResp()
        },
    })

    const moverResposta = (respostas: IPesquisaRespostaModel[], oldIndex: number, newIndex: number) => {
        var resposta = respostas[oldIndex]
        respostas.splice(oldIndex, 1)
        respostas.splice(newIndex, 0, resposta)
        for(let i = 0; i < respostas.length; i++) respostas[i].Ordem = i
        return respostas
    }

    useEffect(() => {
        if(abrirModal && tipoResposta === EnumPesquisaTipoResposta.Dimensao) {
            let tituloIndex = formik.values.temaBuscarDimensao.Dimensao.findIndex((c => c.Id === tituloSelecionado))
            
            onClickSalvarNovaResp(formik.values.temaBuscarDimensao.Dimensao[tituloIndex].Nome)
            formikAddNovaResp.resetForm()
            setAbrirModal(false)
        }
    },[abrirModal])

    const onClickSalvarNovaResp = (ideiaDimensao?:string) =>{

        alert.createDialog({
            html: `Realmente deseja gerar uma nova resposta a partir da ideia central inserida?`,
            confirmAction: async () => {
                const response = await httpClient.request({
                    method: RequestMethod.GET,
                    endpoint: '/Pesquisa/ConfiguracoesIniciais/AdicionarResposta',
                    queryObject: {
                        tipoResposta: tipoResposta,
                        ideia: tipoResposta === EnumPesquisaTipoResposta.Dimensao ? ideiaDimensao : formikAddNovaResp.values.texto,
                        idTema: formik.values.temaBuscarDimensao.Id,
                        idEntidadeSelecionada: textoSelecionado === undefined ? 0 : textoSelecionado,
                        idDimensao: tipoResposta === EnumPesquisaTipoResposta.Pergunta ? dimensaoSelecionada : tipoResposta === EnumPesquisaTipoResposta.Dimensao ? tituloSelecionado : 0
                    }
                })
                
                if(response.success && response.payload) {

                    let pesquisa = formik.values
                    let respModel:IPesquisaRespostaModel = response.payload
                    if (tipoResposta === EnumPesquisaTipoResposta.Topico && tituloSelecionado !== undefined && formik.values.tema.length > 0){
                        let tituloIndex = pesquisa.tema.findIndex((c => c.Id === tituloSelecionado))
                        respModel.Ordem = pesquisa.tema[tituloIndex].Topico.length
                        pesquisa.tema[tituloIndex].Topico.push(respModel)

                        if(textoSelecionado){
                            let oldIndex = pesquisa.tema[tituloIndex].Topico.length - 1
                            let respIndex = pesquisa.tema[tituloIndex].Topico.findIndex((r => r.Id === textoSelecionado))
                            let newIndex = respIndex + 1 
                            pesquisa.tema[tituloIndex].Topico = moverResposta(pesquisa.tema[tituloIndex].Topico, oldIndex, newIndex)
                        }
                    }
                    if (tipoResposta === EnumPesquisaTipoResposta.Dimensao){
                        let tituloIndex = pesquisa.temaBuscarDimensao.Dimensao.findIndex((c => c.Id === tituloSelecionado))
                        
                        pesquisa.temaBuscarDimensao.Dimensao[tituloIndex] = respModel

                        if(textoSelecionado){
                            let oldIndex = pesquisa.temaBuscarDimensao.Dimensao[tituloIndex].DimensaoDescricoes.length - 1
                            let respIndex = pesquisa.temaBuscarDimensao.Dimensao[tituloIndex].DimensaoDescricoes.findIndex((r => r.Id === textoSelecionado))
                            let newIndex = respIndex + 1 
                            pesquisa.temaBuscarDimensao.Dimensao[tituloIndex].DimensaoDescricoes = moverResposta(pesquisa.temaBuscarDimensao.Dimensao[tituloIndex].DimensaoDescricoes, oldIndex, newIndex)
                        }
                    }

                    formik.setValues(pesquisa)
                    formikAddNovaResp.resetForm()
                    setAbrirModal(false)
                }
    
            }
        })
    
    }

    return (
    <div className={`modal ${abrirModal? "show" : ""} fade`} tabIndex={-1} id="kt_modal_1" style={{display: `${abrirModal? "block" : "none"}`}}>
        <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
            <div className="modal-header">
                <h5 className="modal-title">Adicionar Sentença</h5>
                <div
                onClick={() => setAbrirModal(false)}
                className="btn btn-icon btn-sm btn-active-light-primary ms-2"
                data-bs-dismiss="modal"
                aria-label="Fechar"
                >
                <KTSVG
                    path="/media/icons/duotune/arrows/arr061.svg"
                    className="svg-icon svg-icon-2x"
                />
                </div>
            </div>
            <div className="modal-body">
                <FormikProvider value={formikAddNovaResp}>
                    <div className='w-100'>
                        <div className="row">

                        <div className="col-lg-12 fv-row mb-5">
                            <label className='form-label required'>{`Insira a ideia central para nova resposta que deseja elaborar:`}</label>
                            <Field
                                as="textarea"
                                name={`texto`}
                                className='form-control form-control-lg form-control-solid'
                                value={formikAddNovaResp.values.texto}
                            />
                            <div className='text-danger mt-2'>
                                <ErrorMessage name='texto' />
                            </div> 
                        </div>         

                        </div>
                    </div>
                </FormikProvider>
            </div>
            <div className="modal-footer">
                <button
                onClick={() => setAbrirModal(false)}
                type="button"
                className="btn btn-light me-5"
                data-bs-dismiss="modal"
                >
                    Cancelar
                </button>
                
                <button type='button' onClick={() => formikAddNovaResp.handleSubmit()} className='btn btn-pesquisa'>
                    Salvar
                </button>

            </div>
            </div>
        </div>
    </div>
  )
}

export {ModalAddResp}
