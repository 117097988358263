import * as React from 'react';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Paper from '@mui/material/Paper';
import { visuallyHidden } from '@mui/utils';
import { Link, Navigate, useNavigate } from 'react-router-dom';
import { Tooltip, useAlert, useHttpClient } from '../../../../modules/services/Bundle';
import { EnumPesquisaParticipanteStatus, EnumServicoTipoPlano, RequestMethod } from '../../../../modules/services/core/_enums';
import Popover from '@mui/material/Popover';
import Button from '@mui/material/Button';
import { IconButton } from '@mui/material';
import { useAuth } from '../../../../modules/auth';

type Props = {
  idPesquisa: number
  showModal: boolean
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>
  setIdParticipante: React.Dispatch<React.SetStateAction<number>>
  setNomeParticipante: React.Dispatch<React.SetStateAction<string>>
  setStatusParticipante: React.Dispatch<React.SetStateAction<number>>
  setShowModalDetalhes: React.Dispatch<React.SetStateAction<boolean>>
}

interface Column {
  id: 'Id' | 'Nome' | 'Departamento' | 'Status';
  label: string;
  minWidth?: number;
  align?: 'center';
  format?: (value: number) => string;
}

interface Filtros {
  Nome: string,
  Departamento: number
}

const columns: readonly Column[] = [
  { 
    id: 'Nome', 
    label: 'PARTICIPANTE'
  },
  { 
    id: 'Departamento', 
    label: 'DEPARTAMENTO'
  },
  { 
    id: 'Status', 
    label: 'STATUS'
  },
];

interface Data {
  Id: number;
  Nome: string;
  Departamento: string;
  Status: number;
}

function createData(
  Id: number,
  Nome: string,
  Departamento: string,
  Status: number,
): Data {
  return { Id, Nome, Departamento, Status};
}

type Order = 'asc' | 'desc';

interface EnhancedTableProps {
  numSelected: number;
  onRequestSort: (event: React.MouseEvent<unknown>, property: keyof Data) => void;
  onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
  order: Order;
  orderBy: string;
}

const TableParticipantesPesquisa = ({showModal, setShowModal, idPesquisa, setIdParticipante, setShowModalDetalhes, setNomeParticipante, setStatusParticipante}: Props) => {  

  const {auth} = useAuth()
  const httpClient = useHttpClient()
  const [order, setOrder] = React.useState<Order>('asc')
  const [orderBy, setOrderBy] = React.useState<keyof Data>('Nome')
  const [selected, setSelected] = React.useState<readonly string[]>([])
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10)
  const [total, setTotal] = React.useState(0)
  const [rows, setRows] = React.useState<Data[]>([])  
  const [departamentos, setDepartamentos] = React.useState<[]>([]) 
  const alert = useAlert()
  const navigate = useNavigate()

  const [nome, setNome] = React.useState<any>(null)
  const [departamento, setDepartamento] = React.useState<any>(null)

  const obterDepartamentos = () => {
    httpClient.request({
      method: RequestMethod.GET,
      endpoint: '/Gerenciamento/Area/ObterDadosSelect'
    }).then((result) => {
      setDepartamentos(result.payload)
    })
  }

  function EnhancedTableHead(props: EnhancedTableProps) {
    const { onSelectAllClick, order, orderBy, onRequestSort } =
      props;
    const createSortHandler =
      (property: keyof Data) => (event: React.MouseEvent<unknown>) => {
        onRequestSort(event, property);
      };
  
    return (
      <TableHead >
        <TableRow className='table-header-pesquisa'>
            {columns.map((column) => {
                if (
                  auth?.servico.tipoPlano === EnumServicoTipoPlano.Try &&
                  column.id === 'Departamento'
                ) {
                  // Se o tipo de plano for "Try" e a coluna for "Departamento", não renderize a coluna.
                  return null;
                }
                return (
                  <TableCell
                    className='table-cell'
                    key={column.id}
                    align={column.align ? 'right' : 'left'}
                  >
                    <TableSortLabel
                      className={`fw-bolder ${column.id === 'Status' ? 'ms-4' : ''}`}
                      active={orderBy === column.id}
                      direction={orderBy === column.id ? order : 'asc'}
                      onClick={createSortHandler(column.id)}
                    >
                      {column.label}
                      {orderBy === column.id ? (
                        <Box component="span" sx={visuallyHidden}>
                          {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                        </Box>
                      ) : null}
                    </TableSortLabel>
                  </TableCell>
                )
            })}
            <TableCell
              className='fw-bolder table-cell'
              align='center'
            >
              AÇÕES
            </TableCell> 
        </TableRow>
      </TableHead>
    );
  }

  const resquestTable = (pageRq: number, orderByRq: string, orderRq: string, rowsPerPageRq: number, Filtros: any = null) => {
    
      httpClient.request({
        method: RequestMethod.POST,
        endpoint: '/Pesquisa/Dashboard/ListarParticipantesPesquisa',
        data: {
          Order: orderRq,
          OrderBy: orderByRq,
          Page: pageRq,
          RowsPerPage: rowsPerPage,
          Filters: Filtros
        },
        queryObject: {
          idPesquisa: idPesquisa
        }
      }).then((result) => {
          
          const newRows = result.payload.data.map((data: Data) => {
            return createData(data.Id, data.Nome, data.Departamento, data.Status)
          })
          setRows([...newRows])
          setTotal(result.payload.total)
          setDepartamento(null);
      })
    
  }

  React.useEffect(() => {
      resquestTable(page, orderBy, order, rowsPerPage);
      obterDepartamentos();
  },[])

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: keyof Data,
  ) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
    resquestTable(page, property, isAsc ? 'desc' : 'asc', rowsPerPage);
  };

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelected = rows.map((n) => n.Nome);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    resquestTable(newPage, orderBy, order, rowsPerPage);
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    resquestTable(page, orderBy, order, parseInt(event.target.value, 10));
    setPage(0);
  };

  const emptyRows = rows.length;

  const atualizarDados = () => {
    resquestTable(page, orderBy, order, rowsPerPage)
  }

  const onClickBuscar = () => {
    const filtros:Filtros = {
      Nome: nome,
      Departamento: departamento
    }
    
    resquestTable(page, orderBy, order, rowsPerPage,filtros);
    handleClose()
  }

  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
      setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
      setAnchorEl(null);
      setNome(null)
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  const onClickAbrirModalVisualizarDetalhes = (id:number, nome:string, status:number) => {
    setIdParticipante(id)
    setNomeParticipante(nome)
    setStatusParticipante(status)
    setShowModalDetalhes(true)
    setShowModal(false)
  }

  return (
    <>
      <form className='form'>

          <div className='d-flex justify-content-end mb-2'>

            <div>
              <Button type='button' className='btn btn-light-success' aria-describedby={id} onClick={handleClick}>
                <i className="fs-2 fas fa-arrow-down-wide-short"></i>
                Filtros
              </Button>
              <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                className="popover-filtro-color-bg"
              >
                
                  <div className='bg-body'>
                    {/* begin::Header */}
                    <div className='px-7 py-5'>
                      <div className='fs-5 text-dark fw-bolder'>Opções</div>
                    </div>
                    {/* end::Header */}

                    {/* begin::Separator */}
                    <div className='separator border-gray-200'></div>
                    {/* end::Separator */}

                    {/* begin::Content */}
                    <div className='px-7 py-5' >

                      {/* begin::Input group */}
                      <div className='mb-10'>
                        <label className='form-label fs-6 fw-bold'>Nome do Participante:</label>
                        <input 
                          type='text'
                          onChange={e => setNome(e.target.value)} 
                          placeholder='Nome do Participante' 
                          className={`form-control form-control-lg form-control-solid`}
                        ></input>
                      </div>
                      {/* end::Input group */}

                      {/* begin::Input group */}
                      {!(auth?.servico.tipoPlano === EnumServicoTipoPlano.Try) && (
                        <div className='mb-10'>
                          <label className='form-label fs-6 fw-bold'>Departamentos:</label>
                          <select 
                            onChange={e => setDepartamento(e.target.value)} 
                            placeholder='Departamento' 
                            className={`form-control form-control-lg form-control-solid`}
                          >
                            <option>Selecione...</option>
                            {departamentos != null && departamentos.length > 0 && departamentos.map((d: {Id: number, Nome: string}) => {
                              return (
                                <option key={d.Id} value={d.Id}>{d.Nome}</option>
                              );
                            })}
                          </select>
                        </div>
                      )}

                      {/* end::Input group */}

                      {/* begin::Actions */}
                      <div className='d-flex justify-content-end'>
                        <button
                          type='button'
                          className='btn btn-light btn-active-light-primary fw-bold me-5 px-6'
                          onClick={handleClose}
                        >
                          Cancelar
                        </button>
                        <button
                          type='button'
                          className='btn btn-success fw-bold px-6'
                          onClick={() => onClickBuscar()}
                        >
                          Buscar
                        </button>
                      </div>
                      {/* end::Actions */}
                    </div>
                    {/* end::Content */}
                  </div>
              </Popover>
            </div>
          </div>

          <Box sx={{ width: '100%' }}>
            <Paper className='table-border-pesquisa tabela-pesquisa' sx={{ width: '100%', mb: 2 }}>
                     
              <TableContainer>
                <Table
                  sx={{ minWidth: 750 }}
                  aria-labelledby="tableTitle"
                >
                  <EnhancedTableHead
                    numSelected={selected.length}
                    order={order}
                    orderBy={orderBy}
                    onSelectAllClick={handleSelectAllClick}
                    onRequestSort={handleRequestSort}
                  />
                  <TableBody>
                    {rows.map((row, index) => {
                      return (
                        <TableRow
                          className='table-row-pesquisa'
                          hover
                          tabIndex={-1}
                          key={index}
                          sx={{ cursor: 'pointer' }}
                        >
                          <TableCell className='table-cell' align="left">{row.Nome}</TableCell>
                          {!(auth?.servico.tipoPlano === EnumServicoTipoPlano.Try) && (
                            <TableCell className='table-cell' align="left">{row.Departamento}</TableCell>
                          )}
                          <TableCell className='table-cell' align="left">
                            <span
                              className={`badge 
                              ${row.Status === 1 ? 'badge-light-danger' : ''}
                              ${row.Status === 2 ? 'badge-light-primary' : ''}
                              ${row.Status === 3 ? 'badge-light-success' : ''}
                              fw-bolder fs-8 px-2 py-1 ms-2`}
                            >
                              {EnumPesquisaParticipanteStatus[row.Status]}
                            </span>
                          </TableCell>
                          <TableCell className='table-cell' align="center">
                            {row.Status !== EnumPesquisaParticipanteStatus.Finalizada && (
                              <IconButton className='icon-button-dark-hover' 
                                title='Notificar' 
                                onClick={() => {
                                  alert.createDialog({
                                    html: `Deseja enviar uma notificação ao participante: ${row.Nome}?`,
                                    confirmAction: async () => {
                                      httpClient.request({
                                        method: RequestMethod.POST,
                                        endpoint: '/Pesquisa/Dashboard/NotificarParticipante',
                                        queryObject: {
                                          idParticipante: row.Id
                                        }
                                      }).then((response) => {
                                        
                                      })
                                    }
                                  })
                                }} color="primary" aria-label="upload picture" component="label"
                              >
                                <i className="fs-6 text-warning far fa-bell"></i>
                              </IconButton>
                            )}
                            {row.Status === EnumPesquisaParticipanteStatus.Finalizada && (
                              <IconButton className='ms-4'
                              >
                                
                              </IconButton>
                            )}
                            {row.Status !== EnumPesquisaParticipanteStatus['Não iniciada'] && (
                              <IconButton 
                                className={`text-gray-800 icon-button-dark-hover`}
                                title='Visualizar' 
                                onClick={() => onClickAbrirModalVisualizarDetalhes(row.Id, row.Nome, row.Status)}
                              >
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-eye-fill" viewBox="0 0 16 16">
                                    <path d="M10.5 8a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0z"/>
                                    <path d="M0 8s3-5.5 8-5.5S16 8 16 8s-3 5.5-8 5.5S0 8 0 8zm8 3.5a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7z"/>
                                </svg>
                              </IconButton>
                            )}
                            {row.Status === EnumPesquisaParticipanteStatus['Não iniciada'] && (
                              <IconButton className='ms-4'
                              >
                                
                              </IconButton>
                            )}
                          </TableCell>
                        </TableRow>
                      );
                    })}
                    {emptyRows == 0 && (
                      <TableRow
                      >
                        <TableCell className='table-cell bg-body text-center' colSpan={6}>
                          Nenhum registro encontrado
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                className='table-footer-pesquisa'
                labelRowsPerPage="linhas por página"
                rowsPerPageOptions={[10, 25]}
                component="div"
                count={total}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </Paper>
          </Box>
      </form>
    </>    
  )
}

export {TableParticipantesPesquisa}