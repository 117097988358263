import * as Yup from 'yup'

export interface IDescreverValorModel {
  Id?: number
  IdResposta?: number
  Texto: string
  ObservacaoReprovado?: string
}

export const defaultDescreverValor: IDescreverValorModel = {
  Id: undefined,
  IdResposta: undefined,
  Texto: '',
  ObservacaoReprovado: undefined
}

export const defaultDescreverValores: IDescreverValorModel[] = []

export interface IDescreverRespostaModel {
  Id?: number
  IdDescricao?: number
  IdCampo?: number
  IdValorAtual?: number
  Ordem: number
  ValorAtual?: IDescreverValorModel
  Valores: IDescreverValorModel[]
}

export const defaultDescreverResposta: IDescreverRespostaModel = {
  Id: undefined,
  IdDescricao: undefined,
  IdCampo: undefined,
  IdValorAtual: undefined,
  Ordem: 0,
  ValorAtual: defaultDescreverValor,
  Valores: defaultDescreverValores
}

export const defaultDescreverRespostas: IDescreverRespostaModel[] = []

export interface IDescreverTipoCampoModel {
  Id?: number
  Nome: string
  Descricao: string
  MultiplasRespostas: boolean
  TipoInputHTML: number
  RespostaAutomatica: boolean
  InteracaoComAI: boolean
}

export const defaultDescreverTipoCampo: IDescreverTipoCampoModel = {
  Id: undefined,
  Nome: '',
  Descricao: '',
  MultiplasRespostas: false,
  TipoInputHTML: 1,
  RespostaAutomatica: false,
  InteracaoComAI: false
}

export interface IDescreverCampoModel {
  Id?: number
  IdModelo?: number
  IdTipoCampo?: number
  Nome: string
  TipoCampo: IDescreverTipoCampoModel
  Respostas: IDescreverRespostaModel[]
}

export const defaultDescreverCampo: IDescreverCampoModel = {
  Id: undefined,
  IdModelo: undefined,
  IdTipoCampo: undefined,
  Nome: '',
  TipoCampo: defaultDescreverTipoCampo,
  Respostas: defaultDescreverRespostas
}

export const defaultDescreverCampos: IDescreverCampoModel[] = []

export interface IDescreverDescricaoModel {
  Id?: number
  IdModelo?: number
  IdCargo?: number
  StatusAprovacao?: number
  NomeModelo: string
  NomeCargo: string
  NomeNivelHierarquico: string
  NomeArea: string
  Campos: IDescreverCampoModel[]
  IsAprovador: boolean
}

export const descreverDescricao: IDescreverDescricaoModel = {
  Id: undefined,
  IdModelo: undefined,
  IdCargo: undefined,
  StatusAprovacao: undefined,
  NomeModelo: '',
  NomeCargo: '',
  NomeNivelHierarquico: '',
  NomeArea: '',
  Campos: defaultDescreverCampos,
  IsAprovador: false
}

export const descreverDescricaoValidation = Yup.object({
  IdModelo: Yup.string().required('O modelo é obrigatório'),    
  IdCargo: Yup.string().required('O cargo é obrigatório'),
})