import * as React from 'react';
import { TableDescricoesGeradas } from './partials/TableDescricoesGeradas';

const ProcessoDescricoesDeCargo: React.FC = () => {
  return (
    <TableDescricoesGeradas />
  )
}

export {ProcessoDescricoesDeCargo}
