/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { ReactNode, useEffect, useState } from 'react'
import { Field, FormikProps } from 'formik'
import { ServicoModel, useAuth } from '../../../../modules/auth'
import { IExemploDimensaoPerguntaParticipanteModel } from '../helpers/PesquisaHelper'

type Props = {
  textos: IExemploDimensaoPerguntaParticipanteModel[] | undefined,
}

const PesquisaPerguntasParticipante: React.FC<Props> = ({
  textos,
}) => {
  const {auth} = useAuth()
  const servico = auth?.servico

  const [currentUrl, setCurrentUrl] = useState('');

  useEffect(()=>{
    const url = window.location.pathname;
    setCurrentUrl(url);

    const handleUrlChange = () => {
      const updatedUrl = window.location.href;
      setCurrentUrl(updatedUrl);
    };

    window.addEventListener('popstate', handleUrlChange);

    return () => {
      window.removeEventListener('popstate', handleUrlChange);
    };
  }, [])

  return (
    <>
      {textos && textos.map((texto, textoIndex) => (
        <div 
          key={textoIndex}
          className={`row w-100 ${textoIndex < (textos.length - 1) ? 'mb-5' : ''}`}
        >
          <div className='col-md-11'>
            <textarea                  
              className={`form-control form-control-lg form-control-solid`}                                     
              value={texto.Texto ?? ''}
              disabled={true}
            />

          </div>
          <div className='col-md-1 d-flex align-items-center justify-content-end'>
            {texto.Respondida && (
                <span className='fs-1 '><i className="fs-1 text-end text-success fas fa-check"></i></span>
            )}
            {!texto.Respondida && (
                <span className='fs-1 '><i className="fs-1 text-end text-danger fas fa-times"></i></span>
            )}
          </div>
        </div>
      ))}
    </>
  )
}

export {PesquisaPerguntasParticipante}
