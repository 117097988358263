import { ErrorMessage, Field, FormikProvider, useFormik } from 'formik'
import { KTSVG } from '../../../../../_metronic/helpers'
import { DropzoneComponent, Tooltip, useAlert, useHelper, useHttpClient } from '../../../../modules/services/Bundle'
import { RequestMethod } from '../../../../modules/services/core/_enums'
import { defaultCarregarDescricoes, defaultValidationSchemas } from '../helpers/ModalCarregarDescricoesHelper'
import { FileModel, ISelectModel } from '../../../../modules/services/core/_models'
import { useEffect, useState } from 'react'

type Props = {
    abrirModal: boolean
    setAbrirModal: Function
    isConsultoria: boolean
    empresasCliente: ISelectModel[]
}

const ModalCarregarDescricoes = ({abrirModal, setAbrirModal, isConsultoria, empresasCliente}: Props) => {
    let [modelos, setModelos] = useState<any[]>([])
    const httpClient = useHttpClient()
    const alert = useAlert()
    const helper = useHelper()
    const [idEmpresaClienteSelecionada, setIdEmpresaClienteSelecionada] = useState<number>(0)
    
    const formik = useFormik({
        initialValues: defaultCarregarDescricoes,
        validationSchema: defaultValidationSchemas,
        onSubmit: async (values, actions) => {

            alert.createDialog({
                html: `Realmente deseja carregar essas descrições?`,
                confirmAction: async () => {
                    
                    const response = await httpClient.request({
                        method: RequestMethod.POST, 
                        endpoint: `/Gerenciamento/Modelo/CarregarDescricoes`,
                        queryObject: {
                            isConsultoria: isConsultoria,
                            idEmpresa: idEmpresaClienteSelecionada
                        },
                        data: values,
                        ifSuccessRedirectTo: '/conta/uploads',
                    })
                    
                    if (response.success) {
                        setAbrirModal(false)
                    }

                }
            })
    
        },
    })

    const obterModelos = () => {
        if(formik.values.IdModelo) formik.setFieldValue('IdModelo', undefined)
        setModelos([])
        httpClient.request({
            method: RequestMethod.GET,
            endpoint: `/Gerenciamento/Modelo/Obter`,
            queryObject: {
                isConsultoria: isConsultoria,
                idEmpresa: idEmpresaClienteSelecionada
            },
        }).then((response) => {
            if (response.success && response.payload && response.payload.length > 0) {
                setModelos(response.payload)
                formik.setFieldValue('IdModelo', response.payload[0].Id)
            }
        })
    }

    useEffect(() => {
        obterModelos() 
    }, [])

    useEffect(() => {
        if(isConsultoria) obterModelos() 
    }, [idEmpresaClienteSelecionada])

    const filesHandler = async (files: Dropzone.DropzoneFile[]) => { 
        if(files && files.length > 0){

            const file = files[0]
            const base64 = await helper.convertFileToBase64(file)
            
            formik.setFieldValue('Arquivo', {
                Nome: file.name,
                Extensao: file.name.split('.').pop(),
                Tamanho: file.size,
                Tipo: file.type,
                Base64: base64
            })

        }
        else formik.setFieldValue('Arquivo', undefined)
    }

    const onClickBaixarModeloCarregar = async () => { 
        
        const idModelo = formik.values.IdModelo

        if (!idModelo) {
            alert.createMessage({
                html: 'É necessário selecionar um modelo de formulário!',
                icon: 'error',
            })
        }
        else{            
            const response = await httpClient.request({
                method: RequestMethod.GET, 
                endpoint: `/Gerenciamento/Modelo/BaixarModeloCarregar`,
                queryObject: {
                    idModelo: idModelo
                }
            })
            
            if (response.success && response.payload) {
                const file: FileModel = response.payload
                await helper.downloadFile(file)
            }
        }        

    }

    const onClickBaixarCargo = async () => { 
        
        const idModelo = formik.values.IdModelo

        if (!idModelo) {
            alert.createMessage({
                html: 'É necessário selecionar um modelo de formulário!',
                icon: 'error',
            })
        }
        else{            
            const response = await httpClient.request({
                method: RequestMethod.GET, 
                endpoint: `/Gerenciamento/Modelo/BaixarCargo`,
                queryObject: {
                    idModelo: idModelo
                }
            })
            
            if (response.success && response.payload) {
                const file: FileModel = response.payload
                await helper.downloadFile(file)
            }
        }        

    }

    return (
    <div className={`modal show fade`} tabIndex={-1} id="kt_modal_1" style={{display: "block"}}>
        <div className="modal-dialog modal-dialog-centered modal-xl">
            <div className="modal-content">
                <div className="modal-header">
                    <h5 className="modal-title">Carregar descrições</h5>
                    <div
                    onClick={() => setAbrirModal(false)}
                    className="btn btn-icon btn-sm btn-active-light-primary ms-2"
                    data-bs-dismiss="modal"
                    aria-label="Fechar"
                    >
                    <KTSVG
                        path="/media/icons/duotune/arrows/arr061.svg"
                        className="svg-icon svg-icon-2x"
                    />
                    </div>
                </div>
                <div className="modal-body">
                    
                    <FormikProvider value={formik}>
                        <div className='w-100'>
                            <div className="row">

                                <div className='col-lg-12 fv-row mb-10'>
                                    <div className='notice d-flex bg-light-warning rounded border-warning border border-dashed p-6'>
                                        <KTSVG
                                        path='/media/icons/duotune/general/gen044.svg'
                                        className='svg-icon-2tx svg-icon-warning me-4'
                                        />
                                        <div className='d-flex flex-stack flex-grow-1'>
                                        <div className='fw-bold'>
                                            <div className='fs-6 text-gray-600'>
                                            Clique no botão abaixo para baixar o modelo e preencha os dados na coluna correta sem alterar os nomes das colunas ou da planilha, depois carregue o arquivo novamente
                                            </div>
                                        </div>
                                        </div>
                                    </div>
                                </div>

                                {(isConsultoria) && (
                                    <>
                                        <div className='col-lg-12 mb-10'>
                                            <label className='form-label required'>Cliente</label>
                                            <select
                                                className='form-select form-select-lg form-select-solid'
                                                value={idEmpresaClienteSelecionada}
                                                onChange={(e: React.ChangeEvent<HTMLSelectElement>) => { setIdEmpresaClienteSelecionada(parseInt(e.target.value)) }}
                                            >
                                                <option value={0}>Selecione...</option>
                                                {empresasCliente && empresasCliente.length > 0 && empresasCliente.map((data: ISelectModel, index: number) => {
                                                    return (
                                                    <option key={`cliente-option-${index}`} value={data.Id}>
                                                        {data.Nome}
                                                    </option>
                                                    )
                                                })}
                                            </select>
                                        </div>
                                    </>
                                )}      

                                <div className='col-lg-6 fv-row mb-10'>
                                    <label className='form-label required'>Modelo do formulário</label>
                                    <Field
                                        as='select'
                                        name='IdModelo'
                                        className='form-select form-select-lg form-select-solid'
                                        value={formik.values.IdModelo}
                                    >
                                        <option value={''}>Selecione...</option>
                                        {modelos && modelos.length > 0 && modelos.map((data: any, index: number) => {
                                            return (
                                                <option key={`IdModelo-option-${index}`} value={data.Id}>
                                                    {data.Nome}
                                                </option>
                                            )
                                        })}
                                    </Field>
                                    <div className='text-danger mt-2'>
                                        <ErrorMessage name='IdModelo' />
                                    </div>
                                </div>

                                <div className='col-lg-6 fv-row mb-10 text-center'>
                                    
                                    <label className='form-label'>Gerar respostas para campos em branco?
                                        <Tooltip title='Gera as respostas para os campos de interação com IA automaticamente durante o upload caso eles estejam vazios no modelo'>
                                            <i className='fas fa-exclamation-circle text-info ms-2 fs-5'></i>
                                        </Tooltip>
                                    </label>
                                                                        
                                    <div className='form-check form-switch form-check-custom form-check-solid d-flex justify-content-center mx-0 mt-4'>
                                        <Field
                                            type='checkbox'
                                            name={`GerarRespostasParaCamposEmBranco`}
                                            className='form-check-input ms-2 h-25px w-40px'
                                            checked={formik.values.GerarRespostasParaCamposEmBranco}
                                            value={formik.values.GerarRespostasParaCamposEmBranco}
                                        ></Field>
                                    </div>
                                </div>

                                <div className='fv-row mb-10 d-flex justify-content-center'>  
                                    <div className='col-lg-2'>
                                        <button
                                            type='button'
                                            className='btn btn-lg btn-light-primary'
                                            onClick={onClickBaixarModeloCarregar}
                                        >
                                            <i className="fas fs-1 me-1 bi bi-file-arrow-down-fill"></i>
                                            Baixar modelo
                                        </button>
                                    </div>

                                    
                                    <div className='col-lg-2'>
                                        <button
                                            type='button'
                                            className='btn btn-lg btn-light-primary'
                                            onClick={onClickBaixarCargo}
                                        >
                                            <i className="fas fs-1 me-1  fa-file-download"></i>
                                            Baixar Cargos
                                        </button>
                                    </div>
                                </div>


                                <div className='col-lg-12 fv-row'>
                                    <DropzoneComponent 
                                        filesHandler={filesHandler}
                                        id='dz-carregar-descricoes'
                                        preloadFiles={formik.values.Arquivo}
                                        options={{ 
                                            dictDefaultMessage: 'Arraste e solte o arquivo .xlsx do modelo preenchido com os dados ou clique aqui para procurar',
                                            acceptedFiles: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
                                            maxFiles: 1,
                                            maxFilesize: 2
                                        }}
                                    />
                                    <div className='text-danger mt-2'>
                                        <ErrorMessage name='Arquivo' />
                                    </div>
                                </div>

                            </div>
                        </div>
                    </FormikProvider>
                </div>
                <div className="modal-footer">
                    <button
                        onClick={() => setAbrirModal(false)}
                        type="button"
                        className="btn btn-light me-5"
                        data-bs-dismiss="modal"
                    >
                        Cancelar
                    </button>
                    
                    <button type='button' onClick={() => formik.handleSubmit()} className={`btn btn-primary`}>
                        Carregar
                    </button>

                </div>
            </div>
        </div>
    </div>
  )
}

export {ModalCarregarDescricoes}