/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { ReactNode, useEffect, useRef, useState } from 'react'
import { Field, FormikProps } from 'formik'
import { IPesquisaRespostaModel, IPesquisaRespostaPerguntaModel, IWizardModel } from '../helpers/CrudTemaHelper'
import { SortableContainer, SortableElement, SortableHandle } from 'react-sortable-hoc'
import { useAlert, useHttpClient } from '../../../../modules/services/Bundle'
import { EnumServicoTipoPlano, EnumStepsTema, RequestMethod } from '../../../../modules/services/core/_enums'
import { ServicoModel, useAuth } from '../../../../modules/auth'
import { KTSVG } from '../../../../../_metronic/helpers'
import { IconButton } from '@mui/material'
import zIndex from '@mui/material/styles/zIndex'

interface SortableHandleProps {
  textoSelecionado: number | undefined,
  idTexto?: number
} 

const DragHandle = SortableHandle<SortableHandleProps>(({textoSelecionado, idTexto}: SortableHandleProps) => 
<span className={textoSelecionado && idTexto && textoSelecionado === idTexto ? '' : 'd-none'} style={{
  position: 'relative',
  float: 'right',    
  top: '4px',
  right: '6px',
  height: '0px',
  cursor: 'move'
}}>
  <i className="fas fa-grip-vertical"></i>
</span>
)

interface SortableElementProps {
  textos: IPesquisaRespostaModel[],
  numeroStep: number,
  tituloIndex: number,
  textoSelecionado: number | undefined,
  textoSendoEditado: boolean,
  texto: IPesquisaRespostaModel,
  textoIndex: number,
  onClickTexto: Function,
  textoDadosClass: string,
  servico: ServicoModel | undefined,
  currentUrl: string;
  tipoRedirect: string
  indexDimensaoSelecionada: number | undefined,
  utilizaQuestao: boolean | false
  formik: FormikProps<IWizardModel>
} 

const SortableItem = SortableElement<SortableElementProps>(({textos, indexDimensaoSelecionada, numeroStep, tituloIndex, textoSelecionado, textoSendoEditado, texto, textoIndex, onClickTexto, textoDadosClass, servico, currentUrl, tipoRedirect, utilizaQuestao, formik}: SortableElementProps) => {
  const [showInputQuestion, setShowInputQuestion] = useState(false)
  const alert = useAlert()
  const httpClient = useHttpClient()
  const questoesRef = useRef<HTMLDivElement>(null)
  let temaAtual = formik.values.tema.find(t => t.Id == formik.values.temaBuscarDimensao.Id);

  return (
  <div 
    className={`${textoIndex < (textos.length - 1) ? 'mb-5' : ''}`}
    onClick={() => onClickTexto(texto)}
  >
    {tipoRedirect !== 'Visualizar' && (
      <DragHandle 
        textoSelecionado={textoSelecionado}
        idTexto={texto.Id}
      />
    )}
    
    {numeroStep === EnumStepsTema.Tema && (
      <>
        {!textoSendoEditado ? (
          <span
            className={`form-control form-control-lg form-control-solid textarea-texto-pesquisa ${textoDadosClass} ${textoSelecionado === texto.Id ? 'texto-pesquisa-selecionada' : 'texto-pesquisa-nao-selecionada'}`}
          >
            {texto.PesquisaValorAtual.Texto ?? ''}
          </span>
          ) : (
            <Field
              as='textarea'
              name={`tema.${tituloIndex}.Topico.${textoIndex}.PesquisaValorAtual.Texto`}                    
              className={`form-control form-control-lg form-control-solid textarea-texto-pesquisa ${textoDadosClass} ${textoSelecionado === texto.Id ? 'texto-pesquisa-selecionada' : 'texto-pesquisa-nao-selecionada'}`}
              disabled={textoSelecionado !== texto.Id || (textoSelecionado === texto.Id && !textoSendoEditado)}                                         
              value={texto.PesquisaValorAtual.Texto ?? ''}
            />
        )}
      </>
    )}
    {numeroStep === EnumStepsTema.Perguntas && (
      <>
      <div className='flex-nowrap d-flex align-items-center'>
          <div className={`${utilizaQuestao ? 'col-md-11' : 'w-100'}`}>
          {!textoSendoEditado ? (
              <span
                className={`form-control form-control-lg form-control-solid textarea-texto-pesquisa ${textoDadosClass} ${textoSelecionado === texto.Id ? 'texto-pesquisa-selecionada' : 'texto-pesquisa-nao-selecionada'}`}
              >
                {texto.PesquisaValorAtual.Texto ?? ''}
              </span>
              ) : (
                <Field
                  as='textarea'
                  name={`temaBuscarDimensao.Dimensao.${indexDimensaoSelecionada}.Perguntas.${textoIndex}.PesquisaValorAtual.Texto`}                    
                  className={`form-control form-control-lg form-control-solid textarea-texto-pesquisa ${textoDadosClass} ${textoSelecionado === texto.Id ? 'texto-pesquisa-selecionada' : 'texto-pesquisa-nao-selecionada'}`}
                  disabled={textoSelecionado !== texto.Id || (textoSelecionado === texto.Id && !textoSendoEditado)}                                         
                  value={texto.PesquisaValorAtual.Texto ?? ''}
                />

            )}
          </div>
          {utilizaQuestao && (
            showInputQuestion 
            || (temaAtual!.Dimensao[indexDimensaoSelecionada!].Perguntas.find(x => x.Id == texto.Id)!.QuestaoA 
            || temaAtual!.Dimensao[indexDimensaoSelecionada!].Perguntas.find(x => x.Id == texto.Id)!.QuestaoB)
            && tipoRedirect !== "Visualizar"
            ? (
              <div className='col-md-1'>
                <IconButton 
                  disabled={temaAtual?.ExistePesquisa}
                  className='ms-2'
                  title='Remover questões'
                  children={<KTSVG path="/media/icons/duotune/arrows/arr010.svg" className="svg-icon-danger svg-icon-2hx" />}
                  onClick={(e) => {
                    e.preventDefault()

                    alert.createDialog({
                      html: "Deseja remover as questões desta pergunta?",
                      confirmAction: () => {
                        httpClient.request({
                          method: RequestMethod.DELETE,
                          endpoint: '/Pesquisa/ConfiguracoesIniciais/RemoverQuestaoNaPergunta',
                          queryObject: {
                            idPergunta: texto.Id
                          }
                        }).then((response) => {
                          if(response.success){
                            formik.values.tema.find(t => t.Id == temaAtual?.Id)!.Dimensao[indexDimensaoSelecionada!].Perguntas.find(x => x.Id == texto.Id)!.QuestaoA = "";
                            temaAtual!.Dimensao[indexDimensaoSelecionada!].Perguntas.find(x => x.Id == texto.Id)!.QuestaoA = "";
                            formik.values.tema.find(t => t.Id == temaAtual?.Id)!.Dimensao[indexDimensaoSelecionada!].Perguntas.find(x => x.Id == texto.Id)!.QuestaoB = "";
                            temaAtual!.Dimensao[indexDimensaoSelecionada!].Perguntas.find(x => x.Id == texto.Id)!.QuestaoB = "";
                            setShowInputQuestion(false)
                          }
                        })
                      }
                    })
                  }}
                  
                />
                <IconButton 
                  disabled={temaAtual?.ExistePesquisa}
                  className='ms-2'
                  title='Salvar questões'
                  children={<KTSVG path="/media/icons/duotune/arrows/arr012.svg" className="svg-icon-success svg-icon-2hx" />}
                  onClick={(e) => {
                    
                    e.preventDefault()
                    const questaoA = (questoesRef.current!.querySelector(`input.questao[name="QuestaoA"][data-id="${texto.Id}"]`) as HTMLInputElement).value
                    const questaoB = (questoesRef.current!.querySelector(`input.questao[name="QuestaoB"][data-id="${texto.Id}"]`) as HTMLInputElement).value

                    if(questaoA == "" || questaoB == ""){
                      return alert.createMessage({
                        html: "Preencha as questões A e B para salvar!",
                        icon: 'warning'
                       })
                    }

                    alert.createDialog({
                      html: "Deseja salvar as questões para essa pergunta?",
                      confirmAction: () => {
                        httpClient.request({
                          method: RequestMethod.POST,
                          endpoint: '/Pesquisa/ConfiguracoesIniciais/AdicionarQuestaoNaPergunta',
                          queryObject: {
                            idPergunta: texto.Id,
                            questaoA,
                            questaoB
                          }
                        });
                      }
                    })
                  }}
                />
              </div>
            ) : (
              <>
               {tipoRedirect !== "Visualizar" && (
                  <div className='col-md-1'>
                    <IconButton 
                      disabled={temaAtual?.ExistePesquisa}
                      className='ms-2'
                      title='Adicionar questões'
                      children={<KTSVG path="/media/icons/duotune/arrows/arr009.svg" className="svg-icon-success svg-icon-2hx" />}
                      onClick={() => setShowInputQuestion(true)}
                    />
                  </div>
                )}
              </>

            )
          )}
        </div>
        {utilizaQuestao && (showInputQuestion || temaAtual!.Dimensao[indexDimensaoSelecionada!].Perguntas.find(x => x.Id == texto.Id)!.QuestaoA || temaAtual!.Dimensao[indexDimensaoSelecionada!].Perguntas.find(x => x.Id == texto.Id)!.QuestaoB) && (
          <>
          <div className='d-flex flex-nowrap mt-5 align-items-center' ref={questoesRef}>
            <div className='col-md-11'>
              <div className="input-group mb-3">
                <div className="input-group-prepend">
                  <span className="input-group-text">A)</span>
                </div>
                  <input
                    name={`QuestaoA`}                    
                    className={`form-control form-control-solid ms-1 questao`}
                    defaultValue={temaAtual!.Dimensao[indexDimensaoSelecionada!].Perguntas.find(x => x.Id == texto.Id)!.QuestaoA ?? ''}
                    placeholder={`Insira o texto aqui`}
                    data-id={texto.Id}
                    disabled={tipoRedirect == "Visualizar" || temaAtual?.ExistePesquisa}
                    onBlur={(e) => {
                      formik.values.tema.find(t => t.Id == temaAtual?.Id)!.Dimensao[indexDimensaoSelecionada!].Perguntas.find(x => x.Id == texto.Id)!.QuestaoA = e.target.value;
                      temaAtual!.Dimensao[indexDimensaoSelecionada!].Perguntas.find(x => x.Id == texto.Id)!.QuestaoA = e.target.value;
                    }}
                  />
              </div>
              <div className="input-group mb-3">
                <div className="input-group-prepend">
                  <span className="input-group-text">B)</span>
                </div>
                <input
                  name={`QuestaoB`}                    
                  className={`form-control form-control-solid ms-1 questao`}
                  defaultValue={temaAtual!.Dimensao[indexDimensaoSelecionada!].Perguntas.find(x => x.Id == texto.Id)!.QuestaoB ?? ''}
                  placeholder={`Insira o texto aqui`}
                  data-id={texto.Id}
                  disabled={tipoRedirect == "Visualizar" || temaAtual?.ExistePesquisa}
                  onBlur={(e) => {
                    formik.values.tema.find(t => t.Id == temaAtual?.Id)!.Dimensao[indexDimensaoSelecionada!].Perguntas.find(x => x.Id == texto.Id)!.QuestaoB = e.target.value;
                    temaAtual!.Dimensao[indexDimensaoSelecionada!].Perguntas.find(x => x.Id == texto.Id)!.QuestaoB = e.target.value;
                  }}
                />
              </div>
            </div>
            <div className={`col-md-1 ${tipoRedirect == "Visualizar" ? "d-none" : ""}`}>
              <div className="alert alert-warning d-flex align-items-center p-3 ms-1">              
                <div className="d-flex flex-column">
                  <span className='fs-9 text-justify'>Salve as questões no <KTSVG path="/media/icons/duotune/arrows/arr012.svg" className="svg-icon-success fs-9" /> após finalizá-las!</span>
                </div>
              </div>
            </div>
          </div>
          </>
        )}
      </>
    )}
    {numeroStep === EnumStepsTema.Dimensões && (
        <>
          {!textoSendoEditado ? (
            <span
              className={`form-control form-control-lg form-control-solid textarea-texto-pesquisa ${textoDadosClass} ${textoSelecionado === texto.Id ? 'texto-pesquisa-selecionada' : 'texto-pesquisa-nao-selecionada'}`}
            >
              {texto.PesquisaValorAtual.Texto ?? ''}
            </span>
            ) : (
              <Field
                as='textarea'
                name={`temaBuscarDimensao.Dimensao.${tituloIndex}.DimensaoDescricoes.${textoIndex}.PesquisaValorAtual.Texto`}                    
                className={`form-control form-control-lg form-control-solid textarea-texto-pesquisa ${textoDadosClass} ${textoSelecionado === texto.Id ? 'texto-pesquisa-selecionada' : 'texto-pesquisa-nao-selecionada'}`}
                disabled={textoSelecionado !== texto.Id || (textoSelecionado === texto.Id && !textoSendoEditado)}                                         
                value={texto.PesquisaValorAtual.Texto ?? ''}
              />
          )}
        </>
      )}
  </div>
)})

interface SortableContainerProps {
  children: ReactNode
} 

const SortableList = SortableContainer<SortableContainerProps>(({children}: SortableContainerProps) => {
  return <div>{children}</div>;
})

type Props = {
  formik: FormikProps<IWizardModel>,
  tipoRedirect: string
  textos: IPesquisaRespostaModel[] | undefined,
  textoModel: IPesquisaRespostaModel | undefined,
  numeroStep: number
  tituloIndex: number,
  tituloSelecionado: number | undefined,
  indexDimensaoSelecionada: number | undefined,
  fazerBackupDaTexto: Function,
  setTituloSelecionado: React.Dispatch<React.SetStateAction<number | undefined>>,
  textoSelecionado: number | undefined,
  setTextoSelecionado: React.Dispatch<React.SetStateAction<number | undefined>>,
  textoSendoEditado: boolean,
  setTextoSendoEditado: React.Dispatch<React.SetStateAction<boolean>>,
  textoBackup: IPesquisaRespostaModel | undefined,
  setTextoBackup: React.Dispatch<React.SetStateAction<IPesquisaRespostaModel | undefined>>,  
}

const PesquisaTextos: React.FC<Props> = ({
  formik,
  tipoRedirect,
  textos,
  textoModel,
  numeroStep,
  tituloIndex,
  fazerBackupDaTexto,
  tituloSelecionado,
  setTituloSelecionado,
  textoSelecionado,
  setTextoSelecionado,
  textoSendoEditado,
  setTextoSendoEditado,
  textoBackup,
  setTextoBackup, 
  indexDimensaoSelecionada 
}) => {
  const textoDadosClass = `texto-pesquisa texto-pesquisa-interativa`
  const httpClient = useHttpClient()

  
  const {auth} = useAuth()
  const servico = auth?.servico

  const [currentUrl, setCurrentUrl] = useState('');

  useEffect(()=>{
    const url = window.location.pathname;
    setCurrentUrl(url);

    const handleUrlChange = () => {
      const updatedUrl = window.location.href;
      setCurrentUrl(updatedUrl);
    };

    window.addEventListener('popstate', handleUrlChange);

    return () => {
      window.removeEventListener('popstate', handleUrlChange);
    };
  }, [])


  const onClickTexto = (texto: IPesquisaRespostaModel) => {
    let pesquisa = formik.values
    let existePesquisa:boolean = false
    if (numeroStep === EnumStepsTema.Tema){
      let tituloIndex = pesquisa.tema.findIndex((c => c.Id === texto.IdEntidade))
      existePesquisa = pesquisa.tema[tituloIndex].ExistePesquisa
    }
    if (numeroStep === EnumStepsTema.Dimensões){
      existePesquisa = pesquisa.temaBuscarDimensao.ExistePesquisa
    }
    if (numeroStep === EnumStepsTema.Perguntas && indexDimensaoSelecionada) {
      existePesquisa = pesquisa.temaBuscarDimensao.ExistePesquisa
    }

    if (existePesquisa) {
      setTituloSelecionado(undefined)
      setTextoSelecionado(undefined)
      setTextoBackup(undefined)
    }
    else if(textoSelecionado != texto.Id){
      fazerBackupDaTexto()
      setTituloSelecionado(texto.IdEntidade)
      setTextoSelecionado(texto.Id)
      setTextoBackup(texto)
    }
  }
  
  const moverTexto = (textos: IPesquisaRespostaModel[] | IPesquisaRespostaPerguntaModel[], oldIndex: number, newIndex: number) => {
    var texto = textos[oldIndex]
    textos.splice(oldIndex, 1)
    textos.splice(newIndex, 0, texto)
    for(let i = 0; i < textos.length; i++) textos[i].Ordem = i
    return textos
  }

  interface OnSortProps {
    oldIndex: number,
    newIndex: number
  }

  const onSortEnd = async ({oldIndex, newIndex}: OnSortProps) => {

    if(oldIndex !== newIndex) {
      let pesquisa = formik.values
      if (numeroStep === EnumStepsTema.Tema ) {
        let tituloIndex = pesquisa.tema.findIndex((c => c.Id === tituloSelecionado))
        pesquisa.tema[tituloIndex].Topico = moverTexto(pesquisa.tema[tituloIndex].Topico, oldIndex, newIndex)
      }
      if (numeroStep === EnumStepsTema.Perguntas ) {
        pesquisa.temaBuscarDimensao.Dimensao[indexDimensaoSelecionada??0].Perguntas = moverTexto(pesquisa.temaBuscarDimensao.Dimensao[indexDimensaoSelecionada??0].Perguntas, oldIndex, newIndex)
      }
      if (numeroStep === EnumStepsTema.Dimensões ) {
        let tituloIndex = pesquisa.temaBuscarDimensao.Dimensao.findIndex((c => c.Id === tituloSelecionado))
        pesquisa.temaBuscarDimensao.Dimensao[tituloIndex??0].DimensaoDescricoes = moverTexto(pesquisa.temaBuscarDimensao.Dimensao[tituloIndex??0].DimensaoDescricoes, oldIndex, newIndex)
      }
      formik.setValues(pesquisa)

      const response = await httpClient.request({
        method: RequestMethod.GET,
        endpoint: '/Pesquisa/Tema/OrdenarResposta',
        queryObject: {
          tipoResposta: numeroStep,
          idEntidade: numeroStep === EnumStepsTema.Tema ? formik.values.tema[tituloIndex].Id : pesquisa.temaBuscarDimensao.Dimensao[indexDimensaoSelecionada??0].Id,
          oldIndex: oldIndex,
          newIndex: newIndex,
        }
      })

      if(!response.success) {
        let backupPesquisa = formik.values
        if (numeroStep === EnumStepsTema.Tema ) {
          backupPesquisa.tema[tituloIndex].Topico = moverTexto(backupPesquisa.tema[tituloIndex].Topico, newIndex, oldIndex)
        }
        if (numeroStep === EnumStepsTema.Perguntas ) {
          backupPesquisa.temaBuscarDimensao.Dimensao[indexDimensaoSelecionada??0].Perguntas = moverTexto(backupPesquisa.temaBuscarDimensao.Dimensao[indexDimensaoSelecionada??0].Perguntas, newIndex, oldIndex)
        }
        if (numeroStep === EnumStepsTema.Dimensões ) {
          let tituloIndex = pesquisa.temaBuscarDimensao.Dimensao.findIndex((c => c.Id === tituloSelecionado))
          backupPesquisa.temaBuscarDimensao.Dimensao[tituloIndex??0].DimensaoDescricoes = moverTexto(backupPesquisa.temaBuscarDimensao.Dimensao[tituloIndex??0].DimensaoDescricoes, newIndex, oldIndex)
        }
      }
    }
    
  }

  return (
    <>
      <SortableList onSortEnd={onSortEnd} useDragHandle>
        {textos && textos.map((texto, textoIndex) => (
          <SortableItem 
            key={`pesquisa-texto-${texto.Id}`}
            index={textoIndex}
            tipoRedirect={tipoRedirect}
            numeroStep={numeroStep}
            indexDimensaoSelecionada={indexDimensaoSelecionada}
            textos={textos}
            tituloIndex={tituloIndex}
            textoSelecionado={textoSelecionado}
            textoSendoEditado={textoSendoEditado}
            texto={texto}
            textoIndex={textoIndex}
            onClickTexto={onClickTexto}
            servico={servico}
            textoDadosClass={textoDadosClass}
            currentUrl={currentUrl}
            utilizaQuestao={formik.values.tema[0].UtilizaQuestao || false}
            formik={formik}
          />
        ))}
      </SortableList>
    </>
  )
}

export {PesquisaTextos}
