import {Route, Routes, Outlet, Navigate} from 'react-router-dom'
import {PageLink, PageTitle} from '../../../_metronic/layout/core'
import { Tutorial } from './components/Tutorial'
import { Detalhes } from './components/Detalhes'
import { ArrayBreadcrumb, BreadcrumbLayout } from '../../modules/services/core/Breadcrumb'
import { useAuth } from '../../modules/auth'
import { EnumServicoTipoPlano } from '../../modules/services/core/_enums'


const wizardsBreadCrumbsTutorial: Array<ArrayBreadcrumb> = [
  {
    titulo: 'Ajuda',
    path: '/ajuda/tutorial',
  }
]

const wizardsBreadCrumbs: Array<ArrayBreadcrumb> = []

const AjudaPage = () => {

  const {auth} = useAuth()
  const servico = auth?.servico

  return (
    <Routes>
      <Route element={<Outlet />}>    
        {(servico?.tipoPlano === EnumServicoTipoPlano.Try || ((auth?.roles.find(x => x === 'Master') !== undefined) || (auth?.roles.find(x => x === 'Administrador') !== undefined) || (auth?.permissoes.find(x => x === 'B60EE4361CAD2C6C9DCE601B8CDF200E')) !== undefined)) && (
          <>
              <Route
                path='tutorial'
                element={
                  <>
                    <BreadcrumbLayout props={wizardsBreadCrumbs}>Tutorial</BreadcrumbLayout>
                    <Tutorial />
                  </>
                }
              />
              <Route
                path='detalhes/:id'
                element={
                  <>
                    <BreadcrumbLayout props={wizardsBreadCrumbsTutorial}>Detalhes</BreadcrumbLayout>
                    <Detalhes />
                  </>
                }
              />
          </>
        )}
        <Route index element={<Navigate to='/ajuda/tutorial/:id' />} />
      </Route>
    </Routes>
  )
}

export default AjudaPage
