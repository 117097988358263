import { FC, useState } from 'react'
import {Field, ErrorMessage, FieldArray, FormikProps} from 'formik'
import { IPesquisaRespostaModel, ITemaModel, IWizardModel, StepProps, defaultTema } from '../helpers/ConfiguracoesIniciaisHelper'
import { useAlert, useHttpClient } from '../../../../modules/services/Bundle'
import { RequestMethod } from '../../../../modules/services/core/_enums'
import { AbrirModalVideo } from '../../../toolbar/AbrirModalVideo'
import $ from 'jquery';
import { IconButton } from '@mui/material'
import { KTSVG } from '../../../../../_metronic/helpers'
import { PesquisaAccordions } from '../partials/PesquisaAccordions'
import { ModalDimensao } from '../partials/ModalDimensao'

interface Step3Props {
  formik: FormikProps<IWizardModel>,
  numeroStep: number
  indexSelecionado: number | undefined,
  setIndexSelecionado: React.Dispatch<React.SetStateAction<number | undefined>>,
  tituloSelecionado: number | undefined,
  setTituloSelecionado: React.Dispatch<React.SetStateAction<number | undefined>>,
  textoSelecionado: number | undefined,
  setTextoSelecionado: React.Dispatch<React.SetStateAction<number | undefined>>,
  textoSendoEditado: boolean,
  setTextoSendoEditado: React.Dispatch<React.SetStateAction<boolean>>,
  textoBackup: IPesquisaRespostaModel | undefined,
  setTextoBackup: React.Dispatch<React.SetStateAction<IPesquisaRespostaModel | undefined>>,
}

const Step3: FC<Step3Props> = ({
  formik,
  indexSelecionado,
  setIndexSelecionado,
  tituloSelecionado,
  setTituloSelecionado,
  textoSelecionado,
  setTextoSelecionado,
  textoSendoEditado,
  setTextoSendoEditado,
  textoBackup,
  setTextoBackup,
  numeroStep
}) => {

  const httpClient = useHttpClient()
  const alert = useAlert()
  const [abrirModalDimensao, setAbrirModalDimensao] = useState(false)

  const onClickBuscarDimensoes = async () => {
    if (formik.values.dimensoesBuscar > 0 && formik.values.temaBuscarDimensao.Id !== 0) {
      if(formik.values.temaBuscarDimensao.Dimensao.length > 0){
        alert.createDialog({
          html: formik.values.temaBuscarDimensao.Dimensao.some(d => d.Perguntas.length > 0) 
          ? "As dimensões deste tema serão substituidas e suas perguntas serão apagadas. Deseja realmente buscar novas dimensões?" 
          : "As dimensões deste tema serão substituidas. Deseja realmente buscar novas dimensões?",
          confirmAction: async () => {
            await buscarDimensoes();
          }
        })
      } else {
        await buscarDimensoes();
      }
    }
  }

  const buscarDimensoes = async () => {
    const response = await httpClient.request({
      method: RequestMethod.GET,
      endpoint: '/Pesquisa/ConfiguracoesIniciais/BuscarDimensoes',
      queryObject: {
        idTema: formik.values.temaBuscarDimensao.Id,
        qtdDimensoes: formik.values.dimensoesBuscar,
      }
    })

    if (response.success && response.payload) {
      
      const newValues = formik.values.temaBuscarDimensao
      const temas = formik.values.tema

      const tema:ITemaModel = response.payload
      const indexTema = temas.findIndex(x => x.Id == tema.Id);
      temas[indexTema].Dimensao = tema.Dimensao;

      newValues.Dimensao = tema.Dimensao

      formik.setFieldValue('temaBuscarDimensao', newValues)
      formik.setFieldValue('tema', temas);
    }
  }

  return (
    <>
      {abrirModalDimensao && (
        <ModalDimensao 
          setAbrirModal={setAbrirModalDimensao} 
          formik={formik} 
          crud={false}
        />
      )}
      <div className='w-100 row '>   
            <div className='col-lg-6 mb-5'>
              <label className='form-label'>Selecione o tema abaixo e indique ao lado o número de dimensões de sua pesquisa</label>
              <Field
                as='select'
                name='temaBuscarDimensao.Id'
                className='form-select form-select-lg form-select-solid'
                value={formik.values.temaBuscarDimensao.Id}
                onChange={(event: { target: { value: any } }) => {
                  formik.handleChange(event);
                  const tema = event.target.value == "" ? defaultTema : formik.values.tema.find(t => t.Id == event.target.value)
                  formik.setFieldValue('temaBuscarDimensao', tema);
                  setTituloSelecionado(undefined)
                  setTextoSelecionado(undefined)
                  setTextoSendoEditado(false)
                  setTextoBackup(undefined)
                }}
              >
                <option value=''>Selecione...</option>
                {
                  formik.values.tema && formik.values.tema.length > 0 ? formik.values.tema.map((data: ITemaModel, index: any) => {
                    return <option key={`temaBuscarDimensao-option-${index}`} value={data.Id}>{data.Nome}</option>
                  }) : ''
                }
              </Field>
            </div>
            <div className='col-lg-4 mb-5'>
              <label className='form-label required'>Números de dimensões</label>
              <Field 
                name='dimensoesBuscar'
                type='number'
                placeholder='Números de dimensões'
                className='form-control form-control-lg form-control-solid'
              />
              <div className='text-danger mt-2'>
                <ErrorMessage name='dimensoesBuscar' />
              </div>
            </div>
            <div className='col-lg-1 mb-5 text-center'>
              <label className='form-label text-center'>Buscar</label>
              <div>
                <IconButton className='icon-button-dark-hover' disabled={(!(formik.values.temaBuscarDimensao.Id !== 0 && formik.values.dimensoesBuscar !== 0) || formik.values.temaBuscarDimensao.ExistePesquisa)} onClick={() => onClickBuscarDimensoes()}>
                  <KTSVG path="/media/prolead/pesquisa/icons/search_.svg" className="svg-icon-muted text-gray-800 svg-icon-2hx" />
                </IconButton>
              </div>
            </div>
            <div className='col-lg-1 mb-5 text-center'>
              <label className='form-label text-center'>Nova</label>
              <div>
                <IconButton onClick={() => setAbrirModalDimensao(true)} disabled={(formik.values.temaBuscarDimensao.Id === 0) || formik.values.temaBuscarDimensao.ExistePesquisa}>
                  <KTSVG path="/media/icons/duotune/arrows/arr017.svg" className="svg-icon-success svg-icon-2hx" />
                </IconButton>
              </div>
            </div>
            {formik.values.temaBuscarDimensao.Id !== 0 && (
              <div className='col-lg-12 mb-5 scroll scroll-height-step-4 h-475px '>
                <PesquisaAccordions
                  formik={formik}
                  indexDimensaoSelecionada={indexSelecionado}
                  numeroStep={numeroStep}
                  tituloSelecionado={tituloSelecionado}
                  setTituloSelecionado={setTituloSelecionado}
                  textoSelecionado={textoSelecionado}
                  setTextoSelecionado={setTextoSelecionado}
                  textoSendoEditado={textoSendoEditado}
                  setTextoSendoEditado={setTextoSendoEditado}
                  textoBackup={textoBackup}
                  setTextoBackup={setTextoBackup} 
                />
                <div className='text-danger mt-2'>
                  
                </div>
              </div>
            )}
      </div>
    </>
  )
}

export {Step3}
