/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useRef, useState } from 'react'
import { FormikProps } from 'formik'
import { useThemeMode } from '../../../../../_metronic/partials'
import { IPesquisaRespostaModel, IPesquisaRespostaPerguntaModel, IPesquisaValorModel, IWizardModel } from '../helpers/CrudTemaHelper'
import { Draggable } from 'drag-react'
import { Tooltip, useAlert, useHttpClient } from '../../../../modules/services/Bundle'
import { toAbsoluteUrl } from '../../../../../_metronic/helpers'
import { EnumServicoTipoPlano, EnumStepsTema, RequestMethod } from '../../../../modules/services/core/_enums'
import { useAuth } from '../../../../modules/auth'

type Props = {
  mostrarPainel: boolean
  formik: FormikProps<IWizardModel>,
  tituloSelecionado: number | undefined,
  setTituloSelecionado: React.Dispatch<React.SetStateAction<number | undefined>>,
  numeroStep: number
  dimensaoSelecionada: number | undefined,
  setNumeroStep: React.Dispatch<React.SetStateAction<number>>,
  textoSelecionado: number | undefined,
  setTextoSelecionado: React.Dispatch<React.SetStateAction<number | undefined>>,
  textoSendoEditado: boolean,
  setTextoSendoEditado: React.Dispatch<React.SetStateAction<boolean>>,
  textoBackup: IPesquisaRespostaModel | IPesquisaRespostaPerguntaModel | undefined,
  setTextostaBackup: React.Dispatch<React.SetStateAction<IPesquisaRespostaModel | IPesquisaRespostaPerguntaModel | undefined>>,
  abrirModalAddTexto: boolean,
  setAbrirModalAddTexto: React.Dispatch<React.SetStateAction<boolean>>,
  abrirModalArgumentarTexto: boolean,
  setAbrirModalArgumentarTexto: React.Dispatch<React.SetStateAction<boolean>>,
  abrirModalAprimorarTexto: boolean,
  setAbrirModalAprimorarTexto: React.Dispatch<React.SetStateAction<boolean>>,
  abrirModalExcluirPerguntas: boolean,
  setAbrirModalExcluirPerguntas: React.Dispatch<React.SetStateAction<boolean>>
}

const PesquisaPainel: React.FC<Props> = ({
  mostrarPainel,
  formik,
  tituloSelecionado,
  setTituloSelecionado,
  numeroStep,
  setNumeroStep,
  textoSelecionado,
  setTextoSelecionado,
  textoSendoEditado,
  setTextoSendoEditado,
  textoBackup,
  setTextostaBackup,
  abrirModalAddTexto,
  setAbrirModalAddTexto,
  abrirModalArgumentarTexto,
  setAbrirModalArgumentarTexto,
  abrirModalAprimorarTexto,
  setAbrirModalAprimorarTexto,
  abrirModalExcluirPerguntas,
  setAbrirModalExcluirPerguntas,
  dimensaoSelecionada
}) => {
  const {menuMode} = useThemeMode() 
  const [isMobile, setIsMobile] = useState(window.innerWidth < 992)  
  const sizeBarraRobo = useRef<HTMLDivElement>(null); 
  const btnMode = menuMode === 'light' ? 'btn-pesquisa' : 'btn-light-pesquisa'

  let titulo: IPesquisaRespostaModel | IPesquisaRespostaPerguntaModel | undefined = undefined
  if (numeroStep === EnumStepsTema.Dimensões) titulo = formik.values.temaBuscarDimensao.Dimensao.find(c => c.Id === tituloSelecionado)

  let resposta: IPesquisaRespostaModel | IPesquisaRespostaPerguntaModel | undefined = undefined
  let tituloIndex = numeroStep === EnumStepsTema.Tema ? formik.values.tema.findIndex((c => c.Id === tituloSelecionado)) : formik.values.temaBuscarDimensao.Dimensao.findIndex((c => c.Id === tituloSelecionado))
  if (numeroStep === EnumStepsTema.Tema && tituloSelecionado !== undefined && formik.values.tema.length > 0 && tituloIndex >= 0) resposta = formik.values.tema[tituloIndex].Topico.find(c => c.Id === textoSelecionado) ?? undefined
  if (numeroStep === EnumStepsTema.Dimensões && tituloSelecionado !== undefined && formik.values.temaBuscarDimensao.Dimensao.length > 0 && tituloIndex >= 0) resposta = formik.values.temaBuscarDimensao.Dimensao[tituloIndex].DimensaoDescricoes.find(c => c.Id === textoSelecionado) ?? undefined
  if (numeroStep === EnumStepsTema.Perguntas && dimensaoSelecionada) resposta = formik.values.temaBuscarDimensao.Dimensao[dimensaoSelecionada].Perguntas.find(c => c.Id === textoSelecionado) ?? undefined

  const httpClient = useHttpClient()
  const alert = useAlert()
  const {auth} = useAuth()
  
  useEffect(() => {

    const checkIsMobile = () => {
      setIsMobile(window.innerWidth < 992)
    };

    window.addEventListener("resize", checkIsMobile);

    return () => window.removeEventListener("resize", checkIsMobile) 

  }, [])

  const onClickEditar = () =>{

    setTextostaBackup(resposta)
    setTextoSendoEditado(true)    

  }

  const onClickSalvar = () =>{

    alert.createDialog({
      html: `Realmente deseja salvar a resposta editada?`,
      confirmAction: async () => {
          
        const response = await httpClient.request({
          method: RequestMethod.GET,
          endpoint: '/Pesquisa/Tema/SalvarResposta',
          queryObject: {
              tipoResposta: numeroStep,
              idEntidade: resposta?.Id,
              texto: resposta?.PesquisaValorAtual.Texto,
          }
        })
        let pesquisa = formik.values
        if(response.success && response.payload){
          let respostaModel:IPesquisaValorModel = response.payload
          if (numeroStep === EnumStepsTema.Tema){
            let tituloIndex = pesquisa.tema.findIndex((c => c.Id === tituloSelecionado))
            let respIndex = pesquisa.tema[tituloIndex].Topico.findIndex((c => c.Id === textoSelecionado))
                            
            pesquisa.tema[tituloIndex].Topico[respIndex].PesquisaValorAtual = respostaModel

            pesquisa.tema[tituloIndex].Topico[respIndex].PesquisaValores.push(respostaModel)

            pesquisa.tema[tituloIndex].Topico[respIndex].IdPesquisaValorAtual = respostaModel.Id
          }
          if (numeroStep === EnumStepsTema.Dimensões){
            let tituloIndex = pesquisa.temaBuscarDimensao.Dimensao.findIndex((c => c.Id === tituloSelecionado))
            let respIndex = pesquisa.temaBuscarDimensao.Dimensao[tituloIndex].DimensaoDescricoes.findIndex((c => c.Id === textoSelecionado))
            
            pesquisa.temaBuscarDimensao.Dimensao[tituloIndex].DimensaoDescricoes[respIndex].PesquisaValorAtual = respostaModel

            pesquisa.temaBuscarDimensao.Dimensao[tituloIndex].DimensaoDescricoes[respIndex].PesquisaValores.push(respostaModel)

            pesquisa.temaBuscarDimensao.Dimensao[tituloIndex].DimensaoDescricoes[respIndex].IdPesquisaValorAtual = respostaModel.Id
          }
          if (numeroStep === EnumStepsTema.Perguntas && dimensaoSelecionada){
            
              let respIndex = pesquisa.temaBuscarDimensao.Dimensao[dimensaoSelecionada].Perguntas.findIndex((c => c.Id === textoSelecionado))
              
              pesquisa.temaBuscarDimensao.Dimensao[dimensaoSelecionada].Perguntas[respIndex].PesquisaValorAtual = respostaModel

              pesquisa.temaBuscarDimensao.Dimensao[dimensaoSelecionada].Perguntas[respIndex].PesquisaValores.push(respostaModel)

              pesquisa.temaBuscarDimensao.Dimensao[dimensaoSelecionada].Perguntas[respIndex].IdPesquisaValorAtual = respostaModel.Id
          }
        }
        formik.setValues(pesquisa)  
        const newValues = formik.values.tema;
        newValues.map((tema) => {
          if(tema.Id === pesquisa.temaBuscarDimensao.Id){
            return tema.Dimensao = pesquisa.temaBuscarDimensao.Dimensao;
          }
        })
        formik.setFieldValue('tema', newValues);
        setTextostaBackup(undefined)
        setTextoSendoEditado(false)

      }
    })

    

  }

  const onClickAnterior = async () =>{

    const response = await httpClient.request({
      method: RequestMethod.GET,
      endpoint: '/Pesquisa/Tema/AnteriorValorResposta',
      queryObject: {
        tipoResposta: numeroStep,
        idEntidade: resposta?.Id,
      }
    })
    if(response.success && response.payload){

      const pesquisa = formik.values

      if (numeroStep === EnumStepsTema.Tema){
        let tituloIndex = pesquisa.tema.findIndex((c => c.Id === tituloSelecionado))
        let respIndex = pesquisa.tema[tituloIndex].Topico.findIndex((r => r.Id === textoSelecionado))
        
        pesquisa.tema[tituloIndex].Topico[respIndex].PesquisaValorAtual = response.payload

        pesquisa.tema[tituloIndex].Topico[respIndex].IdPesquisaValorAtual = response.payload.Id

      }
      if (numeroStep === EnumStepsTema.Dimensões){
        let tituloIndex = pesquisa.temaBuscarDimensao.Dimensao.findIndex((r => r.Id === tituloSelecionado))
        let respIndex = pesquisa.temaBuscarDimensao.Dimensao[tituloIndex].DimensaoDescricoes.findIndex((c => c.Id === textoSelecionado))
        
        pesquisa.temaBuscarDimensao.Dimensao[tituloIndex].DimensaoDescricoes[respIndex].PesquisaValorAtual = response.payload

        pesquisa.temaBuscarDimensao.Dimensao[tituloIndex].DimensaoDescricoes[respIndex].IdPesquisaValorAtual = response.payload.Id

      }
      if (numeroStep === EnumStepsTema.Perguntas && dimensaoSelecionada){ 
        let respIndex = pesquisa.temaBuscarDimensao.Dimensao[dimensaoSelecionada].Perguntas.findIndex((c => c.Id === textoSelecionado))

        pesquisa.temaBuscarDimensao.Dimensao[dimensaoSelecionada].Perguntas[respIndex].PesquisaValorAtual = response.payload

        pesquisa.temaBuscarDimensao.Dimensao[dimensaoSelecionada].Perguntas[respIndex].IdPesquisaValorAtual = response.payload.Id
        
      }
      formik.setValues(pesquisa)
    }

  }

  const onClickProximo = async () =>{
    const response = await httpClient.request({
      method: RequestMethod.GET,
      endpoint: '/Pesquisa/Tema/ProximoValorResposta',
      queryObject: {
        tipoResposta: numeroStep,
        idEntidade: resposta?.Id,
      }
    })

    if(response.success && response.payload){

      const pesquisa = formik.values

      if (numeroStep === EnumStepsTema.Tema){
        let tituloIndex = pesquisa.tema.findIndex((c => c.Id === tituloSelecionado))
        let respIndex = pesquisa.tema[tituloIndex].Topico.findIndex((r => r.Id === textoSelecionado))
        
        pesquisa.tema[tituloIndex].Topico[respIndex].PesquisaValorAtual = response.payload

        pesquisa.tema[tituloIndex].Topico[respIndex].IdPesquisaValorAtual = response.payload.Id

      }
      if (numeroStep === EnumStepsTema.Dimensões){
        let tituloIndex = pesquisa.temaBuscarDimensao.Dimensao.findIndex((r => r.Id === tituloSelecionado))
        let respIndex = pesquisa.temaBuscarDimensao.Dimensao[tituloIndex].DimensaoDescricoes.findIndex((c => c.Id === textoSelecionado))
        
        pesquisa.temaBuscarDimensao.Dimensao[tituloIndex].DimensaoDescricoes[respIndex].PesquisaValorAtual = response.payload

        pesquisa.temaBuscarDimensao.Dimensao[tituloIndex].DimensaoDescricoes[respIndex].IdPesquisaValorAtual = response.payload.Id
        
      }
      if (numeroStep === EnumStepsTema.Perguntas && dimensaoSelecionada){ 
        let respIndex = pesquisa.temaBuscarDimensao.Dimensao[dimensaoSelecionada].Perguntas.findIndex((c => c.Id === textoSelecionado))

        pesquisa.temaBuscarDimensao.Dimensao[dimensaoSelecionada].Perguntas[respIndex].PesquisaValorAtual = response.payload

        pesquisa.temaBuscarDimensao.Dimensao[dimensaoSelecionada].Perguntas[respIndex].IdPesquisaValorAtual = response.payload.Id
        
      }
      formik.setValues(pesquisa)
    }

  }

  const onClickExcluir = () =>{

    if(numeroStep === EnumStepsTema.Perguntas){

      setAbrirModalExcluirPerguntas(true);

    }
    else{
    
      alert.createDialog({
        html: `Realmente deseja excluir essa resposta?`,
        confirmAction: async () => {
          const response = await httpClient.request({
            method: RequestMethod.GET,
            endpoint: '/Pesquisa/Tema/ExcluirResposta',
            queryObject: {
                tipoResposta: numeroStep,
                idEntidade: resposta?.Id,
            }
          })
          if(response.success && resposta) {

            let pesquisa = formik.values

            if (numeroStep === EnumStepsTema.Tema){
              let tituloIndex = pesquisa.tema.findIndex((c => c.Id === tituloSelecionado))
              pesquisa.tema[tituloIndex].Topico = pesquisa.tema[tituloIndex].Topico.filter(r => r.Id !== textoSelecionado)
              pesquisa.topicosBuscar = pesquisa.tema[tituloIndex].Topico.length
              setTituloSelecionado(undefined)
            }
            if (numeroStep === EnumStepsTema.Dimensões){
              let tituloIndex = pesquisa.temaBuscarDimensao.Dimensao.findIndex((c => c.Id === tituloSelecionado))
              pesquisa.temaBuscarDimensao.Dimensao[tituloIndex].DimensaoDescricoes = pesquisa.temaBuscarDimensao.Dimensao[tituloIndex].DimensaoDescricoes.filter(r => r.Id !== textoSelecionado)
              pesquisa.dimensoesBuscar = pesquisa.temaBuscarDimensao.Dimensao.length
            }
            if (numeroStep === EnumStepsTema.Perguntas && dimensaoSelecionada){
              pesquisa.temaBuscarDimensao.Dimensao[dimensaoSelecionada].Perguntas = pesquisa.temaBuscarDimensao.Dimensao[dimensaoSelecionada].Perguntas.filter(r => r.Id !== textoSelecionado)
            }

            formik.setValues(pesquisa)
            setTextoSelecionado(undefined)
            setTextostaBackup(undefined)
            setTextoSendoEditado(false)
            
          }

        }
      })

    }

  }
  
  return (
    <Draggable className={`painel-pesquisa ${isMobile ? 'painel-pesquisa-mobile' : 'painel-pesquisa-desktop'} ${mostrarPainel ? '' : 'd-none'}`}  style={{ left: 'calc(70% - 277.375px)', top: '200px', zIndex: 11  }}>
      
      <div className={`painel-pesquisa-action`} ref={sizeBarraRobo}>

        <span className='painel-pesquisa-botao-ia'>
          <img className='draggable-false-image' draggable={false} src={toAbsoluteUrl("/media/prolead/pesquisa/botao_IA.png")} width="50px" height="50px" alt="botao-ia" />
        </span>

        <>
          <Tooltip title={'Anterior'}>
            <div className='btn-painel-pesquisa border-painel-pesquisa-left'>
              <button 
                type="button"
                onClick={() => onClickAnterior()} 
                disabled={!resposta || (resposta && resposta.PesquisaValores.length > 0 && resposta.IdPesquisaValorAtual === resposta.PesquisaValores[0].Id) || (resposta && textoSendoEditado)} 
                className={`btn btn-icon ${isMobile ? 'btn-sm' : 'btn-md'} m-2 ${btnMode}`}
                >
                <i className="fs-2 fas fa-arrow-left"></i>
              </button>
            </div>                 
          </Tooltip> 

          {(auth?.servico.tipoPlano === EnumServicoTipoPlano.Try || ((auth?.roles.find(x => x === 'Master') !== undefined) || (auth?.roles.find(x => x === 'Administrador') !== undefined) || (auth?.permissoes.find(x => x === '38ABA640AF33E3221447198ADB90F556') !== undefined))) && (                                    
            <Tooltip title={'Aprimorar'}>
              <div className='btn-painel-pesquisa border-painel-pesquisa-top'>
                <button 
                  type="button" 
                  onClick={() => setAbrirModalAprimorarTexto(true)}
                  disabled={!resposta || (resposta && resposta.PesquisaValores.length > 0 && resposta.IdPesquisaValorAtual !== resposta.PesquisaValores[resposta.PesquisaValores.length - 1].Id) || (resposta && textoSendoEditado)} 
                  className={`btn btn-icon ${isMobile ? 'btn-sm' : 'btn-md'} m-2 ${btnMode}`}
                >
                  <i className="fs-2 fas fa-arrow-up-wide-short"></i>
                </button> 
              </div>               
            </Tooltip>  
          )} 

          <Tooltip title={'Próxima'}>
            <div className='btn-painel-pesquisa border-painel-pesquisa-top'>
              <button 
                type="button" 
                onClick={() => onClickProximo()} 
                disabled={!resposta || (resposta && resposta.PesquisaValores.length > 0 && resposta.IdPesquisaValorAtual === resposta.PesquisaValores[resposta.PesquisaValores.length - 1].Id) || (resposta && textoSendoEditado)} 
                className={`btn btn-icon ${isMobile ? 'btn-sm' : 'btn-md'} m-2 ${btnMode}`}
              >
                <i className="fs-2 fas fa-arrow-right"></i>
              </button> 
            </div>               
          </Tooltip>  
        </>

        {(auth?.servico.tipoPlano === EnumServicoTipoPlano.Try || ((auth?.roles.find(x => x === 'Master') !== undefined) || (auth?.roles.find(x => x === 'Administrador') !== undefined) || (auth?.permissoes.find(x => x === 'C7609E258D774DE27CC97FD6952A51D7') !== undefined))) && (                                    
          <>
            <Tooltip title={'Adicionar'}>
              <div className='btn-painel-pesquisa border-painel-pesquisa-top'>
                <button 
                  type="button" 
                  onClick={() => setAbrirModalAddTexto(true)}
                  disabled={numeroStep === EnumStepsTema.Perguntas || numeroStep === EnumStepsTema.Tema || (((numeroStep === EnumStepsTema.Dimensões && tituloSelecionado === undefined) && !resposta) || (numeroStep !== EnumStepsTema.Dimensões && !resposta) ) || (resposta && textoSendoEditado)} 
                  className={`btn btn-icon ${isMobile ? 'btn-sm' : 'btn-md'} m-2 ${btnMode}`}
                >
                  <i className="fs-2 fas fa-plus"></i>
                </button>             
              </div>        
            </Tooltip>  
          </>
        )}

        {(auth?.servico.tipoPlano === EnumServicoTipoPlano.Try || ((auth?.roles.find(x => x === 'Master') !== undefined) || (auth?.roles.find(x => x === 'Administrador') !== undefined) || (auth?.permissoes.find(x => x === '55B7E6988C5821964B2DFB1513520D2E') !== undefined))) && (                                    
          <>
            <Tooltip title={'Excluir'}>
              <div className='btn-painel-pesquisa border-painel-pesquisa-top'>
                <button 
                  type="button" 
                  disabled={(numeroStep !== EnumStepsTema.Perguntas && (!resposta || (resposta && textoSendoEditado))) || (numeroStep === EnumStepsTema.Perguntas && formik.values.temaBuscarDimensao.Id === 0 && !formik.values.temaBuscarDimensao.ExistePesquisa) }
                  className={`btn btn-icon ${isMobile ? 'btn-sm' : 'btn-md'} m-2 ${btnMode}`}
                  onClick={onClickExcluir}
                >
                  <i className="fs-2 fas fa-trash"></i>
                </button>    
              </div>    
            </Tooltip> 
          </>
        )}

        {(auth?.servico.tipoPlano === EnumServicoTipoPlano.Try || ( (auth?.roles.find(x => x === 'Master') !== undefined) || (auth?.roles.find(x => x === 'Administrador') !== undefined) || (auth?.permissoes.find(x => x === '2D08717A6A30A3A079C1656176D225FC') !== undefined))) && (                                    
          <>
            <Tooltip title={'Argumentar'}>
              <div className='btn-painel-pesquisa border-painel-pesquisa-top'>
                <button 
                  type="button"
                  onClick={() => setAbrirModalArgumentarTexto(true)}
                  disabled={!resposta || (resposta && resposta.PesquisaValores.length > 0 && resposta.IdPesquisaValorAtual !== resposta.PesquisaValores[resposta.PesquisaValores.length - 1].Id) || (resposta && textoSendoEditado)} 
                  className={`btn btn-icon ${isMobile ? 'btn-sm' : 'btn-md'} m-2 ${btnMode}`}
                >
                  <i className="fs-2 fas fa-comment-dots"></i>
                </button>
              </div>                
            </Tooltip>  
          </>
        )}

        {(auth?.servico.tipoPlano === EnumServicoTipoPlano.Try || ((auth?.roles.find(x => x === 'Master') !== undefined) || (auth?.roles.find(x => x === 'Administrador') !== undefined) || (auth?.permissoes.find(x => x === 'BBEAB45477248AFC07DD6A1CF587438E') !== undefined))) && (                                    
          <>
            <Tooltip title={'Editar'}>
              <div className='btn-painel-pesquisa border-painel-pesquisa-top'>            
                <button 
                  type="button" 
                  onClick={() => onClickEditar()}
                  disabled={!resposta || (resposta && resposta.PesquisaValores.length > 0 && resposta.IdPesquisaValorAtual !== resposta.PesquisaValores[resposta.PesquisaValores.length - 1].Id) || (resposta && textoSendoEditado)} 
                  className={`btn btn-icon ${isMobile ? 'btn-sm' : 'btn-md'} m-2 ${btnMode}`}
                >
                  <i className="fs-2 fas fa-edit"></i>
                </button> 
              </div>     
            </Tooltip>  
          </>
        )}

        {(auth?.servico.tipoPlano === EnumServicoTipoPlano.Try || ((auth?.roles.find(x => x === 'Master') !== undefined) || (auth?.roles.find(x => x === 'Administrador') !== undefined) || (auth?.permissoes.find(x => x === '596E383369B50F2DB27E28311FE339D2') !== undefined))) && (                                    
          <>
            <Tooltip title={'Salvar'}>
              <div className='btn-painel-pesquisa border-painel-pesquisa-right'>
                <button 
                  type="button" 
                  onClick={() => onClickSalvar()}
                  disabled={!resposta || (resposta && !textoSendoEditado)} 
                  className={`btn btn-icon ${isMobile ? 'btn-sm' : 'btn-md'} m-2 ${btnMode}`}
                >
                  <i className="fs-2 fas fa-save"></i>
                </button>    
              </div>              
            </Tooltip>  
          </>
        )}

      </div>
    </Draggable>    
  )
}

export {PesquisaPainel}
