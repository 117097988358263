import { toAbsoluteUrl } from '../../../../../_metronic/helpers'

type Props = {
  descricao: string
  descricaoVideo: string
  videoName: string
  btnDisabled?: boolean
  videoPath: string
  className?: string
}

const CardConceitos = ({descricao, videoName,descricaoVideo, btnDisabled = false,videoPath, className = ''}: Props) => {

  return (
    <>
      <div className={`card card-flush h-md-100 ${className}`}>
        <div
          className='card-body d-flex flex-column justify-content-between bgi-no-repeat bgi-size-cover bgi-position-x-center pb-0 min-h-300px'
          style={{
            backgroundPosition: '100% 50%',
            backgroundImage: `url('${toAbsoluteUrl('/media/stock/900x600/42.png')}')`,
          }}
        >
          <div className='mb-5 d-flex justify-content-center'>
            <div className='col-lg-11 ml-0 pl-0' style={{border: "1px", borderBottom: "dashed", borderColor: "grey"}}>
              <div className='fs-1 ml-0 pl-0 pt-5 pb-5 fw-bold text-gray-700 text-start'>
                <span className='me-2'>
                  {descricao}   
                </span>
              </div>
            </div>
          </div>
          <div className='row d-flex justify-content-center mt-5 mb-15'>
            <div className='col-lg-6 '>
              <div className='ratio ratio-16x9 mw-700px mh-450px pt-5 pb-5 rounded'>
                <video 
                  controls 
                  style={{backgroundColor: "black"}} 
                  className=' pt-5 pb-5 rounded'>
                  <source 
                    src={videoPath} 
                    type="video/mp4"
                  />
                </video>
              </div>
            </div>
            <div className='col-lg-5'>
              <div className='row mb-2 fw-bold text-gray-800 fs-2'>
                <span className='me-5'>
                  {videoName}   
                </span>
              </div>
              <div className='row'>
                <span className='me-2'>
                  {descricaoVideo}   
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export {CardConceitos}
