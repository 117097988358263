import {Route, Routes, Outlet, Navigate} from 'react-router-dom'
import {PageLink, PageTitle} from '../../../_metronic/layout/core'
import {Dashboard} from './components/Dashboard'
import {ConfiguracoesIniciais} from './components/ConfiguracoesIniciais'
import {Descrever} from './components/Descrever'
import {AcervoDeDescricoes} from './components/AcervoDeDescricoes'
import {VisualizarDescricao} from './components/VisualizarDescricao'
import {ArrayBreadcrumb, BreadcrumbLayout} from '../../modules/services/core/Breadcrumb'
import {ModeloFormularioDescricao} from './components/ModeloFormularioDescricao'
import {VisualizarEditarModelo} from './components/VisualizarEditarModelo'
import {useAuth} from '../../modules/auth'
import {
  EnumDescricaoStatusAprovacao,
  EnumServicoTipoPlano,
} from '../../modules/services/core/_enums'
import {AprovarDescricao} from './components/AprovarDescricao'
import {HistoricoDescricao} from './components/HistoricoDescricao'
import {Revisar} from './components/Revisar'
import { ClienteConsultoria } from './components/ClienteConsultoria'
import { ProcessoDescricoesDeCargo } from './components/ProcessoDescricoesDeCargo'
import { Colaboradores } from '../configuracao/components/Colaboradores'
import { Colaborador } from '../configuracao/components/Colaborador'
import { ConsultorDescricao } from './components/ConsultorDescricao'
import { Areas } from '../configuracao/components/Areas'
import { DashboardConsultor } from './components/DashboardConsultor'
import { NiveisHierarquicos } from '../configuracao/components/NiveisHierarquicos'
import { Cargos } from '../configuracao/components/Cargos'
import { InicioPage } from '../inicio/InicioPage'
import { InicioDescricao } from './components/Inicio'

const wizardsBreadCrumbs: Array<ArrayBreadcrumb> = [
  {
    titulo: 'Descrição',
    path: '',
  },
]

const wizardsBreadCrumbsConsultoria: Array<ArrayBreadcrumb> = [
  {
    titulo: 'Descrição',
    path: '',
  },
  {
    titulo: 'Consultoria',
    path: '',
  },
]

const wizardsBreadCrumbsDash: Array<ArrayBreadcrumb> = [
  {
    titulo: 'Descrição',
    path: '',
  },
  {
    titulo: 'Dashboard',
    path: '/descricao/dashboard',
  },
]

const wizardsBreadCrumbsConsultoriaModelo: Array<ArrayBreadcrumb> = [
  {
    titulo: 'Descrição',
    path: '',
  },
  {
    titulo: 'Consultoria',
    path: '',
  },
  {
    titulo: 'Modelo de Formulário',
    path: '/descricao/consultoria/modelo-formulario-descricao',
  },
]

const wizardsBreadCrumbsModelo: Array<ArrayBreadcrumb> = [
  {
    titulo: 'Descrição',
    path: '',
  },
  {
    titulo: 'Modelo de Formulário',
    path: '/descricao/modelo-formulario-descricao',
  },
]

const wizardsBreadCrumbsHistorico: Array<ArrayBreadcrumb> = [
  {
    titulo: 'Descrição',
    path: '',
  },
]

const wizardsBreadCrumbsARevisar: Array<ArrayBreadcrumb> = [
  {
    titulo: 'Descrição',
    path: '',
  },
  {
    titulo: 'Dashboard',
    path: '/descricao/dashboard',
  },
]

const DescricaoPage = () => {
  const {auth} = useAuth()
  const servico = auth?.servico

  return (
    <Routes>
      <Route element={<Outlet />}>
        <Route
            path="inicio"
            element={
                <InicioDescricao/>
            }
        />
        {servico?.tipoPlano === EnumServicoTipoPlano.Try && (
          <>
            <Route
              path='configuracoes-iniciais'
              element={
                <>
                  <ConfiguracoesIniciais />
                </>
              }
            />
          </>
        )}
        <Route
          path='processos'
          element={
            <>
              <ProcessoDescricoesDeCargo />
            </>
          }
        />
        {(servico?.tipoPlano === EnumServicoTipoPlano.Try || (auth?.roles.find(x => x === 'Master') !== undefined) || (auth?.roles.find(x => x === 'Administrador') !== undefined) || (auth?.permissoes.find(x => x === '92374908B8E104F71A26BBE0E0126AD2') !== undefined)) && (         
          <Route
            path='dashboard'
            element={
              <>
                <BreadcrumbLayout props={wizardsBreadCrumbs}>Dashboard</BreadcrumbLayout>
                <Dashboard />
              </>
            }
          />
        )}        
        <Route
          path='revisar'
          element={
            <>
              <BreadcrumbLayout props={wizardsBreadCrumbs}>Revisar</BreadcrumbLayout>
              <Descrever />
            </>
          }
        />        
        {servico?.tipoPlano === EnumServicoTipoPlano.Buy && (
          <>  
            {((auth?.roles.find(x => x === 'Master') !== undefined) || (auth?.roles.find(x => x === 'Administrador') !== undefined) || (auth?.permissoes.find(x => x === '217D6C1F64B82F49D78769A987D30602') !== undefined)) && (
              <>
                <Route
                  path='modelo-formulario-descricao'
                  element={
                    <>
                      <BreadcrumbLayout props={wizardsBreadCrumbs}>
                        Modelo de Formulário
                      </BreadcrumbLayout>
                      <ModeloFormularioDescricao isConsultoria={false} />
                    </>
                  }
                />
                <Route
                  path='modelo-formulario-descricao/visualizar'
                  element={
                    <>
                      <BreadcrumbLayout props={wizardsBreadCrumbsModelo}>
                        Visualizar
                      </BreadcrumbLayout>
                      <VisualizarEditarModelo isConsultoria={false} />
                    </>
                  }
                />
                <Route
                  path='modelo-formulario-descricao/editar'
                  element={
                    <>
                      <BreadcrumbLayout props={wizardsBreadCrumbsModelo}>
                        Editar
                      </BreadcrumbLayout>
                      <VisualizarEditarModelo isConsultoria={false} />
                    </>
                  }
                />
                <Route
                  path='modelo-formulario-descricao/adicionar'
                  element={
                    <>
                      <BreadcrumbLayout props={wizardsBreadCrumbsModelo}>
                        Adicionar
                      </BreadcrumbLayout>
                      <VisualizarEditarModelo isConsultoria={false} />
                    </>
                  }
                />
              </>
            )}
            {((auth?.roles.find(x => x === 'Master') !== undefined) || (auth?.roles.find(x => x === 'Administrador') !== undefined) || (auth?.permissoes.find(x => x === 'A1BDDCEDA1A21ED08B4A6D1A24B81E9D') !== undefined)) && (
              <Route
                path='a-revisar'
                element={
                  <>
                    <BreadcrumbLayout props={wizardsBreadCrumbsARevisar}>Descrições a Revisar</BreadcrumbLayout>
                    <Revisar />
                  </>
                }
              />
            )}
            {servico.moduloDescricaoConfig.usaAprovacao && (
              <>
                {((auth?.roles.find(x => x === 'Master') !== undefined) || (auth?.roles.find(x => x === 'Administrador') !== undefined) || (auth?.permissoes.find(x => x === 'E6A49A365543B198BB13D8C46C98F9B6') !== undefined)) && (
                  <>
                    <Route
                      path='a-aprovar'
                      element={
                        <>
                          <BreadcrumbLayout props={wizardsBreadCrumbsDash}>Descrições a Aprovar</BreadcrumbLayout>
                          <AcervoDeDescricoes
                            title='Descrições a Aprovar'
                            status={EnumDescricaoStatusAprovacao.AAprovar}
                          />
                        </>
                      }
                    />                    
                    <Route
                      path='aprovar'
                      element={
                        <>
                          <BreadcrumbLayout props={wizardsBreadCrumbs}>Aprovar</BreadcrumbLayout>
                          <AprovarDescricao />
                        </>
                      }
                    />
                  </>
                )}
                {((auth?.roles.find(x => x === 'Master') !== undefined) || (auth?.roles.find(x => x === 'Administrador') !== undefined) || (auth?.permissoes.find(x => x === '86F360E465721DC0D09E2CD0F31FFBBF') !== undefined)) && (
                  <Route
                    path='reprovadas'
                    element={
                      <>
                        <BreadcrumbLayout props={wizardsBreadCrumbsDash}>Descrições Reprovadas</BreadcrumbLayout>
                        <AcervoDeDescricoes
                          title='Descrições Reprovadas'
                          status={EnumDescricaoStatusAprovacao.Reprovada}
                        />
                      </>
                    }
                  />
                )}                
              </>
            )}
            {(servico?.moduloDescricaoConfig.Consultoria) && (
              <>
                {((auth?.roles.find(x => x === 'Master') !== undefined) || (auth?.roles.find(x => x === 'Administrador') !== undefined) || (auth?.permissoes.find(x => x === '6CD7B6C08D4159B14F07D18889E55520') !== undefined)) && (
                  <Route
                    path='consultoria/cliente'
                    element={
                      <>
                        <BreadcrumbLayout props={wizardsBreadCrumbsConsultoria}>Clientes</BreadcrumbLayout>
                        <ClienteConsultoria/>
                      </>
                    }
                  /> 
                )}
                {((auth?.roles.find(x => x === 'Master') !== undefined) || (auth?.roles.find(x => x === 'Administrador') !== undefined) || (auth?.permissoes.find(x => x === 'E412A196199739074C0A851082CA5C5E') !== undefined)) && (         
                  <Route
                    path='consultoria/dashboard'
                    element={
                      <>
                        <BreadcrumbLayout props={wizardsBreadCrumbsConsultoria}>Dashboard</BreadcrumbLayout>
                        <DashboardConsultor />
                      </>
                    }
                  />
                )}                  
                {((auth?.roles.find(x => x === 'Master') !== undefined) || (auth?.roles.find(x => x === 'Administrador') !== undefined) || (auth?.permissoes.find(x => x === 'AED88DD4985351B464E9D4DF896B7BFB') !== undefined)) && (
                  <Route
                    path='consultoria/consultor'
                    element={
                      <>
                        <BreadcrumbLayout props={wizardsBreadCrumbsConsultoria}>Consultores</BreadcrumbLayout>
                        <ConsultorDescricao/>
                      </>
                    }
                  /> 
                )}   
                {((auth?.roles.find(x => x === 'Master') !== undefined) || (auth?.roles.find(x => x === 'Administrador') !== undefined) || (auth?.permissoes.find(x => x === 'D089461D10267A2E4FF1C9F76C2DA9C2') !== undefined)) && (
                  <Route
                    path='consultoria/modelo-formulario-descricao'
                    element={
                      <>
                        <BreadcrumbLayout props={wizardsBreadCrumbsConsultoria}>
                          Modelo de Formulário
                        </BreadcrumbLayout>
                        <ModeloFormularioDescricao isConsultoria={true} />
                      </>
                    }
                  /> 
                )}  
                {((auth?.roles.find(x => x === 'Master') !== undefined) || (auth?.roles.find(x => x === 'Administrador') !== undefined) || (auth?.permissoes.find(x => x === 'D089461D10267A2E4FF1C9F76C2DA9C2') !== undefined)) && (
                  <Route
                    path='consultoria/modelo-formulario-descricao/visualizar'
                    element={
                      <>
                        <BreadcrumbLayout props={wizardsBreadCrumbsConsultoriaModelo}>
                          Visualizar
                        </BreadcrumbLayout>
                        <VisualizarEditarModelo isConsultoria={true} />
                      </>
                    }
                  />
                )} 
                {((auth?.roles.find(x => x === 'Master') !== undefined) || (auth?.roles.find(x => x === 'Administrador') !== undefined) || (auth?.permissoes.find(x => x === 'E9922F593481BE9644FBEB52AE07A565') !== undefined)) && (
                  <Route
                    path='consultoria/modelo-formulario-descricao/editar'
                    element={
                      <>
                        <BreadcrumbLayout props={wizardsBreadCrumbsConsultoriaModelo}>
                          Editar
                        </BreadcrumbLayout>
                        <VisualizarEditarModelo isConsultoria={true} />
                      </>
                    }
                  />
                )} 
                {((auth?.roles.find(x => x === 'Master') !== undefined) || (auth?.roles.find(x => x === 'Administrador') !== undefined) || (auth?.permissoes.find(x => x === 'FE7B6D3ED19B84F0266856DB7F2FB5C2') !== undefined)) && (
                  <Route
                    path='consultoria/modelo-formulario-descricao/adicionar'
                    element={
                      <>
                        <BreadcrumbLayout props={wizardsBreadCrumbsConsultoriaModelo}>
                          Adicionar
                        </BreadcrumbLayout>
                        <VisualizarEditarModelo isConsultoria={true} />
                      </>
                    }
                  />
                )} 
                {((auth?.roles.find(x => x === 'Master') !== undefined) || (auth?.roles.find(x => x === 'Administrador') !== undefined) || (auth?.permissoes.find(x => x === '4BFB2976419C5EF8AEB67794BC84E777') !== undefined)) && (
                  <Route
                    path='consultoria/niveis-hierarquicos'
                    element={
                      <>
                        <BreadcrumbLayout props={wizardsBreadCrumbsConsultoria}>Níveis Hierárquicos</BreadcrumbLayout>
                        <NiveisHierarquicos isConsultoria={true}/>
                      </>
                    }
                  /> 
                )}
                {((auth?.roles.find(x => x === 'Master') !== undefined) || (auth?.roles.find(x => x === 'Administrador') !== undefined) || (auth?.permissoes.find(x => x === '1B0EEDDAFFD79CD502A53E4AFBCB58D5') !== undefined)) && (
                  <Route
                    path='consultoria/areas'
                    element={
                      <>
                        <BreadcrumbLayout props={wizardsBreadCrumbsConsultoria}>Áreas</BreadcrumbLayout>
                        <Areas isConsultoria={true} />
                      </>
                    }
                  /> 
                )} 
                {((auth?.roles.find(x => x === 'Master') !== undefined) || (auth?.roles.find(x => x === 'Administrador') !== undefined) || (auth?.permissoes.find(x => x === '1B0EEDDAFFD79CD502A53E4AFBCB58D5') !== undefined)) && (
                  <Route
                  path='consultoria/cargos'
                  element={
                    <>
                      <BreadcrumbLayout props={wizardsBreadCrumbsConsultoria}>Cargos</BreadcrumbLayout>
                      <Cargos isConsultoria={true} />
                    </>
                  }
                /> 
                )}
                {((auth?.roles.find(x => x === 'Master') !== undefined) || (auth?.roles.find(x => x === 'Administrador') !== undefined) || (auth?.permissoes.find(x => x === '950824CA8E8B19605779BA7774E224B5') !== undefined)) && (
                  <>
                    <Route
                      path='consultoria/colaborador'
                      element={
                        <>
                          <BreadcrumbLayout props={wizardsBreadCrumbsConsultoria}>Colaboradores</BreadcrumbLayout>
                          <Colaboradores colabCliente={true}/>
                        </>
                      }
                    /> 
                    <Route
                      path='consultoria/colaborador/gerenciar'
                      element={
                        <>
                          <BreadcrumbLayout props={wizardsBreadCrumbsConsultoria}>Colaborador</BreadcrumbLayout>
                          <Colaborador colabCliente={true}/>
                        </>
                      }
                    /> 
                  </>
                )} 
              </>
            )}
          </>
        )}
        <Route
          path='descricoes'
          element={
            <>
              <BreadcrumbLayout props={wizardsBreadCrumbsDash}>
                Descrições Aprovadas
              </BreadcrumbLayout>
              <AcervoDeDescricoes
                title={undefined}
                status={EnumDescricaoStatusAprovacao.Aprovada}
              />
            </>
          }
        />
        <Route
          path='visualizar'
          element={
            <>
              <BreadcrumbLayout props={wizardsBreadCrumbs}>Visualizar</BreadcrumbLayout>
              <VisualizarDescricao />
            </>
          }
        />
        {((auth?.roles.find(x => x === 'Master') !== undefined) || (auth?.roles.find(x => x === 'Administrador') !== undefined) || (auth?.permissoes.find(x => x === '780C5D303B40864B28FFA3BEB37D9FCE') !== undefined)) && (
          <Route
            path='historico'
            element={
              <>
                <BreadcrumbLayout props={wizardsBreadCrumbsHistorico}>Histórico</BreadcrumbLayout>
                <HistoricoDescricao />
              </>
            }
          />
        )}
        {servico?.clienteConsultoriaDescricao != null && (
          <>
            {((auth?.roles.find(x => x === 'Master') !== undefined) || (auth?.roles.find(x => x === 'Administrador') !== undefined) || (auth?.permissoes.find(x => x === 'F1DD4A87E0981CE7EBF8749B6F1C6964') !== undefined)) && (
              <Route
                path='a-revisar'
                element={
                  <>
                    <BreadcrumbLayout props={wizardsBreadCrumbsARevisar}>Descrições a Revisar</BreadcrumbLayout>
                    <Revisar />
                  </>
                }
              />
            )}
            {((auth?.roles.find(x => x === 'Master') !== undefined) || (auth?.roles.find(x => x === 'Administrador') !== undefined) || (auth?.permissoes.find(x => x === 'F1DD4A87E0981CE7EBF8749B6F1C6964') !== undefined)) && (
              <Route
                path='a-avaliar'
                element={
                  <>
                    <BreadcrumbLayout props={wizardsBreadCrumbsDash}>Descrições a Avaliar</BreadcrumbLayout>
                    <AcervoDeDescricoes
                      title='Descrições a Avaliar'
                      status={EnumDescricaoStatusAprovacao.AAvaliar}
                    />
                  </>
                }
              />
            )}
            {((auth?.roles.find(x => x === 'Master') !== undefined) || (auth?.roles.find(x => x === 'Administrador') !== undefined) || (auth?.permissoes.find(x => x === 'F1DD4A87E0981CE7EBF8749B6F1C6964') !== undefined)) && (
              <Route
                path='a-aprovar'
                element={
                  <>
                    <BreadcrumbLayout props={wizardsBreadCrumbsDash}>Descrições a Aprovar</BreadcrumbLayout>
                    <AcervoDeDescricoes
                      title='Descrições a Aprovar'
                      status={EnumDescricaoStatusAprovacao.AAprovar}
                    />
                  </>
                }
              />
            )}
            {((auth?.roles.find(x => x === 'Master') !== undefined) || (auth?.roles.find(x => x === 'Administrador') !== undefined) || (auth?.permissoes.find(x => x === 'B847D84BCACD0DF5405CCE536A99AD58') !== undefined)) && (
              <Route
                path='aprovar'
                element={
                  <>
                    <BreadcrumbLayout props={wizardsBreadCrumbsDash}>Aprovar</BreadcrumbLayout>
                    <AprovarDescricao />
                  </>
                }
              />
            )}
            {((auth?.roles.find(x => x === 'Master') !== undefined) || (auth?.roles.find(x => x === 'Administrador') !== undefined) || (auth?.permissoes.find(x => x === 'F1DD4A87E0981CE7EBF8749B6F1C6964') !== undefined)) && (
              <Route
                path='reprovadas'
                element={
                  <>
                    <BreadcrumbLayout props={wizardsBreadCrumbsDash}>Descrições Reprovadas</BreadcrumbLayout>
                    <AcervoDeDescricoes
                      title='Descrições Reprovadas'
                      status={EnumDescricaoStatusAprovacao.Reprovada}
                    />
                  </>
                }
              />
            )}
          </>
        )}
        <Route path='*' element={<Navigate to='/inicio' />} />
        <Route index element={<Navigate to='/descricao/configuracoes-iniciais' />} />
      </Route>
    </Routes>
  )
}

export default DescricaoPage
