import { ErrorMessage, Field, FormikProps, FormikProvider, useFormik } from 'formik'
import { KTSVG } from '../../../../../_metronic/helpers'
import { useAlert, useHttpClient } from '../../../../modules/services/Bundle'
import { RequestMethod } from '../../../../modules/services/core/_enums'
import { IDescreverDescricaoModel } from '../helpers/DescreverHelper'
import * as Yup from 'yup'

type Props = {
    abrirModal: boolean,
    setAbrirModal: React.Dispatch<React.SetStateAction<boolean>>
    formik: FormikProps<IDescreverDescricaoModel>,
    campoSelecionado: number | undefined,
    setCampoSelecionado: React.Dispatch<React.SetStateAction<number | undefined>>,
    respostaSelecionada: number | undefined,
    setRespostaSelecionada: React.Dispatch<React.SetStateAction<number | undefined>>,
    respostaSendoEditada: boolean,
    setRespostaSendoEditada: React.Dispatch<React.SetStateAction<boolean>>
}

interface IRespModel {
    texto: string
}

const defaultValidationSchemas = Yup.object().shape({
    texto: Yup.string().required('O argumento é obrigatório'),
})

const defaultResp: IRespModel = {
    texto: '',
}

const ModalArgumentarResp= ({abrirModal, setAbrirModal, formik, campoSelecionado, respostaSelecionada}: Props) => {
    const alert = useAlert()
    const httpClient = useHttpClient()
    const campo = formik.values.Campos.find(c => c.Id === campoSelecionado)
    let resposta = campo?.Respostas.find(c => c.Id === respostaSelecionada) ?? undefined

    const formikArgumentarResp = useFormik({
        initialValues: defaultResp,
        validationSchema: defaultValidationSchemas,
        onSubmit: (values, actions) => {
    
        },
    })

    const onClickAddRemoveArgumentoResp = (remover:boolean) =>{

        alert.createDialog({
            html: remover ? `Realmente deseja remover este argumento da frase selecionada?`: `Realmente deseja adicionar um novo argumento na frase selecionada?`,
            confirmAction: async () => {
            
                const response = await httpClient.request({
                    method: RequestMethod.GET,
                    endpoint: '/DescricaoDeCargo/Descrever/AdicionarRemoverArgumentoResposta',
                    queryObject: {
                        idResposta: respostaSelecionada,
                        argumento: formikArgumentarResp.values.texto,
                        remover: remover,
                    }
                    })
                    if(response.success && response.payload) {
                        let descricao = formik.values
                        let campoIndex = descricao.Campos.findIndex((c => c.Id === campoSelecionado))
                        let respIndex = descricao.Campos[campoIndex].Respostas.findIndex((r => r.Id === respostaSelecionada))
                        
                        descricao.Campos[campoIndex].Respostas[respIndex].ValorAtual = response.payload

                        descricao.Campos[campoIndex].Respostas[respIndex].Valores.push(response.payload)

                        descricao.Campos[campoIndex].Respostas[respIndex].IdValorAtual = response.payload.Id

                        formik.setValues(descricao)
                        formikArgumentarResp.resetForm()
                        setAbrirModal(false)
                    }
    
            }
        })
    
    }

    return (
    <div className={`modal ${abrirModal? "show" : ""} fade`} tabIndex={-1} id="kt_modal_1" style={{display: `${abrirModal? "block" : "none"}`}}>
        <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
            <div className="modal-header">
                <h5 className="modal-title">Argumento - adicionar ou remover</h5>
                <div
                onClick={() => setAbrirModal(false)}
                className="btn btn-icon btn-sm btn-active-light-primary ms-2"
                data-bs-dismiss="modal"
                aria-label="Fechar"
                >
                <KTSVG
                    path="/media/icons/duotune/arrows/arr061.svg"
                    className="svg-icon svg-icon-2x"
                />
                </div>
            </div>
            <div className="modal-body">
                <FormikProvider value={formikArgumentarResp}>
                    <div className='w-100'>
                        <div className="row">
                            <div className="col-lg-12 fv-row mb-5">
                                <div className='notice d-flex bg-light-primary rounded border-primary border border-dashed p-6'>
                                    <div className='d-flex flex-stack flex-grow-1'>
                                    <div className='fw-bold'>
                                        <div className='fs-6 text-gray-600 mh-400px overflow-auto'>
                                        {resposta && resposta.ValorAtual && (
                                            resposta.ValorAtual.Texto
                                            
                                        )}
                                        </div>
                                    </div>
                                    </div>
                                </div>
                            </div>     
                            
                            <div className="col-lg-12 fv-row mb-5">
                                <label className='form-label required'>Insira um argumento para adicionar ou remover da resposta:</label>
                                <Field
                                    as="textarea"
                                    name={`texto`}
                                    className='form-control form-control-lg form-control-solid'
                                    value={formikArgumentarResp.values.texto}
                                />
                                <div className='text-danger mt-2'>
                                    <ErrorMessage name='texto' />
                                </div> 
                            </div>         
                        </div>
                    </div>
                </FormikProvider>
            </div>
            <div className="modal-footer">
                <button
                onClick={() => setAbrirModal(false)}
                type="button"
                className="btn btn-light me-5"
                data-bs-dismiss="modal"
                >
                    Cancelar
                </button>
                
                <button type='button' onClick={() => onClickAddRemoveArgumentoResp(false)} className='btn  btn-primary me-5'>
                    Adicionar
                </button>

                <button type='button' onClick={() => onClickAddRemoveArgumentoResp(true)} className='btn  btn-danger'>
                    Remover
                </button>
                

            </div>
            </div>
        </div>
    </div>
  )
}

export {ModalArgumentarResp}
