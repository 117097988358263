/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from 'react'
import { Field, FormikProps } from 'formik'
import { IPesquisaRespostaModel, IWizardModel } from '../helpers/CrudTemaHelper'
import { PesquisaTextos } from './PesquisaTextosCrud'
import { EnumStepsTema, EnumStepsConfigInicialPesquisa, RequestMethod } from '../../../../modules/services/core/_enums'
import { Tooltip, useAlert, useHttpClient } from '../../../../modules/services/Bundle'
import { IconButton } from '@mui/material'
import { ModalDimensao } from './ModalDimensao'

type Props = {
  formik: FormikProps<IWizardModel>,
  numeroStep: number
  tipoRedirect: string
  indexDimensaoSelecionada: number | undefined
  tituloSelecionado: number | undefined,
  setTituloSelecionado: React.Dispatch<React.SetStateAction<number | undefined>>,
  textoSelecionado: number | undefined,
  setTextoSelecionado: React.Dispatch<React.SetStateAction<number | undefined>>,
  textoSendoEditado: boolean,
  setTextoSendoEditado: React.Dispatch<React.SetStateAction<boolean>>,
  textoBackup: IPesquisaRespostaModel | undefined,
  setTextoBackup: React.Dispatch<React.SetStateAction<IPesquisaRespostaModel | undefined>>,
}

const PesquisaAccordions: React.FC<Props> = ({
  formik,
  tipoRedirect,
  tituloSelecionado,
  setTituloSelecionado,
  textoSelecionado,
  setTextoSelecionado,
  textoSendoEditado,
  setTextoSendoEditado,
  textoBackup,
  setTextoBackup,
  numeroStep,
  indexDimensaoSelecionada
}) => {

  const alert = useAlert()
  const httpClient = useHttpClient()
  const [abrirModalDimensao, setAbrirModalDimensao] = useState(false)
  const [dimensaoSelecionada, setDimensaoSelecionada] = useState<IPesquisaRespostaModel>()

  const onClickEditarDimensao = (dimensao: IPesquisaRespostaModel) => {
    setAbrirModalDimensao(true)
    setDimensaoSelecionada(dimensao)
  }

  const onClickTitulo = (titulo: any) => {
    let pesquisa = formik.values
    let existePesquisa:boolean = false
    if (numeroStep === EnumStepsTema.Tema){
      let tituloIndex = pesquisa.tema.findIndex((c => c.Id === titulo.Id))
      existePesquisa = pesquisa.tema[tituloIndex].ExistePesquisa
    }
    if (numeroStep === EnumStepsTema.Dimensões){
      existePesquisa = pesquisa.temaBuscarDimensao.ExistePesquisa
    }
    if (numeroStep === EnumStepsTema.Perguntas && indexDimensaoSelecionada) {
      existePesquisa = pesquisa.temaBuscarDimensao.ExistePesquisa
    }

    if (existePesquisa) {
      setTituloSelecionado(undefined)
      setTextoSelecionado(undefined)
    }
    else if (tituloSelecionado != titulo.Id) {
      fazerBackupDaTexto()
      setTituloSelecionado(titulo.Id)
      setTextoSelecionado(undefined)
    }
  }

  const onClickExcluir = (dimensao: IPesquisaRespostaModel) =>{

    alert.createDialog({
      html: `Realmente deseja excluir essa dimensão?`,
      confirmAction: async () => {
        const response = await httpClient.request({
          method: RequestMethod.GET,
          endpoint: '/Pesquisa/ConfiguracoesIniciais/ExcluirDimensao',
          queryObject: {
            tipoResposta: numeroStep,
            idEntidade: dimensao.Id,
          }
        })
        if(response.success) {

          let pesquisa = formik.values

          if (numeroStep === EnumStepsTema.Dimensões){
            pesquisa.temaBuscarDimensao.Dimensao = pesquisa.temaBuscarDimensao.Dimensao.filter(r => r.Id !== tituloSelecionado)
            pesquisa.dimensoesBuscar = pesquisa.temaBuscarDimensao.Dimensao.length
          }

          formik.setValues(pesquisa)
          setTextoSelecionado(undefined)
          setTextoBackup(undefined)
          setTextoSendoEditado(false)
          
        }

      }
    })  
}

  const fazerBackupDaTexto = () => {
    if (textoBackup && textoBackup.Id && textoBackup.PesquisaValorAtual) {
      let pesquisa = formik.values
      if (numeroStep === EnumStepsTema.Tema){
        let tituloIndex = pesquisa.tema.findIndex((c => c.Id === tituloSelecionado))
        let textoIndex = pesquisa.tema[tituloIndex].Topico.findIndex((c => c.Id === textoBackup.Id))
        pesquisa.tema[tituloIndex].Topico[textoIndex].PesquisaValorAtual = textoBackup.PesquisaValorAtual
      }
      if (numeroStep === EnumStepsTema.Dimensões){
        let tituloIndex = pesquisa.temaBuscarDimensao.Dimensao.findIndex((c => c.Id === tituloSelecionado))
        let textoIndex = pesquisa.temaBuscarDimensao.Dimensao[tituloIndex].DimensaoDescricoes.findIndex((c => c.Id === textoBackup.Id))
        pesquisa.temaBuscarDimensao.Dimensao[tituloIndex].DimensaoDescricoes[textoIndex].PesquisaValorAtual = textoBackup.PesquisaValorAtual
      }
      if (numeroStep === EnumStepsTema.Perguntas && indexDimensaoSelecionada) {
        let textoIndex = pesquisa.temaBuscarDimensao.Dimensao[indexDimensaoSelecionada].Perguntas.findIndex((c => c.Id === textoBackup.Id))
        pesquisa.temaBuscarDimensao.Dimensao[indexDimensaoSelecionada].Perguntas[textoIndex].PesquisaValorAtual = textoBackup.PesquisaValorAtual
      }
      formik.setValues(pesquisa)      
    }
    setTextoBackup(undefined)
    setTextoSendoEditado(false)
  }


  return (
    <>
      {numeroStep === EnumStepsTema.Tema && formik.values.tema.length > 0 && formik.values.tema.map((tema, temaIndex) =>
        <div key={`tema-index-revisasr-${temaIndex}`}>
          <div key={`accordion-titulo-${tema.Id}`} id={`accordion-titulo-${tema.Id}`} className='mb-8'>
            <div className={`card card-custom border border-secondary border-1 shadow-sm titulo-pesquisa titulo-pesquisa-interativo ${tituloSelecionado === tema.Id ? 'titulo-pesquisa-selecionado' : 'titulo-pesquisa-nao-selecionado'}`}>
              <div className="card-header bg-pesquisa border-bottom border-secondary border-1 rounded-bottom" id={`card-titulo-header-${tema.Id}`} onClick={() => onClickTitulo(tema)}>
                <div className="card-title">
                  <h3 className='text-white'>{(tema.Nome)}</h3>                                
                </div>
                  <div className="card-toolbar w-md-auto">   
                    <div className="form-check form-switch form-check-custom form-check-success form-check-solid me-10">
                      <label className="form-check-label text-white fs-3" htmlFor={"utilizaQuestao-"+tema.Id}>
                        Utilizarei múltiplas respostas em minhas perguntas?
                      </label>
                      <input 
                        className="form-check-input form-check-pesquisa h-30px w-50px ms-5" 
                        type="checkbox" 
                        id={"utilizaQuestao-"+tema.Id}
                        defaultChecked={tema.UtilizaQuestao}
                        disabled={tipoRedirect == 'Visualizar' || tema.ExistePesquisa}
                        onChange={(e) => {
                          e.preventDefault();
                          httpClient.request({
                            method: RequestMethod.POST,
                            endpoint: '/Pesquisa/ConfiguracoesIniciais/AlterarUtilizaQuestaoNoTema',
                            queryObject: {
                              idTema: tema.Id
                            }
                          }).then((response) => {
                            if(response.success){
                              e.target.checked = !e.target.checked;
                              const values = {...formik.values}
                              values.tema[temaIndex].UtilizaQuestao = e.target.checked;
                              if(!e.target.checked){
                                values.tema[temaIndex].Dimensao.map((d) => {
                                  return d.Perguntas.map((p) => {
                                    p.QuestaoA = undefined;
                                    p.QuestaoB = undefined;
                                    return p;
                                  });
                                });
                              }
                              formik.setValues(values);
                            }
                          })
                        }}/>
                    </div>          
                    {tipoRedirect === '' && (
                      <button disabled={tema.ExistePesquisa} className='btn btn-icon btn-lg' onClick={(e) => {
                        alert.createDialog({
                          html: "Esta ação é irreversível, deseja realmente excluir este tema?",
                          confirmAction: () => {
                            httpClient.request({
                              method: RequestMethod.DELETE,
                              endpoint: '/Pesquisa/ConfiguracoesIniciais/ExcluirTema',
                              queryObject: {
                                idTema: tema.Id
                              }
                            }).then((response) => {
                              if(response.success){
                                const newValues = formik.values.tema.filter(t => t.Id !== tema.Id);
                                formik.setFieldValue('tema', newValues)
                              }
                            })
                          }
                        })
                      }}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" className="bi bi-trash-fill" viewBox="0 0 16 16">
                          <path d="M2.5 1a1 1 0 0 0-1 1v1a1 1 0 0 0 1 1H3v9a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V4h.5a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H10a1 1 0 0 0-1-1H7a1 1 0 0 0-1 1H2.5zm3 4a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 .5-.5zM8 5a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7A.5.5 0 0 1 8 5zm3 .5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 1 0z"/>
                        </svg>
                      </button>                            
                    )}
                    <button type="button" className="btn btn-icon btn-sm btn-accordion btn-light ms-3" data-bs-toggle="collapse" data-bs-target={`#card-titulo-collapse-${tema.Id}`} aria-expanded={formik.values.tema.length > 1 ? "false" : "true"} aria-controls={`card-titulo-collapse-${tema.Id}`} >
                      <i className='fas fa-arrow-down'></i>
                      <i className='fas fa-arrow-up'></i>
                    </button>                   
                  </div>
              </div>
              <div id={`card-titulo-collapse-${tema.Id}`} className={`${formik.values.tema.length === 1 ? "show" : ""} collapse`} aria-labelledby={`card-titulo-header-${tema.Id}`} data-bs-parent={`#accordion-titulo-${tema.Id}`}>
                <div className="card-body">

                  {(tema) && (
                    (tema.Topico && tema.Topico.length > 0) && (
                      <PesquisaTextos
                        formik={formik}
                        tipoRedirect={tipoRedirect}
                        textoModel={undefined}
                        numeroStep={numeroStep}
                        indexDimensaoSelecionada={indexDimensaoSelecionada}
                        textos={tema.Topico}
                        tituloIndex={temaIndex}
                        fazerBackupDaTexto={fazerBackupDaTexto}
                        tituloSelecionado={tituloSelecionado}
                        setTituloSelecionado={setTituloSelecionado}
                        textoSelecionado={textoSelecionado}
                        setTextoSelecionado={setTextoSelecionado}
                        textoSendoEditado={textoSendoEditado}
                        setTextoSendoEditado={setTextoSendoEditado}
                        textoBackup={textoBackup}
                        setTextoBackup={setTextoBackup}
                      />
                    )
                  )}

                </div>   
              </div>                        
            </div>
          </div>
        </div>
      )}
      {numeroStep === EnumStepsTema.Dimensões && (
        <>
          {abrirModalDimensao && (
            <ModalDimensao 
              setAbrirModal={setAbrirModalDimensao} 
              formik={formik} 
              crud={true}
              dimensao={dimensaoSelecionada}
            />
          )}
          {formik.values.temaBuscarDimensao.Dimensao && formik.values.temaBuscarDimensao.Dimensao.length > 0 && formik.values.temaBuscarDimensao.Dimensao.map((titulo, tituloIndex) => {

          const tituloPesquisaClass = `titulo-pesquisa titulo-pesquisa-interativo`

          return <div key={`accordion-titulo-${titulo.Id}`} id={`accordion-titulo-${titulo.Id}`} className='mb-8'>
            <div className={`card card-custom border border-secondary border-1 shadow-sm ${tituloPesquisaClass} ${tituloSelecionado === titulo.Id ? 'titulo-pesquisa-selecionado' : 'titulo-pesquisa-nao-selecionado'}`}>
              <div className="card-header bg-pesquisa border-bottom border-secondary border-1 rounded-bottom" id={`card-titulo-header-${titulo.Id}`} onClick={() => onClickTitulo(titulo)}>
                <div className="card-title">
                  <h3 className='text-white'>{(titulo.Nome)}</h3>                                
                </div>
                <div className="card-toolbar w-md-auto">  
                  <Tooltip title={'Excluir'}>
                    <div className='btn-painel-pesquisa border-painel-pesquisa-top'>
                      <button 
                        disabled={titulo.DimensaoDescricoes.length > 0 || formik.values.temaBuscarDimensao.ExistePesquisa}
                        style={{ display: tipoRedirect === 'Visualizar' ? 'none' : 'inline-block'}}
                        type="button" 
                        className={`btn btn-icon`}
                        onClick={() => onClickExcluir(titulo)}
                      >
                        <i className="fs-2 fas fa-trash text-white"></i>
                      </button>    
                    </div>    
                  </Tooltip>
                  <IconButton 
                    disabled={formik.values.temaBuscarDimensao.ExistePesquisa} 
                    onClick={() => onClickEditarDimensao(titulo)} 
                    title='Editar' 
                    className='me-2'
                    style={{ display: tipoRedirect === 'Visualizar' ? 'none' : 'inline-block' }}
                  >
                    <i className="bi bi-pencil-fill fs-1 text-white"/>
                  </IconButton>                        
                  <button type="button" className="btn btn-icon btn-sm btn-accordion btn-light" data-bs-toggle="collapse" data-bs-target={`#card-titulo-collapse-${titulo.Id}`} aria-expanded="true" aria-controls={`card-titulo-collapse-${titulo.Id}`} >
                    <i className='fas fa-arrow-down'></i>
                    <i className='fas fa-arrow-up'></i>
                  </button>                   
                </div>
              </div>
              <div id={`card-titulo-collapse-${titulo.Id}`} className="collapse show" aria-labelledby={`card-titulo-header-${titulo.Id}`} data-bs-parent={`#accordion-titulo-${titulo.Id}`}>
                <div className="card-body">

                  {(titulo.DimensaoDescricoes && titulo.DimensaoDescricoes.length > 0) && (
                    <PesquisaTextos
                      formik={formik}
                      tipoRedirect={tipoRedirect}
                      textoModel={undefined}
                      indexDimensaoSelecionada={indexDimensaoSelecionada}
                      numeroStep={numeroStep}
                      textos={titulo.DimensaoDescricoes}
                      tituloIndex={tituloIndex}
                      fazerBackupDaTexto={fazerBackupDaTexto}
                      tituloSelecionado={tituloSelecionado}
                      setTituloSelecionado={setTituloSelecionado}
                      textoSelecionado={textoSelecionado}
                      setTextoSelecionado={setTextoSelecionado}
                      textoSendoEditado={textoSendoEditado}
                      setTextoSendoEditado={setTextoSendoEditado}
                      textoBackup={textoBackup}
                      setTextoBackup={setTextoBackup}
                    />
                  )}
                </div>   
              </div>                         
            </div>
          </div>
        })}
        </>
      )}
      {numeroStep === EnumStepsTema.Perguntas && indexDimensaoSelecionada && formik.values.temaBuscarDimensao.Dimensao && formik.values.temaBuscarDimensao.Dimensao[indexDimensaoSelecionada] && (
        <>
          <div key={`accordion-titulo-${formik.values.temaBuscarDimensao.Dimensao[indexDimensaoSelecionada].Id}`} id={`accordion-titulo-${formik.values.temaBuscarDimensao.Dimensao[indexDimensaoSelecionada].Id}`} className='mb-8'>
            <div className={`card card-custom border border-secondary border-1 shadow-sm titulo-pesquisa titulo-pesquisa-interativo ${tituloSelecionado === formik.values.temaBuscarDimensao.Dimensao[indexDimensaoSelecionada].Id ? 'titulo-pesquisa-selecionado' : 'titulo-pesquisa-nao-selecionado'}`}>
              <div className="card-header bg-pesquisa border-bottom border-secondary border-1 rounded-bottom" id={`card-titulo-header-${formik.values.temaBuscarDimensao.Dimensao[indexDimensaoSelecionada].Id}`} onClick={() => onClickTitulo(formik.values.temaBuscarDimensao.Dimensao[indexDimensaoSelecionada])}>
                <div className="card-title">
                  <h3 className='text-white'>{(formik.values.temaBuscarDimensao.Dimensao[indexDimensaoSelecionada].Nome)}</h3>                                
                </div>
                <div className="card-toolbar w-md-auto">                                
                  <button type="button" className="btn btn-icon btn-sm btn-accordion btn-light" data-bs-toggle="collapse" data-bs-target={`#card-titulo-collapse-${formik.values.temaBuscarDimensao.Dimensao[indexDimensaoSelecionada].Id}`} aria-expanded="true" aria-controls={`card-titulo-collapse-${formik.values.temaBuscarDimensao.Dimensao[indexDimensaoSelecionada].Id}`} >
                    <i className='fas fa-arrow-down'></i>
                    <i className='fas fa-arrow-up'></i>
                  </button>                   
                </div>
              </div>
              <div id={`card-titulo-collapse-${formik.values.temaBuscarDimensao.Dimensao[indexDimensaoSelecionada].Id}`} className="collapse show" aria-labelledby={`card-titulo-header-${formik.values.temaBuscarDimensao.Dimensao[indexDimensaoSelecionada].Id}`} data-bs-parent={`#accordion-titulo-${formik.values.temaBuscarDimensao.Dimensao[indexDimensaoSelecionada].Id}`}>
                <div className="card-body">

                  {(formik.values.temaBuscarDimensao.Dimensao[indexDimensaoSelecionada]) && (
                    (formik.values.temaBuscarDimensao.Dimensao[indexDimensaoSelecionada].Perguntas && formik.values.temaBuscarDimensao.Dimensao[indexDimensaoSelecionada].Perguntas.length > 0) && (
                      <PesquisaTextos
                        formik={formik}
                        tipoRedirect={tipoRedirect}
                        textoModel={undefined}
                        numeroStep={numeroStep}
                        textos={formik.values.temaBuscarDimensao.Dimensao[indexDimensaoSelecionada].Perguntas}
                        tituloIndex={0}
                        indexDimensaoSelecionada={indexDimensaoSelecionada}
                        fazerBackupDaTexto={fazerBackupDaTexto}
                        tituloSelecionado={tituloSelecionado}
                        setTituloSelecionado={setTituloSelecionado}
                        textoSelecionado={textoSelecionado}
                        setTextoSelecionado={setTextoSelecionado}
                        textoSendoEditado={textoSendoEditado}
                        setTextoSendoEditado={setTextoSendoEditado}
                        textoBackup={textoBackup}
                        setTextoBackup={setTextoBackup}
                      />
                    )
                  )}

                </div>   
              </div>                         
            </div>
          </div>
        </>
      )}
    </>    
  )
}

export {PesquisaAccordions}
