/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC} from 'react'
import { EnumServicoTipoPlano } from '../../../modules/services/core/_enums'
import { useAuth } from '../../../modules/auth'
import { AbrirModalVideo } from '../../toolbar/AbrirModalVideo'
import { CardInicio } from './partials/CardInicio'

const InicioDescricao: FC = () => {
  const {auth, saveAuth} = useAuth()
  const servico = auth?.servico
  const moduloDescricaoConfig = servico?.moduloDescricaoConfig

  return (
    <>
      <AbrirModalVideo
        videoPath='https://pro4tech.blob.core.windows.net/visualstuff/Pagina%20inicial.mp4?si=maintaining&spr=https&sv=2022-11-02&sr=b&sig=uFEWdmkq07JGvRjaFTjrPuUGZOQ2lM9tz2Kbv3NL%2FQk%3D'
        videoTitle='Página Inicial'
        descricao='Estamos felizes por você estar aqui! Assista a este vídeo para obter uma visão geral do módulo Descrição de cargo. Em seguida, confira os outros vídeos para obter conhecimento sobre a navegação no sistema.'
      ></AbrirModalVideo>

      <h2>Início</h2>
      <div className='row g-5 g-xl-10 mb-5 mb-xl-10'>
        <div className='col-lg-12 text-center'>
          <h1>Veja como a IA Generativa pode te tornar ainda mais produtivo!</h1>
        </div>
      </div>

      {/* begin::Row */}
      <div className='row g-5 g-xl-10 mb-5 mb-xl-10'>

        {(auth?.servico.tipoPlano === EnumServicoTipoPlano.Try || ((auth?.roles.find(x => x === 'Master') !== undefined) || (auth?.roles.find(x => x === 'Administrador') !== undefined) || (auth?.permissoes.find(x => x === '9C374241FF1E5715026B0FDE04A5E870') !== undefined))) && (                                    
          <>
            {/* begin::Col */}
            <div className='col-xl-6'>
              <CardInicio
                descricao='Ajude os gestores da sua empresa a conhecer os <b class="text-primary">conceitos</b> da descrição de cargos de forma intuitiva'
                btnName='Ver conceitos'
                btnTo='/conceitos/ajuda'
                imgPath='/media/prolead/inicio/conceitos.svg'
              />
            </div>
            {/* end::Col */}
          </>
        )}

        {(auth?.servico.tipoPlano === EnumServicoTipoPlano.Try || ((auth?.roles.find(x => x === 'Master') !== undefined) || (auth?.roles.find(x => x === 'Administrador') !== undefined) || (auth?.permissoes.find(x => x === 'B60EE4361CAD2C6C9DCE601B8CDF200E') !== undefined))) && (                                    
          <>
            {/* begin::Col */}
            <div className='col-xl-6'>
              <CardInicio
                descricao='Domine nossa plataforma rapidamente com <b class="text-primary">tutoriais interativos</b> baseados em IA'
                btnName='Assistir tutorial'
                btnTo='/ajuda/tutorial'
                imgPath='/media/prolead/inicio/navegacao.svg'
              />
            </div>
            {/* end::Col */}
          </>
        )}
        {(auth?.servico.tipoPlano === EnumServicoTipoPlano.Try && servico?.clienteConsultoriaDescricao == null) && (                                    
          <>
            {/* begin::Col */}
            <div className='col-xl-6'>
              <CardInicio
                descricao='Personalize sua experiência com <b class="text-primary">configurações iniciais</b> simplificadas'
                btnName='Efetuar configurações'
                btnTo='/descricao/configuracoes-iniciais'
                imgPath='/media/prolead/inicio/parametrizacao.svg'
              />
            </div>
            {/* end::Col */}
          </>
        )}

        {(auth?.servico.tipoPlano === EnumServicoTipoPlano.Try || ((auth?.roles.find(x => x === 'Master') !== undefined) || (auth?.roles.find(x => x === 'Administrador') !== undefined) || (auth?.permissoes.find(x => x === '92374908B8E104F71A26BBE0E0126AD2') !== undefined))) && (                                    
          <>
            {/* begin::Col */}
            <div className='col-xl-6'>
              <CardInicio
                descricao='Após as configurações iniciais, <b class="text-primary">liberte sua criatividade</b> com nossa IA generativa em suas descrições de cargos'
                btnName='Descrever com IA'
                btnTo='/descricao/dashboard'
                btnDisabled={moduloDescricaoConfig ? !moduloDescricaoConfig.Desbloqueado : true}
                imgPath='/media/prolead/inicio/descrever.svg'
              />
            </div>
            {/* end::Col */}
          </>
        )}
      </div>
      {/* end::Row */}
    </>
  )
}

export {InicioDescricao}
