/* eslint-disable jsx-a11y/anchor-is-valid */
import { useState } from 'react'
import {Link, Navigate, useNavigate} from 'react-router-dom'
import { KTSVG, toAbsoluteUrl } from '../../../../../_metronic/helpers'
import { useAlert, useHttpClient } from '../../../../modules/services/Bundle'
import { RequestMethod } from '../../../../modules/services/core/_enums'
import { TableParticipantesPesquisa } from './TableParticipantesPesquisa'

type Props = {
  idPesquisa: number
  showModal: boolean
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>
  setIdParticipante: React.Dispatch<React.SetStateAction<number>>
  setShowModalDetalhes: React.Dispatch<React.SetStateAction<boolean>>
  setNomeParticipante: React.Dispatch<React.SetStateAction<string>>
  setStatusParticipante: React.Dispatch<React.SetStateAction<number>>
}

const ModalVisualizarParticipante = ({showModal, setShowModal, idPesquisa, setIdParticipante, setShowModalDetalhes, setNomeParticipante, setStatusParticipante}: Props) => {

  const alert = useAlert()
  const httpClient = useHttpClient()
    
  return (
    <div className={`modal ${showModal? "show" : ""} fade`} tabIndex={-1} id="kt_modal_1" style={{display: `${showModal? "block" : "none"}`}}>
        <div className="modal-dialog modal-dialog-centered modal-xl">
            <div className="modal-content">
            <div className="modal-header">
                <h5 className="modal-title">Participantes da pesquisa</h5>
                <div
                onClick={() => setShowModal(false)}
                className="btn btn-icon btn-sm btn-active-light-primary ms-2"
                data-bs-dismiss="modal"
                aria-label="Fechar"
                >
                <KTSVG
                    path="/media/icons/duotune/arrows/arr061.svg"
                    className="svg-icon svg-icon-2x"
                />
                </div>
            </div>
            <div className="modal-body">
               {showModal && (
                <>
                  <TableParticipantesPesquisa 
                    setIdParticipante={setIdParticipante}
                    setNomeParticipante={setNomeParticipante}
                    setShowModalDetalhes={setShowModalDetalhes}
                    setStatusParticipante={setStatusParticipante}
                    idPesquisa={idPesquisa}
                    setShowModal={setShowModal}
                    showModal={showModal}
                  />
                </>
               )}
            </div>
            <div className="modal-footer d-flex justify-content-between">
              <button
                onClick={() => {
                  alert.createDialog({
                    html: `Esta ação notificará todos os participantes que ainda não finalizaram suas pesquisas, deseja prosseguir?`,
                    confirmAction: async () => {
                      httpClient.request({
                        method: RequestMethod.POST,
                        endpoint: '/Pesquisa/Dashboard/NotificarTodosParticipantes',
                        queryObject: {
                          idPesquisa: idPesquisa
                        }
                      }).then((response) => {
                        
                      })
                    }
                  })
                }}
                type="button"
                className="btn btn-warning text-white"
                data-bs-dismiss="modal"
              >
                  Notificar Todos
              </button>
              <button
              onClick={() => setShowModal(false)}
              type="button"
              className="btn btn-light"
              data-bs-dismiss="modal"
              >
                  Fechar
              </button>
            </div>
            </div>
        </div>
    </div>
  )
}

export {ModalVisualizarParticipante}