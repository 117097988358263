import { Link, useNavigate } from 'react-router-dom'
import Button from '@mui/material/Button';
import { useAuth } from '../../../modules/auth';
import { EnumServicoTipoPlano } from '../../../modules/services/core/_enums';
import { TableEscala } from './partials/TableEscala';

const Escalas = () => {

  const navigate = useNavigate()
  const {auth} = useAuth()
  const servico = auth?.servico

  return (
    <>
      <div className='card mb-xl-10'>
        <div
          className='card-header'
        >
          <div className='card-title m-0'>
            <h3 className='fw-bolder m-0'>Escalas</h3>
          </div>
          {(servico?.tipoPlano === EnumServicoTipoPlano.Buy && ((auth?.roles.find(x => x === 'Master') !== undefined) || (auth?.roles.find(x => x === 'Administrador') !== undefined) || (auth?.permissoes.find(x => x === 'D53F146E0BFBD91135E0C2034E491967')) !== undefined)) && (         
            <div className='card-title m-0'>
              <Button type='button' className='btn btn-light-success' onClick={() => navigate('/pesquisa-ia/escalas/crud-escala', { state: { tipoRedirect: '' } })}>
                <i className="fs-2 fas fa-plus"></i>
                Adicionar
              </Button>
            </div>
          )}
        </div>

        <div className='card-body'>
          <TableEscala />
        </div>
        <div className="card-footer py-4 d-flex justify-content-end">
          <Link className='btn btn-secondary' to='pesquisa-ia/inicio'>
            Voltar
          </Link>
        </div>
      </div>
    </>
  )
}

export {Escalas}
