import { FC, useState } from 'react'
import {Field, ErrorMessage, FieldArray, FormikProps} from 'formik'
import {IExemploDimensaoModel, IExemploDimensaoPerguntaModel, IWizardModel} from '../helpers/PesquisaHelper'
import { useAlert, useHttpClient } from '../../../../modules/services/Bundle'
import { EnumPesquisaTipoDisposicaoPergunta, RequestMethod } from '../../../../modules/services/core/_enums'
import { AbrirModalVideo } from '../../../toolbar/AbrirModalVideo'
import $ from 'jquery';
import { IconButton } from '@mui/material'
import { KTSVG } from '../../../../../_metronic/helpers'
import { PesquisaAccordions } from '../partials/PesquisaAccordions'
import { PesquisaPerguntas } from '../partials/PesquisaPerguntas'
import dayjs from 'dayjs'
import moment from 'moment'
import 'moment/locale/pt-br'
import ReactQuill from 'react-quill'


interface Step4Props {
  formik: FormikProps<IWizardModel>,
  dimensoes: IExemploDimensaoModel[],
  perguntas: IExemploDimensaoPerguntaModel[],
}

const Step4: FC<Step4Props> = ({
  formik,
  dimensoes,
  perguntas,
}) => {

  return (
    <div className='w-100'>
      <h1 className='text-gray-800 text-center mb-3'>Confirmar as informações e concluir</h1>
      <div className='row'>
        <div className='col-lg-12 mb-5  card-finalizar-color rounded'>
          <div id={`accordion-identificacao`} className='mb-8 mt-5 '>
            <div className={`card card-custom shadow-sm titulo-pesquisa-nao-selecionado`}>
              <div className="card-header bg-pesquisa border-bottom border-secondary border-1 rounded-bottom" id={`card-titulo-header-identificacao`}>
                <div className="card-title">
                  <h3 className='text-white'>Pesquisa:                     <span>{formik.values.Nome}</span></h3>
                </div>
                <div className="card-toolbar w-md-auto">
                  <button type="button" className="btn btn-icon btn-sm btn-accordion btn-light" data-bs-toggle="collapse" data-bs-target={`#card-titulo-collapse-identificacao`} aria-expanded="false" aria-controls={`card-titulo-collapse-identificacao`} >
                    <i className='fas fa-arrow-down'></i>
                    <i className='fas fa-arrow-up'></i>
                  </button>
                </div>
              </div>
              <div id={`card-titulo-collapse-identificacao`} className="collapse" aria-labelledby={`card-titulo-header-identificacao`} data-bs-parent={`#accordion-identificacao`}>
                <div className="card-body w-100">
                  {/* Conteúdo da Identificação aqui */}
                  <div className='row'> 
                    <div className='col-md-6 d-flex justify-content-end'> 
                      <div className="title_box col-lg-6 fv-row mb-5 ms-3" id="subject">
                          <div id="title">Mês de Referência</div>
                          <div id="content">
                            <div className='row  px-5'>
                              <div className='col-lg-12'>
                                <input 
                                  placeholder='Mês de Referência'
                                  className='form-control form-control-lg form-control-solid text-center mb-5'
                                  value={formik.values.DataInicial !== undefined ? dayjs(formik.values.DataInicial).locale('pt-br').format('MMMM, YYYY').toUpperCase() : ''}
                                  disabled={true}
                                />
                              </div>
                            </div>
                        </div>
                      </div>
                    </div>
                    <div className='col-md-6'>
                      <div className="title_box col-lg-6 fv-row mb-5 ms-3" id="subject">
                        <div id="title">Período de realização da pesquisa</div>
                        <div id="content">
                          <div className='row  px-5'>
                            <div className='col-lg-6'>
                              <input 
                                placeholder='Data Inicial'
                                className='form-control form-control-lg form-control-solid text-center mb-5'
                                value={formik.values.DataInicial !== undefined ? dayjs(formik.values.DataInicial).locale('pt-br').format('DD/MM/YYYY').toUpperCase() : ''}
                                disabled={true}
                              />
                            </div>
                            <div className='col-lg-6'>
                              <input 
                                placeholder='Data Final'
                                className='form-control form-control-lg form-control-solid text-center mb-5 '
                                value={formik.values.DataFinal !== undefined ? dayjs(formik.values.DataFinal).locale('pt-br').format('DD/MM/YYYY').toUpperCase() : ''}
                                disabled={true}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                </div>
              </div>
            </div>
          </div>
          <div id={`accordion-termos-descricoes`} className='mb-8 mt-5 '>
            <div className={`card card-custom shadow-sm titulo-pesquisa-nao-selecionado`}>
              <div className="card-header bg-pesquisa border-bottom border-secondary border-1 rounded-bottom" id={`card-titulo-header-termos-descricoes`}>
                <div className="card-title">
                  <h3 className='text-white'>Termos e Condições</h3>
                </div>
                <div className="card-toolbar w-md-auto">
                  <button type="button" className="btn btn-icon btn-sm btn-accordion btn-light" data-bs-toggle="collapse" data-bs-target={`#card-titulo-collapse-termos-descricoes`} aria-expanded="false" aria-controls={`card-titulo-collapse-termos-descricoes`} >
                    <i className='fas fa-arrow-down'></i>
                    <i className='fas fa-arrow-up'></i>
                  </button>
                </div>
              </div>
              <div id={`card-titulo-collapse-termos-descricoes`} className="collapse" aria-labelledby={`card-titulo-header-termos-descricoes`} data-bs-parent={`#accordion-termos-descricoes`}>
                <div className="card-body w-100">
                  {/* Conteúdo de Termos e Condições aqui */}
                  <div dangerouslySetInnerHTML={{ __html: formik.values.Termos }} />
                </div>
              </div>
            </div>
          </div>
          <div id={`accordion-tema`} className='mb-8 mt-5 '>
            <div className={`card card-custom shadow-sm titulo-pesquisa-nao-selecionado`}>
              <div className="card-header bg-pesquisa border-bottom border-secondary border-1 rounded-bottom" id={`card-titulo-header-tema`}>
                <div className="card-title">
                  <h3 className='text-white'>Tema: {formik.values.NomeTema}</h3>
                </div>
                <div className="card-toolbar w-md-auto">
                  <button type="button" className="btn btn-icon btn-sm btn-accordion btn-light" data-bs-toggle="collapse" data-bs-target={`#card-titulo-collapse-tema`} aria-expanded="false" aria-controls={`card-titulo-collapse-tema`} >
                    <i className='fas fa-arrow-down'></i>
                    <i className='fas fa-arrow-up'></i>
                  </button>
                </div>
              </div>
              <div id={`card-titulo-collapse-tema`} className="collapse" aria-labelledby={`card-titulo-header-tema`} data-bs-parent={`#accordion-tema`}>
                <div className="card-body w-100">
                      {formik.values.TipoDisposicaoPergunta === EnumPesquisaTipoDisposicaoPergunta.AgruparPorDimensao && dimensoes.length > 0 && dimensoes.map((dimensao, dimensaoIndex) => {
                        return <div key={`accordion-titulo-${dimensao.Id}`} id={`accordion-titulo-${dimensao.Id}`} className='mb-8 w-100'>
                          <div className={`card card-custom border border-secondary border-1 shadow-sm titulo-pesquisa-nao-selecionado`}>
                            <div className="card-header bg-pesquisa border-bottom border-secondary border-1 rounded-bottom" id={`card-titulo-header-${dimensao.Id}`}>
                              <div className="card-title">
                                <h3 className='text-white'>{(dimensao.Nome)}</h3>                                
                              </div>
                              <div className="card-toolbar w-md-auto">                                
                                <button type="button" className="btn btn-icon btn-sm btn-accordion btn-light" data-bs-toggle="collapse" data-bs-target={`#card-titulo-collapse-${dimensao.Id}`} aria-expanded="false" aria-controls={`card-titulo-collapse-${dimensao.Id}`} >
                                  <i className='fas fa-arrow-down'></i>
                                  <i className='fas fa-arrow-up'></i>
                                </button>                   
                              </div>
                            </div>
                            <div id={`card-titulo-collapse-${dimensao.Id}`} className="collapse" aria-labelledby={`card-titulo-header-${dimensao.Id}`} data-bs-parent={`#accordion-titulo-${dimensao.Id}`}>
                              <div className="card-body w-100">
              
                                {(dimensao.Perguntas && dimensao.Perguntas.length > 0) && (
                                  <PesquisaPerguntas
                                    textos={dimensao.Perguntas}
                                  />
                                )}
                              </div>   
                            </div>                         
                          </div>
                        </div>
                      })}
                      {formik.values.TipoDisposicaoPergunta === EnumPesquisaTipoDisposicaoPergunta.OrdenarAleatoriamente && perguntas.length > 0 && (
                        <PesquisaPerguntas
                          textos={perguntas}
                        />
                      )}
              </div>
            </div>
          </div>
          <div id={`accordion-participantes`} className='mb-8 mt-5 '>
            <div className={`card card-custom shadow-sm titulo-pesquisa-nao-selecionado`}>
              <div className="card-header bg-pesquisa border-bottom border-secondary border-1 rounded-bottom" id={`card-titulo-header-participantes`}>
                <div className="card-title">
                  <h3 className='text-white'>Participantes</h3>
                </div>
                <div className="card-toolbar w-md-auto">
                  <button type="button" className="btn btn-icon btn-sm btn-accordion btn-light" data-bs-toggle="collapse" data-bs-target={`#card-titulo-collapse-participantes`} aria-expanded="false" aria-controls={`card-titulo-collapse-participantes`} >
                    <i className='fas fa-arrow-down'></i>
                    <i className='fas fa-arrow-up'></i>
                  </button>
                </div>
              </div>
              <div id={`card-titulo-collapse-participantes`} className="collapse" aria-labelledby={`card-titulo-header-participantes`} data-bs-parent={`#accordion-participantes`}>
                <div className="card-body w-100">
                  {/* Conteúdo dos Participantes aqui */}
                  {formik.values.Participantes.length > 0 && formik.values.Participantes.map((participante, participanteIndex) => {
                    return <div key={`participantes-nomes-${participanteIndex}`} className={''}>
                      <div className='row d-flex justify-content-center'>
                        <div className='py-1 mb-2 me-5 col-lg-5 card-finalizar-color rounded'>{`${participante.Nome}`}</div>  
                        <div className='py-1 mb-2 col-lg-5 card-finalizar-color rounded'>{`${participante.Email}`}</div>
                      </div>
                    </div>
                  })}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
)
}

export {Step4}
