import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { KTSVG, toAbsoluteUrl } from '../../../../../_metronic/helpers'
import { useAlert, useHttpClient } from '../../../../modules/services/Bundle'
import { ErrorMessage, Field, FormikProvider, useFormik } from 'formik'
import { RequestMethod } from '../../../../modules/services/core/_enums'
import {defaultValidationSchemas,initValues} from '../helpers/ModalConsultorHelper'

type Props = {
    abrirModal: boolean
    setAbrirModal: Function
    setAtualizarDadosFecharModal: Function
}



const ModalConsultorDescricao = ({abrirModal, setAbrirModal, setAtualizarDadosFecharModal}: Props) => {

    const httpClient = useHttpClient()
    const [empresasCliente, setEmpresasCliente] = useState<[]>([])   
    const [colaboradores, setColaboradores] = useState<[]>([])   
    const alert = useAlert()
    
    const formik = useFormik({
        initialValues: {...initValues},
        validationSchema: defaultValidationSchemas,
        onSubmit: async (values, actions) => {
          alert.createDialog({
            html: `Realmente deseja adicionar um consultor para essa empresa?`,
            confirmAction: async () => {
              const response = await httpClient.request({
                method: RequestMethod.POST,
                endpoint: '/DescricaoDeCargo/Consultoria/AdicionarConsultor',
                data: values,
              })
              if (response.success){
                setAtualizarDadosFecharModal(true)
                setAbrirModal(false)
              }  
            },
          })
        },
    })

    const carregarConsultores = async () => {
        const response = await httpClient.request({
            method: RequestMethod.GET,
            endpoint: '/DescricaoDeCargo/Consultoria/ObterDadosConsultor'
        })

        if(response.success && response.payload){
            setColaboradores(response.payload.Consultores)
            setEmpresasCliente(response.payload.EmpresasCliente)
        }
    }

    useEffect(() => {
        carregarConsultores()
    }, [])

    return (
        <div className={`modal ${abrirModal? "show" : ""} fade`} tabIndex={-1} id="kt_modal_1" style={{display: `${abrirModal? "block" : "none"}`}}>
            <div className="modal-dialog modal-dialog-centered modal-xl">
                <div className="modal-content">
                <div className="modal-header" >
                    <h5 className="modal-title">Adicionar Consultor</h5>
                    <button
                        onClick={() => setAbrirModal(false)}
                        className="btn btn-icon btn-sm btn-active-light-primary ms-2"
                        data-bs-dismiss="modal"
                        aria-label="Fechar"
                    >
                    <KTSVG
                        path="/media/icons/duotune/arrows/arr061.svg"
                        className="svg-icon svg-icon-2x"
                    />
                    </button>
                </div>
                <div className="modal-body">
                    <FormikProvider value={formik}>
                        <div className='row'>
                            <div className='col-lg-6 fv-row mb-5'>
                                <label className='form-label required'>Consultores</label>
                                <Field
                                    as='select'
                                    name='IdUsuario'
                                    className='form-select form-select-lg form-select-solid'
                                    value={formik.values.IdUsuario}
                                >
                                    <option value={undefined}>Selecione...</option>
                                    {
                                        colaboradores && colaboradores.length > 0 && colaboradores.map((data: any, index: any) => {
                                            return <option key={`IdUsuario-option-${index}`} value={data.Id}>{data.Nome}</option>
                                        })
                                    }
                                    
                                </Field>
                                <div className='text-danger mt-2'>
                                    <ErrorMessage name='IdUsuario' />
                                </div>
                            </div>
                            <div className='col-lg-6 fv-row mb-5'>
                                <label className='form-label required'>Cliente</label>
                                <Field
                                    as='select'
                                    name='IdCliente'
                                    className='form-select form-select-lg form-select-solid'
                                    value={formik.values.IdCliente}
                                >
                                    <option value={undefined}>Selecione...</option>
                                    {
                                        empresasCliente && empresasCliente.length > 0 && empresasCliente.map((data: any, index: any) => {
                                            return <option key={`IdCliente-option-${index}`} value={data.Id}>{data.Nome}</option>
                                        })
                                    }
                                    
                                </Field>
                                <div className='text-danger mt-2'>
                                    <ErrorMessage name='IdCliente' />
                                </div>
                            </div>
                        </div>                        
                    </FormikProvider>                
                </div>
                <div className="modal-footer">
                    <button
                        onClick={() => setAbrirModal(false)}
                        type="button"
                        className="btn btn-light me-5"
                        data-bs-dismiss="modal"
                    >
                        Cancelar
                    </button>

                    <button
                        onClick={() => formik.handleSubmit()}
                        type="button"
                        className="btn btn-light-primary"
                    >
                        Salvar
                    </button>
                </div>
            </div>
        </div>
        </div>
    )
}

export {ModalConsultorDescricao}