/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { ReactNode, useEffect, useState } from 'react'
import { Field, FormikProps } from 'formik'
import { IDescreverDescricaoModel, IDescreverRespostaModel, IDescreverTipoCampoModel } from '../helpers/DescreverHelper'
import { SortableContainer, SortableElement, SortableHandle } from 'react-sortable-hoc'
import { useAlert, useHttpClient } from '../../../../modules/services/Bundle'
import { EnumServicoTipoPlano, RequestMethod } from '../../../../modules/services/core/_enums'
import { ServicoModel, useAuth } from '../../../../modules/auth'

interface SortableHandleProps {
  respostaSelecionada: number | undefined,
  idResposta?: number
} 

const DragHandle = SortableHandle<SortableHandleProps>(({respostaSelecionada, idResposta}: SortableHandleProps) => 
<span className={respostaSelecionada && idResposta && respostaSelecionada === idResposta ? '' : 'd-none'} style={{
  position: 'relative',
  float: 'right',    
  top: '4px',
  right: '6px',
  height: '0px',
  cursor: 'move'
}}>
  <i className="fas fa-grip-vertical"></i>
</span>
)

interface SortableElementProps {
  respostas: IDescreverRespostaModel[],
  tipoCampo: IDescreverTipoCampoModel,
  campoIndex: number,
  respostaSelecionada: number | undefined,
  respostaSendoEditada: boolean,
  resposta: IDescreverRespostaModel,
  respostaIndex: number,
  onClickResposta: Function,
  respostaDescricaoClass: string,
  servico: ServicoModel | undefined,
  currentUrl: string;
} 

const borderStyle = (resposta: IDescreverRespostaModel, currentUrl: string) => {
  const style = { border: '' }

  if(resposta.ValorAtual?.ObservacaoReprovado){
    style.border = '2px solid red';
  }else{
    if(currentUrl === "/descricao/aprovar"){
      style.border = "2px solid green";
    }
  }

  return style;
}

const SortableItem = SortableElement<SortableElementProps>(({respostas, tipoCampo, campoIndex, respostaSelecionada, respostaSendoEditada, resposta, respostaIndex, onClickResposta, respostaDescricaoClass, servico, currentUrl}: SortableElementProps) => (
  <div 
    className={`${respostaIndex < (respostas.length - 1) ? 'mb-5' : ''}`}
    onClick={() => onClickResposta(resposta)}
  >
    <DragHandle 
      respostaSelecionada={respostaSelecionada}
      idResposta={resposta.Id}
    />
    {!respostaSendoEditada ? (
      <span
        className={`form-control form-control-lg form-control-solid ${tipoCampo.TipoInputHTML === 1 ? 'input-resposta-descricao' : 'textarea-resposta-descricao'} ${respostaDescricaoClass} ${respostaSelecionada === resposta.Id ? 'resposta-descricao-selecionada' : 'resposta-descricao-nao-selecionada'}`}
        style={servico?.tipoPlano === EnumServicoTipoPlano.Try ? {} : borderStyle(resposta, currentUrl)}  
      >
        {resposta.ValorAtual?.Texto ?? ''}
      </span>
      ) : (
      <Field
        as={tipoCampo.TipoInputHTML === 1 ? 'input' : 'textarea'}
        name={`Campos.${campoIndex}.Respostas.${respostaIndex}.ValorAtual.Texto`}                  
        className={`form-control form-control-lg form-control-solid ${tipoCampo.TipoInputHTML === 1 ? 'input-resposta-descricao' : 'textarea-resposta-descricao'} ${respostaDescricaoClass} ${respostaSelecionada === resposta.Id ? 'resposta-descricao-selecionada' : 'resposta-descricao-nao-selecionada'}`}
        disabled={respostaSelecionada !== resposta.Id || (respostaSelecionada === resposta.Id && !respostaSendoEditada)}
        style={servico?.tipoPlano === EnumServicoTipoPlano.Try ? {} : borderStyle(resposta, currentUrl)}                                            
        value={resposta.ValorAtual?.Texto ?? ''}
      />

    )}    
    <div className={`mt-2 collapse  ${resposta.ValorAtual?.ObservacaoReprovado ? "show" : ""}`}>
      <label htmlFor="observacao" aria-required="true" className="form-label font-weight-bold">Observação:</label>
      <textarea 
        id="observacao" 
        required 
        rows={5} 
        readOnly={true}
        data-id={resposta.IdValorAtual} 
        className="observacao form-control form-control-solid"
        defaultValue={resposta.ValorAtual?.ObservacaoReprovado}
      />
    </div>
  </div>
))

interface SortableContainerProps {
  children: ReactNode
} 

const SortableList = SortableContainer<SortableContainerProps>(({children}: SortableContainerProps) => {
  return <div>{children}</div>;
})

type Props = {
  formik: FormikProps<IDescreverDescricaoModel>,
  respostas: IDescreverRespostaModel[],
  tipoCampo: IDescreverTipoCampoModel,
  campoIndex: number,
  campoSelecionado: number | undefined,
  fazerBackupDaResposta: Function,
  setCampoSelecionado: React.Dispatch<React.SetStateAction<number | undefined>>,
  respostaSelecionada: number | undefined,
  setRespostaSelecionada: React.Dispatch<React.SetStateAction<number | undefined>>,
  respostaSendoEditada: boolean,
  setRespostaSendoEditada: React.Dispatch<React.SetStateAction<boolean>>,
  respostaBackup: IDescreverRespostaModel | undefined,
  setRespostaBackup: React.Dispatch<React.SetStateAction<IDescreverRespostaModel | undefined>>,  
}

const DescricaoRespostas: React.FC<Props> = ({
  formik,
  respostas,
  tipoCampo,
  campoIndex,
  fazerBackupDaResposta,
  campoSelecionado,
  setCampoSelecionado,
  respostaSelecionada,
  setRespostaSelecionada,
  respostaSendoEditada,
  setRespostaSendoEditada,
  respostaBackup,
  setRespostaBackup,  
}) => {
  const respostaDescricaoClass = `resposta-descricao ${tipoCampo.InteracaoComAI ? 'resposta-descricao-interativa' : ''}`
  const httpClient = useHttpClient()

  
  const {auth} = useAuth()
  const servico = auth?.servico

  const [currentUrl, setCurrentUrl] = useState('');

  useEffect(()=>{
    const url = window.location.pathname;
    setCurrentUrl(url);

    const handleUrlChange = () => {
      const updatedUrl = window.location.href;
      setCurrentUrl(updatedUrl);
    };

    window.addEventListener('popstate', handleUrlChange);

    return () => {
      window.removeEventListener('popstate', handleUrlChange);
    };
  }, [])


  const onClickResposta = (resposta: IDescreverRespostaModel) => {
    if(respostaSelecionada != resposta.Id){
      fazerBackupDaResposta()
      setCampoSelecionado(resposta.IdCampo)
      setRespostaSelecionada(resposta.Id)
      setRespostaBackup(resposta)
    }
  }
  
  const moverResposta = (respostas: IDescreverRespostaModel[], oldIndex: number, newIndex: number) => {
    var resposta = respostas[oldIndex]
    respostas.splice(oldIndex, 1)
    respostas.splice(newIndex, 0, resposta)
    for(let i = 0; i < respostas.length; i++) respostas[i].Ordem = i
    return respostas
  }

  interface OnSortProps {
    oldIndex: number,
    newIndex: number
  }

  const onSortEnd = async ({oldIndex, newIndex}: OnSortProps) => {

    if(oldIndex !== newIndex) {
      let descricao = formik.values
      let campoIndex = descricao.Campos.findIndex((c => c.Id === campoSelecionado))
      descricao.Campos[campoIndex].Respostas = moverResposta(descricao.Campos[campoIndex].Respostas, oldIndex, newIndex)
      formik.setValues(descricao)

      const response = await httpClient.request({
        method: RequestMethod.GET,
        endpoint: '/DescricaoDeCargo/Descrever/OrdenarResposta',
        queryObject: {
          idDescricao: formik.values.Id,
          idCampo: campoSelecionado,
          oldIndex: oldIndex,
          newIndex: newIndex,
        }
      })

      if(!response.success) {
        let backupDescricao = formik.values
        backupDescricao.Campos[campoIndex].Respostas = moverResposta(backupDescricao.Campos[campoIndex].Respostas, newIndex, oldIndex)
        formik.setValues(backupDescricao)
      }
    }
    
  }

  return (
    <SortableList onSortEnd={onSortEnd} useDragHandle>
      {respostas.map((resposta, respostaIndex) => (
        <SortableItem 
          key={`descricao-resposta-${resposta.Id}`}
          index={respostaIndex}
          respostas={respostas}
          tipoCampo={tipoCampo}
          campoIndex={campoIndex}
          respostaSelecionada={respostaSelecionada}
          respostaSendoEditada={respostaSendoEditada}
          resposta={resposta}
          respostaIndex={respostaIndex}
          onClickResposta={onClickResposta}
          servico={servico}
          respostaDescricaoClass={respostaDescricaoClass}
          currentUrl={currentUrl}
        />
      ))}
    </SortableList>
  )
}

export {DescricaoRespostas}
