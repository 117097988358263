/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react'
import {Link} from 'react-router-dom'
import {KTSVG, toAbsoluteUrl} from '../../../../../_metronic/helpers'
import {StatusPesquisa, TablePesquisa} from './TablePesquisa'

export interface IDashboardPesquisaDados {
  TotalPesquisas: number
  PesquisasNaoLiberadas: number
  PesquisasNaoIniciadas: number
  PesquisasEmAndamento: number
  PesquisasSuspensas: number
  PesquisasFinalizadas: number
  PesquisasNaoLiberadasPorcentagem: number
  PesquisasNaoIniciadasPorcentagem: number
  PesquisasEmAndamentoPorcentagem: number
  PesquisasSuspensasPorcentagem: number
  PesquisasFinalizadasPorcentagem: number
}

type Props = {
  className?: string
  color: string
  svgIcon: string
  iconColor: string
  title: string
  titleColor?: string
  btnTo: string
  statusPesquisa: StatusPesquisa
  setDadosDash: Function
  dados: IDashboardPesquisaDados
}

const CardPesquisa: React.FC<Props> = ({
  className = '',
  color,
  svgIcon,
  iconColor,
  title,
  titleColor,
  btnTo,
  statusPesquisa,
  setDadosDash,
  dados
}) => {

  const [statusPesquisaAtual, setStatusPesquisaAtual] = useState<StatusPesquisa>(statusPesquisa);

  const handleAccordionToggle = () => {
      document.getElementById('kt_accordion_1_body_Status_das_Pesquisas')?.classList.add('show');
      document.querySelector('#kt_accordion_Status_das_Pesquisas .accordion-header')?.classList.remove('collapsed'); 
  };

  const total = statusPesquisaAtual === StatusPesquisa.NaoLiberada ? dados.PesquisasNaoLiberadas 
  : statusPesquisaAtual === StatusPesquisa.NaoIniciada ? dados.PesquisasNaoIniciadas 
  : statusPesquisaAtual === StatusPesquisa.EmAndamento ? dados.PesquisasEmAndamento
  : statusPesquisaAtual === StatusPesquisa.Suspensa ? dados.PesquisasSuspensas
  : dados.PesquisasFinalizadas;

  const chartValue = statusPesquisaAtual === StatusPesquisa.NaoLiberada ? dados.PesquisasNaoLiberadasPorcentagem 
  : statusPesquisaAtual === StatusPesquisa.NaoIniciada ? dados.PesquisasNaoIniciadasPorcentagem
  : statusPesquisaAtual === StatusPesquisa.EmAndamento ? dados.PesquisasEmAndamentoPorcentagem
  : statusPesquisaAtual === StatusPesquisa.Suspensa ? dados.PesquisasSuspensasPorcentagem
  : dados.PesquisasFinalizadasPorcentagem;  

  return (
    <>
      <div
        className='accordion accordion-icon-toggle'
        id={`kt_accordion_${title.replaceAll(' ', '_').replace(':', '')}`}
      >
        <div
          className={`accordion-header d-flex collapsed w-100 card hoverable ${className}`}
          data-bs-toggle='collapse'
          data-bs-target={`#kt_accordion_1_body_${title.replaceAll(' ', '_').replace(':', '')}`}
          data-bs-parent={null}
          style={{
            backgroundColor: color,
          }}
        >
          <div className='col-lg-11 ribbon ribbon-top'>
            <div
              className={`ribbon-label fw-bold me-2 display-3 opacity-80`}
              style={{backgroundColor: titleColor, color: color}}
            >
              {total}
            </div>
          </div>
          <div className='w-100'>
            <div className='card-body'>
              <div className='row'>
                <div className='col-lg-11'>
                  <div className='row align-items-center'>
                    <div className='col-lg-8 text-start'>
                      <Link
                        to={btnTo}
                        className={`text-${titleColor} hover-link-name-dash fw-bold fs-2 mt-5`}
                      >
                        <KTSVG
                          path={svgIcon || ''}
                          className={`svg-icon-${iconColor} svg-icon-3x ms-n1`}
                        />
                        <span className='mt-2 ms-3'>{title}</span>
                      </Link>
                      {(statusPesquisaAtual === StatusPesquisa.NaoIniciada 
                      || statusPesquisaAtual === StatusPesquisa.EmAndamento 
                      || statusPesquisaAtual === StatusPesquisa.Suspensa) && (
                        <>
                          <button className='btn pe-0' onClick={(e) => {
                            handleAccordionToggle()
                            setStatusPesquisaAtual(StatusPesquisa.NaoIniciada)
                          }}>
                            <span className={`badge ${statusPesquisaAtual === StatusPesquisa.NaoIniciada ? "badge-secondary" : "badge-light"}`}>Não Iniciadas</span>
                          </button>
                          <button className='btn pe-0' onClick={(e) => {
                            handleAccordionToggle()
                            setStatusPesquisaAtual(StatusPesquisa.EmAndamento)
                          }}>
                            <span className={`badge ${statusPesquisaAtual === StatusPesquisa.EmAndamento ? "badge-secondary" : "badge-light"}`}>Em Andamento</span>
                          </button>
                          <button className='btn pe-0' onClick={(e) => {
                            handleAccordionToggle()
                            setStatusPesquisaAtual(StatusPesquisa.Suspensa)
                          }}>
                            <span className={`badge ${statusPesquisaAtual === StatusPesquisa.Suspensa ? "badge-secondary" : "badge-light"}`}>Suspensas</span>
                          </button>
                        </>
                      )}
                    </div>
                  </div>
                  <div className='mt-5'>
                    <div className='d-flex justify-content-between fw-bold fs-2 text-white  w-100 mt-auto mb-2'>
                      <span>total</span>
                      <span>{chartValue}%</span>
                    </div>

                    <div className='h-8px w-100 rounded' style={{backgroundColor: '#ffffff4a'}}>
                      <div
                        className='bg-white rounded h-8px'
                        role='progressbar'
                        style={{width: `${chartValue}%`}}
                        aria-valuenow={50}
                        aria-valuemin={0}
                        aria-valuemax={100}
                      ></div>
                    </div>
                  </div>
                </div>
                <div className='col-lg-1 d-flex justify-content-end'>
                  <span className='accordion-icon '>
                    <i className='fas icon-dashboard-arrow fa-angle-down text-white'></i>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          id={`kt_accordion_1_body_${title.replaceAll(' ', '_').replace(':', '')}`}
          className='accordion-collapse collapse'
          aria-labelledby={`kt_accordion_1_header_${title.replaceAll(' ', '_').replace(':', '')}`}
          data-bs-parent={`#kt_accordion_${title.replaceAll(' ', '_').replace(':', '')}`}
        >
          <div style={{marginTop: '-4px'}}>
            <TablePesquisa
              title={title}
              statusPesquisa={statusPesquisaAtual}
              buscar={true}
              titleColor={titleColor ?? ''}
              color={color}
              setDadosDash={setDadosDash}
            />
          </div>
        </div>
      </div>
    </>
  )
}

export {CardPesquisa}
