import { FormikProps } from 'formik'
import * as Yup from 'yup'
import { IEscalaDescricaoModel } from '../../../pesquisa/components/helpers/CrudEscalaHelper'

export interface IPesquisaParticipanteModel {
  Id: number
  IdPesquisa: number
  Email: string
  Nome: string
  Chave: string
  Status: number
  StatusString: string
  StatusColor: string
}

export interface IApresentarDimensaoPerguntaModel {
  Id:number             
  IdDimensao:number               
  Ordem:number       
  Texto:string
  Resposta?:number
  RespostaB?: number
  QuestaoPergunta: IApresentarDimensaoPerguntalQuestaoModel[]
  Respondida: boolean
}

export interface IApresentarDimensaoPerguntalQuestaoModel{
  Id: number
  ValorQuestao: number
}

export interface IApresentarDimensaoModel {
  Id:number             
  IdTema:number 
  Ordem:number  
  Nome:string             
  Texto:string[]
  Perguntas:IApresentarDimensaoPerguntaModel[]
  UtilizaQuestao: boolean
  QtdPerguntas: number
  QtdPerguntasRespondidas: number
  Porcentagem: number
  TodasPerguntasRespondidas: boolean
}

export interface IApresentarTopicoModel {
  Id:number             
  IdTema:number 
  Ordem:number              
  Texto:string
}

export interface IPesquisaModel {
  Id:number              
  IdTema:number              
  NomeTema:string           
  Nome:string           
  Descricao:string           
  Termos:string           
  Status:number           
  StatusString:string           
  StatusColor:string           
  DataInicial:Date|undefined             
  DataInicialString:string           
  DataInicialStringYMD:string           
  DataFinal:Date|undefined           
  DataFinalString:string     
  DataFinalStringYMD:string     
  TipoDisposicaoPergunta:number
  TipoDisposicaoPerguntaString:string           
  QtdMinRespParaMostrarResultados:number           
  QtdDimensoes:number           
  QtdPerguntas:number           
}

export interface ITemaModel{
  Id:number
  IdEmpresa:number
  Nome:string
  QtdDimensao:number
  QtdPesquisa:number
  QtdPergunta:number
}

const initValuesParticipante: IPesquisaParticipanteModel = {
  Id: 0,
  IdPesquisa: 0,
  Email: '',
  Nome: '',
  Status: 1,
  StatusString: '',
  StatusColor: '',
  Chave: '',
}

const initValuesPesquisa: IPesquisaModel = {
  Id:0,              
  IdTema:0,              
  NomeTema:'',           
  Nome:'',           
  Descricao:'',           
  Termos:'',   
  Status:1,            
  StatusString:'',           
  StatusColor:'',           
  DataInicial:undefined,           
  DataInicialString:'',           
  DataInicialStringYMD:'',           
  DataFinal:undefined,            
  DataFinalString:'',         
  DataFinalStringYMD:'',         
  TipoDisposicaoPergunta:1,
  TipoDisposicaoPerguntaString:'',
  QtdMinRespParaMostrarResultados:0,           
  QtdDimensoes:0,           
  QtdPerguntas:0,         
}

const initValuesTema: ITemaModel = {
  Id:0,
  IdEmpresa:0,
  Nome:'',
  QtdDimensao:0,
  QtdPesquisa:0,
  QtdPergunta:0,
}

const initialValuesDescricao: IEscalaDescricaoModel = {
  Inicio: 0,
  Fim: 0,
  Descricao: '',
  Cor: ''
}

const initialValuesEscala: IEscalaModel = {
Id: 0,        
Nome: '',
Inicio: 0,
Fim: 0,
EscalaDescricao: [initialValuesDescricao]
}

interface IEscalaModel {
  Id?: number
  Nome: string
  Inicio: number
  Fim: number
  EscalaDescricao?: IEscalaDescricaoModel[]
}

export interface IWizardModel {
  Participante: IPesquisaParticipanteModel
  Pesquisa: IPesquisaModel
  Dimensoes: IApresentarDimensaoModel[]
  Perguntas: IApresentarDimensaoPerguntaModel[]
  QtdTotalPerguntas: number
  QtdTotalRespostas: number
  Topicos: IApresentarTopicoModel[]
  Tema: ITemaModel
  Termos: boolean
  EscalaPesquisa: IEscalaModel
}

const initValues: IWizardModel = {
  Participante: initValuesParticipante,
  Pesquisa: initValuesPesquisa,
  Dimensoes: [],
  Perguntas: [],    
  QtdTotalPerguntas: 0, 
  QtdTotalRespostas: 0,
  Topicos: [],
  Tema: initValuesTema,
  Termos: false,
  EscalaPesquisa: initialValuesEscala
}

export interface StepProps {
  formik: FormikProps<IWizardModel>,
}

const defaultValidationSchemasDimensoes = [
  Yup.object({

  }),  
  
  Yup.object({

  }),
  
  Yup.object({
    Dimensoes: Yup.array().of(
      Yup.object().shape({
        Perguntas: Yup.array().of(
          Yup.object().shape({
             Resposta: Yup.string().nullable().required(),
          })
        ),
        TodasPerguntasRespondidas: Yup.boolean().isTrue('É necessário responder todas as perguntas da dimensão!')
      })
    ),
  }),
  Yup.object({

  }),
]

const defaultValidationSchemasPerguntas = [
  Yup.object({

  }),

  Yup.object({

  }),

  Yup.object({
    Perguntas: Yup.array().of(
      Yup.object().shape({
        Resposta: Yup.string().nullable().required(),
      })
    ),
  }),
  Yup.object({

  }),
]

export {defaultValidationSchemasDimensoes, defaultValidationSchemasPerguntas, initValues, initValuesParticipante}