import { FC, useState } from 'react'
import {Field, ErrorMessage, FieldArray, FormikProps} from 'formik'
import { initValuesParticipante, IWizardModel } from '../helpers/PesquisaHelper'
import { useAlert, useHttpClient } from '../../../../modules/services/Bundle'
import { EnumPesquisaStatus, RequestMethod } from '../../../../modules/services/core/_enums'
import { IconButton } from '@mui/material'
import { KTSVG } from '../../../../../_metronic/helpers'

interface Step3Props {
  formik: FormikProps<IWizardModel>,
}

const Step3: FC<Step3Props> = ({
  formik,
}) => {
  const httpClient = useHttpClient()
  const alert = useAlert()

  const onClickAdicionarParticipante = async () => {
    if (formik.isValid) {
      const response = await httpClient.request({
        method: RequestMethod.GET,
        endpoint: '/Pesquisa/Pesquisa/VerificarQtdMaximaParticipantes',
        queryObject: {
          qtdParticipantes: formik.values.Participantes.length
        },
      })

      if (response.success ) {
        const newValues = formik.values
        newValues.Participantes.push(initValuesParticipante)
        formik.setValues(newValues)
      }
    } else {
      alert.createMessage({
        html: `Deve preencher todos os dados antes de adicionar um novo campo!`,
      })
    }

  }

  const onClickRemoverParticipante = (index:number) => {
    const newValues = formik.values
    if (newValues.Participantes.length > 1) {
      newValues.Participantes.splice(index, 1)
      formik.setValues(newValues)
    } else{
      alert.createMessage({
        html: `Deve haver ao menos um participante!`,
      })
    }
  }

  return (
    <>
      <div className='w-100 '>   
        <div 
          className='w-100 mb-5 scroll scroll-height-step-4 h-475px '
        >
          <div key={`accordion-participantes`} id={`accordion-participantes`} className=' w-100'>
            <div className={`card card-custom border border-secondary border-1 shadow-sm titulo-pesquisa-nao-selecionado`}>
              <div className="card-header bg-pesquisa border-bottom border-secondary border-1 rounded-bottom" id={`card-titulo-header-participantes`}>
                <div className="card-title">
                  <h3 className='text-white'>Informe os participantes da pesquisa: <span className='text-white'>{formik.values.Nome}</span></h3>                                
                </div>
                <div className="card-toolbar w-md-auto">     
                  {formik.values.Status !== EnumPesquisaStatus.Finalizada && formik.values.Status !== EnumPesquisaStatus.EmAndamento && (
                    <button type="button" onClick={() => onClickAdicionarParticipante()} className="btn btn-icon btn-sm btn-accordion btn-light">
                      <i className='fas fa-plus fs-1'></i>
                    </button>
                  )}                           
                </div>
              </div>
              <div id={`card-titulo-collapse-participantes`} className="collapse show" aria-labelledby={`card-titulo-header-participantes`} data-bs-parent={`#accordion-participantes`}>
                <div className="card-body">
                  {formik.values.Participantes && formik.values.Participantes.length > 0 && formik.values.Participantes.map((participante, participanteIndex) => { 
                    return <div key={`participante-${participanteIndex}`} className={`row`}>
                      <div className='col-lg-3 mb-5'>
                        <label className='form-label required ms-5' hidden={participanteIndex > 0}>&nbsp;Nome</label>
                        <Field 
                          name={`Participantes.${participanteIndex}.Nome`}
                          maxLength={60}
                          placeholder='Nome'
                          className='form-control form-control-lg form-control-solid'
                          value={participante.Nome}
                          disabled={formik.values.Status === EnumPesquisaStatus.Finalizada || formik.values.Status === EnumPesquisaStatus.EmAndamento}
                        />
                        <div className='text-danger mt-2'>
                          <ErrorMessage name={`Participantes.${participanteIndex}.Nome`}/>
                        </div>
                      </div>

                      <div className='col-lg-4 mb-5'>
                        <label className='form-label required ms-5' hidden={participanteIndex > 0}>&nbsp;E-mail</label>
                        <Field 
                          name={`Participantes.${participanteIndex}.Email`}
                          maxLength={60}
                          placeholder='E-mail'
                          className='form-control form-control-lg form-control-solid'
                          value={participante.Email}
                          disabled={formik.values.Status === EnumPesquisaStatus.Finalizada || formik.values.Status === EnumPesquisaStatus.EmAndamento}
                        />
                        <div className='text-danger mt-2'>
                          <ErrorMessage name={`Participantes.${participanteIndex}.Email`}/>
                        </div>
                      </div>

                      <div className='col-lg-4 mb-5'>
                        <label className='form-label required ms-5' hidden={participanteIndex > 0}>&nbsp;Confirme o e-mail</label>
                        <Field 
                          name={`Participantes.${participanteIndex}.ConfirmeEmail`}
                          maxLength={60}
                          placeholder='Confirme o e-mail'
                          className='form-control form-control-lg form-control-solid'
                          value={participante.ConfirmeEmail}
                          disabled={formik.values.Status === EnumPesquisaStatus.Finalizada || formik.values.Status === EnumPesquisaStatus.EmAndamento}
                        />
                        <div className='text-danger mt-2'>
                          <ErrorMessage name={`Participantes.${participanteIndex}.ConfirmeEmail`}/>
                        </div>
                      </div>
                      {((formik.values.Status !== EnumPesquisaStatus.Suspensa && formik.values.Status !== EnumPesquisaStatus.Finalizada && formik.values.Status !== EnumPesquisaStatus.EmAndamento) || (formik.values.Status === EnumPesquisaStatus.Suspensa && participante.Id === 0) )&& (
                        <div className='col-lg-1 mb-5 mt-5 d-flex align-items-center justify-content-end '>
                          <IconButton className='icon-button-dark-hover' onClick={() => onClickRemoverParticipante(participanteIndex)} color="primary" aria-label="upload picture" component="label"
                          >
                            <KTSVG path="/media/icons/duotune/general/gen034.svg" className="svg-icon-muted svg-icon-2hx text-danger " />
                          </IconButton>
                        </div>
                      )}
                    </div>
                  })}
                </div>   
              </div>                         
            </div>
          </div>
        </div>
        <div className='row'>
          <div 
            className='col-lg-12 mb-5 d-flex'
          >
            <h3 className='stepper-title text-start mt-3 me-2'>Nº mínimo de respondentes da pesquisa para apresentação dos resultados: </h3>  
            <div style={{width: '10%'}}>
              <Field 
                type={'number'}
                name={`QtdMinRespParaMostrarResultados`}
                placeholder='Nome'
                className='form-control form-control-lg form-control-solid'
                value={formik.values.QtdMinRespParaMostrarResultados}
                disabled={formik.values.Status === EnumPesquisaStatus.Finalizada || formik.values.Status === EnumPesquisaStatus.EmAndamento}
              />

            </div>
          </div>
          <div className='text-danger'>
            <ErrorMessage name={`QtdMinRespParaMostrarResultados`}/>
          </div> 
        </div>
      </div>
    </>
  )
}

export {Step3}
