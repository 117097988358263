import * as Yup from 'yup'
import { FileModel } from '../../../../modules/services/core/_models'


export interface IArquivoModel {
  Arquivo: FileModel | undefined
}

export interface IIdEmpresaClienteModel {
  IdEmpresa: number
  ColabCliente: boolean
}

export interface ICarregarColaboradoresModel {
  Dados: IArquivoModel,
  Empresa: IIdEmpresaClienteModel
}

const defaultArquivo: IArquivoModel = {
  Arquivo: undefined
}

const defaultIdEmpresaCliente: IIdEmpresaClienteModel = {
  IdEmpresa: 0,
  ColabCliente: false,
}

const defaultCarregarColaboradores: ICarregarColaboradoresModel = {
  Dados: defaultArquivo,
  Empresa: defaultIdEmpresaCliente
}

const defaultValidationSchemas = Yup.object().shape({
  Dados: Yup.object().shape({
    Arquivo: Yup.object().required('O arquivo do modelo é obrigatório'),
  }),
  Empresa: Yup.object().shape({
    IdEmpresa: Yup.number().when('ColabCliente', {
      is: true,
      then: Yup.number().nullable()
      .required('A empresa cliente é obrigatória').min(1, 'Selecione uma empresa cliente'),
    }),
  }),
})

export {defaultValidationSchemas, defaultCarregarColaboradores}