/* eslint-disable react-hooks/exhaustive-deps */
import * as React from 'react'
import Box from '@mui/material/Box'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TablePagination from '@mui/material/TablePagination'
import TableRow from '@mui/material/TableRow'
import TableSortLabel from '@mui/material/TableSortLabel'
import Paper from '@mui/material/Paper'
import {visuallyHidden} from '@mui/utils'
import {useAlert, useHttpClient} from '../../../modules/services/Bundle'
import {
  RequestMethod,
  EnumDescricaoStatusAprovacao,
  EnumServicoTipoPlano,
} from '../../../modules/services/core/_enums'
import {Link} from 'react-router-dom'
import {IconButton} from '@mui/material'
import {ModalAddCargo} from './partials/ModalAddCargo'
import {useAuth} from '../../../modules/auth'
import {ModalAprovadores} from './partials/ModalAprovadores'
import { KTSVG } from '../../../../_metronic/helpers'

interface Column {
  id: 'Id' | 'NomeCargo' | 'NomeArea' | 'NomeNivelHierarquico' | 'NomeModelo' | 'StatusAprovacao' | 'IdModelo'
  label: string
  minWidth?: number
  align?: 'center'
  format?: (value: number) => string
}

interface Filtros {
  IdModelo?: number
  IdCargo?: number
  IdArea?: number
  IdNivelHierarquico?: number
  StatusAprovacao?: number
}

const columns: readonly Column[] = [
  {
    id: 'NomeCargo',
    label: 'CARGO',
  },
  {
    id: 'NomeArea',
    label: 'ÁREA',
  },
  {
    id: 'NomeNivelHierarquico',
    label: 'NÍVEL HIERÁRQUICO',
  },
  {
    id: 'NomeModelo',
    label: 'MODELO',
  },
  {
    id: 'StatusAprovacao',
    label: 'STATUS',
  },
]

interface Data {
  Id: number
  IdCargo: number
  NomeCargo: string
  NomeArea: string
  NomeNivelHierarquico: string
  NomeModelo: string
  StatusAprovacao: number
  IdModelo: number
}

function createData(
  Id: number,
  IdCargo: number,
  NomeCargo: string,
  NomeArea: string,
  NomeNivelHierarquico: string,
  NomeModelo: string,
  StatusAprovacao: number,
  IdModelo: number
): Data {
  return {Id, IdCargo, NomeCargo, NomeArea, NomeNivelHierarquico, NomeModelo, StatusAprovacao, IdModelo}
}

type Order = 'asc' | 'desc'

interface EnhancedTableProps {
  numSelected: number
  onRequestSort: (event: React.MouseEvent<unknown>, property: keyof Data) => void
  onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void
  order: Order
  orderBy: string
}

function EnhancedTableHead(props: EnhancedTableProps) {
  const {order, orderBy, onRequestSort} = props
  const createSortHandler = (property: keyof Data) => (event: React.MouseEvent<unknown>) => {
    onRequestSort(event, property)
  }

  return (
    <TableHead>
      <TableRow className='table-header'>
        {columns.map((columns) => (
          <TableCell
            className='table-cell'
            key={columns.id}
            align={columns.align ? 'right' : 'left'}
          >
            <TableSortLabel
              className='fw-bolder'
              active={orderBy === columns.id}
              direction={orderBy === columns.id ? order : 'asc'}
              onClick={createSortHandler(columns.id)}
            >
              {columns.label}
              {orderBy === columns.id ? (
                <Box component='span' sx={visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
        <TableCell className='table-cell fw-bolder' align='left'>
          AÇÕES
        </TableCell>
      </TableRow>
    </TableHead>
  )
}

interface TipoDescricao {
  title?: string
}

const Revisar = ({title}: TipoDescricao) => {
  const httpClient = useHttpClient()
  const [order, setOrder] = React.useState<Order>('asc')
  const [orderBy, setOrderBy] = React.useState<keyof Data>('NomeCargo')
  const [selected, setSelected] = React.useState<readonly string[]>([])
  const [page, setPage] = React.useState(0)
  const [rowsPerPage, setRowsPerPage] = React.useState(10)
  const [total, setTotal] = React.useState(0)
  const [rows, setRows] = React.useState<Data[]>([])
  const [abrirModalAddCargo, setAbrirModalAddCargo] = React.useState(false)
  const [abrirModalAprovadores, setAbrirModalAprovadores] = React.useState<number | null>(null)  
  const {auth} = useAuth()

  let [modelos, setModelos] = React.useState<any[]>([])
  let [cargos, setCargos] = React.useState<any[]>([])
  let [areas, setAreas] = React.useState<any[]>([])
  let [niveisHierarquicos, setNiveisHierarquicos] = React.useState<any[]>([])

  const [idModelo, setModelo] = React.useState<any>(null)
  const [idCargo, setCargo] = React.useState<any>(null)
  const [idArea, setArea] = React.useState<any>(null)
  const [idNivelHierarquico, setNivelHierarquico] = React.useState<any>(null)
  const alert = useAlert()

  const resquestTable = (
    pageRq: number,
    orderByRq: string,
    orderRq: string,
    rowsPerPageRq: number,
    Filtros: any = null
  ) => {
    httpClient
      .request({
        method: RequestMethod.POST,
        endpoint: '/DescricaoDeCargo/Descrever/ListarAguardandoRevisar',
        data: {
          Order: orderRq,
          OrderBy: orderByRq,
          Page: pageRq,
          RowsPerPage: rowsPerPageRq,
          Filters: Filtros,
        },
      })
      .then((result) => {
        const newRows = result.payload.data.map((data: Data) => {
          return createData(
            data.Id,
            data.IdCargo,
            data.NomeCargo,
            data.NomeArea,
            data.NomeNivelHierarquico,
            data.NomeModelo,
            data.StatusAprovacao,
            data.IdModelo
          )
        })
        setRows([...newRows])
        setTotal(result.payload.total)
      })
  }

  React.useEffect(() => {
    resquestTable(page, orderBy, order, rowsPerPage)
    httpClient
      .request({
        method: RequestMethod.GET,
        endpoint: `/DescricaoDeCargo/Descrever/ObterDadosSelect`,
      })
      .then((response) => {
        if (response.success && response.payload) {
          const dados = response.payload
          if (dados.Modelos && dados.Modelos.length > 0) setModelos(dados.Modelos)
          if (dados.Cargos && dados.Cargos.length > 0) setCargos(dados.Cargos)
          if (dados.Areas && dados.Areas.length > 0) setAreas(dados.Areas)
          if (dados.NiveisHierarquicos && dados.NiveisHierarquicos.length > 0)
            setNiveisHierarquicos(dados.NiveisHierarquicos)
        }
      })
  }, [])

  const onClickBuscar = () => {
    const filtros: Filtros = {
      IdArea: idArea,
      IdCargo: idCargo,
      IdModelo: idModelo,
      IdNivelHierarquico: idNivelHierarquico,
    }

    resquestTable(page, orderBy, order, rowsPerPage, filtros)
  }

  const handleRequestSort = (event: React.MouseEvent<unknown>, property: keyof Data) => {
    const isAsc = orderBy === property && order === 'asc'
    setOrder(isAsc ? 'desc' : 'asc')
    setOrderBy(property)
    resquestTable(page, property, isAsc ? 'desc' : 'asc', rowsPerPage)
  }

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelected = rows.map((n) => n.NomeCargo)
      setSelected(newSelected)
      return
    }
    setSelected([])
  }

  const handleChangePage = (event: unknown, newPage: number) => {
    resquestTable(newPage, orderBy, order, rowsPerPage)
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    resquestTable(page, orderBy, order, parseInt(event.target.value, 10))
    setPage(0)
  }

  const DeletarCargoADescrever = (idCargo: number) => {
    alert.createDialog({
      html: `Realmente deseja excluir esta descrição?`,
      confirmAction: async () => {
        const result = await httpClient.request({
          method: RequestMethod.DELETE,
          endpoint: '/Gerenciamento/Cargo/Deletar',
          data: idCargo,
        })
        if (result.success) {
          atualizarDados()
        }
      },
    })
  }

  const atualizarDados = () => {
    resquestTable(page, orderBy, order, rowsPerPage)
  }

  const setModalAprovadoresState = (state: boolean) => {
    setAbrirModalAprovadores(null)
  }

  const emptyRows = rows.length

  return (
    <div className='card mb-5 mb-xl-10'>
      <div className='card-header'>
        <div className='card-title m-0'>
          <h3 className='fw-bolder m-0'>Descrições a Revisar</h3>
        </div>
        <div className='card-toolbar'>
        <div className='card-toolbar'>
          <button
              type='button'
              className='btn btn-light-primary'
              onClick={() => { 
                atualizarDados()
              }}
            >
              <i className="fs-2 fas fa-sync-alt"></i>
              Atualizar
            </button>  
          </div>
          {(auth?.roles.find((x) => x === 'Master') !== undefined ||
            auth?.roles.find((x) => x === 'Administrador') !== undefined ||
            auth?.permissoes.find((x) => x === '522125E0C951A53EB4A52BC370AD86EC') !== undefined) && (
            <button
              type='button'
              className='btn btn-light-primary ms-5'
              onClick={() => setAbrirModalAddCargo(true)}
            >
              <KTSVG path="/media/icons/duotune/arrows/arr009.svg" className="svg-icon-muted svg-icon-1" />
              Adicionar Cargo
            </button>
          )}          
        </div>
      </div>
      <div className='card-body p-9'>
        <div className='d-flex justify-content-end mb-2'>
          <>
            {/* begin::Filter Button */}
            <button
              type='button'
              className='btn btn-light-primary'
              data-kt-menu-trigger='click'
              data-kt-menu-placement='bottom-end'
            >
              <i className='fs-2 fas fa-arrow-down-wide-short'></i>
              Filtros
            </button>
            {/* end::Filter Button */}
            {/* begin::SubMenu */}
            <div className='menu menu-sub menu-sub-dropdown w-600px w-md-650px' data-kt-menu='true'>
              {/* begin::Header */}
              <div className='px-7 py-5'>
                <div className='fs-5 text-dark fw-bolder'>Opções</div>
              </div>
              {/* end::Header */}

              {/* begin::Separator */}
              <div className='separator border-gray-200'></div>
              {/* end::Separator */}

              {/* begin::Content */}
              <div className='px-7 py-5' data-kt-user-table-filter='form'>
                {/* begin::Input group */}
                <div className='row'>
                  <div className='mb-10 col-6'>
                    <label className='form-label fs-6 fw-bold'>Cargo:</label>
                    <select
                      className='form-select form-select-solid fw-bolder'
                      data-kt-select2='true'
                      data-placeholder='Select option'
                      data-allow-clear='true'
                      data-kt-user-table-filter='role'
                      data-hide-search='true'
                      onChange={(e) => setCargo(e.target.value)}
                    >
                      <option value=''>Selecione...</option>
                      {cargos && cargos.length > 0
                        ? cargos.map((data: any, index: any) => {
                            return (
                              <option key={`IdCargo-option-${index}`} value={data.Id}>
                                {data.Nome}
                              </option>
                            )
                          })
                        : ''}
                    </select>
                  </div>
                  {/* end::Input group */}

                  {/* begin::Input group */}
                  <div className='mb-10 col-6'>
                    <label className='form-label fs-6 fw-bold'>Áreas:</label>
                    <select
                      className='form-select form-select-solid fw-bolder'
                      data-kt-select2='true'
                      data-placeholder='Select option'
                      data-allow-clear='true'
                      data-kt-user-table-filter='role'
                      data-hide-search='true'
                      onChange={(e) => setArea(e.target.value)}
                    >
                      <option value=''>Selecione...</option>
                      {areas && areas.length > 0
                        ? areas.map((data: any, index: any) => {
                            return (
                              <option key={`IdCargo-option-${index}`} value={data.Id}>
                                {data.Nome}
                              </option>
                            )
                          })
                        : ''}
                    </select>
                  </div>
                  {/* end::Input group */}
                  {/* begin::Input group */}
                  <div className='mb-10 col-6'>
                    <label className='form-label fs-6 fw-bold'>Níveis Hierárquicos:</label>
                    <select
                      className='form-select form-select-solid fw-bolder'
                      data-kt-select2='true'
                      data-placeholder='Select option'
                      data-allow-clear='true'
                      data-kt-user-table-filter='role'
                      data-hide-search='true'
                      onChange={(e) => setNivelHierarquico(e.target.value)}
                    >
                      <option value=''>Selecione...</option>
                      {niveisHierarquicos && niveisHierarquicos.length > 0
                        ? niveisHierarquicos.map((data: any, index: any) => {
                            return (
                              <option key={`IdCargo-option-${index}`} value={data.Id}>
                                {data.Nome}
                              </option>
                            )
                          })
                        : ''}
                    </select>
                  </div>
                  {/* end::Input group */}

                  {/* begin::Input group */}
                  <div className='mb-10 col-6'>
                    <label className='form-label fs-6 fw-bold'>Modelo:</label>
                    <select
                      className='form-select form-select-solid fw-bolder'
                      data-kt-select2='true'
                      data-placeholder='Select option'
                      data-allow-clear='true'
                      data-kt-user-table-filter='role'
                      data-hide-search='true'
                      onChange={(e) => setModelo(e.target.value)}
                    >
                      <option value=''>Selecione...</option>
                      {modelos && modelos.length > 0
                        ? modelos.map((data: any, index: any) => {
                            return (
                              <option key={`IdModelo-option-${index}`} value={data.Id}>
                                {data.Nome}
                              </option>
                            )
                          })
                        : ''}
                    </select>
                  </div>
                  {/* end::Input group */}
                </div>

                {/* begin::Actions */}
                <div className='d-flex justify-content-end'>
                  <button
                    type='button'
                    className='btn btn-light btn-active-light-primary fw-bold me-5 px-6'
                    data-kt-menu-dismiss='true'
                    data-kt-user-table-filter='reset'
                  >
                    Cancelar
                  </button>
                  <button
                    type='button'
                    className='btn btn-primary fw-bold px-6'
                    data-kt-menu-dismiss='true'
                    data-kt-user-table-filter='filter'
                    onClick={() => onClickBuscar()}
                  >
                    Buscar
                  </button>
                </div>
                {/* end::Actions */}
              </div>
              {/* end::Content */}
            </div>
            {/* end::SubMenu */}
          </>
          <ModalAddCargo
            descricaoTipo='Adicionar novo cargo'
            abrirModal={abrirModalAddCargo}
            setAbrirModal={setAbrirModalAddCargo}
            atualizarDados={atualizarDados}
          />

          {abrirModalAprovadores && (
            <ModalAprovadores
              AbrirModal={abrirModalAprovadores != null}
              SetAbrirModal={setModalAprovadoresState}
              IdDescricao={abrirModalAprovadores}
              atualizarDados={atualizarDados}
            />
          )}
        </div>
        <form noValidate className='form'>
          <div>
            <Box sx={{width: '100%'}}>
              <Paper className='table-border ' sx={{width: '100%', mb: 2}}>
                <TableContainer>
                  <Table className='table-header' sx={{minWidth: 750}} aria-labelledby='tableTitle'>
                    <EnhancedTableHead
                      numSelected={selected.length}
                      order={order}
                      orderBy={orderBy}
                      onSelectAllClick={handleSelectAllClick}
                      onRequestSort={handleRequestSort}
                    />
                    <TableBody>
                      {rows.map((row, index) => {
                        return (
                          <TableRow
                            className='table-row'
                            hover
                            tabIndex={-1}
                            key={index}
                            sx={{cursor: 'pointer'}}
                          >
                            <TableCell className='table-cell' align='left'>
                              {row.NomeCargo}
                            </TableCell>
                            <TableCell className='table-cell' align='left'>
                              {row.NomeArea}
                            </TableCell>
                            <TableCell className='table-cell' align='left'>
                              {row.NomeNivelHierarquico}
                            </TableCell>
                            <TableCell className='table-cell' align='left'>
                              {row.NomeModelo}
                            </TableCell>
                            <TableCell className='table-cell' align='left'>
                              { row.StatusAprovacao === EnumDescricaoStatusAprovacao.ARevisar ? "A Revisar" : EnumDescricaoStatusAprovacao[row.StatusAprovacao]}
                            </TableCell>
                            <TableCell className='table-cell' align='left'>
                              <div className='d-flex justify-content-center w-50 align-items-center'>
                                <Link
                                  to={`/descricao/visualizar`}
                                  state={{
                                    idDescricao: row.Id,
                                  }}
                                >
                                  <span
                                    title='Visualizar'
                                    className='btn btn-icon mx-1 btn-active-color-warning w-25px h-25px'
                                  >
                                    <i className='fas fa-eye fs-2'></i>
                                  </span>
                                </Link>
                                {(auth?.servico.tipoPlano === EnumServicoTipoPlano.Try ||
                                  auth?.roles.find((x) => x === 'Master') !== undefined ||
                                  auth?.roles.find((x) => x === 'Administrador') !== undefined ||
                                  auth?.permissoes.find(
                                    (x) => x === '3224203320CCFFD0047BB8EB6515D27B'
                                  ) !== undefined) && auth.servico.clienteConsultoriaDescricao === null && (
                                  <>
                                    <Link
                                      to='/descricao/revisar'
                                      state={{
                                        statusDescricao: EnumDescricaoStatusAprovacao.ARevisar,
                                        idCargoState: row.IdCargo,
                                        idModelo: row.IdModelo
                                      }}
                                    >
                                      <span
                                        title='Revisar'
                                        className='btn btn-icon mx-1 btn-active-color-primary w-25px h-25px'
                                      >
                                        <i className={`far fa-file-alt fs-2`}></i>
                                      </span>
                                    </Link>
                                  </>
                                )}

                                {(auth?.servico.tipoPlano === EnumServicoTipoPlano.Buy && (
                                  auth?.roles.find((x) => x === 'Master') !== undefined ||
                                  auth?.roles.find((x) => x === 'Administrador') !== undefined ||
                                  auth?.permissoes.find(
                                    (x) => x === '854FB94286FD7EF473F9F5F5D74C7DCC'
                                  ) !== undefined) && auth.servico.moduloDescricaoConfig.usaAprovacao) && auth.servico.clienteConsultoriaDescricao === null && (
                                  <>
                                    <button
                                        type='button'
                                        title='Vincular Aprovadores'
                                        className='btn btn-icon mx-1 btn-active-color-primary w-25px h-25px'
                                        onClick={() => {
                                            setAbrirModalAprovadores(row.Id)
                                          }
                                        }
                                      >
                                        <i className={`fas fa-link fs-2`}></i>
                                      </button>
                                  </>
                                )}
                                {(auth?.servico.tipoPlano === EnumServicoTipoPlano.Buy && (
                                  auth?.roles.find((x) => x === 'Master') !== undefined ||
                                  auth?.roles.find((x) => x === 'Administrador') !== undefined ||
                                  auth?.permissoes.find(
                                    (x) => x === '772C9D41C43A95B50B6685495B2FA103'
                                  ) !== undefined) && auth.servico.moduloDescricaoConfig.usaAprovacao) && auth.servico.clienteConsultoriaDescricao === null && (
                                  <>
                                    <button
                                      type='button'
                                      title='Enviar para aprovação'
                                      className='btn btn-icon mx-1 btn-active-color-primary w-25px h-25px'
                                      onClick={() => setAbrirModalAprovadores(row.Id)}
                                    >
                                      <i className={`fas fa-paper-plane fs-2`}></i>
                                    </button>
                                  </>
                                )}
                                {(auth?.servico.tipoPlano === EnumServicoTipoPlano.Try ||
                                  auth?.roles.find((x) => x === 'Master') !== undefined ||
                                  auth?.roles.find((x) => x === 'Administrador') !== undefined ||
                                  auth?.permissoes.find(
                                    (x) => x === 'E09BCF9F9A2BF52388D84C899142DEC8'
                                  ) !== undefined) && auth.servico.clienteConsultoriaDescricao === null && (
                                  <>
                                    <button
                                      type='button'
                                      title='Deletar'
                                      className='btn btn-icon mx-1 btn-active-color-danger w-25px h-25px'
                                      onClick={() => DeletarCargoADescrever(row.Id)}
                                    >
                                      <i className={`fas fa-trash-alt fs-2`}></i>
                                    </button>
                                  </>
                                )}
                              </div>
                            </TableCell>
                          </TableRow>
                        )
                      })}
                      {emptyRows === 0 && (
                        <TableRow>
                          <TableCell className='table-cell text-center' colSpan={6}>
                            Nenhum registro encontrado
                          </TableCell>
                        </TableRow>
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
                <TablePagination
                  className='table-footer'
                  labelRowsPerPage='linhas por página'
                  rowsPerPageOptions={[10, 25]}
                  component='div'
                  count={total}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              </Paper>
            </Box>
          </div>
        </form>
      </div>
      <div className='card-footer d-flex justify-content-end py-4'>
        <Link className='btn btn-secondary' to={`/descricao/dashboard`}>
          Voltar
        </Link>
      </div>
    </div>
  )
}

export {Revisar}
