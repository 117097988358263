import { FormikProps } from 'formik'
import * as Yup from 'yup'

export interface ITopicoValorModel {
  Id: number
  IdDimensao: number
  Texto: string
}

export interface ITopicoModel {
  Id:number             
  IdTema:number             
  Ordem:number             
  IdTopicoValorAtual?:number            
  TopicoValorAtual:ITopicoValorModel   
  TopicoValores:ITopicoValorModel[] 
}

export interface IPesquisaValorModel {
  Id: number
  IdEntidade: number
  Texto: string
}

export interface IPesquisaRespostaPerguntaModel {
  Id:number             
  IdEntidade:number             
  TipoResposta:number             
  Ordem:number       
  Nome:string           
  IdPesquisaValorAtual?:number            
  PesquisaValorAtual:IPesquisaValorModel   
  QuestaoA?: string
  QuestaoB?: string
  PesquisaValores:IPesquisaValorModel[]
  Perguntas:IPesquisaRespostaPerguntaModel[]
  DimensaoDescricoes: IPesquisaDimensaoDescricaoModel[] 
}

export interface IPesquisaDimensaoDescricaoModel {
  Id:number             
  IdEntidade:number             
  TipoResposta:number             
  Ordem:number       
  Nome:string           
  IdPesquisaValorAtual?:number            
  PesquisaValorAtual:IPesquisaValorModel
  PesquisaValores:IPesquisaValorModel[]
  Perguntas:IPesquisaRespostaPerguntaModel[]
  DimensaoDescricoes: IPesquisaDimensaoDescricaoModel[] 
}

export interface IPesquisaRespostaModel {
  Id:number             
  IdEntidade:number 
  TipoResposta:number              
  Ordem:number
  Nome:string             
  IdPesquisaValorAtual?:number            
  PesquisaValorAtual:IPesquisaValorModel   
  PesquisaValores:IPesquisaValorModel[] 
  Perguntas:IPesquisaRespostaPerguntaModel[]
  DimensaoDescricoes: IPesquisaDimensaoDescricaoModel[] 
}

export interface IDimensaoValorModel {
  Id: number
  IdDimensao: number
  Texto: string
}

export interface IDimensaoPerguntaValorModel {
  Id: number
  IdDimensaoPergunta: number
  Texto: string
}

export interface IDimensaoPerguntaModel {
  Id:number                      
  IdDimensao:number                      
  Ordem:number                      
  IdDimensaoPerguntaValorAtual?:number                    
  DimensaoPerguntaValorAtual:IDimensaoPerguntaValorModel  
  DimensaoPerguntaValores:IDimensaoPerguntaValorModel[]
}

export interface IDimensaoModel {  
  Id: number              
  IdTema: number              
  Ordem: number              
  Nome: string
  DimensaoDescricao: IDimensaoDescricaoModel[]
  DimensaoPergunta: IDimensaoPerguntaModel[]
}

export interface IDimensaoDescricaoModel {  
  Id: number              
  IdDimensao: number              
  Ordem: number      
  IdDimensaoValorAtual?: number             
  DimensaoValorAtual: IDimensaoValorModel  
  DimensaoValores: IDimensaoValorModel[]
}

export interface ITemaModel {  
  Id: number        
  IdEmpresa: number        
  Nome: string     
  UtilizaQuestao: boolean
  ExistePesquisa: boolean
  Dimensao: IPesquisaRespostaModel[]
  Topico: IPesquisaRespostaModel[]   
}

export interface IWizardModel {  
  empresaId?: number
  empresaRazaoSocial: string
  empresaSegmento?: number
  empresaNomeSegmento: string
  QtdFuncionarios: number
  PorteEmpresa: number
  empresaLogo: any
  tema: ITemaModel[]
  temaBuscar: ITemaModel
  topicosBuscar: number
  temaBuscarDimensao: ITemaModel,
  dimensoesBuscar: number
  numeroPerguntas: number
  dimensaoSelecionada?: number
}

const defaultTema: ITemaModel = {
  Id: 0,        
  IdEmpresa: 0,        
  Nome: '',
  UtilizaQuestao: false,
  ExistePesquisa: false,
  Dimensao: [],
  Topico: []
}

export interface StepProps {
  formik: FormikProps<IWizardModel>,
}

const defaultValidationSchemas = [
  Yup.object({
    empresaRazaoSocial: Yup.string().required('O nome é obrigatório'),    
    empresaSegmento: Yup.string().required('O segmento de mercado é obrigatório'),
    empresaNomeSegmento: Yup.string()
    .when('empresaSegmento', {
      is: '2',
      then: Yup.string().nullable()
      .required('O nome do segmento é obrigatório')
      .matches(/^[a-zA-Z\sáÁàÀâÂãÃéÉèÈêÊíÍìÌîÎóÓòÒôÔõÕúÚùÙûÛüÜñÑçÇ]*$/, 'O nome do segmento não pode conter números, caracteres especiais ou espaços consecutivos')
      .matches(/^(?!(.*\s){3})/, 'O nome do segmento contém muitos espaços consecutivos'),
      otherwise: Yup.string().nullable(),
    }),
    QtdFuncionarios: Yup.number().min(0, "A quantidade não pode ser negativa."), 
  }),
  Yup.object({
    topicosBuscar: Yup.number().min(1, "O número de tópicos deve ser maior que zero.").required('O número de tópicos é obrigatório'),    
    temaBuscar: Yup.object().shape({
      Nome: Yup.string().required('O tema é obrigatório'),
      Topico: Yup.array().min(1, 'É obrigatório ter no mínimo 1 tópico para o tema'),
    })
  }),
  Yup.object({
    numeroDimensoes: Yup.number().min(1, "O número de dimensões deve ser maior que zero.").required('O número de dimensões é obrigatório'),    
    numeroPerguntas: Yup.number().min(1, "O número de perguntas deve ser maior que zero.").required('O número de perguntas é obrigatório'),    
    tema: Yup.object().shape({
      Dimensao: Yup.array().min(1, 'É obrigatório ter no mínimo 1 dimensão para o tema'),
    })
  }),
]

export {defaultValidationSchemas, defaultTema}

const mockValuesTesteJest:IWizardModel = {
  empresaId: 1,
  empresaRazaoSocial: 'Teste Empresa',
  empresaSegmento: 1,
  empresaNomeSegmento: '',
  QtdFuncionarios: 5,
  PorteEmpresa: 1,
  empresaLogo: undefined,
  tema: [
      {
          Id: 1,        
          IdEmpresa: 1,        
          Nome: 'Tema Teste',
          UtilizaQuestao: true,
          ExistePesquisa: false,
          Dimensao: [
              {
                  Id: 1,
                  IdEntidade: 1,         
                  TipoResposta: 1,            
                  Ordem: 0,       
                  Nome:"Teste Pergunta",           
                  IdPesquisaValorAtual: 1,            
                  PesquisaValorAtual:{
                      Id: 1,
                      IdEntidade: 1,
                      Texto: 'Texto Teste Valor Atual'
                  }, 
                  PesquisaValores: [],
                  Perguntas: [],
                  DimensaoDescricoes: [
                      {
                          Id: 1,
                          IdEntidade: 1,         
                          TipoResposta: 1,            
                          Ordem: 0,       
                          Nome:"Teste Pergunta",           
                          IdPesquisaValorAtual: 1,            
                          PesquisaValorAtual:{
                              Id: 1,
                              IdEntidade: 1,
                              Texto: 'Texto Teste Valor Atual'
                          }, 
                          PesquisaValores: [
                              {
                                  Id: 1,
                                  IdEntidade: 1,
                                  Texto: 'Texto Teste Valor Atual'
                              }, 
                          ],
                          Perguntas: [],
                          DimensaoDescricoes: [] 
                      },
                  ] 
              },
          ],
          Topico: [
              {
                  Id: 1,             
                  IdEntidade: 1, 
                  TipoResposta: 1,              
                  Ordem: 0,
                  Nome: 'Teste Tópico',             
                  IdPesquisaValorAtual: 1,       
                  PesquisaValorAtual: {
                      Id: 1,
                      IdEntidade: 1,
                      Texto: 'Texto Teste Valor Atual'
                  },   
                  PesquisaValores: [
                      {
                          Id: 1,
                          IdEntidade: 1,
                          Texto: 'Texto Teste Valor Atual'
                      },
                  ], 
                  Perguntas: [
                      {
                          Id: 1,
                          IdEntidade: 1,         
                          TipoResposta: 1,            
                          Ordem: 0,       
                          Nome:"Teste Pergunta",           
                          IdPesquisaValorAtual: 1,            
                          PesquisaValorAtual:{
                              Id: 1,
                              IdEntidade: 1,
                              Texto: 'Texto Teste Valor Atual'
                          },   
                          QuestaoA: "Teste 1",
                          QuestaoB: "Teste 2",
                          PesquisaValores:[
                              {
                                  Id: 1,
                                  IdEntidade: 1,
                                  Texto: 'Texto Teste Valor Atual'
                              },
                          ],
                          Perguntas:[],
                          DimensaoDescricoes: [
                              {
                                  Id: 1,
                                  IdEntidade: 1,         
                                  TipoResposta: 1,            
                                  Ordem: 0,       
                                  Nome:"Teste Pergunta",           
                                  IdPesquisaValorAtual: 1,            
                                  PesquisaValorAtual:{
                                      Id: 1,
                                      IdEntidade: 1,
                                      Texto: 'Texto Teste Valor Atual'
                                  }, 
                                  PesquisaValores: [
                                      {
                                          Id: 1,
                                          IdEntidade: 1,
                                          Texto: 'Texto Teste Valor Atual'
                                      }, 
                                  ],
                                  Perguntas: [],
                                  DimensaoDescricoes: [] 
                              },
                          ], 
                      },
                  ],
                  DimensaoDescricoes: [
                      {
                          Id: 1,
                          IdEntidade: 1,         
                          TipoResposta: 1,            
                          Ordem: 0,       
                          Nome:"Teste Pergunta",           
                          IdPesquisaValorAtual: 1,            
                          PesquisaValorAtual:{
                              Id: 1,
                              IdEntidade: 1,
                              Texto: 'Texto Teste Valor Atual'
                          }, 
                          PesquisaValores: [
                              {
                                  Id: 1,
                                  IdEntidade: 1,
                                  Texto: 'Texto Teste Valor Atual'
                              }, 
                          ],
                          Perguntas: [],
                          DimensaoDescricoes: [] 
                      },
                  ], 
              }
          ]
      },
  ],
  temaBuscar: {
      Id: 1,        
      IdEmpresa: 1,        
      Nome: 'Tema Teste',
      UtilizaQuestao: true,
      ExistePesquisa: false,
      Dimensao: [
          {
              Id: 1,
              IdEntidade: 1,         
              TipoResposta: 1,            
              Ordem: 0,       
              Nome:"Teste Pergunta",           
              IdPesquisaValorAtual: 1,            
              PesquisaValorAtual:{
                  Id: 1,
                  IdEntidade: 1,
                  Texto: 'Texto Teste Valor Atual'
              }, 
              PesquisaValores: [],
              Perguntas: [],
              DimensaoDescricoes: [
                  {
                      Id: 1,
                      IdEntidade: 1,         
                      TipoResposta: 1,            
                      Ordem: 0,       
                      Nome:"Teste Pergunta",           
                      IdPesquisaValorAtual: 1,            
                      PesquisaValorAtual:{
                          Id: 1,
                          IdEntidade: 1,
                          Texto: 'Texto Teste Valor Atual'
                      }, 
                      PesquisaValores: [
                          {
                              Id: 1,
                              IdEntidade: 1,
                              Texto: 'Texto Teste Valor Atual'
                          }, 
                      ],
                      Perguntas: [],
                      DimensaoDescricoes: [] 
                  },
              ] 
          },
      ],
      Topico: [
          {
              Id: 1,             
              IdEntidade: 1, 
              TipoResposta: 1,              
              Ordem: 0,
              Nome: 'Teste Tópico',             
              IdPesquisaValorAtual: 1,       
              PesquisaValorAtual: {
                  Id: 1,
                  IdEntidade: 1,
                  Texto: 'Texto Teste Valor Atual'
              },   
              PesquisaValores: [
                  {
                      Id: 1,
                      IdEntidade: 1,
                      Texto: 'Texto Teste Valor Atual'
                  },
              ], 
              Perguntas: [
                  {
                      Id: 1,
                      IdEntidade: 1,         
                      TipoResposta: 1,            
                      Ordem: 0,       
                      Nome:"Teste Pergunta",           
                      IdPesquisaValorAtual: 1,            
                      PesquisaValorAtual:{
                          Id: 1,
                          IdEntidade: 1,
                          Texto: 'Texto Teste Valor Atual'
                      },   
                      QuestaoA: "Teste 1",
                      QuestaoB: "Teste 2",
                      PesquisaValores:[
                          {
                              Id: 1,
                              IdEntidade: 1,
                              Texto: 'Texto Teste Valor Atual'
                          },
                      ],
                      Perguntas:[],
                      DimensaoDescricoes: [
                          {
                              Id: 1,
                              IdEntidade: 1,         
                              TipoResposta: 1,            
                              Ordem: 0,       
                              Nome:"Teste Pergunta",           
                              IdPesquisaValorAtual: 1,            
                              PesquisaValorAtual:{
                                  Id: 1,
                                  IdEntidade: 1,
                                  Texto: 'Texto Teste Valor Atual'
                              }, 
                              PesquisaValores: [
                                  {
                                      Id: 1,
                                      IdEntidade: 1,
                                      Texto: 'Texto Teste Valor Atual'
                                  }, 
                              ],
                              Perguntas: [],
                              DimensaoDescricoes: [] 
                          },
                      ], 
                  },
              ],
              DimensaoDescricoes: [
                  {
                      Id: 1,
                      IdEntidade: 1,         
                      TipoResposta: 1,            
                      Ordem: 0,       
                      Nome:"Teste Pergunta",           
                      IdPesquisaValorAtual: 1,            
                      PesquisaValorAtual:{
                          Id: 1,
                          IdEntidade: 1,
                          Texto: 'Texto Teste Valor Atual'
                      }, 
                      PesquisaValores: [
                          {
                              Id: 1,
                              IdEntidade: 1,
                              Texto: 'Texto Teste Valor Atual'
                          }, 
                      ],
                      Perguntas: [],
                      DimensaoDescricoes: [] 
                  },
              ], 
          }
      ]
  },
  topicosBuscar: 1,
  temaBuscarDimensao: {
      Id: 1,        
      IdEmpresa: 1,        
      Nome: 'Tema Teste',
      UtilizaQuestao: true,
      ExistePesquisa: false,
      Dimensao: [
          {
              Id: 1,
              IdEntidade: 1,         
              TipoResposta: 1,            
              Ordem: 0,       
              Nome:"Teste Pergunta",           
              IdPesquisaValorAtual: 1,            
              PesquisaValorAtual:{
                  Id: 1,
                  IdEntidade: 1,
                  Texto: 'Texto Teste Valor Atual'
              }, 
              PesquisaValores: [],
              Perguntas: [],
              DimensaoDescricoes: [
                  {
                      Id: 1,
                      IdEntidade: 1,         
                      TipoResposta: 1,            
                      Ordem: 0,       
                      Nome:"Teste Pergunta",           
                      IdPesquisaValorAtual: 1,            
                      PesquisaValorAtual:{
                          Id: 1,
                          IdEntidade: 1,
                          Texto: 'Texto Teste Valor Atual'
                      }, 
                      PesquisaValores: [
                          {
                              Id: 1,
                              IdEntidade: 1,
                              Texto: 'Texto Teste Valor Atual'
                          }, 
                      ],
                      Perguntas: [],
                      DimensaoDescricoes: [] 
                  },
              ] 
          },
      ],
      Topico: [
          {
              Id: 1,             
              IdEntidade: 1, 
              TipoResposta: 1,              
              Ordem: 0,
              Nome: 'Teste Tópico',             
              IdPesquisaValorAtual: 1,       
              PesquisaValorAtual: {
                  Id: 1,
                  IdEntidade: 1,
                  Texto: 'Texto Teste Valor Atual'
              },   
              PesquisaValores: [
                  {
                      Id: 1,
                      IdEntidade: 1,
                      Texto: 'Texto Teste Valor Atual'
                  },
              ], 
              Perguntas: [
                  {
                      Id: 1,
                      IdEntidade: 1,         
                      TipoResposta: 1,            
                      Ordem: 0,       
                      Nome:"Teste Pergunta",           
                      IdPesquisaValorAtual: 1,            
                      PesquisaValorAtual:{
                          Id: 1,
                          IdEntidade: 1,
                          Texto: 'Texto Teste Valor Atual'
                      },   
                      QuestaoA: "Teste 1",
                      QuestaoB: "Teste 2",
                      PesquisaValores:[
                          {
                              Id: 1,
                              IdEntidade: 1,
                              Texto: 'Texto Teste Valor Atual'
                          },
                      ],
                      Perguntas:[],
                      DimensaoDescricoes: [
                          {
                              Id: 1,
                              IdEntidade: 1,         
                              TipoResposta: 1,            
                              Ordem: 0,       
                              Nome:"Teste Pergunta",           
                              IdPesquisaValorAtual: 1,            
                              PesquisaValorAtual:{
                                  Id: 1,
                                  IdEntidade: 1,
                                  Texto: 'Texto Teste Valor Atual'
                              }, 
                              PesquisaValores: [
                                  {
                                      Id: 1,
                                      IdEntidade: 1,
                                      Texto: 'Texto Teste Valor Atual'
                                  }, 
                              ],
                              Perguntas: [],
                              DimensaoDescricoes: [] 
                          },
                      ], 
                  },
              ],
              DimensaoDescricoes: [
                  {
                      Id: 1,
                      IdEntidade: 1,         
                      TipoResposta: 1,            
                      Ordem: 0,       
                      Nome:"Teste Pergunta",           
                      IdPesquisaValorAtual: 1,            
                      PesquisaValorAtual:{
                          Id: 1,
                          IdEntidade: 1,
                          Texto: 'Texto Teste Valor Atual'
                      }, 
                      PesquisaValores: [
                          {
                              Id: 1,
                              IdEntidade: 1,
                              Texto: 'Texto Teste Valor Atual'
                          }, 
                      ],
                      Perguntas: [],
                      DimensaoDescricoes: [] 
                  },
              ], 
          }
      ]
  },
  dimensoesBuscar: 1,
  numeroPerguntas: 1,
}

export {mockValuesTesteJest}