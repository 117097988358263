/* eslint-disable react-hooks/exhaustive-deps */
import * as React from 'react'
import Box from '@mui/material/Box'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TablePagination from '@mui/material/TablePagination'
import TableRow from '@mui/material/TableRow'
import TableSortLabel from '@mui/material/TableSortLabel'
import Paper from '@mui/material/Paper'
import {visuallyHidden} from '@mui/utils'
import {useNavigate} from 'react-router-dom'
import {useHttpClient} from '../../../../modules/services/Bundle'
import {RequestMethod} from '../../../../modules/services/core/_enums'
import Popover from '@mui/material/Popover'
import Button from '@mui/material/Button'
import {IconButton} from '@mui/material'
import {TipoAsString} from '../helpers/PromptHelper'

interface Data {
  Id: number
  NomeTipo: string
  NomeModulo: string
  NomeTipoCampo: string
}

interface Column {
  id: keyof Data
  label: string
  minWidth?: number
  align?: 'center'
  format?: (value: number) => string
}

interface Filtros {
  Tipo?: number
  IdModulo?: number
  IdTipoCampo?: number
}

const columns: readonly Column[] = [
  {
    id: 'NomeTipo',
    label: 'TIPO',
  },
  {
    id: 'NomeModulo',
    label: 'MÓDULO',
  },
  {
    id: 'NomeTipoCampo',
    label: 'TIPO CAMPO',
  },  
]

function createData(Id: number, NomeTipo: string, NomeModulo: string, NomeTipoCampo: string): Data {
  return {Id, NomeTipo, NomeModulo, NomeTipoCampo}
}

type Order = 'asc' | 'desc'

interface EnhancedTableProps {
  onRequestSort: (event: React.MouseEvent<unknown>, property: keyof Data) => void
  order: Order
  orderBy: string
}

const TablePrompts: React.FC = () => {
  const httpClient = useHttpClient()
  const [order, setOrder] = React.useState<Order>('asc')
  const [orderBy, setOrderBy] = React.useState<keyof Data>('NomeTipo')
  const [page, setPage] = React.useState(0)
  const [rowsPerPage, setRowsPerPage] = React.useState(10)
  const [total, setTotal] = React.useState(0)
  const [rows, setRows] = React.useState<Data[]>([])
  const navigate = useNavigate()
  const [tiposPrompt, setTiposPrompt] = React.useState<number[]>([])
  const [modulos, setModulos] = React.useState<any[]>([] as any[])
  const [tiposCampo, setTiposCampos] = React.useState<any[]>([] as any[])
  const [filtros, setFiltros] = React.useState<Filtros>({
    Tipo: undefined,
    IdModulo: undefined,
    IdTipoCampo: undefined,
  })

  function EnhancedTableHead(props: EnhancedTableProps) {
    const {order, orderBy, onRequestSort} = props
    const createSortHandler = (property: keyof Data) => (event: React.MouseEvent<unknown>) => {
      onRequestSort(event, property)
    }

    return (
      <TableHead>
        <TableRow className='table-header'>
          {columns.map((columns) => (
            <TableCell
              className='table-cell'
              key={columns.id}
              align={columns.align ? 'right' : 'left'}
            >
              <TableSortLabel
                className='fw-bolder'
                active={orderBy === columns.id}
                direction={orderBy === columns.id ? order : 'asc'}
                onClick={createSortHandler(columns.id)}
              >
                {columns.label}
                {orderBy === columns.id ? (
                  <Box component='span' sx={visuallyHidden}>
                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                  </Box>
                ) : null}
              </TableSortLabel>
            </TableCell>
          ))}
          <TableCell className='fw-bolder table-cell' align='center'>
            AÇÕES
          </TableCell>
        </TableRow>
      </TableHead>
    )
  }

  const requestTable = (
    pageRq: number,
    orderByRq: string,
    orderRq: string,
    rowsPerPageRq: number,
    Filtros: Filtros
  ) => {
    httpClient.request({
      method: RequestMethod.POST,
      endpoint: '/Administracao/Prompt/Listar',
      data: {
        Order: orderRq,
        OrderBy: orderByRq,
        Page: pageRq,
        RowsPerPage: rowsPerPageRq,
        Filters: Filtros,
      },
    }).then((result) => {
      const newRows = result.payload.data.map((data: Data) => {
        return createData(data.Id, data.NomeTipo, data.NomeModulo, data.NomeTipoCampo)
      })
      setRows([...newRows])
      setTotal(result.payload.total)
    })
  }

  const obterDados = async () => {
    obterDadosTabela()
    httpClient.request({
      method: RequestMethod.GET,
      endpoint: '/Administracao/Prompt/ObterDadosFiltro'
    }).then((response) => {
      if (response.success && response.payload) {
        setTiposPrompt(response.payload.TiposPrompt)
        setModulos(response.payload.Modulos)
        setTiposCampos(response.payload.TiposCampo)
      }
    })
  }

  React.useEffect(() => {    
    obterDados()
  }, [])

  const obterDadosTabela = () => {
    requestTable(page, orderBy, order, rowsPerPage, filtros)
  }

  const handleRequestSort = (event: React.MouseEvent<unknown>, property: keyof Data) => {
    const isAsc = orderBy === property && order === 'asc'
    setOrder(isAsc ? 'desc' : 'asc')
    setOrderBy(property)
    requestTable(page, property, isAsc ? 'desc' : 'asc', rowsPerPage, filtros)
  }

  const handleChangePage = (event: unknown, newPage: number) => {
    requestTable(newPage, orderBy, order, rowsPerPage, filtros)
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    requestTable(page, orderBy, order, parseInt(event.target.value, 10), filtros)
    setPage(0)
  }

  const emptyRows = rows.length

  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null)

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const open = Boolean(anchorEl)
  const id = open ? 'simple-popover' : undefined

  return (
    <>
      <form className='form'>
        <div className='d-flex justify-content-end mb-2'>
          <div>
            <Button
              type='button'
              className='btn btn-light-primary'
              aria-describedby={id}
              onClick={handleClick}
            >
              <i className='fs-2 fas fa-arrow-down-wide-short'></i>
              Filtros
            </Button>
            <Popover
              id={id}
              open={open}
              anchorEl={anchorEl}
              onClose={handleClose}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              className='popover-filtro-color-bg'
            >
              <div className='bg-body'>
                {/* begin::Header */}
                <div className='px-7 py-5'>
                  <div className='fs-5 text-dark fw-bolder'>Opções</div>
                </div>
                {/* end::Header */}

                {/* begin::Separator */}
                <div className='separator border-gray-200'></div>
                {/* end::Separator */}

                {/* begin::Content */}
                <div className='px-7 py-5'>

                  {/* begin::Input group */}
                  <div className='mb-10'>
                    <label className='form-label fs-6 fw-bold'>Tipo de Prompt:</label>
                    <select
                      className='form-select form-select-solid fw-bolder'
                      data-kt-select2='true'
                      data-placeholder='Select option'
                      data-allow-clear='true'
                      data-kt-user-table-filter='role'
                      data-hide-search='true'
                      onChange={e => {
                        let newFiltros = filtros
                        newFiltros.Tipo = e.target.value ? parseInt(e.target.value) : undefined
                        setFiltros(newFiltros)
                      }}
                      defaultValue={filtros.Tipo}
                    >
                      <option value=''>Selecione...</option>
                      {
                        tiposPrompt && tiposPrompt.length > 0 && tiposPrompt.map((data: any, index: any) => {
                          return <option key={`Tipo-option-${index}`} value={data}>{TipoAsString(data)}</option>
                        })
                      }
                    </select>
                  </div>
                  {/* end::Input group */}

                  {/* begin::Input group */}
                  <div className='mb-10'>
                    <label className='form-label fs-6 fw-bold'>Modulo:</label>
                    <select
                      className='form-select form-select-solid fw-bolder'
                      data-kt-select2='true'
                      data-placeholder='Select option'
                      data-allow-clear='true'
                      data-kt-user-table-filter='role'
                      data-hide-search='true'
                      onChange={e => {
                        let newFiltros = filtros
                        newFiltros.IdModulo = e.target.value ? parseInt(e.target.value) : undefined
                        setFiltros(newFiltros)
                      }}
                      defaultValue={filtros.IdModulo}
                    >
                      <option value=''>Selecione...</option>
                      {
                        modulos && modulos.length > 0 && modulos.map((data: any, index: any) => {
                          return <option key={`IdModulo-option-${index}`} value={data.Id}>{data.Nome}</option>
                        })
                      }
                    </select>
                  </div>
                  {/* end::Input group */}   

                  {/* begin::Input group */}
                  <div className='mb-10'>
                    <label className='form-label fs-6 fw-bold'>Tipo de Campo:</label>
                    <select
                      className='form-select form-select-solid fw-bolder'
                      data-kt-select2='true'
                      data-placeholder='Select option'
                      data-allow-clear='true'
                      data-kt-user-table-filter='role'
                      data-hide-search='true'
                      onChange={e => {
                        let newFiltros = filtros
                        newFiltros.IdTipoCampo = e.target.value ? parseInt(e.target.value) : undefined
                        setFiltros(newFiltros)
                      }}
                      defaultValue={filtros.IdTipoCampo}
                    >
                      <option value=''>Selecione...</option>
                      {
                        tiposCampo && tiposCampo.length > 0 && tiposCampo.map((data: any, index: any) => {
                          return <option key={`IdTipoCampo-option-${index}`} value={data.Id}>{data.Nome}</option>
                        })
                      }
                    </select>
                  </div>
                  {/* end::Input group */}                  

                  {/* begin::Actions */}
                  <div className='d-flex justify-content-end'>
                    <button
                      type='button'
                      className='btn btn-light btn-active-light-primary fw-bold me-2 px-6'
                      onClick={handleClose}
                    >
                      Cancelar
                    </button>
                    <button
                      type='button'
                      className='btn btn-primary fw-bold px-6'
                      onClick={() => obterDadosTabela()}
                    >
                      Buscar
                    </button>
                  </div>
                  {/* end::Actions */}
                </div>
                {/* end::Content */}
              </div>
            </Popover>
          </div>
        </div>

        <Box sx={{width: '100%'}}>
          <Paper className='table-border ' sx={{width: '100%', mb: 2}}>
            <TableContainer>
              <Table sx={{minWidth: 750}} aria-labelledby='tableTitle'>
                <EnhancedTableHead
                  order={order}
                  orderBy={orderBy}
                  onRequestSort={handleRequestSort}
                />
                <TableBody>
                  {rows.map((row, index) => {
                    return (
                      <TableRow
                        className='table-row'
                        hover
                        tabIndex={-1}
                        key={index}
                        sx={{cursor: 'pointer'}}
                      >                        
                        <TableCell className='table-cell' align='left'>
                          {row.NomeTipo}
                        </TableCell>
                        <TableCell className='table-cell' align='left'>
                          {row.NomeModulo}
                        </TableCell>
                        <TableCell className='table-cell' align='left'>
                          {row.NomeTipoCampo}
                        </TableCell>
                        <TableCell className='table-cell' align='center'>
                          <IconButton
                            className={`text-gray-800 icon-button-dark-hover`}
                            title='Editar'
                            onClick={() => {
                              navigate('/administracao/prompts/editar', {
                                state: {
                                  IdPrompt: row.Id,
                                },
                              })
                            }}
                          >
                            <svg
                              xmlns='http://www.w3.org/2000/svg'
                              width='16'
                              height='16'
                              fill='currentColor'
                              className='bi bi-pencil-square'
                              viewBox='0 0 16 16'
                            >
                              <path d='M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z' />
                              <path
                                fillRule='evenodd'
                                d='M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z'
                              />
                            </svg>
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    )
                  })}
                  {emptyRows === 0 && (
                    <TableRow>
                      <TableCell className='table-cell bg-body text-center' colSpan={6}>
                        Nenhum registro encontrado
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              className='table-footer'
              labelRowsPerPage='linhas por página'
              rowsPerPageOptions={[10, 25]}
              component='div'
              count={total}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Paper>
        </Box>
      </form>
    </>
  )
}

export {TablePrompts}
