import * as React from 'react';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Paper from '@mui/material/Paper';
import { visuallyHidden } from '@mui/utils';
import { Link, Navigate, useNavigate } from 'react-router-dom';
import { Tooltip, useAlert, useHttpClient } from '../../../../modules/services/Bundle';
import { EnumPesquisaParticipanteStatus, EnumServicoTipoPlano, RequestMethod } from '../../../../modules/services/core/_enums';
import Popover from '@mui/material/Popover';
import Button from '@mui/material/Button';
import { IconButton } from '@mui/material';
import { ModalDetalhesPesquisaParticipante } from './ModalDetalhesPesquisaParticipante';
import { useAuth } from '../../../../modules/auth';

interface Column {
  id: 'Id' | 'Nome' | 'QtdDimensao' | 'QtdPergunta' | 'QtdPesquisa';
  label: string;
  minWidth?: number;
  align?: 'center' | 'right' | 'left';
  format?: (value: number) => string;
}

interface Filtros {
  Nome: string,
}

const columns: readonly Column[] = [
  { 
    id: 'Nome', 
    label: 'TEMA',
    align: 'left'
  },
  { 
    id: 'QtdDimensao', 
    label: 'QTD DE DIMENSÕES',
    align: 'center'
  },
  { 
    id: 'QtdPergunta', 
    label: 'QTD DE PERGUNTAS',
    align: 'center'
  },
  { 
    id: 'QtdPesquisa', 
    label: 'POSSUI PESQUISA?',
    align: 'center'
  },
];

interface Data {
  Id: number;
  Nome: string;
  QtdDimensao: number;
  QtdPergunta: number;
  QtdPesquisa: number;
}

interface Props {
  DadosTeste?: Data[];
}

function createData(
  Id: number,
  Nome: string,
  QtdDimensao: number,
  QtdPergunta: number,
  QtdPesquisa: number,
): Data {
  return { Id, Nome, QtdDimensao, QtdPergunta, QtdPesquisa};
}

type Order = 'asc' | 'desc';

interface EnhancedTableProps {
  numSelected: number;
  onRequestSort: (event: React.MouseEvent<unknown>, property: keyof Data) => void;
  onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
  order: Order;
  orderBy: string;
}

const TableTema:React.FC<Props> = ({DadosTeste}) => {  

  const httpClient = useHttpClient()
  const [order, setOrder] = React.useState<Order>('asc')
  const [orderBy, setOrderBy] = React.useState<keyof Data>('Nome')
  const [selected, setSelected] = React.useState<readonly string[]>([])
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10)
  const [total, setTotal] = React.useState(0)
  const [rows, setRows] = React.useState<Data[]>(DadosTeste ? DadosTeste : [])   
  const alert = useAlert()
  const navigate = useNavigate()
  const {auth} = useAuth()
  const servico = auth?.servico

  const [showModalDetalhes, setShowModalDetalhes] = React.useState(false)
  const [idParticipante, setIdParticipante] = React.useState(0)
  const [statusParticipante, setStatusParticipante] = React.useState(0)
  const [nomeParticipante, setNomeParticipante] = React.useState('')

  const [nome, setNome] = React.useState<any>(null)

  function EnhancedTableHead(props: EnhancedTableProps) {
    const { onSelectAllClick, order, orderBy, onRequestSort } =
      props;
    const createSortHandler =
      (property: keyof Data) => (event: React.MouseEvent<unknown>) => {
        onRequestSort(event, property);
      };
  
    return (
      <TableHead >
        <TableRow className='table-header-pesquisa'>
          {columns.map((columns) => (
            <TableCell
            className='table-cell'
            key={columns.id}
            align={columns.align !== undefined ? columns.align : 'right'}
          >
            <TableSortLabel
              className={`fw-bolder ${columns.align === 'center' ? 'ms-8' : ''}`}
              active={orderBy === columns.id}
              direction={orderBy === columns.id ? order : 'asc'}
              onClick={createSortHandler(columns.id)}
            >
              {columns.label}
              {orderBy === columns.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
          ))}
            <TableCell
              className='fw-bolder table-cell'
              align='center'
            >
              AÇÕES
            </TableCell> 
        </TableRow>
      </TableHead>
    );
  }

  const resquestTable = (pageRq: number, orderByRq: string, orderRq: string, rowsPerPageRq: number, Filtros: any = null) => {
    
      httpClient.request({
        method: RequestMethod.POST,
        endpoint: '/Pesquisa/Tema/ListarTemas',
        data: {
          Order: orderRq,
          OrderBy: orderByRq,
          Page: pageRq,
          RowsPerPage: rowsPerPage,
          Filters: Filtros
        }
      }).then((result) => {
          
          const newRows = result.payload.data.map((data: Data) => {
            return createData(data.Id, data.Nome, data.QtdDimensao, data.QtdPergunta, data.QtdPesquisa)
          })
          setRows([...newRows])
          setTotal(result.payload.total)
      })
    
  }

  React.useEffect(() => {
      resquestTable(page, orderBy, order, rowsPerPage);
  },[])

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: keyof Data,
  ) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
    resquestTable(page, property, isAsc ? 'desc' : 'asc', rowsPerPage);
  };

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelected = rows.map((n) => n.Nome);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    resquestTable(newPage, orderBy, order, rowsPerPage);
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    resquestTable(page, orderBy, order, parseInt(event.target.value, 10));
    setPage(0);
  };

  const emptyRows = rows.length;

  const atualizarDados = () => {
    resquestTable(page, orderBy, order, rowsPerPage)
  }

  const onClickBuscar = () => {

    const filtros:Filtros = {
      Nome: nome,
    }
    
    resquestTable(page, orderBy, order, rowsPerPage,filtros);
    handleClose()
  }

  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
      setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
      setAnchorEl(null);
      setNome(null)
  };

  const onClickAbrirModalVisualizarDetalhes = (id:number, nome:string, status:number) => {
    setIdParticipante(id)
    setNomeParticipante(nome)
    setStatusParticipante(status)
    setShowModalDetalhes(true)
  }

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  return (
    <>
      <ModalDetalhesPesquisaParticipante 
        nomeParticipante={nomeParticipante}
        statusParticipante={statusParticipante}
        idParticipante={idParticipante}
        setIdParticipante={setIdParticipante}
        showModal={showModalDetalhes}
        setShowModal={setShowModalDetalhes}
        tipoChamada={"pesquisa-em-andamento"}
      />
      
      <form className='form'>

          <div className='d-flex justify-content-end mb-2'>

            <div>
              <Button type='button' className='btn btn-light-success' aria-describedby={id} onClick={handleClick}>
                <i className="fs-2 fas fa-arrow-down-wide-short"></i>
                Filtros
              </Button>
              <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                className="popover-filtro-color-bg"
              >
                
                  <div className='bg-body'>
                    {/* begin::Header */}
                    <div className='px-7 py-5'>
                      <div className='fs-5 text-dark fw-bolder'>Opções</div>
                    </div>
                    {/* end::Header */}

                    {/* begin::Separator */}
                    <div className='separator border-gray-200'></div>
                    {/* end::Separator */}

                    {/* begin::Content */}
                    <div className='px-7 py-5' >

                      {/* begin::Input group */}
                      <div className='mb-10'>
                        <label className='form-label fs-6 fw-bold'>Nome do Tema:</label>
                        <input 
                          type='text'
                          onChange={e => setNome(e.target.value)} 
                          placeholder='Nome do Tema' 
                          className={`form-control form-control-lg form-control-solid`}
                        ></input>
                      </div>
                      {/* end::Input group */}

                      {/* begin::Actions */}
                      <div className='d-flex justify-content-end'>
                        <button
                          type='button'
                          className='btn btn-light btn-active-light-primary fw-bold me-5 px-6'
                          onClick={handleClose}
                        >
                          Cancelar
                        </button>
                        <button
                          type='button'
                          className='btn btn-success fw-bold px-6'
                          onClick={() => onClickBuscar()}
                        >
                          Buscar
                        </button>
                      </div>
                      {/* end::Actions */}
                    </div>
                    {/* end::Content */}
                  </div>
              </Popover>
            </div>
          </div>

          <Box sx={{ width: '100%' }}>
            <Paper className='table-border-pesquisa tabela-pesquisa' sx={{ width: '100%', mb: 2 }}>
                     
              <TableContainer>
                <Table
                  sx={{ minWidth: 750 }}
                  aria-labelledby="tableTitle"
                >
                  <EnhancedTableHead
                    numSelected={selected.length}
                    order={order}
                    orderBy={orderBy}
                    onSelectAllClick={handleSelectAllClick}
                    onRequestSort={handleRequestSort}
                  />
                  <TableBody>
                    {rows.map((row, index) => {
                      return (
                        <TableRow
                          className='table-row-pesquisa'
                          hover
                          tabIndex={-1}
                          key={index}
                          sx={{ cursor: 'pointer' }}
                        >
                          <TableCell className='table-cell' align={"left"}>{row.Nome}</TableCell>
                          <TableCell className='table-cell' align="center">{row.QtdDimensao}</TableCell>
                          <TableCell className='table-cell' align="center">{row.QtdPergunta}</TableCell>
                          <TableCell className='table-cell' align="center">{row.QtdPesquisa > 0 ? 'Sim' : 'Não'}</TableCell>
                          <TableCell className='table-cell' align="center">
                            <IconButton 
                              className={`text-gray-800 icon-button-dark-hover ms-1`}
                              title='Visualizar' 
                              onClick={() => { 
                                navigate('/pesquisa-ia/temas/crud-tema', { state: { 
                                  idTema: row.Id,
                                  tipoRedirect: 'Visualizar'
                                } })
                              }}
                            >
                              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-eye-fill" viewBox="0 0 16 16">
                                  <path d="M10.5 8a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0z"/>
                                  <path d="M0 8s3-5.5 8-5.5S16 8 16 8s-3 5.5-8 5.5S0 8 0 8zm8 3.5a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7z"/>
                              </svg>
                            </IconButton>
                            {(servico?.tipoPlano === EnumServicoTipoPlano.Buy && ((auth?.roles.find(x => x === 'Master') !== undefined) || (auth?.roles.find(x => x === 'Administrador') !== undefined) || (auth?.permissoes.find(x => x === 'B9194E9ABBF5DDF1519A902BB8129E84')) !== undefined)) && (         
                              <IconButton 
                                className={`text-gray-800 icon-button-dark-hover`}
                                title='Editar'
                                onClick={() => { 
                                  if (row.QtdPesquisa) return alert.createMessage({
                                    html: "É necessário excluir as pesquisas vinculadas para editar o tema."
                                  })
                                  navigate('/pesquisa-ia/temas/crud-tema', { state: { 
                                    idTema: row.Id,
                                    tipoRedirect: 'Editar'
                                  } })
                                }}
                              >
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-pencil-square" viewBox="0 0 16 16">
                                    <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z"/>
                                    <path fillRule="evenodd" d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z"/>
                                </svg>
                              </IconButton>
                            )}
                            {(servico?.tipoPlano === EnumServicoTipoPlano.Buy && ((auth?.roles.find(x => x === 'Master') !== undefined) || (auth?.roles.find(x => x === 'Administrador') !== undefined) || (auth?.permissoes.find(x => x === '448A3057D3B0E7F0C323EACB027E381C')) !== undefined)) && (         
                              <IconButton 
                                className='text-gray-800 icon-button-dark-hover ms-1' 
                                title='Excluir'
                                onClick={() => {
                                  if (row.QtdPesquisa) return alert.createMessage({
                                    html: "É necessário excluir as pesquisas vinculadas para excluir o tema."
                                  })
                                  alert.createDialog({
                                    html: "Esta ação é irreversível, deseja realmente excluir este tema?",
                                    confirmAction: () => {
                                      httpClient.request({
                                        method: RequestMethod.DELETE,
                                        endpoint: '/Pesquisa/ConfiguracoesIniciais/ExcluirTema',
                                        queryObject: {
                                          idTema: row.Id
                                        }
                                      }).then((response) => {
                                        if(response.success){
                                          resquestTable(page, orderBy, order, rowsPerPage)
                                        }
                                      })
                                    }
                                  })
                                }}
                              >
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-trash-fill" viewBox="0 0 16 16">
                                  <path d="M2.5 1a1 1 0 0 0-1 1v1a1 1 0 0 0 1 1H3v9a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V4h.5a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H10a1 1 0 0 0-1-1H7a1 1 0 0 0-1 1H2.5zm3 4a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 .5-.5zM8 5a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7A.5.5 0 0 1 8 5zm3 .5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 1 0z"/>
                                </svg>
                              </IconButton>
                            )}
                          </TableCell>
                        </TableRow>
                      );
                    })}
                    {emptyRows == 0 && (
                      <TableRow
                      >
                        <TableCell className='table-cell bg-body text-center' colSpan={6}>
                          Nenhum registro encontrado
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                className='table-footer-pesquisa'
                labelRowsPerPage="linhas por página"
                rowsPerPageOptions={[10, 25]}
                component="div"
                count={total}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </Paper>
          </Box>
      </form>
    </>    
  )
}

export {TableTema}