import {useEffect, useRef, useState} from 'react'
import {KTSVG} from '../../../../_metronic/helpers'
import {Step1} from './steps/Step1'
import {Step2} from './steps/Step2'
import {Step3} from './steps/Step3'
import {Step4} from './steps/Step4'
import {Step5} from './steps/Step5'
import {Step6} from './steps/Step6'
import {StepperComponent} from '../../../../_metronic/assets/ts/components'
import {FormikValues, useFormik, FormikProvider} from 'formik'
import {
  IWizardModel,
  defaultValidationSchemas,
  defaultWizardArea,
  defaultWizardNivel,
  IWizardTipoCampoModel,
} from './helpers/ConfiguracoesIniciaisHelper'
import {useHttpClient} from '../../../modules/services/Bundle'
import {EnumStepsConfigInicial, RequestMethod} from '../../../modules/services/core/_enums'
import {useAuth} from '../../../modules/auth'
import {useNavigate} from 'react-router'

const initValues: IWizardModel = {
  empresaId: undefined,
  empresaRazaoSocial: '',
  empresaSegmento: undefined,
  empresaNomeSegmento: '',
  QtdFuncionarios: 0,
  PorteEmpresa: 0,
  empresaLogo: undefined,
  modeloId: undefined,
  modeloNome: '',
  modeloCampos: [],
  niveis: [defaultWizardNivel],
  areas: [defaultWizardArea],
  cargos: [defaultWizardArea],
}

const ConfiguracoesIniciais = () => {
  const stepperRef = useRef<HTMLDivElement | null>(null)
  const stepper = useRef<StepperComponent | null>(null)
  const [validationSchemas] = useState(defaultValidationSchemas)
  const [currentSchema, setCurrentSchema] = useState(validationSchemas[0])
  const {auth, saveAuth} = useAuth()
  const httpClient = useHttpClient()
  const [nameStep, setNameStep] = useState('Empresa')
  const [segmentos, setSegmentos] = useState<any[]>([])
  const [tiposCampo, setTiposCampo] = useState<IWizardTipoCampoModel[]>([])
  const navigate = useNavigate()

  const loadStepper = () => {
    stepper.current = StepperComponent.createInsance(stepperRef.current as HTMLDivElement)
  }

  const prevStep = () => {
    if (!stepper.current) {
      return
    }
    if (stepper.current.currentStepIndex === 1) {
      navigate('/inicio')
      return
    }
    stepper.current.goPrev()

    setCurrentSchema(validationSchemas[stepper.current.currentStepIndex - 1])
    setNameStep(EnumStepsConfigInicial[stepper.current.currentStepIndex - 1])
  }

  const submitStep = async (values: IWizardModel, actions: FormikValues) => {
    if (!stepper.current) {
      return
    }
    setNameStep(EnumStepsConfigInicial[stepper.current.currentStepIndex])

    if (stepper.current.currentStepIndex !== stepper.current.totatStepsNumber) {
      let sucesso = false
      //Salvar Step
      switch (stepper.current.currentStepIndex) {
        case 1: {
          const model = {
            Id: values.empresaId ?? 0,
            RazaoSocial: values.empresaRazaoSocial,
            IdSegmento: values.empresaSegmento,
            EmpresaLogo: values.empresaLogo,
            NomeSegmento: values.empresaNomeSegmento ?? '',
            QtdFuncionarios: values.QtdFuncionarios,
            PorteEmpresa: values.PorteEmpresa
          }

          if (model.NomeSegmento) {
            const response = await httpClient.request({
              method: RequestMethod.GET,
              endpoint: '/Gerenciamento/Segmento/ExisteSegmento',
              queryObject: {
                nomeSegmento: values.empresaNomeSegmento,
              },
            })

            if (response.success) {
              const resp = await httpClient.request({
                method: RequestMethod.POST,
                endpoint: '/DescricaoDeCargo/ConfiguracoesIniciais/AdicionarEditarEmpresa',
                data: model,
              })

              if (resp.success && resp.payload) {
                const empresa = resp.payload
                actions.setFieldValue('empresaId', empresa.Id)
                sucesso = true
              }
            }
          } else {
            const response = await httpClient.request({
              method: RequestMethod.POST,
              endpoint: '/DescricaoDeCargo/ConfiguracoesIniciais/AdicionarEditarEmpresa',
              data: model,
            })

            if (response.success && response.payload) {
              const empresa = response.payload
              actions.setFieldValue('empresaId', empresa.Id)
              sucesso = true
            }
          }

          break
        }
        case 2: {
          const model = {
            Id: values.modeloId ?? 0,
            IdEmpresa: values.empresaId,
            Nome: values.modeloNome,
            Campo: values.modeloCampos.map((x, index) => {
              return {
                Id: x.id ?? 0,
                IdModelo: values.modeloId ?? 0,
                IdTipoCampo: x.idTipoCampo,
                Nome: x.nome,
                Ordem: index,
              }
            }),
          }

          const response = await httpClient.request({
            method: RequestMethod.POST,
            endpoint: '/DescricaoDeCargo/ConfiguracoesIniciais/AdicionarEditarModelo',
            data: model,
          })

          if (response.success && response.payload) {
            const modelo = response.payload
            actions.setFieldValue('modeloId', modelo.Id)
            actions.setFieldValue('modeloNome', modelo.Nome)
            actions.setFieldValue(
              'modeloCampos',
              modelo.Campo.sort((x: any) => x.Ordem).map((x: any) => {
                return {
                  id: x.Id,
                  idTipoCampo: x.IdTipoCampo,
                  nome: x.Nome,
                }
              })
            )
            sucesso = true
          }

          break
        }
        case 3: {
          const model = values.niveis.map((x) => {
            return {
              Id: x.id ?? 0,
              IdEmpresa: values.empresaId,
              Nome: x.nome,
              Relevancia: x.relevancia,
            }
          })

          const response = await httpClient.request({
            method: RequestMethod.POST,
            endpoint: '/DescricaoDeCargo/ConfiguracoesIniciais/AdicionarEditarNivelHierarquico',
            data: model,
          })

          if (response.success && response.payload) {
            const niveis = response.payload
            actions.setFieldValue(
              'niveis',
              niveis.map((x: any) => {
                return {
                  id: x.Id,
                  nome: x.Nome,
                  relevancia: x.Relevancia,
                }
              })
            )
            sucesso = true
          }

          break
        }
        case 4: {
          const model = values.areas.map((x) => {
            return {
              Id: x.id ?? 0,
              IdEmpresa: values.empresaId,
              Nome: x.nome,
              Descricao: x.descricao,
            }
          })

          const response = await httpClient.request({
            method: RequestMethod.POST,
            endpoint: '/DescricaoDeCargo/ConfiguracoesIniciais/AdicionarEditarArea',
            data: model,
          })

          if (response.success && response.payload) {
            const areas = response.payload
            actions.setFieldValue(
              'areas',
              areas.map((x: any) => {
                return {
                  id: x.Id,
                  nome: x.Nome,
                  descricao: x.Descricao,
                }
              })
            )
            sucesso = true
          }

          break
        }
        case 5: {
          const model = values.cargos.map((x) => {
            return {
              Id: x.id ?? 0,
              IdArea: x.area,
              IdNivelHierarquico: x.nivel,
              Nome: x.nome,
            }
          })

          const response = await httpClient.request({
            method: RequestMethod.POST,
            endpoint: '/DescricaoDeCargo/ConfiguracoesIniciais/AdicionarEditarCargo',
            data: model,
          })

          if (response.success && response.payload && auth) {
            const cargos = response.payload
            actions.setFieldValue(
              'cargos',
              cargos.map((x: any) => {
                return {
                  id: x.Id,
                  area: x.IdArea,
                  nivel: x.IdNivelHierarquico,
                  nome: x.Nome,
                }
              })
            )
            let newAuth = {...auth}
            newAuth.servico.moduloDescricaoConfig.Desbloqueado = false
            saveAuth(newAuth)
            sucesso = true
          }

          break
        }
      }
      if (sucesso) stepper.current.goNext()
      setCurrentSchema(validationSchemas[stepper.current.currentStepIndex - 1])
      return
    }
    if (stepper.current.currentStepIndex === stepper.current.totatStepsNumber && auth) {
      try {
        const response = await httpClient.request({
          method: RequestMethod.GET,
          endpoint: '/DescricaoDeCargo/ConfiguracoesIniciais/FinalizarConfiguracoesIniciais',
          ifSuccessRedirectTo: '/descricao/processos',
          blockFree: false,
        })
        if (response && response.success) {
          let newAuth = {...auth}
          newAuth.servico.moduloDescricaoConfig.Desbloqueado = true
          saveAuth(newAuth)
        }
      } catch (e) {
      }
    }
  }

  const formik = useFormik({
    initialValues: {...initValues},
    validationSchema: currentSchema,
    onSubmit: submitStep,
  })

  useEffect(() => {
    const fetchConfiguracoesIniciais = async () => {
      
      const response = await httpClient.request({
        method: RequestMethod.GET,
        endpoint: '/DescricaoDeCargo/ConfiguracoesIniciais/ObterConfiguracoesIniciais',
      })

      if (response.success && response.payload) {
        let ci = response.payload

        let empresa = ci.Empresa

        let newValues = {...initValues}

        newValues.empresaId = empresa.Id
        newValues.empresaRazaoSocial = empresa.RazaoSocial
        newValues.empresaSegmento = empresa.IdSegmento
        newValues.empresaNomeSegmento = empresa.NomeSegmento
        newValues.QtdFuncionarios = empresa.QtdFuncionarios
        newValues.PorteEmpresa = empresa.PorteEmpresa

        let empresaLogo = empresa.EmpresaLogo

        if (empresaLogo) {
          newValues.empresaLogo = {
            Nome: empresaLogo.Nome,
            Extensao: empresaLogo.Extensao,
            Tamanho: empresaLogo.Tamanho,
            Tipo: empresaLogo.Tipo,
            Base64: empresaLogo.Base64,
          }
        }

        let modelo = ci.Modelo

        if (modelo) {
          newValues.modeloId = modelo.Id
          newValues.modeloNome = modelo.Nome

          let campos = modelo.Campo

          if (campos && campos.length > 0) {
            newValues.modeloCampos = campos
              .sort((x: any) => x.Ordem)
              .map((x: any) => {
                return {
                  id: x.Id,
                  idTipoCampo: x.IdTipoCampo,
                  nome: x.Nome,
                }
              })
          }
        }

        let niveis = ci.Niveis

        if (niveis && niveis.length > 0) {
          newValues.niveis = niveis
            .sort((a: any, b: any) => {
              if (a.Relevancia! < b.Relevancia!) {
                return -1 // a deve vir antes de b
              }
              if (a.Relevancia! > b.Relevancia!) {
                return 1 // a deve vir depois de b
              }
              return 0 // a e b são iguais em termos de relevância
            })
            .map((x: any) => {
              return {
                id: x.Id,
                nome: x.Nome,
                relevancia: x.Relevancia,
              }
            })
        }

        let areas = ci.Areas

        if (areas && areas.length > 0) {
          newValues.areas = areas.map((x: any) => {
            return {
              id: x.Id,
              nome: x.Nome,
              descricao: x.Descricao,
            }
          })
        }

        let cargos = ci.Cargos

        if (cargos && cargos.length > 0) {
          newValues.cargos = cargos.map((x: any) => {
            return {
              id: x.Id,
              nivel: x.IdNivelHierarquico,
              area: x.IdArea,
              nome: x.Nome,
            }
          })
        }

        formik.setValues(newValues)
      }

      await httpClient.request({
        method: RequestMethod.GET,
        endpoint: '/Gerenciamento/Segmento/ObterParaSelect',
      })
      .then((response) => {
        if (response.success && response.payload) setSegmentos(response.payload)
      })

      await httpClient.request({
        method: RequestMethod.GET,
        endpoint: '/Gerenciamento/TipoCampo/ObterTodos',
      })
      .then((response) => {
        if (response.success && response.payload) setTiposCampo(response.payload)
      })
    }

    fetchConfiguracoesIniciais()
  }, [])

  useEffect(() => {
    if (!stepperRef.current) {
      return
    }
    loadStepper()
  }, [stepperRef])

  return (
    <>
      <ol className='breadcrumb breadcrumb-line text-muted fs-6 mt-0 fw-semibold mb-10'>
        <li className='breadcrumb-item h2 text-gray-600'>Descrição</li>
        <li className='breadcrumb-item h2 text-gray-600'>Configurações Iniciais</li>
        <li className='breadcrumb-item h2 text-dark'>{nameStep}</li>
      </ol>      
      <div
        ref={stepperRef}
        className='stepper stepper-pills stepper-column d-flex flex-column flex-xl-row flex-row-fluid'
        id='kt_create_account_stepper'
      >
        {/* begin::Aside*/}
        <div className='card d-flex justify-content-center justify-content-xl-start flex-row-auto w-100 w-xl-300px me-5'>
          {/* begin::Wrapper*/}
          <div className='card-body px-6 py-20'>
            {/* begin::Nav*/}
            <div className='stepper-nav'>
              {/* begin::Step 1*/}
              <div className='stepper-item current' data-kt-stepper-element='nav'>
                {/* begin::Wrapper*/}
                <div className='stepper-wrapper'>
                  {/* begin::Icon*/}
                  <div className='stepper-icon w-40px h-40px'>
                    <i className='stepper-check fas fa-check'></i>
                    <span className='stepper-number'>1</span>
                  </div>
                  {/* end::Icon*/}

                  {/* begin::Label*/}
                  <div className='stepper-label'>
                    <h3 className='stepper-title'>Empresa</h3>

                    <div className='stepper-desc fw-semibold'>Dados básicos da empresa</div>
                  </div>
                  {/* end::Label*/}
                </div>
                {/* end::Wrapper*/}

                {/* begin::Line*/}
                <div className='stepper-line h-40px'></div>
                {/* end::Line*/}
              </div>
              {/* end::Step 1*/}

              {/* begin::Step 2*/}
              <div className='stepper-item' data-kt-stepper-element='nav'>
                {/* begin::Wrapper*/}
                <div className='stepper-wrapper'>
                  {/* begin::Icon*/}
                  <div className='stepper-icon w-40px h-40px'>
                    <i className='stepper-check fas fa-check'></i>
                    <span className='stepper-number'>2</span>
                  </div>
                  {/* end::Icon*/}

                  {/* begin::Label*/}
                  <div className='stepper-label'>
                    <h3 className='stepper-title'>Modelo</h3>
                    <div className='stepper-desc fw-semibold'>Formulário de descrição</div>
                  </div>
                  {/* end::Label*/}
                </div>
                {/* end::Wrapper*/}

                {/* begin::Line*/}
                <div className='stepper-line h-40px'></div>
                {/* end::Line*/}
              </div>
              {/* end::Step 2*/}

              {/* begin::Step 3*/}
              <div className='stepper-item' data-kt-stepper-element='nav'>
                {/* begin::Wrapper*/}
                <div className='stepper-wrapper'>
                  {/* begin::Icon*/}
                  <div className='stepper-icon w-40px h-40px'>
                    <i className='stepper-check fas fa-check'></i>
                    <span className='stepper-number'>3</span>
                  </div>
                  {/* end::Icon*/}

                  {/* begin::Label*/}
                  <div className='stepper-label'>
                    <h3 className='stepper-title'>Níveis hierárquicos</h3>
                    <div className='stepper-desc fw-semibold'>Hierarquia da empresa</div>
                  </div>
                  {/* end::Label*/}
                </div>
                {/* end::Wrapper*/}

                {/* begin::Line*/}
                <div className='stepper-line h-40px'></div>
                {/* end::Line*/}
              </div>
              {/* end::Step 3*/}

              {/* begin::Step 4*/}
              <div className='stepper-item' data-kt-stepper-element='nav'>
                {/* begin::Wrapper*/}
                <div className='stepper-wrapper'>
                  {/* begin::Icon*/}
                  <div className='stepper-icon w-40px h-40px'>
                    <i className='stepper-check fas fa-check'></i>
                    <span className='stepper-number'>4</span>
                  </div>
                  {/* end::Icon*/}

                  {/* begin::Label*/}
                  <div className='stepper-label'>
                    <h3 className='stepper-title'>Áreas</h3>
                    <div className='stepper-desc fw-semibold'>Departamentos da empresa</div>
                  </div>
                  {/* end::Label*/}
                </div>
                {/* end::Wrapper*/}

                {/* begin::Line*/}
                <div className='stepper-line h-40px'></div>
                {/* end::Line*/}
              </div>
              {/* end::Step 4*/}

              {/* begin::Step 5*/}
              <div className='stepper-item' data-kt-stepper-element='nav'>
                {/* begin::Wrapper*/}
                <div className='stepper-wrapper'>
                  {/* begin::Icon*/}
                  <div className='stepper-icon w-40px h-40px'>
                    <i className='stepper-check fas fa-check'></i>
                    <span className='stepper-number'>5</span>
                  </div>
                  {/* end::Icon*/}

                  {/* begin::Label*/}
                  <div className='stepper-label'>
                    <h3 className='stepper-title'>Cargos</h3>
                    <div className='stepper-desc fw-semibold'>Funções da empresa</div>
                  </div>
                  {/* end::Label*/}
                </div>
                {/* end::Wrapper*/}

                {/* begin::Line*/}
                <div className='stepper-line h-40px'></div>
                {/* end::Line*/}
              </div>
              {/* end::Step 5*/}

              {/* begin::Step 6*/}
              <div className='stepper-item' data-kt-stepper-element='nav'>
                {/* begin::Wrapper*/}
                <div className='stepper-wrapper'>
                  {/* begin::Icon*/}
                  <div className='stepper-icon w-40px h-40px'>
                    <i className='stepper-check fas fa-check'></i>
                    <span className='stepper-number'>6</span>
                  </div>
                  {/* end::Icon*/}

                  {/* begin::Label*/}
                  <div className='stepper-label'>
                    <h3 className='stepper-title'>Finalizar</h3>
                    <div className='stepper-desc fw-semibold'>Informações e conclusão</div>
                  </div>
                  {/* end::Label*/}
                </div>
                {/* end::Wrapper*/}
              </div>
              {/* end::Step 6*/}
            </div>
            {/* end::Nav*/}
          </div>
          {/* end::Wrapper*/}
        </div>
        {/* begin::Aside*/}

        <div className='d-flex flex-row-fluid flex-center bg-body rounded'>
          <FormikProvider value={formik}>
            <form
              id={'form-configs-iniciais'}
              className={`py-20 w-100 ${
                (stepper.current?.currentStepIndex ?? 0) === 2 ? '' : 'w-xl-700px'
              } px-9`}
              noValidate
              onSubmit={formik.handleSubmit}
            >
              <div className='current' data-kt-stepper-element='content'>
                <Step1 setSegmentos={setSegmentos} segmentos={segmentos} formik={formik} />
              </div>

              <div data-kt-stepper-element='content'>
                <Step2 setTiposCampo={setTiposCampo} tiposCampo={tiposCampo} formik={formik} />
              </div>

              <div data-kt-stepper-element='content'>
                <Step3 formik={formik} />
              </div>

              <div data-kt-stepper-element='content'>
                <Step4 formik={formik} />
              </div>

              <div data-kt-stepper-element='content'>
                <Step5 formik={formik} />
              </div>

              <div data-kt-stepper-element='content'>
                <Step6 />
              </div>

              <div className='d-flex flex-stack pt-10'>
                <div className='mr-2'>
                  <button
                    onClick={prevStep}
                    type='button'
                    className='btn btn-lg btn-primary me-3'
                    // data-kt-stepper-action='previous'
                  >
                    <KTSVG
                      path='/media/icons/duotune/arrows/arr063.svg'
                      className='svg-icon-4 me-1'
                    />
                    Voltar
                  </button>
                </div>

                <div>
                  <button
                    type='button'
                    onClick={() => formik.handleSubmit()}
                    className='btn btn-lg btn-primary me-3'
                  >
                    <span className='indicator-label'>
                      {(stepper.current?.currentStepIndex ?? 0) ===
                      (stepper.current?.totatStepsNumber ?? 6)
                        ? 'Finalizar'
                        : 'Continuar'}
                      <KTSVG
                        path='/media/icons/duotune/arrows/arr064.svg'
                        className='svg-icon-3 ms-2 me-0'
                      />
                    </span>
                  </button>
                </div>
              </div>
            </form>
          </FormikProvider>
        </div>
      </div>
    </>
  )
}

export {ConfiguracoesIniciais}
