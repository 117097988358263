import * as Yup from 'yup'

export interface INivelModel {
  id?: number,
  nome: string,
  relevancia: number
}

const defaultValidationSchemas = Yup.object().shape({
  nome: Yup.string().required('O nome é obrigatório'),
  relevancia: Yup.number()
              .min(1, "A relevância deve ser maior que zero.")
              .typeError("Somente números são permitidos.")
              .required("A Relevância é obrigatória")
})

const defaultNivel: INivelModel = {
  id: undefined,
  nome: '',
  relevancia: 0
}

export {defaultValidationSchemas, defaultNivel}