import {useEffect, useState} from 'react'
import {CardDescricao} from './partials/CardDescricao'
import {useHttpClient} from '../../../modules/services/Bundle'
import {EnumDescricaoStatusAprovacao, EnumServicoTipoPlano, RequestMethod} from '../../../modules/services/core/_enums'
import {useAuth} from '../../../modules/auth'

interface IDashboardDescricaoDados {
  TotalCargos: number
  CargosARevisar: number
  CargosAAvaliar: number
  CargosAAprovar: number
  CargosARevisarPorcentagem: number
  CargosAAvaliarPorcentagem: number
  CargosAAprovarPorcentagem: number
  DescricoesReprovadas: number
  DescricoesAprovadas: number
  DescricoesReprovadasPorcentagem: number
  DescricoesAprovadasPorcentagem: number
}

const defaultDados: IDashboardDescricaoDados = {
  TotalCargos: 0,
  CargosARevisar: 0,
  CargosAAvaliar: 0,
  CargosAAprovar: 0,
  CargosARevisarPorcentagem: 0,
  CargosAAvaliarPorcentagem: 0,
  CargosAAprovarPorcentagem: 0,
  DescricoesReprovadas: 0,
  DescricoesAprovadas: 0,
  DescricoesReprovadasPorcentagem: 0,
  DescricoesAprovadasPorcentagem: 0,
}

const DescricaoType = {
  Revisar: 'A Revisar',
  Avaliar: 'A Avaliar',
  Aprovar: 'A Aprovar',
  DescricoesAprovadas: 'Descrições Aprovadas',
  DescricoesReprovadas: 'Descrições Reprovadas',
}

const Dashboard = () => {
  let [dados, setDados] = useState<IDashboardDescricaoDados>(defaultDados)
  let [atualizarTodasAsTabelas, setAtualizarTodasAsTabelas] = useState(false)
  const httpClient = useHttpClient()
  const {auth} = useAuth()
  const servico = auth?.servico
  const {moduloDescricaoConfig, moduloPesquisaConfig} = servico!

  const obterDescricaoDados = () => {
    httpClient.request({
      method: RequestMethod.GET,
      endpoint: `/DescricaoDeCargo/Dashboard/ObterDashboardDescricaoDados`,
    }).then((response) => {
      if (response.success && response.payload) setDados(response.payload)
    })
  }

  useEffect(() => {
    obterDescricaoDados()
  }, [])

  return (
    <>
      {/* begin::Row */}
      <div className='row g-5 g-xl-8'>
      {servico?.moduloConfig.DescricaoDeCargo && 
      moduloDescricaoConfig?.ativo && 
      ((auth?.permissoes.find((x) => x === '410F06772E4A9186E4ED3D687CF241A0') !== undefined) === false) 
      && ((auth?.permissoes.find((x) => x === 'B847D84BCACD0DF5405CCE536A99AD58') !== undefined) 
      || (auth?.permissoes.find((x) => x === 'F83AC0F344DD8AFE835ABD3DF11092A8') !== undefined)) ?
        <>
          {/* avaliador */}
          {(auth?.permissoes.find((x) => x === 'F83AC0F344DD8AFE835ABD3DF11092A8') !== undefined) && (
            <div className='col-xl-12'>
              <CardDescricao
                svgIcon='/media/icons/duotune/files/fil011.svg'
                color='#3498DB'
                iconColor='white'
                title='A Avaliar'
                titleColor='white'
                total={dados.CargosAAvaliar}
                chartValue={dados.CargosAAvaliarPorcentagem}
                btnTo='' 
                descricaoType={DescricaoType.Avaliar}
                statusDescricao={EnumDescricaoStatusAprovacao.AAvaliar}
                setDadosDash={obterDescricaoDados}
                atualizarTodasAsTabelas={atualizarTodasAsTabelas}
                setAtualizarTodasAsTabelas={setAtualizarTodasAsTabelas}
                mostrarPorcentagem={false}
                filterArea={true}
              />
            </div>
          )}

          {/* aprovador */}
          {(auth?.permissoes.find((x) => x === 'B847D84BCACD0DF5405CCE536A99AD58') !== undefined) && (
            <div className='col-xl-12'>
              <CardDescricao
                svgIcon='/media/icons/duotune/general/gen005.svg'
                color='#1ABC9C'
                iconColor='white'
                title='A Aprovar'
                titleColor='white'
                total={dados.CargosAAprovar}
                chartValue={dados.CargosAAprovarPorcentagem}
                btnTo=''
                descricaoType={DescricaoType.Aprovar}
                statusDescricao={EnumDescricaoStatusAprovacao.AAprovar}
                setDadosDash={obterDescricaoDados}
                atualizarTodasAsTabelas={atualizarTodasAsTabelas}
                setAtualizarTodasAsTabelas={setAtualizarTodasAsTabelas}
                mostrarPorcentagem={false}
                filterArea={true}
              />
            </div>
          )}
        </>
      :
        <>
          <div className='col-xl-12'>
            <CardDescricao
              svgIcon='/media/icons/duotune/files/fil005.svg'
              color='#ABABAB'
              iconColor='white'
              title='A Revisar'
              titleColor='white'
              total={dados.CargosARevisar}
              chartValue={dados.CargosARevisarPorcentagem}
              descricaoType={DescricaoType.Revisar}
              statusDescricao={EnumDescricaoStatusAprovacao.ARevisar}
              btnTo=''
              setDadosDash={obterDescricaoDados}
              atualizarTodasAsTabelas={atualizarTodasAsTabelas}
              setAtualizarTodasAsTabelas={setAtualizarTodasAsTabelas}
              filterArea={true}
            />
          </div>
          {(servico?.tipoPlano === EnumServicoTipoPlano.Buy && servico.moduloDescricaoConfig.usaAprovacao) || servico?.clienteConsultoriaDescricao ? (
            <>
  
              {((servico.clienteConsultoriaDescricao && servico.clienteConsultoriaDescricao.UtilizaAvaliador)) && (
                <div className='col-xl-12'>
                  <CardDescricao
                    svgIcon='/media/icons/duotune/files/fil011.svg'
                    color='#3498DB'
                    iconColor='white'
                    title='A Avaliar'
                    titleColor='white'
                    total={dados.CargosAAvaliar}
                    chartValue={dados.CargosAAvaliarPorcentagem}
                    btnTo='' 
                    descricaoType={DescricaoType.Avaliar}
                    statusDescricao={EnumDescricaoStatusAprovacao.AAvaliar}
                    setDadosDash={obterDescricaoDados}
                    atualizarTodasAsTabelas={atualizarTodasAsTabelas}
                    setAtualizarTodasAsTabelas={setAtualizarTodasAsTabelas}
                    filterArea={true}
                  />
                </div>
              )}
  
              <div className='col-xl-12'>
                <CardDescricao
                  svgIcon='/media/icons/duotune/general/gen005.svg'
                  color='#1ABC9C'
                  iconColor='white'
                  title='A Aprovar'
                  titleColor='white'
                  total={dados.CargosAAprovar}
                  chartValue={dados.CargosAAprovarPorcentagem}
                  btnTo=''
                  descricaoType={DescricaoType.Aprovar}
                  statusDescricao={EnumDescricaoStatusAprovacao.AAprovar}
                  setDadosDash={obterDescricaoDados}
                  atualizarTodasAsTabelas={atualizarTodasAsTabelas}
                  setAtualizarTodasAsTabelas={setAtualizarTodasAsTabelas}
                  filterArea={true}
                />
              </div>
  
              <div className='col-xl-12'>
                <CardDescricao
                  svgIcon='/media/icons/duotune/files/fil007.svg'
                  color='#E74C3C'
                  iconColor='white'
                  title='Reprovadas'
                  titleColor='white'
                  total={dados.DescricoesReprovadas}
                  chartValue={dados.DescricoesReprovadasPorcentagem}
                  descricaoType={DescricaoType.DescricoesReprovadas}
                  statusDescricao={EnumDescricaoStatusAprovacao.Reprovada}
                  btnTo=''
                  setDadosDash={setDados}
                  atualizarTodasAsTabelas={atualizarTodasAsTabelas}
                  setAtualizarTodasAsTabelas={setAtualizarTodasAsTabelas}
                  filterArea={true}
                />
              </div>
  
              <div className='col-xl-12'>
                <CardDescricao
                  svgIcon='/media/icons/duotune/files/fil008.svg'
                  color='#2ECC71'
                  iconColor='white'
                  title='Aprovadas'
                  titleColor='white'
                  total={dados.DescricoesAprovadas}
                  chartValue={dados.DescricoesAprovadasPorcentagem}
                  descricaoType={DescricaoType.DescricoesAprovadas}
                  statusDescricao={EnumDescricaoStatusAprovacao.Aprovada}
                  btnTo=''
                  setDadosDash={setDados}
                  atualizarTodasAsTabelas={atualizarTodasAsTabelas}
                  setAtualizarTodasAsTabelas={setAtualizarTodasAsTabelas}
                  filterArea={true}
                  gerarRelatorio={true}
                />
              </div>
            </>
          ) : (
              <div className='col-xl-12'>
                <CardDescricao
                  svgIcon='/media/icons/duotune/general/gen005.svg'
                  color='#1ABC9C'
                  iconColor='white'
                  title='Descrições Aprovadas'
                  titleColor='white'
                  total={dados.DescricoesAprovadas}
                  chartValue={dados.DescricoesAprovadasPorcentagem}
                  descricaoType={DescricaoType.DescricoesAprovadas}
                  statusDescricao={EnumDescricaoStatusAprovacao.Aprovada}
                  btnTo=''
                  setDadosDash={obterDescricaoDados}
                  atualizarTodasAsTabelas={atualizarTodasAsTabelas}
                  setAtualizarTodasAsTabelas={setAtualizarTodasAsTabelas}
                  filterArea={true}
                  gerarRelatorio={true}
                />
              </div>
          )}
        </>
      }

      </div>
      {/* end::Row */}
    </>
  )
}

export {Dashboard, DescricaoType}
