import * as React from 'react'
import Box from '@mui/material/Box'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TablePagination from '@mui/material/TablePagination'
import TableRow from '@mui/material/TableRow'
import TableSortLabel from '@mui/material/TableSortLabel'
import Paper from '@mui/material/Paper'
import {visuallyHidden} from '@mui/utils'
import {useAlert, useHttpClient} from '../../../modules/services/Bundle'
import {RequestMethod} from '../../../modules/services/core/_enums'
import {Link} from 'react-router-dom'
import {KTSVG} from '../../../../_metronic/helpers'
import {IconButton} from '@mui/material'
import {ModalCargo} from './partials/ModalCargo'
import {ModalCarregarCargos} from './partials/ModalCarregarCargos'
import {useAuth} from '../../../modules/auth'
import { ISelectModel } from '../../../modules/services/core/_models'

interface Data {
  Id: number
  IdEmpresa: number
  IdNivelHierarquico: number
  IdArea: number
  Nome: string
  NomeArea: string
  NomeNivelHierarquico: string
  Descrito: boolean
  IdModelo: number | undefined
}

interface Column {
  id: keyof Data
  label: string
  minWidth?: number
  align?: 'center'
  format?: (value: number) => string
}

interface Filtros {
  IdArea?: number
  IdNivelHierarquico?: number
}

const columns: readonly Column[] = [
  {
    id: 'Nome',
    label: 'CARGO',
  },
  {
    id: 'NomeArea',
    label: 'ÁREA',
  },
  {
    id: 'NomeNivelHierarquico',
    label: 'NÍVEL HIERÁRQUICO',
  },
]

function createData(
  Id: number,
  IdEmpresa: number,
  IdNivelHierarquico: number,
  IdArea: number,
  Nome: string,
  NomeArea: string,
  NomeNivelHierarquico: string,
  Descrito: boolean,
  IdModelo: number | undefined
): Data {
  return {Id, IdEmpresa, IdNivelHierarquico, IdArea, Nome, NomeArea, NomeNivelHierarquico, Descrito, IdModelo}
}

type Order = 'asc' | 'desc'

interface EnhancedTableProps {
  onRequestSort: (event: React.MouseEvent<unknown>, property: keyof Data) => void
  order: Order
  orderBy: string
  moduloDescricaoAtivo: boolean
}

function EnhancedTableHead(props: EnhancedTableProps) {
  const {order, orderBy, onRequestSort} = props
  const createSortHandler = (property: keyof Data) => (event: React.MouseEvent<unknown>) => {
    onRequestSort(event, property)
  }

  return (
    <TableHead>
      <TableRow className='table-header'>
        {columns.map((columns) => (
          <TableCell
            className='table-cell'
            key={columns.id}
            align={columns.align ? 'right' : 'left'}
          >
            <TableSortLabel
              className='fw-bolder'
              active={orderBy === columns.id}
              direction={orderBy === columns.id ? order : 'asc'}
              onClick={createSortHandler(columns.id)}
            >
              {columns.label}
              {orderBy === columns.id ? (
                <Box component='span' sx={visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
        {props.moduloDescricaoAtivo && (
          <TableCell className='table-cell' key={'Descrito'} align={'left'}>
            <TableSortLabel
              className='fw-bolder'
              active={orderBy === 'Descrito'}
              direction={orderBy === 'Descrito' ? order : 'asc'}
              onClick={createSortHandler('Descrito')}
            >
              {'DESCRITO'}
              {orderBy === 'Descrito' ? (
                <Box component='span' sx={visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        )}
        <TableCell className='table-cell fw-bolder' align='center'>
          AÇÕES
        </TableCell>
      </TableRow>
    </TableHead>
  )
}

interface CargosProps {
  isConsultoria: boolean
}

const Cargos = ({ isConsultoria }: CargosProps) => {
  const httpClient = useHttpClient()
  const [order, setOrder] = React.useState<Order>('asc')
  const [orderBy, setOrderBy] = React.useState<keyof Data>('Nome')
  const [page, setPage] = React.useState(0)
  const [rowsPerPage, setRowsPerPage] = React.useState(10)
  const [total, setTotal] = React.useState(0)
  const [rows, setRows] = React.useState<Data[]>([])
  const [filtros, setFiltros] = React.useState<Filtros>({
    IdArea: undefined,
    IdNivelHierarquico: undefined,
  })
  const [areas, setAreas] = React.useState<any[]>([])
  const [niveisHierarquicos, setNiveisHierarquicos] = React.useState<any[]>([])
  const [abrirModalCargo, setAbrirModalCargo] = React.useState(false)
  const [abrirModalCarregarCargos, setAbrirModalCarregarCargos] = React.useState(false)
  const [visualizar, setVisualizar] = React.useState<boolean>(false)
  const [currentCargo, setCurrentCargo] = React.useState<Data | undefined>(undefined)
  const alert = useAlert()
  const {auth} = useAuth()
  const servico = auth?.servico
  const moduloDescricaoConfig = servico?.moduloDescricaoConfig
  const [empresasCliente, setEmpresasCliente] = React.useState<ISelectModel[]>([])
  const [idEmpresaClienteSelecionada, setIdEmpresaClienteSelecionada] = React.useState<number>(0)

  const resquestTable = (
    pageRq: number,
    orderByRq: string,
    orderRq: string,
    rowsPerPageRq: number,
    Filtros: Filtros
  ) => {
    httpClient
      .request({
        method: RequestMethod.POST,
        endpoint: '/Gerenciamento/Cargo/Listar',
        queryObject: {
          isConsultoria: isConsultoria,
          idEmpresa: idEmpresaClienteSelecionada
        },
        data: {
          Order: orderRq,
          OrderBy: orderByRq,
          Page: pageRq,
          RowsPerPage: rowsPerPageRq,
          Filters: Filtros,
        },
      })
      .then((result) => {
        const newRows = result.payload.data.map((data: Data) => {
          return createData(
            data.Id,
            data.IdEmpresa,
            data.IdNivelHierarquico,
            data.IdArea,
            data.Nome,
            data.NomeArea,
            data.NomeNivelHierarquico,
            data.Descrito,
            data.IdModelo
          )
        })
        setRows([...newRows])
        setTotal(result.payload.total)
      })
  }

  const obterDados = async () => {    
    const response = await httpClient.request({
      method: RequestMethod.GET,
      endpoint: '/Gerenciamento/Cargo/ObterDadosSelect',
      queryObject: {
        isConsultoria: isConsultoria,
        idEmpresa: idEmpresaClienteSelecionada
      },
    })

    if (response.success && response.payload) {
      const dados = response.payload
      if (dados.Areas && dados.Areas.length > 0) setAreas(dados.Areas)
      if (dados.NiveisHierarquicos && dados.NiveisHierarquicos.length > 0) setNiveisHierarquicos(dados.NiveisHierarquicos)
    }

    obterDadosTabela()
  }

  React.useEffect(() => {
    carregarDados()
  },[isConsultoria])

  React.useEffect(() => {
    setFiltros({
      IdArea: undefined,
      IdNivelHierarquico: undefined,
    })
    setAreas([])
    setNiveisHierarquicos([])
    setRows([])
    if (isConsultoria && idEmpresaClienteSelecionada > 0) obterDados()
  },[idEmpresaClienteSelecionada])

  const carregarDados = async () => {
    if (isConsultoria) await obterEmpresasCliente()
    else await obterDados()
  }

  const obterEmpresasCliente = async () => {
    const response = await httpClient.request({
      method: RequestMethod.GET,
      endpoint: '/DescricaoDeCargo/Consultoria/ObterClientesParaSelect',
    })

    if(response.success && response.payload) {
      setEmpresasCliente(response.payload)
      if(response.payload.length > 0) setIdEmpresaClienteSelecionada(response.payload[0].Id)
    }
  }

  const obterDadosTabela = () => {
    resquestTable(page, orderBy, order, rowsPerPage, filtros)
  }

  const obterStatusUpload = async () => {
    return await httpClient.request({
      method: RequestMethod.GET,
      endpoint: '/Gerenciamento/Cargo/ObterStatusUpload',
      queryObject: {
        isConsultoria: isConsultoria,
        idEmpresa: idEmpresaClienteSelecionada
      },
    })
  }

  const handleRequestSort = (event: React.MouseEvent<unknown>, property: keyof Data) => {
    const isAsc = orderBy === property && order === 'asc'
    setOrder(isAsc ? 'desc' : 'asc')
    setOrderBy(property)
    resquestTable(page, property, isAsc ? 'desc' : 'asc', rowsPerPage, filtros)
  }

  const handleChangePage = (event: unknown, newPage: number) => {
    resquestTable(newPage, orderBy, order, rowsPerPage, filtros)
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    resquestTable(page, orderBy, order, parseInt(event.target.value, 10), filtros)
    setPage(0)
  }

  const emptyRows = rows.length

  return (
    <>
      <div className='card mb-5 mb-xl-10'>
        <div className='card-header'>
          <div className='card-title m-0'>
            <h3 className='fw-bolder m-0'>Cargos</h3>
          </div>
          <div className='card-toolbar'>
            {(auth?.roles.find((x) => x === 'Master') !== undefined ||
              auth?.roles.find((x) => x === 'Administrador') !== undefined ||
              auth?.permissoes.find((x) => x === (isConsultoria ? '53BC5B332B31F2A4C3803B755C118521' : '80C85513915640A5D62B7412730A10BD')) !==
                undefined) && (
              <button
                type='button'
                className='btn btn-light-primary me-5'
                onClick={() => {
                  setAbrirModalCarregarCargos(true)
                }}
              >
                <KTSVG
                  path='/media/icons/duotune/files/fil013.svg'
                  className='svg-icon-muted svg-icon-1'
                />
                Carregar
              </button>
            )}
            {(auth?.roles.find((x) => x === 'Master') !== undefined ||
              auth?.roles.find((x) => x === 'Administrador') !== undefined ||
              auth?.permissoes.find((x) => x === (isConsultoria ? '0454E3884545EA4F94BC63C7449ADCDB' : '522125E0C951A53EB4A52BC370AD86EC')) !==
                undefined) && (
              <button
                type='button'
                id="adicionar-cargo"
                className='btn btn-light-primary'
                onClick={() => {
                  obterStatusUpload().then((response) => {
                    if (response.success) {
                      setCurrentCargo(undefined)
                      setVisualizar(false)
                      setAbrirModalCargo(true)
                    }
                  })
                }}
              >
                <KTSVG
                  path='/media/icons/duotune/arrows/arr009.svg'
                  className='svg-icon-muted svg-icon-1'
                />
                Adicionar
              </button>
            )}
          </div>
        </div>
        <div className='card-body p-9'>
          <div className='row'>
            {(isConsultoria) && (
              <>
                  <div className='col-lg-4 mb-5'>
                    <label className='form-label'>Cliente</label>
                    <select
                      className='form-select form-select-lg form-select-solid'
                      value={idEmpresaClienteSelecionada}
                      onChange={(e: React.ChangeEvent<HTMLSelectElement>) => { setIdEmpresaClienteSelecionada(parseInt(e.target.value)) }}
                    >
                      <option value={0}>Selecione...</option>
                      {empresasCliente && empresasCliente.length > 0 && empresasCliente.map((data: ISelectModel, index: number) => {
                          return (
                            <option key={`cliente-option-${index}`} value={data.Id}>
                              {data.Nome}
                            </option>
                          )
                      })}
                    </select>
                  </div>
              </>
            )}  
            <div className={`${isConsultoria ? 'col-lg-8' : 'col-lg-12'} mb-5`}>
              <div className={`d-flex justify-content-end ${isConsultoria ? 'mt-8' : ''}`}>
                <>
                  {/* begin::Filter Button */}
                  <button
                    type='button'
                    className='btn btn-light-primary'
                    data-kt-menu-trigger='click'
                    data-kt-menu-placement='bottom-end'
                  >
                    <i className='fs-2 fas fa-arrow-down-wide-short'></i>
                    Filtros
                  </button>
                  {/* end::Filter Button */}
                  {/* begin::SubMenu */}
                  <div
                    className='menu menu-sub menu-sub-dropdown w-300px w-md-325px'
                    data-kt-menu='true'
                  >
                    {/* begin::Header */}
                    <div className='px-7 py-5'>
                      <div className='fs-5 text-dark fw-bolder'>Opções</div>
                    </div>
                    {/* end::Header */}

                    {/* begin::Separator */}
                    <div className='separator border-gray-200'></div>
                    {/* end::Separator */}

                    {/* begin::Content */}
                    <div className='px-7 py-5' data-kt-user-table-filter='form'>
                      {/* begin::Input group */}
                      <div className='mb-10'>
                        <label className='form-label fs-6 fw-bold'>Áreas:</label>
                        <select
                          className='form-select form-select-solid fw-bolder'
                          data-kt-select2='true'
                          data-placeholder='Select option'
                          data-allow-clear='true'
                          data-kt-user-table-filter='role'
                          data-hide-search='true'
                          onChange={(e) => {
                            let newFiltros = filtros
                            newFiltros.IdArea = e.target.value ? parseInt(e.target.value) : undefined
                            setFiltros(newFiltros)
                          }}
                        >
                          <option value=''>Selecione...</option>
                          {areas &&
                            areas.length > 0 &&
                            areas.map((data: any, index: any) => {
                              return (
                                <option key={`IdArea-option-${index}`} value={data.Id}>
                                  {data.Nome}
                                </option>
                              )
                            })}
                        </select>
                      </div>
                      {/* end::Input group */}

                      {/* begin::Input group */}
                      <div className='mb-10'>
                        <label className='form-label fs-6 fw-bold'>Níveis Hierárquicos:</label>
                        <select
                          className='form-select form-select-solid fw-bolder'
                          data-kt-select2='true'
                          data-placeholder='Select option'
                          data-allow-clear='true'
                          data-kt-user-table-filter='role'
                          data-hide-search='true'
                          onChange={(e) => {
                            let newFiltros = filtros
                            newFiltros.IdNivelHierarquico = e.target.value
                              ? parseInt(e.target.value)
                              : undefined
                            setFiltros(newFiltros)
                          }}
                        >
                          <option value=''>Selecione...</option>
                          {niveisHierarquicos &&
                            niveisHierarquicos.length > 0 &&
                            niveisHierarquicos.map((data: any, index: any) => {
                              return (
                                <option key={`IdNivel-option-${index}`} value={data.Id}>
                                  {data.Nome}
                                </option>
                              )
                            })}
                        </select>
                      </div>
                      {/* end::Input group */}

                      {/* begin::Actions */}
                      <div className='d-flex justify-content-end'>
                        <button
                          type='button'
                          className='btn btn-light btn-active-light-primary fw-bold me-5 px-6'
                          data-kt-menu-dismiss='true'
                          data-kt-user-table-filter='reset'
                        >
                          Cancelar
                        </button>
                        <button
                          type='button'
                          className='btn btn-primary fw-bold px-6'
                          data-kt-menu-dismiss='true'
                          data-kt-user-table-filter='filter'
                          onClick={() => obterDadosTabela()}
                        >
                          Buscar
                        </button>
                      </div>
                      {/* end::Actions */}
                    </div>
                    {/* end::Content */}
                  </div>
                  {/* end::SubMenu */}
                </>
              </div>
            </div>   
          </div>          
          <form noValidate className='form'>
            <div>
              <Box sx={{width: '100%'}}>
                <Paper className='table-border ' sx={{width: '100%', mb: 2}}>
                  <TableContainer>
                    <Table
                      className='table-header'
                      sx={{minWidth: 750}}
                      aria-labelledby='tableTitle'
                    >
                      <EnhancedTableHead
                        order={order}
                        orderBy={orderBy}
                        onRequestSort={handleRequestSort}
                        moduloDescricaoAtivo={moduloDescricaoConfig?.ativo ?? false}
                      />
                      <TableBody>
                        {rows.map((row, index) => {
                          return (
                            <TableRow
                              className='table-row'
                              hover
                              tabIndex={-1}
                              key={index}
                              sx={{cursor: 'pointer'}}
                            >
                              <TableCell className='table-cell' align='left'>
                                {row.Nome}
                              </TableCell>
                              <TableCell className='table-cell' align='left'>
                                {row.NomeArea}
                              </TableCell>
                              <TableCell className='table-cell' align='left'>
                                {row.NomeNivelHierarquico}
                              </TableCell>
                              {moduloDescricaoConfig?.ativo && (
                                <TableCell className='table-cell' align='left'>
                                  {row.Descrito ? 'Sim' : 'Não'}
                                </TableCell>
                              )}
                              <TableCell className='table-cell' align='center'>
                                <IconButton
                                  className={`text-gray-800`}
                                  title='Visualizar'
                                  onClick={() => {
                                    setCurrentCargo(row)
                                    setVisualizar(true)
                                    setAbrirModalCargo(true)
                                  }}
                                >
                                  <svg
                                    xmlns='http://www.w3.org/2000/svg'
                                    width='16'
                                    height='16'
                                    fill='currentColor'
                                    className='bi bi-eye-fill'
                                    viewBox='0 0 16 16'
                                  >
                                    <path d='M10.5 8a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0z' />
                                    <path d='M0 8s3-5.5 8-5.5S16 8 16 8s-3 5.5-8 5.5S0 8 0 8zm8 3.5a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7z' />
                                  </svg>
                                </IconButton>
                                {(auth?.roles.find((x) => x === 'Master') !== undefined ||
                                  auth?.roles.find((x) => x === 'Administrador') !== undefined ||
                                  auth?.permissoes.find(
                                    (x) => x === (isConsultoria ? 'C84429771393FD3CDD683C271177633D' : 'D89F89B4E26368C2446516CAC938F461')
                                  ) !== undefined) && (
                                  <IconButton
                                    className={`text-gray-800`}
                                    title='Editar'
                                    onClick={() => {
                                      obterStatusUpload().then((response) => {
                                        if (response.success) {
                                          setCurrentCargo(row)
                                          setVisualizar(false)
                                          setAbrirModalCargo(true)
                                        }
                                      })
                                    }}
                                  >
                                    <svg
                                      xmlns='http://www.w3.org/2000/svg'
                                      width='16'
                                      height='16'
                                      fill='currentColor'
                                      className='bi bi-pencil-square'
                                      viewBox='0 0 16 16'
                                    >
                                      <path d='M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z' />
                                      <path
                                        fillRule='evenodd'
                                        d='M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z'
                                      />
                                    </svg>
                                  </IconButton>
                                )}
                                {(auth?.roles.find((x) => x === 'Master') !== undefined ||
                                  auth?.roles.find((x) => x === 'Administrador') !== undefined ||
                                  auth?.permissoes.find(
                                    (x) => x === (isConsultoria ? '3B95530F6611C58E0A94F2433620FAB8' : '7D551D8F74A2C01FAA69A035809A803C')
                                  ) !== undefined) && (
                                  <IconButton
                                    className={`text-gray-800`}
                                    title='Excluir'
                                    onClick={() => {
                                      alert.createDialog({
                                        html: `Realmente deseja excluir este cargo?`,
                                        confirmAction: async () => {
                                          const response = await httpClient.request({
                                            method: RequestMethod.DELETE,
                                            endpoint: '/Gerenciamento/Cargo/Deletar',
                                            data: row.Id,
                                          })

                                          if (response.success) obterDadosTabela()
                                        },
                                      })
                                    }}
                                  >
                                    <svg
                                      xmlns='http://www.w3.org/2000/svg'
                                      width='16'
                                      height='16'
                                      fill='currentColor'
                                      className='bi bi-trash-fill'
                                      viewBox='0 0 16 16'
                                    >
                                      <path d='M2.5 1a1 1 0 0 0-1 1v1a1 1 0 0 0 1 1H3v9a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V4h.5a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H10a1 1 0 0 0-1-1H7a1 1 0 0 0-1 1H2.5zm3 4a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 .5-.5zM8 5a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7A.5.5 0 0 1 8 5zm3 .5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 1 0z' />
                                    </svg>
                                  </IconButton>
                                )}
                              </TableCell>
                            </TableRow>
                          )
                        })}
                        {emptyRows === 0 && (
                          <TableRow>
                            <TableCell className='table-cell text-center' colSpan={6}>
                              Nenhum registro encontrado
                            </TableCell>
                          </TableRow>
                        )}
                      </TableBody>
                    </Table>
                  </TableContainer>
                  <TablePagination
                    className='table-footer'
                    labelRowsPerPage='linhas por página'
                    rowsPerPageOptions={[10, 25]}
                    component='div'
                    count={total}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                  />
                </Paper>
              </Box>
            </div>
          </form>
        </div>
        <div className='card-footer d-flex justify-content-end py-4'>
          <Link className='btn btn-secondary' to={`/inicio`}>
            Voltar
          </Link>
        </div>
      </div>

      {abrirModalCargo && (
        <ModalCargo
          isConsultoria={isConsultoria}
          empresasCliente={empresasCliente}
          descricaoTipo={
            !currentCargo
              ? 'Adicionar novo cargo'
              : visualizar
              ? 'Visualizar cargo'
              : 'Editar cargo'
          }
          abrirModal={abrirModalCargo}
          setAbrirModal={setAbrirModalCargo}
          obterDadosTabela={obterDadosTabela}
          visualizar={visualizar}
          currentCargo={currentCargo}
        />
      )}

      {abrirModalCarregarCargos && (
        <ModalCarregarCargos
          isConsultoria={isConsultoria}
          empresasCliente={empresasCliente}
          abrirModal={abrirModalCarregarCargos}
          setAbrirModal={setAbrirModalCarregarCargos}
        />
      )}
    </>
  )
}

export {Cargos}
