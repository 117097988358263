/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useCallback, useEffect, useState} from 'react'
import {Field, ErrorMessage, FormikProps} from 'formik'
import { IWizardModel } from '../helpers/ConfiguracoesIniciaisHelper'
import { DropzoneComponent, useHelper } from '../../../../modules/services/Bundle'
import { AbrirModalVideo } from '../../../toolbar/AbrirModalVideo'
import Cropper, { Size } from 'react-easy-crop'
import getCroppedImg from '../partials/cropImage'
import { useAuth } from '../../../../modules/auth'
import $ from 'jquery';
import { EnumPorteEmpresa } from '../../../../modules/services/core/_enums'

interface Step1Props {
  formik: FormikProps<IWizardModel>,
  segmentos: any[],
  setSegmentos: React.Dispatch<React.SetStateAction<any[]>>,
}

const Step1: FC<Step1Props> = ({formik, segmentos, setSegmentos}) => {
  const helper = useHelper()  
  const [srcLogo, setSrcLogo] = useState("")
  const [crop, setCrop] = useState({ x: 0, y: 0 })
  const [cropSize, setCropSize] = useState<Size>({width: 0, height: 0})
  const [zoom, setZoom] = useState(1)
  const [aspect] = useState(120)
  const [abrirCrop, setAbrirCrop] = useState(false)
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const [showNomeSegmento, setShowNomeSegmento] = useState<boolean>(false);
  const {auth, saveAuth} = useAuth()

  const onCropComplete = useCallback((croppedArea:any, newcroppedAreaPixels:any) => {
    setCroppedAreaPixels(newcroppedAreaPixels)
  }, [])

  useEffect(() => {
    if (auth && formik.values.empresaLogo) {
      let newAuth = {...auth}
      const empresaLogo = formik.values.empresaLogo
      const base64 = empresaLogo.Base64
      const tipo = empresaLogo.Tipo

      let urlLogoAtt = "data:" + tipo + ";base64," + base64
      newAuth.user.logoDataUrl = urlLogoAtt
      saveAuth(newAuth)
    }
  },[formik.values.empresaLogo])

  const filesHandler = async (files: Dropzone.DropzoneFile[]) => { 
    if(files && files.length > 0){

      const file = files[0]
      const base64 = await helper.convertFileToBase64(file)

      if (auth?.user.logoDataUrl != "data:" + file.type + ";base64," + base64) {
        setAbrirCrop(true)
      }
      
      formik.setFieldValue('empresaLogo', {
        Nome: file.name,
        Extensao: file.name.split('.').pop(),
        Tamanho: file.size,
        Tipo: file.type,
        Base64: base64
      })
      
      setSrcLogo("data:" + file.type + ";base64," + base64)

    }
    else{
      if (auth) {
        let newAuth = {...auth}
        newAuth.user.logoDataUrl = ""
        saveAuth(newAuth) 
      }
      formik.setFieldValue('empresaLogo', undefined)
    }
  }

  const cancelarCrop = () => {
    $('.dz-remove')[0].click()
    setAbrirCrop(false)
  };

  const onCrop = async () => {
    
    await getCroppedImg(srcLogo, croppedAreaPixels, formik, helper.convertFileToBase64)

    setAbrirCrop(false)

  };

  return (
    <>
      <AbrirModalVideo
        videoPath='https://pro4tech.blob.core.windows.net/visualstuff/Wizard%20Empresa.mp4?si=maintaining&spr=https&sv=2022-11-02&sr=b&sig=5ny7q%2BZSat0n73Knhp6tYH35zBhNWd32FAvRAp6XPJc%3D'
        videoTitle='Parâmetros da Empresa'
        descricao='Inicialmente, deve-se configurar as informações de sua empresa, forneça os dados necessários para poder continuar para os próximos passos.'
      />

      <div className={`modal ${abrirCrop? "show" : ""} fade`} tabIndex={-1} id="kt_modal_crop"  style={{display: `${abrirCrop? "block" : "none"}`}}>
        <div className="modal-dialog modal-dialog-centered modal-xl">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Ajuste sua logo</h5>
            </div>
            <div className="modal-body mx-5">
              <div className='m-20 p-20'>
                <Cropper
                  image={srcLogo}
                  crop={crop}
                  zoom={zoom}
                  aspect={460 / aspect}
                  onCropChange={setCrop}
                  onCropComplete={onCropComplete}
                  onZoomChange={setZoom}
                  setCropSize={setCropSize}
                  restrictPosition={false}
                />
              </div>
            </div>
            <div className="modal-footer ">
              <div className="controls col-xl-12 mb-5 d-flex justify-content-center w-100">
                <div className='w-50 controls d-flex justify-content-center'>
                  <span>Zoom:</span>
                  <input
                    type="range"
                    value={zoom}
                    min={0.01}
                    max={5}
                    step={0.1}
                    aria-labelledby="Zoom"
                    onChange={(e) => {
                      setZoom(parseFloat(e.target.value))
                    }}
                    className="zoom-range ms-2"
                  />
                </div>
              </div>
              
              <div className='col-xl-12 border-top'>
               
              </div>
              <div className='col-xl-12 d-flex justify-content-end'>
                <button type="button" onClick={() => setAbrirCrop(false)} className="btn btn-secondary me-5">
                  Cancelar
                </button>
                <button type="button" onClick={() => onCrop()} className="btn btn-primary">
                  Salvar
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className='w-100'>

        <div className='min-h-375px'>

          <div className="row">

            <div className='col-lg-12 fv-row mb-5'>
              <label className='form-label required'>Razão Social</label>
              <Field 
                name='empresaRazaoSocial'
                placeholder='Nome'
                className='form-control form-control-lg form-control-solid'
                value={formik.values.empresaRazaoSocial}
              />
              <div className='text-danger mt-2'>
                <ErrorMessage name='empresaRazaoSocial' />
              </div>
            </div>

            <div className='col-lg-12 fv-row mb-5'>
              <label className='form-label required'>Segmento de mercado</label>
              <Field
                as='select'
                name='empresaSegmento'
                className='form-select form-select-lg form-select-solid'
                value={formik.values.empresaSegmento}
                onChange={(event: { target: { value: number } }) => {
                  formik.handleChange(event);
                  // Aqui você pode acessar o valor selecionado através de event.target.value
                  setShowNomeSegmento(event.target.value == 2);
                }}
              >
                <option value=''>Selecione...</option>
                {
                  segmentos && segmentos.length > 0 ? segmentos.map((data: any, index: any) => {
                    return <option key={`empresaSegmento-option-${index}`} value={data.Id}>{data.Nome}</option>
                  }) : ''
                }
              </Field>
              <div className='text-danger mt-2'>
                <ErrorMessage name='empresaSegmento' />
              </div>
            </div>

            {(showNomeSegmento || formik.values.empresaSegmento == 2) && (
                <div className="col-lg-12 fv-row mb-5">
                  <label className='form-label required'>Nome do Segmento:</label>
                  <Field
                      name={`empresaNomeSegmento`}
                      placeholder='Segmento de mercado'
                      className='form-control form-control-lg form-control-solid'
                      value={formik.values.empresaNomeSegmento}
                  />
                  <div className='text-danger mt-2'>
                    <ErrorMessage name='empresaNomeSegmento' />
                </div>
                </div>
              )}

            <div className='col-lg-6 fv-row mb-5'>
              <label className='form-label'>Porte da empresa</label>
              <Field
                as='select'
                name='PorteEmpresa'
                className='form-select form-select-lg form-select-solid'
                value={formik.values.PorteEmpresa}
              >
                <option value='0'>Selecione...</option>
                {Object.keys(EnumPorteEmpresa).map(key => (
                  
                  parseFloat(key) > 0 && (
                    <option key={key} value={key}>
                      {EnumPorteEmpresa[parseFloat(key)]}
                    </option>
                  )
                  
                ))}
              </Field>
            </div>
            <div className="col-lg-6 fv-row mb-5">
              <label className='form-label'>Quantidade de funcionários</label>
              <Field
                type={`number`}
                name={`QtdFuncionarios`}
                placeholder='Quantidade de funcionários'
                className='form-control form-control-lg form-control-solid'
                value={formik.values.QtdFuncionarios}
              />
            </div>

            <div className='col-lg-12 fv-row'>
              <label className='form-label'>Logo</label>
              <DropzoneComponent 
                filesHandler={filesHandler}
                id='dz-empresa-logo'
                preloadFiles={formik.values.empresaLogo}
                options={{ 
                  acceptedFiles: 'image/jpeg,image/png',
                  maxFiles: 1,
                  maxFilesize: 2
                }}
              />
            </div>

          </div>

        </div>
        
      </div>
    </>
  )
}

export {Step1}
