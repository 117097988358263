/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react'
import { useAlert, useBlockUI, useHttpClient } from '../../../modules/services/Bundle'
import { RequestMethod } from '../../../modules/services/core/_enums'
import { ErrorMessage, Field, FormikProvider, useFormik } from 'formik'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { defaultValidationSchemas, initialValues, initialValuesDescricao } from './helpers/CrudEscalaHelper'
import { IconButton } from '@mui/material'
import { KTSVG } from '../../../../_metronic/helpers'

interface dados {
  idEscala: number
  visualizar: boolean
  title: string
}


const CrudEscala = () => {
  const httpClient = useHttpClient()
  const navigate = useNavigate();
  const {setBlocking} = useBlockUI()
  const alert = useAlert()
  const location = useLocation();
  const [currentSchema, setCurrentSchema] = useState(defaultValidationSchemas[0]);
  const state = location.state as dados
  const informacao: dados = state
  const [color, setColor] = useState("");
  
  const formik = useFormik({
      initialValues: {...initialValues},
      validationSchema: currentSchema,
      onSubmit: async (values, actions) => { 
        var firstColor = values.EscalaDescricao?.[0]?.Cor;
        var invalidColors = values.EscalaDescricao!.length > 1 && values.EscalaDescricao?.every(x => x.Cor === firstColor);
        if(invalidColors){
          return alert.createMessage({
            html: `As cores das escalas devem ser diferentes!`,
          })
        }
        httpClient.request({
          method: RequestMethod.PUT,
          endpoint: `/Pesquisa/Escala/AdicionarEditarEscala`,
          data: values,
          ifSuccessRedirectTo: '/pesquisa-ia/escalas'
        })
      },
  })

  const obterCrudEscala = async () => {
    
    const response = await httpClient.request({
      method: RequestMethod.GET,
      endpoint: '/Pesquisa/Escala/ObterPorId',
      queryObject: {
        idEscala: informacao.idEscala,
      },
      blockFree: true
    })
    
    if(response.success && response.payload){          
      const CrudEscala = response.payload;
      
      formik.setFieldValue('Nome', CrudEscala.Nome)
      formik.setFieldValue('Id', CrudEscala.Id)
      formik.setFieldValue('EscalaDescricao', CrudEscala.EscalaDescricao)
    }

  }

  const obterDados = async() => {
    setBlocking(true)

    if(informacao && informacao.idEscala){
      await obterCrudEscala()
    }

    setBlocking(false)
  }

  useEffect(() => {
    obterDados()
  },[informacao])

  const onClickAdicionarDescricao = async () => {
    if (formik.isValid) {
        const newValues = formik.values
        newValues.EscalaDescricao!.push(initialValuesDescricao)
        formik.setValues(newValues)
    } else {
      alert.createMessage({
        html: `Deve preencher todos os dados antes de adicionar um novo campo!`,
      })
    }

  }

  const onClickRemoverDescricao = (index:number) => {
    const newValues = formik.values
    if (newValues.EscalaDescricao!.length > 1) {
      newValues.EscalaDescricao!.splice(index, 1)
      formik.setValues(newValues)
    } else{
      alert.createMessage({
        html: `Deve haver ao menos uma descrição!`,
      })
    }
  }

  return (
    <>
      <ol className='breadcrumb breadcrumb-line text-muted fs-6 mt-0 fw-semibold mb-10'>
      <li className='breadcrumb-item h2 hover-link-name-dash text-gray-600'><Link className="text-gray-600" to='/pesquisa-ia/inicio'>Pesquisa</Link></li>
        <li className='breadcrumb-item h2 hover-link-name-dash text-gray-600'><Link className="text-gray-600" to='/pesquisa-ia/escalas'>Escalas</Link></li>
        <li className='breadcrumb-item h2 text-dark'>Criar</li>
      </ol>
    <div className='card mb-5 mb-xl-10'>

      <div
        className='card-header'
      >
        <div className='card-title m-0'>
          <h3 className='fw-bolder m-0'>{informacao != null && informacao.title ? informacao.title : "Adicionar"} Escala</h3>
        </div>
      </div>

      <div
        className='card-body'
      >
        <FormikProvider value={formik}>
          <div className='w-100'>
            <form 
              noValidate
            >
              <div className="row container">
                <div className="row">
                  <div className="col-lg-8 fv-row mb-5">
                    <label className='form-label required fs-3'>Nome da Escala</label>
                        <Field
                            name={`Nome`}
                            placeholder='Nome'
                            disabled={informacao != null && informacao.visualizar}
                            className='form-control form-control-lg form-control-solid'
                        />
                        <div className='text-danger mt-2'>
                          <ErrorMessage name='Nome' />
                        </div> 
                  </div>  
                  <div className='w-100 mb-5 scroll scroll-height-step-4 h-475px'>
                    <div key={`accordion-descricoes`} id={`accordion-descricoes`} className=' w-100'>
                        <div className={`card card-custom border border-secondary border-1 shadow-sm titulo-pesquisa-nao-selecionado`}>
                        <div className="card-header bg-pesquisa border-bottom border-secondary border-1 rounded-bottom" id={`card-titulo-header-descricoes`}>
                            <div className="card-title">
                            <h2 className='text-white'>Intervalo de notas</h2>                                
                            </div>
                            <div className="card-toolbar w-md-auto" hidden={informacao != null && informacao.visualizar}>     
                                <button type="button" onClick={() => onClickAdicionarDescricao()} className="btn btn-icon btn-sm btn-accordion btn-light">
                                <i className='fas fa-plus fs-1'></i>
                                </button>
                            </div>
                        </div>
                        <div id={`card-titulo-collapse-descricoes`} className="collapse show" aria-labelledby={`card-titulo-header-descricoes`} data-bs-parent={`#accordion-descricoes`}>
                            <div className="card-body">
                              {formik.values.EscalaDescricao && formik.values.EscalaDescricao.length > 0 && (
                                <div>
                                  <div className='row'>
                                    <div className='col-lg-2 mb-5 text-center fv-row'>
                                      <label className='form-label required fs-3'>De</label>
                                    </div>
                                    <div className='col-lg-2 mb-5 text-center fv-row'>
                                      <label className='form-label required fs-3'>Até</label>
                                    </div>
                                    <div className='col-lg-6 mb-5 fv-row'>
                                      <label className='form-label required fs-3'>Descrição</label>
                                    </div>
                                    <div className='col-lg-1 mb-5 fv-row'>
                                      <label className='form-label required fs-3'>Cor</label>
                                    </div>
                                  </div>

                                  {formik.values.EscalaDescricao.map((descricao, index) => (
                                    <div key={`descricao-${index}`} className={`row`}>
                                      <div className='col-lg-2 mb-5 text-center fv-row'>
                                        <Field
                                          name={`EscalaDescricao.${index}.Inicio`}
                                          placeholder='Início'
                                          className='form-control form-control-lg form-control-solid text-center'
                                          value={descricao.Inicio}
                                          type='number'
                                          disabled={informacao != null && informacao.visualizar}
                                          min={formik.values.EscalaDescricao?.length === 1 ? 0 : formik.values.EscalaDescricao!.filter((item, ix) => ix < index).reduce((max, item) => (item!.Fim ?? 0 > max ? (item!.Fim ? item.Fim + 1 : 0) : max), Number.MIN_SAFE_INTEGER)}
                                        />
                                        <div className='text-danger mt-2'>
                                          <ErrorMessage name={`EscalaDescricao.${index}.Inicio`} />
                                        </div>
                                      </div>
                                      <div className='col-lg-2 mb-5 text-center fv-row'>
                                        <Field
                                          name={`EscalaDescricao.${index}.Fim`}
                                          placeholder='Fim'
                                          className='form-control form-control-lg form-control-solid text-center'
                                          value={descricao.Fim}
                                          type='number'
                                          disabled={informacao != null && informacao.visualizar}
                                          min={descricao.Inicio}
                                        />
                                        <div className='text-danger mt-2'>
                                          <ErrorMessage name={`EscalaDescricao.${index}.Fim`} />
                                        </div>
                                      </div>

                                      <div className='col-lg-6 mb-5 text-end fv-row'>
                                        <Field
                                          name={`EscalaDescricao.${index}.Descricao`}
                                          maxLength={60}
                                          placeholder='Descrição do Intervalo'
                                          className='form-control form-control-lg form-control-solid'
                                          value={descricao.Descricao}
                                          disabled={informacao != null && informacao.visualizar}
                                        />
                                        <div className='text-danger mt-2'>
                                          <ErrorMessage name={`EscalaDescricao.${index}.Descricao`} />
                                        </div>
                                      </div>

                                      <div className='col-lg-1 mb-5 text-end fv-row'>
                                        <Field
                                          type="color"
                                          name={`EscalaDescricao.${index}.Cor`}
                                          className='form-control form-control-lg form-control-solid input-color-field-class'
                                          value={descricao.Cor}
                                          disabled={informacao != null && informacao.visualizar}
                                        />
                                        <div className='text-danger mt-2'>
                                          <ErrorMessage name={`EscalaDescricao.${index}.Cor`} />
                                        </div>
                                      </div>
                                      <div className='col-lg-1 mb-5 mt-5 d-flex align-items-center justify-content-end'>
                                        <IconButton hidden={informacao != null && informacao.visualizar} className='icon-button-dark-hover' onClick={() => onClickRemoverDescricao(index)} color="primary" aria-label="upload picture" component="label"
                                        >
                                        
                                          {<KTSVG path="/media/icons/duotune/general/gen034.svg" className="svg-icon-muted svg-icon-2hx text-danger " />}
                                        </IconButton>
                                      </div>
                                    </div>
                                  ))}
                                </div>
                              )}
                            </div>   
                        </div>                         
                        </div>
                    </div>
                    </div>
                </div>
              </div>
              
            </form>
          </div>
        </FormikProvider>
      </div>
      <div className="card-footer d-flex justify-content-end py-4">        
        <button className='btn btn-secondary' onClick={() => navigate(-1)}>
              Voltar
        </button>
        <button
          type='button'
          onClick={() => formik.handleSubmit()}
          className={`btn btn-lg btn-pesquisa ms-5 ${informacao != null && informacao.visualizar ? "d-none" : ""}`}
        >
          Salvar
        </button>
      </div>
    </div>
    </>
  )
}

export {CrudEscala}

