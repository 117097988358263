import { useCallback, useEffect, useState } from 'react'
import { DropzoneComponent, Tooltip, useAlert, useHelper, useHttpClient } from '../../../modules/services/Bundle'
import { EnumPorteEmpresa, RequestMethod } from '../../../modules/services/core/_enums'
import { ErrorMessage, Field, FormikProvider, useFormik } from 'formik'
import InputMask from 'react-input-mask'
import Cropper, { Size } from 'react-easy-crop'
import { useAuth } from '../../../modules/auth'
import $ from 'jquery';
import { defaultValidationSchemas, initValues } from './helpers/EmpresaHelper'
import getCroppedImg from './partials/cropImage'
import { useLocation, useNavigate } from 'react-router-dom'

interface dados {
  idEmpresa: number
}

const Empresa = () => {
  const [indexEnderecoPrincipal, setIndexEnderecoPrincipal] = useState(0)
  const [indexEnderecoCobrancaNaoPrincipal, setIndexEnderecoCobrancaNaoPrincipal] = useState<number | undefined>(undefined)
  const [utilizaEnderecoPricipalParaCobranca, setUtilizaEnderecoPricipalParaCobranca] = useState(true)
  const httpClient = useHttpClient()
  const viaCepClient = useHttpClient("https://viacep.com.br/ws/")
  const [segmentos, setSegmentos] = useState<any[]>([])
  const [showNomeSegmento, setShowNomeSegmento] = useState<boolean>(false);
  const [srcLogo, setSrcLogo] = useState("")
  const [crop, setCrop] = useState({ x: 0, y: 0 })
  const [cropSize, setCropSize] = useState<Size>({width: 0, height: 0})
  const [zoom, setZoom] = useState(1)
  const [aspect, setAspect] = useState(120)
  const [abrirCrop, setAbrirCrop] = useState(false)
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const {auth, saveAuth} = useAuth()
  const helper = useHelper()
  const location = useLocation();
  const navigate = useNavigate()
  const state = location.state as dados
  const informacao: dados = state

  const onCropComplete = useCallback((croppedArea:any, newcroppedAreaPixels:any) => {
    setCroppedAreaPixels(newcroppedAreaPixels)
  }, [])
  
  const formik = useFormik({
      initialValues: {...initValues},
      validationSchema: defaultValidationSchemas,
      onSubmit: async (values, actions) => {
        
        if (values.EmpresaEndereco.length === 1) values.EmpresaEndereco[indexEnderecoPrincipal].EnderecoCobranca = true
        if(values.NomeSegmento){
          httpClient.request({
            method: RequestMethod.GET,
            endpoint: '/Gerenciamento/Segmento/ExisteSegmento',
            queryObject:{
              nomeSegmento: values.NomeSegmento
            }
          }).then((response) => {
            if(response.success){
              httpClient.request({
                method: RequestMethod.PUT,
                endpoint: '/Gerenciamento/Empresa/Editar',
                data: values,
                ifSuccessRedirectTo: informacao.idEmpresa ? '/administracao/empresas' : '/inicio'
              })
            }
          })
        } else {
          httpClient.request({
            method: RequestMethod.PUT,
            endpoint: '/Gerenciamento/Empresa/Editar',
            data: values,
            ifSuccessRedirectTo: informacao.idEmpresa ? '/administracao/empresas' : '/inicio'
          })
        }
      },
  })

  const onChangeCepEndPrincipal = async (e:any) => {
    const cep = e.target.value.replace(/[^0-9,.]+/g, "")
    formik.setFieldValue(`EmpresaEndereco.${indexEnderecoPrincipal}.Cep`, cep)

    if (cep.length === 8) {

      const response = await viaCepClient.request({
        method: RequestMethod.GET,
        endpoint: cep + '/json'
      })

      if (response.success && response.payload) {
        formik.setFieldValue(`EmpresaEndereco.${indexEnderecoPrincipal}.Cidade`, response.payload.localidade)
        formik.setFieldValue(`EmpresaEndereco.${indexEnderecoPrincipal}.Estado`, response.payload.uf)
        formik.setFieldValue(`EmpresaEndereco.${indexEnderecoPrincipal}.Endereco`, response.payload.logradouro)
        formik.setFieldValue(`EmpresaEndereco.${indexEnderecoPrincipal}.Bairro`, response.payload.bairro)
      }
    }
  }

  const onChangeCepEndCobranca = async (e:any) => {
    const cep = e.target.value.replace(/[^0-9,.]+/g, "")
    formik.setFieldValue(`EmpresaEndereco.${indexEnderecoCobrancaNaoPrincipal}.Cep`, cep)

    if (cep.length === 8) {

      const response = await viaCepClient.request({
        method: RequestMethod.GET,
        endpoint: cep + '/json'
      })

      if (response.success && response.payload) {
        formik.setFieldValue(`EmpresaEndereco.${indexEnderecoCobrancaNaoPrincipal}.Cidade`, response.payload.localidade)
        formik.setFieldValue(`EmpresaEndereco.${indexEnderecoCobrancaNaoPrincipal}.Estado`, response.payload.uf)
        formik.setFieldValue(`EmpresaEndereco.${indexEnderecoCobrancaNaoPrincipal}.Endereco`, response.payload.logradouro)
        formik.setFieldValue(`EmpresaEndereco.${indexEnderecoCobrancaNaoPrincipal}.Bairro`, response.payload.bairro)
      }
    }
  }

  const filesHandler = async (files: Dropzone.DropzoneFile[]) => { 
    if(files && files.length > 0){

      const file = files[0]
      const base64 = await helper.convertFileToBase64(file)

      if (auth?.user.logoDataUrl != "data:" + file.type + ";base64," + base64) {
        setAbrirCrop(true)
      }
      
      formik.setFieldValue('EmpresaLogo', {
        Nome: file.name,
        Extensao: file.name.split('.').pop(),
        Tamanho: file.size,
        Tipo: file.type,
        Base64: base64
      })
      
      setSrcLogo("data:" + file.type + ";base64," + base64)

    }
    else{
      if (auth) {
        let newAuth = {...auth}
        newAuth.user.logoDataUrl = ""
        saveAuth(newAuth) 
      }
      formik.setFieldValue('EmpresaLogo', undefined)
    }
  }

  const cancelarCrop = () => {
    $('.dz-remove')[0].click()
    setAbrirCrop(false)
  };

  const onCrop = async () => {
    
    await getCroppedImg(srcLogo, croppedAreaPixels, formik, helper.convertFileToBase64)

    setAbrirCrop(false)

  };

  useEffect(() => {
    if (auth && formik.values.EmpresaLogo) {
      let newAuth = {...auth}
      const EmpresaLogo = formik.values.EmpresaLogo
      const base64 = EmpresaLogo.Base64
      const tipo = EmpresaLogo.Tipo

      let urlLogoAtt = "data:" + tipo + ";base64," + base64
      newAuth.user.logoDataUrl = urlLogoAtt
      saveAuth(newAuth)
    }
  },[formik.values.EmpresaLogo])

  const onChangeCheckEnderecoCobranca = () => {
    let valor = utilizaEnderecoPricipalParaCobranca
    
    setUtilizaEnderecoPricipalParaCobranca(!valor)
    if (valor) {
      let newValues = formik.values
      newValues.EmpresaEndereco = [...newValues.EmpresaEndereco, {
        Id: undefined,
        IdEmpresa: undefined,
        Cep: '',
        Estado: '',
        Cidade: '',
        Bairro: '',
        Endereco: '',
        Numero: '',
        Complemento: '',
        EnderecoPrincipal: false,
        EnderecoCobranca: true,
      }]
      newValues.EmpresaEndereco[indexEnderecoPrincipal].EnderecoCobranca = false
      
      let valorIndexEnderecoCobrancaNaoPrincipal =  newValues.EmpresaEndereco.length > 1 ?  newValues.EmpresaEndereco.findIndex(x => x.EnderecoCobranca && !x.EnderecoPrincipal) : undefined
      
      setIndexEnderecoCobrancaNaoPrincipal(valorIndexEnderecoCobrancaNaoPrincipal)
      formik.setValues(newValues)
      setIndexEnderecoCobrancaNaoPrincipal(valorIndexEnderecoCobrancaNaoPrincipal)
      
    } else {
      if (formik.values.EmpresaEndereco.length > 1 && indexEnderecoCobrancaNaoPrincipal !== undefined) {
        let newValues = formik.values
        newValues.EmpresaEndereco.splice(indexEnderecoCobrancaNaoPrincipal, 1)
        newValues.EmpresaEndereco[indexEnderecoPrincipal].EnderecoCobranca = true
        formik.setValues(newValues)
        setIndexEnderecoCobrancaNaoPrincipal(undefined)
      }
    }
    
  }

  const fetchConfiguracoesIniciais = async () => {

    const response = await httpClient.request({
      method: RequestMethod.GET,
      endpoint: '/Gerenciamento/Empresa/Obter',
    })

    if (response.success && response.payload) {

      let empresa = response.payload

      let newValues = {...initValues}
      
      newValues.Id = empresa.Id
      newValues.RazaoSocial = empresa.RazaoSocial
      newValues.NomeFantasia = empresa.NomeFantasia
      newValues.IdSegmento = empresa.IdSegmento
      newValues.NomeSegmento = empresa.NomeSegmento
      newValues.Cnpj = empresa.CNPJ
      newValues.Celular = empresa.Celular
      newValues.Telefone = empresa.Telefone
      newValues.Site = empresa.Site
      newValues.QtdFuncionarios = empresa.QtdFuncionarios
      newValues.PorteEmpresa = empresa.PorteEmpresa

      let EmpresaLogo = empresa.EmpresaLogo

      if (EmpresaLogo) {
        newValues.EmpresaLogo = {
          Nome: EmpresaLogo.Nome,
          Extensao: EmpresaLogo.Extensao,
          Tamanho: EmpresaLogo.Tamanho,
          Tipo: EmpresaLogo.Tipo,
          Base64: EmpresaLogo.Base64,
        }
      }

      let empresaEndereco = empresa.EmpresaEndereco
      
      if (empresaEndereco && empresaEndereco.length > 0) {
        
        newValues.EmpresaEndereco = empresaEndereco
        const indexEnderecoPrincipal = newValues.EmpresaEndereco.findIndex(x => x.EnderecoPrincipal)

        const indexEnderecoCobranca = newValues.EmpresaEndereco.findIndex(x => x.EnderecoCobranca)

        setIndexEnderecoPrincipal(indexEnderecoPrincipal)

        if (indexEnderecoCobranca !== undefined && indexEnderecoCobranca === indexEnderecoPrincipal && newValues.EmpresaEndereco[indexEnderecoCobranca].Id === newValues.EmpresaEndereco[indexEnderecoPrincipal].Id) setUtilizaEnderecoPricipalParaCobranca(true)
        else if (indexEnderecoCobranca !== undefined) {
          setUtilizaEnderecoPricipalParaCobranca(false)
          setIndexEnderecoCobrancaNaoPrincipal(indexEnderecoCobranca)
        }
      }
      formik.setValues(newValues)      

    }

    await httpClient.request({
      method: RequestMethod.GET,
      endpoint: '/Gerenciamento/Segmento/ObterParaSelect',
    }).then((response) => {
      if(response.success && response.payload) setSegmentos(response.payload)
    })
  }

  const requestDados = async () => {

    const response = await httpClient.request({
      method: RequestMethod.GET,
      endpoint: '/Gerenciamento/Empresa/ObterPorId',
      queryObject: {
        IdEmpresa: informacao.idEmpresa
      }
    })

    if (response.success && response.payload) {

      let empresa = response.payload

      let newValues = {...initValues}
      
      newValues.Id = empresa.Id
      newValues.RazaoSocial = empresa.RazaoSocial
      newValues.NomeFantasia = empresa.NomeFantasia
      newValues.IdSegmento = empresa.IdSegmento
      newValues.NomeSegmento = empresa.NomeSegmento
      newValues.Cnpj = empresa.CNPJ
      newValues.Celular = empresa.Celular
      newValues.Telefone = empresa.Telefone
      newValues.Site = empresa.Site

      newValues.QtdFuncionarios = empresa.QtdFuncionarios
      newValues.PorteEmpresa = empresa.PorteEmpresa

      let EmpresaLogo = empresa.EmpresaLogo

      if (EmpresaLogo) {
        newValues.EmpresaLogo = {
          Nome: EmpresaLogo.Nome,
          Extensao: EmpresaLogo.Extensao,
          Tamanho: EmpresaLogo.Tamanho,
          Tipo: EmpresaLogo.Tipo,
          Base64: EmpresaLogo.Base64,
        }
      }

      let empresaEndereco = empresa.EmpresaEndereco
      
      if (empresaEndereco && empresaEndereco.length > 0) {
        
        newValues.EmpresaEndereco = empresaEndereco
        const indexEnderecoPrincipal = newValues.EmpresaEndereco.findIndex(x => x.EnderecoPrincipal)

        const indexEnderecoCobranca = newValues.EmpresaEndereco.findIndex(x => x.EnderecoCobranca)

        setIndexEnderecoPrincipal(indexEnderecoPrincipal)

        if (indexEnderecoCobranca !== undefined && indexEnderecoCobranca === indexEnderecoPrincipal && newValues.EmpresaEndereco[indexEnderecoCobranca].Id === newValues.EmpresaEndereco[indexEnderecoPrincipal].Id) setUtilizaEnderecoPricipalParaCobranca(true)
        else if (indexEnderecoCobranca !== undefined) {
          setUtilizaEnderecoPricipalParaCobranca(false)
          setIndexEnderecoCobrancaNaoPrincipal(indexEnderecoCobranca)
        }
      }
      formik.setValues(newValues)      

    }

    await httpClient.request({
      method: RequestMethod.GET,
      endpoint: '/Gerenciamento/Segmento/ObterParaSelect',
    }).then((response) => {
      if(response.success && response.payload) setSegmentos(response.payload)
    })
  }

  useEffect(() => {

    if (informacao && informacao.idEmpresa) {
      requestDados()
    } else{
      fetchConfiguracoesIniciais()
    }

  },[])

  return (
    <>

      <div className={`modal ${abrirCrop? "show" : ""} fade`} tabIndex={-1} id="kt_modal_crop"  style={{display: `${abrirCrop? "block" : "none"}`}}>
        <div className="modal-dialog modal-dialog-centered modal-xl">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Ajuste sua logo</h5>
            </div>
            <div className="modal-body mx-5">
              <div className='m-20 p-20'>
                <Cropper
                  image={srcLogo}
                  crop={crop}
                  zoom={zoom}
                  aspect={460 / aspect}
                  onCropChange={setCrop}
                  onCropComplete={onCropComplete}
                  onZoomChange={setZoom}
                  setCropSize={setCropSize}
                  restrictPosition={false}
                />
              </div>
            </div>
            <div className="modal-footer ">
              <div className="controls col-xl-12 mb-5 d-flex justify-content-center w-100">
                <div className='w-50 controls d-flex justify-content-center'>
                  <span>Zoom:</span>
                  <input
                    type="range"
                    value={zoom}
                    min={0.01}
                    max={5}
                    step={0.1}
                    aria-labelledby="Zoom"
                    onChange={(e) => {
                      setZoom(parseFloat(e.target.value))
                    }}
                    className="zoom-range ms-2"
                  />
                </div>
              </div>
              
              <div className='col-xl-12 border-top'>
               
              </div>
              <div className='col-xl-12 d-flex justify-content-end'>
                <button type="button" onClick={() => setAbrirCrop(false)} className="btn btn-secondary me-5">
                  Cancelar
                </button>
                <button type="button" onClick={() => onCrop()} className="btn btn-primary">
                  Salvar
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

    <div className='card mb-5 mb-xl-10'>

      <div
        className='card-header'
      >
        <div className='card-title m-0'>
          <h3 className='fw-bolder m-0'>Configuração Empresa</h3>
        </div>
      </div>

      <div
        className='card-body'
      >
        <FormikProvider value={formik}>
          <div className='w-100'>
            <form 
              noValidate
            >
              <div className="row">

                <div className="col-lg-12 fv-row mb-5">
                    <label className='form-label required'>CNPJ</label>
                    <InputMask
                      mask={'99.999.999/9999-99'}
                      placeholder='__.___.___/____-__'
                      type='text'
                      {...formik.getFieldProps('Cnpj')}
                      name={`Cnpj`}
                      className='form-control form-control-lg form-control-solid w-50'
                      value={formik.values.Cnpj}
                      
                    ></InputMask>
                    <div className='text-danger mt-2'>
                      <ErrorMessage name='Cnpj' />
                    </div> 
                </div>  

                <div className="col-lg-12 fv-row mb-5">
                    <label className='form-label required'>Razão Social</label>
                    <Field
                        name={`RazaoSocial`}
                        placeholder='Razão Social'
                        className='form-control form-control-lg form-control-solid'
                        value={formik.values.RazaoSocial}
                    />
                    <div className='text-danger mt-2'>
                      <ErrorMessage name='RazaoSocial' />
                    </div> 
                </div>

                <div className="col-lg-12 fv-row mb-5">
                    <label className='form-label required'>Nome Fantasia</label>
                    <Field
                        name={`NomeFantasia`}
                        placeholder='Nome Fantasia'
                        className='form-control form-control-lg form-control-solid'
                        value={formik.values.NomeFantasia}
                    />
                    <div className='text-danger mt-2'>
                      <ErrorMessage name='NomeFantasia' />
                    </div> 
                </div>   

                <div className="col-lg-6 fv-row mb-5">
                    <label className='form-label required'>Telefone</label>
                    <InputMask
                      mask={'(99) 9999-9999'}
                      placeholder='(__) ____-____'
                      type='text'
                      autoComplete='off'
                      {...formik.getFieldProps('Telefone')}
                      name={`Telefone`}
                      className='form-control form-control-lg form-control-solid'
                      value={formik.values.Telefone}
                    ></InputMask>
                    <div className='text-danger mt-2'>
                      <ErrorMessage name='Telefone' />
                    </div> 
                </div>

                <div className="col-lg-6 fv-row mb-5">
                    <label className='form-label'>Celular</label>
                    <InputMask
                      mask={'(99) 99999-9999'}
                      placeholder='(__) _____-____'
                      type='text'
                      autoComplete='off'
                      {...formik.getFieldProps('Celular')}
                      name={`Celular`}
                      className='form-control form-control-lg form-control-solid'
                      value={formik.values.Celular}
                    ></InputMask>
                    <div className='text-danger mt-2'>
                      <ErrorMessage name='Celular' />
                    </div> 
                </div>

                <div className="col-lg-6 fv-row mb-5">
                    <label className='form-label required'>CEP</label>
                    <InputMask
                      mask={'99999-999'}
                      placeholder='_____-___'
                      type='text'
                      autoComplete='off'
                      {...formik.getFieldProps(`EmpresaEndereco.${indexEnderecoPrincipal}.Cep`)}
                      name={`EmpresaEndereco.${indexEnderecoPrincipal}.Cep`}
                      className='form-control form-control-lg form-control-solid'
                      value={formik.values.EmpresaEndereco[indexEnderecoPrincipal].Cep}
                      onChange={(e:any) => onChangeCepEndPrincipal(e)}
                    ></InputMask>
                    <div className='text-danger mt-2'>
                      <ErrorMessage name={`EmpresaEndereco.${indexEnderecoPrincipal}.Cep`} />
                    </div> 
                </div>

                <div className="col-lg-6 fv-row mb-5">
                    
                </div>

                <div className="col-lg-6 fv-row mb-5">
                    <label className='form-label required'>Estado</label>
                    <Field
                      name={`EmpresaEndereco.${indexEnderecoPrincipal}.Estado`}
                      placeholder='Estado'
                      className='form-control form-control-lg form-control-solid'
                      value={formik.values.EmpresaEndereco[indexEnderecoPrincipal].Estado}
                    />
                    <div className='text-danger mt-2'>
                      <ErrorMessage name={`EmpresaEndereco.${indexEnderecoPrincipal}.Estado`} />
                    </div> 
                </div>

                <div className="col-lg-6 fv-row mb-5">
                    <label className='form-label required'>Cidade</label>
                    <Field
                      name={`EmpresaEndereco.${indexEnderecoPrincipal}.Cidade`}
                      placeholder='Cidade'
                      className='form-control form-control-lg form-control-solid'
                      value={formik.values.EmpresaEndereco[indexEnderecoPrincipal].Cidade}
                    />
                    <div className='text-danger mt-2'>
                      <ErrorMessage name={`EmpresaEndereco.${indexEnderecoPrincipal}.Cidade`} />
                    </div> 
                </div>

                <div className="col-lg-6 fv-row mb-5">
                    <label className='form-label required'>Bairro</label>
                    <Field
                      name={`EmpresaEndereco.${indexEnderecoPrincipal}.Bairro`}
                      placeholder='Bairro'
                      className='form-control form-control-lg form-control-solid'
                      value={formik.values.EmpresaEndereco[indexEnderecoPrincipal].Bairro}
                    />
                    <div className='text-danger mt-2'>
                      <ErrorMessage name={`EmpresaEndereco.${indexEnderecoPrincipal}.Bairro`} />
                    </div> 
                </div>

                <div className="col-lg-6 fv-row mb-5">
                    <label className='form-label required'>Endereço</label>
                    <Field
                      name={`EmpresaEndereco.${indexEnderecoPrincipal}.Endereco`}
                      placeholder='Endereço'
                      className='form-control form-control-lg form-control-solid'
                      value={formik.values.EmpresaEndereco[indexEnderecoPrincipal].Endereco}
                    />
                    <div className='text-danger mt-2'>
                      <ErrorMessage name={`EmpresaEndereco.${indexEnderecoPrincipal}.Endereco`} />
                    </div> 
                </div>

                <div className="col-lg-6 fv-row mb-5">
                    <label className='form-label required'>Número</label>
                    <Field
                      name={`EmpresaEndereco.${indexEnderecoPrincipal}.Numero`}
                      placeholder='Número'
                      className='form-control form-control-lg form-control-solid'
                      value={formik.values.EmpresaEndereco[indexEnderecoPrincipal].Numero}
                    />
                    <div className='text-danger mt-2'>
                      <ErrorMessage name={`EmpresaEndereco.${indexEnderecoPrincipal}.Numero`} />
                    </div> 
                </div> 

                <div className="col-lg-6 fv-row mb-5">
                    <label className='form-label'>Complemento</label>
                    <Field
                      name={`EmpresaEndereco.${indexEnderecoPrincipal}.Complemento`}
                      placeholder='Complemento'
                      className='form-control form-control-lg form-control-solid'
                      value={formik.values.EmpresaEndereco[indexEnderecoPrincipal].Complemento}
                    />
                </div>  

                <div className="col-lg-12 fv-row mb-5">
                  <label className='form-label required'>Endereço de Cobrança</label>

                  <div className="form-check form-switch form-check-custom form-check-solid m-0">
                    <label className="form-check-label m-0 fs-3">Utilizar o mesmo endereço acima?</label>
                    <input 
                      className="form-check-input ms-2 h-25px w-40px" 
                      type="checkbox" 
                      onChange={() => onChangeCheckEnderecoCobranca()}
                      checked={utilizaEnderecoPricipalParaCobranca}
                    />         
                  </div>

                </div>
                
                { !utilizaEnderecoPricipalParaCobranca && formik.values.EmpresaEndereco.length > 1 && indexEnderecoCobrancaNaoPrincipal !== undefined && 
                  (
                    <>
                      <div className="col-lg-6 fv-row mb-5">
                        <label className='form-label required'>CEP</label>
                        <InputMask
                          mask={'99999-999'}
                          placeholder='_____-___'
                          type='text'
                          autoComplete='off'
                          {...formik.getFieldProps(`EmpresaEndereco.${indexEnderecoCobrancaNaoPrincipal}.Cep`)}
                          name={`EmpresaEndereco.${indexEnderecoCobrancaNaoPrincipal}.Cep`}
                          className='form-control form-control-lg form-control-solid'
                          value={formik.values.EmpresaEndereco[indexEnderecoCobrancaNaoPrincipal].Cep}
                          onChange={(e:any) => onChangeCepEndCobranca(e)}
                        ></InputMask>
                        <div className='text-danger mt-2'>
                          <ErrorMessage name={`EmpresaEndereco.${indexEnderecoCobrancaNaoPrincipal}.Cep`} />
                        </div> 
                      </div>
            
                      <div className="col-lg-6 fv-row mb-5">
                          
                      </div>

                      <div className="col-lg-6 fv-row mb-5">
                          <label className='form-label required'>Estado</label>
                          <Field
                            name={`EmpresaEndereco.${indexEnderecoCobrancaNaoPrincipal}.Estado`}
                            placeholder='Estado'
                            className='form-control form-control-lg form-control-solid'
                            value={formik.values.EmpresaEndereco[indexEnderecoCobrancaNaoPrincipal].Estado}
                          />
                          <div className='text-danger mt-2'>
                            <ErrorMessage name={`EmpresaEndereco.${indexEnderecoCobrancaNaoPrincipal}.Estado`} />
                          </div> 
                      </div>

                      <div className="col-lg-6 fv-row mb-5">
                          <label className='form-label required'>Cidade</label>
                          <Field
                            name={`EmpresaEndereco.${indexEnderecoCobrancaNaoPrincipal}.Cidade`}
                            placeholder='Cidade'
                            className='form-control form-control-lg form-control-solid'
                            value={formik.values.EmpresaEndereco[indexEnderecoCobrancaNaoPrincipal].Cidade}
                          />
                          <div className='text-danger mt-2'>
                            <ErrorMessage name={`EmpresaEndereco.${indexEnderecoCobrancaNaoPrincipal}.Cidade`} />
                          </div> 
                      </div>

                      <div className="col-lg-6 fv-row mb-5">
                          <label className='form-label required'>Bairro</label>
                          <Field
                            name={`EmpresaEndereco.${indexEnderecoCobrancaNaoPrincipal}.Bairro`}
                            placeholder='Bairro'
                            className='form-control form-control-lg form-control-solid'
                            value={formik.values.EmpresaEndereco[indexEnderecoCobrancaNaoPrincipal].Bairro}
                          />
                          <div className='text-danger mt-2'>
                            <ErrorMessage name={`EmpresaEndereco.${indexEnderecoCobrancaNaoPrincipal}.Bairro`} />
                          </div> 
                      </div>
            
                      <div className="col-lg-6 fv-row mb-5">
                          <label className='form-label required'>Endereço</label>
                          <Field
                            name={`EmpresaEndereco.${indexEnderecoCobrancaNaoPrincipal}.Endereco`}
                            placeholder='Endereço'
                            className='form-control form-control-lg form-control-solid'
                            value={formik.values.EmpresaEndereco[indexEnderecoCobrancaNaoPrincipal].Endereco}
                          />
                          <div className='text-danger mt-2'>
                            <ErrorMessage name={`EmpresaEndereco.${indexEnderecoCobrancaNaoPrincipal}.Endereco`} />
                          </div> 
                      </div>
            
                      <div className="col-lg-6 fv-row mb-5">
                          <label className='form-label required'>Número</label>
                          <Field
                            name={`EmpresaEndereco.${indexEnderecoCobrancaNaoPrincipal}.Numero`}
                            placeholder='Número'
                            className='form-control form-control-lg form-control-solid'
                            value={formik.values.EmpresaEndereco[indexEnderecoCobrancaNaoPrincipal].Numero}
                          />
                          <div className='text-danger mt-2'>
                            <ErrorMessage name={`EmpresaEndereco.${indexEnderecoCobrancaNaoPrincipal}.Numero`} />
                          </div> 
                      </div> 
            
                      <div className="col-lg-6 fv-row mb-5">
                          <label className='form-label'>Complemento</label>
                          <Field
                            name={`EmpresaEndereco.${indexEnderecoCobrancaNaoPrincipal}.Complemento`}
                            placeholder='Complemento'
                            className='form-control form-control-lg form-control-solid'
                            value={formik.values.EmpresaEndereco[indexEnderecoCobrancaNaoPrincipal].Complemento}
                          />
                      </div>           
                      
                    </>
                  )
                }

              <div className='col-lg-12 fv-row mb-5'>
                <label className='form-label required'>Segmento de mercado</label>
                <Field
                  as='select'
                  name='IdSegmento'
                  className='form-select form-select-lg form-select-solid'
                  value={formik.values.IdSegmento}
                  onChange={(event: { target: { value: number } }) => {
                    formik.handleChange(event);
                    // Aqui você pode acessar o valor selecionado através de event.target.value
                    setShowNomeSegmento(event.target.value === 2);
                  }}
                >
                  <option value=''>Selecione...</option>
                  {
                    segmentos && segmentos.length > 0 ? segmentos.map((data: any, index: any) => {
                      return <option key={`idSegmento-option-${index}`} value={data.Id}>{data.Nome}</option>
                    }) : ''
                  }
                </Field>
                <div className='text-danger mt-2'>
                  <ErrorMessage name='IdSegmento' />
                </div>
              </div>

              {(showNomeSegmento || formik.values.IdSegmento === 2) && (
                <div className="col-lg-12 fv-row mb-5">
                  <label className='form-label required'>Segmento de mercado:</label>
                  <Field
                      name={`NomeSegmento`}
                      placeholder='Segmento de mercado'
                      className='form-control form-control-lg form-control-solid'
                      value={formik.values.NomeSegmento}
                  />
                  <div className='text-danger mt-2'>
                    <ErrorMessage name='NomeSegmento' />
                </div>
                </div>
              )}
              
              <div className="col-lg-12 fv-row mb-5">
                <label className='form-label'>Site</label>
                <Field
                    name={`Site`}
                    placeholder='Site'
                    className='form-control form-control-lg form-control-solid'
                    value={formik.values.Site}
                />
              </div>

              <div className='col-lg-6 fv-row mb-5'>
                <label className='form-label'>Porte da empresa</label>
                <Field
                  as='select'
                  name='PorteEmpresa'
                  className='form-select form-select-lg form-select-solid'
                  value={formik.values.PorteEmpresa}
                >
                  <option value='0'>Selecione...</option>
                  {Object.keys(EnumPorteEmpresa).map(key => (
                    
                    parseFloat(key) > 0 && (
                      <option key={key} value={key}>
                        {EnumPorteEmpresa[parseFloat(key)]}
                      </option>
                    )
                    
                  ))}
                </Field>
              </div>
              <div className="col-lg-6 fv-row mb-5">
                <label className='form-label'>Quantidade de funcionários</label>
                <Field
                  type={`number`}
                  name={`QtdFuncionarios`}
                  placeholder='Quantidade de funcionários'
                  className='form-control form-control-lg form-control-solid'
                  value={formik.values.QtdFuncionarios}
                />
              </div>

              <div className='col-lg-12 fv-row'>
                <label className='form-label'>Logo</label>
                <DropzoneComponent 
                  filesHandler={filesHandler}
                  id='dz-empresa-logo'
                  preloadFiles={formik.values.EmpresaLogo}
                  options={{ 
                    acceptedFiles: 'image/jpeg,image/png',
                    maxFiles: 1,
                    maxFilesize: 2
                  }}
                />
              </div>
              
              </div>
              
            </form>
          </div>
        </FormikProvider>
      </div>
      {((auth?.roles.find(x => x === 'Master') !== undefined) || (auth?.roles.find(x => x === 'Administrador') !== undefined) || (auth?.permissoes.find(x => x === 'C101D857F18553B80A649298B8C822F8') !== undefined)) && (
        <div className="card-footer d-flex justify-content-end py-4">
          <button className='btn btn-secondary me-2' onClick={() => navigate(-1)}>
            Voltar
          </button>
          <button
            type='button'
            onClick={() => formik.handleSubmit()}
            className='btn btn-lg btn-primary'
          >
            Salvar
          </button>
        </div>
      )}
    </div>
    </>
  )
}

export {Empresa}
