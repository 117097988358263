import { PageLink, PageTitle } from '../../../../_metronic/layout/core'
import { useAuth } from '../../../modules/auth'
import { useHttpClient } from '../../../modules/services/Bundle'
import { BtnTutorial } from './partials/BtnTutorial'
import { BtnAbrirVideo } from './partials/BtnAbrirVideo'
import { useEffect, useState } from 'react'
import { EnumServicoTipoPlano } from '../../../modules/services/core/_enums'
import { useNavigate } from 'react-router-dom'

interface Videos {
  btnTo: string,
  descricaoVideo: string,
  videoName: string,
  videoPath: string,
}

const Tutorial = () => {
  const {auth} = useAuth()
  const user = auth?.user
  const servico = auth?.servico
  const navigate = useNavigate();

  let [descricao, setDescricao] = useState("Tutorial de navegação no sistema Prolead")
  let [descricaoVideo, setDescricaoVideo] = useState("Estamos felizes por você estar aqui! Assista a este vídeo para obter uma visão geral do módulo Descrição de cargo. Em seguida, confira os outros vídeos para obter conhecimento sobre a navegação no sistema.")
  let [videoName, setVideoName] = useState("Seja bem-vindo ao módulo de Descrição de Cargo com Inteligência Artificial")
  let [videoPath, setVideoPath] = useState("https://pro4tech.blob.core.windows.net/visualstuff/Pagina%20de%20tutorial.mp4?si=maintaining&spr=https&sv=2022-11-02&sr=b&sig=Ghf1XdSkTNNrOxW%2FJCXsIoIkNF5Ye7oc6s%2FOZmAKNvU%3D")
  const [videos, setVideos] = useState<Videos[]>([])

  useEffect(() => {
    setVideos([
      {
        btnTo: '/ajuda/detalhes/paginaInicial',
        descricaoVideo:'Bem vindos a nossa página inicial, através dela poderá se aprofundar nos conceitos sobre Descrições de Cargos, acessar nossos tutoriais interativos, iniciar as configurações iniciais de sua empresa e por fim começar a descrever cada cargo.',
        videoName:'Página Inicial',
        videoPath:'https://pro4tech.blob.core.windows.net/visualstuff/Pagina%20inicial.mp4?si=maintaining&spr=https&sv=2022-11-02&sr=b&sig=uFEWdmkq07JGvRjaFTjrPuUGZOQ2lM9tz2Kbv3NL%2FQk%3D',
      },
      {
        btnTo: '/ajuda/detalhes/empresa',
        descricaoVideo:'Inicialmente, deve-se configurar as informações de sua empresa, forneça os dados necessários para poder continuar para os próximos passos.',
        videoName:'Parâmetros da Empresa',
        videoPath:'https://pro4tech.blob.core.windows.net/visualstuff/Wizard%20Empresa.mp4?si=maintaining&spr=https&sv=2022-11-02&sr=b&sig=5ny7q%2BZSat0n73Knhp6tYH35zBhNWd32FAvRAp6XPJc%3D',
      },
      {
        btnTo: '/ajuda/detalhes/modelo',
        descricaoVideo:'Neste passo, demonstraremos como customizar seu Modelo de Formulário para iniciar suas descrições de cargos.',
        videoName:'Modelo de Formulário',
        videoPath:'https://pro4tech.blob.core.windows.net/visualstuff/Wizard%20Formulario.mp4?si=maintaining&spr=https&sv=2022-11-02&sr=b&sig=59F73YyiVGQe7Bz4ldHY4X5Tw%2BD0wicSxhLAb%2B%2Fqo5c%3D',
      },
      {
        btnTo: '/ajuda/detalhes/niveishierarquicos',
        descricaoVideo:'Neste passo deve-se configurar quais os níveis hierárquicos de sua empresa que estarão atrelados aos cargos.',
        videoName:'Níveis Hierárquicos',
        videoPath:'https://pro4tech.blob.core.windows.net/visualstuff/Wizard%20Niveis.mp4?si=maintaining&spr=https&sv=2022-11-02&sr=b&sig=vHsCGnw5yygDDQsJ%2Fgj%2F5OHlsaC%2BHz5ctEKzRwjPRCo%3D',
      },
      {
        btnTo: '/ajuda/detalhes/areas',
        descricaoVideo:'Nessa etapa realize as configurações das áreas de sua empresa.',
        videoName:'Áreas da empresa',
        videoPath:'https://pro4tech.blob.core.windows.net/visualstuff/Wizard%20Areas.mp4?si=maintaining&spr=https&sv=2022-11-02&sr=b&sig=%2BXGQ3oSrIz47q7MQlaScdq3EXzTWPBkvrb%2BTvw%2B9dEs%3D',
      },
      {
        btnTo: '/ajuda/detalhes/cargos',
        descricaoVideo:'Por fim, você aprenderá como cadastrar cargos na plataforma, fornecendo informações essenciais, como o nome do cargo, seu nível hierárquico e a área a que pertence. Nesta versão experimental, você terá a possibilidade de cadastrar até dez cargos.',
        videoName:'Cargos da empresa',
        videoPath:'https://pro4tech.blob.core.windows.net/visualstuff/Wizard%20Cargos.mp4?si=maintaining&spr=https&sv=2022-11-02&sr=b&sig=YNmyFYD9ut69RD02fFG1QJ3BReWtYuKVqfNHxL%2BrU7A%3D',
      },
      {
        btnTo: '/ajuda/detalhes/finalizar',
        descricaoVideo:'Com as parametrizações realizadas, hora de encerrar essa etapa de configurações e começar o processo de descrição de cargos com IA.',
        videoName:'Finalizar configurações',
        videoPath:'https://pro4tech.blob.core.windows.net/visualstuff/Wizard%20Finalizar.mp4?si=maintaining&spr=https&sv=2022-11-02&sr=b&sig=xv1nav2Xj3wUrXZHM6FDqIz0%2FngSfdLdveTj4ZdUPaw%3D',
      },
    ])
  }, [])

  return (
    <>
      {/* begin::Row */}
      <div className='row g-5 g-xl-10 mb-5 mb-xl-10'>
        {/* begin::Col */}
        <div className='col-xl-12'>
          <BtnTutorial
            descricao={descricao}
            descricaoVideo={descricaoVideo}
            videoName={videoName}
            videoPath={videoPath}
            tempoVideo=''
          />
        </div>
        {/* end::Col */}
      </div>
      {/* end::Row */}
      {/* begin::Row */}
      {servico?.tipoPlano === EnumServicoTipoPlano.Try && (
      <div className='row g-5 g-xl-10 mb-5 mb-xl-10'>
        {
          videos && videos.length > 0 && videos.map((data: any, index: any) => {
            return <div key={index} className='col-xl-4'>
                    <BtnAbrirVideo
                      btnTo={data.btnTo}
                      descricao=''
                      descricaoVideo={data.descricaoVideo}
                      videoName={data.videoName}
                      videoPath={data.videoPath}
                      tempoVideo=''
                    />
                  </div>
          })
        }
      </div>
      )}
      {/* end::Row */}
      <div className='d-flex justify-content-end'>
        <button className='btn btn-secondary' onClick={() => navigate(-1)}>
          Voltar
        </button>
      </div>

    </>
  )

}

export {Tutorial}
