import { Link, useNavigate } from 'react-router-dom'
import { KTSVG } from '../../../../_metronic/helpers'
import { TableConsultorDescricao } from './partials/TableConsultorDescricao'
import { useState } from 'react';
import { ModalConsultorDescricao } from './partials/ModalConsultorDescricao';
import { useAuth } from '../../../modules/auth';

const ConsultorDescricao = () => {

  const navigate = useNavigate();
  const [abrirModalConsultorDescricao, setAbrirModalConsultorDescricao] = useState(false)
  const [atualizarDadosFecharModal, setAtualizarDadosFecharModal] = useState(false)
  const {auth} = useAuth()

  return (
    <>

    <div className='card mb-xl-10'>
      <div
        className='card-header'
      >
        <div className='card-title m-0'>
          <h3 className='fw-bolder m-0'>Consultores</h3>
        </div>
        <div className='card-toolbar'>
          {((auth?.roles.find(x => x === 'Master') !== undefined) || (auth?.roles.find(x => x === 'Administrador') !== undefined) || (auth?.permissoes.find(x => x === '8215B2E4C65163C356D320BA7B73BC17') !== undefined)) && (
            <button
              type='button'
              className='btn btn-light-primary'
              onClick={() => { 
                setAbrirModalConsultorDescricao(true); 
              }}
            >
              <KTSVG path="/media/icons/duotune/arrows/arr009.svg" className="svg-icon-muted svg-icon-1" />
              Adicionar
            </button>
          )}          
        </div>
      </div>

      <div className='card-body'>
        <TableConsultorDescricao 
          setAtualizarDadosFecharModal={setAtualizarDadosFecharModal}
          atualizarDadosFecharModal={atualizarDadosFecharModal}
        />
      </div>
      <div className="card-footer py-4 d-flex justify-content-end">
        <Link className='btn btn-secondary' to='/inicio'>
          Voltar
        </Link>
      </div>
    </div>
    {
      abrirModalConsultorDescricao && (
          <ModalConsultorDescricao
              abrirModal={abrirModalConsultorDescricao}
              setAbrirModal={setAbrirModalConsultorDescricao}
              setAtualizarDadosFecharModal={setAtualizarDadosFecharModal}
          />
      )
    }
    </>
  )
}

export {ConsultorDescricao}
