import Chart from 'react-apexcharts'
import { IDadosGraficosResultadosPorDimensaoPesquisaModel } from '../helpers/AnaliseResultadoHelper'
import Select from 'react-select'
import { ISelectModel } from '../../../../modules/services/core/_models'

type Props = {
  dadosGraficosPesquisa: IDadosGraficosResultadosPorDimensaoPesquisaModel | undefined
  dimensoes: ISelectModel[]
  setDimensao: Function
  perguntas: ISelectModel[]
  setPergunta: Function
  perguntaSelecionada: ISelectModel | undefined
}

const GraficoResultadosGlobaisPorPergunta  = ({dadosGraficosPesquisa, dimensoes, setDimensao, perguntas, setPergunta, perguntaSelecionada}:Props) => {

  let stackType: any = '100%'

  let options = {
    colors : dadosGraficosPesquisa?.Colors ?? [],
    series: dadosGraficosPesquisa?.Series ?? [],
    chart: {
      stacked: true,
      stackType: stackType,
      toolbar: {
        show: false
      },
    },
    plotOptions: {
      bar: {
        horizontal: true,
      },
    },
    stroke: {
      width: 1,
      colors: ['#fff']
    },
    xaxis: {
      categories: dadosGraficosPesquisa?.Categories ?? [],
    },
    fill: {
      opacity: 1    
    },
    legend: {
      offsetX: 40
    }
  }
  
  return (
    <>
      <div className={`card m-0 p-0 ${dadosGraficosPesquisa ? '' : 'd-none'}`}>        
        <div className='card-body m-0 p-0'>
          <div className="row justify-content-between m-8 mb-0 px-10">
            <div className='fv-row col-lg-4 ms-5'>
              <label className={`form-label text-gray-800`}>Selecione a dimensão</label>
              {dimensoes.length > 0 && (
              <Select 
                placeholder='Selecione...' 
                className='form-control-custom-select'
                noOptionsMessage={() => 'Nenhuma opção encontrada'}
                styles={{
                    control: (baseStyles, state) => ({
                    ...baseStyles,
                    border: 'none',
                    backgroundColor: '$input-solid-bg',
                    borderRadius: '7px',
                    height: '3.4rem',
                    color: '#5e6278',
                    fontSize: '1.15rem'
                    }),
                    menu: (baseStyles, state) => ({
                    ...baseStyles,
                    backgroundColor: '#F9F9F9',
                    }),
                    option: (provided, state) => ({
                    ...provided,
                    color: state.isFocused ? 'black' : '$input-solid-color',
                    }),
                }}
                options={dimensoes.map((data) => ({
                    value: data.Id,
                    label: data.Nome
                }))}
                defaultValue={{value: dimensoes[0].Id, label: dimensoes[0].Nome}}
                isSearchable
                onChange={(e: any) => setDimensao(e)}
                />
              )}

            </div>
            <div className='fv-row col-lg-7'>
              <label className={`form-label text-gray-800`}>Selecione a pergunta</label>
              {perguntas.length > 0 && (
              <Select 
                placeholder='Selecione...' 
                className='form-control-custom-select'
                noOptionsMessage={() => 'Nenhuma opção encontrada'}
                styles={{
                    control: (baseStyles, state) => ({
                    ...baseStyles,
                    border: 'none',
                    backgroundColor: '$input-solid-bg',
                    borderRadius: '7px',
                    height: '3.4rem',
                    color: '#5e6278',
                    fontSize: '1.15rem'
                    }),
                    menu: (baseStyles, state) => ({
                    ...baseStyles,
                    backgroundColor: '#F9F9F9',
                    }),
                    option: (provided, state) => ({
                    ...provided,
                    color: state.isFocused ? 'black' : '$input-solid-color',
                    }),
                }}
                options={perguntas.map((data) => ({
                    value: data.Id,
                    label: data.Texto
                }))}
                value={
                  perguntaSelecionada === undefined ? 
                  {value: perguntas[0].Id, label: perguntas[0].Texto} : 
                  {value: perguntaSelecionada.Id, label: perguntaSelecionada.Nome}
                }
                isSearchable
                onChange={(e: any) => setPergunta(e)}
                />
              )}

            </div>
          </div>
          <div className='row justify-content-center m-0 p-0'>
            <Chart options={options} series={options.series} type="bar" height={'400px'} />           
          </div>
        </div>        
      </div> 
    </>         
  )
}
  
export {GraficoResultadosGlobaisPorPergunta}