import {useEffect, useState} from 'react'
import {CardPesquisa} from './partials/CardPesquisa'
import {useHttpClient} from '../../../modules/services/Bundle'
import {EnumServicoTipoPlano, RequestMethod} from '../../../modules/services/core/_enums'
import {useAuth} from '../../../modules/auth'
import {StatusPesquisa} from './partials/TablePesquisa'

interface IDashboardPesquisaDados {
  TotalPesquisas: number
  PesquisasNaoLiberadas: number
  PesquisasNaoIniciadas: number
  PesquisasEmAndamento: number
  PesquisasSuspensas: number
  PesquisasFinalizadas: number
  PesquisasNaoLiberadasPorcentagem: number
  PesquisasNaoIniciadasPorcentagem: number
  PesquisasEmAndamentoPorcentagem: number
  PesquisasSuspensasPorcentagem: number
  PesquisasFinalizadasPorcentagem: number
}

const defaultDados: IDashboardPesquisaDados = {
  TotalPesquisas: 0,
  PesquisasNaoLiberadas: 0,
  PesquisasNaoIniciadas: 0,
  PesquisasEmAndamento: 0,
  PesquisasSuspensas: 0,
  PesquisasFinalizadas: 0,
  PesquisasNaoLiberadasPorcentagem: 0,
  PesquisasNaoIniciadasPorcentagem: 0,
  PesquisasEmAndamentoPorcentagem: 0,
  PesquisasSuspensasPorcentagem: 0,
  PesquisasFinalizadasPorcentagem: 0,
}

const DashboardPesquisa = () => {
  let [dados, setDados] = useState<IDashboardPesquisaDados>(defaultDados)
  const httpClient = useHttpClient()
  const auth = useAuth()
  const servico = auth.auth?.servico
  
  const obterPesquisaDados = () => {
    httpClient
      .request({
        method: RequestMethod.GET,
        endpoint: '/Pesquisa/Dashboard/ObterDashboardPesquisaDados',
      })
      .then((response) => {
        if (response.success && response.payload) {
          setDados(response.payload)
        }
      })
  }

  useEffect(() => {
    obterPesquisaDados()
  }, [])
  
  return (
    <>
      {/* begin::Row */}
      <div className='row g-5 g-xl-8'>
        <div className='col-xl-12'>
          <CardPesquisa
            svgIcon='/media/prolead/pesquisa/pesquisa-naoiniciada.svg'
            color='#ABABAB'
            iconColor='white'
            title='Criar/Liberar Pesquisas'
            titleColor='white'
            dados={dados}
            statusPesquisa={StatusPesquisa.NaoLiberada}
            btnTo=''
            setDadosDash={obterPesquisaDados}
          />
        </div>
        <div className='col-xl-12'>
          <CardPesquisa
            svgIcon='/media/prolead/pesquisa/pesquisa-emandamento.svg'
            color='#223365'
            iconColor='white'
            title='Status das Pesquisas:'
            titleColor='white'
            dados={dados}
            btnTo=''
            statusPesquisa={StatusPesquisa.EmAndamento}
            setDadosDash={obterPesquisaDados}
          />
        </div>
        <div className='col-xl-12'>
          <CardPesquisa
            svgIcon='/media/prolead/pesquisa/pesquisa-finalizada.svg'
            color='#1ABC9C'
            iconColor='white'
            title='Finalizadas'
            titleColor='white'
            dados={dados}
            statusPesquisa={StatusPesquisa.Finalizada}
            btnTo=''
            setDadosDash={obterPesquisaDados}
          />
        </div>
      </div>
      {/* end::Row */}
    </>
  )
}

export {DashboardPesquisa}
