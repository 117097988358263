import { Link, useNavigate } from 'react-router-dom'
import { KTSVG } from '../../../../_metronic/helpers'
import { TablePerfis } from './partials/TablePerfis'

const Perfis = () => {

  const navigate = useNavigate();

  return (
    <>

    <div className='card mb-xl-10'>
      <div
        className='card-header'
      >
        <div className='card-title m-0'>
          <h3 className='fw-bolder m-0'>Perfis</h3>
        </div>
        <div className='card-toolbar'>
          <button
            type='button'
            className='btn btn-light-primary'
            onClick={() => { 
              navigate('/configuracao/perfis/configurar',
              { state: { 
                Acao: "Adicionar"
              }}
              )
            }}
          >
            <KTSVG path="/media/icons/duotune/arrows/arr009.svg" className="svg-icon-muted svg-icon-1" />
            Adicionar
          </button>
        </div>
      </div>

      <div className='card-body'>
        <TablePerfis />
      </div>
      <div className="card-footer py-4 d-flex justify-content-end">
        <Link className='btn btn-secondary' to='/inicio'>
          Voltar
        </Link>
      </div>
    </div>
    </>
  )
}

export {Perfis}
