import * as React from 'react'
import {useAlert, useHelper, useHttpClient} from '../../../../modules/services/Bundle'
import {EnumModalAvalidorAprovador, EnumTipoEnviarAprovacao, EnumTipoEnviarAvaliacao, RequestMethod} from '../../../../modules/services/core/_enums'
import {Link, useNavigate} from 'react-router-dom'
import {KTSVG} from '../../../../../_metronic/helpers'
import { TableAvaliadores } from './TableAvaliadores'
import { TableAprovadores } from './TableAprovadores'

interface ModalAvaliadoresAprovadores {
  TipoModal: number,
  IdDescricao: number
  IdsDescricao?: readonly number[]
  TipoRequest?: number
  AbrirModal: boolean
  SetAbrirModal: Function
  atualizarDados?: Function 
  idEmpresa?: number | null
}

const ModalAvaliadoresAprovadores = ({
  TipoModal,
  IdDescricao,
  IdsDescricao,
  TipoRequest,
  AbrirModal,
  SetAbrirModal,
  atualizarDados,
  idEmpresa = null
}: ModalAvaliadoresAprovadores) => {
  const httpClient = useHttpClient()
  const navigate = useNavigate()
  const alert = useAlert()

  return (
    <div className={`modal ${ AbrirModal ? 'show' : ''} fade`} tabIndex={-1} id='kt_modal_1' style={{display:  AbrirModal ? 'block' : ''}}>
      <div className='modal-dialog modal-dialog-centered modal-xl'>
        <div className='modal-content'>
          <div className='modal-header'>
            <h5 className='modal-title'>{ TipoModal === EnumModalAvalidorAprovador.Avaliador ? "Avaliadores" : "Aprovadores" }</h5>
            <div
              onClick={() => SetAbrirModal(false)}
              className='btn btn-icon btn-sm btn-active-light-primary ms-2'
              data-bs-dismiss='modal'
              aria-label='Fechar'
            >
              <KTSVG
                path='/media/icons/duotune/arrows/arr061.svg'
                className='svg-icon svg-icon-2x'
              />
            </div>
          </div>
          <div className='modal-body'>

            {TipoModal === EnumModalAvalidorAprovador.Avaliador 
            ? 
              <TableAvaliadores AbrirModal={AbrirModal} IdDescricao={IdDescricao} idEmpresa={idEmpresa} IdsDescricao={IdsDescricao} TipoRequest={TipoRequest} />
            : 
              <TableAprovadores AbrirModal={AbrirModal} IdDescricao={IdDescricao} idEmpresa={idEmpresa} IdsDescricao={IdsDescricao} TipoRequest={TipoRequest} />
            }

          </div>
          <div className='modal-footer'>
            {TipoModal === EnumModalAvalidorAprovador.Avaliador 
            ? 
              <button
                type='button'
                className='btn btn-primary me-3'
                onClick={() => {
                  alert.createDialog({
                    html: `Realmente deseja salvar e enviar para avaliação esta descrição?`,
                    confirmAction: async () => {
                      let data: {} | undefined = {}

                      let query = {}

                      if (TipoRequest !== undefined && EnumTipoEnviarAvaliacao.Multipla === TipoRequest) {
                        data = IdsDescricao
                        query = {
                          idEmpresa: idEmpresa ?? 0
                        }
                      } else {
                        data = undefined
                        query = {
                          idDescricao: IdDescricao,
                          idEmpresa: idEmpresa ?? 0
                        }
                      }
                      await httpClient.request({
                        method: RequestMethod.PUT,
                        endpoint: (TipoRequest !== undefined && EnumTipoEnviarAvaliacao.Multipla === TipoRequest ? '/DescricaoDeCargo/Descrever/FinalizarDescricoesEnviarParaAvaliacao' : '/DescricaoDeCargo/Descrever/FinalizarDescricaoEnviarParaAvaliacao'),
                        data: data,
                        queryObject: query,
                      }).then((result) => {
                        
                        if (result.success) {
                          if (atualizarDados !== undefined) {
                            atualizarDados()
                          } else {
                            navigate(idEmpresa == null ? '/descricao/dashboard' : '/descricao/consultoria/dashboard')
                          }
                          SetAbrirModal(false)
                        }
                        
                      })
                    },
                  })
                }}
              >
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  width='16'
                  height='16'
                  fill='currentColor'
                  className='bi bi-send-plus me-2'
                  viewBox='0 0 16 16'
                >
                  <path d='M15.964.686a.5.5 0 0 0-.65-.65L.767 5.855a.75.75 0 0 0-.124 1.329l4.995 3.178 1.531 2.406a.5.5 0 0 0 .844-.536L6.637 10.07l7.494-7.494-1.895 4.738a.5.5 0 1 0 .928.372l2.8-7Zm-2.54 1.183L5.93 9.363 1.591 6.602l11.833-4.733Z' />
                  <path d='M16 12.5a3.5 3.5 0 1 1-7 0 3.5 3.5 0 0 1 7 0Zm-3.5-2a.5.5 0 0 0-.5.5v1h-1a.5.5 0 0 0 0 1h1v1a.5.5 0 0 0 1 0v-1h1a.5.5 0 0 0 0-1h-1v-1a.5.5 0 0 0-.5-.5Z' />
                </svg>
                Enviar
              </button>
            : 
              <button
                type='button'
                className='btn btn-primary me-3'
                onClick={() => {
                  alert.createDialog({
                    html: `Realmente deseja salvar e enviar para aprovação esta descrição?`,
                    confirmAction: async () => {
                      let data: {} | undefined = {}

                      let query = {}

                      if (TipoRequest !== undefined && EnumTipoEnviarAprovacao.Multipla === TipoRequest) {
                        data = IdsDescricao
                        query = {
                          idEmpresa: idEmpresa ?? 0
                        }
                      } else {
                        data = undefined
                        query = {
                          idDescricao: IdDescricao,
                          idEmpresa: idEmpresa ?? 0
                        }
                      }
                      await httpClient.request({
                        method: RequestMethod.PUT,
                        endpoint: (TipoRequest !== undefined && EnumTipoEnviarAprovacao.Multipla === TipoRequest ? '/DescricaoDeCargo/Descrever/FinalizarDescricoesEnviarParaAprovacao' : '/DescricaoDeCargo/Descrever/FinalizarDescricaoEnviarParaAprovacao'),
                        data: data,
                        queryObject: query,
                      }).then((result) => {
                        
                        if (result.success) {
                          if (atualizarDados !== undefined) {
                            atualizarDados()
                          } else {
                            navigate(idEmpresa == null ? '/descricao/dashboard' : '/descricao/consultoria/dashboard')
                          }
                          SetAbrirModal(false)
                        }
                        
                      })
                    },
                  })
                }}
              >
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  width='16'
                  height='16'
                  fill='currentColor'
                  className='bi bi-send-plus me-2'
                  viewBox='0 0 16 16'
                >
                  <path d='M15.964.686a.5.5 0 0 0-.65-.65L.767 5.855a.75.75 0 0 0-.124 1.329l4.995 3.178 1.531 2.406a.5.5 0 0 0 .844-.536L6.637 10.07l7.494-7.494-1.895 4.738a.5.5 0 1 0 .928.372l2.8-7Zm-2.54 1.183L5.93 9.363 1.591 6.602l11.833-4.733Z' />
                  <path d='M16 12.5a3.5 3.5 0 1 1-7 0 3.5 3.5 0 0 1 7 0Zm-3.5-2a.5.5 0 0 0-.5.5v1h-1a.5.5 0 0 0 0 1h1v1a.5.5 0 0 0 1 0v-1h1a.5.5 0 0 0 0-1h-1v-1a.5.5 0 0 0-.5-.5Z' />
                </svg>
                Enviar
              </button>
            }
            
            <button className='btn btn-secondary' onClick={() => SetAbrirModal(false)}>
              Voltar
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export {ModalAvaliadoresAprovadores}
