/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useEffect, useState, useRef} from 'react'
import {useHttpClient} from '../../../modules/services/Bundle'
import {RequestMethod} from '../../../modules/services/core/_enums'
import {getCSS, getCSSVariableValue} from '../../../../_metronic/assets/ts/_utils'
import ApexCharts from 'apexcharts'
import {TableRelatorio} from '../../../modules/profile/components/settings/cards/TableRelatorio'
import {startOfWeek, endOfWeek} from 'date-fns'
import ptBR from 'date-fns/locale/pt-BR'
import {DateTimePicker, LocalizationProvider} from '@mui/x-date-pickers'
import {ptBR as ptBr} from '@mui/x-date-pickers/locales'
import br from 'dayjs/locale/pt-br'
import dayjs from 'dayjs'
import {AdapterDayjs} from '@mui/x-date-pickers/AdapterDayjs'
import {styled} from '@mui/material'

type ExtratoOperacao = {
  Tipo: number
  NomeAtividade: string
  QuantidadeEmpresas: number
  Empresas: number[]
}

const getStartOfWeek = () => {
  const start = startOfWeek(new Date(), {locale: ptBR})
  const startJs = dayjs(start)
    .set('hour', start.getHours() - 3)
    .format('YYYY-MM-DD HH:mm:ss')

  return startJs
}

const getEndOfWeek = () => {
  const end = endOfWeek(new Date(), {locale: ptBR})
  const endJs = dayjs(end)
    .set('hour', end.getHours() - 3)
    .format('YYYY-MM-DD HH:mm:ss')

  return endJs
}

const StyledDateTimePicker = styled(DateTimePicker)({
  '& .MuiInputBase-input': {
    color: 'gray', // Defina a cor da fonte desejada aqui
  },
})

const Relatorio: FC = () => {
  // dayjs.locale('pt-br')

  const chartRef = useRef<HTMLDivElement | null>(null)

  const datePickerRef = useRef()
  const [dataInicio, setDataInicio] = useState(getStartOfWeek())
  const [dataFim, setDataFim] = useState(getEndOfWeek())
  const [dados, setDados] = useState([] as ExtratoOperacao[])
  const [detalhes, setDetalhes] = useState<ExtratoOperacao | null>(null)
  const httpClient = useHttpClient()

  useEffect(() => {
    carregarDados()
  }, [])

  useEffect(() => {
    const chart = refreshChart()

    return () => {
      if (chart) {
        chart.destroy()
      }
    }
  }, [chartRef, dados])

  const refreshChart = () => {
    if (!chartRef.current) {
      return
    }

    const height = parseInt(getCSS(chartRef.current, 'height'))

    const chart = new ApexCharts(chartRef.current, getChartOptions(height))
    if (chart) {
      chart.render()
      if (detalhes) {
        setDetalhes(null)
      }
    }

    return chart
  }

  const carregarDados = async () => {
    const response = await httpClient.request({
      method: RequestMethod.POST,
      endpoint: '/Administracao/Relatorio/Buscar',
      data: {
        DataInicio: dataInicio,
        DataFim: dataFim,
      },
    })

    if (response.success && response.payload) {
      setDados(response.payload)
    }
  }

  const getChartOptions = (height: number) => {
    const labelColor = getCSSVariableValue('--bs-gray-500')
    const borderColor = getCSSVariableValue('--bs-gray-200')
    const baseColor = getCSSVariableValue('--bs-primary')
    const secondaryColor = getCSSVariableValue('--bs-gray-300')

    const dadosQuantidadeEmpresas = dados.map((x) => x.QuantidadeEmpresas)
    const dadosAtividades = dados.map((x) => x.NomeAtividade)

    return {
      series: [
        {
          name: 'Quantidade de Empresas',
          data: dadosQuantidadeEmpresas,
        },
      ],
      chart: {
        fontFamily: 'inherit',
        type: 'bar',
        height: height,
        toolbar: {
          show: false,
          intersect: true,
          shared: false,
        },
        events: {
          dataPointSelection: function (event: any, chartContext: any, config: any) {
            const index = config.dataPointIndex
            const dado = dados[index]
            setDetalhes(dado)
          },
        },
      },
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: '30%',
          borderRadius: 5,
        },
      },
      legend: {
        show: false,
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        show: true,
        width: 2,
        colors: ['transparent'],
      },
      xaxis: {
        categories: dadosAtividades,
        axisBorder: {
          show: false,
        },
        axisTicks: {
          show: false,
        },
        labels: {
          style: {
            colors: labelColor,
            fontSize: '10px',
          },
        },
      },
      yaxis: {
        labels: {
          style: {
            colors: labelColor,
            fontSize: '12px',
          },
        },
      },
      fill: {
        opacity: 1,
      },
      states: {
        normal: {
          filter: {
            type: 'none',
            value: 0,
          },
        },
        hover: {
          filter: {
            type: 'none',
            value: 0,
          },
        },
        active: {
          allowMultipleDataPointsSelection: false,
          filter: {
            type: 'none',
            value: 0,
          },
        },
      },
      tooltip: {
        style: {
          fontSize: '12px',
        },
        y: {
          formatter: function (val: any) {
            return val + ' Empresa(s)'
          },
        },
      },
      colors: [baseColor, secondaryColor],
      grid: {
        borderColor: borderColor,
        strokeDashArray: 4,
        yaxis: {
          lines: {
            show: true,
          },
        },
      },
    }
  }

  return (
    <>
      <div className='d-flex flex-column-fluid align-center'>
        <div className='container-fluid'>
          <div className='card'>
            <div className='card-header'>
              <div className='card-title'>Extrato de Operações</div>
            </div>
            <div className='card-body'>
              <div className='d-flex row w-100 mx-auto pt-5'>
                <div className='form-group col-6'>
                  <label>Período</label>
                  <div className='input-daterange input-group' id='kt_datepicker'>
                    <LocalizationProvider adapterLocale={br.name} dateAdapter={AdapterDayjs}>
                      <StyledDateTimePicker
                        format='DD/MM/YYYY HH:mm:ss'
                        inputRef={datePickerRef.current}
                        localeText={ptBr.components.MuiLocalizationProvider.defaultProps.localeText}
                        onChange={(e: any) => setDataInicio(e)}
                        className='mt-2 form-control'
                        value={dayjs(dataInicio)}
                        slotProps={{
                          textField: {size: 'small', style: {color: 'GrayText'}},
                        }}
                      />
                    </LocalizationProvider>
                    <div className='input-group-append d-flex align-items-end'>
                      <span
                        className='input-group-text'
                        style={{padding: '1.1rem 1rem !important'}}
                      >
                        <i className='la la-ellipsis-h'></i>
                      </span>
                    </div>
                    <LocalizationProvider adapterLocale={br.name} dateAdapter={AdapterDayjs}>
                      <StyledDateTimePicker
                        format='DD/MM/YYYY HH:mm:ss'
                        inputRef={datePickerRef.current}
                        localeText={ptBr.components.MuiLocalizationProvider.defaultProps.localeText}
                        onChange={(e: any) => setDataFim(e)}
                        className='mt-2 form-control'
                        value={dayjs(dataFim)}
                        slotProps={{
                          textField: {size: 'small', color: 'secondary'},
                        }}
                      />
                    </LocalizationProvider>
                  </div>
                </div>
                <div className='col-6 align-items-end d-flex'>
                  <button type='submit' className='btn btn-primary' onClick={carregarDados}>
                    Buscar
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='card mt-10'>
        <div className='card-header border-0 pt-5'>
          <h3 className='card-title align-items-start flex-column'>
            <span className='card-label fw-bold fs-3 mb-1'>Operações Realizadas por Empresa</span>

            {/* <span className='text-muted fw-semibold fs-7'>More than 400 new members</span> */}
          </h3>
        </div>
        <div className='card-body'>
          <div ref={chartRef} id='chart' style={{height: '300px'}} />
        </div>
      </div>

      {detalhes && (
        <TableRelatorio
          Atividade={detalhes.NomeAtividade}
          Log={detalhes.Tipo}
          Empresas={detalhes.Empresas}
          DataInicio={dataInicio}
          DataFim={dataFim}
        />
      )}
    </>
  )
}

export {Relatorio}
