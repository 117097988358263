import {useEffect, useRef, useState} from 'react'
import {KTSVG} from '../../../../_metronic/helpers'
import {Step1} from './stepsPesquisa/Step1'
import {Step2} from './stepsPesquisa/Step2'
import {Step3} from './stepsPesquisa/Step3'
import {Step4} from './stepsPesquisa/Step4'
import {StepperComponent} from '../../../../_metronic/assets/ts/components'
import {FormikValues, useFormik, FormikProvider} from 'formik'
import {
  IWizardModel,
  defaultValidationSchemas,
  initValues,
  IExemploDimensaoModel,
  IExemploDimensaoPerguntaModel,
  initValuesParticipante
} from './helpers/PesquisaHelper'
import {useAlert, useHttpClient} from '../../../modules/services/Bundle'
import {EnumPesquisaTipoDisposicaoPergunta, EnumStepsPesquisa, RequestMethod} from '../../../modules/services/core/_enums'
import {useAuth} from '../../../modules/auth'
import {useNavigate} from 'react-router'
import $ from 'jquery'
import { Link, useLocation } from 'react-router-dom'
import dayjs from 'dayjs'

interface dados {
  Id: number,
  TipoRedirect: string
}

const Pesquisa = () => {
  const stepperRef = useRef<HTMLDivElement | null>(null)
  const stepper = useRef<StepperComponent | null>(null)
  const [validationSchemas] = useState(defaultValidationSchemas)
  const [currentSchema, setCurrentSchema] = useState(validationSchemas[0])
  const {auth, saveAuth} = useAuth()
  const httpClient = useHttpClient()
  const [nameStep, setNameStep] = useState('Identificação')
  const [tipoRedirect, setTipoRedirect] = useState('')
  const [numeroStep, setNumeroStep] = useState(0)
  const [segmentos, setSegmentos] = useState<any[]>([])
  const location = useLocation()
  const state = location.state as dados
  const informacao: dados = state
  const navigate = useNavigate()
  let [temas, setTemas] = useState<any[]>([])
  let [escalas, setEscalas] = useState<any[]>([])
  let [dimensoes, setDimensoes] = useState<IExemploDimensaoModel[]>([])
  let [perguntas, setPerguntas] = useState<IExemploDimensaoPerguntaModel[]>([])
  const [divAgrupar, setDivAgrupar] = useState(false)
  const [divAleatorio, setDivAleatorio] = useState(false)
  const alert = useAlert()


  const loadStepper = () => {
    stepper.current = StepperComponent.createInsance(stepperRef.current as HTMLDivElement)
  }

  const prevStep = () => {
    if (!stepper.current) {
      return
    }
    if (stepper.current.currentStepIndex === 1) {
      navigate('/pesquisa-ia/dashboard')
      return
    }
    stepper.current.goPrev()

    setCurrentSchema(validationSchemas[stepper.current.currentStepIndex - 1])
    setNumeroStep(stepper.current.currentStepIndex - 1)
    setNameStep(EnumStepsPesquisa[stepper.current.currentStepIndex - 1])
  }

  const submitStep = async (values: IWizardModel, actions: FormikValues) => {
    if (!stepper.current) {
      return
    }
    
    setNameStep(EnumStepsPesquisa[stepper.current.currentStepIndex])
    setNumeroStep(stepper.current.currentStepIndex)
    if (stepper.current.currentStepIndex !== stepper.current.totatStepsNumber) {
      let sucesso = false
      
      switch (stepper.current.currentStepIndex) {
        case 1: {
          const response = await httpClient.request({
            method: RequestMethod.POST,
            endpoint: '/Pesquisa/Pesquisa/AdicionarEditarPesquisa',
            data: values,
          })

          if (response.success && response.payload) {
            const newValues = formik.values
            newValues.Id = response.payload.Id
            actions.setValues(newValues)
            navigate("",
              {state:{
                  Id: response.payload.Id,
                  TipoRedirect: tipoRedirect
              }}
            )

            if (newValues.TipoDisposicaoPergunta === EnumPesquisaTipoDisposicaoPergunta.AgruparPorDimensao) {
              setDivAgrupar(true)
              setDivAleatorio(false)
              setPerguntas([])
              const response = await httpClient.request({
                method: RequestMethod.GET,
                endpoint: '/Pesquisa/Pesquisa/ObterExemploPerguntasAgrupadasPorDimensao',
                queryObject: {
                  idTema: formik.values.IdTema
                },
              })
              if (response.success && response.payload && response.payload.length > 0) {
                setDimensoes(response.payload)
              }
            }
            if (newValues.TipoDisposicaoPergunta === EnumPesquisaTipoDisposicaoPergunta.OrdenarAleatoriamente) {
              setDivAleatorio(true)
              setDivAgrupar(false)
              setDimensoes([])
              const response = await httpClient.request({
                method: RequestMethod.GET,
                endpoint: '/Pesquisa/Pesquisa/ObterExemploPerguntasOrdenadasAleatoriamente',
                queryObject: {
                  idTema: formik.values.IdTema
                },
              })

              if (response.success && response.payload && response.payload.length > 0) {
                setPerguntas(response.payload)
              }
            }

            sucesso = true
          }
          break
        }
        case 2: {
          const response = await httpClient.request({
            method: RequestMethod.POST,
            endpoint: '/Pesquisa/Pesquisa/ConfigurarTipoDisposicaoPergunta',
            data: values,
          })

          if (response.success) {
            if (formik.values.Participantes.length === 0) {
              const newValues = formik.values
              newValues.Participantes.push(initValuesParticipante)
              actions.setValues(newValues)
            }
            sucesso = true
          }

          break
        }
        case 3: {
          const response = await httpClient.request({
            method: RequestMethod.POST,
            endpoint: '/Pesquisa/Pesquisa/AdicionarEditarParticipantes',
            data: values,
          })

          if (response.success  && response.payload) {
            const newValues = formik.values
            newValues.Participantes = response.payload.Participantes
            for (let i = 0; i < newValues.Participantes.length; i++) {
              newValues.Participantes[i].ConfirmeEmail = newValues.Participantes[i].Email
            }
            
            newValues.NomeTema = temas.find(x => x.Id === formik.values.IdTema)?.Nome ?? ''
            
            actions.setValues(newValues)
            sucesso = true
          }
          break
        }
      }
      if (sucesso) stepper.current.goNext()
      setCurrentSchema(validationSchemas[stepper.current.currentStepIndex - 1])
      return
    }
    if (stepper.current.currentStepIndex === stepper.current.totatStepsNumber && auth) {
      try {
        if (informacao && informacao.TipoRedirect === '') {
          alert.createMessage({
            title: 'Sucesso',
            html: `Pesquisa criada com sucesso!`,
            icon: 'success',
          }).then(() => {
            navigate('/pesquisa-ia/dashboard')
          })
        }
        if (informacao && informacao.TipoRedirect === 'editar') {
          alert.createMessage({
            title: 'Sucesso',
            html: `Pesquisa editada com sucesso!`,
            icon: 'success',
          }).then(() => {
            navigate('/pesquisa-ia/dashboard')
          })
        }
        
      } catch (e) {
      }
    }
  }

  const formik = useFormik({
    initialValues: {...initValues},
    validationSchema: currentSchema,
    onSubmit: submitStep,
  })

  const autoSizeTextArea = () => {
    $('.textarea-texto-pesquisa').each(function (i, element) {
      let target = element as HTMLTextAreaElement
      target.style.height = '90px'

      if (target.scrollHeight && target.scrollHeight > 90)
        target.style.height = target.scrollHeight + 'px'
    })
  }

  useEffect(() => {
    autoSizeTextArea()
  }, [formik])

  useEffect(() => {
    const fetchPesquisa = async () => {
      
      if (informacao && informacao.Id) {
        setTipoRedirect(informacao.TipoRedirect)
        const response = await httpClient.request({
          method: RequestMethod.GET,
          endpoint: '/Pesquisa/Pesquisa/Obter',
          queryObject: {
            idPesquisa: informacao.Id
          }
        })

        if (response.success && response.payload) {

          let newValues = {...initValues}

          newValues = response.payload

          if (newValues.Participantes.length === 0) {
            newValues.Participantes.push(initValuesParticipante)
          } else{
            for (let i = 0; i < newValues.Participantes.length; i++) {
              newValues.Participantes[i].ConfirmeEmail = newValues.Participantes[i].Email
            }
          }
          
          formik.setValues(newValues)
          
          if (response.payload.DataInicialStringYMD !== '')  formik.setFieldValue('DataInicial', dayjs(response.payload.DataInicialStringYMD))
    
          if (response.payload.DataFinalStringYMD !== '')  formik.setFieldValue('DataFinal', dayjs(response.payload.DataFinalStringYMD))

        }
      }

      const responseTema = await httpClient.request({
        method: RequestMethod.GET,
        endpoint: '/Pesquisa/Tema/ObterParaSelect',
      })
      if (responseTema.success && responseTema.payload && responseTema.payload.length > 0) {
        setTemas(responseTema.payload)
      }

      const responseEscala = await httpClient.request({
        method: RequestMethod.GET,
        endpoint: '/Pesquisa/Escala/ObterParaSelect',
      })
      if (responseEscala.success && responseEscala.payload && responseEscala.payload.length > 0) {
        setEscalas(responseEscala.payload)
      }

    }
    
    fetchPesquisa()
    
  }, [])

  useEffect(() => {
    if (!stepperRef.current) {
      return
    }
    loadStepper()
  }, [stepperRef])

  return (
    <>

      <ol className='breadcrumb breadcrumb-line text-muted fs-6 mt-0 fw-semibold mb-10'>
        <li className='breadcrumb-item h2 hover-link-name-dash text-gray-600'><Link className="text-gray-600" to='/pesquisa-ia/dashboard'>Pesquisa</Link></li>
        <li className='breadcrumb-item h2 hover-link-name-dash text-gray-600'><Link className="text-gray-600" to='/pesquisa-ia/dashboard'>Dashboard</Link></li>
        <li className='breadcrumb-item h2 text-dark'>{nameStep}</li>
      </ol>

      <div
        ref={stepperRef}
        className='stepper stepper-pills'
        id='kt_create_account_stepper'
      >
        {/* begin::Aside*/}
        <div className='card d-flex justify-content-center mb-5 w-100 me-5'>
          {/* begin::Wrapper*/}
          <div className='card-body'>
            {/* begin::Nav*/}
            <div className='stepper-nav  mat-horizontal-content-container flex-center flex-wrap'>
              {/* begin::Step 1*/}
              <div className='stepper-item mx-8 my-4 current' data-kt-stepper-element='nav'>
                {/* begin::Wrapper*/}
                <div className='stepper-wrapper d-flex align-items-center flex-column'>
                  {/* begin::Icon*/}
                  <div className='stepper-icon step-counter mx-0 w-40px h-40px'>
                    <i className='stepper-check fas fa-check'></i>
                    <span className='stepper-number color-white-step-number'>1</span>
                  </div>
                  {/* end::Icon*/}

                  {/* begin::Label*/}
                  <div className='stepper-label '>
                    <h3 className='stepper-title'>Identificação</h3>
                  </div>
                  {/* end::Label*/}
                </div>
                {/* end::Wrapper*/}

                {/* begin::Line*/}
                <div className='stepper-line h-40px'></div>
                {/* end::Line*/}
              </div>
              {/* end::Step 1*/}

              {/* begin::Step 2*/}
              <div className='stepper-item mx-8 my-4 ' data-kt-stepper-element='nav'>
                {/* begin::Wrapper*/}
                <div className='stepper-wrapper d-flex align-items-center flex-column'>
                  {/* begin::Icon*/}
                  <div className='stepper-icon step-counter mx-0 w-40px h-40px'>
                    <i className='stepper-check fas fa-check'></i>
                    <span className='stepper-number color-white-step-number'>2</span>
                  </div>
                  {/* end::Icon*/}

                  {/* begin::Label*/}
                  <div className='stepper-label'>
                    <h3 className='stepper-title'>Disposição</h3>
                  </div>
                  {/* end::Label*/}
                </div>
                {/* end::Wrapper*/}

                {/* begin::Line*/}
                <div className='stepper-line h-40px'></div>
                {/* end::Line*/}
              </div>
              {/* end::Step 2*/}

              {/* begin::Step 3*/}
              <div className='stepper-item mx-8 my-4 ' data-kt-stepper-element='nav'>
                {/* begin::Wrapper*/}
                <div className='stepper-wrapper d-flex align-items-center flex-column'>
                  {/* begin::Icon*/}
                  <div className='stepper-icon step-counter mx-0 w-40px h-40px'>
                    <i className='stepper-check fas fa-check'></i>
                    <span className='stepper-number color-white-step-number'>3</span>
                  </div>
                  {/* end::Icon*/}

                  {/* begin::Label*/}
                  <div className='stepper-label'>
                    <h3 className='stepper-title'>Participantes</h3>
                  </div>
                  {/* end::Label*/}
                </div>
                {/* end::Wrapper*/}

                {/* begin::Line*/}
                <div className='stepper-line h-40px'></div>
                {/* end::Line*/}
              </div>
              {/* end::Step 3*/}

              {/* begin::Step 4*/}
              <div className='stepper-item mx-8 my-4' data-kt-stepper-element='nav'>
                {/* begin::Wrapper*/}
                <div className='stepper-wrapper d-flex align-items-center flex-column'>
                  {/* begin::Icon*/}
                  <div className='stepper-icon step-counter mx-0 w-40px h-40px'>
                    <i className='stepper-check fas fa-check'></i>
                    <span className='stepper-number color-white-step-number'>4</span>
                  </div>
                  {/* end::Icon*/}

                  {/* begin::Label*/}
                  <div className='stepper-label'>
                    <h3 className='stepper-title'>Revisar e Enviar</h3>
                  </div>
                  {/* end::Label*/}
                </div>
                {/* end::Wrapper*/}

                {/* begin::Line*/}
                <div className='stepper-line h-40px'></div>
                {/* end::Line*/}
              </div>
              {/* end::Step 4*/}
            </div>
            {/* end::Nav*/}
          </div>
          {/* end::Wrapper*/}
        </div>
        {/* begin::Aside*/}

        <div className='d-flex flex-row-fluid flex-center bg-body rounded'>
          <FormikProvider value={formik}>
            <form
              className={`py-20 w-100 ${
                (stepper.current?.currentStepIndex ?? 0) === 1 ? 'w-xl-700px' : ''
              } px-9`}
              noValidate
              onSubmit={formik.handleSubmit}
            >
              <div className='current' data-kt-stepper-element='content'>
                <Step1 
                  formik={formik}
                  temas={temas}
                  setTemas={setTemas}
                  escalas={escalas}
                  setEscalas={setEscalas}
                />
              </div>

              <div data-kt-stepper-element='content'>
                <Step2 
                  formik={formik}
                  dimensoes={dimensoes}
                  setDimensoes={setDimensoes}
                  perguntas={perguntas}
                  setPerguntas={setPerguntas}
                  divAgrupar={divAgrupar}
                  setDivAgrupar={setDivAgrupar}
                  divAleatorio={divAleatorio}
                  setDivAleatorio={setDivAleatorio}
                />
              </div>

              <div data-kt-stepper-element='content'>
                <Step3 
                  formik={formik}
                />
              </div>

              <div data-kt-stepper-element='content'>
                <Step4 
                  formik={formik}
                  dimensoes={dimensoes}
                  perguntas={perguntas}
                />
              </div>

              <div className='d-flex flex-stack pt-10'>
                <div className='mr-2'>
                  <button
                    onClick={prevStep}
                    type='button'
                    className='btn btn-lg btn-pesquisa me-3'
                    // data-kt-stepper-action='previous'
                  >
                    <KTSVG
                      path='/media/icons/duotune/arrows/arr063.svg'
                      className='svg-icon-4 me-1 text-white'
                    />
                    Voltar
                  </button>
                </div>

                <div>
                  <button
                    type='button'
                    onClick={() => formik.handleSubmit()}
                    className='btn btn-lg btn-pesquisa me-3'
                  >
                    <span className='indicator-label'>
                      {(stepper.current?.currentStepIndex ?? 0) ===
                      (stepper.current?.totatStepsNumber ?? 4)
                        ? 'Finalizar'
                        : 'Continuar'}
                      <KTSVG
                        path='/media/icons/duotune/arrows/arr064.svg'
                        className='svg-icon-3 ms-2 text-white me-0'
                      />
                    </span>
                  </button>
                </div>
              </div>
            </form>
          </FormikProvider>
        </div>
      </div>
    </>
  )
}

export {Pesquisa}
