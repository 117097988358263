import * as React from 'react';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Paper from '@mui/material/Paper';
import { visuallyHidden } from '@mui/utils';
import { useNavigate } from 'react-router-dom';
import { useAlert, useHttpClient } from '../../../../modules/services/Bundle';
import { RequestMethod } from '../../../../modules/services/core/_enums';
import Popover from '@mui/material/Popover';
import Button from '@mui/material/Button';
import { IconButton } from '@mui/material';
import { useAuth } from '../../../../modules/auth';

interface Column {
  id: 'Id' | 'NomeUsuario' | 'NomeCliente';
  label: string;
  minWidth?: number;
  align?: 'center';
  format?: (value: number) => string;
}

interface Filtros {
  Id?: number,
  NomeConsultor: string,
  NomeCliente: string,
}

const columns: readonly Column[] = [
  { 
    id: 'NomeUsuario', 
    label: 'Consultor'
  },
  { 
    id: 'NomeCliente', 
    label: 'Cliente'
  },
];

interface Data {
  Id: number;
  NomeUsuario: string;
  NomeCliente: string;
}

function createData(
  Id: number,
  NomeUsuario: string,
  NomeCliente: string,
): Data {
  return { Id, NomeUsuario, NomeCliente};
}

type Order = 'asc' | 'desc';


interface EnhancedTableProps {
  numSelected: number;
  onRequestSort: (event: React.MouseEvent<unknown>, property: keyof Data) => void;
  onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
  order: Order;
  orderBy: string;
}

type Props = {
  atualizarDadosFecharModal: boolean
  setAtualizarDadosFecharModal: Function
}

const TableConsultorDescricao =  ({atualizarDadosFecharModal, setAtualizarDadosFecharModal}: Props) => {  
  const httpClient = useHttpClient()
  const [order, setOrder] = React.useState<Order>('asc')
  const [orderBy, setOrderBy] = React.useState<keyof Data>('NomeUsuario')
  const [selected, setSelected] = React.useState<readonly string[]>([])
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10)
  const [total, setTotal] = React.useState(0)
  const [rows, setRows] = React.useState<Data[]>([])   
  const alert = useAlert()
  const {auth} = useAuth()

  const [NomeUsuario, setNomeUsuario] = React.useState<any>(null)
  const [NomeCliente, setNomeCliente] = React.useState<any>(null)

  function EnhancedTableHead(props: EnhancedTableProps) {
    const { onSelectAllClick, order, orderBy, onRequestSort } =
      props;
    const createSortHandler =
      (property: keyof Data) => (event: React.MouseEvent<unknown>) => {
        onRequestSort(event, property);
      };
  
    return (
      <TableHead >
        <TableRow className='table-header'>
          {columns.map((columns) => (
            <TableCell
            className='table-cell'
            key={columns.id}
            align={columns.align ? 'right' : 'left'}
          >
            <TableSortLabel
              className='fw-bolder'
              active={orderBy === columns.id}
              direction={orderBy === columns.id ? order : 'asc'}
              onClick={createSortHandler(columns.id)}
            >
              {columns.label}
              {orderBy === columns.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
          ))}
            <TableCell
              className='fw-bolder table-cell'
              align='center'
            >
              AÇÕES
            </TableCell> 
        </TableRow>
      </TableHead>
    );
  }

  const resquestTable = (pageRq: number, orderByRq: string, orderRq: string, rowsPerPageRq: number, Filtros: any = null) => {
    
      httpClient.request({
        method: RequestMethod.POST,
        endpoint: '/DescricaoDeCargo/Consultoria/ListarConsultorCliente',
        data: {
          Order: orderRq,
          OrderBy: orderByRq,
          Page: pageRq,
          RowsPerPage: rowsPerPageRq,
          Filters: Filtros
        }
      }).then((result) => {
          
          const newRows = result.payload.data.map((data: Data) => {
            return createData(data.Id, data.NomeUsuario, data.NomeCliente)
          })
          setRows([...newRows])
          setTotal(result.payload.total)
      })
    
  }

  React.useEffect(() => {
      resquestTable(page, orderBy, order, rowsPerPage);
  },[])

  React.useEffect(() => {
    if(atualizarDadosFecharModal){
      resquestTable(page, orderBy, order, rowsPerPage);
      setAtualizarDadosFecharModal(false);
    }
  },[atualizarDadosFecharModal])

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: keyof Data,
  ) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
    resquestTable(page, property, isAsc ? 'desc' : 'asc', rowsPerPage);
  };

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelected = rows.map((n) => n.NomeUsuario);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    resquestTable(newPage, orderBy, order, rowsPerPage);
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    resquestTable(page, orderBy, order, parseInt(event.target.value, 10));
    setPage(0);
  };

  const emptyRows = rows.length;

  const atualizarDados = () => {
    resquestTable(page, orderBy, order, rowsPerPage)
  }

  const onClickBuscar = () => {

    const filtros:Filtros = {
      NomeConsultor: NomeUsuario,
      NomeCliente: NomeCliente,
    }
    
    resquestTable(page, orderBy, order, rowsPerPage,filtros);
    handleClose()
  }

  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
      setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
      setAnchorEl(null);
      setNomeUsuario(null)
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  return (
    <>
      <form className='form'>

          <div className='d-flex justify-content-end mb-2'>

            <div>
              <Button type='button' className='btn btn-light-primary' aria-describedby={id} onClick={handleClick}>
                <i className="fs-2 fas fa-arrow-down-wide-short"></i>
                Filtros
              </Button>
              <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                className="popover-filtro-color-bg"
              >
                
                  <div className='bg-body'>
                    {/* begin::Header */}
                    <div className='px-7 py-5'>
                      <div className='fs-5 text-dark fw-bolder'>Opções</div>
                    </div>
                    {/* end::Header */}

                    {/* begin::Separator */}
                    <div className='separator border-gray-200'></div>
                    {/* end::Separator */}

                    {/* begin::Content */}
                    <div className='px-7 py-5' >

                      {/* begin::Input group */}
                      <div className='mb-10'>
                        <label className='form-label fs-6 fw-bold'>Nome do Consultor:</label>
                        <input 
                          type='text'
                          onChange={e => setNomeUsuario(e.target.value)} 
                          placeholder='Nome do Consultor' 
                          className={`form-control form-control-lg form-control-solid`}
                        ></input>
                      </div>
                      {/* end::Input group */}

                      {/* begin::Input group */}
                      <div className='mb-10'>
                        <label className='form-label fs-6 fw-bold'>Nome do Cliente:</label>
                        <input 
                          type='text'
                          onChange={e => setNomeCliente(e.target.value)} 
                          placeholder='Nome do Cliente' 
                          className={`form-control form-control-lg form-control-solid`}
                        ></input>
                      </div>
                      {/* end::Input group */}

                      {/* begin::Actions */}
                      <div className='d-flex justify-content-end'>
                        <button
                          type='button'
                          className='btn btn-light btn-active-light-primary fw-bold me-5 px-6'
                          onClick={handleClose}
                        >
                          Cancelar
                        </button>
                        <button
                          type='button'
                          className='btn btn-primary fw-bold px-6'
                          onClick={() => onClickBuscar()}
                        >
                          Buscar
                        </button>
                      </div>
                      {/* end::Actions */}
                    </div>
                    {/* end::Content */}
                  </div>
              </Popover>
            </div>
          </div>

          <Box sx={{ width: '100%' }}>
            <Paper className='table-border ' sx={{ width: '100%', mb: 2 }}>
                     
              <TableContainer>
                <Table
                  sx={{ minWidth: 750 }}
                  aria-labelledby="tableTitle"
                >
                  <EnhancedTableHead
                    numSelected={selected.length}
                    order={order}
                    orderBy={orderBy}
                    onSelectAllClick={handleSelectAllClick}
                    onRequestSort={handleRequestSort}
                  />
                  <TableBody>
                    {rows.map((row, index) => {
                      return (
                        <TableRow
                          className='table-row'
                          hover
                          tabIndex={-1}
                          key={index}
                          sx={{ cursor: 'pointer' }}
                        >
                          <TableCell className='table-cell' align="left">{row.NomeUsuario}</TableCell>
                          <TableCell className='table-cell' align="left">{row.NomeCliente}</TableCell>
                          <TableCell className='table-cell' align="center">
                            {((auth?.roles.find(x => x === 'Master') !== undefined) || (auth?.roles.find(x => x === 'Administrador') !== undefined) || (auth?.permissoes.find(x => x === '9021EEBD5C0F82AF5BC7407900E5F016') !== undefined)) && (
                              <IconButton className='icon-button-dark-hover' onClick={() => {
                                alert.createDialog({
                                  html: `Realmente deseja remover esse consultor dessa empresa cliente?`,
                                  confirmAction: async () => {
                                    httpClient.request({
                                      method: RequestMethod.DELETE,
                                      endpoint: '/DescricaoDeCargo/Consultoria/RemoverConsultor',
                                      queryObject: {
                                        IdConsultorCliente: row.Id
                                      }
                                    }).then((response) => {
                                      if(response.success){
                                        resquestTable(page, orderBy, order, rowsPerPage);
                                      }
                                    })
                                  }
                                })
                                }} color="primary" aria-label="upload picture" component="label"
                              >
                                <i className="fs-6 text-gray-800 m-0 p-0 fas fa-trash-alt"></i>
                              </IconButton>
                            )}
                          </TableCell>
                        </TableRow>
                      );
                    })}
                    {emptyRows == 0 && (
                      <TableRow
                      >
                        <TableCell className='table-cell bg-body text-center' colSpan={6}>
                          Nenhum registro encontrado
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                className='table-footer'
                labelRowsPerPage="linhas por página"
                rowsPerPageOptions={[10, 25]}
                component="div"
                count={total}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </Paper>
          </Box>
      </form>
    </>    
  )
}

export {TableConsultorDescricao}