import {Link} from 'react-router-dom'
import {TablePrompts} from './partials/TablePrompts'

const Prompt = () => {
  return (
    <>
      <div className='card mb-xl-10'>
        <div className='card-header'>
          <div className='card-title m-0'>
            <h3 className='fw-bolder m-0'>Prompts</h3>
          </div>
        </div>

        <div className='card-body'>
          <TablePrompts />
        </div>
        <div className='card-footer py-4 d-flex justify-content-end'>
          <Link className='btn btn-secondary' to='/inicio'>
            Voltar
          </Link>
        </div>
      </div>
    </>
  )
}

export {Prompt}
