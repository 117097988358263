import { Link, useNavigate } from 'react-router-dom'
import { TableEmpresas } from './partials/TableEmpresas'
import Button from '@mui/material/Button';

const Empresas = () => {

  const navigate = useNavigate()

  return (
    <>

    <div className='card mb-xl-10'>
      <div
        className='card-header'
      >
        <div className='card-title m-0'>
          <h3 className='fw-bolder m-0'>Administração Empresas</h3>
        </div>
        <div className='card-title m-0'>
          <Button type='button' className='btn btn-light-primary' onClick={() => navigate('/administracao/empresas/criar')}>
            <i className="fs-2 fas fa-plus"></i>
            Adicionar Empresa
          </Button>
        </div>
      </div>

      <div className='card-body'>
        <TableEmpresas/>
      </div>
      <div className="card-footer py-4 d-flex justify-content-end">
        <Link className='btn btn-secondary' to='/inicio'>
          Voltar
        </Link>
      </div>
    </div>
    </>
  )
}

export {Empresas}
