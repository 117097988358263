/* eslint-disable jsx-a11y/anchor-is-valid */
import { useEffect, useState } from 'react'
import {Link, Navigate, useNavigate} from 'react-router-dom'
import { KTSVG, toAbsoluteUrl } from '../../../../../_metronic/helpers'
import { useAlert, useHttpClient } from '../../../../modules/services/Bundle'
import { EnumPesquisaParticipanteStatus, RequestMethod } from '../../../../modules/services/core/_enums'
import { IExemploDimensaoParticipanteModel } from '../helpers/PesquisaHelper'
import { PesquisaPerguntasParticipante } from './PesquisaPerguntasParticipante'

type Props = {
  showModal: boolean
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>
  statusParticipante: number
  nomeParticipante: string
  tipoChamada?: string
  idParticipante: number
  setIdParticipante: React.Dispatch<React.SetStateAction<number>>
}

const ModalDetalhesPesquisaParticipante = ({showModal, setShowModal, idParticipante, setIdParticipante, nomeParticipante,statusParticipante, tipoChamada = 'dashboard'}: Props) => {

  const [dimensoes, setDimensoes] = useState<IExemploDimensaoParticipanteModel[]>([])
  const alert = useAlert()
  const httpClient = useHttpClient()

  const buscarDadosParticipantes = async () => {
    setDimensoes([])
    const response = await httpClient.request({
      method: RequestMethod.GET,
      endpoint: '/Pesquisa/Dashboard/ObterRespostaPesquisa',
      queryObject: {
        idParticipantePesquisa: idParticipante
      }
    })
    if (response.success && response.payload) {
      const dimensao:IExemploDimensaoParticipanteModel[] = response.payload.Dimensoes
      if (dimensao) {
        setDimensoes(dimensao)
      }
    }
  }

  useEffect(() => {
    if (showModal) {
      buscarDadosParticipantes()
    }
  }, [showModal])
    
  return (
    <div className={`modal ${showModal? "show" : ""} fade`} tabIndex={-1} id="kt_modal_1" style={{display: `${showModal? "block" : "none"}`}}>
        <div className="modal-dialog modal-dialog-centered modal-xl">
            <div className="modal-content">
            <div className="modal-header">
              <h1 className="modal-title">{nomeParticipante}</h1>
              
              <div>
                <div className='col-lg-12 fs-3'><i className="fs-2 text-success fas fa-check"></i> Respondida</div>
                <div className='col-lg-12 fs-3'><i className="fs-1 text-danger fas fa-times me-1"></i> Pendente</div>
              </div>
            </div>
            <div className="modal-body">
              
              {dimensoes.length > 0 && dimensoes.map((dimensao, dimensaoIndex) => {
                return <div key={`accordion-titulo-${dimensaoIndex}`} id={`accordion-titulo-${dimensaoIndex}`} className='mb-8 w-100'>
                  <div className={`card card-custom border border-secondary border-1 shadow-sm titulo-pesquisa-nao-selecionado`}>
                    <div className="card-header bg-pesquisa border-bottom border-secondary border-1 rounded-bottom" id={`card-titulo-header-${dimensaoIndex}`}>
                      <div className="card-title w-100">
                        <div className='row w-100'>
                          <div className='col-md-4'>
                            <h3 className='text-white'>{(dimensao.Nome)}</h3>  
                          </div>
                          <div className='col-md-7'>
                            <div className='mt-3'>
                              <div className='h-8px w-100 rounded' style={{backgroundColor: '#ffffff4a'}}>
                                <div
                                  className='bg-white rounded h-8px'
                                  role='progressbar'
                                  style={{width: `${dimensao.Porcentagem}%`}}
                                  aria-valuenow={50}
                                  aria-valuemin={0}
                                  aria-valuemax={100}
                                ></div>
                              </div>
                            </div>     
                          </div>
                          <div className='col-md-1'>
                            <span className='ms-5 text-white'>{dimensao.Porcentagem}%</span>
                          </div>
                        </div>
                        <div className="card-toolbar w-md-auto">                                
                          <button type="button" className="btn btn-icon btn-sm btn-accordion btn-light" data-bs-toggle="collapse" data-bs-target={`#card-titulo-collapse-${dimensaoIndex}`} aria-expanded="false" aria-controls={`card-titulo-collapse-${dimensaoIndex}`} >
                            <i className='fas fa-arrow-down'></i>
                            <i className='fas fa-arrow-up'></i>
                          </button>                   
                        </div>
                                      
                      </div>
                    </div>
                    <div id={`card-titulo-collapse-${dimensaoIndex}`} className="collapse" aria-labelledby={`card-titulo-header-${dimensaoIndex}`} data-bs-parent={`#accordion-titulo-${dimensaoIndex}`}>
                      <div className="card-body w-100">
      
                        {(dimensao.Perguntas && dimensao.Perguntas.length > 0) && (
                          <PesquisaPerguntasParticipante
                            textos={dimensao.Perguntas}
                          />
                        )}
                      </div>   
                    </div>                         
                  </div>
                </div>
              })}
            </div>
            <div className={`modal-footer ${statusParticipante !== EnumPesquisaParticipanteStatus.Finalizada && tipoChamada === 'dashboard' ? 'd-flex justify-content-between' : '' }`}>
              {statusParticipante !== EnumPesquisaParticipanteStatus.Finalizada && tipoChamada === 'dashboard' && (
                <button
                  onClick={() => {
                    alert.createDialog({
                      html: `Deseja enviar uma notificação ao participante: ${nomeParticipante}?`,
                      confirmAction: async () => {
                        httpClient.request({
                          method: RequestMethod.POST,
                          endpoint: '/Pesquisa/Dashboard/NotificarParticipante',
                          queryObject: {
                            idParticipante: idParticipante
                          }
                        }).then((response) => {
                          
                        })
                      }
                    })
                  }}
                  type="button"
                  className="btn btn-warning text-white"
                  data-bs-dismiss="modal"
                >
                    Notificar
                </button>
              )}
              <button
                onClick={() => setShowModal(false)}
                type="button"
                className="btn btn-light"
                data-bs-dismiss="modal"
              >
                Fechar
              </button>
            </div>
            </div>
        </div>
    </div>
  )
}

export {ModalDetalhesPesquisaParticipante}