import React, {FC} from 'react'
import {KTSVG} from '../../../../../_metronic/helpers'
import {Link} from 'react-router-dom'
import { AbrirModalVideo } from '../../../toolbar/AbrirModalVideo'

const Step6: FC = () => {
  return (
    <>
      <AbrirModalVideo
        videoPath='https://pro4tech.blob.core.windows.net/visualstuff/Wizard%20Finalizar.mp4?si=maintaining&spr=https&sv=2022-11-02&sr=b&sig=xv1nav2Xj3wUrXZHM6FDqIz0%2FngSfdLdveTj4ZdUPaw%3D'
        videoTitle='Finalizar configurações'
        descricao='Com as parametrizações realizadas, hora de encerrar essa etapa de configurações e começar o processo de descrição de cargos com IA.'
      />
      <div className='w-100'>

        <div className='min-h-375px py-20'>
          <div className='fs-6 text-gray-600 mb-5'>
            Você acabou de cadastrar as informações básicas que precisamos! Caso seja necessário, você pode usar o menu lateral a esquerda para voltar a essa tela e editar os dados quando quiser.
          </div>

          <div className='notice d-flex bg-light-warning rounded border-warning border border-dashed p-6'>
            <KTSVG
              path='/media/icons/duotune/general/gen044.svg'
              className='svg-icon-2tx svg-icon-warning me-4'
            />
            <div id='step-6-configs' className='d-flex flex-stack flex-grow-1'>
              <div className='fw-bold'>
                <h4 className='text-gray-800 fw-bolder'>Tudo pronto!</h4>
                <div className='fs-6 text-gray-600'>
                  Agora clique no botão abaixo para finalizar as configurações iniciais e começar a descrever seus cargos com IA
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export {Step6}
