/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC } from 'react'
import { IWizardModel } from '../helpers/ResponderPesquisaHelper'
import { FormikProps } from 'formik'
import { KTSVG } from '../../../../../_metronic/helpers'

interface Step4Props {
  formik: FormikProps<IWizardModel>,
  isMobile: boolean
}

const Step4: FC<Step4Props> = ({formik, isMobile}) => {  
  return (
    <>
      <div className='w-100'>
        <div className='row'>
          <div className='notice d-flex bg-light-success rounded border-success border border-dashed p-6'>
            <KTSVG
              path='/media/icons/duotune/general/gen044.svg'
              className='svg-icon-2tx svg-icon-success me-4'
            />
            <div className='d-flex flex-stack flex-grow-1'>
              <div className='fw-bold'>
                <h4 className='text-gray-800 fw-bolder'>Pesquisa finalizada!</h4>
                <div className='fs-6 text-gray-600'>
                  Você já respondeu todas as perguntas dessa pesquisa. Agradecemos sua participação, muito obrigado!
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export {Step4}
