import clsx from 'clsx'
import { ErrorMessage, Field, FormikProvider, useFormik } from 'formik'
import { useEffect, useState } from 'react'
import { KTSVG } from '../../../../../_metronic/helpers'
import { useHttpClient } from '../../../../modules/services/Bundle'
import { RequestMethod } from '../../../../modules/services/core/_enums'
import { initialValues, defaultValidationSchemas } from '../helpers/ModalAddUsuarioHelper'
import InputMask from 'react-input-mask'
import { IconButton } from '@mui/material'
import { PasswordMeterComponent } from '../../../../../_metronic/assets/ts/components'

type Props = {
    abrirModal: boolean
    setAbrirModal: Function
    atualizarDados: Function
}


const ModalAddUsuario = ({abrirModal, setAbrirModal, atualizarDados}: Props) => {
    
    const httpClient = useHttpClient()
    const formik = useFormik({
        initialValues: initialValues,
        validationSchema: defaultValidationSchemas,
        onSubmit: async (values, actions) => {
            values.Telefone =  values.Telefone.replace(/\D/g, '')
            const response = await httpClient.request({
                method: RequestMethod.POST, 
                endpoint: '/Administracao/ParametrizarEmpresa/SalvarNovoUsuario',
                data: values
            })
            if (response.success) {

                setAbrirModal(false)
                atualizarDados()

                let defaultFormik = initialValues
                formik.setFieldValue('Nome', defaultFormik.Nome)
                formik.setFieldValue('Sobrenome', defaultFormik.Sobrenome)
                formik.setFieldValue('Email', defaultFormik.Email)
                formik.setFieldValue('Password', defaultFormik.Password)
                formik.setFieldValue('ConfirmPassword', defaultFormik.ConfirmPassword)
                formik.setFieldValue('Telefone', defaultFormik.Telefone)
                
            }
    
        },
    })

    useEffect(() => {
        PasswordMeterComponent.bootstrap()
    }, [])

    const [showPassword, setShowPassword] = useState(false);

    const handleClickShowPassword = () => setShowPassword((show) => !show);

    const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    };

    const [showConfirmPassword, setShowConfirmPassword] = useState(false);

    const handleClickShowConfirmPassword = () => setShowConfirmPassword((show) => !show);

    const handleMouseDownConfirmPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    };


    return (
    <div className={`modal ${abrirModal? "show" : ""} fade`} tabIndex={-1} id="kt_modal_1" style={{display: `${abrirModal? "block" : "none"}`}}>
        <div className="modal-dialog modal-dialog-centered modal-xl">
            <div className="modal-content">
            <div className="modal-header">
                <h5 className="modal-title">Criar novo usuario</h5>
                <div
                onClick={() => setAbrirModal(false)}
                className="btn btn-icon btn-sm btn-active-light-primary ms-2"
                data-bs-dismiss="modal"
                aria-label="Fechar"
                >
                <KTSVG
                    path="/media/icons/duotune/arrows/arr061.svg"
                    className="svg-icon svg-icon-2x"
                />
                </div>
            </div>
            <div className="modal-body">
                
                <FormikProvider value={formik}>
                    <div className='w-100'>
                        <div className="row">

                        <div className="col-lg-6 fv-row mb-5">
                            <label className='form-label required'>Nome</label>
                            <Field
                                name={`Nome`}
                                placeholder='Nome'
                                className='form-control form-control-lg form-control-solid'
                                value={formik.values.Nome}
                            />
                            <div className='text-danger mt-2'>
                                <ErrorMessage name='Nome' />
                            </div> 
                        </div>

                        <div className="col-lg-6 fv-row mb-5">
                            <label className='form-label required'>Sobrenome</label>
                            <Field
                                name={`Sobrenome`}
                                placeholder='Sobrenome'
                                className='form-control form-control-lg form-control-solid'
                                value={formik.values.Sobrenome}
                            />
                            <div className='text-danger mt-2'>
                                <ErrorMessage name='Sobrenome' />
                            </div> 
                        </div>

                        <div className="col-lg-8 fv-row mb-5">
                            <label className='form-label required'>E-mail</label>
                            <Field
                                name={`Email`}
                                placeholder='E-mail'
                                className='form-control form-control-lg form-control-solid'
                                value={formik.values.Email}
                            />
                            <div className='text-danger mt-2'>
                                <ErrorMessage name='Email' />
                            </div> 
                        </div>

                        <div className='col-lg-4 fv-row mb-8'>
                            <label className='form-label required'>Telefone</label>
                            <InputMask
                                mask={'(99) 99999-9999'}
                                placeholder='Telefone'
                                type='text'
                                autoComplete='off'
                                {...formik.getFieldProps('Telefone')}
                                className={clsx(
                                    'form-control form-control-lg form-control-solid'
                                )}
                            ></InputMask>
                            {formik.touched.Telefone && formik.errors.Telefone && (
                            <div className='fv-plugins-message-container'>
                                <div className='fv-help-block'>
                                <span role='alert'>{formik.errors.Telefone}</span>
                                </div>
                            </div>
                            )}
                        </div>

                        <div className='col-lg-6 fv-row mb-8' data-kt-password-meter='true'>
                            <label className='form-label required'>Senha</label>
                            <div className='mb-1'>
                            <div className='position-relative mb-3'>

                                <div className="wrapper-olho">
                                <div className={`${formik.touched.Password ? "icon-olho-distancia-maior" : "icon-olho"}`}>
                                    <IconButton
                                    aria-label="visualizar senha"
                                    onClick={handleClickShowPassword}
                                    onMouseDown={handleMouseDownPassword}
                                    edge="end"
                                    >
                                    {showPassword ?<i className="fas fa-eye"></i> : <i className="fas fa-eye-slash"></i>}
                                    </IconButton>
                                </div>
                                <input
                                    type={showPassword ? 'text' : 'password'}
                                    placeholder='Senha'
                                    autoComplete='off'
                                    {...formik.getFieldProps('Password')}
                                    className={clsx(
                                    'form-control form-control-lg form-control-solid',
                                    'input-olho'
                                    )}
                                />
                                </div>  
                                {formik.touched.Password && formik.errors.Password && (
                                <div className='fv-plugins-message-container'>
                                    <div className='fv-help-block'>
                                    <span role='alert'>{formik.errors.Password}</span>
                                    </div>
                                </div>
                                )}
                            </div>
                            <div
                                className='d-flex align-items-center mb-3'
                                data-kt-password-meter-control='highlight'
                            >
                                <div className='flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2'></div>
                                <div className='flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2'></div>
                                <div className='flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2'></div>
                                <div className='flex-grow-1 bg-secondary bg-active-success rounded h-5px'></div>
                            </div>
                            </div>
                            <div className='text-muted'>
                            Use 8 a 20 caracteres com uma mistura de letras, números e símbolos.
                            </div>
                        </div>

                        <div className='col-lg-6 fv-row mb-5'>
                            <label className='form-label required'>Confirmar Senha</label>
                            <div className="wrapper-olho">
                                <div className={`${formik.touched.ConfirmPassword ? "icon-olho-distancia-maior" : "icon-olho"}`}>
                                    <IconButton
                                    aria-label="visualizar senha"
                                    onClick={handleClickShowConfirmPassword}
                                    onMouseDown={handleMouseDownConfirmPassword}
                                    edge="end"
                                    >
                                    {showConfirmPassword ?<i className="fas fa-eye"></i> : <i className="fas fa-eye-slash"></i>}
                                    </IconButton>
                                </div>
                                <input
                                    type={showConfirmPassword ? 'text' : 'password'}
                                    placeholder='Confirmação de senha'
                                    autoComplete='off'
                                    {...formik.getFieldProps('ConfirmPassword')}
                                    className={clsx(
                                    'form-control form-control-lg form-control-solid',
                                    'input-olho'
                                    )}
                                />
                                </div>  
                                {formik.touched.ConfirmPassword && formik.errors.ConfirmPassword && (
                                <div className='fv-plugins-message-container'>
                                    <div className='fv-help-block'>
                                    <span role='alert'>{formik.errors.ConfirmPassword}</span>
                                    </div>
                                </div>
                                )}
                            </div>

                        </div>
                    </div>
                </FormikProvider>
            </div>
            <div className="modal-footer">
                <button
                onClick={() => setAbrirModal(false)}
                type="button"
                className="btn btn-light me-5"
                data-bs-dismiss="modal"
                >
                    Cancelar
                </button>
                
                <button type='button' onClick={() => formik.handleSubmit()} className='btn  btn-primary'>
                    Salvar
                </button>

            </div>
            </div>
        </div>
    </div>
  )
}

export {ModalAddUsuario}