import Chart from 'react-apexcharts'
import { IDadosGraficosResultadosGlobaisPesquisaModel } from '../helpers/AnaliseResultadoHelper';
import { toAbsoluteUrl } from '../../../../../_metronic/helpers';

type Props = {
  dadosGraficosPesquisa: IDadosGraficosResultadosGlobaisPesquisaModel | undefined
}

const GraficoResultadosGlobais  = ({dadosGraficosPesquisa}:Props) => {  
  let stackType: any = '100%'

  let options = {
    colors : dadosGraficosPesquisa?.Colors ?? [],
    series: dadosGraficosPesquisa?.Series ?? [],
    chart: {
      stacked: true,
      stackType: stackType,
      toolbar: {
        show: false
      },
    },
    plotOptions: {
      bar: {
        horizontal: false,
      },
    },
    stroke: {
      width: 1,
      colors: ['#fff']
    },
    xaxis: {
      categories: dadosGraficosPesquisa?.Categories ?? [],
    },
    fill: {
      opacity: 1    
    },
    legend: {
      offsetX: 40
    }
  }
  
  return (
    <>
      <div className={`card m-0 p-0 ${dadosGraficosPesquisa ? '' : 'd-none'}`}>        
        <div className='card-body m-0 p-0'>
          <div className='row justify-content-center m-0 p-0'>
            <div className='col-lg-6 mt-5 d-flex justify-content-center'>
              <img className='h-100' src={toAbsoluteUrl("/media/prolead/pesquisa/pesquisa-resultados-globais.svg")} />
            </div>
            <div className='col-lg-6 mt-5 p-15'>
              <Chart options={options} series={options.series} type="bar" height={'400px'} />
            </div>            
          </div>
        </div>        
      </div> 
    </>         
  )
}
  
export {GraficoResultadosGlobais}