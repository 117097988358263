import * as Yup from 'yup'
import { useValidation } from '../../../../modules/services/Bundle'


export interface IEmpresaModel {  
  Id?: number
  RazaoSocial: string
  EmailRepresentante: string
  IdSegmento?: number
  NomeSegmento: string
  QtdFuncionarios: number
  QtdMaximaCargos: number
  PorteEmpresa: number
  IdEmpresaCliente: number
  CaracteristicasDoNegocio: string
  UtilizaAvaliador: boolean
  PermiteEditarDescricao: boolean
  EmpresaDominio: string[]
}

const initValues: IEmpresaModel = {
  Id: undefined,
  RazaoSocial: '',
  EmailRepresentante: '',
  IdSegmento: undefined,
  NomeSegmento: '',
  QtdFuncionarios: 0,
  QtdMaximaCargos: 10,
  PorteEmpresa: 0,
  IdEmpresaCliente: 0,
  CaracteristicasDoNegocio: '',
  PermiteEditarDescricao: false,
  UtilizaAvaliador: false,
  EmpresaDominio: []
}

const defaultValidationSchemas = Yup.object().shape({
      RazaoSocial: Yup.string().required('A razão social é obrigatória'),
      IdSegmento: Yup.string().required('O segmento de mercado é obrigatório'),
      EmailRepresentante: Yup.string()
      .email('E-mail inválido')
      .test('company-email', 'O e-email deve ser corporativo', (val = '') => {
        if (!val) return true
        const validation = useValidation()
        return validation.CompanyEmail(val)
      })
      .min(3, 'O e-mail deve ter no mínimo 3 caracteres')
      .max(80, 'O e-mail deve ter no máximo 80 caracteres')
      .required('O e-mail é obrigatório'),
      NomeSegmento: Yup.string().when('IdSegmento', {
        is: '2',
        then: Yup.string().nullable()
        .required('O nome do segmento é obrigatório')
        .matches(/^[a-zA-Z\sáÁàÀâÂãÃéÉèÈêÊíÍìÌîÎóÓòÒôÔõÕúÚùÙûÛüÜñÑçÇ]*$/, 'O nome do segmento não pode conter números, caracteres especiais ou espaços consecutivos')
        .matches(/^(?!(.*\s){3})/, 'O nome do segmento contém muitos espaços consecutivos'),
        otherwise: Yup.string().nullable()
        // .matches(/^[a-zA-Z\sáÁàÀâÂãÃéÉèÈêÊíÍìÌîÎóÓòÒôÔõÕúÚùÙûÛüÜñÑçÇ]*$/, 'O nome do segmento não pode conter números, caracteres especiais ou espaços consecutivos')
        // .matches(/^(?!(.*\s){3})/, 'O nome do segmento contém muitos espaços consecutivos'),
      }),
      QtdFuncionarios: Yup.number().min(0, "A quantidade não pode ser negativa."),
      QtdMaximaCargos: Yup.number().min(1, "A quantidade deve ser maior que 0.").required('A quantidade máxima de cargos é obrigatória'),   
      EmpresaDominio: Yup.array().min(1, 'Deve ter pelo menos um domínio')
  })

export {defaultValidationSchemas, initValues}
