import { useEffect, useState } from 'react'
import { useHttpClient } from '../../../modules/services/Bundle'
import { RequestMethod } from '../../../modules/services/core/_enums'
import { GraficoMediaGeralPorParticipante } from './partials/GraficoMediaGeralPorParticipante'

export interface ISelectPesquisaModel {
  Id: number              
  Nome: string 
  IdPesquisaParticipante: number
  Texto?: string
}

const ResultadoProprio = () => {
  let [isMobile, setIsMobile] = useState(window.innerWidth < 992)
  let [pesquisas, setPesquisas] = useState<ISelectPesquisaModel[]>([])
  let [idPesquisa, setIdPesquisa] = useState<number>(0)
  let [idParticipante, setIdParticipante] = useState<string | null>("")
  const httpClient = useHttpClient()

  const obterParaSelectPesquisa = async () => {
    const response = await httpClient.request({
      method: RequestMethod.GET,
      endpoint: '/Pesquisa/Resultado/ObterParaSelectPorColaborador',
    })
    if (response.success && response.payload && response.payload.length > 0) {
      setPesquisas(response.payload)
    }
  }

  useEffect(() => {
    obterParaSelectPesquisa()
  },[])

  useEffect(() => {

    const onResizeWindow = () => {
      setIsMobile(window.innerWidth < 992)
    };

    window.addEventListener("resize", onResizeWindow);

    return () => window.removeEventListener("resize", onResizeWindow) 

  }, [])

  const onChangeSelectPesquisa = async (e: React.ChangeEvent<HTMLSelectElement>) => {
    setIdPesquisa(parseInt(e.target.value))  
    setIdParticipante(e.target.selectedOptions[0]?.getAttribute('data-participante'));
  }

  return (
    <>
      <div className='card mb-10'>
        <div
          className='card-header'
        >
          <div className='card-title m-0'>
            <h3 className='fw-bolder m-0'>Análise de Dados - Próprio</h3>
          </div>
        </div>

        <div className='card-body'>
            <div className='col-lg-6 fv-row'>
              <label className={`form-label text-gray-800`}>Selecione a pesquisa</label>
              <select
                className='form-select form-select-lg form-select-solid'
                onChange={(e: React.ChangeEvent<HTMLSelectElement>) => onChangeSelectPesquisa(e)}
              >
                <option value='0'>{'Selecione...'}</option>

                {pesquisas && pesquisas.length > 0
                  && pesquisas.map((data: ISelectPesquisaModel, index: number) => {
                    return (
                      <option key={`select-pesquisa-${index}`} value={data.Id} data-participante={data.IdPesquisaParticipante}>
                        {data.Nome}
                      </option>
                    )
                  })
                }
                
              </select>
            </div>
        </div>
      </div>
      {
        idPesquisa > 0 && idParticipante && idParticipante !== "" && (
          <GraficoMediaGeralPorParticipante
            isMobile={isMobile}
            dadosGraficosPesquisa={undefined}
            idParticipanteProprio={idParticipante}
          />
        )
      }


    </>
  )
}

export {ResultadoProprio}
