/* eslint-disable jsx-a11y/anchor-is-valid */
import { KTSVG } from '../../../../../_metronic/helpers'
import { useAlert, useHttpClient } from '../../../../modules/services/Bundle'
import { EnumPesquisaParticipanteStatusDeAlcance, RequestMethod } from '../../../../modules/services/core/_enums'
import { TablePesquisaParticipanteAlcance } from './TablePesquisaParticipanteAlcance'

type Props = {
  idPesquisa: number
  statusDeAlcance: number
  statusDeAlcanceColor: string
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>
}

const ModalGraficoAlcancePesquisa = ({setShowModal, idPesquisa, statusDeAlcance, statusDeAlcanceColor}: Props) => {

  const alert = useAlert()
  const httpClient = useHttpClient()
    
  return (
    <div className={`modal show fade`} tabIndex={-1} id="kt_modal_1" style={{display: `block`}}>
        <div className="modal-dialog modal-dialog-centered modal-xl">
            <div className="modal-content" style={{border: `1px solid ${statusDeAlcanceColor}`}}>
            <div className="modal-header" style={{backgroundColor: statusDeAlcanceColor}}>
                <h3 className="modal-title">{EnumPesquisaParticipanteStatusDeAlcance[statusDeAlcance]}</h3>
                <div
                  onClick={() => setShowModal(false)}
                  className="btn btn-icon btn-sm btn-active-light-primary ms-2"
                  data-bs-dismiss="modal"
                  aria-label="Fechar"
                >
                  <KTSVG
                      path="/media/icons/duotune/arrows/arr061.svg"
                      className="svg-icon svg-icon-2x"
                  />
                </div>
            </div>
            <div className="modal-body">
              <TablePesquisaParticipanteAlcance
                idPesquisa={idPesquisa}
                statusDeAlcance={statusDeAlcance}
                statusColor={statusDeAlcanceColor}
              />
            </div>
            <div className={`modal-footer ${statusDeAlcance !== EnumPesquisaParticipanteStatusDeAlcance.Finalizada ? 'd-flex justify-content-between' : ''}`}>
              {statusDeAlcance !== EnumPesquisaParticipanteStatusDeAlcance.Finalizada && (

                <button
                onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
                  e.currentTarget.blur()
                  alert.createDialog({
                    html: `Esta ação notificará todos os participantes com status (${EnumPesquisaParticipanteStatusDeAlcance[statusDeAlcance]}) na pesquisa, deseja prosseguir?`,
                    confirmAction: async () => {
                      httpClient.request({
                        method: RequestMethod.POST,
                        endpoint: '/Pesquisa/Resultado/NotificarTodosParticipantesPorStatusDeAlcance',
                        queryObject: {
                          idPesquisa: idPesquisa,
                          statusDeAlcance: statusDeAlcance
                        }
                      })
                    }
                  })
                }}
                type="button"
                className="btn btn-secondary"
                data-bs-dismiss="modal"
                style={{backgroundColor: `${statusDeAlcanceColor}`}}
                >
                  Notificar Todos
                </button>

              )}              
              <button
              onClick={() => setShowModal(false)}
              type="button"
              className="btn btn-light"
              data-bs-dismiss="modal"
              >
                  Fechar
              </button>
            </div>
            </div>
        </div>
    </div>
  )
}

export {ModalGraficoAlcancePesquisa}