import { ErrorMessage, Field, FormikProvider, useFormik } from 'formik'
import { KTSVG } from '../../../../../_metronic/helpers'
import { DropzoneComponent, useAlert, useHelper, useHttpClient } from '../../../../modules/services/Bundle'
import { RequestMethod } from '../../../../modules/services/core/_enums'
import { defaultCarregarColaboradores, defaultValidationSchemas } from '../helpers/ModalCarregarColaboradoresHelper'
import { FileModel } from '../../../../modules/services/core/_models'
import { useEffect, useState } from 'react'

type Props = {
    abrirModal: boolean
    setAbrirModal: Function
    colabCliente: boolean
}

const ModalCarregarColaboradores = ({abrirModal, setAbrirModal, colabCliente}: Props) => {
    
    const httpClient = useHttpClient()
    const alert = useAlert()
    const helper = useHelper()
    const [empresasCliente, setEmpresasCliente] = useState<any[]>([])

    const startDados = async () => {
        if (colabCliente) {
            const response = await httpClient.request({
                method: RequestMethod.GET,
                endpoint: '/DescricaoDeCargo/Consultoria/ObterClientesParaSelect',
            })

            if(response.success && response.payload) setEmpresasCliente(response.payload)
        }
        
        let newValues = {...defaultCarregarColaboradores}
        newValues.Empresa.ColabCliente = colabCliente
        formik.setValues(newValues) 
    }

    useEffect(() => {
        startDados()
      },[])
    
    const formik = useFormik({
        initialValues: defaultCarregarColaboradores,
        validationSchema: defaultValidationSchemas,
        onSubmit: async (values, actions) => {
            
            alert.createDialog({
                html: `Realmente deseja carregar esses colaboradores?`,
                confirmAction: async () => {
                    
                    const response = await httpClient.request({
                        method: RequestMethod.POST, 
                        endpoint: `/Gerenciamento/Usuario/Carregar`,
                        queryObject: {
                            isConsultoria: colabCliente,
                            idEmpresa: values.Empresa.IdEmpresa,
                        },
                        data: values.Dados,
                        ifSuccessRedirectTo: '/conta/uploads',
                    })
                    
                    if (response.success) {
                        setAbrirModal(false)
                    }

                }
            })
    
        },
    })

    const filesHandler = async (files: Dropzone.DropzoneFile[]) => { 
        if(files && files.length > 0){

            const file = files[0]
            const base64 = await helper.convertFileToBase64(file)
            
            formik.setFieldValue('Dados.Arquivo', {
                Nome: file.name,
                Extensao: file.name.split('.').pop(),
                Tamanho: file.size,
                Tipo: file.type,
                Base64: base64
            })

        }
        else formik.setFieldValue('Dados.Arquivo', undefined)
    }

    const onClickBaixarModeloCarregar = async () => { 
        
        const response = await httpClient.request({
            method: RequestMethod.GET, 
            endpoint: `/Gerenciamento/Usuario/BaixarModeloCarregar`
        })
        
        if (response.success && response.payload) {
            const file: FileModel = response.payload
            await helper.downloadFile(file)
        }

    }

    return (
    <div className={`modal show fade`} tabIndex={-1} id="kt_modal_1" style={{display: "block"}}>
        <div className="modal-dialog modal-dialog-centered modal-xl">
            <div className="modal-content">
                <div className="modal-header">
                    <h5 className="modal-title">Carregar colaboradores</h5>
                    <div
                    onClick={() => setAbrirModal(false)}
                    className="btn btn-icon btn-sm btn-active-light-primary ms-2"
                    data-bs-dismiss="modal"
                    aria-label="Fechar"
                    >
                    <KTSVG
                        path="/media/icons/duotune/arrows/arr061.svg"
                        className="svg-icon svg-icon-2x"
                    />
                    </div>
                </div>
                <div className="modal-body">
                    
                    <FormikProvider value={formik}>
                        <div className='w-100'>
                            <div className="row">

                                {colabCliente && (
                                    <div className='col-lg-12 fv-row mb-5'>
                                        <label className='form-label required'>Cliente</label>
                                        <Field
                                            as='select'
                                            name='Empresa.IdEmpresa'
                                            className='form-select form-select-lg form-select-solid'
                                            value={formik.values.Empresa.IdEmpresa}
                                        >
                                            <option value=''>Selecione...</option>
                                            {
                                                empresasCliente && empresasCliente.length > 0 ? empresasCliente.map((data: any, index: any) => {
                                                    return <option key={`IdEmpresa-option-${index}`} value={data.Id}>{data.Nome}</option>
                                                }) : ''
                                            }
                                        </Field>
                                        <div className='text-danger mt-2'>
                                            <ErrorMessage name='Empresa.IdEmpresa' />
                                        </div>
                                    </div>
                                )}

                                <div className='col-lg-12 fv-row mb-10'>
                                    <div className='notice d-flex bg-light-warning rounded border-warning border border-dashed p-6'>
                                        <KTSVG
                                        path='/media/icons/duotune/general/gen044.svg'
                                        className='svg-icon-2tx svg-icon-warning me-4'
                                        />
                                        <div className='d-flex flex-stack flex-grow-1'>
                                        <div className='fw-bold'>
                                            <div className='fs-6 text-gray-600'>
                                            Clique no botão abaixo para baixar o modelo e preencha os dados na coluna correta sem alterar os nomes das colunas ou da planilha, depois carregue o arquivo novamente
                                            </div>
                                        </div>
                                        </div>
                                    </div>
                                </div>

                                <div className='col-lg-12 fv-row mb-10 d-flex justify-content-center'>
                                    <button
                                        type='button'
                                        className='btn btn-lg btn-light-primary'
                                        onClick={onClickBaixarModeloCarregar}
                                    >
                                        <i className="fas fs-1 me-1 fa-file-download"></i>
                                        Baixar modelo
                                    </button>
                                </div>

                                <div className='col-lg-12 fv-row'>
                                    <DropzoneComponent 
                                        filesHandler={filesHandler}
                                        id='dz-carregar-colaboradores'
                                        preloadFiles={formik.values.Dados.Arquivo}
                                        options={{ 
                                            dictDefaultMessage: 'Arraste e solte o arquivo .xlsx do modelo preenchido com os dados ou clique aqui para procurar',
                                            acceptedFiles: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
                                            maxFiles: 1,
                                            maxFilesize: 2
                                        }}
                                    />
                                    <div className='text-danger mt-2'>
                                        <ErrorMessage name='Dados.Arquivo' />
                                    </div>
                                </div>

                            </div>
                        </div>
                    </FormikProvider>
                </div>
                <div className="modal-footer">
                    <button
                        onClick={() => setAbrirModal(false)}
                        type="button"
                        className="btn btn-light me-5"
                        data-bs-dismiss="modal"
                    >
                        Cancelar
                    </button>
                    
                    <button type='button' onClick={() => formik.handleSubmit()} className={`btn btn-primary`}>
                        Carregar
                    </button>

                </div>
            </div>
        </div>
    </div>
  )
}

export {ModalCarregarColaboradores}