import { Link, useNavigate } from 'react-router-dom'
import { toAbsoluteUrl } from '../../../../../_metronic/helpers'

type Props = {
  descricaoVideo: string
  videoName: string
  btnDisabled?: boolean
  videoPath: string
  className?: string
  btnTo: string
}

const BtnAbrirVideo = ({btnTo, videoName,descricaoVideo, btnDisabled = false,videoPath, className = ''}: Props) => {
  const navigate = useNavigate()

  return (
    <>
      <Link 
        to={btnTo} 
        state={{ 
          titleVideo: videoName,
          descriptionVideo: descricaoVideo,
          pathVideo: videoPath,
        }}>
        <div className={`card card-flush h-md-100 ${className}`}>
          <div
            className='card-body d-flex flex-column bgi-no-repeat bgi-size-cover bgi-position-x-center pb-0 min-h-300px'
            style={{
              backgroundPosition: '100% 50%',
              backgroundImage: `url('${toAbsoluteUrl('/media/stock/900x600/42.png')}')`,
            }}
          >
            <div aria-disabled className='row mt-5 mb-10 justify-content-center'>
                <video 
                  disablePictureInPicture
                  style={{backgroundColor: "black"}} 
                  className='ratio ratio-16x9 mw-300px mh-250px rounded'>
                  <source 
                    src={videoPath} 
                    type="video/mp4"
                  />
                </video>
            </div>
            <div className='row fw-bold text-gray-900 fs-5 justify-content-center min-h-35px'>
              <span className='me-2'>
                {videoName}   
              </span>
            </div>
            <div className='row mb-5 mt-2 justify-content-center'>
              <span className='me-2 text-gray-900'>
                {descricaoVideo}   
              </span>
            </div>
              
          </div>
        </div>
      </Link>
    </>
  )
}

export {BtnAbrirVideo}
