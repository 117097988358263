import { EnumPromptTipo } from "../../../../modules/services/core/_enums"

const TipoAsString = (item: EnumPromptTipo) => {
  switch (item) {
    case EnumPromptTipo.GerarDescricoes:
      return 'Gerar Descrições'
    case EnumPromptTipo.AdicionarResposta:
      return 'Adicionar Resposta'
    case EnumPromptTipo.AdicionarArgumento:
      return 'Adicionar Argumento'
    case EnumPromptTipo.RemoverArgumento:
      return 'Remover Argumento'
    case EnumPromptTipo.AprimorarRespostaAdicionando:
      return 'Aprimorar Resposta - Adicionando'
    case EnumPromptTipo.AprimorarRespostaRemovendo:
      return 'Aprimorar Resposta - Removendo'
    case EnumPromptTipo.ObterAcoesExistentes:
      return 'Obter Ações - Existentes'
    case EnumPromptTipo.ObterAcoesSugeridas:
      return 'Obter Ações - Sugeridas'
    case EnumPromptTipo.GerarTopicos:
      return 'Gerar Tópicos'
    case EnumPromptTipo.GerarDimensoes:
      return 'Gerar Dimensões'
    case EnumPromptTipo.GerarDimensoesPerguntas:
      return 'Gerar Dimensões Perguntas'
    case EnumPromptTipo.AdicionarTopico:
      return 'Adicionar Tópico'
    case EnumPromptTipo.AdicionarDimensao:
      return 'Adicionar Dimensão'
    case EnumPromptTipo.AdicionarDimensaoPergunta:
      return 'Adicionar Dimensão Pergunta'
  }
}

type Prompt = {
  Id: number
  IdTipoCampo?: number
  NomeTipoCampo: string
  Mensagem: string
  Tipo: number
  NomeTipo: string
  IdModulo: number
  NomeModulo: string
}

const initValues: Prompt = {
  Id: 0,
  IdTipoCampo: undefined,
  NomeTipoCampo: '',
  Mensagem: '',
  Tipo: 0,
  NomeTipo: '',
  IdModulo: 0,
  NomeModulo: ''
}

export {initValues, TipoAsString}

export type {Prompt}
