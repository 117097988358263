import Chart from 'react-apexcharts'
import { IDadosGraficosResultadosPorQuestaoPesquisaModel } from '../helpers/AnaliseResultadoHelper'
import { ISelectModel } from '../../../../modules/services/core/_models'
import Select from 'react-select'

type Props = {
  dadosGraficosPesquisa: IDadosGraficosResultadosPorQuestaoPesquisaModel | undefined
  dimensoes: ISelectModel[]
  dimensaoSelecionada: ISelectModel | undefined
  setDimensao: Function
}

const GraficoResultadosPorQuestao  = ({dadosGraficosPesquisa, dimensoes, dimensaoSelecionada, setDimensao}:Props) => {

  let stackType: any = '100%'

  let options = {
    colors : dadosGraficosPesquisa?.Colors ?? [],
    series: dadosGraficosPesquisa?.Series ?? [],
    chart: {
      stacked: true,
      stackType: stackType,
      toolbar: {
        show: false
      },
    },
    plotOptions: {
      bar: {
        horizontal: true,
      },
    },
    stroke: {
      width: 1,
      colors: ['#fff']
    },
    xaxis: {
      categories: dadosGraficosPesquisa?.Categories ?? [],
    },
    fill: {
      opacity: 1    
    },
    legend: {
      offsetX: 40
    }
  }
  
  return (
    <>
      <div className={`card m-0 p-0 ${dadosGraficosPesquisa ? '' : 'd-none'}`}>        
        <div className='card-body m-0 p-0'>
        <div className="row justify-content-end m-8 mb-0">
            <div className='fv-row col-lg-4'>
              <label className={`form-label text-gray-800`}>Selecione a dimensão</label>
              {dimensoes.length > 0 && (
                <Select 
                  placeholder='Selecione...' 
                  className='form-control-custom-select'
                  noOptionsMessage={() => 'Nenhuma opção encontrada'}
                  styles={{
                      control: (baseStyles, state) => ({
                      ...baseStyles,
                      border: 'none',
                      backgroundColor: '$input-solid-bg',
                      borderRadius: '7px',
                      height: '3.4rem',
                      color: '#5e6278',
                      fontSize: '1.15rem'
                      }),
                      menu: (baseStyles, state) => ({
                      ...baseStyles,
                      backgroundColor: '#F9F9F9',
                      }),
                      option: (provided, state) => ({
                      ...provided,
                      color: state.isFocused ? 'black' : '$input-solid-color',
                      }),
                  }}
                  options={dimensoes.map((data) => ({
                      value: data.Id,
                      label: data.Nome
                  }))}
                  value={dimensaoSelecionada ? {
                    value: dimensaoSelecionada?.Id,
                    label: dimensaoSelecionada?.Nome
                  } : null}
                  isSearchable
                  onChange={(e: any) => setDimensao(e)}
                />
              )}

            </div>
          </div>
          <div className='row justify-content-center m-0 p-0'>
            <Chart options={options} series={options.series} type="bar" />           
          </div>
        </div>        
      </div> 
    </>         
  )
}
  
export {GraficoResultadosPorQuestao}