import {FC, useEffect, useState} from 'react'
import {KTSVG, toAbsoluteUrl} from '../../../../../_metronic/helpers'
import {Field, ErrorMessage, FormikProps} from 'formik'
import { IPesquisaRespostaModel, ITemaModel, IWizardModel, StepProps } from '../helpers/ConfiguracoesIniciaisHelper'
import { Tooltip, useAlert, useHttpClient } from '../../../../modules/services/Bundle'
import { RequestMethod } from '../../../../modules/services/core/_enums'
import $ from 'jquery'
import { AbrirModalVideo } from '../../../toolbar/AbrirModalVideo'
import { IconButton } from '@mui/material'
import { PesquisaAccordions } from '../partials/PesquisaAccordions'

interface Step2Props {
  formik: FormikProps<IWizardModel>,
  numeroStep: number
  indexSelecionado: number | undefined,
  setIndexSelecionado: React.Dispatch<React.SetStateAction<number | undefined>>,
  tituloSelecionado: number | undefined,
  setTituloSelecionado: React.Dispatch<React.SetStateAction<number | undefined>>,
  textoSelecionado: number | undefined,
  setTextoSelecionado: React.Dispatch<React.SetStateAction<number | undefined>>,
  textoSendoEditado: boolean,
  setTextoSendoEditado: React.Dispatch<React.SetStateAction<boolean>>,
  textoBackup: IPesquisaRespostaModel | undefined,
  setTextoBackup: React.Dispatch<React.SetStateAction<IPesquisaRespostaModel | undefined>>,
}

const Step2: FC<Step2Props> = ({
  formik,
  indexSelecionado,
  setIndexSelecionado,
  tituloSelecionado,
  setTituloSelecionado,
  textoSelecionado,
  setTextoSelecionado,
  textoSendoEditado,
  setTextoSendoEditado,
  textoBackup,
  setTextoBackup,
  numeroStep
}) => {
  const httpClient = useHttpClient()
  const alert = useAlert()

  const onClickBuscarTopicos = async () => {
    if (formik.values.temaBuscar.Nome !== '' && formik.values.topicosBuscar > 0) {
      const response = await httpClient.request({
        method: RequestMethod.GET,
        endpoint: '/Pesquisa/ConfiguracoesIniciais/BuscarTopicos',
        queryObject: {
          nomeTema: formik.values.temaBuscar.Nome,
          qtdTopicos: formik.values.topicosBuscar,
          idTema: formik.values.temaBuscar.Id
        }
      })
  
      if (response.success && response.payload) {
        
        const newValues = formik.values.tema

        const tema:ITemaModel = response.payload
        newValues.push(tema)

        formik.setFieldValue('tema', newValues)
        formik.setFieldValue('temaBuscar.Nome', "")
        formik.setFieldValue('topicosBuscar', 0)
      }
      
    }
  }

  return (
    <>
      <div className='w-100 row '>   
        <div className='pt-3 mb-3 px-6'>
            <div className='title_box border border-dark rounded row px-3'>
              <div >
                <label id="title" className='px-4'>Adicione os temas que deseja pesquisar</label>
              </div>
              <div className='col-lg-7 mb-5' style={{textAlign: 'left'}}>
                <label className='form-label required'>Tema</label>
                <Field 
                  name='temaBuscar.Nome'
                  placeholder='Tema'
                  className={`form-control form-control-lg form-control-solid`}
                  value={formik.values.temaBuscar.Nome}
                />
                <div className='text-danger mt-2'>
                  <ErrorMessage name='temaBuscar.Nome' />
                </div>
              </div>
              <div className='col-lg-4 mb-5' style={{textAlign: 'left'}}>
                <label className='form-label required'>Numero de sentenças para explicar o tema</label>
                <Field 
                  name='topicosBuscar'
                  type='number'
                  placeholder='Números de tópicos'
                  value={formik.values.topicosBuscar}
                  className='form-control form-control-lg form-control-solid'
                />
                <div className='text-danger mt-2'>
                  <ErrorMessage name='topicosBuscar' />
                </div>
              </div>
              <div className='col-lg-1 mb-5 d-flex align-items-end'>
                <div>
                  <IconButton title='Adicionar tema' disabled={!(formik.values.temaBuscar.Nome != "" && formik.values.topicosBuscar > 0)} className='icon-button-dark-hover' onClick={() => onClickBuscarTopicos()}>
                    <KTSVG path="/media/icons/duotune/arrows/arr017.svg" className="svg-icon-success svg-icon-3hx" />
                  </IconButton>
                </div>
              </div>
            </div>
        </div>
            <div className='col-lg-12 mb-5 scroll scroll-height-step-4 h-475px '>
              <PesquisaAccordions
                formik={formik}
                indexDimensaoSelecionada={indexSelecionado}
                numeroStep={numeroStep}
                tituloSelecionado={tituloSelecionado}
                setTituloSelecionado={setTituloSelecionado}
                textoSelecionado={textoSelecionado}
                setTextoSelecionado={setTextoSelecionado}
                textoSendoEditado={textoSendoEditado}
                setTextoSendoEditado={setTextoSendoEditado}
                textoBackup={textoBackup}
                setTextoBackup={setTextoBackup} 
              />
              <div className='text-danger mt-2'>
                
              </div>
            </div>
      </div>
    </>
  )
}

export {Step2}
