import * as Yup from 'yup'
import { useValidation } from '../../../../modules/services/Bundle'


export interface IColaboradorModel {
  Nome: string,
  Sobrenome: string,
  Email: string,
  CPF: string,
  Salario: string,
  IdSuperior?: number,
  IdCargo?: number,
  UsuarioPerfil?: IUsuarioPerfilModel[],
  IdPerfil: number,
  DataAdmissao: string,
  Ativo: boolean
  UsuarioFoto?: IUsuarioFotoModel
  PerfilPermissao?: []
  IdEmpresa?: number,
  ColabCliente?: boolean,
}

export interface IUsuarioFotoModel {
    Nome: string,
    Extensao: string,
    Tamanho: number,
    Tipo: string,
    Base64: string
}

export interface IUsuarioPerfilModel {
    Id?: number,
    IdUsuario?: number,
    IdPerfil?: number,
    IdPerfilNavigation?: any
}

const initialValues:IColaboradorModel = {
  Nome: '',
  Sobrenome: '',
  Email: '',
  CPF: '',
  Salario: '',
  IdSuperior: undefined,
  IdCargo: undefined,
  IdPerfil: 0,
  DataAdmissao: '',
  UsuarioFoto: undefined,
  PerfilPermissao: [],
  Ativo: true,
  IdEmpresa: undefined,
  ColabCliente: false,
}

const defaultValidationSchemas = Yup.object().shape({
  Nome: Yup.string()
    .min(3, 'O nome deve ter no mínimo 3 caracteres')
    .max(50, 'O nome deve ter no máximo 50 caracteres')
    .required('O nome é obrigatório'),
  Sobrenome: Yup.string()
    .min(3, 'O sobrenome deve ter no mínimo 3 caracteres')
    .max(50, 'O sobrenome deve ter no máximo 50 caracteres')
    .required('O sobrenome é obrigatório'),
  Email: Yup.string()
    .email('E-mail inválido')
    .min(3, 'O e-mail deve ter no mínimo 3 caracteres')
    .max(80, 'O e-mail deve ter no máximo 80 caracteres')
    .required('O e-mail é obrigatório'),
  CPF: Yup.string()
    .test('validCPF', 'CPF inválido', (val = '') => {
        if (!val) return true
        const validation = useValidation()
        return validation.CPF(val)
    })
    .required("O CPF é obrigatório"),
  PerfilPermissao: Yup
    .array()
    .of(
      Yup.object().shape({
        value: Yup.number().required('Selecione um perfil válido'),
        label: Yup.string().required('Selecione um perfil válido'),
      })
    )
    .min(1, 'Selecione pelo menos um perfil'),
    IdEmpresa: Yup.number().when('ColabCliente', {
      is: true,
      then: Yup.number().nullable()
      .required('A empresa cliente é obrigatória'),
      otherwise: Yup.number().nullable()
    }),
})

export {defaultValidationSchemas, initialValues}
