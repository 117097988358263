import {ErrorMessage, Field, FormikProvider, useFormik} from 'formik'
import {useEffect, useRef, useState} from 'react'
import {Link, useLocation, useNavigate} from 'react-router-dom'
import {useAlert, useHttpClient} from '../../../modules/services/Bundle'
import {EnumPorteEmpresa, EnumServicoTipoPlano, RequestMethod} from '../../../modules/services/core/_enums'
import {
  defaultModuloDescricaoConfig,
  defaultModuloPesquisaConfig,
  defaultValidationSchemas,
  initValues,
} from './helpers/EmpresaCriarHelper'
import {AdapterDayjs} from '@mui/x-date-pickers/AdapterDayjs'
import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider'
import {DatePicker} from '@mui/x-date-pickers/DatePicker'
import {ptBR} from '@mui/x-date-pickers/locales'
import br from 'dayjs/locale/pt-br'
import dayjs from 'dayjs'
import {KTSVG} from '../../../../_metronic/helpers'
import {ModalAddUsuario} from './partials/ModalAddUsuario'
import { PasswordMeterComponent } from '../../../../_metronic/assets/ts/components'
import InputMask from 'react-input-mask'
import clsx from 'clsx'
import { IconButton } from '@mui/material'
import { MultipleSelect } from '../../descricao/components/partials/ModalAddCliente'

const CriarEmpresa = () => {

  const navigate = useNavigate()
  const httpClient = useHttpClient()
  const [buyAtivo, setBuyAtivo] = useState(false)
  const [tipoPlanoBuy, setTipoPlanoBuy] = useState(false)
  const datePickerRef = useRef()
  const alert = useAlert()
  const [segmentos, setSegmentos] = useState<any[]>([])
  const [showNomeSegmento, setShowNomeSegmento] = useState<boolean>(false);
  const [selectedOptions, setSelectedOptions] = useState<string[]>([])

  const requestSeguimentos = async () => {
  
      await httpClient.request({
        method: RequestMethod.GET,
        endpoint: '/Gerenciamento/Segmento/ObterParaSelect',
      }).then((response) => {
        if(response.success && response.payload) setSegmentos(response.payload)
      })

  }
  
  useEffect(() => {
    requestSeguimentos()
    PasswordMeterComponent.bootstrap()
  },[])

  useEffect(() => {
    formik.setFieldValue('EmpresaDominio', selectedOptions);
  },[selectedOptions])

  const formik = useFormik({
    initialValues: {...initValues},
    validationSchema: defaultValidationSchemas,
    onSubmit: async (values, actions) => {
      values.Representante.Telefone =  values.Representante.Telefone.replace(/\D/g, '') 
      const response = await httpClient.request({
        method: RequestMethod.POST,
        endpoint: '/Administracao/ParametrizarEmpresa/Adicionar',
        data: values,
        ifSuccessRedirectTo: '/administracao/empresas',
      })
    },
  })

  const onChangeCheckTipoPlano = (e: any) => {
    const valor = e.target.value

    if (valor.toString() === 'false') {
      formik.setFieldValue('Servico.tipoPlano', EnumServicoTipoPlano.Buy)
      setTipoPlanoBuy(true)
    } else {
      formik.setFieldValue('Servico.tipoPlano', EnumServicoTipoPlano.Try)
      setTipoPlanoBuy(false)
    }
  }

  const onChangeCheckConsultoria = (e: any) => {
    const valor = e.target.value

    if (valor.toString() === 'false') {
      formik.setFieldValue('Servico.moduloDescricaoConfig.Consultoria', true)
    } else {
      formik.setFieldValue('Servico.moduloDescricaoConfig.Consultoria', false)
    }
  }

  const onChangeAtivarDescricaoDeCargo = (e: any) => {
    const valor = e.target.value

    if (valor.toString() === 'true') {
      formik.setFieldValue(
        'Servico.moduloDescricaoConfig.QtdMaximaCargos',
        defaultModuloDescricaoConfig.QtdMaximaCargos
      )
      formik.setFieldValue(
        'Servico.moduloDescricaoConfig.usaAprovacao',
        defaultModuloDescricaoConfig.usaAprovacao
      )
      formik.setFieldValue('Servico.moduloDescricaoConfig.ativo', defaultModuloDescricaoConfig.ativo)
    } else formik.setFieldValue('Servico.moduloDescricaoConfig.ativo', true)
  }

  const onChangeAtivarPesquisa = (e: any) => {
    const valor = e.target.value

    if (valor.toString() === 'true') {
      formik.setFieldValue(
        'Servico.moduloPesquisaConfig.QtdMaximaTemas',
        defaultModuloPesquisaConfig.QtdMaximaTemas
      )
      formik.setFieldValue(
        'Servico.moduloPesquisaConfig.QtdMaximaPesquisas',
        defaultModuloPesquisaConfig.QtdMaximaPesquisas
      )
      formik.setFieldValue(
        'Servico.moduloPesquisaConfig.QtdMaximaParticipantes',
        defaultModuloPesquisaConfig.QtdMaximaParticipantes
      )
      formik.setFieldValue('Servico.moduloPesquisaConfig.ativo', defaultModuloPesquisaConfig.ativo)
    } else formik.setFieldValue('Servico.moduloPesquisaConfig.ativo', true)
  }

  const onChangeUsaAprovacaoDescricaoDeCargo = (e: any) => {
    const valor = e.target.value

    if (valor.toString() === 'true')
      formik.setFieldValue('Servico.moduloDescricaoConfig.usaAprovacao', false)
    else formik.setFieldValue('Servico.moduloDescricaoConfig.usaAprovacao', true)
  }

  const onChangeAutoGerenciarPerfis = (e: any) => {
    const valor = e.target.value

    if (valor.toString() === 'true') formik.setFieldValue('Servico.AutoGerenciarPerfis', false)
    else formik.setFieldValue('Servico.AutoGerenciarPerfis', true)
  }

  const onChangeDatePickerStart = (e: any) => {
    if (e && dayjs(e.$d).toString() !== 'Invalid Date') {
      formik.setFieldValue('Servico.PeriodoAcessoDataInicial', dayjs(e.$d).format('YYYY-MM-DD'))
    } else if (e === null) {
      formik.setFieldValue('Servico.PeriodoAcessoDataInicial', '')
    }
  }

  const onChangeDatePickerEnd = (e: any) => {
    if (e && dayjs(e.$d).toString() !== 'Invalid Date')
      formik.setFieldValue('Servico.PeriodoAcessoDataFinal', dayjs(e.$d).format('YYYY-MM-DD'))
    else if (e === null) formik.setFieldValue('Servico.PeriodoAcessoDataFinal', '')
  }

  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
  event.preventDefault();
  };

  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const handleClickShowConfirmPassword = () => setShowConfirmPassword((show) => !show);

  const handleMouseDownConfirmPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
  event.preventDefault();
  };

  return (
    <>
      <div className='card mb-xl-10 container'>
        <div className='card-header'>
          <div className='card-title m-0'>
            <h3 className='fw-bolder m-0'>Criar Empresa</h3>
          </div>
        </div>

        <div className='card-body '>
          <FormikProvider value={formik}>
            <div className='w-100 '>
              <form noValidate>
                <div className='row'>

                  <div className='col-lg-12 fv-row mb-5'>
                    <label className='form-label required'>Razão Social</label>
                    <Field 
                        name='RazaoSocial'
                        placeholder='Nome'
                        className='form-control form-control-lg form-control-solid'
                        value={formik.values.RazaoSocial}
                    />
                    <div className='text-danger mt-2'>
                        <ErrorMessage name='RazaoSocial' />
                    </div>
                    </div>
                    <div className='col-lg-12 fv-row mb-5'>
                    <label className='form-label required'>Segmento de mercado</label>
                    <Field
                        as='select'
                        name='IdSegmento'
                        className='form-select form-select-lg form-select-solid'
                        value={formik.values.IdSegmento}
                        onChange={(event: { target: { value: number } }) => {
                        formik.handleChange(event);
                        // Aqui você pode acessar o valor selecionado através de event.target.value
                        setShowNomeSegmento(event.target.value == 2);
                        }}
                    >
                        <option value=''>Selecione...</option>
                        {
                        segmentos && segmentos.length > 0 ? segmentos.map((data: any, index: any) => {
                            return <option key={`IdSegmento-option-${index}`} value={data.Id}>{data.Nome}</option>
                        }) : ''
                        }
                    </Field>
                    <div className='text-danger mt-2'>
                        <ErrorMessage name='IdSegmento' />
                    </div>
                    </div>
                    {(showNomeSegmento || formik.values.IdSegmento == 2) && (
                        <div className="col-lg-12 fv-row mb-5">
                        <label className='form-label required'>Nome do Segmento:</label>
                        <Field
                            name={`NomeSegmento`}
                            placeholder='Segmento de mercado'
                            className='form-control form-control-lg form-control-solid'
                            value={formik.values.NomeSegmento}
                        />
                        <div className='text-danger mt-2'>
                            <ErrorMessage name='NomeSegmento' />
                        </div>
                        </div>
                    )}
                    <div className='col-lg-6 fv-row mb-5'>
                    <label className='form-label'>Porte da empresa</label>
                    <Field
                        as='select'
                        name='PorteEmpresa'
                        className='form-select form-select-lg form-select-solid'
                        value={formik.values.PorteEmpresa}
                    >
                        <option value='0'>Selecione...</option>
                        {Object.keys(EnumPorteEmpresa).map(key => (
                        
                        parseFloat(key) > 0 && (
                            <option key={key} value={key}>
                            {EnumPorteEmpresa[parseFloat(key)]}
                            </option>
                        )
                        
                        ))}
                    </Field>
                    </div>
                    <div className="col-lg-6 fv-row mb-5">
                    <label className='form-label'>Quantidade de funcionários</label>
                    <Field
                        type={`number`}
                        name={`QtdFuncionarios`}
                        placeholder='Quantidade de funcionários'
                        className='form-control form-control-lg form-control-solid'
                        value={formik.values.QtdFuncionarios}
                    />
                  </div>

                  <div className='col-lg-4 fv-row mb-5'>
                    <label className='form-label required'>Status da Versão da Empresa</label>
                    <div className='form-check form-switch form-check-custom form-check-solid mx-0 mt-4'>
                      <label className='form-label m-0 fs-3'>Ativar a versão buy?</label>
                      <Field
                        type='checkbox'
                        name={`Servico.tipoPlano`}
                        placeholder='Módulo de descrição de cargos'
                        className='form-check-input ms-2 h-25px w-40px'
                        onChange={(e: any) => onChangeCheckTipoPlano(e)}
                        checked={tipoPlanoBuy}
                        value={tipoPlanoBuy}
                        disabled={buyAtivo}
                      ></Field>
                    </div>
                  </div>
                  <div className='col-lg-8 fv-row mb-5'>                    
                      <div className='row'>
                        <div className='col-lg-5'>
                          <label className='form-label fs-6 fw-semibold required'>Período de Acesso - Início:</label>
                          <LocalizationProvider adapterLocale={br.name} dateAdapter={AdapterDayjs}>
                            <DatePicker
                              format='DD/MM/YYYY'
                              inputRef={datePickerRef.current}
                              {...formik.getFieldProps('Servico.PeriodoAcessoDataInicial')}
                              localeText={
                                ptBR.components.MuiLocalizationProvider.defaultProps.localeText
                              }
                              onChange={(e: any) => onChangeDatePickerStart(e)}
                              className='date-picker-color w-100'
                              value={
                                formik.values.Servico.PeriodoAcessoDataInicial !== ''
                                  ? formik.values.Servico.PeriodoAcessoDataInicial
                                  : null
                              }
                              slotProps={{
                                textField: {size: 'small', margin: 'dense'},
                              }}
                            />
                          </LocalizationProvider>
                          <div className='text-danger mt-2'>
                            <ErrorMessage name='Servico.PeriodoAcessoDataInicial' />
                          </div>
                        </div>
                        <div className='col-lg-2 d-flex justify-content-center align-items-center mt-4'>
                          <KTSVG
                            path='/media/icons/duotune/arrows/arr010.svg'
                            className='svg-icon-muted svg-icon-2hx text-gray-800'
                          />
                        </div>
                        <div className='col-lg-5'>
                          <label className='form-label fs-6 fw-semibold required'>Período de Acesso - Fim:</label>
                          <LocalizationProvider adapterLocale={br.name} dateAdapter={AdapterDayjs}>
                            <DatePicker
                              format='DD/MM/YYYY'
                              inputRef={datePickerRef.current}
                              {...formik.getFieldProps('Servico.PeriodoAcessoDataFinal')}
                              localeText={
                                ptBR.components.MuiLocalizationProvider.defaultProps.localeText
                              }
                              onChange={(e: any) => onChangeDatePickerEnd(e)}
                              className='date-picker-color w-100'
                              value={
                                formik.values.Servico.PeriodoAcessoDataFinal !== ''
                                  ? formik.values.Servico.PeriodoAcessoDataFinal
                                  : null
                              }
                              slotProps={{
                                textField: {size: 'small', margin: 'dense'},
                              }}
                            />
                          </LocalizationProvider>
                          <div className='text-danger mt-2'>
                            <ErrorMessage name='Servico.PeriodoAcessoDataFinal' />
                          </div>
                        </div>
                      </div>
                  </div>
                </div>               
                <div className='row'>
                  <div className='col-lg-6 fv-row mb-5'>
                    <label className='form-label required'>
                      Quantidade máxima de usuários permitidos
                    </label>
                    <Field
                      type='number'
                      name={`Servico.QtdMaximaUsuarios`}
                      placeholder='Quantidade máxima de usuários permitidos'
                      className='form-control form-control-lg form-control-solid'
                      value={formik.values.Servico.QtdMaximaUsuarios}
                    ></Field>
                    <div className='text-danger mt-2'>
                      <ErrorMessage name='Servico.QtdMaximaUsuarios' />
                    </div>
                  </div>
                </div>
                <div className='row'>            
                  <div className='col-lg-12 fv-row'>
                    <label className='form-label required'>Módulos</label>
                  </div>
                  <div className='col-lg-12 fv-row mb-5'>
                    <div className='w-100fv-row border border-2 rounded p-2'>
                      <div className='col-lg-12 fv-row m-2'>
                        <div className='form-check form-switch form-check-custom form-check-solid m-0'>
                          <label className='form-label m-0 fs-3'>
                            Ativar módulo Descrição de Cargos?
                          </label>
                          <Field
                            type='checkbox'
                            name={`Servico.moduloDescricaoConfig.ativo`}
                            placeholder='Módulo de descrição de cargos'
                            className='form-check-input ms-2 h-25px w-40px'
                            onChange={(e: any) => onChangeAtivarDescricaoDeCargo(e)}
                            checked={formik.values.Servico.moduloDescricaoConfig.ativo}
                            value={formik.values.Servico.moduloDescricaoConfig.ativo}
                          ></Field>
                        </div>
                      </div>
                      {formik.values.Servico.moduloDescricaoConfig.ativo && (
                        <div className='accordion mt-5' id='kt_accordion_1'>
                          <div className='accordion-item'>
                            <h2 className='accordion-header' id='kt_accordion_1_header_1'>
                              <button
                                className='accordion-button fs-4 fw-bold collapsed'
                                type='button'
                                data-bs-toggle='collapse'
                                data-bs-target='#kt_accordion_1_body_1'
                                aria-expanded='false'
                                aria-controls='kt_accordion_1_body_1'
                              >
                                Parâmetros de descrição de cargos
                              </button>
                            </h2>
                            <div
                              id='kt_accordion_1_body_1'
                              className='accordion-collapse collapse'
                              aria-labelledby='kt_accordion_1_header_1'
                              data-bs-parent='#kt_accordion_1'
                            >
                              <div className='accordion-body row'>
                                <div className='col-lg-4 fv-row mb-5'>
                                  <label className='form-label required'>
                                    Quantidade máxima de cargos permitidos
                                  </label>
                                  <Field
                                    type='number'
                                    name={`Servico.moduloDescricaoConfig.QtdMaximaCargos`}
                                    placeholder='Quantidade máxima de cargos permitidos'
                                    className='form-control form-control-lg form-control-solid'
                                    value={formik.values.Servico.moduloDescricaoConfig.QtdMaximaCargos}
                                  ></Field>
                                  <div className='text-danger mt-2'>
                                    <ErrorMessage name='Servico.moduloDescricaoConfig.QtdMaximaCargos' />
                                  </div>
                                </div>
                                <div className='col-lg-4 fv-row mb-5'>
                                  <label className='form-label'></label>
                                  <div className='form-check form-switch form-check-custom form-check-solid d-flex justify-content-between mx-0 mt-2 border border-1 rounded ps-5 py-3'>
                                    <label className='form-label m-0'>
                                      Utiliza workflow de aprovação?
                                    </label>
                                    <Field
                                      type='checkbox'
                                      name={`Servico.moduloDescricaoConfig.usaAprovacao`}
                                      placeholder='Módulo de descrição de cargos'
                                      className='form-check-input me-2 h-25px w-40px'
                                      onChange={(e: any) => onChangeUsaAprovacaoDescricaoDeCargo(e)}
                                      checked={formik.values.Servico.moduloDescricaoConfig.usaAprovacao}
                                      value={formik.values.Servico.moduloDescricaoConfig.usaAprovacao}
                                    ></Field>
                                  </div>
                                </div>
                                <div className='col-lg-4 fv-row mb-5'>
                                  <label className='form-label'></label>
                                  <div className='form-check form-switch form-check-custom form-check-solid d-flex justify-content-between mx-0 mt-2 border border-1 rounded ps-5 py-3'>
                                    <label className='form-label m-0'>
                                      Empresa é consultoria?
                                    </label>
                                    <Field
                                      type='checkbox'
                                      name={`Servico.moduloDescricaoConfig.Consultoria`}
                                      placeholder='Módulo de descrição de cargos'
                                      className='form-check-input me-2 h-25px w-40px'
                                      onChange={(e: any) => onChangeCheckConsultoria(e)}
                                      checked={formik.values.Servico.moduloDescricaoConfig.Consultoria}
                                      value={formik.values.Servico.moduloDescricaoConfig.Consultoria}
                                    ></Field>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className='col-lg-12 fv-row mb-5'>
                    <div className='w-100fv-row border border-2 rounded p-2'>
                      <div className='col-lg-12 fv-row m-2'>
                        <div className='form-check form-switch form-check-custom form-check-solid m-0'>
                          <label className='form-label m-0 fs-3'>
                            Ativar módulo Pesquisa?
                          </label>
                          <Field
                            type='checkbox'
                            name={`Servico.moduloPesquisaConfig.ativo`}
                            placeholder='Módulo de pesquisa'
                            className='form-check-input ms-2 h-25px w-40px'
                            onChange={(e: any) => onChangeAtivarPesquisa(e)}
                            checked={formik.values.Servico.moduloPesquisaConfig.ativo}
                            value={formik.values.Servico.moduloPesquisaConfig.ativo}
                          ></Field>
                        </div>
                      </div>
                      {formik.values.Servico.moduloPesquisaConfig.ativo && (
                        <div className='accordion mt-5' id='kt_accordion_2'>
                          <div className='accordion-item'>
                            <h2 className='accordion-header' id='kt_accordion_2_header_1'>
                              <button
                                className='accordion-button fs-4 fw-bold collapsed'
                                type='button'
                                data-bs-toggle='collapse'
                                data-bs-target='#kt_accordion_2_body_1'
                                aria-expanded='false'
                                aria-controls='kt_accordion_2_body_1'
                              >
                                Parâmetros de pesquisa
                              </button>
                            </h2>
                            <div
                              id='kt_accordion_2_body_1'
                              className='accordion-collapse collapse'
                              aria-labelledby='kt_accordion_2_header_1'
                              data-bs-parent='#kt_accordion_2'
                            >
                              <div className='accordion-body row'>
                                <div className='col-lg-4 fv-row mb-5'>
                                  <label className='form-label required'>
                                    Quantidade máxima de temas
                                  </label>
                                  <Field
                                    type='number'
                                    name={`Servico.moduloPesquisaConfig.QtdMaximaTemas`}
                                    placeholder='Quantidade máxima de temas permitidos'
                                    className='form-control form-control-lg form-control-solid'
                                    value={formik.values.Servico.moduloPesquisaConfig.QtdMaximaTemas}
                                  ></Field>
                                  <div className='text-danger mt-2'>
                                    <ErrorMessage name='Servico.moduloPesquisaConfig.QtdMaximaTemas' />
                                  </div>
                                </div>
                                <div className='col-lg-4 fv-row mb-5'>
                                  <label className='form-label required'>
                                    Quantidade máxima de pesquisas
                                  </label>
                                  <Field
                                    type='number'
                                    name={`Servico.moduloPesquisaConfig.QtdMaximaPesquisas`}
                                    placeholder='Quantidade máxima de pesquisas permitidos'
                                    className='form-control form-control-lg form-control-solid'
                                    value={formik.values.Servico.moduloPesquisaConfig.QtdMaximaPesquisas}
                                  ></Field>
                                  <div className='text-danger mt-2'>
                                    <ErrorMessage name='Servico.moduloPesquisaConfig.QtdMaximaPesquisas' />
                                  </div>
                                </div>
                                <div className='col-lg-4 fv-row mb-5'>
                                  <label className='form-label required'>
                                    Quantidade máxima de participantes
                                  </label>
                                  <Field
                                    type='number'
                                    name={`Servico.moduloPesquisaConfig.QtdMaximaParticipantes`}
                                    placeholder='Quantidade máxima de participantes permitidos'
                                    className='form-control form-control-lg form-control-solid'
                                    value={formik.values.Servico.moduloPesquisaConfig.QtdMaximaParticipantes}
                                  ></Field>
                                  <div className='text-danger mt-2'>
                                    <ErrorMessage name='Servico.moduloPesquisaConfig.QtdMaximaParticipantes' />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>     
                </div>                
                <div className='w-100'>
                <hr />

                <h3 className='fw-bolder mb-5'>Usuário Master</h3>
                <div className="row">
                  <div className="col-lg-6 fv-row mb-5">
                      <label className='form-label required'>Nome</label>
                      <Field
                          name={`Representante.Nome`}
                          placeholder='Nome'
                          className='form-control form-control-lg form-control-solid'
                          value={formik.values.Representante.Nome}
                      />
                      <div className='text-danger mt-2'>
                          <ErrorMessage name='Representante.Nome' />
                      </div> 
                  </div>

                  <div className="col-lg-6 fv-row mb-5">
                      <label className='form-label required'>Sobrenome</label>
                      <Field
                          name={`Representante.Sobrenome`}
                          placeholder='Sobrenome'
                          className='form-control form-control-lg form-control-solid'
                          value={formik.values.Representante.Sobrenome}
                      />
                      <div className='text-danger mt-2'>
                          <ErrorMessage name='Representante.Sobrenome' />
                      </div> 
                  </div>

                  <div className="col-lg-8 fv-row mb-5">
                      <label className='form-label required'>E-mail</label>
                      <Field
                          name={`Representante.Email`}
                          placeholder='E-mail'
                          className='form-control form-control-lg form-control-solid'
                          value={formik.values.Representante.Email}
                      />
                      <div className='text-danger mt-2'>
                          <ErrorMessage name='Representante.Email' />
                      </div> 
                  </div>

                  <div className='col-lg-4 fv-row mb-8'>
                      <label className='form-label required'>Telefone</label>
                      <InputMask
                          mask={'(99) 99999-9999'}
                          placeholder='Telefone'
                          type='text'
                          autoComplete='off'
                          {...formik.getFieldProps('Representante.Telefone')}
                          className={clsx(
                              'form-control form-control-lg form-control-solid'
                          )}
                      ></InputMask>
                      {formik.touched.Representante?.Telefone && formik.errors.Representante?.Telefone && (
                      <div className='fv-plugins-message-container'>
                          <div className='fv-help-block'>
                          <span role='alert'>{formik.errors.Representante?.Telefone}</span>
                          </div>
                      </div>
                      )}
                  </div>


                  <div className='col-lg-12 fv-row mb-5'>
                    <label className='form-label required'>
                      Domínios reservados
                    </label>
                    <Field 
                        name='EmpresaDominio'
                        className='form-control form-control-lg form-control-solid'
                        component={() =>
                            <MultipleSelect selectedOptions={selectedOptions} setSelectedOptions={setSelectedOptions}/>
                        }
                    />
                    <div className='text-danger mt-2'>
                        <ErrorMessage name='EmpresaDominio' />
                    </div>
                  </div>     

                  <div className='col-lg-6 fv-row mb-8' data-kt-password-meter='true'>
                      <label className='form-label required'>Senha</label>
                      <div className='mb-1'>
                      <div className='position-relative mb-3'>

                          <div className="wrapper-olho">
                          <div className={`${formik.touched.Representante?.Password ? "icon-olho-distancia-maior" : "icon-olho"}`}>
                              <IconButton
                              aria-label="visualizar senha"
                              onClick={handleClickShowPassword}
                              onMouseDown={handleMouseDownPassword}
                              edge="end"
                              >
                              {showPassword ?<i className="fas fa-eye"></i> : <i className="fas fa-eye-slash"></i>}
                              </IconButton>
                          </div>
                          <input
                              type={showPassword ? 'text' : 'password'}
                              placeholder='Senha'
                              autoComplete='off'
                              {...formik.getFieldProps('Representante.Password')}
                              className={clsx(
                              'form-control form-control-lg form-control-solid',
                              'input-olho'
                              )}
                          />
                          </div>  
                          {formik.touched.Representante?.Password && formik.errors.Representante?.Password && (
                          <div className='fv-plugins-message-container'>
                              <div className='fv-help-block'>
                              <span role='alert'>{formik.errors.Representante?.Password}</span>
                              </div>
                          </div>
                          )}
                      </div>
                      <div
                          className='d-flex align-items-center mb-3'
                          data-kt-password-meter-control='highlight'
                      >
                          <div className='flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2'></div>
                          <div className='flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2'></div>
                          <div className='flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2'></div>
                          <div className='flex-grow-1 bg-secondary bg-active-success rounded h-5px'></div>
                      </div>
                      </div>
                      <div className='text-muted'>
                      Use 8 a 20 caracteres com uma mistura de letras, números e símbolos.
                      </div>
                  </div>

                  <div className='col-lg-6 fv-row mb-5'>
                      <label className='form-label required'>Confirmar Senha</label>
                      <div className="wrapper-olho">
                          <div className={`${formik.touched.Representante?.ConfirmPassword ? "icon-olho-distancia-maior" : "icon-olho"}`}>
                              <IconButton
                              aria-label="visualizar senha"
                              onClick={handleClickShowConfirmPassword}
                              onMouseDown={handleMouseDownConfirmPassword}
                              edge="end"
                              >
                              {showConfirmPassword ?<i className="fas fa-eye"></i> : <i className="fas fa-eye-slash"></i>}
                              </IconButton>
                          </div>
                          <input
                              type={showConfirmPassword ? 'text' : 'password'}
                              placeholder='Confirmação de senha'
                              autoComplete='off'
                              {...formik.getFieldProps('Representante.ConfirmPassword')}
                              className={clsx(
                              'form-control form-control-lg form-control-solid',
                              'input-olho'
                              )}
                          />
                          </div>  
                          {formik.touched.Representante?.ConfirmPassword && formik.errors.Representante?.ConfirmPassword && (
                          <div className='fv-plugins-message-container'>
                              <div className='fv-help-block'>
                              <span role='alert'>{formik.errors.Representante?.ConfirmPassword}</span>
                              </div>
                          </div>
                          )}
                      </div>
                  </div>
                </div>
                <div className='row'>        
                  <div className='col-lg-12 fv-row'>
                    <label className='form-label'></label>
                    <div className='form-check form-switch form-check-custom form-check-solid'>
                      <label className='form-label m-0 fs-3'>Auto gerenciar perfis?</label>
                      <Field
                        type='checkbox'
                        name={`Servico.AutoGerenciarPerfis`}
                        placeholder='Módulo de descrição de cargos'
                        className='form-check-input ms-2 h-25px w-40px'
                        onChange={(e: any) => onChangeAutoGerenciarPerfis(e)}
                        checked={formik.values.Servico.AutoGerenciarPerfis}
                        value={formik.values.Servico.AutoGerenciarPerfis}
                      ></Field>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </FormikProvider>
        </div>
        <div className='card-footer py-4 d-flex justify-content-end'>
          <button
            type='button'
            className='btn btn-lg btn-secondary me-5'
            onClick={() => navigate(-1)}
          >
            Voltar
          </button>
          <button
            type='button'
            onClick={() => formik.handleSubmit()}
            className='btn btn-lg btn-primary'
          >
            Salvar
          </button>
        </div>
      </div>
    </>
  )
}

export {CriarEmpresa}
