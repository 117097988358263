/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC, useEffect } from 'react'
import { IWizardModel } from '../helpers/ResponderPesquisaHelper'
import { ErrorMessage, FormikProps } from 'formik'
import { EnumPesquisaTipoDisposicaoPergunta } from '../../../../modules/services/core/_enums'
import { PesquisaPerguntas } from '../partials/PesquisaPerguntas'

interface Step3Props {
  formik: FormikProps<IWizardModel>,
  isMobile: boolean
}

const Step3: FC<Step3Props> = ({formik, isMobile}) => {

  return (
    <>
      <div className='w-100'>
        <div className="row">
          <div className='col-lg-12 fv-row'>
            {formik.values.Pesquisa.TipoDisposicaoPergunta === EnumPesquisaTipoDisposicaoPergunta.AgruparPorDimensao && formik.values.Dimensoes.length > 0 && formik.values.Dimensoes.map((dimensao, dimensaoIndex) => {
              return (
                <div key={`accordion-dimensao-${dimensao.Id}`} id={`accordion-dimensao-${dimensao.Id}`} className={`w-100 ${dimensaoIndex < (formik.values.Dimensoes.length - 1) ? 'mb-8' : ''}`}>
                  <div className={`card card-custom card-dimensao border border-secondary border-1 shadow-sm dimensao-pesquisa-nao-selecionado`}>
                    <div className="card-header bg-pesquisa border-bottom border-secondary border-1 rounded-bottom" id={`card-dimensao-header-${dimensao.Id}`}>
                      <div className="card-title">
                        <h3 className='text-white'>{(dimensao.Nome)}</h3>                                
                      </div>
                      <div className="card-toolbar w-md-auto">                                
                        <button type="button" className="btn btn-icon btn-sm btn-accordion btn-light" data-bs-toggle="collapse" data-bs-target={`#card-dimensao-collapse-${dimensao.Id}`} aria-expanded="true" aria-controls={`card-dimensao-collapse-${dimensao.Id}`} >
                          <i className='fas fa-arrow-down'></i>
                          <i className='fas fa-arrow-up'></i>
                        </button>                   
                      </div>
                    </div>
                    <div id={`card-dimensao-collapse-${dimensao.Id}`} className="collapse show" aria-labelledby={`card-dimensao-header-${dimensao.Id}`} data-bs-parent={`#accordion-dimensao-${dimensao.Id}`}>
                      <div className={`card-body w-100 ${isMobile ? 'p-2' : ''}`}>

                        {dimensao.Texto && dimensao.Texto.map((texto, textoIndex) => (
                          <textarea  
                            key={`textarea-texto-${textoIndex}`}                
                            className={`form-control form-control-lg form-control-solid bg-light-pesquisa mb-5 textarea-texto-pesquisa`}                                     
                            value={texto}
                            disabled={true}
                          />
                        ))}

                        {(dimensao.Perguntas && dimensao.Perguntas.length > 0) && (
                          <PesquisaPerguntas
                            formik={formik}
                            perguntas={dimensao.Perguntas}
                            dimensaoIndex={dimensaoIndex}
                          />
                        )}
                        
                      </div>   
                    </div>                         
                  </div>
                  <div className='text-danger mt-2'>
                    <ErrorMessage name={`Dimensoes.${dimensaoIndex}.TodasPerguntasRespondidas`}/>
                  </div>
                </div>
              )
            })}
            
            {formik.values.Pesquisa.TipoDisposicaoPergunta === EnumPesquisaTipoDisposicaoPergunta.OrdenarAleatoriamente && formik.values.Perguntas.length > 0 && (
              <PesquisaPerguntas
                formik={formik}
                perguntas={formik.values.Perguntas}
              />
            )}      
          </div>            
        </div>
      </div>
    </>
  )
}

export {Step3}
