import { useLocation, useNavigate } from 'react-router-dom'
import { toAbsoluteUrl } from '../../../../_metronic/helpers'

const Detalhes = () => {
  interface dados {
    descriptionVideo: string
    pathVideo: string
    titleVideo: string
  }
  const location = useLocation();
  const navigate = useNavigate();
  const state = location.state as dados
  const informacao: dados = state
  
  return (
    <>
      <div className={`card card-flush h-md-100 `}>
        <div
          className='card-body d-flex flex-column justify-content-between bgi-no-repeat bgi-size-cover bgi-position-x-center pb-0 min-h-300px'
          style={{
            backgroundPosition: '100% 50%',
            backgroundImage: `url('${toAbsoluteUrl('/media/stock/900x600/42.png')}')`,
          }}
        >
          <div className='mb-5 d-flex justify-content-center'>
            <div className='col-lg-11 ml-0 pl-0' style={{border: "1px", borderBottom: "dashed", borderColor: "grey"}}>
              <div className='fs-1 ml-0 pl-0 pt-5 pb-5 fw-bold text-gray-900 text-start'>
                <span className='me-2'>
                  {informacao.titleVideo}   
                </span>
              </div>
            </div>
          </div>
          <div className='row d-flex justify-content-center mt-5 mb-5'>
            <div className='col-lg-11 d-flex justify-content-center'>
              <video 
                controls 
                style={{backgroundColor: "black"}} 
                className='w-100 mw-750px py-5 rounded'>
                <source 
                  src={informacao.pathVideo} 
                  type="video/mp4"
                />
              </video>
            </div>
          </div>
          <div className='row d-flex justify-content-center mt-5 mb-15'>
            <div className='col-lg-11 d-flex justify-content-center'>
              <span className='me-2'>
                {informacao.descriptionVideo}
              </span>
            </div>
          </div>
        </div>
        <div className='card-footer d-flex justify-content-end'>
            <button className='btn btn-secondary' onClick={() => navigate(-1)}>
              Voltar
            </button>
        </div>
      </div>
    </>
  )
}

export {Detalhes}
