/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { KTSVG, toAbsoluteUrl } from '../../../../../_metronic/helpers'
import Filters, { TableDescricao } from './TableDescricao'
import { DescricaoType } from '../Dashboard'
import { FileModel, ISelectModel } from '../../../../modules/services/core/_models'
import { Tooltip, useAlert, useHelper, useHttpClient } from '../../../../modules/services/Bundle'
import { useAuth } from '../../../../modules/auth'
import { EnumTipoEnviarAprovacao, EnumTipoEnviarAvaliacao, RequestMethod } from '../../../../modules/services/core/_enums'

type Props = {
  className?: string
  color: string
  svgIcon: string
  iconColor: string
  title: string
  titleColor?: string
  total: number
  chartValue: number
  btnTo: string
  descricaoType: string
  statusDescricao: number
  atualizarTodasAsTabelas: boolean
  setAtualizarTodasAsTabelas: Function
  setDadosDash: Function
  consultor?: number | null
  filter?: boolean,
  filterArea?: boolean,
  gerarRelatorio?: boolean,
  mostrarPorcentagem?: boolean
}

interface Area {
  Id: number;
  Nome: string;
}

const CardDescricao: React.FC<Props> = ({
  className = '',
  color,
  svgIcon,
  iconColor,
  title,
  titleColor,
  total,
  chartValue,
  btnTo,
  descricaoType,
  statusDescricao,
  atualizarTodasAsTabelas,
  setAtualizarTodasAsTabelas,
  setDadosDash,
  consultor = null,
  filter = false,
  filterArea = false,
  gerarRelatorio = false,
  mostrarPorcentagem = true,
}) => {
  const [filters, setFilters] = useState<Filters>({ IdEntidade: undefined, Texto: undefined, IdArea: undefined });
  const { auth } = useAuth()
  const [tipoRequestEnviarAprovacao, setTipoRequestEnviarAprovacao] = React.useState<number>(0);
  const [utilizaAvaliador, setUtilizaAvaliador] = React.useState(false)
  const [abrirModalAddCargo, setAbrirModalAddCargo] = React.useState(false)
  const [tipoRequestEnviarAvaliacao, setTipoRequestEnviarAvaliacao] = React.useState<number>(0);
  const [abrirModalAvaliadores, setAbrirModalAvaliadores] = React.useState<number | null>(null)
  const [abrirModalAprovadores, setAbrirModalAprovadores] = React.useState<number | null>(null)
  const [abrirModalAvaliadoresAprovadores, setAbrirModalAvaliadoresAprovadores] = React.useState<boolean>(false)
  const [areas, setAreas] = useState<Area[]>([]);
  const [modelos, setModelos] = useState<any[]>([]);
  const [modeloSelecionado, setModeloSelecionado] = useState<number>(0);
  const [selected, setSelected] = React.useState<readonly number[]>([]);
  const httpClient = useHttpClient()
  const alert = useAlert()
  const helper = useHelper()

  const obterAreas = () => {
    var consultorValue = false
    if(consultor) consultorValue = true

    httpClient.request({
      method: RequestMethod.GET,
      endpoint: `/Gerenciamento/Cargo/ObterDadosSelect?isConsultoria=${consultorValue}&idEmpresa=${consultor ? consultor : 0}`,
    }).then((result) => {
      if (result.success) setAreas(result.payload.Areas)
    })
  }

  const obterModelos = () => {
    var consultorValue = false
    if(consultor) consultorValue = true

    httpClient.request({
      method: RequestMethod.GET,
      endpoint: `/Gerenciamento/Modelo/Obter`,
      queryObject: {
        isConsultoria: consultorValue,
        idEmpresa: consultor ? consultor : 0
      },
    }).then((response) => {
      if (response.success && response.payload && response.payload.length > 0) {
        setModelos(response.payload)
      }
    })
  }

  React.useEffect(() => {
    if(filterArea){obterAreas()}
    if(gerarRelatorio){obterModelos()}
  }, [])

  const onClickBaixarCargo = async () => {

    if (!modeloSelecionado) {
      alert.createMessage({
        html: 'É necessário selecionar um modelo de relatório!',
        icon: 'error',
      })
    }
    else {
      const response = await httpClient.request({
        method: RequestMethod.GET,
        endpoint: `/Gerenciamento/Modelo/BaixarCargoAprovado`,
        queryObject: {
          idModelo: modeloSelecionado
        }
      })

      if (response.success && response.payload) {
        const file: FileModel = response.payload
        await helper.downloadFile(file)
      }
    }

  }

  return (
    <>
      <div
        className='accordion accordion-icon-toggle'
        id={`kt_accordion_${title.replace(' ', '_')}`}
      >
        <div
          className={`accordion-header d-flex collapsed w-100 card hoverable ${className}`}
          data-bs-toggle='collapse'
          data-bs-target={`#kt_accordion_1_body_${title.replace(' ', '_')}`}
          style={{
            backgroundColor: color,
          }}
        >
          <div className='col-lg-11 ribbon ribbon-top'>
            <div
              className={`ribbon-label fw-bold me-2 display-3 opacity-80`}
              style={{ backgroundColor: titleColor, color: color }}
              id={`total_${title.replace(' ', '_')}`}
            >
              {total}
            </div>
          </div>
          <div className='w-100'>
            <div className='card-body'>
              <div className='row'>
                <div className='col-lg-11'>
                  <div className='row align-items-center'>
                    <div className='col-lg-6 text-start'>
                      <Link
                        to={btnTo}
                        className={`text-${titleColor} hover-link-name-dash fw-bold fs-2 mt-5`}
                      >
                        <KTSVG
                          path={svgIcon}
                          className={`svg-icon-${iconColor} svg-icon-3x ms-n1`}
                        />
                        <span className='mt-2'>{title}</span>
                      </Link>
                    </div>
                  </div>
                  {mostrarPorcentagem && (
                    <div className='mt-5'>
                      <div className='d-flex justify-content-between fw-bold fs-2 text-white  w-100 mt-auto mb-2'>
                        <span>total</span>
                        <span>{chartValue}%</span>
                      </div>

                      <div className='h-8px w-100 rounded' style={{ backgroundColor: '#ffffff4a' }}>
                        <div
                          className='bg-white rounded h-8px'
                          role='progressbar'
                          style={{ width: `${chartValue}%` }}
                          aria-valuenow={50}
                          aria-valuemin={0}
                          aria-valuemax={100}
                        ></div>
                      </div>
                    </div>
                  )}
                </div>
                <div className='col-lg-1 d-flex justify-content-end'>
                  <span className='accordion-icon '>
                    <i className='fas icon-dashboard-arrow fa-angle-down text-white'></i>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          id={`kt_accordion_1_body_${title.replace(' ', '_')}`}
          className='accordion-collapse collapse'
          aria-labelledby={`kt_accordion_1_header_${title.replace(' ', '_')}`}
          data-bs-parent={`#kt_accordion_${title.replace(' ', '_')}`}
        >
          <div className='py-2 px-4' style={{ backgroundColor: color, color: titleColor }}>
            <div className='row'>
              {filter && (descricaoType === DescricaoType.Avaliar || descricaoType === DescricaoType.Aprovar) && (
                <div className='col-12 col-md-4'>
                  <input
                    type='text'
                    onChange={e => {
                      const newFiltros = { ...filters, IdEntidade: undefined, Texto: e.target.value ? e.target.value : undefined };
                      setFilters(newFiltros);
                    }}
                    placeholder={`Pesquisar por ${descricaoType === DescricaoType.Avaliar ? 'avaliador' : 'aprovador'} (Nome, E-mail ou CPF)`}
                    className={`form-control form-control-lg form-control-solid text-dark`}
                  />
                </div>
              )}
              {filterArea && (!consultor ? auth?.permissoes.find((x) => x === '410F06772E4A9186E4ED3D687CF241A0') : true) && (
                <div className='col-12 col-md-4 mb-2 mb-md-0'>
                  <select
                    onChange={e => {
                      const newFiltros = { ...filters, IdArea: e.target.value ? parseInt(e.target.value) : undefined };
                      setFilters(newFiltros);
                    }}
                    placeholder='Selecionar Área'
                    className={`form-select form-select-lg form-select-solid`}
                  >
                    <option value=''>Selecionar Área</option>
                    {areas && areas.length > 0 ? (
                      areas.map(area => (
                        <option key={area.Id} value={area.Id}>{area.Nome}</option>
                      ))
                    ) : (
                      <option value='' disabled>Carregando...</option>
                    )}
                  </select>
                </div>
              )}
              {gerarRelatorio && (!consultor ? auth?.permissoes.find((x) => x === '410F06772E4A9186E4ED3D687CF241A0') : true) && (
                <div className='col-12 col-md-4'>
                  <select
                    onChange={e => {
                      setModeloSelecionado(e.target.value ? parseInt(e.target.value) : 0);
                    }}
                    placeholder='Selecionar Modelo de Relatório'
                    className={`form-select form-select-lg form-select-solid`}
                  >
                    <option value=''>Selecionar Modelo de Relatório</option>
                    {modelos && modelos.length > 0 ? (
                      modelos.map(modelo => (
                        <option key={modelo.Id} value={modelo.Id}>
                          {modelo.Nome}
                        </option>
                      ))
                    ) : (
                      <option value=''>Nenhum modelo encontrado</option>
                    )}
                  </select>
                </div>
              )}
              <div className='col-12 col-md-4 d-flex align-items-center justify-content-end'>
                {gerarRelatorio && (!consultor ? auth?.permissoes.find((x) => x === '410F06772E4A9186E4ED3D687CF241A0') : true) && (
                  <button className='btn btn-light' onClick={onClickBaixarCargo}> <i className="fas fs-1 me-1  fa-file-download"></i>Gerar Relatório</button>
                )}
              </div>
            </div>
          </div>
          {(descricaoType === DescricaoType.Revisar && auth?.servico.clienteConsultoriaDescricao === null) && (
            <>
              <div className='py-2 px-4 d-flex justify-content-end' style={{ backgroundColor: color, color: titleColor }}>
                <div className='me-5'>
                  <Tooltip title='Adicionar novo cargo'>
                    <button
                      type='button'
                      style={{ marginRight: '-8px' }}
                      className='btn py-0 my-0 px-0 btn-light'
                      onClick={() => setAbrirModalAddCargo(true)}
                    >
                      <i className='fas fa-plus fs-2 py-2 px-4 m-0'></i>
                    </button>
                  </Tooltip>
                </div>
                {utilizaAvaliador ? (
                  <div className='d-flex justify-content-start'>
                    <Tooltip title='Enviar selecionados para avaliação'>
                      <button
                        disabled={selected.length <= 0}
                        type='button'
                        className='btn py-0 my-0 px-0 btn-light'
                        onClick={async () => {
                          alert.createDialog({
                            html: `Realmente deseja enviar as descrições selecionadas para avaliação?`,
                            confirmAction: async () => {
                              await httpClient.request({
                                method: RequestMethod.PUT,
                                endpoint: '/DescricaoDeCargo/Descrever/FinalizarDescricoesEnviarParaAvaliacao',
                                data: selected,
                                queryObject: {
                                  idEmpresa: consultor ?? 0
                                },
                              }).then((result) => {

                                if (result.success) {
                                  setSelected([])
                                  setDadosDash()
                                  setAtualizarTodasAsTabelas(true)
                                }

                              })
                            },
                          })
                        }}
                      >
                        <i className='fas fa-paper-plane fs-2 py-2 px-4 m-0'></i>
                      </button>
                    </Tooltip>
                  </div>
                ) : (
                  <div className='d-flex justify-content-start'>
                    <Tooltip title='Enviar selecionados para aprovação'>
                      <button
                        disabled={selected.length <= 0}
                        type='button'
                        className='btn py-0 my-0 px-0 btn-light'
                        onClick={async () => {
                          alert.createDialog({
                            html: `Realmente deseja enviar as descrições selecionadas para aprovação?`,
                            confirmAction: async () => {
                              await httpClient.request({
                                method: RequestMethod.PUT,
                                endpoint: '/DescricaoDeCargo/Descrever/FinalizarDescricoesEnviarParaAprovacao',
                                data: selected,
                                queryObject: {
                                  idEmpresa: consultor ?? 0
                                },
                              }).then((result) => {

                                if (result.success) {
                                  setSelected([])
                                  setDadosDash()
                                  setAtualizarTodasAsTabelas(true)
                                }

                              })
                            },
                          })
                        }}
                      >
                        <i className='fas fa-paper-plane fs-2 py-2 px-4 m-0'></i>
                      </button>
                    </Tooltip>
                  </div>
                )}
              </div>
            </>
          )}
          {(descricaoType === DescricaoType.Avaliar && auth?.permissoes.find((x) => x === 'F83AC0F344DD8AFE835ABD3DF11092A8') !== undefined) && (
            <>
              <div className='py-2 px-4 d-flex justify-content-end' style={{ backgroundColor: color, color: titleColor }}>
                <div className='d-flex justify-content-start'>
                  <Tooltip title='Enviar selecionados para aprovação'>
                    <button
                      disabled={selected.length <= 0}
                      type='button'
                      className='btn py-0 my-0 px-0 btn-light'
                      onClick={async () => {
                        alert.createDialog({
                          html: `Realmente deseja enviar as descrições selecionadas para aprovação?`,
                          confirmAction: async () => {
                            await httpClient.request({
                              method: RequestMethod.PUT,
                              endpoint: '/DescricaoDeCargo/Descrever/FinalizarDescricoesEnviarParaAprovacao',
                              data: selected,
                              queryObject: {
                                idEmpresa: consultor ?? 0
                              },
                            }).then((result) => {

                              if (result.success) {
                                setSelected([])
                                setDadosDash()
                                setAtualizarTodasAsTabelas(true)
                              }

                            })
                          },
                        })
                      }}
                    >
                      <i className='fas fa-paper-plane fs-2 py-2 px-4 m-0'></i>
                    </button>
                  </Tooltip>
                </div>
              </div>
            </>
          )}
          <div style={{ marginTop: '-1px' }}>
            <TableDescricao
              title={title}
              descricaoType={descricaoType}
              statusDescricao={statusDescricao}
              buscar={true}
              titleColor={titleColor ?? ''}
              color={color}
              setDadosDash={setDadosDash}
              atualizarTodasAsTabelas={atualizarTodasAsTabelas}
              setAtualizarTodasAsTabelas={setAtualizarTodasAsTabelas}
              consultor={consultor}
              filters={filters}
              tipoRequestEnviarAprovacao={tipoRequestEnviarAprovacao}
              setTipoRequestEnviarAprovacao={setTipoRequestEnviarAprovacao}
              tipoRequestEnviarAvaliacao={tipoRequestEnviarAvaliacao}
              setTipoRequestEnviarAvaliacao={setTipoRequestEnviarAvaliacao}
              utilizaAvaliador={utilizaAvaliador}
              setUtilizaAvaliador={setUtilizaAvaliador}
              abrirModalAddCargo={abrirModalAddCargo}
              setAbrirModalAddCargo={setAbrirModalAddCargo}
              abrirModalAvaliadores={abrirModalAvaliadores}
              setAbrirModalAvaliadores={setAbrirModalAvaliadores}
              abrirModalAprovadores={abrirModalAprovadores}
              setAbrirModalAprovadores={setAbrirModalAprovadores}
              selected={selected}
              setSelected={setSelected}
              abrirModalAvaliadoresAprovadores={abrirModalAvaliadoresAprovadores}
              setAbrirModalAvaliadoresAprovadores={setAbrirModalAvaliadoresAprovadores}
            />
          </div>
        </div>
      </div >
    </>
  )
}

export { CardDescricao }
