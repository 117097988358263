/* eslint-disable jsx-a11y/anchor-is-valid */
import { useEffect, useState } from 'react'
import { KTSVG, toAbsoluteUrl } from '../../../../../_metronic/helpers'
import { IDadosGraficosPesquisaModel, IGraficoDispersaoDimensaoModel, IGraficoDispersaoTipoModel } from '../helpers/AnaliseResultadoHelper'
import { EnumPesquisaGraficoDispersaoTipo } from '../../../../modules/services/core/_enums'

type Props = {
  showModal: boolean
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>
  dimensaoIndex: number | undefined
  dispersaoIndex: number | undefined
  fillColor: string | undefined
  dadosGraficosPesquisa: IDadosGraficosPesquisaModel | undefined
}

const ModalGraficoDispersao = ({showModal, setShowModal, dimensaoIndex, dispersaoIndex, fillColor, dadosGraficosPesquisa}: Props) => {
  let [dimensao, setDimensao] = useState<IGraficoDispersaoDimensaoModel | undefined>(undefined)  
  let [dispersao, setDispersao] = useState<IGraficoDispersaoTipoModel | undefined>(undefined)  

  useEffect(() => {

    if (dimensaoIndex !== undefined) {

      let dimensaoAtual = dadosGraficosPesquisa?.GraficoDispersao.Dimensoes[dimensaoIndex] ?? undefined      
      setDimensao(dimensaoAtual)
      
      if(dispersaoIndex !== undefined && dimensaoAtual) {
        let dispersaoAtual = dimensaoAtual.Dispersoes[dispersaoIndex] ?? undefined
        setDispersao(dispersaoAtual)
      }
    }

  }, [dispersaoIndex])
    
  return (
    <div className={`modal ${showModal && dimensaoIndex !== undefined && dispersaoIndex !== undefined && fillColor !== undefined ? "show" : ""} fade`} tabIndex={-1} id="kt_modal_1" style={{display: `${showModal && dimensaoIndex !== undefined && dispersaoIndex !== undefined && fillColor !== undefined ? "block" : "none"}`}}>
        <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-xl">
            <div className="modal-content border border-1" style={{ borderColor: fillColor + ' !important' }}>
            <div className="modal-header" style={{ backgroundColor: fillColor }}>
                <h3 className="modal-title">{`${dispersao?.Nome}: ${dimensao?.Nome ?? ''}`}</h3>
                <div
                  onClick={() => setShowModal(false)}
                  className="btn btn-icon btn-sm btn-active-light-primary ms-2"
                  data-bs-dismiss="modal"
                  aria-label="Fechar"
                >
                  <KTSVG
                      path="/media/icons/duotune/arrows/arr061.svg"
                      className="svg-icon svg-icon-2x"
                  />
                </div>
            </div>
            <div className="modal-body">
              {dispersao && dispersao.Perguntas.map((pergunta, perguntaIndex) => {

                return (
                  <div key={`accordion-pergunta-${pergunta.Id}`} id={`accordion-pergunta-${pergunta.Id}`} className={`w-100 ${perguntaIndex < ((dispersao?.Perguntas.length ?? 1) - 1) ? 'mb-8' : ''}`}>
                    <div className={`card card-custom card-pergunta border border-secondary border-1 shadow-sm dimensao-pesquisa-nao-selecionado`}>
                      <div className="card-header border-bottom border-secondary border-1 rounded-bottom flex-nowrap" id={`card-pergunta-header-${pergunta.Id}`}  style={{ backgroundColor: 'rgba(218, 218, 217, 1)' }}>
                        <div className="card-title">
                          <h5 className=''>{(pergunta.Texto)}</h5>                                
                        </div>
                        <div className="card-toolbar w-md-auto">                                
                          <button type="button" className="btn btn-icon btn-sm btn-accordion btn-light" data-bs-toggle="collapse" data-bs-target={`#card-pergunta-collapse-${pergunta.Id}`} aria-expanded="false" aria-controls={`card-pergunta-collapse-${pergunta.Id}`} >
                            <i className='fas fa-arrow-down'></i>
                            <i className='fas fa-arrow-up'></i>
                          </button>                   
                        </div>
                      </div>
                      <div id={`card-pergunta-collapse-${pergunta.Id}`} className="collapse" aria-labelledby={`card-pergunta-header-${pergunta.Id}`} data-bs-parent={`#accordion-pergunta-${pergunta.Id}`}>
                        <div className={`card-body w-100`}>

                          {dadosGraficosPesquisa && pergunta.IdsParticipantes && pergunta.IdsParticipantes.length > 0 ? pergunta.IdsParticipantes.map((idParticipante) => {

                            let participante = dadosGraficosPesquisa.Participantes.find(p => p.Id === idParticipante)

                            return (
                              <div key={`grafico-dispersao-${dispersaoIndex}-pergunta-${perguntaIndex}-participante-${idParticipante}`} className='my-5'>
                                <div className='menu-content d-flex align-items-center px-3'>
                                  <div className='symbol symbol-75px me-5'>
                                    <img
                                      alt='Logo'
                                      src={
                                        participante?.FotoDataUrl ? participante?.FotoDataUrl : toAbsoluteUrl('/media/avatars/blank.png')
                                      }
                                    />
                                  </div>

                                  <div className='d-flex flex-column'>
                                    <div className='fw-bolder fs-5 mb-1'>
                                      {participante?.Nome}
                                    </div>
                                    <div className='fw-bold text-muted text-hover-primary fs-7 mb-1'>
                                    {participante?.Email}
                                    </div>
                                    <div className='fw-bold text-muted fs-7'>
                                      {participante?.NomeCargo}
                                    </div>
                                  </div>
                                </div>
                              </div>                            
                            )
                          }) : (
                            <h2 className='text-center m-0 p-0'>Essa pergunta não possui {dispersao?.Nome.toLowerCase()}</h2>
                          )}
                          
                        </div>   
                      </div>                         
                    </div>
                  </div>
                )
              })}
            </div>
            <div className={`modal-footer d-flex justify-content-end`}>                        
              <button
                onClick={() => setShowModal(false)}
                type="button"
                className="btn btn-light"
                data-bs-dismiss="modal"
              >
                Fechar
              </button>
            </div>
            </div>
        </div>
    </div>
  )
}

export {ModalGraficoDispersao}