import * as React from 'react';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Paper from '@mui/material/Paper';
import { visuallyHidden } from '@mui/utils';
import { useAlert, useHelper, useHttpClient } from '../../../modules/services/Bundle';
import { RequestMethod } from '../../../modules/services/core/_enums';
import { Link, useNavigate } from 'react-router-dom';
import { KTSVG } from '../../../../_metronic/helpers';
import { IconButton } from '@mui/material';
import { ModalCarregarDescricoes } from './partials/ModalCarregarDescricoes';
import { FileModel, ISelectModel } from '../../../modules/services/core/_models';
import { useAuth } from '../../../modules/auth';

interface Data {
  Id: number;
  Nome: string;
  QtdCampos: number;
  PossuiCamposIAEmBranco: boolean;
}

interface Column {
  id: keyof Data;
  label: string;
  minWidth?: number;
  align?: 'center';
  format?: (value: number) => string;
}

const columns: readonly Column[] = [
  { 
    id: 'Nome', 
    label: 'MODELO'
  },
  { 
    id: 'QtdCampos', 
    label: 'QTD DE CAMPOS'
  },
  { 
    id: 'PossuiCamposIAEmBranco', 
    label: 'DESCRIÇOES COM CAMPOS IA EM BRANCO?'
  },
]

function createData(
  Id: number,
  Nome: string,
  QtdCampos: number,
  PossuiCamposIAEmBranco: boolean,
): Data {
  return {Id, Nome, QtdCampos, PossuiCamposIAEmBranco};
}

type Order = 'asc' | 'desc'

interface EnhancedTableProps {
  onRequestSort: (event: React.MouseEvent<unknown>, property: keyof Data) => void;
  order: Order;
  orderBy: string;
}

function EnhancedTableHead(props: EnhancedTableProps) {
  const { order, orderBy, onRequestSort } =
    props;
  const createSortHandler =
    (property: keyof Data) => (event: React.MouseEvent<unknown>) => {
      onRequestSort(event, property);
    };

  return (
    <TableHead>
      <TableRow className='table-header'>
        {columns.map((columns) => (
          <TableCell
            className='table-cell'
            key={columns.id}
            align={columns.align ? 'right' : 'left'}
          >
            <TableSortLabel
              className='fw-bolder'
              active={orderBy === columns.id}
              direction={orderBy === columns.id ? order : 'asc'}
              onClick={createSortHandler(columns.id)}
            >
              {columns.label}
              {orderBy === columns.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
          <TableCell
            className='table-cell fw-bolder'
            align='center'
          >
            AÇÕES
          </TableCell>
      </TableRow>
    </TableHead>
  );
}

interface ModeloFormularioDescricaoProps {
  isConsultoria: boolean,
}

const ModeloFormularioDescricao = ({ isConsultoria }: ModeloFormularioDescricaoProps) => {
  const httpClient = useHttpClient()
  const navigate = useNavigate()
  const alert = useAlert()
  const helper = useHelper()
  const [order, setOrder] = React.useState<Order>('asc')
  const [orderBy, setOrderBy] = React.useState<keyof Data>('Nome')
  const [page, setPage] = React.useState(0)
  const [rowsPerPage, setRowsPerPage] = React.useState(10)
  const [total, setTotal] = React.useState(0)
  const [rows, setRows] = React.useState<Data[]>([])
  const [abrirModalCarregarDescricoes, setAbrirModalCarregarDescricoes] = React.useState(false)
  const {auth} = useAuth()
  const [empresasCliente, setEmpresasCliente] = React.useState<ISelectModel[]>([])
  const [idEmpresaClienteSelecionada, setIdEmpresaClienteSelecionada] = React.useState<number>(0)

  const resquestTable = async (pageRq: number, orderByRq: string, orderRq: string, rowsPerPageRq: number) => {
    const response = await httpClient.request({
      method: RequestMethod.POST,
      endpoint: '/Gerenciamento/Modelo/Listar',
      queryObject: {
        isConsultoria: isConsultoria,
        idEmpresa: idEmpresaClienteSelecionada
      },
      data: {
        Order: orderRq,
        OrderBy: orderByRq,
        Page: pageRq,
        RowsPerPage: rowsPerPageRq
      }
    })

    if(response.success && response.payload){
      const newRows = response.payload.data.map((data: Data) => {
        return createData(data.Id, data.Nome, data.QtdCampos, data.PossuiCamposIAEmBranco)
      })
      setRows([...newRows])
      setTotal(response.payload.total)
    }
  }

  React.useEffect(() => {
    carregarDados()
  },[isConsultoria])

  React.useEffect(() => {
    setRows([])
    if (isConsultoria && idEmpresaClienteSelecionada > 0) obterDadosTabela()
  },[idEmpresaClienteSelecionada])

  const carregarDados = async () => {
    if (isConsultoria) await obterEmpresasCliente()
    else await obterDadosTabela()
  }

  const obterEmpresasCliente = async () => {
    const response = await httpClient.request({
      method: RequestMethod.GET,
      endpoint: '/DescricaoDeCargo/Consultoria/ObterClientesParaSelect',
    })

    if(response.success && response.payload) {
      setEmpresasCliente(response.payload)
      if(response.payload.length > 0) setIdEmpresaClienteSelecionada(response.payload[0].Id)
    }
  }

  const obterDadosTabela = async () => {
    await resquestTable(page, orderBy, order, rowsPerPage)
  }

  const handleRequestSort = async (
    event: React.MouseEvent<unknown>,
    property: keyof Data,
  ) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
    await resquestTable(page, property, isAsc ? 'desc' : 'asc', rowsPerPage);
  };

  const handleChangePage = async (event: unknown, newPage: number) => {
    await resquestTable(newPage, orderBy, order, rowsPerPage);
    setPage(newPage);
  };

  const handleChangeRowsPerPage = async (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    await resquestTable(page, orderBy, order, parseInt(event.target.value, 10));
    setPage(0);
  };

  const obterStatusUpload = async () => {
    return await httpClient.request({
      method: RequestMethod.GET,
      endpoint: '/Gerenciamento/Modelo/ObterStatusUpload',
    })
  }

  const emptyRows = rows.length;

  return (
    <>
      <div className='card mb-5 mb-xl-10'>
        <div className='card-header'>
          <div className='card-title m-0'>
            <h3 className='fw-bolder m-0'>Modelo de Formulário</h3>
          </div>
          <div className='card-toolbar'>
            {((auth?.roles.find(x => x === 'Master') !== undefined) || (auth?.roles.find(x => x === 'Administrador') !== undefined) || (auth?.permissoes.find(x => x === (isConsultoria ? '300B6C2B916A3C2DCFA6D98808EC07F3' : '57E1723C2CAE07A63CEA4B41378BBFAB')) !== undefined)) && (                                    
              <button
                type='button'
                className='btn btn-light-primary me-5'
                onClick={() => { 
                  setAbrirModalCarregarDescricoes(true); 
                }}
              >
                <KTSVG path="/media/icons/duotune/files/fil013.svg" className="svg-icon-1" />
                Carregar Descrições
              </button>
            )}
            {((auth?.roles.find(x => x === 'Master') !== undefined) || (auth?.roles.find(x => x === 'Administrador') !== undefined) || (auth?.permissoes.find(x => x === (isConsultoria ? 'FE7B6D3ED19B84F0266856DB7F2FB5C2' : '7DC3F142F8C8F6C4B1FF2E8CDAC11DE0')) !== undefined)) && (
              <button 
                className='btn btn-light-primary'
                title='Adicionar'
                onClick={() => {
                  obterStatusUpload().then((response) => {
                    navigate(`/descricao/${isConsultoria ? 'consultoria/' : ''}modelo-formulario-descricao/adicionar`, {
                      state: {
                        visualizar: false
                      }
                    })
                  })
                }}  
              >
                <KTSVG path="/media/icons/duotune/arrows/arr009.svg" className="svg-icon-1" />
                Adicionar
              </button>
            )}
          </div>
        </div>
        <div className='card-body p-9'>
          {(isConsultoria) && (
            <>
              <div className='row'>
                <div className='col-lg-4 mb-5'>
                  <label className='form-label'>Cliente</label>
                  <select
                    className='form-select form-select-lg form-select-solid'
                    value={idEmpresaClienteSelecionada}
                    onChange={(e: React.ChangeEvent<HTMLSelectElement>) => { setIdEmpresaClienteSelecionada(parseInt(e.target.value)) }}
                  >
                    <option value={0}>Selecione...</option>
                    {empresasCliente && empresasCliente.length > 0 && empresasCliente.map((data: ISelectModel, index: number) => {
                        return (
                          <option key={`cliente-option-${index}`} value={data.Id}>
                            {data.Nome}
                          </option>
                        )
                    })}
                  </select>
                </div>
              </div>
            </>
          )}          
          <div className='row'>
            <div className='col-lg-12'>
              <Box sx={{ width: '100%' }}>
                <Paper className='table-border ' sx={{ width: '100%', mb: 2 }}>
                  <TableContainer>
                    <Table
                      className='table-header'
                      sx={{ minWidth: 750 }}
                      aria-labelledby="tableTitle"
                    >
                      <EnhancedTableHead
                        order={order}
                        orderBy={orderBy}
                        onRequestSort={handleRequestSort}
                      />
                      <TableBody>
                        {rows.map((row, index) => {
                          return (
                            <TableRow
                              className='table-row'
                              hover
                              tabIndex={-1}
                              key={index}
                              sx={{ cursor: 'pointer' }}
                            >
                              <TableCell className='table-cell' align="left">{row.Nome}</TableCell>
                              <TableCell className='table-cell' align="left">{row.QtdCampos}</TableCell>
                              <TableCell className='table-cell' align="left">{`${row.PossuiCamposIAEmBranco ? 'Sim' : 'Não'}`}</TableCell>
                              <TableCell className='table-cell' align="center">                          
                                <IconButton 
                                  className={`text-gray-800`}
                                  title='Visualizar' 
                                  onClick={() => { 
                                    navigate(`/descricao/${isConsultoria ? 'consultoria/' : ''}modelo-formulario-descricao/visualizar`, { state: { 
                                      idModelo: row.Id,
                                      visualizar: true
                                    } })
                                  }}>
                                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-eye-fill" viewBox="0 0 16 16">
                                      <path d="M10.5 8a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0z"/>
                                      <path d="M0 8s3-5.5 8-5.5S16 8 16 8s-3 5.5-8 5.5S0 8 0 8zm8 3.5a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7z"/>
                                  </svg>
                                </IconButton>
                                {((auth?.roles.find(x => x === 'Master') !== undefined) || (auth?.roles.find(x => x === 'Administrador') !== undefined) || (auth?.permissoes.find(x => x === 'B10B631CBE57D5680B16CE7C4346C627') !== undefined)) && (
                                  <IconButton 
                                    className={`text-gray-800`}
                                    title='Editar'
                                    onClick={() => { 
                                      obterStatusUpload().then((response) => {
                                        if(response.success){
                                          navigate(`/descricao/${isConsultoria ? 'consultoria/' : ''}modelo-formulario-descricao/editar`, { state: { 
                                            idModelo: row.Id,
                                            visualizar: false
                                          } })
                                        }
                                      })
                                    }}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-pencil-square" viewBox="0 0 16 16">
                                        <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z"/>
                                        <path fillRule="evenodd" d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z"/>
                                    </svg>
                                  </IconButton>
                                )}
                                {((auth?.roles.find(x => x === 'Master') !== undefined) || (auth?.roles.find(x => x === 'Administrador') !== undefined) || (auth?.permissoes.find(x => x === (isConsultoria ? 'DEEEB2B35B65D77B46716C83AF78FAAF' : '52497CBA67780B0CE06ED1F093C9C002')) !== undefined)) && (
                                  <IconButton 
                                    className={`text-gray-800`}
                                    title='Excluir'
                                    onClick={() => {
                                        alert.createDialog({
                                            html: `Realmente deseja excluir este modelo?`,
                                            confirmAction: async () => {                              
                                                const response = await httpClient.request({
                                                  method: RequestMethod.DELETE,
                                                  endpoint: '/Gerenciamento/Modelo/Deletar',
                                                  data: row.Id
                                                })

                                                if (response.success) obterDadosTabela()                                        
                                            }
                                        })
                                    }}
                                    >
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-trash-fill" viewBox="0 0 16 16">
                                        <path d="M2.5 1a1 1 0 0 0-1 1v1a1 1 0 0 0 1 1H3v9a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V4h.5a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H10a1 1 0 0 0-1-1H7a1 1 0 0 0-1 1H2.5zm3 4a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 .5-.5zM8 5a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7A.5.5 0 0 1 8 5zm3 .5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 1 0z"/>
                                    </svg>
                                  </IconButton>
                                )}
                                {((auth?.roles.find(x => x === 'Master') !== undefined) || (auth?.roles.find(x => x === 'Administrador') !== undefined) || (auth?.permissoes.find(x => x === (isConsultoria ? 'F96F05E02F53AABF6F255C1C0F89ABB9' : 'BFB383F22CFC5C5B73AEE6D6640B61B6')) !== undefined)) && (                                    
                                    <>
                                      <IconButton 
                                        className={`text-gray-800`}
                                        title='Baixar'
                                        onClick={() => {
                                            alert.createDialog({
                                                html: `Realmente deseja baixar este modelo?`,
                                                confirmAction: async () => {                              
                                                    const response = await httpClient.request({
                                                      method: RequestMethod.GET,
                                                      endpoint: '/Gerenciamento/Modelo/Baixar',
                                                      queryObject: {
                                                        idModelo: row.Id
                                                      } 
                                                    })

                                                    if (response.success && response.payload) {
                                                      const file: FileModel = response.payload
                                                      await helper.downloadFile(file)
                                                    }                                       
                                                }
                                            })
                                        }}
                                        >
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-download" viewBox="0 0 16 16">
                                          <path d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5z"/>
                                          <path d="M7.646 11.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V1.5a.5.5 0 0 0-1 0v8.793L5.354 8.146a.5.5 0 1 0-.708.708l3 3z"/>
                                        </svg>
                                      </IconButton>
                                    </>
                                  )}
                                  {row.PossuiCamposIAEmBranco && (                                    
                                    <>
                                      <IconButton 
                                        className={`text-gray-800`}
                                        title='Gerar as respostas para as descrições com campos em branco'
                                        onClick={() => {
                                            alert.createDialog({
                                                html: `Realmente deseja gerar as respostas para as descrições com campos em branco neste modelo?`,
                                                confirmAction: async () => {                              
                                                    const response = await httpClient.request({
                                                      method: RequestMethod.GET,
                                                      endpoint: '/Gerenciamento/Modelo/GerarRespostasParaCamposEmBranco',
                                                      queryObject: {
                                                        idModelo: row.Id
                                                      } 
                                                    })

                                                    if (response.success) obterDadosTabela()                                     
                                                }
                                            })
                                        }}
                                        >
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-database-fill-add" viewBox="0 0 16 16">
                                          <path d="M12.5 16a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7Zm.5-5v1h1a.5.5 0 0 1 0 1h-1v1a.5.5 0 0 1-1 0v-1h-1a.5.5 0 0 1 0-1h1v-1a.5.5 0 0 1 1 0ZM8 1c-1.573 0-3.022.289-4.096.777C2.875 2.245 2 2.993 2 4s.875 1.755 1.904 2.223C4.978 6.711 6.427 7 8 7s3.022-.289 4.096-.777C13.125 5.755 14 5.007 14 4s-.875-1.755-1.904-2.223C11.022 1.289 9.573 1 8 1Z"/>
                                          <path d="M2 7v-.839c.457.432 1.004.751 1.49.972C4.722 7.693 6.318 8 8 8s3.278-.307 4.51-.867c.486-.22 1.033-.54 1.49-.972V7c0 .424-.155.802-.411 1.133a4.51 4.51 0 0 0-4.815 1.843A12.31 12.31 0 0 1 8 10c-1.573 0-3.022-.289-4.096-.777C2.875 8.755 2 8.007 2 7Zm6.257 3.998L8 11c-1.682 0-3.278-.307-4.51-.867-.486-.22-1.033-.54-1.49-.972V10c0 1.007.875 1.755 1.904 2.223C4.978 12.711 6.427 13 8 13h.027a4.552 4.552 0 0 1 .23-2.002Zm-.002 3L8 14c-1.682 0-3.278-.307-4.51-.867-.486-.22-1.033-.54-1.49-.972V13c0 1.007.875 1.755 1.904 2.223C4.978 15.711 6.427 16 8 16c.536 0 1.058-.034 1.555-.097a4.507 4.507 0 0 1-1.3-1.905Z"/>
                                        </svg>
                                      </IconButton>
                                    </>
                                  )}
                              </TableCell>
                            </TableRow>
                          );
                        })}
                        {emptyRows === 0 && (
                          <TableRow
                          >
                            <TableCell className='table-cell text-center' colSpan={6}>
                              Nenhum registro encontrado
                            </TableCell>
                          </TableRow>
                        )}
                      </TableBody>
                    </Table>
                  </TableContainer>
                  <TablePagination
                    className='table-footer'
                    labelRowsPerPage="linhas por página"
                    rowsPerPageOptions={[10, 25]}
                    component="div"
                    count={total}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                  />
                </Paper>
              </Box>        
            </div>
          </div>             
        </div>
        <div className='card-footer d-flex justify-content-end py-4'>
          <Link className='btn btn-secondary' to={`/inicio`} >Voltar</Link>
        </div>
      </div>

      {
        abrirModalCarregarDescricoes && (
          <ModalCarregarDescricoes
            abrirModal={abrirModalCarregarDescricoes}
            setAbrirModal={setAbrirModalCarregarDescricoes}
            isConsultoria={isConsultoria}
            empresasCliente={empresasCliente}
          />
        )
      }

    </>
  )
}

export {ModeloFormularioDescricao}
