import React from 'react';
import Chart from 'react-apexcharts'
import { IDadosGraficosPesquisaModel } from '../helpers/AnaliseResultadoHelper';

type Props = {
  dadosGraficosPesquisa: IDadosGraficosPesquisaModel | undefined
  setStatusDeAlcance: React.Dispatch<React.SetStateAction<number>>
  setStatusDeAlcanceColor: React.Dispatch<React.SetStateAction<string>>
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>
}

const GraficoAlcancePesquisa  = ({dadosGraficosPesquisa, setStatusDeAlcance, setStatusDeAlcanceColor, setShowModal}:Props) => {
  
  let options = {
      chart: {
          events: {
            click: function(event: React.ChangeEvent<HTMLInputElement>, chartContext:any, config:any) {
              const valor = event.target.attributes.getNamedItem('j')?.value 
              if (valor !== undefined) {
                setStatusDeAlcance(parseInt(valor) + 1)
                setStatusDeAlcanceColor(dadosGraficosPesquisa?.GraficoAlcancePesquisa.Colors[parseInt(valor)] ?? '#FFFFFF')              
                setShowModal(true) 
              }
            }
          }
      },
      labels: dadosGraficosPesquisa?.GraficoAlcancePesquisa.Labels ?? [],
      colors: dadosGraficosPesquisa?.GraficoAlcancePesquisa.Colors ?? [],
      series: dadosGraficosPesquisa?.GraficoAlcancePesquisa.Series ?? [],
      plotOptions: {
          pie: {
              expandOnClick: false,
              donut: {
                  size: '0%',
              }
          }
      }
  }
  
  return (
    <div className={`card mb-10 ${dadosGraficosPesquisa ? '' : 'd-none'}`}>
      <div
        className='card-header'
      >
        <div className='card-title m-0'>
          <h3 className='fw-bolder m-0'>Alcance da pesquisa</h3>
        </div>
      </div>
      <div className='card-body'>
        <div className='row justify-content-center my-10'>
          <div className='col-lg-6'>
            <Chart options={options} series={options.series} type="donut" height="400px" />
          </div>
        </div>
      </div>        
    </div>      
  )
}
  
export {GraficoAlcancePesquisa}