import Chart from 'react-apexcharts'
import { IDadosGraficosResultadosPorDimensaoPesquisaModel } from '../helpers/AnaliseResultadoHelper'

type Props = {
  dadosGraficosPesquisa: IDadosGraficosResultadosPorDimensaoPesquisaModel | undefined
}

const GraficoResultadosPorDimensao  = ({dadosGraficosPesquisa}:Props) => {

  let stackType: any = '100%'

  let options = {
    colors : dadosGraficosPesquisa?.Colors ?? [],
    series: dadosGraficosPesquisa?.Series ?? [],
    chart: {
      stacked: true,
      stackType: stackType,
      toolbar: {
        show: false
      },
    },
    plotOptions: {
      bar: {
        horizontal: false,
      },
    },
    stroke: {
      width: 1,
      colors: ['#fff']
    },
    xaxis: {
      categories: dadosGraficosPesquisa?.Categories ?? [],
    },
    fill: {
      opacity: 1    
    },
    legend: {
      offsetX: 40
    }
  }
  
  return (
    <>
      <div className={`card m-0 p-0 ${dadosGraficosPesquisa ? '' : 'd-none'}`}>        
        <div className='card-body m-0 p-0'>
          <div className='row justify-content-center m-0 p-0'>
            <Chart options={options} series={options.series} type="bar" height={'400px'} />           
          </div>
        </div>        
      </div> 
    </>         
  )
}
  
export {GraficoResultadosPorDimensao}