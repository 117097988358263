import * as React from 'react';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Paper from '@mui/material/Paper';
import { visuallyHidden } from '@mui/utils';
import { useAlert, useHttpClient } from '../../../../modules/services/Bundle';
import { EnumPesquisaParticipanteStatus, RequestMethod } from '../../../../modules/services/core/_enums';
import { IconButton } from '@mui/material';

interface Column {
  id: keyof Data
  label: string
  minWidth?: number
  align?: 'center'
  format?: (value: number) => string
}

const columns: readonly Column[] = [
  { 
    id: 'Nome', 
    label: 'PARTICIPANTE'
  },
  { 
    id: 'Email', 
    label: 'EMAIL'
  },
];

interface Data {
  Id: number
  IdPesquisa: number
  Nome: string
  NomeParticipante: string
  Email: string
  Status: number
  Chave: string
}

function createData(
  Id: number,
  IdPesquisa: number,
  Nome: string,
  NomeParticipante: string,
  Email: string,
  Status: number,
  Chave: string,
): Data {
  return { Id, IdPesquisa, Nome, NomeParticipante, Email, Status, Chave};
}

type Order = 'asc' | 'desc';

interface EnhancedTableProps {
  onRequestSort: (event: React.MouseEvent<unknown>, property: keyof Data) => void
  order: Order
  orderBy: string
}

type Props = {
  idPesquisa: number
  statusDeAlcance: number
  statusColor: string
}

const TablePesquisaParticipanteAlcance = ({idPesquisa, statusDeAlcance, statusColor}: Props) => {  

  const httpClient = useHttpClient()
  const [order, setOrder] = React.useState<Order>('asc')
  const [orderBy, setOrderBy] = React.useState<keyof Data>('Nome')
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10)
  const [total, setTotal] = React.useState(0)
  const [rows, setRows] = React.useState<Data[]>([])   
  const alert = useAlert()

  function EnhancedTableHead(props: EnhancedTableProps) {
    const { order, orderBy, onRequestSort } = props;
    const createSortHandler =
      (property: keyof Data) => (event: React.MouseEvent<unknown>) => {
        onRequestSort(event, property);
      };
  
    return (
      <TableHead >
        <TableRow className='' style={{
          backgroundColor: `${statusColor + '80'}`,
          color: '#2b2b2b'
        }}>
          {columns.map((columns) => (
            <TableCell
            className='table-cell'
            key={columns.id}
            align={columns.align ? 'right' : 'left'}
          >
            <TableSortLabel
              className={`fw-bolder ${columns.id === 'Status' ? 'ms-4' : ''}`}
              active={orderBy === columns.id}
              direction={orderBy === columns.id ? order : 'asc'}
              onClick={createSortHandler(columns.id)}
            >
              {columns.label}
              {orderBy === columns.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
          ))}
            <TableCell
              className='fw-bolder table-cell'
              align='center'
            >
              AÇÕES
            </TableCell> 
        </TableRow>
      </TableHead>
    );
  }

  const resquestTable = (pageRq: number, orderByRq: string, orderRq: string, rowsPerPageRq: number) => {
    
    setRows([])
    setTotal(0)

    httpClient.request({
      method: RequestMethod.POST,
      endpoint: '/Pesquisa/Resultado/ListarPesquisaParticipantePorStatusDeAlcance',
      data: {
        Order: orderRq,
        OrderBy: orderByRq,
        Page: pageRq,
        RowsPerPage: rowsPerPageRq,
      },
      queryObject: {
        idPesquisa: idPesquisa,
        statusDeAlcance: statusDeAlcance
      }
    }).then((response) => {
        
      if(response.success && response.payload){
        const newRows = response.payload.data.map((data: Data) => {
          return createData(data.Id, data.IdPesquisa, data.Nome, data.NomeParticipante, data.Email, data.Status, data.Chave)
        })
        setRows([...newRows])
        setTotal(response.payload.total)
      }
        
    })
    
  }

  React.useEffect(() => {
      if(idPesquisa > 0 && statusDeAlcance > 0) obterDadosTabela()
  },[statusDeAlcance])

  const obterDadosTabela = () => {
    resquestTable(page, orderBy, order, rowsPerPage)
  }

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: keyof Data,
  ) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
    resquestTable(page, property, isAsc ? 'desc' : 'asc', rowsPerPage);
  }

  const handleChangePage = (event: unknown, newPage: number) => {
    resquestTable(newPage, orderBy, order, rowsPerPage);
    setPage(newPage);
  }

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    resquestTable(page, orderBy, order, parseInt(event.target.value, 10));
    setPage(0);
  }

  const emptyRows = rows.length;

  return (
    <>      
      <form className='form'>
          <Box sx={{ width: '100%' }}>
            <Paper className='tabela-pesquisa mb-0' sx={{ width: '100%', mb: 2 }} style={{
              border: `2px solid ${statusColor + '80'}`,
              borderRadius: '5px !important'
            }}>
                     
              <TableContainer>
                <Table
                  sx={{ minWidth: 750 }}
                  aria-labelledby="tableTitle"
                >
                  <EnhancedTableHead
                    order={order}
                    orderBy={orderBy}
                    onRequestSort={handleRequestSort}
                  />
                  <TableBody>
                    {rows.map((row, index) => {
                      return (
                        <TableRow
                          className=''
                          hover
                          tabIndex={-1}
                          key={index}
                          sx={{ cursor: 'pointer' }}
                          style={{
                            backgroundColor: `${statusColor + '50'}`
                          }}
                        >
                          <TableCell className='table-cell' align="left">{row.Nome}</TableCell>
                          <TableCell className='table-cell' align="left">{row.Email}</TableCell>                          
                          <TableCell className='table-cell' align="center">
                            {row.Status !== EnumPesquisaParticipanteStatus.Finalizada && (
                              <IconButton className='icon-button-dark-hover' 
                                title='Notificar' 
                                onClick={() => {
                                  alert.createDialog({
                                    html: `Deseja enviar uma notificação ao participante: ${row.Nome}?`,
                                    confirmAction: async () => {
                                      httpClient.request({
                                        method: RequestMethod.POST,
                                        endpoint: '/Pesquisa/Dashboard/NotificarParticipante',
                                        queryObject: {
                                          idParticipante: row.Id
                                        }
                                      })
                                    }
                                  })
                                }} 
                                aria-label="upload picture" 
                                component="label"
                              >
                                <i className="fs-6 far fa-bell" style={{color: `${statusColor}`}}></i>
                              </IconButton>
                            )}
                          </TableCell>
                        </TableRow>
                      );
                    })}
                    {emptyRows == 0 && (
                      <TableRow
                      >
                        <TableCell className='table-cell bg-body text-center' colSpan={6}>
                          Nenhum registro encontrado
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                className='table-cell'
                labelRowsPerPage="linhas por página"
                rowsPerPageOptions={[10, 25]}
                component="div"
                count={total}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                style={{
                  backgroundColor: `${statusColor + '80'}`,
                  color: '#2b2b2b'
                }}
              />
            </Paper>
          </Box>
      </form>
    </>    
  )
}

export {TablePesquisaParticipanteAlcance}