/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect } from 'react'
import { Field, FormikProps } from 'formik'
import { IDescreverDescricaoModel } from '../helpers/DescreverHelper'
import { KTSVG } from '../../../../../_metronic/helpers'
import { useHttpClient } from '../../../../modules/services/Bundle'
import { RequestMethod } from '../../../../modules/services/core/_enums'
import { Collapse } from 'bootstrap'

type Props = {
  formik: FormikProps<IDescreverDescricaoModel>,
  aprovar?: boolean,
  setTemValorReprovado?: Function
}

const VisualizarCampos: React.FC<Props> = ({
  formik,
  aprovar,
  setTemValorReprovado
}) => {

  const [buttonObsStates, setButtonObsStates] = React.useState<{ [key: string]: boolean }>({});
  const [collapseStates, setCollapseStates] = React.useState<{ [key: string]: boolean }>({});

  const httpClient = useHttpClient();

  useEffect(() => {
    if(aprovar && setTemValorReprovado){
      setTemValorReprovado(formik.values.Campos.some(x => x.Respostas.some(r => r.ValorAtual?.ObservacaoReprovado)))
      const newCollapseStates: { [key: string]: boolean } = {};
      formik.values.Campos.forEach((campo) => {
        campo.Respostas.forEach((resposta) => {
          newCollapseStates[resposta.IdValorAtual!.toString()] = resposta.ValorAtual?.ObservacaoReprovado != null;
        });
      });
      setCollapseStates(newCollapseStates);
    }
  }, [formik.values])

  const abrirCampoReprovar = (id: number) => {
    setCollapseStates((prevStates) => ({
      ...prevStates,
      [id]: true,
    }));

    const aprovarResposta = document.querySelector(`.aprovarResposta[data-id="${id}"]`) as HTMLButtonElement;
    const reprovarResposta = document.querySelector(`.abrirCampoReprovar[data-id="${id}"]`) as HTMLButtonElement;

    aprovarResposta.style.pointerEvents = '';

    reprovarResposta.style.pointerEvents = 'none';

    const respostaElement = document.querySelector(`.resposta[data-id="${id}"]`) as HTMLInputElement;
    if (respostaElement) {
      respostaElement.style.border = '2px solid red';
    }

  };

  const reprovarCampo = (id: number) => {
    var obsReprovacao = document.querySelector(".observacao[data-id='" + id + "']") as HTMLTextAreaElement;

    httpClient
      .request({
        method: RequestMethod.GET,
        endpoint: "/DescricaoDeCargo/Descrito/ReprovarCampoDescricao",
        queryObject: {
          idValor: id,
          obsReprovado: obsReprovacao.value
        }
      })
      .then((response) => {
        if (response.success) {
          setTemValorReprovado!(true)
        }
      })
  }

  const aprovarCampo = (id: number) => {
    httpClient
      .request({
        method: RequestMethod.GET,
        endpoint: "/DescricaoDeCargo/Descrito/AprovarCampoDescricao",
        queryObject: {
          idValor: id
        }
      })
      .then((response) => {
        if (response.success) {
          
          setCollapseStates((prevStates) => ({
            ...prevStates,
            [id]: false,
          }));

          const aprovarResposta = document.querySelector(`.aprovarResposta[data-id="${id}"]`) as HTMLButtonElement;
          const reprovarResposta = document.querySelector(`.abrirCampoReprovar[data-id="${id}"]`) as HTMLButtonElement;
      
          aprovarResposta.style.pointerEvents = 'none';

          reprovarResposta.style.pointerEvents = '';
      
          const respostaElement = document.querySelector(`.resposta[data-id="${id}"]`) as HTMLInputElement;
          if (respostaElement) {
            respostaElement.style.border = '2px solid green';
          }
          
          const todasAprovadas = document.querySelectorAll('.aprovarResposta:disabled').length === (document.querySelectorAll('.aprovarResposta').length - 1);
          setTemValorReprovado && setTemValorReprovado(!todasAprovadas)
        }
      })
  }

  const handleTextareaChange = (event: React.ChangeEvent<HTMLTextAreaElement>, id: number) => {
    const textareaValue = event.target.value;
    const isButtonDisabled = textareaValue.length < 2;

    setButtonObsStates((prevStates) => ({
      ...prevStates,
      [id]: !isButtonDisabled,
    }));
  };
  
  return (
    <>
      {
        formik.values.Campos && formik.values.Campos.length > 0 ? formik.values.Campos.map((campo, campoIndex) => {

          const tipoCampo = campo.TipoCampo

          const respostaDescricaoClass = `resposta-descricao ${tipoCampo.InteracaoComAI ? 'resposta-descricao-interativa' : ''}`

          const campoRespostaAutomatica = tipoCampo.RespostaAutomatica
          let campoRespostaAutomaticaValor = ''

          switch(tipoCampo.Id){
            case 1: {
              campoRespostaAutomaticaValor = formik.values.NomeCargo
              break
            }
            case 2: {
              campoRespostaAutomaticaValor = formik.values.NomeNivelHierarquico
              break
            }
            case 3: {
              campoRespostaAutomaticaValor = formik.values.NomeArea
              break
            }
          }

          return <div key={`accordion-campo-${campo.Id}`} id={`accordion-campo-${campo.Id}`} className='mb-8'>
                  <div className={`card card-custom border border-secondary border-1 shadow-sm`}>
                    <div className="card-header bg-descricao border-bottom border-secondary border-1 rounded-bottom" id={`card-campo-header-${campo.Id}`}>
                      <div className="card-title">
                        <h3 className='text-white'>{(campo.Nome ? campo.Nome : tipoCampo.Nome)}</h3>                                
                      </div>
                      <div className="card-toolbar w-md-auto">                                
                        <button type="button" className="btn btn-icon btn-sm btn-accordion btn-light" data-bs-toggle="collapse" data-bs-target={`#card-campo-collapse-${campo.Id}`} aria-expanded="true" aria-controls={`card-campo-collapse-${campo.Id}`} >
                          <i className='fas fa-arrow-down'></i>
                          <i className='fas fa-arrow-up'></i>
                        </button>                   
                      </div>
                    </div>
                    <div id={`card-campo-collapse-${campo.Id}`} className="collapse show" aria-labelledby={`card-campo-header-${campo.Id}`} data-bs-parent={`#accordion-campo-${campo.Id}`}>
                      <div className="card-body">
                        { 
                          (campoRespostaAutomatica) 
                          ?
                            <input className={`form-control form-control-lg form-control-solid ${respostaDescricaoClass}`} disabled={true} defaultValue={campoRespostaAutomaticaValor} />  
                          :
                            campo.Respostas && campo.Respostas.length > 0 ? campo.Respostas.map((resposta, respostaIndex) => {

                              switch(tipoCampo.TipoInputHTML){
                                case 1: {
                                  return <div 
                                            key={`descricao-resposta-${resposta.Id}`}
                                            className={`${respostaIndex < (campo.Respostas.length - 1) ? 'mb-5 d-flex' : 'd-flex'}`}
                                          >
                                            <div className='me-2' style={{flexGrow: "1"}}>
                                              <Field 
                                                as='input'
                                                type='text'
                                                className={`form-control resposta form-control-lg min-h-85px form-control-solid ${respostaDescricaoClass}`}
                                                disabled={true}
                                                data-id={resposta.IdValorAtual} 
                                                value={resposta.ValorAtual?.Texto ?? ''}
                                                onChange={() => {}}                                            
                                              /> 
                                              <div className={`mt-2 collapse  ${collapseStates[resposta.IdValorAtual!] ? "show" : ""} ${aprovar ? "" : "d-none"}`} style={{transition: "height 0.3s ease"}}>
                                                  <label htmlFor="observacao" aria-required="true" className="form-label font-weight-bold">Insira uma observação para reprovar a resposta:</label>
                                                  <textarea 
                                                    id="observacao" 
                                                    required 
                                                    rows={5} 
                                                    data-id={resposta.IdValorAtual} 
                                                    className="observacao form-control form-control-solid"
                                                    onChange={(event) => handleTextareaChange(event, resposta.IdValorAtual!)}
                                                    defaultValue={resposta.ValorAtual?.ObservacaoReprovado}
                                                  />
                                                  <div className="d-flex justify-content-end mt-2">
                                                      <button 
                                                        data-id={resposta.IdValorAtual} 
                                                        className="btn btn-sm btn-danger btn-descricao-de-cargo btnReprovarCampo"
                                                        disabled={!buttonObsStates[resposta.IdValorAtual!] || !formik.values.IsAprovador}
                                                        onClick={() => reprovarCampo(resposta.IdValorAtual!)}
                                                      >
                                                          <span className="svg-icon svg-icon-muted svg-icon-2">
                                                              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                  <path opacity="0.3" fillRule="evenodd" clipRule="evenodd" d="M2 4.63158C2 3.1782 3.1782 2 4.63158 2H13.47C14.0155 2 14.278 2.66919 13.8778 3.04006L12.4556 4.35821C11.9009 4.87228 11.1726 5.15789 10.4163 5.15789H7.1579C6.05333 5.15789 5.15789 6.05333 5.15789 7.1579V16.8421C5.15789 17.9467 6.05333 18.8421 7.1579 18.8421H16.8421C17.9467 18.8421 18.8421 17.9467 18.8421 16.8421V13.7518C18.8421 12.927 19.1817 12.1387 19.7809 11.572L20.9878 10.4308C21.3703 10.0691 22 10.3403 22 10.8668V19.3684C22 20.8218 20.8218 22 19.3684 22H4.63158C3.1782 22 2 20.8218 2 19.3684V4.63158Z" fill="currentColor" />
                                                                  <path d="M10.9256 11.1882C10.5351 10.7977 10.5351 10.1645 10.9256 9.77397L18.0669 2.6327C18.8479 1.85165 20.1143 1.85165 20.8953 2.6327L21.3665 3.10391C22.1476 3.88496 22.1476 5.15129 21.3665 5.93234L14.2252 13.0736C13.8347 13.4641 13.2016 13.4641 12.811 13.0736L10.9256 11.1882Z" fill="currentColor" />
                                                                  <path d="M8.82343 12.0064L8.08852 14.3348C7.8655 15.0414 8.46151 15.7366 9.19388 15.6242L11.8974 15.2092C12.4642 15.1222 12.6916 14.4278 12.2861 14.0223L9.98595 11.7221C9.61452 11.3507 8.98154 11.5055 8.82343 12.0064Z" fill="currentColor" />
                                                              </svg>
                                                          </span>
                                                          Salvar observação
                                                      </button>
                                                  </div>
                                              </div>
                                            </div>
                                            <div className={`d-flex flex-column py-1 ${aprovar ? "" : "d-none"}`}>
                                              <button 
                                                type='submit'
                                                className="btn btn-icon btn-sm btn-success aprovarResposta" 
                                                disabled={(collapseStates[resposta.IdValorAtual!] ? false : true) || !formik.values.IsAprovador} 
                                                data-toggle="tooltip" 
                                                title="Aprovar resposta" 
                                                data-id={resposta.IdValorAtual}
                                                onClick={() => aprovarCampo(resposta.IdValorAtual!)}
                                              >
                                                <i className="fas fa-check fs-2"></i>
                                              </button>
                                              <button 
                                                type="submit" 
                                                className="btn btn-icon btn-sm btn-danger mt-4 abrirCampoReprovar" 
                                                disabled={collapseStates[resposta.IdValorAtual!] ? true : false} 
                                                data-toggle="tooltip" title="Reprovar resposta" 
                                                data-id={resposta.IdValorAtual} 
                                                aria-label="Close"
                                                onClick={() => abrirCampoReprovar(resposta.IdValorAtual!)}
                                              >
                                                <i className="fas fa-times fs-2"></i>
                                              </button>
                                            </div>
                                          </div> 
                                }
                                case 2: {
                                  return <div 
                                            key={`descricao-resposta-${resposta.Id}`}
                                            className={`${respostaIndex < (campo.Respostas.length - 1) ? 'mb-5 d-flex' : 'd-flex'}`}
                                          >
                                            <div className='me-2' style={{flexGrow: "1"}}>
                                              <Field    
                                                as='textarea'                                           
                                                className={`form-control resposta min-h-85px form-control-lg form-control-solid textarea-resposta-descricao ${respostaDescricaoClass}`}
                                                rows={3}
                                                disabled={true}                                              
                                                value={resposta.ValorAtual?.Texto ?? ''} 
                                                data-id={resposta.IdValorAtual}
                                              />
                                              <div className={`mt-2 collapse  ${collapseStates[resposta.IdValorAtual!] ? "show" : ""}  ${aprovar ? "" : "d-none"}`} style={{transition: "height 0.8s ease"}}>
                                                  <label htmlFor="observacao" aria-required="true" className="form-label fs-5 font-weight-bold">Insira uma observação para reprovar a resposta:</label>
                                                  <textarea 
                                                    id="observacao" 
                                                    required 
                                                    rows={5} 
                                                    data-id={resposta.IdValorAtual} 
                                                    className="observacao form-control form-control-solid"
                                                    onChange={(event) => handleTextareaChange(event, resposta.IdValorAtual!)}
                                                    defaultValue={resposta.ValorAtual?.ObservacaoReprovado}
                                                  />  
                                                  <span className='text-danger fs-8'>O campo é reprovado ao salvar a observação</span>                                                
                                                  <div className="d-flex justify-content-end mt-2">
                                                      <button 
                                                        data-id={resposta.IdValorAtual} 
                                                        className="btn btn-sm btn-danger btn-descricao-de-cargo btnReprovarCampo"
                                                        disabled={!buttonObsStates[resposta.IdValorAtual!] || !formik.values.IsAprovador}
                                                        onClick={() => reprovarCampo(resposta.IdValorAtual!)}
                                                      >
                                                        <span className="svg-icon svg-icon-muted svg-icon-2">
                                                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <path opacity="0.3" fillRule="evenodd" clipRule="evenodd" d="M2 4.63158C2 3.1782 3.1782 2 4.63158 2H13.47C14.0155 2 14.278 2.66919 13.8778 3.04006L12.4556 4.35821C11.9009 4.87228 11.1726 5.15789 10.4163 5.15789H7.1579C6.05333 5.15789 5.15789 6.05333 5.15789 7.1579V16.8421C5.15789 17.9467 6.05333 18.8421 7.1579 18.8421H16.8421C17.9467 18.8421 18.8421 17.9467 18.8421 16.8421V13.7518C18.8421 12.927 19.1817 12.1387 19.7809 11.572L20.9878 10.4308C21.3703 10.0691 22 10.3403 22 10.8668V19.3684C22 20.8218 20.8218 22 19.3684 22H4.63158C3.1782 22 2 20.8218 2 19.3684V4.63158Z" fill="currentColor" />
                                                                <path d="M10.9256 11.1882C10.5351 10.7977 10.5351 10.1645 10.9256 9.77397L18.0669 2.6327C18.8479 1.85165 20.1143 1.85165 20.8953 2.6327L21.3665 3.10391C22.1476 3.88496 22.1476 5.15129 21.3665 5.93234L14.2252 13.0736C13.8347 13.4641 13.2016 13.4641 12.811 13.0736L10.9256 11.1882Z" fill="currentColor" />
                                                                <path d="M8.82343 12.0064L8.08852 14.3348C7.8655 15.0414 8.46151 15.7366 9.19388 15.6242L11.8974 15.2092C12.4642 15.1222 12.6916 14.4278 12.2861 14.0223L9.98595 11.7221C9.61452 11.3507 8.98154 11.5055 8.82343 12.0064Z" fill="currentColor" />
                                                            </svg>
                                                        </span>
                                                        Salvar observação
                                                      </button>
                                                  </div>
                                              </div>
                                            </div>
                                            <div className={`d-flex flex-column py-1 ${aprovar ? "" : "d-none"}`}>
                                              <button 
                                                className="btn btn-icon btn-sm btn-success aprovarResposta" 
                                                disabled={(collapseStates[resposta.IdValorAtual!] ? false : true) || !formik.values.IsAprovador} 
                                                data-toggle="tooltip" 
                                                title="Aprovar resposta" 
                                                data-id={resposta.IdValorAtual}
                                                type='submit'
                                                onClick={() => aprovarCampo(resposta.IdValorAtual!)}
                                              >
                                                <i className="fas fa-check fs-2"></i>
                                              </button>
                                              <button 
                                                type="submit" 
                                                className="btn btn-icon btn-sm btn-danger mt-4 abrirCampoReprovar" 
                                                disabled={collapseStates[resposta.IdValorAtual!] ? true : false} 
                                                data-toggle="tooltip" title="Reprovar resposta" 
                                                data-id={resposta.IdValorAtual} 
                                                aria-label="Close"
                                                onClick={() => abrirCampoReprovar(resposta.IdValorAtual!)}
                                              >
                                                <i className="fas fa-times fs-2"></i>
                                              </button>
                                            </div>
                                          </div>
                                }
                              }

                            }) : ''
                        }
                      </div>   
                    </div>                         
                  </div>
                </div>
        }) : ''
      }
    </>    
  )
}

export {VisualizarCampos}
