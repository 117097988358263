import { Link } from 'react-router-dom'
import { TableClienteConsultoria } from './partials/TableClienteConsultoria'
import Button from '@mui/material/Button';
import { ModalAddCliente } from './partials/ModalAddCliente';
import { useState } from 'react';
import { useAuth } from '../../../modules/auth';
import { EnumServicoTipoPlano } from '../../../modules/services/core/_enums';

const ClienteConsultoria = () => {

  const {auth} = useAuth()
  const servico = auth?.servico
  const {moduloDescricaoConfig, moduloPesquisaConfig} = servico!

  const [abrirModal, setAbrirModal] = useState(false)
  const [atualizarTable, setAtualizarTable] = useState(false)

  return (
    <>
      {(auth?.roles.find((x) => x === 'Master') !== undefined ||
        auth?.roles.find((x) => x === 'Administrador') !== undefined || 
        (auth?.permissoes.find(x => x === '54522E1985DA86BF93D683BAEB093EC7') !== undefined)) && (
          <ModalAddCliente 
            setAtualizarTable={setAtualizarTable}
            abrirModal={abrirModal}
            setAbrirModal={setAbrirModal}
          />
      )}

      <div className='card mb-xl-10'>
        <div className='card-header'>
          <div className='card-title m-0'>
            <h3 className='fw-bolder m-0'>Clientes</h3>
          </div>
          <div className='card-toolbar'>
            {(auth?.roles.find((x) => x === 'Master') !== undefined ||
              auth?.roles.find((x) => x === 'Administrador') !== undefined || 
              (auth?.permissoes.find(x => x === '54522E1985DA86BF93D683BAEB093EC7') !== undefined)) && (
                  <Button type='button' className='btn btn-light-primary' onClick={() => setAbrirModal(true)}>
                    <i className="fs-2 fas fa-plus"></i>
                    Adicionar
                  </Button>
            )}
          </div>
        </div>
        <div className='card-body'>
          <TableClienteConsultoria
            atualizarTable={atualizarTable}
            setAtualizarTable={setAtualizarTable}
          />
        </div>
        <div className="card-footer py-4 d-flex justify-content-end">
          <Link className='btn btn-secondary' to='/inicio'>
            Voltar
          </Link>
        </div>
      </div>
    </>
  )
}

export {ClienteConsultoria}
