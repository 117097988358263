import {Route, Routes, Navigate} from 'react-router-dom'
import { SemAcessoLayout } from '../../../_metronic/layout/SemAcessoLayout'
import { ConfirmarFinalizarClienteConsultoriaDescricao } from './components/ConfirmarFinalizarClienteConsultoriaDescricao'

const ConfirmarFinalizarClienteConsultoria = () => {
  return (
    <Routes>
      <Route element={<SemAcessoLayout />}>        
        <Route path=':chave' element={<ConfirmarFinalizarClienteConsultoriaDescricao />} />   
        <Route path='*' element={<Navigate to='/error/404' />} />
      </Route>
    </Routes>
  )
}

export {ConfirmarFinalizarClienteConsultoria}
